import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError} from 'rxjs/operators';
import { throwError } from 'rxjs';

// config
import { AppConfig } from 'src/app/config/config';

// models
import { SiteVisibility, ParamsSiteVisibility } from 'src/app/models/site-visibility.model';
import { ParamsSiteCreate, ParamsSiteEdit } from '../../../pages/user/data-manager/site-manager/models';

@Injectable({
  providedIn: 'root',
})
export class SiteService {

  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router
  ) {}

  getSites(params: ParamsSiteVisibility = {}, highLevel: boolean = false){
    let endpoint = `ubications`;
    let routeUrl = this.router.url;

    return this.httpClient.post<SiteVisibility[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        if(!highLevel){
          res = res.filter(site => site.is_valid === 1)
        }
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  createSite(params: ParamsSiteCreate){
    let endpoint = `create-ubications`;
    let routeUrl = this.router.url;

    return this.httpClient.post<SiteVisibility>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  editSite(params: ParamsSiteEdit){
    let endpoint = `edit-ubications`;
    let routeUrl = this.router.url;

    return this.httpClient.post<SiteVisibility>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }
}
