// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-android-icon {
  color: #3DDC84;
}

.color-ios-icon {
  color: #555555;
}

.font-lg {
  font-size: 2.5em;
}

.d-flex {
  display: flex;
  justify-content: center;
}

.mtop-4 {
  margin-top: 4px;
}

.ml-10 {
  margin-left: 10px;
}

.font-lg-text {
  font-size: 2em;
}

.font-md {
  font-size: 1.2em;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/icon-system-operative/icon-system-operative.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAGA;EACE,cAAA;AAAF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,uBAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,iBAAA;AADF;;AAIA;EACE,cAAA;AADF;;AAKA;EACE,gBAAA;AAFF","sourcesContent":[".color-android-icon{\r\n  color: #3DDC84;\r\n}\r\n\r\n\r\n.color-ios-icon{\r\n  color: #555555;\r\n}\r\n\r\n\r\n.font-lg{\r\n  font-size: 2.5em;\r\n}\r\n\r\n.d-flex{\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.mtop-4{\r\n  margin-top: 4px;\r\n}\r\n\r\n.ml-10{\r\n  margin-left: 10px;\r\n}\r\n\r\n.font-lg-text{\r\n  font-size: 2em;\r\n}\r\n\r\n\r\n.font-md{\r\n  font-size: 1.2em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
