// models
import { PositionWeek } from '../../models';

// utils
import { addTimeDate, getShortDate } from 'src/app/utils';

const WEEK_DAYS = 7

export const generatePositionWeekDashboard = (dateSelected: Date) => {
  const positionsWeek: PositionWeek[] = [];

  for (let i = 0; i < WEEK_DAYS; i++) {
    const datePos = addTimeDate({
      startDate: dateSelected,
      increment: i,
      typeIncrement: 'days',
    });

    const positionWeek: PositionWeek = {
      shortDate: getShortDate(datePos),
      day: datePos.getDate(),
      position: i,
    };

    positionsWeek.push(positionWeek);
  }

  return positionsWeek;
};
