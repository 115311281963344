import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// modules
import { ColorChangeButtonModule } from '../color-change-button/color-change-button.module';
import { LanguageSelectorModule } from '../language-selector/language-selector.module';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

// Components
import {
  SubMenuSearchComponent,
  SubMenuSupportComponent,
  MainMenuComponent,
  SubMenuComponent,
} from './';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    RouterModule,

    // module
    ColorChangeButtonModule,
    LanguageSelectorModule,

    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    // NgIf
  ],
  declarations: [
    MainMenuComponent,
    SubMenuComponent,
    SubMenuSearchComponent,
    SubMenuSupportComponent,
  ],
  exports: [
    MainMenuComponent,
    SubMenuComponent,
    SubMenuSearchComponent,
    SubMenuSupportComponent,
  ],
})
export class MainMenuModule {}
