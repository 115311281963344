import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, throwError } from 'rxjs';

// models
import { MachineRoute } from 'src/app/pages/user/route-management/sales-module/models';

// config
import { AppConfig } from 'src/app/config/config';
import { removeUMTDate } from 'src/app/utils';

@Injectable({
  providedIn: 'root',
})
export class RoutesShareService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient, protected router: Router) {}

  getMachinesRoute(params: { clientID?: number; routeID?: number }) {
    let endpoint = `route/machines`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<MachineRoute[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          res.map((machineRoute) => {
            machineRoute.lastSaleDate = machineRoute?.last_sale_date
              ? removeUMTDate(new Date(machineRoute.last_sale_date))
              : null;
            machineRoute.lastChargeDate = machineRoute?.last_chargue_date
              ? removeUMTDate(new Date(machineRoute.last_chargue_date))
              : null;

            return res;
          });

          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}
