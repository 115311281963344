// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h-500 {
  height: 500px;
}

.start-line {
  margin-top: 2%;
  text-align: left;
  font-size: 0.8em;
  color: var(--ion-color-danger);
}

.pb-20 {
  --padding-bottom: 20px ;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/register-cards/components/subtypes/text-input-card/text-input-card.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,8BAAA;AACJ;;AAEA;EACE,uBAAA;AACF","sourcesContent":[".h-500{\r\n    height: 500px;\r\n}\r\n\r\n.start-line{\r\n    margin-top: 2%;\r\n    text-align: left;\r\n    font-size: 0.8em;\r\n    color: var(--ion-color-danger)\r\n  }\r\n\r\n.pb-20 {\r\n  --padding-bottom: 20px\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
