import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

// services
import { PermissionService } from 'src/app/services';

// utils
import {
  SUPERVISOR_INVENTORY_MANAGER_ROUTE,
  USE_SUPERVISOR_INVENTORY_MANAGER_NAME,
} from '../utils';

@Injectable({ providedIn: 'root' })
export class InventoryManagerResolverService {
  constructor(private _permissionService: PermissionService) {}

  resolve(): Observable<{ useSupervisor: boolean }> {
    return this._permissionService.getAllPermissions().pipe(
      map((res) => {
        const useSupervisor = res.find(
          (permission) =>
            permission.route === SUPERVISOR_INVENTORY_MANAGER_ROUTE &&
            USE_SUPERVISOR_INVENTORY_MANAGER_NAME.includes(permission.name)
        );

        return { useSupervisor: Boolean(useSupervisor) };
      })
    );
  }
}
