import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Config
import { AppConfig } from '../../../config/config';

// models
import { Till } from 'src/app/models/Till.model';
import {
  ParamsCreateTill,
  ParamsEditTill,
} from '../../../pages/user/data-manager/till-manager/models/';
import { TypeMachine } from '../../../pages/user/data-manager/till-manager/models/';

// utils
import { MACHINE_STATUS_ROUTE, MACHINE_STATUS_SUBROUTES } from 'src/app/utils';

@Injectable({
  providedIn: 'root',
})
export class TillService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient, protected router: Router) {}

  getTills(params: paramsGetTills = {}, highLevel = false) {
    let endpoint = `tills/get-tills`;
    let routeUrl = this.router.url;

    // if (
    //   routeUrl === '/user/machines-status/till-state' ||
    //   routeUrl === '/user/machines-status/current-state'
    // ) {
    //   routeUrl = '/user/machines-status';
    // }

    if(MACHINE_STATUS_SUBROUTES.includes(routeUrl)){
      routeUrl = MACHINE_STATUS_ROUTE;
    }

    if (params?.operatorID && params?.operatorID === -1) {
      delete params.operatorID;
    }

    return this.httpClient
      .post<Till[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          if (!highLevel) {
            res = res.filter((till) => till.enabled === 1);
          }
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getTillById(tillId: number, relations = false) {
    let endpoint = `tills/get-tills`;
    let routeUrl = this.router.url;
    let params: any = { id: tillId };
    if (relations) {
      params['relations'] = true;
    }
    return this.httpClient
      .post<Till>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  createTill(params: ParamsCreateTill) {
    let endpoint = `create-machines`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<Till>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  editTill(params: ParamsEditTill) {
    let endpoint = `edit-machines`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<Till>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getTypeMachine() {
    let endpoint = `machine-types`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<TypeMachine[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}

export interface paramsGetTills {
  operatorGroupID?: number;
  operatorID?: number;
  clientId?: number;
  ubicationId?: number;
  relations?: boolean;
  id?: number;
}
