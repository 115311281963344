import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Idle
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

// Component
import { IdleModalComponent } from './idle-modal/idle-modal.component';

// Service
import { AuthService, ColorModeService } from 'src/app/services';
import { MainMenuService } from '../main-menu/service/main-menu.service';

// utils
import { pcWidth, ROUTE_NOT_SHOW_IDLE } from 'src/app/utils';

@Component({
  selector: 'app-idle',
  templateUrl: './idle.component.html',
  styleUrls: ['./idle.component.scss'],
})
export class IdleComponent implements OnInit {
  // tiempo en aparecer 301
  timeStart = 301;
  // tiempo de cuenta atras 50
  timeCounter = 50;

  // las rutas que no se muetran el idle
  routesNotShow: string[] = ROUTE_NOT_SHOW_IDLE;

  private idleOnTimeout$: Subscription = null;
  private idleOnIdleStart$: Subscription = null;

  isOpen: boolean = false;

  logOutMenu$: Subscription;

  constructor(
    public readonly platform: Platform,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private modalCtrl: ModalController,
    private _authService: AuthService,
    private _mainMenuService: MainMenuService,
    private _colorModeService: ColorModeService
  ) {
    //Tiempo para que salga el modal
    this.idle.setIdle(this.timeStart);
    // //Tiempo que el usuario tiene para indicar si sigue con la session
    this.idle.setTimeout(this.timeCounter);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.reset();
  }

  ngOnInit() {
    this.idleOnTimeout$ = this.idle.onTimeout.subscribe(() => {
      this.logout();
    });

    this.idleOnIdleStart$ = this.idle.onIdleStart.subscribe(() => {
      this.showIdle();
    });

    // se ha clicado logout te desubscribes del idle
    this.logOutMenu$ = this._mainMenuService.logoutMenu.subscribe((res) => {
      if (res) {
        this.logout(false);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.logOutMenu$) this.logOutMenu$.unsubscribe();
  }

  async logout(closeModals = true) {
    if (closeModals) {
      await this.modalCtrl.dismiss();
      await this.closeAllModals();
    }

    this.idle.stop();
    this.idle.onTimeout.observers.length = 0;
    this.idle.onIdleStart.observers.length = 0;
    this.idle.onIdleEnd.observers.length = 0;
    this.idleOnTimeout$.unsubscribe();
    this.idleOnIdleStart$.unsubscribe();

    this._authService.logout();
  }

  async closeAllModals() {
    let i = 0;

    let intervalCloseModal = setInterval(async () => {
      i++;
      let isModalOpened = await this.modalCtrl.getTop();

      if (isModalOpened) {
        await this.modalCtrl.dismiss();
      } else {
        clearInterval(intervalCloseModal);
      }

      if (i >= 10) {
        clearInterval(intervalCloseModal);
      }
    }, 300);
  }

  async showIdle() {
    if (this.isOpen) return;

    if (this.routeShowIdle()) {
      const isLightColor = this._colorModeService.getIsLightColor();
      this.isOpen = true;
      let modalActive = await this.modalCtrl.create({
        component: IdleModalComponent,
        componentProps: {
          timeText: this.timeCounter,
        },
        cssClass:
          this.platform.width() > pcWidth
            ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
            : '',
      });
      if (modalActive) {
        await modalActive.present();

        const { data } = await modalActive.onWillDismiss();

        if (data?.exit) {
          await this.logout();
        }
        this.isOpen = false;
      }
    } else {
      this.reset();
    }
  }

  routeShowIdle() {
    return this.router.url.includes('/user/') &&
      !this.routesNotShow.includes(this.router.url)
      ? true
      : false;
  }

  reset() {
    if (this.idle) this.idle.watch();
  }
}
