// External Module
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Services
import { LanguageService } from '../../services';

// Pipe
import { DateStringLanguagePipe } from './date-string-language.pipe';

@NgModule({
  declarations: [
    DateStringLanguagePipe
  ],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule,
  ],
  providers: [
    LanguageService,
    DatePipe,
    DateStringLanguagePipe
  ],
  exports: [
    DateStringLanguagePipe
  ],
})
export class DateStringLanguagePipeModule {}
