import { Injectable } from '@angular/core';
import { ClientService } from '../../../../../share/services/client/client.service';
import { OperatorService } from '../../../../../share/services/operator/operator.service';
import { ArticleService } from '../../../../../share/services/article/article.service';
import { CardService } from '../../../../../share/services/card/card.service';
import { TransactionService } from '../../../../../share/services/transaction/transaction.service';
import { AppConfig } from '../../../../../config/config';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { DeleteCardSubsidy, DeleteSubsidyExcel, GetCardSubsidies, Subsidies, SubsidiesTotal, Subsidy, TransferCardSubsidy, UpdateSubsidy, UploadExcelSubsidies } from 'src/app/pages/user/cards/subsidies/models/subsidies.model';
import { Router } from '@angular/router';
import { TillService } from '../../../../../share/services/till/till.service';



@Injectable({
  providedIn: 'root'
})
export class SubsidiesService {

  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(

    private httpClient: HttpClient,
    private _tillService: TillService,
    private _clientService: ClientService,
    protected router: Router
  ) { }

  //LLAMAR ENDPOINT
  getClients() {
    return this._clientService.getClients();
  }


  getTills(params) {
    return this._tillService.getTills(params)
  }

  getTillById(tillId: number) {
    return this._tillService.getTillById(tillId);
  }


  getCardSubsidies(params: GetCardSubsidies): Observable<SubsidiesTotal> {
    let endpoint = `getCardSubsidies`;
    let routeUrl = this.router.url;

    return this.httpClient.post<SubsidiesTotal>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map((res: SubsidiesTotal) => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }


  // TODO por que hay 3 endpoints con el misma ruta!
  getSubsidies() {
    let endpoint = `Subsidies`;
    let routeUrl = this.router.url;
    return this.httpClient.get(this.serviceUrl + endpoint + `?route=${routeUrl}`).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );


  }


  getSubsidies2() {
    let endpoint = `Subsidies`;
    let routeUrl = this.router.url;
    return this.httpClient.get(this.serviceUrl + endpoint + `?route=${routeUrl}`).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );

  }


  existSubsidy() {
    let endpoint = `Subsidies`;
    let routeUrl = this.router.url;
    return this.httpClient.get<Subsidy[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );

  }



  uploadExcel(params: UploadExcelSubsidies) {
    let endpoint = `addCardSubsidy`;
    let routeUrl = this.router.url;

    return this.httpClient.post<any>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }


  uploadExcelDeleteSubsidy(params: DeleteSubsidyExcel) {

    let endpoint = `DeleteCardSubsidy`;
    let routeUrl = this.router.url;

    return this.httpClient.post<any>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );

  }


  transferCardSubsidy(params: TransferCardSubsidy) {

    let endpoint = `transferCardSubsidy`;
    let routeUrl = this.router.url;

    return this.httpClient.post<any>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );

  }


  deleteCardSubsidy(params: DeleteCardSubsidy) {

    let endpoint = `DeleteCardSubsidy`;
    let routeUrl = this.router.url;

    return this.httpClient.post<any>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );


  }

  updateSubsidy(params: UpdateSubsidy ) {
    let endpoint = `/subsidy/expired-schedule`;
    let routeUrl = this.router.url;

    return this.httpClient.put<{updated: boolean}>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }


}
