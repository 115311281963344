import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ModalController,
  ToastController,
  NavController,
  MenuController,
  Platform,
} from '@ionic/angular';
import { Subject, Subscription, lastValueFrom } from 'rxjs';
import * as CryptoJS from 'crypto-js';

// Swiper
import Swiper from 'swiper';
import { SwiperComponent } from 'swiper/angular';

// component
import { ForcePasswordRenewalComponent } from '../force-password-renewal/force-password-renewal.component';
import { ConfidentialityV2vComponent } from '../confidentiality-v2v/confidentiality-v2v.component';
import { ConfidentialityComponent } from '../confidentiality/confidentiality.component';
import { SendEmailForcePasswordComponent } from '../send-email-force-password/send-email-force-password.component';
import { WarningNotEmailComponent } from '../warning-not-email/warning-not-email.component';

// multilanguage
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../share/services/languages.service';

// services
import { MainMenuService } from '../../../../share/components/main-menu/service/main-menu.service';
import {
  PermissionService,
  ColorModeService,
  GlobalService,
  AuthService,
  SkinMangementService,
} from '../../../../services';

// models
import { AuthLogin, ColorTheme, Company, Credential } from '../../../../models';

// utils
import {
  durationErrorToast,
  pcWidth,
  pcWidthMix,
  type,
  setCrypto,
  getCrypto,
  presentToast,
  clientesavDomain,
  errorsTypes,
  UtilsTranslate,
  SIZE_FOOTER_V2V,
  openMainMenu,
} from '../../../../utils';

@Component({
  selector: 'app-login-v2v',
  templateUrl: './login-v2v.component.html',
  styleUrls: ['./login-v2v.component.scss'],
})
export class LoginV2VComponent implements OnInit {
  @ViewChild('Field2', { static: false }) public Field2: ElementRef;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  @ViewChild('Field1', { static: false }) loginInput?: any;

  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;
  plataforma: string = '';

  loginUser: Credential = {
    user: '',
    password: '',
  };
  // pos slide
  screenNext: number = 0;
  showErrorEmail = false;
  showErrorAgreement = false;

  acceptAgreement: boolean = false;
  fecha = new Date();

  // bloquear cambiar slide
  blockNext: boolean = false;

  company: Company;
  type = type;

  // login
  errorServerTranslate = '';
  errorLoginTranslate = '';
  errorLogin2Translate = '';
  errorLogin3Translate = '';
  errorTranslate = '';

  eventsSubjectClick: Subject<boolean> = new Subject<boolean>();

  loading = false;

  showPassword = false;

  forcePasswordActive = false;

  isLightColor: boolean = true;
  colorTheme: ColorTheme = 'light';
  logos = [
    '../../../assets/images/logo_elora.png',
    '../../../assets/images/logo_elora_light2.png',
  ];

  logoRocca = ['../../../assets/images/larocalogo.png', '../../../assets/images/larocca-logo-dark.png']


  sizeFooterV2V = SIZE_FOOTER_V2V;

  changeLanguage$: Subscription;
  logOutAction$: Subscription;
  changeColor$: Subscription;

  constructor(
    public readonly platform: Platform,
    private menuController: MenuController,
    private modalCtrl: ModalController,
    private toastController: ToastController,
    private navCtrl: NavController,
    private _languageService: LanguageService,
    private translate: TranslateService,
    private _authService: AuthService,
    private _permissionService: PermissionService,
    private skinService: SkinMangementService,
    private _mainMenuService: MainMenuService,
    private _globalService: GlobalService,
    private _colorModeService: ColorModeService,
    private utils: UtilsTranslate
  ) {}

  ngOnInit() {
    this.menuController.enable(false, 'first');
    this.menuController.enable(false, 'custom');

    this.setCurrentPlatform();
    this.savePlaceholders();
    this.changeLanguage$ = this._languageService.languageChange.subscribe(
      (value) => {
        this.translate.setDefaultLang(value);
        this.savePlaceholders();
      }
    );

    this.company = this.skinService.getCompany();

    this.forcePasswordActive = false;

    this.logOutAction$ = this._authService.logOutAction.subscribe((logout) => {
      if (logout) this.loading = false;
    });

    setTimeout((_) => {
      this.loginInput.setFocus();
    }, 200);

    this.getColorMode();
  }

  getColorMode() {
    this.isLightColor = this._colorModeService.getIsLightColor();
    this.colorTheme = this._colorModeService.getColorTooltipTheme();

    this.changeColor$ = this._colorModeService.changeColor$.subscribe(
      (isLightColor) => {
        this.isLightColor = isLightColor;
        this.colorTheme = this._colorModeService.getColorTooltipTheme();
      }
    );
  }

  ngOnDestroy() {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if (this.logOutAction$) this.logOutAction$.unsubscribe();
    if (this.changeColor$) this.changeColor$.unsubscribe();
  }

  setCurrentPlatform() {
    if (this.platform.is('ios')) {
      this.plataforma = 'ios';
    } else {
      this.plataforma = 'android';
    }
  }

  next() {
    if (this.blockNext) {
      return;
    }

    this.forcePasswordActive = false;
    if (this.loginUser.user) {
      this.blockNext = true;
      this._authService.validateUser(this.loginUser.user).subscribe({
        next: (res) => {
          this.blockNext = false;

          if (res?.showEmail) {
            this.showNoEmail();
          } else if (res?.showChangePassword) {
            this.forcePasswordActive = true;
            if (this.acceptAgreement) this.showChangePassword();
            else {
              this.nextTab();
            }
          } else if (res) {
            this.nextTab();
          }
        },
        error: (error) => {
          this.blockNext = false;
          if (error === '504 Gateway timeout') {
            this.utils.presentToastTranslate('error_timeout', true, 0);
          } else {
            this.utils.presentToastWithoutTranslate(this.errorServerTranslate)
          }
        },
      });
    } else {
      this.showErrorEmail = true;
    }
  }

  nextTab() {
    this.screenNext = 1;
    this.swiper.swiperRef.slideNext(300);
    this.gotoNextField(this.Field2);
  }

  gotoNextField(Field2) {
    Field2.setFocus();
  }

  back() {
    this.screenNext = 0;
    this.swiper.swiperRef.slidePrev(500);
  }

  async showAgreement() {
    let modal: any;
    const isLightColor = this._colorModeService.getIsLightColor();
    if (this.company.companySkin == this.type.alliance) {
      modal = await this.modalCtrl.create({
        component: ConfidentialityComponent,
        cssClass:
          this.platform.width() > this.pcWidth
            ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
            : '',
      });
    } else {
      modal = await this.modalCtrl.create({
        component: ConfidentialityV2vComponent,
        cssClass:
          this.platform.width() > this.pcWidth
            ? `modal-border-radius  ${!isLightColor ? 'modal-border-dark' : ''}`
            : '',
      });
    }

    if (modal) {
      await modal.present();

      const { data } = await modal.onWillDismiss();

      if (data?.accept) {
        this.acceptAgreement = true;
      } else {
        this.acceptAgreement = false;
      }
    }
  }

  activateCheckbox() {
    console.log('active');
    this.acceptAgreement = !this.acceptAgreement;
  }

  createNewUser() {
    this.navCtrl.navigateRoot('/sign-up', { animated: true });
  }

  activateUser() {
    this.navCtrl.navigateRoot('/activate-account', { animated: true });
  }

  async sendLogin() {
    if (!this.loginUser.user || !this.loginUser.password) {
      return;
    }

    if (this.loading) {
      return;
    }

    if (!this.acceptAgreement) {
      this.showErrorAgreement = true;
      this.presentToast(this.errorLogin3Translate);
      return;
    }

    if (this.forcePasswordActive) {
      this.showChangePassword();
      return;
    }

    let userData: Credential = {
      user: this.loginUser.user,
      password: setCrypto(this.loginUser.password),
    };

    try {
      this.loading = true;
      this._globalService.logOut();
      let resLogin: AuthLogin = await lastValueFrom(
        this._authService.login(userData)
      );

      await this._authService.guardarToken(resLogin.token);
      await this._authService.saveStorage(resLogin);
      this._permissionService.getPermissions(true);

      this._mainMenuService.newLogin();

      this.loading = false;

      if (clientesavDomain()) {
        this.navCtrl.navigateRoot('/user/online-shop', { animated: true });
      } else {
        this.navCtrl.navigateRoot('/user/home', { animated: true });
      }

      // if (this.platform.width() < pcWidth) {
      //   this.menuController.enable(true, 'first');
      //   this.menuController.open('first');
      // }
      openMainMenu(this.menuController)
    } catch (error) {
      this.loading = false;

      if (error == errorsTypes.login_error) {
        this.presentToast(this.errorLoginTranslate);
      } else if (error === '504 Gateway timeout') {
        this.utils.presentToastTranslate('error_timeout', true, 0);
      } else {
        this.presentToast(this.errorLogin2Translate);
      }
    }
  }

  savePlaceholders() {
    this.translate
      .get([
        'login.error_server',
        'login.error_message',
        'login.error_login2',
        'login.error_login3',
        'login.error_acceptAgreement',
        'error',
      ])
      .subscribe((translates) => {
        this.errorServerTranslate = translates['login.error_server'];
        this.errorLoginTranslate = translates['login.error_message'];
        this.errorLogin2Translate = translates['login.error_login2'];
        this.errorLogin3Translate = translates['login.error_login3'];
        this.errorTranslate = translates['error'];
      });
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: durationErrorToast,
      position: 'top',
      color: 'danger',
    });

    if (toast) {
      toast.present();
    }
  }

  async showNoEmail() {
    const isLightColor = this._colorModeService.getIsLightColor();
    const modal = await this.modalCtrl.create({
      component: WarningNotEmailComponent,
      cssClass:
        this.platform.width() > this.pcWidth
          ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
          : '',
    });

    if (modal) await modal.present();
  }

  async showChangePassword() {
    const isLightColor = this._colorModeService.getIsLightColor();

    const modal = await this.modalCtrl.create({
      component: ForcePasswordRenewalComponent,
      componentProps: {
        username: this.loginUser.user,
      },
      cssClass:
        this.platform.width() > this.pcWidth
          ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
          : '',
    });

    if (modal) await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data?.remember) {
      await this.showSendEmailForcePassword();
      this._authService.sendEmailForcePassword(this.loginUser.user).subscribe({
        next: () => {},
        error: () => {
          this.presentToast(this.errorTranslate);
        },
      });
    }
  }

  showRecoverPassword() {
    this.navCtrl.navigateRoot('/recover-password', { animated: true });
  }

  changeShowPassword(event) {
    this.showPassword = event?.detail?.checked;
  }

  async showSendEmailForcePassword() {
    const isLightColor = this._colorModeService.getIsLightColor();
    const modal = await this.modalCtrl.create({
      component: SendEmailForcePasswordComponent,
      cssClass:
        this.platform.width() > this.pcWidth
          ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
          : '',
    });

    if (modal) await modal.present();
  }

  changeAcceptAgreement(event) {
    if (
      this.forcePasswordActive &&
      this.acceptAgreement &&
      this.screenNext == 1
    ) {
      this.showChangePassword();
    }
  }

  changeAcceptAgreementEvent(event) {
    this.acceptAgreement = event;
    this.changeAcceptAgreement(event);
  }
}
