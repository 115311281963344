import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// swiper
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper';

// Component
import { CardEmitter } from './components/filter-card-individual/filter-card-individual.component';

// Service
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from 'src/app/services';
import { LanguageRouteService } from '../../services/';
import { FilterCardNumService } from '../filter-card-num/service/filter-card-num.service';
import { FilterSelectRegisterCardService } from './components/select-register-card/service/filter-select-register-card.service';
import { ClientFilterService } from '../filter-client/service/client-filter.service';
import { VisibilitySelectService } from '../../services/visibility-select/visibility-select.service';

// Models
import { Client, Company } from 'src/app/models';
import { IntervalCard } from '../../../pages/user/cards/register-cards/models/interval-cards.model';

import { CarouselType, MoveScroll } from './models/carousel-type.type';

// Utils
import { pcWidth, pcWidthMix } from '../../../utils';

@Component({
  selector: 'app-carousel-filter-guided',
  templateUrl: './carousel-filter-guided.component.html',
  styleUrls: ['./carousel-filter-guided.component.scss'],
})
export class CarouselFilterGuidedcomponent
  implements OnInit, AfterViewInit, OnChanges {

    config: SwiperOptions = {
      pagination: false,
      keyboard: false,
    };

  @Input() pcWidthMixInput = pcWidthMix;
  @Input() carouselElements: CarouselType[] = [];
  // mostrar los titulos de seleccionar
  @Input() showTitle = false;
  @Input() isOperatorRequired = false;

  // cardnum
  @Input() haveBankCard = false;

  // import
  @Input() min = 0.01;
  @Input() max = 2.0;
  @Input() step = 0.01;
  @Input() route = 'cards-webload-unlimited';

  // carga de saldo
  @Input() subscriptions: number[] = [];

  @Output() sendClientSelected = new EventEmitter<Client>();

  @Output() positionActive = new EventEmitter<CarouselType>();
  @Output() moveUp = new EventEmitter<MoveScroll>();

  company: Company;

  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;
  locale = 'es-ES';

  @ViewChild('swiperCarouselGuided', { static: false }) swiper: SwiperComponent;

  // visibilidad
  showOGSlide = true;
  showOperatorSlide = true;

  changeOperatorGroup = false;

  operatorGroupId = null;
  operatorId = null;

  loadingOperators = false;
  operators = [];

  operatorName = '';
  operatorGroupName = '';

  positionSlide = 0;

  firstTime = true;

  // client
  scrollClientActive = false;
  client: Client = null;

  scrollCardActive = false;
  card: CardEmitter = null;
  @Output() sendCardNum = new EventEmitter<CardEmitter>();

  // interval card
  cardInvidual: string = '';
  intervalCard: IntervalCard = { posStart: '', posEnd: '' };

  // loadCredit
  // isLoadCreditActive = true;

  reload = false;

  changeLanguage$: Subscription;
  filterChange$: Subscription;
  segmentChange$: Subscription;

  constructor(
    private _skinService: SkinMangementService,
    private _visibilityService: VisibilitySelectService,
    private _clientService: ClientFilterService,
    private _languageRouteService: LanguageRouteService,
    private translate: TranslateService,
    public readonly platform: Platform,
    private _filterIntervalCard: FilterSelectRegisterCardService,
    private _filterCardService: FilterCardNumService
  ) {}

  ngOnInit() {

    this.company = this._skinService.getCompany();

    // language
    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );
  }

  ngAfterViewInit() {

    if (this._visibilityService.getLoaded()) {
      setTimeout(() => {
        this.operatorId = this._visibilityService.getOperatorSelected();
        this.operatorGroupId = this._visibilityService.getOperatorGroupSelected();
      }, 50);

      let {
        operator,
        operatorGroup,
      } = this._visibilityService.getExistVision();
      setTimeout(() => {
        this.showOGSlide = operatorGroup;
        this.showOperatorSlide = operator;
        this.operatorName = this._visibilityService.getOperatorName();
        this.operatorGroupName = this._visibilityService.getOperatorGroupName();
        if (this.showOGSlide && this.carouselElements[0] == 'Group') {
          this.positionActive.emit(this.carouselElements[0]);
        } else if (
          this.showOperatorSlide &&
          this.carouselElements[1] == 'Operator'
        ) {
          this.positionActive.emit(this.carouselElements[1]);
        } else if (this.carouselElements.length > 2) {
          this.slideReset()
          this.firstTime = false;
          this.positionActive.emit(this.carouselElements[2]);
          if (this.carouselElements[2] == 'Client') {
            this.scrollClientActive = true;
          } else if (this.carouselElements[2] == 'CardNum') {
            this.scrollCardActive = true;
          }
        }
      }, 100);

      //this.firstTime = false;
    }

    if (this.firstTime) {
      if (
        this._visibilityService.getLoaded() &&
        !this._visibilityService.getLoadingOperator() &&
        !this._visibilityService.getloadingOperatorsGroup()
      ) {
        this.start();
      } else {
        this.segmentChange$ = this._visibilityService.segmentChange.subscribe(
          (res) => {
            this.start();
          }
        );
      }
    }

    if (
      this.carouselElements.includes('Group') ||
      this.carouselElements.includes('Operator')
    ) {
      this.filterChange$ = this._visibilityService.filterChange.subscribe(
        (res) => {
          if (this.firstTime) {
            return;
          }

          if (
            res.operatorGroupSelected &&
            this.showOGSlide &&
            this.operatorGroupId != res.operatorGroupSelected &&
            !res.loadingOperatorsGroup &&
            !res.loadingOperators &&
            this.carouselElements.includes('Group')
          ) {
            this.operatorGroupId = res.operatorGroupSelected;
            this.operatorId = res.operatorSelected;
            this.changeOperatorGroup = true;
            this.operatorGroupName = res?.operatorGroupName;
            this.moveSlideTo('Group');

            if(this.operatorId === -1 && res.operatorSelected === -1){
              this.changeOperatorGroup = false;
            }

          } else if (
            res.operatorSelected &&
            res.operatorSelected != this.operatorId &&
            !res.loadingOperatorsGroup &&
            !res.loadingOperators &&
            this.carouselElements.includes('Operator')
          ) {
            let prevOperator = this.operatorId;
            this.operatorId = res.operatorSelected;
            this.operatorName = this._visibilityService.getOperatorName();

            if(this.carouselElements.includes('Client')){
              this.clearClient()
            }

            if(this.carouselElements.includes('CardNum')) {
              this.clearCard()
            }

            // this.changeOperatorGroup
            //   ? (this.changeOperatorGroup = false)
            //   : (!this.isOperatorRequired || this.operatorId != -1) &&
            //     prevOperator
            //   ? this.moveSlideTo('Operator')
            //   : '';
            if(this.changeOperatorGroup){
              this.changeOperatorGroup = false
            }else if( (!this.isOperatorRequired || this.operatorId != -1) && prevOperator){
              this.moveSlideTo('Operator')
            }
          }
          // else{
          // this.nextSlide(this.carouselElements[2]);
          //   if(this.carouselElements[2] == 'Client'){
          //     this.scrollClientActive = true;
          //   }else if(this.carouselElements[2] == 'CardNum'){
          //     this.scrollCardActive = true;
          //   }
          // }
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (
      changes?.carouselElements?.previousValue &&
      changes.carouselElements?.currentValue
    ) {
      // limpiar cliente
      if (
        changes.carouselElements.previousValue.includes('Client') &&
        !changes.carouselElements.currentValue.includes('Client')
      ) {

        this.clearClient()
      }

      // limpiar visibilidad
      if (
        changes.carouselElements.previousValue.includes('Group') ||
        changes.carouselElements.previousValue.includes('Operator')
      ) {
        this._visibilityService.resetParams();
      }

      // limpiar interval card
      if (changes.carouselElements.previousValue.includes('IntervalCard')) {
        this.intervalCard = { posStart: '', posEnd: '' };
        this._filterIntervalCard.reset();
      }

      this.slideReset()
    }

    // TODO menu open and close test
    if (changes?.pcWidthMixInput) {
      if (this.company) {
        this.reload = true;
        setTimeout(() => {
          this.reload = false;
        }, 100);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if (this.filterChange$) this.filterChange$.unsubscribe();
    if (this.segmentChange$) this.segmentChange$.unsubscribe();
  }

  async slideChange() {
    this.positionSlide = await this.swiper?.swiperRef?.activeIndex;
  }

  moveSlideTo(newSlide: NewSlide = null) {

    let isValid = this.isValidNext(newSlide);

    let position = this.carouselElements.indexOf(newSlide) + 1;

    if (isValid) {
      this.positionSlide++;
      this.slideNext()

      if (newSlide == 'LoadCredit') {
        if (this.carouselElements[position] == 'Group' && !this.showOGSlide) {
          position++;
          if (
            this.carouselElements[position] == 'Operator' &&
            !this.showOperatorSlide
          ) {
            position++;
          }
        }
      }

      this.positionActive.emit(this.carouselElements[position]);

      this.carouselElements[position] == 'Client'
        ? (this.scrollClientActive = true)
        : (this.scrollClientActive = false);
      this.carouselElements[position] == 'CardNum'
        ? (this.scrollCardActive = true)
        : (this.scrollCardActive = false);

    }
  }

  backSlideTo(slide: NewSlide = null) {
    if (this.positionSlide > 0) {
      this.positionSlide--;
      this.slidePrev()

      let position = this.carouselElements.indexOf(slide) - 1;

      if (this.carouselElements.includes('LoadCredit')) {
        if (
          this.carouselElements[position] == 'Operator' &&
          !this.showOperatorSlide
        ) {
          position = position - 2;
        } else if (
          this.carouselElements[position] == 'Group' &&
          !this.showOGSlide
        ) {
          position--;
        }
      }

      this.positionActive.emit(this.carouselElements[position]);

      this.carouselElements[position] == 'Client'
        ? (this.scrollClientActive = true)
        : (this.scrollClientActive = false);
      this.carouselElements[position] == 'CardNum'
        ? (this.scrollCardActive = true)
        : (this.scrollCardActive = false);

    }
  }

  isValidNext(newSlide: NewSlide = null) {
    if (this.firstTime) {
      return true;
    }

    if (
      newSlide == 'Operator' &&
      this.operatorId == -1 &&
      this.isOperatorRequired &&
      this.operatorId != null
    ) {
      return false;
    }

    if (newSlide == 'Client' && !this.client?.ID) {
      return false;
    }

    if (
      newSlide == 'CardNum' &&
      (!this.card?.cardnum || (!this.card?.id && !this.haveBankCard))
    ) {
      return false;
    }

    if (newSlide == 'LoadCredit' && !this.card?.id) {
      return false;
    }

    if (this.carouselElements[this.carouselElements.length - 1] == newSlide) {
      return false;
    }

    return true;
  }

  sendClient(clientSelected: Client) {
    if (clientSelected) {
      if (this.carouselElements[this.carouselElements.length - 1] == 'Client') {
        this.sendClientSelected.emit(clientSelected);

        if (
          this.carouselElements.includes('Group') ||
          this.carouselElements.includes('Operator')
        ) {
          this._visibilityService.resetParams();
        }
        this._clientService.clearFilter();
      } else {
        this.client = clientSelected;
        this.moveUp.emit('Start');
        this.moveSlideTo('Client');
      }
    }
  }

  moveUpEvent() {
    if (this.showOGSlide) {
      this.moveUp.emit('Client');
    }
  }

  start() {
    let { operator, operatorGroup } = this._visibilityService.getExistVision();

    setTimeout(() => {
      this.showOGSlide = operatorGroup;
      this.showOperatorSlide = operator;
      this.operatorName = this._visibilityService.getOperatorName();
      this.operatorGroupName = this._visibilityService.getOperatorGroupName();
      this.operatorId = this._visibilityService.getOperatorSelected();
      this.operatorGroupId = this._visibilityService.getOperatorGroupSelected();

      if (this.showOGSlide && this.carouselElements[0] == 'Group') {
        this.positionActive.emit(this.carouselElements[0]);
      } else if (
        this.showOperatorSlide &&
        this.carouselElements[1] == 'Operator'
      ) {
        this.positionActive.emit(this.carouselElements[1]);
      } else if (this.carouselElements.length > 2) {
        this.positionActive.emit(this.carouselElements[2]);


        if (this.carouselElements[2] == 'Client') {
          this.scrollClientActive = true;
        } else if (this.carouselElements[2] == 'CardNum') {
          this.scrollCardActive = true;
        }
      }

      this.swiper?.swiperRef?.slideTo(0)
      setTimeout(() => {
        this.firstTime = false;
      });
    }, 100);
  }

  createdRegisterCard() {
    this.intervalCard = { posStart: '', posEnd: '' };
    this._filterIntervalCard.reset();
    if (this.carouselElements.includes('Client')) {
      this.clearClient()
    }
    this._visibilityService.resetParams();
    this.slideReset()
  }

  // Cards
  async sendCard(cardSelected: CardEmitter) {
    if (cardSelected) {
      this.card = cardSelected;
      if (
        this.carouselElements[this.carouselElements.length - 1] == 'CardNum'
      ) {
        this.sendCardNum.emit(cardSelected);
        this.moveSlideTo('CardNum');
      }
    }

    if (
      ['Import', 'LoadCredit'].includes(this.carouselElements?.[this.carouselElements.length - 1])
      &&
      this.carouselElements?.[this.carouselElements.length - 2] === 'CardNum'
    ) {
      this.slideNext()
      this.moveUp.emit('Start');
    }
  }

  // LoadCard
  changeAmount(event) {
    this._visibilityService.resetParams();
    this._filterCardService.resetCard();
    this.card = null;
    if (
      !this.showOGSlide &&
      !this.showOperatorSlide &&
      this.carouselElements[2] == 'CardNum'
    ) {
      this.scrollCardActive = true;
    }

    this.slideReset()
  }

  // envio saldo, carga de saldo unlimited
  sendImport(event: boolean) {
    if (event) {
      this._visibilityService.resetParams();
      this._filterCardService.resetCard();
      this.card = null;
      if (
        !this.showOGSlide &&
        !this.showOperatorSlide &&
        this.carouselElements[2] == 'CardNum'
      ) {
        this.scrollCardActive = true;
      }

      this.slideReset()
    }
  }

  savePlaceholders() {
    this.translate.get(['locale']).subscribe((translates) => {
      this.locale = translates['locale'];
    });
  }


  slideNext(){
    this.swiper?.swiperRef?.slideNext()
  }

  slidePrev(){
    this.swiper?.swiperRef?.slidePrev()
  }

  slideReset(){
    this.swiper?.swiperRef?.slideTo(0)
  }

  clearClient(){
    this._clientService.clearFilter();
    this.client = null;
  }

  clearCard(){
    this.card = null;
    this._filterCardService.resetCard();
  }
}

type NewSlide = CarouselType | null;
