import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

// swiper
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper';

// models
import { Company, OperatorGroup, Operator, Client } from 'src/app/models';

@Component({
  selector: 'app-search-client-by-operator',
  templateUrl: './search-client-by-operator.component.html',
  styleUrls: ['./search-client-by-operator.component.scss'],
})
export class SearchClientByOperatorComponent implements OnInit {
  config: SwiperOptions = {
    pagination: { clickable: false },
    keyboard: false,
  };

  @Input() company: Company;

  @Input() showOperatorGroup: boolean = true;
  @Input() showOperator: boolean = true;

  @Input() operatorGroups: OperatorGroup[] = [];
  @Input() operatorsAll: Operator[] = [];
  @Input() clientsAll: Client[] = [];

  operators: Operator[] = [];
  clients: Client[] = [];

  operatorGroupSelected: OperatorGroup = null;
  operatorSelected: Operator = null;
  clientSelected: Client = null;

  pos: number = 0;

  translatesTitles = [
    'register_cards.search_client.select_operator_group',
    'register_cards.search_client.select_operator',
    'register_cards.search_client.select_client',
  ];

  @ViewChild('swiperSearchClientByOperatorModal', { static: false })
  swiper: SwiperComponent;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    if (!this.showOperatorGroup) {
      this.pos = 1;
      this.operatorGroupSelected = this.operatorGroups?.[0];
      this.operators = this.operatorsAll;
    }

    if (!this.showOperator) {
      this.pos = 2;
      this.operatorSelected = this.operatorsAll?.[0];
      this.clients = this.clientsAll;
    }
  }

  goNext() {
    const pos = this.swiper?.swiperRef?.activeIndex || 0;

    this.swiper?.swiperRef?.slideTo(pos + 1);
    this.pos++;
  }

  goBack() {
    const pos = this.swiper?.swiperRef?.activeIndex || 0;

    if (pos > 0) {
      this.swiper?.swiperRef?.slideTo(pos - 1);
      this.pos--;
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  changeOperatorGroup(operatorGroup: OperatorGroup) {
    this.operatorGroupSelected = operatorGroup;
    this.operators = []

    if (this.operatorGroupSelected ) {
      if(this.operatorGroupSelected?.ID !== -1){
        this.operators = this.operatorsAll.filter(
          (operatorPos) =>
            operatorPos.OperatorGroupId === this.operatorGroupSelected.ID
        );
      }else{
        this.operators = this.operatorsAll;
      }
      this.goNext();
    }else{
      this.operators = this.operatorsAll;
    }
  }

  changeOperator(operator: Operator) {
    this.operatorSelected = operator;
    this.clients = [];

    if (this.operatorSelected) {
      if (this.operatorSelected?.id !== -1) {
        this.clients = this.clientsAll.filter(
          (clientPos) => clientPos.OperatorId === this.operatorSelected.id
        );
      }else{
        this.clients = this.clientsAll
      }
      this.goNext();
    }else{
      this.clients = this.clientsAll
    }
  }

  changeClient(client: any) {
    this.modalCtrl.dismiss({ client: client as Client });
  }
}
