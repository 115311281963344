
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';

// Service
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from '../../../../../../../services/skin-mangement.service';
import { LanguageRouteService } from '../../../../../../../share/services/languageRoute.ts/languagesRoute.services';

// Models
import { Company } from 'src/app/models/';

// Utils
import { getTargetByPlatform, type } from '../../../../../../../utils';
import { URL_SUPPORT_MAX_LICENSE } from '../../utils';


@Component({
  selector: 'app-legend-machines-status',
  templateUrl: './legend-machines-status.component.html',
  styleUrls: ['./legend-machines-status.component.scss'],
})
export class LegendMachinesStatusComponent implements OnInit {

  // aspecto
  company: Company;
  type = type;

  isTillState: boolean = false;

  target: '_self' | '_blank' = '_blank';

  URL_SUPPORT_MAX_LICENSE = URL_SUPPORT_MAX_LICENSE;

  sizeTextLink = 800;

  constructor(
    public readonly platform: Platform,
    private router: Router,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.isTillState = this.router.url === '/user/machines-status/till-state'

    this.target = getTargetByPlatform(this.platform)

  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
