import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Services
import { WebloadTransferService, FilterWebloadService } from '../../../share/services';
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from '../../../../../../../share/services/languageRoute.ts/languagesRoute.services';

// Models
import { Company, Card } from 'src/app/models';

// Utils
import { pcWidth, pcWidthMix, presentToast, successToast, UtilsTranslate  } from 'src/app/utils';


@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
})
export class InformationModalComponent implements OnInit {

  @Input() company: Company;
  @Input() amount: number = 0
  @Input() card: Card = null;
  @Input() currency: string = '';
  @Input() locale: string = 'es-ES'
  @Input() subscriptions: number[] = [2, 3, 4, 5, 10, 20]
  @Input() route: string = 'cards-webload'
  @Input() screenSm: boolean = false;

  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;

  loadingTransfer = false;

  // translates
  errorAmountSubscriptions1: string = '';
  errorAmountSubscriptions2: string = '';
  successTranslate1: string = '';
  successTranslate2: string = '';

  changeLanguage$: Subscription;

  constructor(
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _filterService: FilterWebloadService,
    private _amountTransferService: WebloadTransferService,
    private utils: UtilsTranslate,
  ) { }

  ngOnInit() {

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });

  }

  ngOnDestroy(): void {
    if( this.changeLanguage$)  this.changeLanguage$.unsubscribe()
  }

  closeModal() {
    this._filterService.setAmount(0)
    this.modalCtrl.dismiss({});
  }

  sendLoadCredit() {

    let params = this._filterService.getParams();

    if (!params.amount) {
      this.utils.presentToastTranslate('load_credit.error_not_amount', true, 0)
      return;
    }

    if (!this.subscriptions.includes(params?.amount)) {
      presentToast(this.getErrorAmountSubscription(), this.toastCtrl)
      return;
    }

    if (!params.cardId) {
      (this.company.nameCard == 'AlliancePay')
        ? this.utils.presentToastTranslate('amount_transfer.error_cardA', true, 0)
        : this.utils.presentToastTranslate('amount_transfer.error_card', true, 0)

      return;
    }

    this.loadingTransfer = true;
    this._amountTransferService.sendTransfer(params.cardId, params.amount, this.route).subscribe({next: res => {
      if (res?.updated) {
        successToast(this.getSuccessTranslate(params.amount, params.cardNum), this.toastCtrl)
        this._filterService.resetParams();
        this.modalCtrl.dismiss({updated: true});
      } else {
        this.utils.presentToastTranslate('error', true, 0)
      }
      this.loadingTransfer = false;
    }, error: () => {
      this.utils.presentToastTranslate('error', true, 0)
      this.loadingTransfer = false;
    }})
  }

  savePlaceholders() {

    this.translate.get(['load_credit.error_import_1', 'load_credit.error_import_2',
      'amount_transfer.add_amount_1', 'amount_transfer.add_amount_2']).subscribe((translates) => {
        this.errorAmountSubscriptions1 = translates['load_credit.error_import_1'];
        this.errorAmountSubscriptions1 = translates['load_credit.error_import_2'];
        this.successTranslate1 = translates['amount_transfer.add_amount_1'];
        this.successTranslate2 = translates['amount_transfer.add_amount_2'];
      });
  }

  getSuccessTranslate(amount, cardNum) {
    if (this.currency === '$') {
      return this.successTranslate1
        .replace('{amount}', amount.toFixed(2))
        .replace('{cardnum}', cardNum)
        .replace('{currency}', this.currency)
    } else {
      return this.successTranslate2
        .replace('{amount}', amount.toFixed(2))
        .replace('{cardnum}', cardNum)
        .replace('{currency}', this.currency)
    }
  }


  getErrorAmountSubscription() {
    if (this.currency === '$') {
      return this.errorAmountSubscriptions1
        .replace('{{import}}', this.amount.toFixed(2))
        .replace('{{currency}}', this.currency)
        .replace('{{valids_import}}', this.subscriptions.join(','));
    } else {
      return this.errorAmountSubscriptions2
        .replace('{{import}}', this.amount.toFixed(2))
        .replace('{{currency}}', this.currency)
        .replace('{{valids_import}}', this.subscriptions.join(','));
    }
  }

}
