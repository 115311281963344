// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { FilterInputSearchShareModule } from '../filter-input-search-share';
import { FilterTemplateSelectElemByListShareModule } from '../filter-template-select-elem-by-list-share';

// Component
import { FilterTemplateOneElemPositionShareComponent } from './filter-template-one-elem-position-share.component';

// Service
import { LanguageService } from 'src/app/share/services';
import { SkinMangementService } from 'src/app/services';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,

    FilterInputSearchShareModule,
    FilterTemplateSelectElemByListShareModule,
  ],
  declarations: [FilterTemplateOneElemPositionShareComponent],
  providers: [LanguageService, SkinMangementService],
  exports: [FilterTemplateOneElemPositionShareComponent],
})
export class FilterTemplateOneElemPositionShareModule {}
