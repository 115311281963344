import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Config
import { AppConfig } from '../../../config/config';


@Injectable({
  providedIn: 'root'
})
export class ImageHexService {

  private serviceUrl = AppConfig.RTVnodeUrl;
  constructor(private httpClient: HttpClient) { }


  public getImageById(id){
    let endpoint = `/image/${id}`;
    return this.httpClient.get(this.serviceUrl + endpoint).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }
}
