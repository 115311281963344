// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
  font-size: 30px;
}

.margin-end-60 {
  margin-right: 60px;
}

.header-elora {
  --background: linear-gradient(to right, var(--ion-color-elora1), var(--ion-color-elora2));
  --color: var(--ion-color-light-always);
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/header-modal/header-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,yFAAA;EACA,sCAAA;AACF","sourcesContent":[".icon{\r\n  font-size: 30px;\r\n}\r\n\r\n.margin-end-60{\r\n  margin-right: 60px;\r\n}\r\n\r\n.header-elora{\r\n  --background: linear-gradient(to right, var(--ion-color-elora1), var(--ion-color-elora2));\r\n  --color: var(--ion-color-light-always);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
