// models
import { diffDates, getUuid } from 'src/app/utils';
import { FilterDashboards, TotalArticlesAreasValids } from '../../models';

// constant
import {
  MAX_FILTER_WEEK_DASHBOARD,
  MAX_FILTER_MONTH_DASHBOARD,
} from '../constants';

interface ValidateDashboardDate {
  filters: FilterDashboards;
  dateStart: Date;
  dateEnd: Date;
  option: TotalArticlesAreasValids;
}

export const validateDateFilterDahsboard = (filters: FilterDashboards) => {
  validateDateTotalGross(filters);
  validateDateTotalArticle(filters, 'areaA');
  validateDateTotalArticle(filters, 'areaB');
};

const validateDateTotalGross = (filters: FilterDashboards) => {
  const { dateSelected, typeDate } = filters.totalGross.date;

  if (typeDate === 'monthly') {
    const dateStart = new Date(dateSelected);
    const dateEnd = new Date();
    const diffMonths = diffDates(dateStart, dateEnd, 'months');
    if (diffMonths >= MAX_FILTER_MONTH_DASHBOARD) {
      filters.totalGross.date = {
        dateSelected: null,
        typeDate: 'yearly',
      };
      filters.totalGross.selectDate = {
        start: null,
        id: getUuid(),
      };
    }
  }
};

const validateDateTotalArticle = (
  filters: FilterDashboards,
  option: TotalArticlesAreasValids
) => {
  const { dateSelected, typeDate } = filters.articles[option].date;

  const dateStart = new Date(dateSelected);
  const dateEnd = new Date();

  if (typeDate === 'monthly') {
    validateDateTotalArticleMonth({ filters, dateStart, dateEnd, option });
  }

  if (typeDate === 'weekly') {
    validateDateTotalArticleWeek({ filters, dateStart, dateEnd, option });
  }
};

const validateDateTotalArticleMonth = ({
  filters,
  dateStart,
  dateEnd,
  option,
}: ValidateDashboardDate) => {
  const diffMonths = diffDates(dateStart, dateEnd, 'months');
  if (diffMonths >= MAX_FILTER_MONTH_DASHBOARD) {
    resetFilterArticleDate(filters, option);
  }
};

const validateDateTotalArticleWeek = ({
  filters,
  dateStart,
  dateEnd,
  option,
}: ValidateDashboardDate) => {
  const diffsDays = diffDates(dateStart, dateEnd, 'days');
  if (diffsDays > MAX_FILTER_WEEK_DASHBOARD) {
    resetFilterArticleDate(filters, option);
  }
};

const resetFilterArticleDate = (
  filters: FilterDashboards,
  option: TotalArticlesAreasValids
) => {
  filters.articles[option].date = {
    dateSelected: null,
    typeDate: 'yearly',
  };
  filters.articles[option].selectDate = {
    start: null,
    id: getUuid(),
  };
};
