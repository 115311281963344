// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-flex-center2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon-lg {
  font-size: 3em;
}

.ml-5 {
  margin-left: 5px;
}

.font-md {
  font-size: 1.2em;
}

table {
  justify-content: center;
  text-align: center;
  width: 90%;
}

th {
  font-weight: bold;
  text-align: center;
}

td,
th {
  border: 1px solid #A9A9A9;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

.h-50 {
  height: 50px;
}

.pb-25 {
  padding-bottom: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/machines/till-alert/components/modals/legend-till-alert/legend-till-alert.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAIA;EACE,uBAAA;EACA,kBAAA;EACA,UAAA;AADF;;AAIA;EACE,iBAAA;EACA,kBAAA;AADF;;AAIA;;EAEE,yBAAA;AADF;;AAIA;EACE,aAAA;EACA,uBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,oBAAA;AADF","sourcesContent":[".d-flex-center2{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: flex-start;\r\n}\r\n\r\n.icon-lg{\r\n  font-size: 3em;\r\n}\r\n\r\n.ml-5{\r\n  margin-left: 5px;\r\n}\r\n\r\n.font-md{\r\n  font-size: 1.2em;\r\n}\r\n\r\n\r\n\r\ntable {\r\n  justify-content: center;\r\n  text-align: center;\r\n  width: 90%;\r\n}\r\n\r\nth {\r\n  font-weight: bold;\r\n  text-align: center;\r\n}\r\n\r\ntd,\r\nth {\r\n  border: 1px solid #A9A9A9;\r\n}\r\n\r\n.d-flex-center{\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.h-50{\r\n  height: 50px;\r\n}\r\n\r\n.pb-25{\r\n  padding-bottom: 25px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
