import { WritableSignal } from '@angular/core';

// models
import { FilterDateServiceI } from '../../../../../../models/filter-date.interface';
import { InfoDateMachineAlert } from '../models';

// utils
import { getTimeByHour } from 'src/app/utils';

interface GetInfoDateMachineAlert {
  _filterDate: FilterDateServiceI;
  _filterDateExtraHour: FilterDateServiceI;
  haveExtraHour: boolean;
  minimumHour: WritableSignal<number>
}

interface GetWarningTimeSlot2{
  _filterDateExtraHour: FilterDateServiceI;
  haveExtraHour: boolean;
  minimumHour: WritableSignal<number>
}

export const getInfoDateMachineAlert = ({
  _filterDate,
  _filterDateExtraHour,
  haveExtraHour,
  minimumHour
}: GetInfoDateMachineAlert): InfoDateMachineAlert => {
  let infoDate: InfoDateMachineAlert = {
    isPeriod: _filterDate.getCheckSegment() === 'period',
    date1: _filterDate.getDateStart(),
    haveExtraHour,
    timeSlot1: `${_filterDate.getStartHour()} - ${_filterDate.getEndHour()}`,
    warningTimeSlot1: getWarningTimeSlot1(_filterDate, minimumHour),
    warningTimeSlot2: getWarningTimeSlot2({_filterDateExtraHour, haveExtraHour, minimumHour})
  };

  if (_filterDate.getCheckSegment() === 'period') {
    infoDate.date2 = _filterDate.getDateEnd();
  }

  if (haveExtraHour) {
    infoDate.timeSlot2 = `${_filterDateExtraHour.getStartHour()} - ${_filterDateExtraHour.getEndHour()}`;
  }

  return infoDate;
};

export const getWarningTimeSlot1 = (_filterDateService: FilterDateServiceI, minimumHour:WritableSignal<number> ) => {
  const start = getTimeByHour(_filterDateService.getStartHour());
  const end = getTimeByHour(_filterDateService.getEndHour());

  const diffTime = end - start;
  const minHourTime = minimumHour() * 60;

  return diffTime < minHourTime;
}

export const getWarningTimeSlot2 = ({
  _filterDateExtraHour,
  haveExtraHour,
  minimumHour
}: GetWarningTimeSlot2) => {
  if(!haveExtraHour){
    return false;
  }

  return getWarningTimeSlot1(_filterDateExtraHour, minimumHour);
}
