import { WritableSignal } from '@angular/core';

// models
import { RouteDriver } from 'src/app/models';
import { RouteEditable } from '../models';

interface GetRouteDriversNotSelectedsRM {
  routeDrivers: WritableSignal<RouteDriver[]>;
  routeEditable: WritableSignal<RouteEditable>;
}

interface GetSubstituteRouteNotSelected extends GetRouteDriversNotSelectedsRM {
  isSubstitute: true;
  position: number;
}

interface GetHolderRouteNotSelected extends GetRouteDriversNotSelectedsRM {
  isSubstitute: false;
  position?: number;
}

interface GetRouteDriverSelecteds {
  routeEditable: WritableSignal<RouteEditable>;
  isSubstitute: boolean;
  position?: number;
}

type GetRouteDriverNotSelectedValid =
  | GetSubstituteRouteNotSelected
  | GetHolderRouteNotSelected;

export const getRouteDriversNotSelectedsRM = ({
  routeEditable,
  routeDrivers,
  isSubstitute,
  position = -1,
}: GetRouteDriverNotSelectedValid): RouteDriver[] => {
  let routeIdSelecteds = getRouteDriverSelecteds({
    routeEditable,
    isSubstitute,
    position,
  });

  const routeRouteDriversNotSelecteds = routeDrivers().filter(
    (route) => !routeIdSelecteds.includes(route.routeDriver_id)
  );

  return routeRouteDriversNotSelecteds;
};

const getRouteDriverSelecteds = ({
  routeEditable,
  isSubstitute,
  position,
}: GetRouteDriverSelecteds): number[] => {
  let routeIdSelecteds: number[] = [];

  if (
    isSubstitute &&
    (routeEditable()?.holderRouteDriver?.routeDriverId ||
      routeEditable()?.holderRouteDriver?.routeDriverId === 0)
  ) {
    routeIdSelecteds.push(routeEditable()?.holderRouteDriver?.routeDriverId);
  }

  const substitutes = routeEditable()?.substituteRouteDriver || [];
  for (let i = 0; i < substitutes.length; i++) {
    if (
      i !== position &&
      (substitutes?.[i]?.routeDriverId || substitutes?.[i]?.routeDriverId === 0)
    ) {
      routeIdSelecteds.push(substitutes?.[i]?.routeDriverId);
    }
  }

  return routeIdSelecteds;
};
