import { WritableSignal } from '@angular/core';
import { lastValueFrom } from 'rxjs';

// models
import { Report } from '../../models';

// services
import { LoadingService } from 'src/app/share/services';
import { ReportsService } from '../../services';

// utils
import { UtilsTranslate } from 'src/app/utils';

interface ChangeStatusReport {
  report: Report;
  _loadingService: LoadingService;
  _reportService: ReportsService;
  utils: UtilsTranslate;
  success: WritableSignal<boolean>
}

export const changeStatusReport = async ({
  report,
  _loadingService,
  _reportService,
  utils,
  success
}: ChangeStatusReport) => {
  _loadingService.present();

  const status = !report?.enabled;

  try {
    await lastValueFrom(_reportService.editStatusReports(report));
    utils.presentToastTranslate(
      status
        ? 'reports.controller.success_enabled'
        : 'reports.controller.success_disabled',
      false,
      0
    );
    success.set(true);
    _loadingService.close();
  } catch (error) {
    utils.presentToastTranslate('reports.controller.error_change_status');
    success.set(false);
    _loadingService.close();
  }
};
