// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
  font-size: 1.2em;
}

.font-sm {
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/machines/till-mapping/components/modals/legend-till-mapping/legend-till-mapping.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".text{\r\n    font-size: 1.2em;\r\n}\r\n\r\n.font-sm{\r\n  font-size: 0.9em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
