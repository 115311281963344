import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { VisibilitySegment, VisibilityFilter } from '../../../../../../models';
import { FilterCardNumService } from '../../../../../../share/components/filter-card-num/service/filter-card-num.service';
import { VisibilitySelectService } from '../../../../../../share/services/visibility-select/visibility-select.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class FilterWebloadService {

  cardId: number = null;
  cardNumber: string = '';
  amount: number = 0;
  operatorId = null;
  operatorGroupId = null;
  currency: string = '';



  // emite los cambios del filtro
  filterChange: Subject<FilterWebload> = new Subject<FilterWebload>();

  // clear
  // reset: Subject<any> = new Subject<any>();

  segmentChange: Subject<VisibilitySegment> = new Subject<VisibilitySegment>();

  currencyChange$: Subject<string> = new Subject<string>();

  filterChange$: Subscription;
  cardChange$: Subscription;
  segmentChange$: Subscription;

  constructor(
    private _filterCardService: FilterCardNumService,
    private _visibilityService: VisibilitySelectService
  ) {

    this.cardNumber = this._filterCardService.getCardNum();
    this.operatorId = this._visibilityService.getOperatorSelected();
    this.operatorGroupId = this._visibilityService.getOperatorGroupSelected();

    this.cardChange$ = this._filterCardService.filterChange.subscribe(res => {
      if (res?.cardNum || res?.cardNum == '') {
        this.cardNumber = res.cardNum
        this.cardId = res.cardId;
        this.filterChange.next({ cardNumber: this.cardNumber });
      }
      if (res?.cardId) {
        this.cardId = res.cardId
      }
    })

    // operator and group operator
    this.filterChange$ = this._visibilityService.filterChange.subscribe((res: VisibilityFilter) => {
      this.operatorId = this._visibilityService.getOperatorSelected();
      this.operatorGroupId = this._visibilityService.getOperatorGroupSelected();

      this.currency = res?.currency || '';

      this.currencyChange$.next(this.currency)
      this._filterCardService.resetCard();
    });

    // show operator, operatorGroup
    this.segmentChange$ = this._visibilityService.segmentChange.subscribe(res => {
      this.segmentChange.next(res)
    })

  }

  ngOnDestroy(): void {
    if(this.filterChange$) this.filterChange$.unsubscribe()
    if(this.cardChange$) this.cardChange$.unsubscribe()
    if(this.segmentChange$) this.segmentChange$.unsubscribe()
  }

  // cardNum
  getCardNum() {
    return this.cardNumber;
  }

  getParams(): {cardId: number, amount: number, cardNum: string} {
    return {cardId: this.cardId, amount: this.amount, cardNum: this.cardNumber };
  }

  getOperator(): number{
    return this.operatorId;
  }

  getOperatorGroup(): number{
    return this.operatorGroupId;
  }

  getAmount(){
    return this.amount
  }

  setAmount(newAmount){
    this.amount = newAmount;
  }

  resetParams(){
    this._filterCardService.resetCard();
    this._visibilityService.resetParams();

    this.cardId = this._filterCardService.getCardId();
    this.cardNumber = this._filterCardService.getCardNum();
    this.amount = null;

    this.filterChange.next({reset: true});
  }

  getOptionsValids(){
    return this._visibilityService.getOptionsValids();
  }

  started(){
    if(!this._visibilityService.getOperatorsGroup()){
      return false;
    }
    return true;
  }

  getCardId(){
    return this.cardId;
  }

  resetCard(){
    this._filterCardService.resetAllCards()
  }



}


interface FilterWebload {
  cardNumber?: string,
  amount?: number,
  reset?: boolean
}
