import { WritableSignal } from '@angular/core';

// models
import { FilterStatusOptionsReport, Report } from '../../models';

interface GetReportsByStatusFilter {
  reports: WritableSignal<Report[]>;
  statusSelected: WritableSignal<FilterStatusOptionsReport>;
}

export const getReportsByStatusFilter = ({
  reports,
  statusSelected,
}: GetReportsByStatusFilter) => {

  if(statusSelected() === null || statusSelected() === undefined){
    return;
  }

  if (statusSelected() === FilterStatusOptionsReport.Active) {
    reports.set(reports().filter((res) => res.enabled));
    return;
  }

  reports.set(reports().filter((res) => !res.enabled));
};
