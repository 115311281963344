import { Injectable } from '@angular/core';
import { MINLENGTHCARD } from 'src/app/utils';

@Injectable({
  providedIn: 'root'
})
export class CardLengthRegisterService {

  min = MINLENGTHCARD;
  max = 6;

  constructor() { }

  getMinLengthCard(){
    return this.min;
  }

  getMaxLengthCard(){
    return this.max;
  }

  setMaxLegnthCard(max: number){
    this.max = max;
  }
}
