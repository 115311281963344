export interface RoutesManagementRoutesTranslates {
  machinesModule: string,
  salesModule: string
}

export const getRoutesManagementRoutes = (
  translates: RoutesManagementRoutesTranslates
): { route: string; name: string }[] => {
  return [
    { route: '/user/machine-module', name: translates.machinesModule },
    { route: '/user/sales-module', name: translates.salesModule },
  ]
}
