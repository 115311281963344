import { Injectable } from '@angular/core';
import { TillService } from '../../../services/till/till.service';
import { Subject } from 'rxjs';
import { Till } from '../../../../models/Till.model';

@Injectable({
  providedIn: 'root',
})
export class FilterTillService {
  tills: Till[] = [];

  code: number = null;
  idTill: number = null;

  loadedTill: boolean = false;
  loadingTill: boolean = false;

  filterChange: Subject<number> = new Subject<number>();
  changeState: Subject<LoadingTill> = new Subject<LoadingTill>();

  clientFilter: number = null;

  constructor(private _tillService: TillService) {}

  startTills() {
    if (!this.loadedTill) {
      this.loadingTill = true;
      this.tills = [];
      this.changeState.next({
        loadingTill: this.loadingTill,
        loadedTill: this.loadedTill,
      });
      let filter = this.clientFilter ? { clientId: this.clientFilter } : {};
      this._tillService.getTills(filter).subscribe({
        next: (res: Till[]) => {
          this.tills = res;
          this.loadedTill = true;
          this.loadingTill = false;
          this.changeState.next({
            loadingTill: this.loadingTill,
            loadedTill: this.loadedTill,
          });
        },
        error: (error) => {
          this.tills = [];
          this.loadedTill = true;
          this.loadingTill = false;
        },
      });
    }
  }

  getTills(): Till[] {
    return this.tills;
  }

  // machine
  getMachineNumber() {
    return this.code;
  }

  getTillid() {
    return this.idTill;
  }

  setMachine(code: number, id: number) {
    this.code = code;
    this.idTill = id;
    this.filterChange.next(this.code);
  }

  getFilterTill(): FilterTill {
    return {
      code: this.code,
      idTill: this.idTill,
      loadedTill: this.loadedTill,
      loadingTill: this.loadingTill,
    };
  }

  getLoaded(): boolean {
    return this.loadedTill;
  }

  changeFilter(filter: Filter) {
    this.code = null;

    if (filter.clientId) {
      this.clientFilter = filter.clientId;
    }
    this.loadingTill = false;
    this.loadedTill = false;

    this.code = null;
    this.idTill = null;
    this.filterChange.next(this.code);

    this.startTills();
  }

  clearFilter(clearTills = false) {
    this.code = null;
    this.idTill = null;
    this.filterChange.next(this.code);
    if (clearTills) {
      this.loadedTill = false;
      this.loadingTill = false;
      this.tills = [];
      this.clientFilter = null;
      this.changeState.next({
        loadingTill: this.loadingTill,
        loadedTill: this.loadedTill,
      });
    }
  }
}

interface LoadingTill {
  loadingTill: boolean;
  loadedTill: boolean;
}

interface FilterTill {
  code: number;
  idTill: number;
  loadedTill: boolean;
  loadingTill: boolean;
}

interface Filter {
  clientId?: number;
}
