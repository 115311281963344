export const addImageToDiv = (image, id, divId, height?: string )=> {

  const img = document.createElement("img");
  img.src = image;
  img.id = "article" + id;
  if(height)
    img.style.height = height;

  const currentDiv = document.getElementById(divId);

  let theFirstChild = currentDiv.firstChild;
  if (theFirstChild) {
      currentDiv.removeChild(theFirstChild)
  }
  theFirstChild = currentDiv.firstChild;

  currentDiv.insertBefore(img, theFirstChild);

}


export const createImages = (hexImage) => {
  let input = hexImage;
  if (input) {
    input = input.replace(/[^A-Fa-f0-9]/g, '');
    if (input.length % 2) {
      //  console.log("cleaned hex string length is odd.");
      return;
    }

    let binary = new Array();
    for (let i = 0; i < input.length / 2; i++) {
      let h = input.substr(i * 2, 2);
      binary[i] = parseInt(h, 16);
    }

    let byteArray = new Uint8Array(binary);

    return URL.createObjectURL(
      new Blob([byteArray], { type: 'application/octet-stream' })
    );
  }
};
