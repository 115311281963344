export interface ArticlesRoutesTranslates {
  soldItems: string,
  top5sales: string
}

export const getArticlesRoutes = (
  translates: ArticlesRoutesTranslates
): { route: string; name: string }[] => {
  return [
    { route: '/user/product-sold', name: translates.soldItems },
    { route: '/user/top5-sales', name: translates.top5sales }
  ]
}
