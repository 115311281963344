import { LinkRoute } from 'src/app/models';

export const generateRoutesGroupValid = (
  routesGroup: LinkRoute[],
  routesValid: number[]
) => {

  let routesGroupValid = [];

  for (let valid of routesValid) {
    routesGroupValid.push(routesGroup[valid]);
  }

  return routesGroupValid;
};
