import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// services
import { SkinMangementService } from 'src/app/services';

@Component({
  selector: 'app-licenses-vatg-legend',
  templateUrl: './licenses-vatg-legend.component.html',
  styleUrls: ['./licenses-vatg-legend.component.scss'],
})
export class LicensesVatgLegendComponent implements OnInit {
  company: Company;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }
}
