import { addTimeDate, useChangeFormatDate } from 'src/app/utils';
import { getLastDayWithInfo } from '.';

// constant
import { DIFF_DAYS_FILTER_DATE } from '..';

export const getMinMaxFilterDaily = (): { min: Date; max: Date } => {
  const lastDay = getLastDayWithInfo();

  const min = addTimeDate({
    startDate: lastDay,
    increment: -DIFF_DAYS_FILTER_DATE,
    typeIncrement: 'days',
  });

  return { min, max: lastDay };
};

export const getMinMaxFilterDailyWithFormat = (): {
  min: string;
  max: string;
} => {
  const format: string = 'YYYY-MM-DD';

  const filterDaily: { min: Date; max: Date } = getMinMaxFilterDaily();

  return {
    min: useChangeFormatDate(filterDaily.min, format),
    max: useChangeFormatDate(filterDaily.max, format),
  };
};
