import { Injectable } from "@angular/core";

import { Observable, of } from 'rxjs';
import { PermissionService } from '../../../../../services/permission.service';
import { filter, find, map } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class GenerateQRResolverService {

    constructor(private _permissionService: PermissionService) {}

    resolve(): Observable<boolean>{
        return this._permissionService
                .getAllPermissions()
                    .pipe(
                        map(res => 
                             !!(res.find((permission) => 
                                permission?.route === '/user/generate-qr' && permission?.name === 'High Level: Generar código QR'
                            )) 
                        )
                    );
    }

}
