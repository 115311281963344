import {
  Component,
  Input,
  OnInit,
  signal,
  TemplateRef,
  WritableSignal,
} from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { animate, style, transition, trigger } from '@angular/animations';
import { ComponentProps } from '@ionic/core';

// // components
import { InvalidCardsByTextReportModalComponent } from '../invalid-cards-by-text-report-modal/invalid-cards-by-text-report-modal.component';

// services
import { TranslateService } from '@ngx-translate/core';
import {
  FilterDateInModalService,
  LoadingService,
} from 'src/app/share/services';
import {
  CardsByTextReportService,
  FilterReportService,
  ReportsService,
} from '../../../services';

// models
import { Company } from 'src/app/models';
import { DatePickerFilterOptions } from 'src/app/share/components/filter-date-template/models';
import {
  CreateReportParams,
  EditReportParams,
  Periodicity,
  PeriodicityInfo,
  TypeReport,
  TypesSelectedReport,
  ValidationCardByTextReportProps,
} from '../../../models';
import {
  PeriocityOptionReport,
  PeriocityTextOptionsReport,
  SelectCardsOptionsReport,
  SendingInfoReport,
  StepCreateEditReport,
} from '../../../models/enums';
import { EmailReport } from '../../../models/params/email-report-params.model';

// utils
import {
  difumineModal,
  pcWidth,
  pcWidthMix,
  searchDuplicate,
  validateEmail,
  UtilsTranslate,
  getTomorrowDay,
  searchDuplicateCompleteObj,
  ModalGenerator,
  convertNumber,
  removeUMTDate,
  addUMTDate,
} from '../../../../../../../utils/';
import {
  applyChangeTypeReport,
  DIFF_DAYS_REPORT,
  haveTypeReportSelectedCard,
  MAX_LENGTH_NAME_REPORT,
  MONTHLY_PERIODICITY_REPORT,
  WEEKLY_PERIODICITY_REPORT,
  ONLY_DAYS_MONTH_REPORT,
  TIME_DURATION_ERROR_REPORT,
  getTextRepeatCardTranslateReport,
  PERIODICITIES_REPORTS,
  editReport,
  createReport,
  getFirstDateCreateEditReport,
  isValidCardReport,
  getCardsIdsByTextReport,
  TIME_DURATION_MIN_CLOSE_LOADING_BANNER_REPORT,
  setEditDaySelectedMonthlyReport,
  MAX_GUIDED_CARD_REPORT,
  getDaysByMonthReport,
  transformUMTDateReport,
  hasTypeReportExtraHierarchy,
} from '../../../utils';

@Component({
  selector: 'app-create-edit-report-modal',
  templateUrl: './create-edit-report-modal.component.html',
  styleUrls: ['./create-edit-report-modal.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('1s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CreateEditReportModalComponent implements OnInit {
  @Input() company: Company;

  @Input() typeReports: TypeReport[] = [];
  @Input() isEdit: boolean = false;

  @Input() emails: EmailReport[] = [
    {
      ID: null,
      ReportID: null,
      Email: '',
      Enable: 1,
      CreationDate: null,
      ModificationDate: null,
    },
  ];
  @Input() reportName: string = '';
  @Input() typeReportSelected: number = null;
  @Input() modifiedDate: string | Date = null;

  @Input() dateHourInModal: TemplateRef<string>;
  @Input() datePickerFilter: WritableSignal<DatePickerFilterOptions>;

  @Input() reportId: number = null;
  @Input() reportEnable: number = null;

  showMultipleCards: boolean = false;
  showExtraHierarchy: boolean = true;

  periocities: PeriodicityInfo[] = PERIODICITIES_REPORTS;

  periodSelected = null;

  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;
  typeReportsFiltered: TypeReport[] = [];

  MAXLENGTHNAME = MAX_LENGTH_NAME_REPORT;

  isOpenCalendarModal: boolean = false;
  idContentModal = 'reportContentModal';

  isOpenFilterModal: boolean = false;
  emailsValids: EmailReport[] = [];

  positionVisibilityReport = TypesSelectedReport.createEdit;
  PeriocityOptionReport = PeriocityOptionReport;

  typeReport: TypeReport = null;

  showerStep: Record<StepCreateEditReport, boolean> = {
    [StepCreateEditReport.Periodicity]: true,
    [StepCreateEditReport.ReportType]: false,
    [StepCreateEditReport.Visibility]: false,
    [StepCreateEditReport.Date]: false,
    [StepCreateEditReport.Emails]: false,
    [StepCreateEditReport.Name]: false,
  };

  StepCreateEditReport = StepCreateEditReport;

  dayReportMonthly: WritableSignal<number> = signal(null);
  monthReportMonthly: WritableSignal<number> = signal(null);
  daysMonthlyReport: WritableSignal<number[]> = signal([1, 26]);

  firstDate: Date = null;

  // ------- Multiple cards text -----------
  selectCardSegment: WritableSignal<SelectCardsOptionsReport> = signal(
    SelectCardsOptionsReport.Guided
  );
  SelectCardsOptionsReport = SelectCardsOptionsReport;
  convertNumber = convertNumber;
  loadingGetCardIds: WritableSignal<boolean> = signal(false);

  //------ SendingType----------

  @Input() sendingFormatSelected: any = SendingInfoReport.Excel;

  sendingInfo: Record<SendingInfoReport, string> = {
    [SendingInfoReport.Excel]: 'Excel',
    [SendingInfoReport.PDF]: 'PDF',
    [SendingInfoReport.ExcelPDF]: 'Excel+PDF',
  };

  constructor(
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private _loadingService: LoadingService,
    private translate: TranslateService,
    private _reportService: ReportsService,
    private _filterReportService: FilterReportService,
    private _filterDateService: FilterDateInModalService,
    public _cardsByTextReportService: CardsByTextReportService,
    private modalGenerator: ModalGenerator,
    private utils: UtilsTranslate
  ) {}

  ngOnInit() {
    this.setDate();

    this.typeReportsFiltered = this.typeReports;

    this.periocities = new Periodicity(this.translate).getPeriocities();

    this.startShowerStep();

    if (this.typeReportSelected) {
      this.changesStatus();
    }

    this.getCardsEdit();
  }

  startShowerStep() {
    if (this.isEdit) {
      this.showerStep = {
        [StepCreateEditReport.Periodicity]: true,
        [StepCreateEditReport.ReportType]: true,
        [StepCreateEditReport.Visibility]: true,
        [StepCreateEditReport.Date]: true,
        [StepCreateEditReport.Emails]: true,
        [StepCreateEditReport.Name]: true,
      };
    }
  }

  getCardsEdit() {
    if (this.isEdit) {
      const cards = this._filterReportService.getCards();

      if ((cards || []).length > MAX_GUIDED_CARD_REPORT) {
        this._filterReportService.startCards();
        this._cardsByTextReportService.setCards(
          cards,
          this._filterReportService
        );
        this.selectCardSegment.set(SelectCardsOptionsReport.Text);
      }
    }
  }

  setDate() {
    if (!this.modifiedDate) {
      this.startDate();
    } else {
      this.editDate();
    }
  }

  startDate() {
    const tomorrowDate = getTomorrowDay();

    const dateCurrent = `${tomorrowDate.getFullYear()}/${
      tomorrowDate.getMonth() + 1
    }/${tomorrowDate.getDate()}`;

    this._filterDateService.setDate(dateCurrent, 0);
  }

  editDate() {
    const modifiedDate = new Date(this.modifiedDate);

    const dateModifiedDate = `${modifiedDate.getFullYear()}/${
      modifiedDate.getMonth() + 1
    }/${modifiedDate.getDate()}`;

    this._filterDateService.setDate(dateModifiedDate, 0);

    setEditDaySelectedMonthlyReport({
      dateSelected: modifiedDate,
      dayReportMonthly: this.dayReportMonthly,
      monthReportMonthly: this.monthReportMonthly,
    });

    getDaysByMonthReport({
      month: this.monthReportMonthly(),
      daysMonthlyReport: this.daysMonthlyReport,
    });

    // bugfix next year report
    if (
      !this.daysMonthlyReport().includes(this.dayReportMonthly()) &&
      (this.daysMonthlyReport() || []).length > 0
    ) {
      this.dayReportMonthly.set(this.daysMonthlyReport()[0]);
    }
  }

  closeModal() {
    this._filterDateService.clear();
    this.modalCtrl.dismiss();
  }

  changesStatus() {
    this.typeReport = this.typeReports.find(
      (typeReport) => typeReport.ID === this.typeReportSelected
    );

    console.log('typeReport', this.typeReport);

    if (this.typeReport) {
      const periodicity = this.periocities.find(
        (periocity) => periocity.Periodicity === this.typeReport.Periodicity
      );
      if (periodicity) {
        this.periodSelected = periodicity.ID;
      }
    }

    this.showMultipleCards = haveTypeReportSelectedCard(
      this.typeReports,
      this.typeReportSelected
    );

    this.showExtraHierarchy = hasTypeReportExtraHierarchy(
      this.typeReports,
      this.typeReportSelected
    );

    // this.changeTypeReport();

    if (!this.showerStep[StepCreateEditReport.Visibility])
      this.showerStep[StepCreateEditReport.Visibility] = true;

    if (!this.showMultipleCards && !this.showerStep[StepCreateEditReport.Date])
      this.showerStep[StepCreateEditReport.Date] = true;

    if (
      !this.showMultipleCards &&
      !this.showerStep[StepCreateEditReport.Emails]
    )
      this.showerStep[StepCreateEditReport.Emails] = true;
  }

  handleAddEmail(pos: number) {
    // this.emails.splice(pos + 1, 0, '');
    this.emails.splice(pos + 1, 0, {
      ID: null,
      ReportID: null,
      Email: '',
      Enable: 1,
      CreationDate: null,
      ModificationDate: null,
    });
    this.filteredEmails.splice(pos + 1, 0, {
      ID: null,
      ReportID: null,
      Email: '',
      Enable: 1,
      CreationDate: null,
      ModificationDate: null,
    });
  }

  handleRemoveEmail(pos: number) {
    // Obtener el email filtrado a remover
    // const emailToRemove = this.filteredEmails[pos];

    // Actualizar el array principal de emails usando map
    this.emails.map((email) => {
      if (
        email.Email === this.filteredEmails[pos].Email &&
        email.ID === this.filteredEmails[pos].ID &&
        email.Enable === 1 &&
        email.CreationDate === this.filteredEmails[pos].CreationDate
      ) {
        return { ...email, Enable: 0 };
      }
      return email;
    });

    // Actualizar el email en filteredEmails al final
    this.filteredEmails[pos].Enable = 0;

    // Opcional: Si necesitas eliminar el email del array filtrado
    // this.filteredEmails.splice(pos, 1);
  }

  trackByFn(index, item) {
    return index;
  }

  isOpenModal(isOpenModal: boolean) {
    this.isOpenFilterModal = isOpenModal;
    difumineModal(this.idContentModal, isOpenModal);
  }

  async createReportOrEdit() {
    // this.emailsValids = this.emails.filter((email) => email);

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this.emailsValids = [];
    for (let i = 0; i < this.emails.length; i++) {
      if (this.emails[i]) {
        this.emailsValids.push({
          ID: this.emails[i].ID,
          Email: this.emails[i].Email,
          ReportID: this.reportId,
          Enable: this.emails[i].Enable,
          CreationDate: this.isEdit ? this.emails[i].CreationDate : today,
          ModificationDate: today,
        });
      }
    }

    this.firstDate = getFirstDateCreateEditReport({
      _filterDateService: this._filterDateService,
      dayReportMonthly: this.dayReportMonthly,
      monthReportMonthly: this.monthReportMonthly,
      periodSelected: this.periodSelected,
    });

    const isValidReport = await this.isValidReport();
    if (!isValidReport) {
      return;
    }

    // let params: CreateReportParams =
    // {
    //   operatorID: this._filterReportService.getOperator(
    //     this.positionVisibilityReport
    //   )?.id,
    //   operatorGroupID: this._filterReportService.getOperatorGroup(
    //     this.positionVisibilityReport
    //   )?.ID,
    //   description: this.reportName,
    //   // first_Date: new Date(`${this._filterDateService.getDateStart()} 00:00`),
    //   first_Date: this.firstDate.toString(),
    //   emails: this.emailsValids,
    //   typeID: this.typeReportSelected,
    //   companyID: this._filterReportService.getOperatorGroup(
    //     this.positionVisibilityReport
    //   )?.CompanyId,
    // };

    let clientID = null;
    if (this._filterReportService.getClient(this.positionVisibilityReport)) {
      // params.clientID = this._filterReportService.getClient(
      //   this.positionVisibilityReport
      // )?.ID;
      clientID = this._filterReportService.getClient(
        this.positionVisibilityReport
      )?.ID;
    }

    let machineID = null;

    if (this._filterReportService.getTill(this.positionVisibilityReport)) {
      // params.machineID = this._filterReportService.getTill(
      //   this.positionVisibilityReport
      // )?.id;

      machineID = this._filterReportService.getTill(
        this.positionVisibilityReport
      )?.id;
    }

    let tarjetas = [];
    if (this.showMultipleCards) {
      if (
        Number(this.selectCardSegment()) === SelectCardsOptionsReport.Guided
      ) {
        // params.cards = this._filterReportService.getCardsId();
        tarjetas = this._filterReportService.getCardsId();
      } else {
        this.loadingGetCardIds.set(true);
        this._loadingService.present();
        const cardsIds: [
          cardIds: number[],
          haveError: boolean
        ] = await getCardsIdsByTextReport({
          _reportService: this._reportService,
          _cardsByTextReportService: this._cardsByTextReportService,
          utils: this.utils,
          isEdit: this.isEdit,
        });
        this.loadingGetCardIds.set(false);
        const [cards, haveError] = cardsIds;
        if (haveError) {
          setTimeout(() => {
            this._loadingService.close();
          }, TIME_DURATION_MIN_CLOSE_LOADING_BANNER_REPORT);
          return;
        }
        // params.cards = cards;
        tarjetas = cards;
      }
    }
    let first_Date = this.firstDate.toString();
    // params.first_Date = transformUMTDateReport(params.first_Date).toString();

    this._filterDateService.clear();
    
    let params: CreateReportParams = {
      operatorID: this._filterReportService.getOperator(
            this.positionVisibilityReport
          )?.id,
          operatorGroupID: this._filterReportService.getOperatorGroup(
            this.positionVisibilityReport
          )?.ID,
          description: this.reportName,
          // first_Date: new Date(`${this._filterDateService.getDateStart()} 00:00`).toString(),
          first_Date:  addUMTDate(this.firstDate).toString(),
          emails: this.emailsValids,
          typeID: this.typeReportSelected,
          companyID: this._filterReportService.getOperatorGroup(
            this.positionVisibilityReport
          )?.CompanyId,
          clientID: clientID,
          machineID: machineID,
          cards: tarjetas,
          // Periodicity: perio,
          sendingFormat: Number(this.sendingFormatSelected)
    }
    console.log("params report", first_Date);


    if (!this.isEdit) {
      await this.createReport(params);
    } else {
      await this.editReport(params);
    }
  }

  async isValidReport() {
    const operatorGroupSelected = this._filterReportService.getOperatorGroup(
      TypesSelectedReport.createEdit
    );

    if (!operatorGroupSelected) {
      this.utils.presentToastTranslate('Selecciona un grupo operador');
      return false;
    }

    if (this.showExtraHierarchy) {
      const operatorSelected = this._filterReportService.getOperator(
        TypesSelectedReport.createEdit
      );
      if (!operatorSelected) {
        this.utils.presentToastTranslate('Selecciona un operador');
        return false;
      }

      const clientSelected = this._filterReportService.getClient(
        TypesSelectedReport.createEdit
      );
      if (!clientSelected) {
        this.utils.presentToastTranslate('reports.create_edit.client_required');
        return false;
      }
    }

    if (!this.typeReportSelected) {
      this.utils.presentToastTranslate(
        'reports.create_edit.report_type_required',
        true,
        0
      );
      return false;
    }

    if (!this.reportName) {
      this.utils.presentToastTranslate(
        'reports.create_edit.name_report_required',
        true,
        0
      );
      return false;
    }

    if (this.emailsValids.length === 0) {
      this.utils.presentToastTranslate(
        'reports.create_edit.enter_one_email',
        true,
        0
      );
      return false;
    }

    const emailNotValid = this.emailsValids.find(
      (email) => !validateEmail(email.Email)
    );
    if (emailNotValid) {
      this.utils.presentToastWithVariable(
        'reports.create_edit.invalid_email',
        'email',
        emailNotValid.Email,
        true
      );
      return false;
    }

    const duplicateEmail = searchDuplicate(this.emailsValids);
    if (duplicateEmail.length > 0) {
      this.utils.presentToastWithVariable(
        'reports.create_edit.duplicate_email',
        'email',
        duplicateEmail[0],
        true
      );
      return false;
    }

    if (!this.isValidDate()) {
      return false;
    }

    if (!(await this.isValidCards())) {
      return false;
    }

    if (!this.isValidDateByPeriodSelected()) {
      return false;
    }

    return true;
  }

  changePeriod() {
    const periodicity = this.periocities.find(
      (periocity) => periocity.ID === this.periodSelected
    );

    if (periodicity) {
      this.typeReportsFiltered = this.typeReports.filter(
        (typeReport) => typeReport.Periodicity === periodicity.Periodicity
      );

      const typeReport = this.typeReports.find(
        (typeReport) => typeReport.ID === this.typeReportSelected
      );

      if (typeReport?.Periodicity !== periodicity.Periodicity) {
        this.typeReportSelected = null;
      }
    }

    this.changeTypeReport();

    if (!this.showerStep[StepCreateEditReport.ReportType])
      this.showerStep[StepCreateEditReport.ReportType] = true;

    if ((this.typeReportsFiltered || []).length === 1) {
      this.typeReportSelected = this.typeReportsFiltered[0]?.ID;
      this.changesStatus();
    }
  }

  changeTypeReport() {
    const periodSelectedText =
      PeriocityTextOptionsReport?.[this.periodSelected] || '';

    applyChangeTypeReport({
      periodSelected: this.periodSelected,
      datePickerFilter: this.datePickerFilter,
      _filterDateService: this._filterDateService,
    });
  }

  async isValidCards() {
    return await isValidCardReport({
      company: this.company,
      showMultipleCards: this.showMultipleCards,
      _filterReportService: this._filterReportService,
      _reportService: this._reportService,
      _cardsByTextReportService: this._cardsByTextReportService,
      selectCardSegment: this.selectCardSegment,
      showerStep: this.showerStep,
      utils: this.utils,
    });
  }

  async finishValidationCardByText() {
    if (
      !this._cardsByTextReportService.isValidCardText() &&
      Number(this.selectCardSegment()) === SelectCardsOptionsReport.Text &&
      ((
        this._cardsByTextReportService.invalidCardsByText().dupplicateCards ||
        []
      ).length > 0 ||
        (
          this._cardsByTextReportService.invalidCardsByText().notExistCards ||
          []
        ).length > 0)
    ) {
      this.isOpenModal(true);
      const props: ComponentProps & ValidationCardByTextReportProps = {
        company: this.company,
        // sendingFormat: this.sendingFormatSelected,
      };
      await this.modalGenerator.show(
        InvalidCardsByTextReportModalComponent,
        props
      );
      this.isOpenModal(false);
    }
  }

  isValidDate() {
    if (!this.firstDate) {
      this.utils.presentToastTranslate(
        'reports.create_edit.invalid_date',
        true,
        0
      );
      return false;
    }

    const diffDays = Math.floor(
      (this.firstDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
    );
    if ((!this.modifiedDate && diffDays < -1) || diffDays >= DIFF_DAYS_REPORT) {
      this.utils.presentToastWithVariable(
        'reports.create_edit.invalid_max_date',
        'days',
        DIFF_DAYS_REPORT.toString(),
        true
      );
      return false;
    }

    return true;
  }

  isValidDateByPeriodSelected(): boolean {
    if (
      (this.typeReport?.Periodicity || '').toLowerCase() ===
      WEEKLY_PERIODICITY_REPORT.toLowerCase()
    ) {
      return this.isValidDateSelectedByWeekly();
    }

    if (
      (this.typeReport?.Periodicity || '').toLowerCase() ===
      MONTHLY_PERIODICITY_REPORT.toLowerCase()
    ) {
      return this.isValidDateByMonthly();
    }

    return true;
  }

  isValidDateSelectedByWeekly(): boolean {
    const dayWeek = this.firstDate.getDay();

    if (dayWeek !== 1) {
      this.utils.presentToastTranslate(
        'reports.create_edit.periocity_week_error',
        true,
        0,
        TIME_DURATION_ERROR_REPORT
      );
      return false;
    }

    return true;
  }

  isValidDateByMonthly(): boolean {
    const dayMonth = this.firstDate.getDate();

    if (!ONLY_DAYS_MONTH_REPORT.includes(dayMonth)) {
      const daysMonthValid = ONLY_DAYS_MONTH_REPORT.join(', ');
      this.utils.presentToastWithVariable(
        'reports.create_edit.periocity_month_error',
        'DAYS_MONTH_VALID',
        daysMonthValid,
        true,
        TIME_DURATION_ERROR_REPORT
      );
      return false;
    }

    return true;
  }

  haveSomeRepeatCard() {
    const cardsSelected = this._filterReportService.getValidCards();

    const duplicateCards = searchDuplicateCompleteObj<{
      id: number;
      cardNum: string;
    }>(cardsSelected, 'id');

    if ((duplicateCards || []).length > 0) {
      const cardNumbers = duplicateCards.map((card) => card.cardNum);

      let textDuplicate = getTextRepeatCardTranslateReport(
        this.company,
        cardNumbers.length
      );

      if ((cardNumbers || []).length > 1) {
        this.utils.presentToastWithVariableAndCardName(
          {
            message: textDuplicate,
            nameVariable: 'cards',
            valueVariable: cardNumbers.join(', '),
            error: true,
            duration: TIME_DURATION_ERROR_REPORT,
            isCardNamePlural: true
          }
        );
      } else {
        this.utils.presentToastWithVariable(
          textDuplicate,
          'cards',
          cardNumbers.join(', '),
          true,
          TIME_DURATION_ERROR_REPORT
        );
      }
      return true;
    }

    return false;
  }

  changeEmail(i: number) {
    if (this.showerStep[StepCreateEditReport.Name]) {
      return;
    }

    if (validateEmail(this.emails?.[i].Email || ''))
      this.showerStep[StepCreateEditReport.Name] = true;
  }

  async createReport(paramsCreate: CreateReportParams) {
    const success = signal(true);

    await createReport({
      company: this.company,
      _loadingService: this._loadingService,
      _reportService: this._reportService,
      paramsCreate,
      utils: this.utils,
      success,
      isLoadingDisplayed:
        this.showMultipleCards &&
        Number(this.selectCardSegment()) !== SelectCardsOptionsReport.Guided,
    });

    if (success) {
      this.modalCtrl.dismiss({ create: true, success: true });
    }
  }

  async editReport(paramsCreate: CreateReportParams) {
    const success = signal(true);

    const paramsEdit: EditReportParams = {
      reportID: this.reportId,
      enable: this.reportEnable,
      ...paramsCreate,
    };

    await editReport({
      company: this.company,
      _loadingService: this._loadingService,
      _reportService: this._reportService,
      params: paramsEdit,
      utils: this.utils,
      success,
      isLoadingDisplayed:
        this.showMultipleCards &&
        Number(this.selectCardSegment()) !== SelectCardsOptionsReport.Guided,
    });

    if (success()) {
      this.modalCtrl.dismiss({ edit: true, success: true });
    }
  }

  needValidateCardsByText() {
    return (
      this.showerStep[StepCreateEditReport.Name] &&
      this.showMultipleCards &&
      Number(this.selectCardSegment()) === SelectCardsOptionsReport.Text &&
      !this._cardsByTextReportService.isValidCardText()
    );
  }

  get filteredEmails() {
    let filteredEmails = this.emails.filter((email) => email.Enable === 1);
    return filteredEmails;
  }

}
