import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Service
import { SkinMangementService } from '../../../../../../services/skin-mangement.service';
import { ColorModeService } from 'src/app/services';

// Models
import { CardNames, Company } from 'src/app/models/';


@Component({
  selector: 'app-created-interval-modal',
  templateUrl: './created-interval-modal.component.html',
  styleUrls: ['./created-interval-modal.component.scss'],
})
export class CreatedIntervalModalComponent implements OnInit {

  company: Company;
  colorClearButton = 'light'

  @Input() notCreated: string[] = []

  cardNames = CardNames;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
    private _colorModeService: ColorModeService,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.colorClearButton = this._colorModeService.getColorButtonClear()
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
