import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform, } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Component
import { FilterClientModalComponent } from './filter-client-modal/filter-client-modal.component';

// Service
import { ColorModeService, SkinMangementService } from '../../../services/';
import { VisibilitySelectService } from '../../services/';
import { FilterTillService } from '../filter-till/service/filter-till.service';
import { ClientFilterService } from './service/client-filter.service';

// Models
import { Company } from '../../../models';

// Utils
import { pcWidth, pcWidthMix, type } from 'src/app/utils/constants';


@Component({
  selector: 'app-filter-client',
  templateUrl: './filter-client.component.html',
  styleUrls: ['./filter-client.component.scss'],
})
export class FilterClientComponent implements OnInit {

  // modificar filtro de till
  @Input() changeTillFilter = false;

  // filtrar por grupo operador y operador
  @Input() useFilter = false;

  @Input() showCodeClient = true;

  @Input() pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;

  // aspecto
  company: Company;
  type = type;

  code = '';
  name = '';
  idClient = null;
  idClientPrev = null;

  clientChange$: Subscription;
  filterChange$: Subscription;

  constructor(
    private _skinService: SkinMangementService,
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private _clientFilterService: ClientFilterService,
    private _tillFilterService: FilterTillService,
    private _visibilityService: VisibilitySelectService,
    private _colorModeService: ColorModeService
  ) { }


  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.name = this._clientFilterService.getNameClient();
    this.code = this._clientFilterService.getCodeClient();
    this.idClient = this._clientFilterService.getClientId();

    this.clientChange$ = this._clientFilterService.filterChange.subscribe(res => {
      setTimeout(() => {
        this.name = res.name;
        this.code = res.code;
        this.idClient = res.idClient;
      }, 50)

    })


    if(this.useFilter){
      this.filterChange$ = this._visibilityService.filterChange.subscribe(res => {
        this._clientFilterService.setClient('', null, null);
        this._clientFilterService.clearFilter(true);
      })
    }

  }

  ngOnDestroy(): void {
    if(this.clientChange$) this.clientChange$.unsubscribe()
    if (this.filterChange$) this.filterChange$.unsubscribe()
  }


  async showFilterClient() {

    const isLightColor = this._colorModeService.getIsLightColor();

    let modal = await this.modalCtrl.create({
        component: FilterClientModalComponent,
        componentProps: {
          useFilter: this.useFilter
        },
        cssClass: (this.platform.width() > this.pcWidth ) ?
          `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
          : '',
      });
    await modal.present();


    const { data } = await modal.onWillDismiss();

    if (data) {
      let { code, name, idClient } = this._clientFilterService.getInfoClient();
      this.code = code;
      this.name = name;
      this.idClient = idClient;
      if(this.idClient != this.idClientPrev){
        this.idClientPrev = this.idClient;
        if(this.changeTillFilter){
          this._tillFilterService.changeFilter({clientId: this.idClient})
        }
      }
    }

  }


}
