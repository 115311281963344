
// models
import { ClientDashboardWelcome } from "../../models";

// constant
import { EXTRA_INFO_CLIENTS, TILL_HAVE_ALLIANCE, TILL_HAVE_ENRIQUE_TOMAS } from "../constants";

export const getSubCodeTillDashboardPrat = (client: ClientDashboardWelcome) => {

  if(!client){
    return null;
  }

  if((client?.description || '').toLowerCase() !== EXTRA_INFO_CLIENTS[0].toLowerCase()){
    return null;
  }

  if(client?.haveEnriqueTomas && !client?.haveAlliance){
    return TILL_HAVE_ENRIQUE_TOMAS;
  }

  if(!client?.haveEnriqueTomas && client?.haveAlliance){
    return TILL_HAVE_ALLIANCE;
  }

  return null;

}
