// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Component
import { FileInputComponent } from './file-input.component';

// Service
import { LanguageService } from '../../services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
  ],
  declarations: [
    FileInputComponent
  ],
  providers: [LanguageService],
  exports: [
    FileInputComponent
  ],
})
export class FileInputModule {}
