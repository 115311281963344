// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-sm {
  font-size: 1.3em;
}

.h-100 {
  height: 100%;
}

.ml-10 {
  margin-left: 10px;
}

.file-input {
  display: none;
}

.upload-btn {
  background-color: transparent !important;
}

.max-width100 {
  max-width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/aena/send-files-aena/components/send-files-aena-card/send-files-aena-card.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,wCAAA;AAAF;;AAGA;EACE,0BAAA;AAAF","sourcesContent":[".font-sm{\r\n  font-size: 1.3em;\r\n}\r\n\r\n.h-100{\r\n  height: 100%;\r\n}\r\n\r\n.ml-10{\r\n  margin-left: 10px;\r\n}\r\n\r\n\r\n.file-input {\r\n  display: none;\r\n}\r\n\r\n.upload-btn {\r\n  background-color: transparent !important;\r\n}\r\n\r\n.max-width100{\r\n  max-width: 100% !important;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
