const WEEK_DAY_ID_DB = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
  4: 5,
  5: 6,
  6: 7,
};

export const weekDayToWeekDayDB = (weekDay: string | number) => {

  if (
    !WEEK_DAY_ID_DB?.[Number(weekDay)] &&
    WEEK_DAY_ID_DB?.[Number(weekDay)] !== 0
  ) {
    console.error('Not exist WeekDayID');
    return 0;
  }

  return WEEK_DAY_ID_DB?.[Number(weekDay)];
};
