// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-indent-10 {
  text-indent: 10px;
}

.strong {
  font-weight: 600;
}

.block {
  display: block;
}

.border-dark {
  border: 1px solid var(--ion-color-medium);
  box-shadow: 0px 0px 17px 2px rgba(128, 128, 128, 0.8);
}

.color-dark {
  color: var(--ion-color-dark);
}

.ml-5 {
  margin-left: 5px;
}

.color-article::marker {
  color: var(--color-marker-company);
}

.color-article {
  margin-left: 0;
  padding-left: 0px;
}

.no-margin {
  margin-left: 0;
  padding-left: 20px;
  list-style-type: square;
}

.display-inline {
  display: inline;
}

.m-summary-icon {
  margin-left: 5px;
  margin-right: 5px;
}

.rounded {
  border-radius: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.title-card {
  font-size: 1.8em;
}

.font-text-group {
  font-size: 1.5em;
}

.font-text {
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .title-card {
    font-size: 1.2em;
  }
  .font-text-group {
    font-size: 1.1em;
  }
  .font-text {
    font-size: 1em;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/legends/subsidies-manager-legend/subsidies-manager-legend.component.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;AAAF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,cAAA;AAFF;;AAKA;EACE,yCAAA;EACA,qDAAA;AAFF;;AAKA;EACE,4BAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,kCAAA;AAFF;;AAKA;EACE,cAAA;EACA,iBAAA;AAFF;;AAKA;EACE,cAAA;EACA,kBAAA;EACA,uBAAA;AAFF;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,gBAAA;EACA,iBAAA;AAFF;;AAKA;EACE,mBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAMA;EACE;IACE,gBAAA;EAHF;EAMA;IACE,gBAAA;EAJF;EAOA;IACE,cAAA;EALF;AACF","sourcesContent":["\r\n.text-indent-10 {\r\n  text-indent: 10px;\r\n}\r\n\r\n\r\n\r\n.strong {\r\n  font-weight: 600;\r\n}\r\n\r\n.block {\r\n  display: block;\r\n}\r\n\r\n.border-dark {\r\n  border: 1px solid var(--ion-color-medium);\r\n  box-shadow: 0px 0px 17px 2px rgb(128, 128, 128, 0.8);\r\n}\r\n\r\n.color-dark {\r\n  color: var(--ion-color-dark)\r\n}\r\n\r\n.ml-5 {\r\n  margin-left: 5px;\r\n}\r\n\r\n.color-article::marker {\r\n  color: var(--color-marker-company);\r\n}\r\n\r\n.color-article {\r\n  margin-left: 0;\r\n  padding-left: 0px;\r\n}\r\n\r\n.no-margin {\r\n  margin-left: 0;\r\n  padding-left: 20px;\r\n  list-style-type: square;\r\n}\r\n\r\n.display-inline{\r\n  display: inline;\r\n}\r\n\r\n.m-summary-icon{\r\n  margin-left: 5px;\r\n  margin-right: 5px;\r\n}\r\n\r\n.rounded{\r\n  border-radius: 20px\r\n}\r\n\r\n.mt-25{\r\n  margin-top: 25px;\r\n}\r\n\r\n.title-card{\r\n  font-size: 1.8em;\r\n}\r\n\r\n.font-text-group {\r\n  font-size: 1.5em;\r\n}\r\n\r\n.font-text {\r\n  font-size: 1.2em;\r\n}\r\n\r\n\r\n@media (max-width: 768px) {\r\n  .title-card{\r\n    font-size: 1.2em;\r\n  }\r\n\r\n  .font-text-group {\r\n    font-size: 1.1em;\r\n  }\r\n\r\n  .font-text {\r\n    font-size: 1em;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
