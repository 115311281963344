// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-invert-svg {
  filter: brightness(0) invert(1) contrast(100%);
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/legend/legend.component.scss"],"names":[],"mappings":"AAAA;EAEE,8CAAA;AACF","sourcesContent":[".color-invert-svg{\r\n  -webkit-filter: brightness(0) invert(1);\r\n  filter: brightness(0) invert(1) contrast(100%);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
