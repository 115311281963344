
import { Pipe, PipeTransform, Injectable, NgModule, Component, InjectionToken, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AbstractControl } from '@angular/forms';
import { randomNumber } from './utils';


export const getRandomString = (minLength: number, maxLength?: number) => {
  let charValids = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  let numElements = minLength;
  if(maxLength){
    numElements = randomNumber(minLength, maxLength)
  }
  for (let i = 0; i < numElements; i++) {
    result += charValids.charAt(Math.floor(Math.random() * charValids.length));
  }
  return result;
}

export class NavMock {
    public navigateBack: Function = (url: string | any[], options: any) => {

    };
    public navigateForward: Function = (url: string | any[], options: any) => {

    };
    public navigateRoot: Function = (url: string | any[], options: any) => {

    };
}

export const valueStorage = 'As2342fAfgsdr';

export const storageIonicMock: any = {
    get: () => new Promise<any>((resolve, reject) => resolve(valueStorage)),
    set: () => new Promise<any>((resolve, reject) => resolve(valueStorage)),
};


export const languageServiceMock: any = {
    setLanguage: (language) => { },
    getLanguage: () => { return 'es-ES' },
    getImage: () => { return '../image.png' },
    getImagesArray: () => { return ['../image.png', '../image2.png'] },
    getAlt: () => { return ['image1', 'image2'] },
    startLanguageAsync: () => { }
}

export const translateServiceMock: any = {
    setDefaultLang: () => { },
    get(key: any): any {
        of(key);
    },
    getBrowserLang: () => {
        return 'de';
    },
    onLangChange: of({

    }),
}

export const loadingServiceMock: any = {
    present: async () => { return true },
    dismiss: async () => { return false }
}

export class LoadingMock {

    public create(): any {
        return new LoadingMock;
    }

    public dismiss(): any { }

    public present(): any { }
}


export const LOCATION_TOKEN = new InjectionToken<Location>('Window location object');


// class MockPlatform {
//     ready: jasmine.Spy<any>;
//     backButton: any;
// }

@Pipe({ name: 'translate' })
export class MockPipe implements PipeTransform {
    transform(value: string): string {
        return value;
    }
}

@Pipe({ name: 'versionTill' })
export class VersionTillPipeMock implements PipeTransform {
    transform(value: string): string {
        return value;
    }
}

@Pipe({ name: 'numberLanguage' })
export class NumberLanguagePipeMock implements PipeTransform {
    transform(value: number, locale: string, decimals: number = 0, coin: string = ''): string {
        if(!value) value = 0
        return `${value.toFixed(2)}${coin}`;
    }
}


@Pipe({
    name: 'date',
    pure: false
})
export class DatePipeMock implements PipeTransform {
    name: string = 'date';

    transform(query: string, ...args: any[]): any {
        return query;
    }
}

@Pipe({ name: 'paginate' })
export class PaginatePipeMock implements PipeTransform {
    transform(text: string, config: string): string {
        return text;
    }
}

@Pipe({ name: 'typeLanguage' })
export class TypeLanguagePipeMock implements PipeTransform {
    transform(text: string, translates: {[name: string]: string}): string {
        return text;
    }
}



@Component({
    selector: 'app-todo-item',
    template: ''
})
export class fakeComponent {

}

// fake components
@Component({
    selector: 'app-todo-item1',
    template: ''
})
export class fakeComponent1 {

}

@Component({
    selector: 'app-todo-item2',
    template: ''
})
export class fakeComponent2 {

}

@Component({
    selector: 'pdf-viewer',
    template: ''
})
export class fakePDFViewer {

}


@Component({
    selector: 'app-todo-item3',
    template: ''
})
export class fakeComponent3 {

}

@Component({
    selector: 'app-date-period',
    template: ''
})
export class datePeriodFake {
  @Input() showHour: boolean = true;
}

export const apkSize = 300;
export const pcSize = 630;

export const languageRouteServiceMock: any = {
    subjectNewTest: of({}),
}

export const sendFilterMock: any = {
    changeFilter: (any) => {},
    delete: (any) => {},

    typeDelete:{
     subscribe: () => of({}),
     next: (value) => {},
    },
    resetFilter: {
        subscribe: () => of({}),
        next: (value) => {},
    },
    filterValues: of({value: "value1"})
}


export const mockActivatedRouteSnapshot = {
    url: '/test',
    root: null,
    _data: null
}

// @Injectable()
// export class TranslateServiceStub {
//   public get<T>(key: T): Observable<T> {
//     return of(key);
//   }
// }

// class FakeLoader implements TranslateLoader {
//     getTranslation(lang: string): Observable<any> {
//         return of(translations);
//     }
// }
