// models
import { CardNames, Company } from 'src/app/models';
import { InvalidCardReport } from '../../models';

export const getTitleInvalidCardByTextReport = (
  company: Company,
  invalidCardsByText: InvalidCardReport
) => {
  const moreOneCard: boolean =
    (invalidCardsByText?.dupplicateCards || []).length > 1 ||
    (invalidCardsByText?.notExistCards || []).length > 1;
  if (company.nameCard === CardNames.arbitradePay) {
    return moreOneCard
      ? 'reports.invalid_cards_by_text.duplicate_alliances'
      : 'reports.invalid_cards_by_text.duplicate_alliance';
  } else {
    return moreOneCard
      ? 'reports.invalid_cards_by_text.duplicate_close_loop_cards'
      : 'reports.invalid_cards_by_text.duplicate_close_loop_card';
  }
};
