// models
import { Client, Operator } from 'src/app/models';
import { ClientDashboardWelcome } from '../../models';

// constant
import { EXTRA_INFO_CLIENTS, getOperatorGroupIdByClient } from '..';

export const startExtraInfoClientWithVisibilityClient = (
  client: Client,
  operators: Operator[]
): ClientDashboardWelcome => {
  return {
    ...client,
    haveAlliance: EXTRA_INFO_CLIENTS.includes(client?.description),
    haveEnriqueTomas: EXTRA_INFO_CLIENTS.includes(client?.description),
    operatorGroupId: getOperatorGroupIdByClient(
      operators,
      client.OperatorId
    ),
  };
};
