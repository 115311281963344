// models
import { TypeMachineAlertOptions } from '../models';

// constant
import { DEFAULT_HOUR_TYPE_ALERT_MACHINE_ALERT } from './constant';

export const getDefaultHourByTypeMachineAlert = (
  isAena: boolean,
  typeAlert: TypeMachineAlertOptions
) => {
  if (typeAlert === TypeMachineAlertOptions.Connectivity) {
    return DEFAULT_HOUR_TYPE_ALERT_MACHINE_ALERT.connectivity;
  }

  if (isAena) {
    return DEFAULT_HOUR_TYPE_ALERT_MACHINE_ALERT.innactivity.isAena;
  }

  return DEFAULT_HOUR_TYPE_ALERT_MACHINE_ALERT.innactivity.notIsAena;
};
