import { WritableSignal } from '@angular/core';

// models
import { RouteEditable, RouteInfoRM } from '../../../models';

// utils
import { UtilsTranslate } from 'src/app/utils';
import { isValidCreateRouteManager } from '../create';

interface IsValidEditRouteManager {
  routeSelected: WritableSignal<RouteInfoRM>;
  routeEditable: WritableSignal<RouteEditable>;
  haveOperatorVisibility: boolean;
  utils: UtilsTranslate;
}

export const isValidEditRouteManager = ({
  routeSelected,
  routeEditable,
  haveOperatorVisibility,
  utils,
}: IsValidEditRouteManager): boolean => {

  if (!routeSelected()?.routeId && routeSelected()?.routeId !== 0) {
    utils.presentToastTranslate('route_manager.create_edit.error.select_route')
    return false;
  }

  if (
    !isValidCreateRouteManager({
      routeEditableCreate: routeEditable,
      haveOperatorVisibility,
      utils,
    })
  ) {
    return false;
  }

  return true;
};
