import {
  CharacteristicsTillAlertSpider,
  CharacteristicsTillAlertSpiderWithExtra,
} from '../models';

export const getCharacteristicsIdByDescription = (
  selectedDescription: string,
  characteristics:
    | CharacteristicsTillAlertSpider[]
    | CharacteristicsTillAlertSpiderWithExtra[]
): number => {
  const characteristicSelected = characteristics.find(
    (characteristic) => characteristic.description === selectedDescription
  );

  return characteristicSelected?.id;
};
