import { WritableSignal } from '@angular/core';

// models
import { DatePickerFilterOptions } from 'src/app/share/components/filter-date-template/models';
import { PeriocityOptionReport } from '../../models/enums';

// services
import { FilterDateServiceI } from 'src/app/models';

// utils
import { applyChangeTypeReportWeekly } from './apply-change-type-weekly';
import { applyChangeTypeReportMonthly } from './apply-change-type-monthly';

interface ApplyChangeTypeReport {
  periodSelected: PeriocityOptionReport;
  datePickerFilter: WritableSignal<DatePickerFilterOptions>;
  _filterDateService: FilterDateServiceI,
}

export const applyChangeTypeReport = ({
  periodSelected,
  datePickerFilter,
  _filterDateService
}: ApplyChangeTypeReport) => {

  const CHANGE_TYPE_REPORT: Record<number, Function> = {
    [PeriocityOptionReport.Monthly]: () =>
      // not use filter month, because use other type date selected
      // applyChangeTypeReportMonthly({
      //   datePickerFilter,
      //   _filterDateService
      // }),
      applyChangeTypeReportWeekly({
        datePickerFilter,
        _filterDateService
      }),
    [PeriocityOptionReport.Weekly]: () =>
      applyChangeTypeReportWeekly({
        datePickerFilter,
        _filterDateService
      }),
  };

  if (CHANGE_TYPE_REPORT?.[periodSelected]) {
    CHANGE_TYPE_REPORT[periodSelected]();
  } else {
    datePickerFilter.set(null);
  }
};
