import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ComponentProps, ComponentRef, ModalOptions } from '@ionic/core';

// services
import { ColorModeService } from '../services/color-mode.service';

// utils
import { pcWidth } from './constants';

interface ShowModal {
  component: ComponentRef;
  componentProps: ComponentProps<ComponentRef>;
  cssClass: string;
  backdropDismiss?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ModalGenerator {
  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private _colorModeService: ColorModeService
  ) {}

  async show(
    component: ComponentRef,
    componentProps: ComponentProps<ComponentRef> = {}
  ) {
    // const isLightColor = this._colorModeService.getIsLightColor();
    // const width = this.platform.width();

    // const cssClass =
    //   width > pcWidth
    //     ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
    //     : '';

    const cssClass = this.getBasicStyleModal();
    return await this.showModal({
      component,
      componentProps,
      cssClass,
      backdropDismiss: true,
    });
  }

  async showWithCss(
    component: ComponentRef,
    componentProps: ComponentProps<ComponentRef> = {},
    cssClass: string = ''
  ) {
    return await this.showModal({
      component,
      componentProps,
      cssClass,
      backdropDismiss: true,
    });
  }

  async showModalNotClickOutisde(
    component: ComponentRef,
    componentProps: ComponentProps<ComponentRef> = {}
  ) {
    const cssClass = this.getBasicStyleModal();
    return await this.showModal({
      component,
      componentProps,
      cssClass,
      backdropDismiss: false,
    });
  }

  private async showModal({
    component,
    componentProps,
    cssClass,
    backdropDismiss,
  }: ShowModal) {
    let modal = await this.modalCtrl.create({
      component,
      componentProps,
      cssClass,
      backdropDismiss,
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();

    return data;
  }

  private getBasicStyleModal() {
    const isLightColor = this._colorModeService.getIsLightColor();
    const width = this.platform.width();

    const cssClass =
      width > pcWidth
        ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
        : '';

    return cssClass;
  }

  async showLargeModal(
    component: ComponentRef,
    componentProps: ComponentProps<ComponentRef> = {}
  ) {
    const isLightColor = this._colorModeService.getIsLightColor();

    const styleModal =
      this.platform.width() > pcWidth
        ? `modal-wrapper2 modal-border-radius ${
            !isLightColor ? 'modal-border-dark' : ''
          }`
        : 'modal-wrapper2';

    return await this.showWithCss(component, componentProps, styleModal);
  }
}
