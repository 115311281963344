import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonContent } from '@ionic/angular';

// Service
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from '../../../services/languageRoute.ts/languagesRoute.services';
import { SkinMangementService } from '../../../../services/skin-mangement.service';

// Models
import { Company } from '../../../../models/';
import { Client } from 'src/app/models';
import { CarouselType } from '../../carousel-filter-guided/models/carousel-type.type';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-modal-select-client',
  templateUrl: './modal-select-client.component.html',
  styleUrls: ['./modal-select-client.component.scss'],
})
export class ModalSelectClientComponent implements OnInit {

  // aspecto
  company: Company;

  carouselElements: CarouselType[] = ['Group', 'Operator', 'Client']

  titleHeader = 'Seleccionar';

  titleGroupTranslate = '';
  titleOperatorTranslate = ''
  titleClientTranslate = '';

  positionY = 0
  positionX = 0

  changeLanguage$: Subscription;

  @ViewChild(IonContent, { static: false }) content: IonContent;

  constructor(
    private _skinService: SkinMangementService,
    private modalCtrl: ModalController,
    private _languageRouteService: LanguageRouteService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });
  }

  ngOnDestroy(): void {
    if(this.changeLanguage$) this.changeLanguage$.unsubscribe
  }

  clientSelected(client: Client) {
    if (this.modalCtrl) {
      this.modalCtrl.dismiss({
        client
      });
    }
  }

  changeTittle(carouselSelected: string) {

    if(carouselSelected == 'Group'){
      this.titleHeader = this.titleGroupTranslate
    }else if(carouselSelected == 'Operator'){
      this.titleHeader = this.titleOperatorTranslate
    }else if(carouselSelected == 'Client'){
      this.titleHeader = this.titleClientTranslate
    }else{
      this.titleHeader = 'Select'
    }
  }

  closeModal() {
    if (this.modalCtrl) {
      this.modalCtrl.dismiss({});
    }
  }

  savePlaceholders() {
    this.translate.get(['select_operator_group','select_operator','select_client'])
      .subscribe((translates) => {


        this.titleGroupTranslate = translates['select_operator_group'];
        this.titleOperatorTranslate = translates['select_operator'];
        this.titleClientTranslate = translates['select_client'];

      });
  }

  moveUp(){
    this.content.scrollToPoint(0,this.positionY-100);
  }

  async changeScroll(event){

    const scrollElement = await event.target.getScrollElement();

    this.positionX = scrollElement.scrollWidth
    this.positionY = scrollElement.scrollHeight - scrollElement.clientHeight;

  }

}
