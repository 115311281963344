import {
  Component,
  Input,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { timeReload } from 'src/app/utils';

@Component({
  selector: 'app-filter-template-select-elem-by-list-share',
  templateUrl: './filter-template-select-elem-by-list-share.component.html',
  styleUrls: ['./filter-template-select-elem-by-list-share.component.scss'],
})
export class FilterTemplateSelectElemByListShareComponent implements OnInit {
  @Input() company: Company;

  @Input() elements: any[];
  @Input() nameSearchElement: string = '';
  @Input() nameId: string = '';

  @Input() elementsFilter: WritableSignal<any[]> = signal([]);
  @Input() elementsShower: WritableSignal<any[]> = signal([]);
  @Input() elementSelected: WritableSignal<any>;

  @Input() numElements = 50;

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor() {}

  ngOnInit() {}

  changeElementSelected(event) {
    this.elementSelected.set(event);
  }

  loadDataHandle() {
    setTimeout(() => {
      if (this.elementsShower().length >= this.elementsFilter().length) {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      let newElements = [];
      if (
        this.elementsFilter().length <
        this.elementsShower().length + this.numElements
      ) {
        newElements = this.elementsFilter().slice(
          this.elementsShower().length,
          this.elementsFilter().length
        );
      } else {
        newElements = this.elementsFilter().slice(
          this.elementsShower().length,
          this.elementsShower().length + this.numElements
        );
      }

      this.elementsShower.set(this.elementsShower().concat(newElements));

      this.inifiteScroll.complete();
    }, timeReload);
  }
}
