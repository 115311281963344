import {
  Component,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

// models
import { Client, Company } from 'src/app/models';
import {
  MultipleMachineSelected,
  ClientMultipleMachineOrOneMachineShare,
  PositionVisibilityMultipleMachineOneMachineShare,
  SelectClientMultipleMachineModalOutputs,
} from '../models';

// utils
import { convertNumber } from '../../../../utils/utils';

@Component({
  selector: 'app-select-client-multiple-machine-modal',
  templateUrl: './select-client-multiple-machine-modal.component.html',
  styleUrls: ['./select-client-multiple-machine-modal.component.scss'],
})
export class SelectClientMultipleMachineModalComponent implements OnInit {
  @Input() company: Company;

  @Input() tills: WritableSignal<MultipleMachineSelected[]>;
  @Input() tillsSelected: WritableSignal<MultipleMachineSelected[]>;
  @Input() clients: Client[];
  @Input() machineOneSelected: MultipleMachineSelected = null;
  @Input() segment: ClientMultipleMachineOrOneMachineShare =
    ClientMultipleMachineOrOneMachineShare.clientMultipleMachine;

  tillsFiltered: MultipleMachineSelected[] = [];

  tillsByClient: MultipleMachineSelected[] = [];

  clientSelected: Client;

  positionVisibility = PositionVisibilityMultipleMachineOneMachineShare.client;

  PositionClientMultipleMachineOrOneMachineShare = ClientMultipleMachineOrOneMachineShare;
  PositionVisibilityMultipleMachineOneMachineShare = PositionVisibilityMultipleMachineOneMachineShare;

  convertNumber = convertNumber;

  masterCheck: WritableSignal<boolean> = signal(true);
  isIndeterminate: WritableSignal<boolean> = signal(false);
  eventSelectAll: Subject<{
    masterCheck: boolean;
    clientId: number;
  }> = new Subject<{ masterCheck: boolean; clientId: number }>();

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  goPosition(
    positionVisibility: PositionVisibilityMultipleMachineOneMachineShare
  ) {
    this.positionVisibility = positionVisibility;
  }

  changeClient(client) {
    if (!client) {
      return;
    }
    this.clientSelected = client;
    const tillByClient = this.tills().filter(
      (till) => till.clientId === this.clientSelected.ID
    );
    this.tillsByClient = tillByClient;
    this.positionVisibility =
      PositionVisibilityMultipleMachineOneMachineShare.multipleMachine;
  }

  selectAll() {
    this.eventSelectAll.next({
      masterCheck: this.masterCheck(),
      clientId: this.clientSelected?.ID,
    });
  }

  changeOneMachine(machine) {
    if (!machine) {
      return;
    }

    const tillSelected = this.tills().find((till) => till.id === machine.id);
    if (tillSelected) {
      tillSelected.isSelected = true;
    }
    const tillSelectedByClient = this.tillsByClient.find(
      (till) => till.id === machine.id
    );
    if (tillSelectedByClient) {
      tillSelectedByClient.isSelected = true;
    }

    this.machineOneSelected = machine;

    // this.changeOneMachineSelected.emit();
  }

  applyFilter() {
    const isMultipleMachine =
      this.segment ===
      ClientMultipleMachineOrOneMachineShare.clientMultipleMachine;

    const selectInfo: SelectClientMultipleMachineModalOutputs = {
      info: {
        isMultipleMachine,
        tillOneSelected: this.machineOneSelected,
        tillsSelected: this.tillsSelected(),
      },
    };

    this.modalCtrl.dismiss(selectInfo)
  }

  deleteMachines() {
    const deleteInfo: SelectClientMultipleMachineModalOutputs = { clear: true };
    this.modalCtrl.dismiss(deleteInfo);
  }
}
