import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

// Service
import { FilterUserCardsService } from './service/filter-user-cards.service';

// Model
import { Card } from 'src/app/models/Card.model';
import { Subscription } from 'rxjs';

// TODO mover a transaccion
@Component({
  selector: 'app-filter-user-cards',
  templateUrl: './filter-user-cards.component.html',
  styleUrls: ['./filter-user-cards.component.scss'],
})
export class FilterUserCardsComponent implements OnInit {

  @Input() cards: Card[] = [];

  cardSelected: string = null;

  filterChange$: Subscription;

  @Output() changeCardSelected = new EventEmitter<string>();

  constructor(
    private _filterService: FilterUserCardsService
  ) {}

  ngOnInit() {
    this.cardSelected = this._filterService.getCardNum();

    this.filterChange$ = this._filterService.filterChange$.subscribe((res) => {
      this.cardSelected = res.cardNum;
    });
  }

  ngOnDestroy(): void {
    if(this.filterChange$ ) this.filterChange$.unsubscribe()
  }

  changeCard() {
    this._filterService.setCard(this.cardSelected, this.cardSelected);
    this.changeCardSelected.emit(this.cardSelected);
  }
}
