// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block {
  display: block;
}

.indent-3 {
  text-indent: 3%;
}

table {
  text-align: center;
  align-items: center;
  width: 100%;
  border-collapse: collapse;
}

th {
  color: var(--ion-color-light);
  font-weight: bold;
  text-align: center;
}

td,
th {
  padding: 6px;
  border: 1px solid var(--ion-color-light-table-th);
  text-align: left;
  text-align: center;
}

.h-60 {
  min-height: 60px;
}

.h-header-60 {
  height: 60px;
}

.mt-20 {
  margin-top: 20px;
}

.color-link-table {
  color: #1034A6;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login/components/cookies-policy/cookies-policy.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,yBAAA;AACF;;AAEA;EACE,6BAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;;EAEE,YAAA;EACA,iDAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".block{\r\n  display: block;\r\n}\r\n\r\n.indent-3{\r\n  text-indent: 3%;\r\n}\r\n\r\ntable {\r\n  text-align: center;\r\n  align-items: center;\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n}\r\n\r\nth {\r\n  color: var(--ion-color-light);\r\n  font-weight: bold;\r\n  text-align: center;\r\n}\r\n\r\ntd,\r\nth {\r\n  padding: 6px;\r\n  border: 1px solid  var(--ion-color-light-table-th);\r\n  text-align: left;\r\n  text-align: center;\r\n}\r\n\r\n.h-60{\r\n  min-height: 60px;\r\n}\r\n\r\n.h-header-60{\r\n  height: 60px;\r\n}\r\n\r\n.mt-20{\r\n  margin-top: 20px;\r\n}\r\n\r\n.color-link-table{\r\n  color: #1034A6;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
