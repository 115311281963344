// External Module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

// Modules
import { FilterClientModule } from '../filter-client/filter-client.module';
import { VisibilitySelectModule } from '../visibility-select/visibility-select.module';
import { HeaderModalModule } from '../header-modal/header-modal.modal';

// Components
import { VisibilityClientTillSelectComponent } from './visibility-client-till-select.component';
import { VisibilityClientTillSelectPcComponent } from './visibility-client-till-select-pc/visibility-client-till-select-pc.component';
import { VisibilityClientTillSelectSmComponent } from './visibility-client-till-select-sm/visibility-client-till-select-sm.component';

// Services
import { LanguageService } from '../../services/languages.service';

@NgModule({
    declarations: [
      VisibilityClientTillSelectComponent,
      VisibilityClientTillSelectPcComponent,
      VisibilityClientTillSelectSmComponent,
    ],
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      ReactiveFormsModule,
      SwiperModule,
      VisibilitySelectModule,
      FilterClientModule,
      TranslateModule,
      HeaderModalModule,
    ],
    providers: [
      LanguageService,
    ],
    exports: [
      VisibilityClientTillSelectComponent
    ]
  })
  export class VisibilityClientTillSelectModule { }
