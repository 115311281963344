// models
import { TillStatus } from '../../share/models';
import { MachineStatusSelected } from '../models';

// services
import { FilterClientTillService } from 'src/app/share/services';

interface GetMachineStatusSelected {
  _filterClientTillService: FilterClientTillService;
  tillIdSelected: number;
  tillStatus: TillStatus;
}

export const getMachineStatusSelected = ({
  _filterClientTillService,
  tillIdSelected,
  tillStatus,
}: GetMachineStatusSelected): MachineStatusSelected => {
  const tillId = getTillIdSelected(_filterClientTillService, tillIdSelected);
  if (tillId || tillId === 0) {
    return { clientIdMachineStatus: null, tillIdMachineStatus: tillId };
  }

  const clientId = getClientIdSelected(_filterClientTillService, tillStatus);
  if (clientId || clientId !== 0) {
    return { clientIdMachineStatus: clientId, tillIdMachineStatus: null };
  }

  return { clientIdMachineStatus: null, tillIdMachineStatus: null };
};

const getTillIdSelected = (
  _filterClientTillService: FilterClientTillService,
  tillIdSelected: number
) => {
  const tillId = _filterClientTillService.getTillId();
  if (tillId || tillId === 0) {
    return tillId;
  }

  return tillIdSelected;
};

const getClientIdSelected = (
  _filterClientTillService: FilterClientTillService,
  tillStatus: TillStatus
) => {
  const clientId = _filterClientTillService.getClientId();

  if (clientId || clientId === 0) {
    return clientId;
  }

  return tillStatus?.client?.clientID;
};
