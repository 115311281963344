import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription, lastValueFrom } from 'rxjs';

// Components
import { InformationModalComponent } from '../information-modal/information-modal.component';

// Service
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService, ColorModeService } from '../../../../../../../services';
import { LanguageRouteService, VisibilitySelectService } from '../../../../../../../share/services';
import { WebloadTransferService, FilterWebloadService } from '../../../share/services';

// Models
import { CardNames, Company } from '../../../../../../../models/';

// Utils
import { type, pcWidth, pcWidthMix, UtilsTranslate } from '../../../../../../../utils';

@Component({
  selector: 'app-load-credit-card',
  templateUrl: './load-credit-card.component.html',
  styleUrls: ['./load-credit-card.component.scss'],
})
export class LoadCreditCardComponent implements OnInit {

  pcWidthMix = pcWidthMix;
  pcWidth = pcWidth;

  @Input() subscriptions: number[] = [];
  @Input() route: string = 'cards-webload'
  @Input() screenSm: boolean = false
  @Output() changeAmount= new EventEmitter<number>();


  company: Company;
  type = type;

  locale = 'es-ES';

  cardNumber: string =  null;
  amount: number = null;
  currency: string = ''

  loadingCard = false;

  isLightColor: boolean = true;

  cardNames = CardNames;

  changeLanguage$: Subscription;
  filterChange$: Subscription;
  changeColor$: Subscription;

  constructor(
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _filterService: FilterWebloadService,
    private _visibilitySelectService:VisibilitySelectService,
    private _webloadService: WebloadTransferService,
    private _colorModeService: ColorModeService,
    private utils: UtilsTranslate,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    // language
    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });

    this.cardNumber = this._filterService.getCardNum();

    this.filterChange$  = this._filterService.filterChange.subscribe(res => {
      if (res?.reset) {
        this.cardNumber = this._filterService.getCardNum();
        this.amount = this._filterService.getAmount();
        this.currency = this._visibilitySelectService.getCurrency()
      }else{
        this.cardNumber = this._filterService.getCardNum();
        this.currency = this._visibilitySelectService.getCurrency()
      }
    })

    this.getColors();


  }

  ngOnDestroy(): void {
    if(this.changeLanguage$ ) this.changeLanguage$.unsubscribe()
    if(this.filterChange$) this.filterChange$.unsubscribe()
    if(this.changeColor$) this.changeColor$.unsubscribe()
  }

  getColors() {
    this.isLightColor = this._colorModeService.getIsLightColor();

    this.changeColor$ = this._colorModeService.changeColor$.subscribe(
      (isLightColor) => (this.isLightColor = isLightColor)
    );
  }

  async clickChangeAmount(amount){


    const cardId = this._filterService.getCardId()
    let card = null;
    this.loadingCard = true;
    try{
      card = await lastValueFrom(this._webloadService.getCardById(cardId))
      this._filterService.setAmount(amount)
      this.loadingCard = false;
      let modal = await this.modalCtrl.create({
        component: InformationModalComponent,
        componentProps: {
          card,
          amount,
          company: this.company,
          currency: this.currency,
          locale: this.locale,
          subscriptions: this.subscriptions,
          route: this.route,
          screenSm: this.screenSm
        },
        cssClass: (this.platform.width() > this.pcWidth ) ?
          `modal-border-radius ${!this.isLightColor ? 'modal-border-dark': ''}` : '',
        //cssClass: 'modal-border-radius'
      });
      if (modal) {
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if(data?.updated){
          this.changeAmount.emit(amount)
        }

      }
    }catch(error){
      this.loadingCard = false;
      this.utils.presentToastTranslate('error', true, 0)
    }

  }

  savePlaceholders() {
    this.translate.get(['locale']).subscribe((translates) => {
      this.locale = translates['locale'];
    })
  }


}
