import { COLOR_TOOLTIP_LIGHT_DASHBOARD, COLOR_TOOLTIP_DARK_DASHBOARD } from '../constants';

const LIGHT_COLOR = COLOR_TOOLTIP_LIGHT_DASHBOARD;
const DARK_COLOR = COLOR_TOOLTIP_DARK_DASHBOARD;

export const getColorTooptipColor = (isLightColor: boolean) => {
  return {
    backgroundColor: isLightColor ? DARK_COLOR : LIGHT_COLOR,
    titleColor: isLightColor ? LIGHT_COLOR : DARK_COLOR,
    labelColor: isLightColor ? LIGHT_COLOR : DARK_COLOR,
  }
}
