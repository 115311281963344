import { DeleteTillAlertParams } from '../../../models';

interface GetParamsDeleteTillAlert {
  alertId: number;
  companyId: number;
  operatorGroupId: number;
  operatorId: number;
  clientId: number;
  machineId: number;
}

export const getParamsDeleteTillAlert = ({
  alertId,
  companyId,
  operatorGroupId,
  operatorId,
  clientId,
  machineId,
}: GetParamsDeleteTillAlert): DeleteTillAlertParams => {
  return {
    alertID: alertId,
    companyID: companyId,
    operatorGroupID: operatorGroupId,
    operatorID: operatorId,
    clientID: clientId,
    machineID: machineId,
  };
};
