import {
  Component,
  OnInit,
  Input,
  WritableSignal,
  signal,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ModalController, Platform } from '@ionic/angular';

// models
import { Company } from '../../../../../../../models';
import {
  FilterStatusOptionsReport,
  PeriodicityInfo,
  TypeReport,
  TypesSelectedReport,
  VisibilityReport,
} from '../../../models';

// services
import { FilterCardNumService } from '../../../../../../../share/components/filter-card-num/service/filter-card-num.service';
import { FilterReportService } from '../../../services';

// utils
import { closeTooltip, pcWidth } from 'src/app/utils';
import { PERIODICITIES_REPORTS } from '../../../utils';

@Component({
  selector: 'app-filters-report-modal',
  templateUrl: './filters-report-modal.component.html',
  styleUrls: ['./filters-report-modal.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.75s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.75s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class FiltersReportModalComponent implements OnInit {
  @Input() company: Company;

  @Input() selectedStatusPrev: FilterStatusOptionsReport = null;
  @Input() periodSelectedPrev: number = null;
  @Input() useFiterVisibility: boolean = true;
  @Input() useFilterTypeReport: boolean = false;

  @Input() typeReportFilteredSelected: number = null;
  @Input() typeReports: TypeReport[] = [];

  periocities: PeriodicityInfo[] = PERIODICITIES_REPORTS;

  selectStatus: WritableSignal<FilterStatusOptionsReport> = signal(null);
  periodSelected: WritableSignal<number> = signal(null);
  userVisibilityInModal: WritableSignal<boolean> = signal(true);

  useFilterTypeReportInModal: WritableSignal<boolean> = signal(false);
  typeReportsFiltered: TypeReport[] = [];
  typeReportSelected: WritableSignal<number> = signal(null);

  pcWidth = pcWidth;

  constructor(
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private _filterCardNumService: FilterCardNumService,
    private _filterReportService: FilterReportService
  ) {}

  ngOnInit() {
    closeTooltip();

    this.selectStatus.set(this.selectedStatusPrev);
    this.periodSelected.set(this.periodSelectedPrev);
    this.userVisibilityInModal.set(this.useFiterVisibility);
    this.useFilterTypeReportInModal.set(this.useFilterTypeReport);
    this.typeReportSelected.set(this.typeReportFilteredSelected);

    this.typeReportsFiltered = this.typeReports;

    if(this.periodSelected()){
      this.changeTypeReportByPeriodSelected()
    }

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  applyFilter() {
    const visibility: VisibilityReport = {
      operatorGroupId:
        this._filterReportService.getOperatorGroup(TypesSelectedReport.filter)
          ?.ID || null,
      operatorId:
        this._filterReportService.getOperator(TypesSelectedReport.filter)?.id ||
        null,
      clientId:
        this._filterReportService.getClient(TypesSelectedReport.filter)?.ID ||
        null,
      tillId:
        this._filterReportService.getTill(TypesSelectedReport.filter)?.id ||
        null,
      cardNum: this._filterCardNumService.getCardNum(0),
    };

    this.modalCtrl.dismiss({
      filtered: true,
      visibility,
      statusSelected: this.selectStatus(),
      periodSelected: this.periodSelected(),
      useFiterVisibility: this.userVisibilityInModal(),
      useFilterTypeReport: this.useFilterTypeReportInModal(),
      typeReportSelected: this.typeReportSelected()
    });
  }

  deleteFilter() {
    this._filterCardNumService.setCard('', null, 0);
    this._filterReportService.clear(TypesSelectedReport.filter);
    this.modalCtrl.dismiss({ delete: true });
  }

  changeUseFilterVisibility() {
    this.userVisibilityInModal.set(!this.userVisibilityInModal());
  }

  changeUseTypeReportFilterInModal() {
    this.useFilterTypeReportInModal.set(!this.useFilterTypeReportInModal());
  }

  changesStatus(event) {
    this.typeReportSelected.set(event);
  }

  changeTypeReportByPeriodSelected() {

    const periodicity = this.periocities.find(
      (periocity) => periocity.ID === this.periodSelected()
    );

    if (!periodicity) {
      this.typeReportSelected.set(null);
      this.useFilterTypeReportInModal.set(false);
      return;
    }

    this.typeReportsFiltered = this.typeReports.filter(
      (typeReport) => typeReport.Periodicity === periodicity.Periodicity
    );

    const typeReport = this.typeReports.find(
      (typeReport) => typeReport.ID === this.typeReportSelected()
    );

    if (typeReport?.Periodicity !== periodicity.Periodicity) {
      this.typeReportSelected.set(null);
    }

  }
}
