import { WritableSignal } from '@angular/core';

// models
import { Operator, Supervisor } from 'src/app/models';

interface SetSupervisorsByOperatorSelected {
  operatorSelected: WritableSignal<Operator>;
  supervisors: WritableSignal<Supervisor[]>;
  supervisorsByOperator: WritableSignal<Supervisor[]>;
}

export const setSupervisorsByOperatorSelected = ({
  operatorSelected,
  supervisors,
  supervisorsByOperator,
}: SetSupervisorsByOperatorSelected) => {
  if (!operatorSelected) {
    return;
  }

  const supervisorByOperatorSelected = supervisors().filter(
    (supervisor) => supervisor?.OperatorID === operatorSelected()?.id
  );
  supervisorsByOperator.set(supervisorByOperatorSelected);
};
