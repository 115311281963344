import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Material
import { MatDateRangePicker } from '@angular/material/datepicker';

// Services
import { Company, FilterDateServiceI } from 'src/app/models';
import { RangeDate } from '../../../../../models';

@Component({
  selector: 'app-datepicker-date-material-input-rocca',
  templateUrl: '../share/datepicker-date-material-input.component.html',
  styleUrls: ['./datepicker-date-material-input-rocca.component.scss'],
})
export class DatepickerDateMaterialInputRoccaComponent implements OnInit {
  @Input() company: Company;
  @Input() dateText: string = '';
  @Input() _filterDate: FilterDateServiceI;
  @Input() rangeDate: RangeDate;
  @Input() hideIconBySize: boolean = false;

  @Output() changeDate = new EventEmitter<Date>();

  datePicker: FormGroup;

  @ViewChild('picker') picker: MatDateRangePicker<any>;

  filterChange$: Subscription;

  minDate: Date;
  maxDate: Date;

  constructor(public readonly platform: Platform) {}

  ngOnInit() {
    this.datePicker = new FormGroup({
      start: new FormControl('', Validators.required),
    });

    this.setValueDatePicker();

    this.filterChange$ = this._filterDate.filterChange$.subscribe(() => {
      this.setValueDatePicker();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.rangeDate) {
      this.minDate = this.rangeDate.minDate;
      this.maxDate = this.rangeDate.maxDate;
    }
  }

  ngOnDestroy(): void {
    if (this.filterChange$) this.filterChange$.unsubscribe();
  }

  reOpenCalendar() {
    this.picker.open();
  }

  closed() {
    this.changeDate.emit(this.datePicker.get('start').value);
  }

  setValueDatePicker() {
    this.datePicker.controls.start.setValue(
      new Date(this._filterDate.getDateStart())
    );
  }
}
