
// models
import { EmailMachineAlert } from '../models';

// utils
import { validateEmail } from 'src/app/utils';

export const convertEmailsMachineAlert = (
  emails: string[]
): EmailMachineAlert[] => {
  if ((emails || []).length === 0) {
    return [];
  }

  let emailsMachineAlert: EmailMachineAlert[] = [];
  for (let email of emails) {
    emailsMachineAlert.push(convertEmailMachineAlert(email));
  }

  return emailsMachineAlert;
};

const convertEmailMachineAlert = (email: string): EmailMachineAlert => {
  return { email: email, isValid: validateEmail(email), focusOut: false };
};
