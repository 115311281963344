export interface HorecaQueryRoutesTranslate {
  horecaQuery: string,
  horecaDashboard: string
}

export const getHorecaQueryRoutes = (
  translates: HorecaQueryRoutesTranslate
): { route: string; name: string }[] => {
  return [
    { route: '/user/horeca/horeca-query', name: translates.horecaQuery },
    { route: '/user/horeca/horeca-dashboard', name: translates.horecaDashboard },
  ]
}
