import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AppConfig } from '../config/config';

// environments
import { alliance, V2vFactory, useApk, Rocca, TriangleSkin} from '../../environments/companySetting';

// Model
import { Company } from '../models/';

// utils
import { PORT_IONIC, isApkAndroid } from '../utils';


@Injectable({
  providedIn: 'root'
})
export class SkinMangementService {

  company: Company;

  companies: Company[] = [alliance, V2vFactory, Rocca, TriangleSkin]
  defaultCompany: Company = alliance;

  constructor(
    private platform: Platform
  ) {
    this.assignCompanySkin(window.location.hostname);
  }

  assignCompanySkin(hostname: string): any {

    if(useApk){
      this.company = useApk;
      return;
    }

    for(let company of this.companies){
      if(hostname == company.url || hostname == company.url_test || hostname == company.url_local){
        this.company = company;
        return;
      }
    }

    this.company = this.defaultCompany;

  }

  getCompany(){
    return this.company;
  }

  getOrigin(){
    if(isApkAndroid(this.platform)){
      return AppConfig[this.company.appConfig]
    }

    return ( window.location.hostname === 'localhost') ? `${window.location.hostname}:${PORT_IONIC}` :window.location.hostname;
  }

  getURLCallback(){

   if(isApkAndroid(this.platform)){
    return `localhost:${PORT_IONIC}`
   }

   return AppConfig[this.company.appConfig]
  }


}
