import { Injectable } from "@angular/core";

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { PermissionService } from "src/app/services";

// Utils
import { PermissionArticleManager, ARTICLE_MANAGER_ROUTE, NAME_ARTICLE_MANAGER_ROUTES }
  from '../utils/name-article-manager-routes';





@Injectable({providedIn: 'root'})
export class ArticleManagerResolverService {

    constructor(private _permissionService: PermissionService) {}

    resolve(): Observable<any>{
        const PermissionArticleManager: PermissionArticleManager =
          {plusInfo: false, productFrom0To99: false, downloadExcel: false}
          return this._permissionService.getAllPermissions().pipe(map((res)=> {
            const articleManagerRoute = res.find((permission)=> permission.route === ARTICLE_MANAGER_ROUTE)
            if(articleManagerRoute){
              return NAME_ARTICLE_MANAGER_ROUTES[articleManagerRoute?.name?.toString()];
            }
          })
        )
    }

}
