import { Component, OnInit } from '@angular/core';

// Service
import { SkinMangementService } from '../../../services';

// Models
import { Company } from '../../../models';

// Utils
import { type } from '../../../utils/';


@Component({
  selector: 'app-filter-menu-full',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
})
export class FilterMenuComponent implements OnInit {


  company: Company;
  type = type;

  language = 'es-Es';
  startDateFormat;
  endDateFormat;


  // translate
  pvpDeletedFilterTranslate = '';
  deleteClientFilterTranslate = '';
  deleteMachineFilterTranslate= '';
  deleteModelFilterTranslate = '';
  deleteFamilyFilterTranslate = '';
  deleteArticleTranslate = '';
  deleteCardNumFilterTranslate = '';
  allTranslate = '';

  constructor(private  _skinService: SkinMangementService) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  updateMenuState(status){
  }


}
