export const errorsTypes = {
  not_exist_email: 'No existe el usuario con ese email',
  not_exist_user: 'No existe el usuario del registro',
  user_already_registered: 'El usuario ya esta registrado',
  bad_request_reset_password: 'La url de la petición de password no es válida',
  expired_reset_password: 'La petición de password ha expirado',

  forcePassword_diferent_password:
    'El password anterior y el nuevo tienen que ser diferentes',
  force_password_actived:
    'El usuario no tiene activado la opción de forzar password',
  force_password_incorrect_crendentials:
    'El nombre de usuario o la contraseña son incorrectos.',
  same_password: 'La contraseña debe ser diferente',

  transactions_error_min_date: 'La fecha no es válida para el usuario',

  login_error: 'El nombre de usuario o la contraseña son incorrectos.',

  // profile
  errorPhoneProfile: 'El móvil no es válido',

  // error actualizar email
  changeEmail_credentials: 'Error, credenciales incorrectas.',
  changeEmail_duplicated_email:
    'Error, el nuevo email introducido ya pertenece a un usuario.',
  changeEmail_code: 'Error, enlace no valido.',

  // data-qr-component
  not_exist_hash: 'Actualmente no se ha podido generar el QR de la máquina',

  // register-card
  exist_card: 'Error, todas las tarjetas indicadas ya existen',
  all_exist_card: 'Error, todas las tarjetas indicadas ya existen',
  incorrect_card_register_card: 'Error, tarjeta incorrecta',
  incorrect_interval_card: 'Error, el intervalo no es correcto',
  invalid_card_register_card: 'Error, tarjeta incorrecta',

  // generate-qr
  not_contain_prices_qr: 'No existen precios para el cliente',

  // site
  not_operator_site: 'Error, operador no disponible',
  not_register_machine_site: 'Error, máquina no registrada en RTV',

  // online-shop
  not_stock_online_shop: 'Error, stock insuficiente del producto',
  error_price_online_shop: 'Error, calculo del precio erroneo',
  not_taxes: 'Error, impuesto asociado al producto',
  not_exist_online_shop: 'Error, producto no encontrado o stock insuficiente',

  // access-query
  not_exist_login: 'Error, usuario no encontrado',
  not_api_register: 'Error, api no registrada',

  // ecommerce-manager
  repeat_shop_article: 'Error, es gasto de envio indicado ya existe',
  not_exist_shop: 'Error, tienda no encontrada',
  shop_article_exist:
    'Error, la tienda indicada ya tiene tipos de pagos asignados',
  shop_articles_exist2: 'Error, la tienda indicada ya tiene artículos',
  shop_articles_exist_shipping_cost:
    'Error, la tienda indicada ya tiene gastos de envio asignados',

  // inventory-manager
  no_products_im: 'Error, la máquina no tiene productos',
  no_assigned_tills_im:
    'Error, el cliente indicado no tiene máquinas asignadas',

  // image-manager
  no_exist_image_im: 'Error, imagen no encontrada',

  // assign-articles-families-aena
  till_not_airport_aena: 'Error, máquina no situada en un aeropuerto',

  // article-manager
  repeat_family_articles_manager: 'Error, la familia indicada ya existe',
  repeat_code_articles_manager: 'Error, el artículo con codigo ',
  repeat_code_articles_manager2: ' ya existe',
  family_not_exist_articles_manager: 'Error, la familia indicada no existe',
  subfamily_not_exist_articles_manager:
    'Error, la subfamilia indicada no existe',
  vat_error_articles_manager: 'Error, tipo de impuesto incorrecto',

  // error schema
  not_exist_column_schema2:
    ' is not defined in the schema and the schema does not allow additional properties',
  not_exist_column_schema1: 'The property ',
  required_schema: 'is missing and it is required',
  max_length_schema1: 'may only be ',
  max_length_schema2: ' characters long',
  is_not_number_schema: '- string value found, but a number is required',

  // operator-group-manager
  operator_group_exist: 'Error, el grupo operador ya existe',

  // operator-manager
  operator_exist: 'Error, el grupo operador ya existe',

  // client-gropup-manager
  client_group_manager_exist: 'Error, el grupo cliente ya existe',

  // client-manager
  client_exist_client_manager: 'Error, el grupo cliente ya existe',

  // till-manager
  till_exist_till_manager: 'Error, la máquina ya existe',

  // access-web
  user_exist_access_web: 'Error, el usuario ya existe',
  user_pending_access_web:
    'Error, el usuario tiene una cuenta pendiente de activación',
  perrmission_access_web: 'Error, permisos incorrectos',
  rol_not_found_access_web: 'Error, rol no encontrado',
  invalid_visibility_access_web:
    'Error, la visibilidad indicada no es correcta',
  not_exist_user_access_web: 'Error, el usuario no existe',
  not_found_user_access_web: 'Error, usuario no encontrado',

  // send-file-aena
  not_exist_card_send_file_aena1: 'Error, la tarjeta',
  not_exist_card_send_file_aena2: 'no existe',
  invalid_format_send_file_aena: 'Error, formato del fichero incorrecto',
  never_generated_send_file_aena1:
    'Error, no se puede regenerar el fichero de transacciones diario de la máquina ',
  never_generated_send_file_aena2: ' ya que nunca ha sido generado',

  // article manager aena
  not_exist_subgroup_article_manager_aena: 'Error, subgrupos no encontrados',
  not_exist_article_manager_aena: 'Error, articulo no encontrado',
  misconfigured_client_article_manager_aena: 'Error, cliente mal configurado',

  // till-alert
  error_configuration_till_alert:
    'Error, ya existe una alerta con esta configuración',
  error_alert: 'Error, alerta no encontrada',
  error_till1_till_alert: 'Error, máquina ',
  error_till2_till_alert: 'no encontrada',
  error_create_till_alert: 'Error, ya existe una alerta con esa configuración',

  // multi-login
  error_role_user_multi_login: 'Error, el rol del usuario no es válido',
  error_role_invalid_multi_login: 'Error, el rol no es válido',
  not_exist_user_with_this_role_multi_login:
    'Error, el rol del usuario no es válido',
  error_visibility_multi_login: 'Error, el rol del usuario no es válido',

  // site-elora
  error_not_exist_visibility_site_elora:
    'Error, visibilidad del usuario es incorrecta',
  error_exceed_capacity_site_elora:
    'Error, estas superando la capacidad del canal ',
  error_first_inventory_not_stock_site_elora:
    'Error, para primer inventario se debe de indicar el stock',
  error_first_inventory_not_capacity_site_elora:
    'Error, para primer inventario se debe de indicar la capacidad del canal',
  error_first_inventory_capacity_site_elora:
    'Error, capacidad del canal incorrecta',

  // machine_status
  error_max_license_machine_status:
    'Error, se ha alcanzado el maximo número de licencias de alertas',
  error_not_found_machine_status1: 'Error, máquina ',
  error_not_found_machine_status2: ' no encontrada',
  error_register_alert_machine_status1:
    'Error, alerta ya registrada para la máquina ',
  error_register_alert_machine_status2: ' y el email ',
  error_invalid_client_machine_status: 'Error, el cliente no es válido',

 // reports
 error_type_not_card_report: 'Error, el tipo de reporte no admite tarjeta',
 error_invalid_card_report: 'Error, card no encontrada',
 error_some_invalid_card_report: 'Error, alguna card no encontrada',
 error_client_required_report:
   'Error, parametros incorrectos no has puesto client',

  // statusnet
  not_found_service_status_net: 'Error, servicio no encontrado',

  // ifree-vend-manager
  error_date_ifree_vend: 'Error, fechas incorrectas',

  // till-mapping
  error_not_hot_drink_capacity_0_till_mapping:
    'Error, no se puede asignar capacidad 0 a esta máquina',
  error_hot_drink_capacity_diff_0_till_mapping:
    'Error, no se puede asignar capacidad mayor a 0 a esta máquina',


};

export const ERROR_TYPES_BY_ROUTE = {
  supervisor_manager: {
    exist_supervisor: 'Error, supervisor ya existente',
    other_exist_supervisor:
      'Error, ya existe un supervisor con estas caracteristicas',
    invalid_route: 'Error, ruta incorrecta',
  },
  route_driver_manager: {
    exist_route_driver: 'Error, routedriver ya existente',
    invalid_route: 'Error, ruta incorrecta',
    other_exist_route_driver:
      'Error, ya existe una ruta con estas caracteristicas',
  },
  route_manager: {
    exist_route: 'Error, ruta indicada ya existe',
    not_found_supervisor: 'Error, supervisor no encontrado',
    not_found_stocker: 'Error, reponedor incorrecto',
    not_found_machine: 'Error, máquina incorrecta',
    not_exist_route: 'Error, ruta no encontrada',
    other_exist_route: 'Error, ya existe una ruta con estas caracteristicas',
  },
  request_licenses_vtag: {
    not_api:
      'Error, el usuario no tiene la visibilidad suficiente para utilizar esta función',
    not_functionality:
      'Error, funcionalidad no habilitada para la aplicación indicada',
    error_email: 'Error: Enviando el email',
  },
  licenses_vtag_manager: {
    functionality_not_enabled:
      'Error, funcionalidad no habilitada para la aplicación indicada',
    not_enought_licenses: 'Error, No hay licencias suficientes.',
    other_exist_route: 'Error, ya existe una ruta con estas caracteristicas',
  },
  turn_over: {
    max_period_not_client: 'Error, el periodo debe ser inferior a 3 meses',
    max_period_client: 'Error, el periodo debe ser inferior a 12 meses',
  },
  subsidy_manager: {
    not_exist_subsidy: "Error, bonificacion no encontrada",
    error_max_article_edit: "Error, no se puede actualizar bonificacion",
    not_exist_article: "Error, articulo no existe"
  },
  subsidy: {
    not_exist_card: "Error, tarjeta no existe",
    max_actives: "Error, tarjeta ha alcanzado el maximo numero de bonificaciones activas",
    not_exist_subsidy: "Error, bonificacion no existe",
    not_exist_till: "Error, máquina no encontrada"
  }

}
