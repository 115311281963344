import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { ION_GRIDSIZE } from 'src/app/utils';
import { ArrowTemplateOptions } from './models';
import { pcWidth } from '../../../utils/constants';

@Component({
  selector: 'app-arrow-template',
  templateUrl: './arrow-template.component.html',
  styleUrls: ['./arrow-template.component.scss'],
})
export class ArrowTemplateComponent implements OnInit {
  @Input() company: Company;
  @Input() options: ArrowTemplateOptions = ArrowTemplateOptions.bothDirection;

  @Output() back = new EventEmitter();
  @Output() next = new EventEmitter();

  ArrowTemplateOptions = ArrowTemplateOptions;

  sizeMd = ION_GRIDSIZE.md;

  pcWidth = pcWidth;

  constructor(public readonly platform: Platform) {}

  ngOnInit() {}

  backHandle() {
    this.back.emit();
  }

  nextHandle() {
    this.next.emit();
  }
}
