export interface ApplicationManagementRoutesTranslates {
  imageManager: string;
  notificationPanel: string;
  notificationManager: string;
  appFailedCharges: string;
  requestLicensesVtag: string;
  licensesVtagManager: string;
}

export const getApplicationManagementRoutes = (
  translates: ApplicationManagementRoutesTranslates
): { route: string; name: string }[] => {
  return [
    { route: '/user/image-manager', name: translates.imageManager },
    {
      route: '/user/notification-panel',
      name: translates.notificationPanel
    },
    {
      route: '/user/notification-manager',
      name: translates.notificationManager
    },
    {
      route: '/user/app-failed-charges',
      name: translates.appFailedCharges,
    },
    {
      route: '/user/request-licenses-vtag',
      name: translates.requestLicensesVtag,
    },
    {
      route: '/user/licenses-vtag-manager',
      name: translates.licensesVtagManager,
    },
  ];
};
