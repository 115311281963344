// services
import { TranslateService } from '@ngx-translate/core';

// models
import { TillAlertSpiderPeriod, WeekDaysMachineStatus } from '../../models';
import { WEEK_DAYS_MACHINE_STATUS } from '../constant';

export const getTillAlertSpiderPeriod = async (
  lastDays: TillAlertSpiderPeriod[],
  translate: TranslateService
): Promise<WeekDaysMachineStatus[]> => {
  const weekDays: WeekDaysMachineStatus[] = [];

  for (let lastDay of lastDays) {
    let description = lastDay.Weekday;
    if (WEEK_DAYS_MACHINE_STATUS?.[description]) {
      description = await translate.instant(
        WEEK_DAYS_MACHINE_STATUS?.[description]
      );
    }

    weekDays.push({ id: lastDay.ID, description, isActive: true });
  }

  return weekDays;
};
