import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Service
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from '../../../../../../../../../share/services/languageRoute.ts/languagesRoute.services';
import { SkinMangementService } from '../../../../../../../../../services/skin-mangement.service';

// Models
import { CardNames, Company } from '../../../../../../../../../models/';

@Component({
  selector: 'app-created-text-card-pc',
  templateUrl: './created-text-card-pc.component.html',
  styleUrls: ['./created-text-card-pc.component.scss'],
})
export class CreatedTextCardPcComponent implements OnInit {
  company: Company;

  @Input() existCards: string[] = [];

  cardNames = CardNames;

  constructor(
    private modalCtrl: ModalController,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }
}
