interface IsDashboardRefillingValid {
  operatorGroupId: number;
  operatorId: number;
  clientGroupId: number;
  clientId: number;
  routeDriveId: number;
  routeId: number;
  supervisorId: number;
}

export const isDashboardRefillingValid = ({
  operatorGroupId,
  operatorId,
  clientGroupId,
  clientId,
  supervisorId,
  routeDriveId,
  routeId,
}: IsDashboardRefillingValid) => {
  if (!operatorGroupId) {
    return false;
  }

  if ((clientId || clientGroupId) && !supervisorId) {
    return false;
  }

  if(routeDriveId || routeId){
    return false;
  }

  return true;
};
