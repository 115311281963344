import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, HostBinding } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

// Material
import { MatDateRangePicker } from '@angular/material/datepicker';

// Services
import { Company, FilterDateServiceI } from 'src/app/models';
import { RangeDate } from '../../../../models';

// utils
import { type } from 'src/app/utils';


@Component({
  selector: 'app-datepicker-date-material-input',
  templateUrl: './datepicker-date-material-input.component.html',
  styleUrls: ['./datepicker-date-material-input.component.scss'],
})
export class DatepickerDateMaterialInputComponent implements OnInit {

  @Input() company: Company;
  @Input() dateText: string = ''
  @Input() _filterDate: FilterDateServiceI;
  @Input() rangeDate: RangeDate;
  @Input() hideIconBySize: boolean = false;

  @Output() changeDate = new EventEmitter<Date>();

  datePicker: FormGroup;

  @ViewChild('picker') picker: MatDateRangePicker<any>;

  filterChange$: Subscription;

  minDate: Date;
  maxDate: Date;

  type = type;

  constructor() { }

  ngOnInit() { }

  changedDate(dateInput: Date){
    this.changeDate.emit(dateInput)
  }

}
