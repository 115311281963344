import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpParams,
} from '@angular/common/http';

import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// Service
import { PermissionService, SkinMangementService } from '../../services';
import { StorageService } from '../services';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private _storageService: StorageService,
    private _permissionService: PermissionService,
    private _skinMangementService: SkinMangementService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return from(this._storageService.get('token')).pipe(
      switchMap((token: any) => {
        if (token) {
          req = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + token),
          });
        }

        req = req.clone({
            headers: req.headers.set('origin_request', this._skinMangementService.getOrigin()),
        })

        const groupName = this._permissionService.getGroupName();
        if (groupName && !req?.url.includes('&group=')) {
          req = req.clone({
            params: (req.params ? req.params : new HttpParams()).set(
              'group',
              groupName
            ),
          });
        }

        return next.handle(req);
      })
    );
  }
}
