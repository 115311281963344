import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  Platform,
  ModalController,
  IonFab,
  NavController,
} from '@ionic/angular';
import { Subscription } from 'rxjs';

// Component
import {
  LegendCardActivityComponent,
  LegendCheckQrComponent,
  LegendEcommerceManagerComponent,
  LegendImageManagerComponent,
  LegendInventoryComponent,
  LegendInventoryManagerComponent,
  LegendLoadCreditUnlimitedComponent,
  LegendMachinesStatusComponent,
  LegendQrRequestComponent,
  LegendRegisterCardComponent,
  LegendSiteComponent,
  LegendSubsidiesComponent,
  LegendTagStatusComponent,
  LegendTicketCheckComponent,
  LegendTransactionQueryComponent,
  LegendTurnOverComponent,
  LegendTillMappingComponent,
  LegendsSendFileAenaComponent,
  NotificationManagerLegendComponent,
  CreateEditArticlesLegendComponent,
  LegendArticlesFamilyAenaComponent,
  LegendMachinesModuleComponent,
  LegendTillAlertComponent,
  DashboardWelcomeLegendComponent,
} from './legend';
import { IfreeVendLegendShareComponent } from '../legends/ifree-vend-legend-share/ifree-vend-legend-share.component';
import { ModalPdfComponent } from '../modal-pdf/modal-pdf.component';

// Service
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  SkinMangementService,
  ColorModeService,
} from '../../../services';
import { LanguageRouteService, ExcelService } from '../../services';
import { FabBrService, TypesFabEmitter } from './services/fab-br.service';

// Model
import { Company } from '../../../models';
import { TillManagerLegendComponent } from '../../../pages/user/data-manager/till-manager/components/modals/till-manager-legend/till-manager-legend.component';

// Utils
import { pcWidth, type, MULTI_LOGIN_ROUTE } from '../../../utils/';
import { DashboardRefillingLegendComponent } from '../legends/dashboard-refilling-legend/dashboard-refilling-legend.component';
import {
  LicensesVatgLegendComponent,
  SubsidiesLegendComponent,
  SubsidiesManagerLegendComponent,
} from '../legends';

@Component({
  selector: 'app-fab-br',
  templateUrl: './fab-br.component.html',
  styleUrls: ['./fab-br.component.scss'],
})
export class FabBRComponent implements OnInit {
  @ViewChild(IonFab) fab: IonFab;

  // aspecto
  company: Company;
  type = type;
  language: string = '';

  pcWidth = pcWidth;

  // rutas que tienen leyenda
  legendsModal: { [x: string]: Function } = {
    '/user/card-activity': LegendCardActivityComponent,
    '/user/check-qr': LegendCheckQrComponent,
    '/user/ecommerce-manager': LegendEcommerceManagerComponent,
    '/user/image-manager': LegendImageManagerComponent,
    '/user/inventory': LegendInventoryComponent,
    '/user/inventory-manager': LegendInventoryManagerComponent,
    '/user/machines-status': LegendMachinesStatusComponent,
    '/user/machines-status/current-state': LegendMachinesStatusComponent,
    '/user/machines-status/till-state': LegendMachinesStatusComponent,
    '/user/notification-manager': NotificationManagerLegendComponent,
    '/user/qr-request': LegendQrRequestComponent,
    '/user/register-cards': LegendRegisterCardComponent,
    '/user/site': LegendSiteComponent,
    '/user/subsidies': LegendSubsidiesComponent,
    '/user/tag-status': LegendTagStatusComponent,
    '/user/ticket-check': LegendTicketCheckComponent,
    '/user/transactions-query': LegendTransactionQueryComponent,
    '/user/transactions-query-aena': LegendTransactionQueryComponent,
    '/user/turn-over': LegendTurnOverComponent,
    '/user/webload/load-credit/component/load-credit-card-unlimited': LegendLoadCreditUnlimitedComponent,
    '/user/create-edit/articles': CreateEditArticlesLegendComponent,
    '/user/till-mapping': LegendTillMappingComponent,
    '/user/regen-files-aena': LegendsSendFileAenaComponent,
    '/user/assign-articles-families-aena': LegendArticlesFamilyAenaComponent,
    '/user/assign-subgroups-articles-aena': LegendArticlesFamilyAenaComponent,
    '/user/aena/check-article': LegendArticlesFamilyAenaComponent,
    '/user/check-subgroups-articles-aena': LegendArticlesFamilyAenaComponent,
    '/user/machine-module': LegendMachinesModuleComponent,
    '/user/tills-alerts': LegendTillAlertComponent,
    '/user/tills-alerts-by-user': LegendTillAlertComponent,
    '/user/data-manager/till-manager': TillManagerLegendComponent,
    '/user/site-elora': LegendSiteComponent,
    '/user/dashboard/welcome': DashboardWelcomeLegendComponent,
    '/user/dashboard/refilling-dashboard': DashboardRefillingLegendComponent,
    '/user/ifree-vend-manager': IfreeVendLegendShareComponent,
    '/user/licenses-vtag-manager': LicensesVatgLegendComponent,
    '/user/subsidies-v2': SubsidiesLegendComponent,
    '/user/subsidy-manager': SubsidiesManagerLegendComponent
  };

  // rutas con manuales, margin si solo tiene una pagina el pdf
  manualsModal: {
    [x: string]: { pdf: string; margin: boolean; useTranslate: boolean };
  } = {
    '/user/card-activity': {
      pdf: 'Modulo_Actividad_tarjeta_',
      margin: true,
      useTranslate: true,
    },
    '/user/site': {
      pdf: 'Modulo_Prop_Carga_',
      margin: false,
      useTranslate: true,
    },
    '/user/tag-status': {
      pdf: 'Modulo_Estado_Tarjetas_',
      margin: true,
      useTranslate: true,
    },
    '/user/machines-status/till-state': {
      pdf: 'Modulo_Estado_Maquinas_',
      margin: true,
      useTranslate: true,
    },
  };

  // rutas que tienen descargar pdf
  transactionRoute = '/user/transactions-query';
  transactionAenaRoute = '/user/transactions-query-aena';
  cardActivityRoute = '/user/card-activity';
  ticketCheckRoute = '/user/ticket-check';

  pdfDownload = ['/user/product-sold'];

  // use getShowDownloadExcel
  excelRoutes: string[] = [
    '/user/check-qr',
    '/user/turn-over',
    '/user/sales-module',
    '/user/subsidies-v2',
  ];

  //Display the option internalname
  changeInternalName = ['/user/tag-status'];

  // showChangeInternalName = false;

  loadingDownloadPdf = false;
  showDownload = false;

  // icon print
  havePrinter = false;
  routeValidPrinter = '/user/info-payment-online-shop/1/';

  showFabRoute: boolean = true;
  isMultiLogin: boolean = false;
  haveReload: boolean = false;

  manualsModalAlliance: {
    [x: string]: { pdf: string; margin: boolean; useTranslate: boolean };
  } = {
    '/user/generate-qr': {
      pdf: 'alliance_reports_qr',
      margin: true,
      useTranslate: false,
    },
  };

  realodRoutes = [];
  isLightColor: boolean = true;

  routerEvents$: Subscription;
  closeFab$: Subscription;
  fabExcel$: Subscription;
  languageChange$: Subscription;
  validChange$: Subscription;
  haveMultiLogin$: Subscription;
  changeColor$: Subscription;

  constructor(
    private _skinService: SkinMangementService,
    private _authService: AuthService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    public readonly platform: Platform,
    public readonly router: Router,
    private _excelService: ExcelService,
    private _fabBrService: FabBrService,
    private _languageRouteService: LanguageRouteService,
    private translate: TranslateService,
    private _colorModeService: ColorModeService
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.addManualsByCompany();

    this.routerEvents$ = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd && this.fab) {
        this.fab.close();
      }
      if (ev instanceof NavigationEnd) {
        this.havePrinter = this.router.url.includes(this.routeValidPrinter);
        this.showFabRoute = this.router.url !== MULTI_LOGIN_ROUTE;
        this.haveReload = this.realodRoutes.includes(this.router.url);
        //console.log('showFabRoute', this.showFabRoute)
      }
    });

    this.languageChange$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.language = valor;
      }
    );

    this.loadingDownloadPdf = this._excelService.getLoading();
    this.fabExcel$ = this._excelService.fabExcel.subscribe((res) => {
      if (res?.loading || res.loading == false) {
        this.loadingDownloadPdf = res.loading;
      }
    });

    // show donwload
    this.showDownload =
      (this._fabBrService.getShowDonwload() &&
        (this.router.url == this.transactionRoute ||
          this.router.url === this.transactionAenaRoute)) ||
      (this._fabBrService.getShowCardActivity() &&
        this.router.url == this.cardActivityRoute) ||
      (this._fabBrService.getShowDownloadExcel() &&
        this.excelRoutes.includes(this.router.url));

    this.validChange$ = this._fabBrService.validChange.subscribe((res) => {
      this.showDownload =
        ((res.route == this.transactionRoute ||
          res.route == this.transactionAenaRoute) &&
          res.valid) ||
        (this._fabBrService.getShowCardActivity() &&
          this.router.url == this.cardActivityRoute) ||
        (res.route === this.ticketCheckRoute && res.valid) ||
        (this._fabBrService.getShowDownloadExcel() &&
          this.excelRoutes.includes(this.router.url));

      if (this.fab) {
        this.fab.close();
      }
    });

    this.closeFab$ = this._fabBrService.closeFab.subscribe((res) => {
      if (res && this.fab?.activated) {
        this.fab.close();
      }
    });

    this.multiLoginHandle();

    this.getColor();
  }

  ionViewDidEnter() {}

  ionViewDidLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    if (this.routerEvents$) this.routerEvents$.unsubscribe();
    if (this.languageChange$) this.languageChange$.unsubscribe();
    if (this.fabExcel$) this.fabExcel$.unsubscribe();
    if (this.validChange$) this.validChange$.unsubscribe();
    if (this.closeFab$) this.closeFab$.unsubscribe();
    if (this.haveMultiLogin$) this.haveMultiLogin$.unsubscribe();
    if (this.changeColor$) this.changeColor$.unsubscribe();
  }

  logout() {
    this._authService.logout();
  }

  multiLoginHandle() {
    this._authService.haveMultiLogin();
    this.haveMultiLogin$ = this._authService.haveMultiLogin$.subscribe(
      (res) => {
        // console.log('multi login fab', res)
        this.isMultiLogin = res;
      }
    );
  }

  async showLegend() {
    let modal: any;

    // if (this.router.url == '/user/site') {
    //   modal = await this.modalCtrl.create({
    //     component: LegendSiteComponent,
    //     componentProps: {
    //       images: imagesSite,
    //       icons: iconsSite,
    //     },
    //   });
    //   await modal.present();
    // }
    if (this.router.url !== '/user/transactions-query') {
      modal = await this.modalCtrl.create({
        component: this.legendsModal[this.router.url],
      });
      await modal.present();
    } else {
      this._fabBrService.openLegendModal();
    }
  }

  async modifyInternalName() {
    if (this.router.url == '/user/tag-status') {
      this._fabBrService.fabEmit('TagStatus');
    }
  }

  existLegend() {
    return this.legendsModal.hasOwnProperty(this.router.url);
  }

  existDownloadExcel() {
    return this.pdfDownload.includes(this.router.url);
  }

  existChangeInternalName() {
    return this.changeInternalName.includes(this.router.url);
  }

  downloadExcel() {
    if (!this.loadingDownloadPdf) this._excelService.downLoadExcel();
  }

  async showManual() {
    let modal = await this.modalCtrl.create({
      component: ModalPdfComponent,
      componentProps: {
        name:
          this.manualsModal[this.router.url].pdf +
          `${
            this.manualsModal[this.router.url].useTranslate ? this.language : ''
          }`,
        marginTop: this.manualsModal[this.router.url].margin,
      },
    });
    await modal.present();
  }

  existManual() {
    return this.manualsModal.hasOwnProperty(this.router.url);
  }

  emitPrint() {
    this._fabBrService.fabEmit('Print');
  }

  changeColor() {
    this._colorModeService.changeColor();
  }

  backSelectRole() {
    this.navCtrl.navigateRoot('/user/select-role', { animated: true });
  }

  addManualsByCompany() {
    if (this.company.companySkin === type.alliance) {
      this.manualsModal = {
        ...this.manualsModal,
        ...this.manualsModalAlliance,
      };
    }
  }

  emitFabClicked(typeFilter: TypesFabEmitter) {
    this._fabBrService.fabEmit(typeFilter);
  }

  getColor() {
    this.isLightColor = this._colorModeService.getIsLightColor();

    this.changeColor$ = this._colorModeService.changeColor$.subscribe(() => {
      this.isLightColor = this._colorModeService.getIsLightColor();
    });
  }
}
