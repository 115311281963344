import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-footer-save-modal',
  templateUrl: './footer-save-modal.component.html',
  styleUrls: ['./footer-save-modal.component.scss'],
})
export class FooterSaveModalComponent implements OnInit {
  @Input() company: Company;
  @Input() closeText = 'close';
  @Input() saveText = 'save';

  @Output() save = new EventEmitter();
  @Output() close = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  saveHandle() {
    this.save.emit();
  }

  closeHandle() {
    this.close.emit();
  }
}
