import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { presentToast, successToast, warningToast } from './';

/**
 * Test use IonicSafeString
 */
@Injectable({
  providedIn: 'root',
})
export class UtilsTranslate {
  constructor(
    private toastCtrl: ToastController,
    private translate: TranslateService
  ) {}

  presentToastTranslate = async (
    message: string,
    error = true,
    time: number = 50,
    duration?: number
  ) => {
    setTimeout(() => {
      if (error) {
        this.translate.get([message]).subscribe((translates) => {
          presentToast(
            translates[message],
            this.toastCtrl,duration
          );
        });
      } else {
        this.translate.get([message]).subscribe((translates) => {
          successToast(
            translates[message],
            this.toastCtrl, duration
          );
        });
      }
    }, time);
  };

  presentToastWithVariable = async (
    message: string,
    nameVariable: string,
    valueVariable: string,
    error: boolean = true,
    duration?: number
  ) => {
    this.translate.get([message]).subscribe((translates) => {
      const messageChanged = translates[message].replace(
        '{{' + nameVariable + '}}',
        valueVariable
      );
      error
        ? presentToast( messageChanged, this.toastCtrl, duration)
        : successToast(
            messageChanged,
            this.toastCtrl,
            duration
          );
    });
  };

  presentToastWithoutTranslate = async (message: string, error = true) => {
    if (error) presentToast(message, this.toastCtrl);
    else successToast(message, this.toastCtrl);
  };

  showError() {
    presentToast(this.translate.instant('error'), this.toastCtrl);
  }

  async getTranslate(translateText: string) {
    return await this.translate.instant(translateText);
  }

  presentWarningTranslate = async (message: string, duration: number) => {
    this.translate.get([message]).subscribe((translates) => {
      warningToast(translates[message], this.toastCtrl, duration);
    });
  };

  removeWeigthTranslate(translate: string) {
    return translate.replace(/<b>/, '').replace(/<\/b>/g, '');
  }
}
