import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-footer-update-modal',
  templateUrl: './footer-update-modal.component.html',
  styleUrls: ['./footer-update-modal.component.scss'],
})
export class FooterUpdateModalComponent implements OnInit {
  @Input() company: Company;
  @Input() editText: string = 'edit';
  @Input() closeText: string = 'close';

  @Output() edit = new EventEmitter();
  @Output() close = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  editHandle() {
    this.edit.emit();
  }

  closeHandle() {
    this.close.emit();
  }
}
