import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

// Config
import { AppConfig } from '../../../config/config';

// Models
import {
  Card,
  ParamsGetCardByCardNum,
  ParamsFiltersCards,
} from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient, protected router: Router) {}

  getCards(params: ParamsFiltersCards): Observable<Card[]> {
    let endpoint = `cards`;
    let routeUrl = this.router.url;

    if (params?.operatorId == -1) {
      delete params.operatorId;
    }

    if (params?.valid != 0) {
      params.valid = 1;
    }

    return this.httpClient
      .post<Card[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getUserCards() {
    let endpoint = `cards/user-cards`;
    let routeUrl = this.router.url;
    // console.log('params2', params);
    return this.httpClient
      .get<Card[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getCardyById(id) {
    let endpoint = `/cards/${id}`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<Card>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getCardByCardnum(params: ParamsGetCardByCardNum) {
    let endpoint = 'cards-by-cardnum';
    let routeUrl = this.router.url;

    return this.httpClient
      .post<Card>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

}
