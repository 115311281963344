// model
import { Client } from 'src/app/models';
import { MachineStatus } from '../../models';

// constant
import { AENA_CLIENT_TEXT } from '../constant';

export const isClientAenaTillState = (clients: Client[], clientId: number) => {
  const clientsAenas = clients.filter((client) =>
    (client.description || '').toLowerCase().includes(AENA_CLIENT_TEXT)
  );
  if (clientsAenas.length > 0) {
    const isAena = clientsAenas.some((client) => client.ID === clientId);

    if (isAena) {
      return true;
    }
  }

  return false;
};

export const isClientAenaMachineStatus = (machineStatus: MachineStatus) => {
  return (machineStatus?.client || '').toLowerCase().includes(AENA_CLIENT_TEXT);
};
