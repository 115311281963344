import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FixMenuService {

  menuState = false;
  fixMenuChange: Subject<boolean> = new Subject<boolean>();

  constructor(
    private menuController: MenuController
  ) { }

  setFixMenu(menuFix) {
    this.menuState = menuFix;
    this.fixMenuChange.next(menuFix);
  }

  getMenuFix(){
    return this.menuState;
  }

  closeMenu(){
    if(!this.menuState){
      this.menuController.close()
    }
  }

}
