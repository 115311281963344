import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// config
import { AppConfig } from 'src/app/config/config';

// services
import { ClientService } from 'src/app/share/services';
import { AenaService } from '../../../../../share/services/aena/aena.service';

// models
import {
  FamilyAena,
  SetArticlesAenaParams,
  TillAenaInfo,
  ArticlesAena,
  deleteRelationAenaParams,
} from '../models';
import { ClientArticleAena } from '../subgroup/models';


@Injectable({
  providedIn: 'root',
})
export class AenaShareService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private _clientService: ClientService,
    private _aenaService: AenaService
    ) {}

  getTills(clientID: number) {
    return this._aenaService.getTills(clientID)
  }

  getClients() {
    return this._clientService.getClientsAena()
  }

  getTillAenaById(
    tillId: number,
    familiesPublic: FamilyAena[],
    familiesPrivate: FamilyAena[]
  ) {
    let endpoint = `AenaMachine/${tillId}`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<TillAenaInfo>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          res.articles = [];
          let articlesWithSubgroup: ArticlesAena[] = [];
          res.articles_with_subgroup.map((article) => {
            let newArticle = articlesWithSubgroup.find(
              (articleWithSubgroup) =>
                article.ArticleID === articleWithSubgroup.articleId
            );

            if (!newArticle) {
              newArticle = {
                article: article.Article,
                articleId: article.ArticleID,
                code: article?.codeArticle
              };

              articlesWithSubgroup.push(newArticle);
            }

            const familyPublic = familiesPublic.find(
              (familyPublic) =>
                familyPublic.id === article.subgroupID &&
                familyPublic.Code === article.subgroupCode
            );
            if (familyPublic) {
              newArticle.subgroupIdPublic = familyPublic.id;
              newArticle.subgroupCodePublic = familyPublic.Code;
              newArticle.subgroupPublic = familyPublic.Description;
            }

            const familyPrivate = familiesPrivate.find(
              (familyPrivate) =>
                familyPrivate.id === article.subgroupID &&
                familyPrivate.Code === article.subgroupCode
            );
            if (familyPrivate) {
              newArticle.subgroupIdPrivate = familyPrivate.id;
              newArticle.subgroupCodePrivate = familyPrivate.Code;
              newArticle.subgroupPrivate = familyPrivate.Description;
            }
          });

          const articlesWithoutSubgroup = res.articles_without_subgroup.map(
            (article) => ({
              article: article.Article,
              articleId: article.idArticle,
            })
          );

          res.articles = [...articlesWithSubgroup, ...articlesWithoutSubgroup];

          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getFamilyAena(isPublic: boolean) {
    return this._aenaService.getFamilyAena(isPublic)
  }

  deleteRelationAena(deleteParams: deleteRelationAenaParams) {
    let endpoint = 'deleteRelationAena';
    let routeUrl = this.router.url;

    return this.httpClient
      .post<null>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        deleteParams
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  setRelationAena(setRelationParams: SetArticlesAenaParams) {
    let endpoint = 'setRelationAena';
    let routeUrl = this.router.url;

    return this.httpClient
      .post<null>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        setRelationParams
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  deleteAllRelationAena(machineID: number) {
    let endpoint = 'deleteAllRelationAena';
    let routeUrl = this.router.url;

    const params = { machineID };

    return this.httpClient
      .post<null>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }


  getSubgroupsAena(clientId: number = null) {
    return this._aenaService.getSubgroupsAena(clientId)
  }


  getClientArticles() {
    let endpoint = `AENA/clientArticles`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<ClientArticleAena[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }


}
