import { Component, Input, OnInit, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { Subscription } from 'rxjs/internal/Subscription';

// Models
import { Client, Company } from 'src/app/models';

// Service
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from 'src/app/services';
import { LanguageRouteService } from '../../services';
import { ClientFilterService } from '../filter-client/service/client-filter.service';

// Utils
import { timeReload } from '../../../utils/constants';


@Component({
  selector: 'app-filter-client-individual',
  templateUrl: './filter-client-individual.component.html',
  styleUrls: ['./filter-client-individual.component.scss'],
})
export class FilterClientIndividualComponent implements OnInit {

  @Input() filterClientGroup: boolean = false;
  @Input() filterOperator: boolean = false;
  @Input() filterOperatorGroup : boolean = false;

  @Input() clientGroupId: number = null;
  @Input() operatorId: number = null;
  @Input() operatorGroupId : number = null;

  @Input() inifiteScrollActive = false;

  @Output() moveUp = new EventEmitter<void>();

  code: string = '';
  name: string = '';

  // aspecto
  company: Company;

  typeFilter = "name";

  clientNameFilter: any[] = [];
  clientSelected = null;

  // todos los clientes
  clientsFull: Client[] = [];
  // clients filtrado
  clients: Client[] = [];

   // translates
   deleteClientFilterTranslate = '';
   errorTranslate = '';

   clientsDB: Client[] = [];
   loadingClient = true;

   operatorFiltered = null;

   @Output() sendClient = new EventEmitter<Client>();

   changeLanguage$: Subscription;
   filterChange$: Subscription;
   changeState$: Subscription;


   @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _filterService: ClientFilterService,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });

    this.name = this._filterService.getNameClient();
    this.code = this._filterService.getCodeClient();

    this.clientSelected = this._filterService.getClientId();

    let operator = (this.filterOperator) ? this.operatorId : null
    this.operatorFiltered = operator;

    this._filterService.updateFilter(operator, this.clientGroupId);


    if (this._filterService.getLoaded()) {
      this.start();
      if (this.name) {
        this.changeName();
      }
      this.loadingClient = false;
    }


    this.filterChange$ = this.filterChange$ = this._filterService.filterChange.subscribe(filterChange => {

      this.name = this._filterService.getNameClient();
      this.code = this._filterService.getCodeClient();
      setTimeout(()=> {
        this.clientSelected = filterChange?.idClient;
      },100)

    })

    this.changeState$ = this._filterService.changeState.subscribe(newState => {

      if (this._filterService.getLoaded()) {
        this.start();
        this.loadingClient = false;
      }
    })

  }


  ngOnChanges(changes: SimpleChanges): void {

    let operator = (this.filterOperator) ? this.operatorId : null

    if(operator != this.operatorFiltered){
      this._filterService.updateFilter(operator, this.clientGroupId);
      this.operatorFiltered = operator;
    }

  }

  ngOnDestroy(): void {
    if(this.changeState$) this.changeState$.unsubscribe()
    if(this.changeLanguage$) this.changeLanguage$.unsubscribe()
    if( this.filterChange$) this.filterChange$.unsubscribe()
  }

  start() {


    this.clientsFull = this._filterService.getClients();
    this.clients = this.clientsFull;
    this.clientNameFilter = [];
    if (this.clients.length < 10) {
      this.clientNameFilter.push(...this.clients.slice(0, this.clients.length));
    } else {
      this.clientNameFilter.push(...this.clients.slice(0, 10));
    }
  }


  changeFilter() {
    if (this.typeFilter == 'name') {
      this.changeName()
    } else {
      this.changeCode();
    }
  }


  changeName() {
    if (!this.name) {
      this.start();
    } else {
      this.clientNameFilter = [];
      this.clients = this.clientsFull.filter(clientFull => clientFull.description?.toLowerCase().trim()
        .includes(this.name?.toLowerCase().trim()));
      if (this.clients.length < 10) {
        this.clientNameFilter.push(...this.clients.slice(0, this.clients.length));
      } else {
        this.clientNameFilter.push(...this.clients.slice(0, 10));
      }
    }
  }

  changeCode() {
    if (!this.code) {
      this.start();
    } else {
      this.clientNameFilter = [];
      this.clients = this.clientsFull.filter(clientFull => clientFull.code?.toLowerCase().trim()
        .includes(this.code?.toLowerCase().trim()));
      if (this.clients.length < 10) {
        this.clientNameFilter.push(...this.clients.slice(0, this.clients.length));
      } else {
        this.clientNameFilter.push(...this.clients.slice(0, 10));
      }
    }
  }

  loadData(event) {



    setTimeout(() => {

      if (this.clientNameFilter.length >= this.clients.length) {

        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      let nuevoArr2 = [];
      if (this.clients.length < this.clientNameFilter.length + 10) {
        nuevoArr2 = this.clients.slice(this.clientNameFilter.length, this.clients.length);
      } else {
        nuevoArr2 = this.clients.slice(this.clientNameFilter.length, this.clientNameFilter.length + 10);
      }
      this.clientNameFilter.push(...nuevoArr2);

      this.inifiteScroll.complete();
      this.moveUp.emit();

    }, timeReload);

  }

  savePlaceholders() {
    this.translate.get(['error']).subscribe((translates) => {
      this.errorTranslate = translates['error'];
    })
  }

  changeClient(){
    //console.log('changeClient', this.clientSelected)
    if(!this.clientSelected){
      return;
    }
    let selectFilter: Client = this.clientsFull.find(clientFull => clientFull.ID == this.clientSelected);
    this._filterService.setClient(selectFilter.description, selectFilter.code, selectFilter.ID);
    this.code = selectFilter?.code;
    this.name = selectFilter?.description;
    this.sendClient.emit(selectFilter)
  }

}

