import { Component, OnInit, Input } from '@angular/core';


// models
import { Company, FilterDateServiceI } from 'src/app/models';
import { DatePickerFilterOptions, RangeDate } from '../../models';

@Component({
  selector: 'app-filter-date-with-hours',
  templateUrl: './filter-date-with-hours.component.html',
  styleUrls: ['./filter-date-with-hours.component.scss'],
})
export class FilterDateWithHoursComponent
  implements OnInit {

  @Input() showHour: boolean = true;

  @Input() company: Company;
  @Input() datePipe = 'dd/MM/y';

  @Input() _filterDate: FilterDateServiceI;
  @Input() rangeDate: RangeDate;

  @Input() startDate: string;
  @Input() startHour: string;
  @Input() endHour: string;

  @Input() showTextHourComplete: boolean = false;
  @Input() datePickerFilter: DatePickerFilterOptions = null;

  constructor(
  ) {
  }

  ngOnInit() {

  }

  changeDate(startDate: Date) {
    if (!startDate) {
      return;
    }

    this._filterDate.setDate(startDate, 0);
  }

}
