export const getIsValidOperator = (
  visibilityOperatorGroup: boolean,
  operatorSelected: number[]
): boolean => {
  if (!visibilityOperatorGroup) {
    return true;
  }

  return operatorSelected.length === 1;
};

export const getIsValidSend = (
  visibilityOperatorGroup: boolean,
  operatorSelected: number[],
  clientId: number,
  tillCode: string
): boolean => {
  if (getIsValidOperator(visibilityOperatorGroup, operatorSelected)) {
    return true;
  }

  return clientId === 0 || !!clientId || (tillCode || '').length >  0;
};
