import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Config
import { AppConfig } from '../../../config/config';

// Models
import { GetClientsParams, Client } from 'src/app/models';
import {
  ParamsCreateClient,
  EditClient,
} from '../../../pages/user/data-manager/client-manager/models/';
import { ClientAena } from '../../../pages/user/aena/share/models/client-aena.model';

// utils
import { MACHINE_STATUS_ROUTE, MACHINE_STATUS_SUBROUTES } from 'src/app/utils';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient, protected router: Router) {}

  getClients(params: GetClientsParams = {}, highLevel: boolean = false) {
    let endpoint = `clients`;
    let route = this.router?.url;

    if (params?.operatorId && params?.operatorId == -1) {
      delete params.operatorId;
    }

    // if (
    //   route === '/user/machines-status/till-state' ||
    //   route === '/user/machines-status/current-state'
    // ) {
    //   route = '/user/machines-status';
    // }

    if(MACHINE_STATUS_SUBROUTES.includes(route)){
      route = MACHINE_STATUS_ROUTE;
    }

    return this.httpClient
      .post<Client[]>(this.serviceUrl + endpoint + `?route=${route}`, params)
      .pipe(
        map((res) => {
          if (!highLevel) {
            res = res.filter((client) => client.is_valid === 1);
          }
          if ((res || []).length > 0) {
            res = res.sort((client1, client2) =>
              client1.description.localeCompare(client2.description)
            );
          }
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getClientsAena() {
    let endpoint = `getClientsAena`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<ClientAena[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  createClient(params: ParamsCreateClient) {
    let endpoint = `client`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<Client>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  editClient(params: EditClient) {
    let endpoint = `editClient`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<Client>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getClientType() {
    let endpoint = `client_type`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}
