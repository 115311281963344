import { WritableSignal } from '@angular/core';

// models
import { Supervisor } from 'src/app/models';

interface GetSupervisorByOperatorRM {
  supervisors: WritableSignal<Supervisor[]>;
  loadingSupervisors: WritableSignal<boolean>;
  supervisorsByOperator: WritableSignal<Supervisor[]>;
  operatorId: number;
}

export const getSupervisorByOperatorRM = ({
  supervisors,
  loadingSupervisors,
  supervisorsByOperator,
  operatorId,
}: GetSupervisorByOperatorRM) => {
  if ((supervisors() || []).length === 0 || loadingSupervisors()) {
    supervisorsByOperator.set([]);
    return;
  }

  const supervisorFiltered = supervisors().filter(
    (supervisor) => supervisor.OperatorID === operatorId
  );
  supervisorsByOperator.set(supervisorFiltered);
};
