import { Injectable } from '@angular/core';
import { Subject, lastValueFrom } from 'rxjs';

// services
import { RegisterCardsVisibilitiesService } from './';

// models
import { OperatorGroup, Operator, Client } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class FilterVisibilityRegisterCardService {
  operatorGroups: OperatorGroup[] = [];
  operatorsAll: Operator[] = [];
  clientsAll: Client[] = [];

  operators: Operator[] = [];
  clients: Client[] = [];

  operatorGroupSelected: OperatorGroup = null;
  operatorSelected: Operator = null;
  clientSelected: Client = null;

  loadingVisibilities: boolean = true;

  changeVisibility$: Subject<changeLoadingVisibility> = new Subject<changeLoadingVisibility>();

  constructor(
    private _registerCardsVisibilitiesService: RegisterCardsVisibilitiesService
  ) {}

  start() {
    this.loadingVisibilities = true;
    this.changeEmit();

    Promise.all([
      this.getOperatorGroupsPromise(),
      this.getOperatorsPromise(),
      this.getClientsPromise(),
    ]).then(([operatorGroups, operators, clients]) => {
      this.operatorGroups = operatorGroups;
      this.operatorsAll = operators;
      this.clientsAll = clients;

      if (this.operatorGroups.length === 1) {
        this.operatorGroupSelected = this.operatorGroups[0];
        this.selectOperator();
      }

      this.loadingVisibilities = false;

      this.changeEmit();
    });
  }

  changeEmit() {
    this.changeVisibility$.next({
      loadingVisibilities: this.loadingVisibilities,
    });
  }

  selectOperator() {
    this.operators = this.operatorsAll.filter(
      (operator) => operator.OperatorGroupId === this.operatorGroupSelected.ID
    );

    if (this.operators.length === 1) {
      this.operatorSelected = this.operators[0];
      this.selectClient();
    } else {
      this.operatorSelected = null;
      this.clientSelected = null;
    }
  }

  selectClient() {
    this.clients = this.clientsAll.filter(
      (client) => client?.OperatorId === this.operatorSelected.id
    );

    if (this.clients.length === 1) {
      this.clientSelected = this.clients[0];
    } else {
      this.clientSelected = null;
    }
  }

  resetSelected() {
    if (this.operatorGroups.length > 1) {
      this.operatorGroupSelected = null;
    }

    if (this.operators.length > 1) {
      this.operatorSelected = null;
    }

    if (this.clients.length > 1) {
      this.clientSelected = null;
    }
  }

  getOperatorGroups() {
    return this.operatorGroups;
  }

  getOperators() {
    return this.operators;
  }

  getClients() {
    return this.clients;
  }

  getShowOperatorGroup(): boolean {
    return this.operatorGroups.length > 1;
  }

  getShowOperator(): boolean {
    return this.operatorsAll.length > 1;
  }

  getOperatorsAll() {
    return this.operatorsAll;
  }

  getClientsAll() {
    return this.clientsAll;
  }

  getOperatorGroupsPromise() {
    return lastValueFrom(
      this._registerCardsVisibilitiesService.getOperatorGroups()
    );
  }

  getOperatorsPromise() {
    return lastValueFrom(this._registerCardsVisibilitiesService.getOperators());
  }

  getClientsPromise() {
    return lastValueFrom(this._registerCardsVisibilitiesService.getClients());
  }

  getLoadingVisibilities() {
    return this.loadingVisibilities;
  }
}

interface changeLoadingVisibility {
  loadingVisibilities: boolean;
}
