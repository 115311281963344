type ActivateDesactivateTextTranslate =
  | 'desactivate'
  | 'activateDesactivate'
  | 'activate';

export const ACTIVATE_DESACTIVATE_TEXT_TRANSLATE: Record<
  ActivateDesactivateTextTranslate,
  string
> = {
  desactivate:
    'machines_status.machine_status_alert.machine_alert_modal.turn_off_alerts',
  activateDesactivate:
    'machines_status.machine_status_alert.machine_alert_modal.activate_desactivate',
  activate: 'machines_status.machine_status_alert.machine_alert_modal.activate',
};
