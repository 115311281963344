// models
import { FamilyAena } from '../models';
import { SubGroupAENA } from '../subgroup/models';

export const getFamiliesAenaBySubgroups = (
  subgroupsAena: SubGroupAENA[]
): FamilyAena[] => {
  if (!subgroupsAena) subgroupsAena = [];

  return subgroupsAena.map((subgroup) => ({
    id: subgroup.ID,
    Code: subgroup.Code,
    Description: subgroup.Description,
  }));
};
