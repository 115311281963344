import {
  getClassNamesFilterArticle,
  getClassNamesPayments,
  getClassNamesPositionFilterArticle,
  getClassNamesTitle,
  getClassNamesTitleArticleBill,
  getClassNamesTitleArticleUnit,
  getClassNamesTitlePosition,
  getClassNamesTitleTotalGrossByFamily,
  getClassNamesUnitsBilling,
} from '../style-responsive';

export const getAreasClassNames = (width: number, isFixMenu: boolean) => {
  return {
    typePayments: getClassNamesPayments(width, isFixMenu),
    unitBilling: getClassNamesUnitsBilling(width, isFixMenu),
    filterArticle: getClassNamesFilterArticle(width, isFixMenu),
    titleCharts: getClassNamesTitle(width),
    titlePositionChart: getClassNamesTitlePosition(isFixMenu),
    titleUnit: getClassNamesTitleArticleUnit(width, isFixMenu),
    titleBill: getClassNamesTitleArticleBill(width, isFixMenu),
    titleFamily: getClassNamesTitleTotalGrossByFamily(isFixMenu),
    positionFilterArticle: getClassNamesPositionFilterArticle(width, isFixMenu),
  };
};
