import { DIFF_DAYS_REPORT } from 'src/app/pages/user/listings/reports/utils';
import { MINDATE } from 'src/app/utils';

export const MIN_DATE_FILTER = new Date(MINDATE);

export const ADD_DAYS_IN_MODAL: number = 90;

export const DIFF_DAYS_REPORT_MODAL = DIFF_DAYS_REPORT;

export const TRANSACTION_QUERY_SERVICE_ROUTES = [
  '/user/transactions-query',
  '/user/transactions-query-aena',
];

export const FUTURE_DATE_SERVICES_ROUTES = [
  '/user/ifree-vend-manager',
  '/user/machines-status/till-state',
  '/user/machines-status/current-state',
];
