interface IsCodeValidBySubcode {
  code: number;
  subcode: number;
}

export const isCodeValidBySubcode = ({
  code,
  subcode,
}: IsCodeValidBySubcode) => {
  const codeStart = (code || '').toString().substring(0, 4);

  return codeStart === subcode.toString();
};
