// External Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { LoadCreditCardComponent } from './load-credit-card.component';

// Service
import { LanguageService } from '../../../../../../../share/services/languages.service';
import { SkinMangementService } from '../../../../../../../services/skin-mangement.service';


@NgModule({

  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],

  declarations: [
    LoadCreditCardComponent,
  ],

  providers: [
    LanguageService,
    SkinMangementService,
  ],

  exports: [
    LoadCreditCardComponent
  ],
})
export class LoadCreditCardModule { }
