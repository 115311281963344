import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, throwError } from 'rxjs';

// config
import { AppConfig } from 'src/app/config/config';

// models
import { RouteDriver } from 'src/app/models';
import {
  CreateRouteDriverParams,
  EditRouteDriverParams,
  GetRouteDriversParams,
} from './models';
import { ROUTE_DRIVER_MANAGER_ROUTE } from './utils';

@Injectable({
  providedIn: 'root',
})
export class RouteDriverService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient, protected router: Router) {}

  getRouteDrivers(params: GetRouteDriversParams) {
    let endpoint = `getRouteDrivers`;
    let routeUrl = this.router.url;

    const isEditRouteDriver = routeUrl === ROUTE_DRIVER_MANAGER_ROUTE;

    return this.httpClient
      .post<RouteDriver[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          if(!isEditRouteDriver){
            res = res.filter(routeDriver => routeDriver.routeDriver_enable === 1)
          }
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  createRouteDriver(params: CreateRouteDriverParams) {
    let endpoint = `routeDriver/create`;
    let routeUrl = this.router.url;
    return this.httpClient
      .post<RouteDriver>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  editRouteDriver(params: EditRouteDriverParams) {
    let endpoint = `routeDriver/edit`;
    let routeUrl = this.router.url;
    return this.httpClient
      .post<RouteDriver>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  deleteRouteDriver(routeDriverId: number) {
    let endpoint = `routeDriver/delete`;
    let routeUrl = this.router.url;

    const params: { routeDriverID: number } = { routeDriverID: routeDriverId };
    return this.httpClient
      .post<RouteDriver>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}
