// models
import { ClientDashboardWelcome } from '../../models';

// constant
import { ALLIANCE_DASHBOARD_TEXT, EXTRA_INFO_CLIENTS } from '..';

export const getExtraInfoClient = (clientSelected: ClientDashboardWelcome) => {
  if (!clientSelected) {
    return '';
  }

  if (!EXTRA_INFO_CLIENTS.includes(clientSelected?.description)) {
    return '';
  }

  return `(${clientSelected.haveAlliance ? ALLIANCE_DASHBOARD_TEXT : ''}${
    clientSelected.haveAlliance && clientSelected.haveEnriqueTomas ? '-' : ''
  }${clientSelected.haveEnriqueTomas ? 'Enrique Tomás' : ''})`;
};
