export interface PermissionsTransactionsQuery{
    loadPermission: boolean,
    permissionTotal: boolean,
    permissionTicket: boolean,
    permissionAllTypes: boolean;
    isAena: boolean;
    nameRoute: PermissionsTransactionsQueryNames;
}

export enum PermissionsTransactionsQueryNames {
  'totals' = 'Consulta General con totales',
  'ticket' = 'Consulta General con ticket',
  'allTypes' = 'Consulta General All types',
  'ticketAndTotals' = 'Consulta General con ticket y totales',
  'normal' = 'Consulta General'
}
