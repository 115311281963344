import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterAenaShareService {

  clientId: number = null;
  constructor() { }

  getClientId(){
    return this.clientId;
  }

  setClientId(clientId: number){
    this.clientId = clientId;
  }
}
