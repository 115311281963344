import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// models
import { Company, FilterDateServiceI } from 'src/app/models';
import { CheckSegment, RangeDate } from '../../../../models';

// utils
import { type } from 'src/app/utils';


@Component({
  selector: 'app-datepicker-range-material-input',
  templateUrl: './datepicker-range-material-input.component.html',
  styleUrls: ['./datepicker-range-material-input.component.scss'],
})
export class DatepickerRangeMaterialInput implements OnInit {

  @Input() company: Company;
  @Input() dateText: string = '';
  @Input() _filterDate: FilterDateServiceI;
  @Input() rangePeriod: RangeDate;
  @Input() checkSegment: CheckSegment;

  type = type;


  @Output() changePeriod = new EventEmitter<{
    startDate: Date | null;
    endDate: Date | null;
  }>();

  constructor() { }

  ngOnInit() {}

  changedPeriod({ startDate, endDate }: { startDate: Date; endDate: Date }){
    this.changePeriod.emit({startDate, endDate})
  }

}
