import { WritableSignal } from '@angular/core';

// models
import {
  STATUS_NAME_BY_TYPE_MACHINE_ALERT_OPTIONS,
  StatusMachineAlert,
  TypeMachineAlertOptions,
} from '../models';
import { PERFOMANCE_TYPE_ALERT } from './constant';

interface GetStatusIdByTypeMachineAlert {
  statusAlert: StatusMachineAlert[];
  typeAlertSelected: TypeMachineAlertOptions;
  haveBankSales: WritableSignal<boolean>;
}

export const getStatusIdByTypeMachineAlert = ({
  statusAlert,
  typeAlertSelected,
  haveBankSales,
}: GetStatusIdByTypeMachineAlert) => {
  // const typeSelectedName =
  //   STATUS_NAME_BY_TYPE_MACHINE_ALERT_OPTIONS[typeAlertSelected];

  const typeSelectedName = getTypeSelectedName(
    typeAlertSelected,
    haveBankSales
  );

  const statusAlertSelected = statusAlert.find(
    (status) =>
      (status.Description || '').toLowerCase() ===
      (typeSelectedName || '').toLowerCase()
  );

  return statusAlertSelected?.ID || null;
};

const getTypeSelectedName = (
  typeAlertSelected: TypeMachineAlertOptions,
  haveBankSales: WritableSignal<boolean>
) => {
  if (
    Number(typeAlertSelected) === TypeMachineAlertOptions.Activity &&
    haveBankSales()
  ) {
    return PERFOMANCE_TYPE_ALERT.haveBankSales;
  }

  return STATUS_NAME_BY_TYPE_MACHINE_ALERT_OPTIONS[typeAlertSelected];
};
