import { getLastDayWithInfo } from "./get-last-day-with-info";

export const getLastShortDate = () => {
  const currentMonth = getLastDayWithInfo().getMonth()

  return new Array(13)
    .fill(null)
    .map((_, i) => `${getYearShortDate(i)}${getMonthShortDate(currentMonth, i)}`);
};

const getYearShortDate = (position: number) => {
  const currentMonth = getLastDayWithInfo().getMonth();
  const currentYear = getLastDayWithInfo().getFullYear();

  return currentMonth + position + 1 > 12 ? currentYear : currentYear - 1;
};


const getMonthShortDate = (currentMonth: number, i: number) => {
  return (((currentMonth + i) % 12) + 1).toString().padStart(2, '0')
}
