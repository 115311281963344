// External modules
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Modules


// Component
import { CardWithTitleCompanyTemplateComponent } from './card-with-title-company-template.component';


// Service
import { LanguageService } from '../../../services/languages.service';
import { SkinMangementService } from '../../../../services/skin-mangement.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    HttpClientModule,

  ],
  declarations: [
    CardWithTitleCompanyTemplateComponent
  ],
  providers: [
    LanguageService,
    SkinMangementService,
  ],
  exports: [
    CardWithTitleCompanyTemplateComponent
  ],
})
export class CardsWithTitleCompanyTemplateModule {}
