import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// services
import { FilterTillAlertService, TillAlertService } from '../../../services';
import { SkinMangementService } from '../../../../../../../services';

// models
import { Company } from 'src/app/models';
import {
  CharacteristicsTillAlertSpider,
  CharacteristicsTillAlertSpiderWithExtra,
} from '../../../models';

// utils
import { UtilsTranslate } from 'src/app/utils';
import {
  ICON_PRIORITIES,
  ICON_STATUS,
  getCharacteristicsByStatusTillAlert,
  getCharacteristicsByTypeTillAlert,
} from '../../../utils';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-legend-till-alert',
  templateUrl: './legend-till-alert.component.html',
  styleUrls: ['./legend-till-alert.component.scss'],
})
export class LegendTillAlertComponent implements OnInit {
  company: Company;

  loading: boolean = true;
  status: CharacteristicsTillAlertSpider[] = [];
  typesAlert: CharacteristicsTillAlertSpiderWithExtra[] = [];

  iconMode = ICON_STATUS;
  iconPriorities = ICON_PRIORITIES;

  constructor(
    private modalCtrl: ModalController,
    private _skinMangementService: SkinMangementService,
    private _tillAlertService: TillAlertService,
    private _filterTillAlertService: FilterTillAlertService,
    private utils: UtilsTranslate
  ) {}

  ngOnInit() {

    this.company = this._skinMangementService.getCompany();

    this.status = this._filterTillAlertService.getStatus();
    this.typesAlert = this._filterTillAlertService.getTypeAlert();

    if (this.status.length > 0 && this.typesAlert.length > 0) {
      this.loading = false;
    } else {
      this.getStatusAndTypesAlert();
    }
  }

  getStatusAndTypesAlert() {
    Promise.all([this.getStatus(), this.getTypeAlert()])
      .then(([status, typeAlert]) => {
        this.status = getCharacteristicsByStatusTillAlert(status);
        this.typesAlert = getCharacteristicsByTypeTillAlert(typeAlert);
        this.loading = false;
      })
      .catch((error) => {
        this.utils.showError();
      });
  }

  getStatus() {
    return lastValueFrom(this._tillAlertService.getStatus())
  }

  getTypeAlert() {
    return lastValueFrom(this._tillAlertService.getTypeAlert())
  }

  closeModal(){
    this.modalCtrl.dismiss()
  }
}
