import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

// Models
import { Company } from '../../../../models';

// Service
import { SkinMangementService } from '../../../../services/skin-mangement.service';

// Utils
import { getTargetByPlatform } from '../../../../utils/useApkAndroid';

@Component({
  selector: 'app-legal-notice-v2v',
  templateUrl: './legal-notice-v2v.component.html',
  styleUrls: ['./legal-notice-v2v.component.scss'],
})
export class LegalNoticeV2vComponent implements OnInit {

  company: Company;
  target: '_self' | '_blank' = '_blank';

  constructor(
    private platform: Platform,
    private modalCtrl: ModalController,
    private skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this.skinService.getCompany();
    this.target = getTargetByPlatform(this.platform)
  }

  closeModal(state: boolean = false) {
    this.modalCtrl.dismiss({
      accept: state
    });
  }

}
