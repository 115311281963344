import { Component, Input, OnInit } from '@angular/core';

// Service
import { ImageHexService } from '../../services/image-hex/image-hex.service';


@Component({
  selector: 'app-image-hex',
  templateUrl: './image-hex.component.html',
  styleUrls: ['./image-hex.component.scss'],
})
export class ImageHexComponent implements OnInit {

  @Input() widthImage: string;
  @Input() heightImage: string;
  @Input() idDiv: string = 'header-image';
  @Input() idImage: number = 0;

  constructor(private _imageService : ImageHexService) { }

  ngOnInit() {

    this._imageService.getImageById(0).subscribe((res: any) => {

          let newDiv = this.createImages(res.image, 'image01');


          let currentDiv = document.getElementById('header-image');

          var theFirstChild = currentDiv.firstChild;

          currentDiv.insertBefore(newDiv, theFirstChild);
        });
  }


  createImages(hexImage, id_article) {
    let input = hexImage
    if (input) {
      input = input.replace(/[^A-Fa-f0-9]/g, "")
      if (input.length % 2) {
        return;
      }

      let binary = new Array();
      for (let i = 0; i < input.length / 2; i++) {
        let h = input.substr(i * 2, 2);
        binary[i] = parseInt(h, 16);
      }


      let byteArray = new Uint8Array(binary);
      var img = document.createElement("img");
      img.src = URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
      img.id = id_article;
      //img.style.width = '20%';
      //img.style.height = '20%';
      //console.log(img)
      return img
    }
  }

}
