import { Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { IonSegment } from '@ionic/angular';

// Swipper
import { SwiperComponent } from 'swiper/angular';
import Swiper, { Pagination, SwiperOptions } from 'swiper';

// models
import { Company, HaveVisibility } from 'src/app/models';
import { LoadingVisibilityClientTillSelect } from '../models';

// utils
import { pcWidth } from '../../../../utils/constants';


@Component({
  selector: 'app-visibility-client-till-select-sm',
  templateUrl: './visibility-client-till-select-sm.component.html',
  styleUrls: ['./visibility-client-till-select-sm.component.scss'],
})
export class VisibilityClientTillSelectSmComponent implements OnInit {

  config: SwiperOptions = {
    pagination: { clickable: true, type: 'bullets', dynamicBullets: true },
    keyboard: true
  };

  @Input() company: Company;
  @Input() haveClearButton: boolean = true;

  @Input() showClient: boolean = false;
  @Input() showTill: boolean = false;
  @Input() haveVisibility: HaveVisibility = { client: false, operator: false, operatorGroup: false }

  @Input() loadings: LoadingVisibilityClientTillSelect = {
    loadingClient: true, loadingTill: true, loadingMore1Client: true, loadingMore1Till: true
  };
  @Input() nameClient: string = '';
  @Input() codeMachine: number = null;

  @Input() haveMore1Client: boolean = false;
  @Input() haveMore1Till: boolean = false;

  @Output() showClientTillModal = new EventEmitter()
  @Output() clearHandle = new EventEmitter();

  @Input() pcWidth = pcWidth;

  segment = '0';

  @ViewChild(IonSegment) segment2: IonSegment;
  @ViewChild('swiperVisibilityClientTill', { static: false }) swiper: SwiperComponent;

  positions = {
    "0": null,
    "1": null,
    "2": 0,
  }

  constructor(
  ) { }

  ngOnInit() {
    Swiper.use([Pagination])
    this.startSegment()
  }

  ngOnChanges(changes: SimpleChanges): void {


    if(changes?.showClient || changes?.showTill){
      this.startSegment()
    }else if(changes?.haveVisibility){
      this.startSegment()
    }

  }


  segmentChanged() {
    this.swiper?.swiperRef?.slideTo(this.positions[Number(this.segment)]);
  }

  async slideChange() {
    let active = this.swiper?.swiperRef?.activeIndex;
    let key = Object.keys(this.positions).find(key => this.positions[key] === active);
    this.segment2.value = (key).toString();

    document.getElementById("segment-" + this.segment).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest'
    });
  }


  async showClientFilterTill() {
    this.showClientTillModal.emit()
  }

  clearFilter() {
    this.clearHandle.emit()
  }

  startSegment(moveSlideAndSegment = true) {
    setTimeout(() => {
      this.positions = {
        "0": null,
        "1": null,
        "2": null
      }

      if (this.showClient || this.showTill) {
        this.positions = {
          "0": null,
          "1": null,
          "2": 0
        }
      }

      if (!this.haveVisibility.operator) {
        this.positions = {
          "0": null,
          "1": 0,
          "2": (this.showClient || this.showTill) ? 1 : null
        }
      }

      if (!this.haveVisibility.operatorGroup) {

          this.positions = {
            "0": 0,
            "1": 1,
            "2": (this.showClient || this.showTill) ? 2 : null
        }
      }

      //console.log('positions', this.positions)

      if(moveSlideAndSegment){
        let key = Object.keys(this.positions).find(key => this.positions[key] === 0);
        if(key){
          this.segment = key;
          this.swiper?.swiperRef?.slideTo(0);
        }

      }

    }, 100)

  }


}
