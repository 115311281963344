import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';

// models
import { Company } from 'src/app/models';
import { FilterTemplateObjectTripleInputOneOption } from '../../filter-template-object-triple-inputs-modal';


@Component({
  selector: 'app-filter-template-one-elem-position-share',
  templateUrl: './filter-template-one-elem-position-share.component.html',
  styleUrls: ['./filter-template-one-elem-position-share.component.scss'],
})
export class FilterTemplateOneElemPositionShareComponent implements OnInit {
  @Input() company: Company;
  @Input() search: WritableSignal<string>;
  @Input() textSearch: string;
  @Input() elementsInput: FilterTemplateObjectTripleInputOneOption;
  @Input() numElements = 50;
  @Input() elementSelected: WritableSignal<any> = signal(null);

  @Output() changeSearch = new EventEmitter();

  elementsFilter: WritableSignal<any[]> = signal([]);
  elementsShower: WritableSignal<any[]> = signal([]);


  constructor() {}

  ngOnInit() {
    this.start();
  }

  start() {
    this.elementsFilter.set(this.elementsInput?.elements || []);
    this.elementsShower.set([]);
    if (this.elementsFilter().length < this.numElements) {
      this.elementsShower.set(
        this.elementsShower().concat(
          this.elementsFilter().slice(0, this.elementsFilter().length)
        )
      );
    } else {
      this.elementsShower.set(
        this.elementsShower().concat(
          this.elementsFilter().slice(0, this.numElements)
        )
      );
    }

    if (this.search()) {
      this.changeInput();
    }
  }

  changeInput() {
    if (!this.search()) {
      this.start();
    } else {
      this.elementsShower.set([]);

      const { elements, nameSearchElement, nameId } = this.elementsInput;

      const newElementFilter = elements.filter((element) =>
        element?.[nameSearchElement]
          ?.toString()
          .toLowerCase()
          .trim()
          .includes(this.search()?.toString().toLowerCase().trim())
      );

      this.elementsFilter.set(
        newElementFilter
      );
      if (this.elementsFilter().length < this.numElements) {
        this.elementsShower.set(
          this.elementsShower().concat(
            this.elementsFilter().slice(0, this.elementsFilter().length)
          )
        );
      } else {
        this.elementsShower.set(
          this.elementsShower().concat(
            this.elementsFilter().slice(0, this.numElements)
          )
        );
      }

      let elementFilter = elements.find(
        (element) =>
          element?.[nameSearchElement]?.toString().toLowerCase().trim() ===
          this.search()?.toString().toLowerCase().trim()
      );
      elementFilter
        ? this.elementSelected.set(elementFilter[nameId])
        : this.elementSelected.set(null);
    }
  }

}
