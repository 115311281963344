// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-segment-button-style {
  --background-checked: var(--ion-color-alliance);
  --color-checked: var(--ion-color-light);
  --indicator-color: transparent;
  --margin-top: 0px;
  --margin-bottom: 0px;
}

::ng-deep .width-size-lg {
  --width: 95%;
  --max-width: 400px;
}

.center-menu {
  align-items: center !important;
  flex-flow: column !important;
  justify-content: center !important;
  margin-left: 200px;
}

.space-filter-client {
  margin: 25px;
  width: 100%;
  height: 50%;
  /* Center vertically and horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  width: 50px;
  height: 50px;
}

.w-100 {
  width: 95% !important;
  min-height: 150px;
}

.sliders .sliders-pager {
  text-align: right;
  margin-top: 200px !important;
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/visibility-client-select/visibility-client-select-sm/visibility-client-select-sm.component.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;EACA,uCAAA;EACA,8BAAA;EACA,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,8BAAA;EACA,4BAAA;EACA,kCAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,WAAA;EAEA,uCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AACA;EACE,qBAAA;EACA,iBAAA;AAEF;;AACA;EACE,iBAAA;EACA,4BAAA;AAEF;;AAEA;;EAEE,aAAA;AACF","sourcesContent":[".ion-segment-button-style {\r\n  --background-checked: var(--ion-color-alliance);\r\n  --color-checked: var(--ion-color-light);\r\n  --indicator-color: transparent;\r\n  --margin-top: 0px;\r\n  --margin-bottom: 0px;\r\n}\r\n\r\n::ng-deep .width-size-lg {\r\n  --width: 95%;\r\n  --max-width: 400px;\r\n}\r\n\r\n.center-menu {\r\n  align-items: center !important;\r\n  flex-flow: column !important;\r\n  justify-content: center !important;\r\n  margin-left: 200px;\r\n}\r\n\r\n.space-filter-client {\r\n  margin: 25px;\r\n  width: 100%;\r\n  height: 50%;\r\n\r\n  /* Center vertically and horizontally */\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n.center {\r\n  width: 50px;\r\n  height: 50px;\r\n}\r\n.w-100 {\r\n  width: 95% !important;\r\n  min-height: 150px;\r\n}\r\n\r\n.sliders .sliders-pager {\r\n  text-align: right;\r\n  margin-top: 200px !important;\r\n}\r\n\r\n// inhabilitar la imagen del scroll\r\n::-webkit-scrollbar,\r\n*::-webkit-scrollbar {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
