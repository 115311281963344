import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

// services
import { SkinMangementService } from 'src/app/services';

// models
import { Company } from 'src/app/models';

// utils
import { CHECK_SUBGROUP_AENA, ROUTE_ARTICLE_MANAGER } from '../../../utils';

@Component({
  selector: 'app-legend-articles-family-aena',
  templateUrl: './legend-articles-family-aena.component.html',
  styleUrls: ['./legend-articles-family-aena.component.scss'],
})
export class LegendArticlesFamilyAenaComponent implements OnInit {

  company: Company;

  isArticleManager = false;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
    public readonly router: Router,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    if(this.router.url === ROUTE_ARTICLE_MANAGER ||  this.router.url === CHECK_SUBGROUP_AENA){
      this.isArticleManager = true;
    }

  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
