import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// models
import { Company, FilterDateServiceI } from 'src/app/models';
import { CheckSegment, RangeDate } from '../../models';


@Component({
  selector: 'app-filter-period',
  templateUrl: './filter-period.component.html',
  styleUrls: ['./filter-period.component.scss'],
})
export class FilterPeriodComponent implements OnInit {
  @Input() company: Company;
  @Input() datePipe = 'dd/MM/y';
  @Input() maxDatePeriod: Date = null;

  @Input() _filterDate: FilterDateServiceI;
  @Input() rangePeriod: RangeDate;

  @Input() startDate: string;
  @Input() endDate: string;

  @Input() checkSegment: CheckSegment;

  filterChange$: Subscription;

  constructor() {}

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  changePeriod({ startDate, endDate }: { startDate: Date; endDate: Date }) {
    if (!startDate || !endDate) {
      return;
    }
    this._filterDate.setDate(startDate, 0);
    this._filterDate.setDate(endDate, 1);

  }

}
