import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

// models
import { Company } from '../../../../models/';

// utils
import { pcWidth } from '../../../../utils/constants';

@Component({
  selector: 'app-visibility-client-select-pc',
  templateUrl: './visibility-client-select-pc.component.html',
  styleUrls: ['./visibility-client-select-pc.component.scss'],
})
export class VisibilityClientSelectPcComponent implements OnInit {

  @Input() company: Company;
  @Input() haveSend: boolean = true;
  @Input() haveClear: boolean = true;
  @Input() haveDateFilter: boolean = false;
  @Input() showClientFilter: boolean = false;
  @Input() loadingSended: boolean = false;
  @Input() canSend: boolean = false;
  @Input() haveMore1Client: boolean = false;

  @Input() dobleDateFilter:boolean = false;
  @Input() showHour: boolean = false;

  @Output() sendFilter = new EventEmitter();
  @Output() clearFilter = new EventEmitter()

  // filter client
  @Input() showCodeClient = true;
  @Input() pcWidth = pcWidth;

  constructor(
  ) { }

  ngOnInit() {
  }

  send(){
    this.sendFilter.emit()
  }

  clear(){
    this.clearFilter.emit()
  }

}
