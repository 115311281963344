import {
  FilterDateExtraHourService,
  FilterDateFutureService,
} from 'src/app/share/services';

interface RemoveDateMachineStatus {
  _filterDateService: FilterDateFutureService;
  _filterDateExtraHourService: FilterDateExtraHourService;
  minDate: Date;
}

export const removeDateMachineStatus = ({
  _filterDateService,
  _filterDateExtraHourService,
  minDate,
}: RemoveDateMachineStatus) => {

  _filterDateService.setDate(minDate, 0);
  _filterDateService.setDate(minDate, 1);

  _filterDateService.setHour('00:00', 0);
  _filterDateService.setHour('23:59', 1);

  _filterDateExtraHourService.setHour('00:00', 0);
  _filterDateExtraHourService.setHour('23:59', 1);

};
