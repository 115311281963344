import { WritableSignal } from '@angular/core';
import { lastValueFrom } from 'rxjs';

// models
import { CardNames, Company } from 'src/app/models';
import { CreateReportParams } from '../../models';

// services
import { LoadingService } from 'src/app/share/services';
import { ReportsService } from '../../services';

// utils
import { durationErrorToast, errorsTypes, UtilsTranslate } from 'src/app/utils';

interface CreateReport {
  company: Company;
  _loadingService: LoadingService;
  _reportService: ReportsService;
  paramsCreate: CreateReportParams;
  utils: UtilsTranslate;
  success: WritableSignal<boolean>;
  isLoadingDisplayed: boolean;
}

export const createReport = async ({
  company,
  _loadingService,
  _reportService,
  paramsCreate,
  utils,
  success,
  isLoadingDisplayed,
}: CreateReport) => {
  const cardNames = CardNames;

  if (!isLoadingDisplayed) {
    _loadingService.present();
  }

  try {
    await lastValueFrom(_reportService.createReport(paramsCreate));
    utils.presentToastTranslate('reports.successfully_create_report', false, 0);
    success.set(true);
    _loadingService.dismiss();
  } catch (error) {
    if (error === errorsTypes.error_type_not_card_report) {
      utils.presentToastTranslate(
        company.nameCard === cardNames.arbitradePay
          ? 'reports.create_edit.not_card_type_alliancepay'
          : 'reports.create_edit.not_card_type_close_loop_card'
      );
    } else if (
      error == errorsTypes.error_invalid_card_report ||
      error === errorsTypes.error_some_invalid_card_report
    ) {
      utils.presentToastCardNameTranslate(
        company.nameCard === cardNames.arbitradePay
          ? 'reports.create_edit.error_invalid_card_alliancepay'
          : 'reports.create_edit.error_invalid_card',
        true,
        0,
        durationErrorToast,
        true
      );
    } else if (error === errorsTypes.error_client_required_report) {
      utils.presentToastTranslate('reports.create_edit.client_required');
    } else {
      utils.presentToastTranslate('reports.error_create_report', true, 0);
    }
    success.set(false);
    _loadingService.close();
  }
};
