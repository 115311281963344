import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

// services
import { PermissionService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class PermissionWithParamsGuard {
  constructor(
    private _permissionService: PermissionService,
    public _router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._permissionService.permissionValidateWithParams(state.url);
  }
}
