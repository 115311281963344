import { WritableSignal } from '@angular/core';

// models
import { DatePickerFilterOptions } from 'src/app/share/components/filter-date-template/models';

// services
import { FilterDateServiceI } from 'src/app/models';

// utils
import { addTimeDate, getPrevMonday, getTomorrowDay } from 'src/app/utils';

interface ApplyChangeTypeReportWeekly {
  datePickerFilter: WritableSignal<DatePickerFilterOptions>;
  _filterDateService: FilterDateServiceI;
}

export const applyChangeTypeReportWeekly = ({
  datePickerFilter,
  _filterDateService,
}: ApplyChangeTypeReportWeekly) => {
  datePickerFilter.set(DatePickerFilterOptions.OnlyMonday);

  changeDateToMondayValid(_filterDateService);
};

const changeDateToMondayValid = (_filterDateService: FilterDateServiceI) => {
  const startDate = new Date(_filterDateService.getDateStart()) ?? new Date();
  const weekDay = startDate.getDay();

  if (weekDay !== 1) {
    const tomorrow = getTomorrowDay();
    if (startDate.getTime() > tomorrow.getTime()) {
      setFirstMondayBySelectedDay(startDate, _filterDateService);
    } else {
      setDayByFirstDayValid(tomorrow, _filterDateService);
    }
  }
};

const getNextMonday = (nextMonday: Date) => {
  return addTimeDate({
    startDate: getPrevMonday(nextMonday),
    increment: 7,
    typeIncrement: 'days',
  });
};

const setFirstMondayBySelectedDay = (
  startDate: Date,
  _filterDateService: FilterDateServiceI
) => {
  const prevMonday = getPrevMonday(startDate);
  if (prevMonday.getTime() >= getTomorrowDay().getTime()) {
    _filterDateService.setDate(prevMonday, 0);
  } else {
    setDateNextMonday(startDate, _filterDateService);
  }
};

const setDayByFirstDayValid = (
  tomorrow: Date,
  _filterDateService: FilterDateServiceI
) => {
  const weekDay = tomorrow.getDay();

  if (weekDay === 1) {
    _filterDateService.setDate(tomorrow, 0);
  } else {
    setDateNextMonday(tomorrow, _filterDateService);
  }
};

const setDateNextMonday = (
  date: Date,
  _filterDateService: FilterDateServiceI
) => {
  const nextMonday = getNextMonday(date);
  _filterDateService.setDate(nextMonday, 0);
};
