import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { ComponentProps } from '@ionic/core';
import { Subscription, lastValueFrom } from 'rxjs';

// Component
import { CreatedIntervalModalComponent } from '../created-interval-modal/created-interval-modal.component';
import { AssignCardModalComponent } from '../assign-card-modal/assign-card-modal.component';
import { CreatedIntervalCardPcComponent } from '../../../../../../pages/user/cards/register-cards/components/subtypes';

// Service
import { TranslateService } from '@ngx-translate/core';
import {
  VisibilitySelectService,
  LanguageRouteService,
  LoadingService,
} from '../../../../../services';
import {
  RegisterCardInterval,
  RegisterCardsService,
  RegisterCard,
  AssignIntervalCardParams,
  AssignCardCheck,
} from '../../../../../services/register-cards/register-cards.service';
import { FilterSelectRegisterCardService } from '../service/filter-select-register-card.service';
import { CardLengthRegisterService } from '../../../../../../pages/user/cards/register-cards/services/card-length-register.service';

// Models
import { Company } from '../../../../../../models';
import { IntervalCard } from '../../../../../../pages/user/cards/register-cards/models/interval-cards.model';
import { ParamsUnlinkClientCard } from '../../../../../../pages/user/cards/register-cards/models/';
import { CarouselType } from '../../../models/carousel-type.type';

// Utils
import {
  errorsTypes,
  UtilsTranslate,
  isNumber,
  successToast,
  showModal,
  replaceVariableText,
} from '../../../../../../utils';
import { MAX_INTERVAL_CARDS } from 'src/app/pages/user/cards/register-cards/utils';

@Component({
  selector: 'app-select-interval-card',
  templateUrl: './select-interval-card.component.html',
  styleUrls: ['./select-interval-card.component.scss'],
})
export class SelectIntervalCardComponent implements OnInit {
  @Input() company: Company;
  @Input() intervalCard: IntervalCard = { posStart: '', posEnd: '' };
  @Input() showError: boolean = false;

  @Input() haveBack: boolean = true;

  @Input() isRegister: boolean = false;
  @Input() clientId: number = null;

  @Input() isUnLinked: boolean = false;

  @Output() handleBack = new EventEmitter<CarouselType>();
  @Output() created = new EventEmitter<boolean>();

  firstColumnRequiredTranslate = '';
  hasNumberTranslate = '';
  diffLengthTranslate = '';
  diffDiffTranslate = '';
  diffLessZeroTranslate = '';
  min6Translate = '';
  maxErrorTranslate = '';
  createdTranslate = '';

  error1 = false;
  error2 = false;
  messageError = '';

  loadingInterval: boolean = false;

  minCard: number = 6;
  maxCard: number = 8;

  changeLanguage$: Subscription;
  resetCards$: Subscription;

  constructor(
    private platform: Platform,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private _languageRouteService: LanguageRouteService,
    private translate: TranslateService,
    private _filterSelectRegisterCardService: FilterSelectRegisterCardService,
    private _registerCardsService: RegisterCardsService,
    private _visibilityService: VisibilitySelectService,
    private _loadingService: LoadingService,
    private _cardLengthService: CardLengthRegisterService,
    private utils: UtilsTranslate
  ) {}

  ngOnInit() {
    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    this.minCard = this._cardLengthService.getMinLengthCard();
    this.maxCard = this._cardLengthService.getMaxLengthCard();

    this.resetCards$ = this._filterSelectRegisterCardService.resetCards.subscribe(
      (res) => {
        if (res) {
          this.intervalCard = { posStart: '', posEnd: '' };
          this.showError = false;
        }
      }
    );

    this.validateInput();
  }

  ngOnDestroy(): void {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if (this.resetCards$) this.resetCards$.unsubscribe();
  }

  validateInput() {
    let start = this.intervalCard.posStart;
    let end = this.intervalCard.posEnd;

    // 6 caracteres
    if (
      (start && start.length < this.minCard) ||
      (end && end.length < this.minCard)
    ) {
      this.error1 = start && start.length < this.minCard;
      this.error2 = end && end.length < this.minCard;
      this.messageError = this.min6Translate;
    }

    // 8 o 9 caracteres segun company
    else if (
      (start && start.length > this.maxCard) ||
      (end && end.length > this.maxCard)
    ) {
      this.error1 = start && start.length > this.maxCard;
      this.error2 = end && end.length > this.maxCard;
      this.messageError = this.maxErrorTranslate;
    }

    // intervalo sin el primer valor
    else if (!start && end) {
      this.error1 = true;
      this.error2 = false;
      this.messageError = this.firstColumnRequiredTranslate;
    }

    // intervalo sin numeros
    else if (start && end && (!isNumber(start) || !isNumber(end))) {
      this.error1 = !isNumber(start);
      (this.error2 = !isNumber(end)),
        (this.messageError = this.hasNumberTranslate);
    }

    // intervalo misma longitud
    else if (start && end && start.length != end.length) {
      this.error1 = false;
      this.error2 = true;
      this.messageError = this.diffLengthTranslate;
    }

    // diferencia superior a 500 numeros
    else if (start && end && Number(end) - Number(start) > MAX_INTERVAL_CARDS) {
      this.error1 = false;
      this.error2 = true;
      this.messageError = this.diffDiffTranslate;
    }

    // segundo valor del intervalo inferior
    else if (start && end && Number(end) - Number(start) < 0) {
      this.error1 = true;
      this.error2 = false;
      this.messageError = this.diffLessZeroTranslate;
    }

    // primer valor es requerido
    else if (!start && !end) {
      this.error1 = true;
      this.error2 = false;
      this.messageError = this.firstColumnRequiredTranslate;
    } else {
      this.error1 = false;
      this.error2 = false;
      this.messageError = '';
    }
  }

  savePlaceholders() {
    this.translate
      .get([
        'register_cards.interval_pc.first_interval_required',
        'register_cards.interval_pc.be_number',
        'register_cards.interval_pc.same_length',
        'register_cards.interval_pc.diff_100',
        'register_cards.interval_pc.diff_0',
        'register_cards.select_pc.min_card_length',
        'register_cards.select_pc.max_card_length',
        'register_cards.selected_interval.created_alliancepay',
        'register_cards.selected_interval.created_v2v',
        'register_cards.interval_pc.diff_max',
      ])
      .subscribe((translates) => {
        this.firstColumnRequiredTranslate =
          translates['register_cards.interval_pc.first_interval_required'];
        this.hasNumberTranslate =
          translates['register_cards.interval_pc.be_number'];
        this.diffLengthTranslate =
          translates['register_cards.interval_pc.same_length'];

        this.diffDiffTranslate = replaceVariableText(
          translates['register_cards.interval_pc.diff_max'] || '',
          MAX_INTERVAL_CARDS.toString(),
          'MAX_INTERVAL_CARDS'
        );

        this.diffLessZeroTranslate =
          translates['register_cards.interval_pc.diff_0'];
        this.min6Translate = this.getMinTranslate(
          translates['register_cards.select_pc.min_card_length'],
          this.minCard
        );
        this.maxErrorTranslate = this.getMaxTranslate(
          translates['register_cards.select_pc.max_card_length'],
          this.maxCard
        );
        if (this.company.nameCard == 'AlliancePay') {
          this.createdTranslate =
            translates['register_cards.selected_interval.created_alliancepay'];
        } else {
          this.createdTranslate =
            translates['register_cards.selected_interval.created_v2v'];
        }
      });
  }

  getMinTranslate(minTranslate, min) {
    return minTranslate.replace('{{min}}', min);
  }

  getMaxTranslate(maxTranslate, max) {
    return maxTranslate.replace('{{max}}', max);
  }

  back() {
    this.handleBack.emit('IntervalCard');
  }

  async sendCard() {
    if (this.messageError) {
      this.showError = true;
      return;
    }

    if (!this.intervalCard.posStart) {
      this.validateInput();
      this.showError = true;
      // this._loadingService.dismiss();
      this.loadingInterval = false;
      return;
    }

    this.loadingInterval = true;
    this._loadingService.present();

    if (this.isUnLinked) {
      await this.unLinkedCard();
    } else if (this.isRegister) {
      // dar de alta
      let params: RegisterCardInterval = {
        Operator_id: this._visibilityService.getOperatorSelected(),
        VT_interval1: this.intervalCard.posStart.trim(),
        VT_interval2: this.intervalCard.posEnd
          ? this.intervalCard.posEnd.trim()
          : this.intervalCard.posStart.trim(),
      };
      try {
        let resRegisterCard = await lastValueFrom(
          this._registerCardsService.registerCardInterval(params)
        );

        // console.log('res', res);
        this.loadingInterval = false;
        this._loadingService.dismiss();
        if (!resRegisterCard?.not_created) {
          successToast(this.createdTranslate, this.toastCtrl);
          this.created.emit(true);
        } else {
          this.showCreatedInterval(resRegisterCard);
        }
      } catch (error) {
        this.loadingInterval = false;
        this._loadingService.dismiss();
        if (error == errorsTypes.all_exist_card) {
          // this.utils.presentToastTranslate(
          //   'register_cards.selected_interval.error_all_exist',
          //   true,
          //   0
          // );
          this.showAllCreated();
        } else {
          this.utils.presentToastTranslate('error', true, 0);
        }
      }
    } else {
      // asignar cards interval
      let params: AssignIntervalCardParams = {
        Client_id: this.clientId,
        VT_interval1: this.intervalCard.posStart.trim(),
        VT_interval2: this.intervalCard.posEnd
          ? this.intervalCard.posEnd.trim()
          : this.intervalCard.posStart.trim(),
      };

      let resCheck = await lastValueFrom(
        this._registerCardsService.checkCardList(params)
      );

      //console.log('res check', resCheck);
      if (
        resCheck.cards_with_clients.length > 0 ||
        resCheck.not_exist_cards.length > 0
      ) {
        this._loadingService.dismiss();
        await this.showModal(resCheck, params);
        this.loadingInterval = false;
      } else {
        this.loadingInterval = false;
        this._loadingService.dismiss();
        this.changeClients(params);
      }
    }
  }

  async showAllCreated() {
    let interval: IntervalCard = {
      notCreateds: ['all'],
      posStart: this.intervalCard?.posStart,
      posEnd: this.intervalCard?.posEnd,
    };

    const props: ComponentProps = {
      intervals: [interval],
    };

    showModal(
      this.modalCtrl,
      CreatedIntervalCardPcComponent,
      props,
      '',
      this.platform.width()
    );
  }

  async unLinkedCard() {
    try {
      let params: ParamsUnlinkClientCard = {
        VT_interval1: this.intervalCard.posStart.trim(),
        VT_interval2: this.intervalCard.posEnd
          ? this.intervalCard.posEnd.trim()
          : this.intervalCard.posStart.trim(),
      };
      await lastValueFrom(this._registerCardsService.unlinkCLientCard(params));

      this.utils.presentToastTranslate(
        'register_cards.select_pc.unlinked_text_successfuly',
        false,
        0
      );
      this.intervalCard = { posStart: '', posEnd: '' };
      this._loadingService.dismiss();
      this.loadingInterval = false;
      this.created.emit();
    } catch (error) {
      if (error === errorsTypes.incorrect_interval_card) {
        this.utils.presentToastTranslate(
          this.company.nameCard === 'AlliancePay'
            ? 'register_cards.select_pc.unlinked_interval_not_exist1'
            : 'register_cards.select_pc.unlinked_interval_not_exist2',
          true,
          0
        );
      } else {
        this.utils.showError();
      }
      this._loadingService.dismiss();
      this.loadingInterval = false;
    }
  }

  async showCreatedInterval(res: RegisterCard) {
    const modal = await this.modalCtrl.create({
      component: CreatedIntervalModalComponent,
      componentProps: {
        notCreated: res?.not_created,
      },
    });
    if (modal) await modal.present();
  }

  async showModal(res: AssignCardCheck, params) {
    const modal = await this.modalCtrl.create({
      component: AssignCardModalComponent,
      componentProps: {
        cardsWithClients: res?.cards_with_clients || [],
        notExistCards: res?.not_exist_cards || [],
      },
    });

    if (modal) {
      await modal.present();
      const { data } = await modal.onWillDismiss();

      if (data?.changeClient) {
        //console.log('change client');
        this.changeClients(params);
      }
    }
  }

  changeClients(params) {
    this.loadingInterval = true;
    this._loadingService.present();
    this._registerCardsService.assignCardInterval(params).subscribe({
      next: (res) => {
        this.loadingInterval = false;
        this._loadingService.dismiss();
        if (res?.not_exist_cards && res?.not_exist_cards.length == 0) {
          this.utils.presentToastTranslate(
            this.company.nameCard == 'AlliancePay'
              ? 'register_cards.selected_interval.assigned_alliancepay'
              : 'register_cards.selected_interval.assigned_v2v',
            false,
            0
          );
          this.created.emit(true);
        } else {
          this.utils.presentToastTranslate('error', true, 0);
        }
      },
      error: () => {
        this.loadingInterval = false;
        this._loadingService.dismiss();
        this.utils.presentToastTranslate('error', true, 0);
      },
    });
  }
}
