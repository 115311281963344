import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Company,  OperatorGroup } from 'src/app/models';
import { TicketCheckModalsModule } from 'src/app/pages/user/machines/ticket-check/components/modals/ticket-check-modals.module';
import { SkinMangementService } from 'src/app/services';
import { successToast, type } from 'src/app/utils';
import { LanguageRouteService } from '../../services';
import { FilterOperatorModalService } from '../filter-operator-modal/services/filter-operator-modal.service';
import { OperatorModalService } from '../filter-operator-modal/services/operator-modal.service';
import { FilterGroupOperatorModalService } from './services/filter-group-operator-modal.service';
import { GroupOperatorModalService } from './services/group-operator-modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-group-operator-modal',
  templateUrl: './filter-group-operator-modal.component.html',
  styleUrls: ['./filter-group-operator-modal.component.scss'],
})
export class FilterGroupOperatorModalComponent implements OnInit {

    @Input() operatorsGroupFullInput: OperatorGroup[] = [];

    loadingOperatorGroup:boolean = false;
    deleteOperatorGroupFilterTranslate = '';
    operatorGroupSelected:number = null
    operatorGroup: string = '';
    operatorsGroup: OperatorGroup[] = [];
    operatorGroupFilter: OperatorGroup[] = [];

    company: Company;
    type = type;

    changeLanguage$: Subscription;

    @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private modalCtrl: ModalController,
    private toastCrl: ToastController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _filterService: FilterGroupOperatorModalService,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });

    if (this._filterService.getOperatorGroup() ){
      this.operatorGroup = this._filterService.getOperatorGroupName()
    }

    if (!this.operatorsGroupFullInput){
      this.loadingOperatorGroup = true
    }

    if (this.operatorsGroupFullInput){
      this.start()
    }

  }

  ngOnDestroy(): void {
    if(this.changeLanguage$) this.changeLanguage$.unsubscribe()
  }

  start(){

    if(this.operatorsGroupFullInput.length > 50 ){
      this.operatorsGroup = this.operatorsGroupFullInput.slice(0,50)
      }
    else{
        this.operatorsGroup = this.operatorsGroupFullInput.slice(0, this.operatorsGroupFullInput.length)
      }

  }


  changeOperatorGroup() {
    if (!this.operatorGroup){
      this.start()
    }
    else {
      this.operatorsGroup = [ ]
      this.operatorGroupFilter = this.operatorsGroupFullInput.filter(operatorGroup =>
        (operatorGroup?.description || '').toLowerCase().trim()
      .includes((this.operatorGroup || '').toLowerCase().trim()));

      if (this.operatorGroupFilter.length < 50) {
        this.operatorsGroup.push(...this.operatorGroupFilter.slice(0, this.operatorGroupFilter.length));
      }
      else {
        this.operatorsGroup.push(...this.operatorGroupFilter.slice(0, 50));
      }

    }
  }

 loadData(event) {

  setTimeout(() => {
    this.operatorGroupFilter = this.operatorsGroupFullInput.filter(operatorGroup => this.operatorGroup.trim()
    .includes(operatorGroup.description.trim()));

    if (this.operatorsGroup.length >= this.operatorGroupFilter.length) {
      this.inifiteScroll.complete();
      this.inifiteScroll.disabled = true;
      return;
    }
    let nuevoArr2 = [];

    if (this.operatorGroupFilter.length < this.operatorsGroup.length + 50) {
      nuevoArr2 = this.operatorGroupFilter.slice(this.operatorsGroup.length, this.operatorGroupFilter.length);
    } else {
      nuevoArr2 = this.operatorGroupFilter.slice(this.operatorsGroup.length, this.operatorsGroup.length + 50);
    }
    this.operatorsGroup.push(...nuevoArr2);
    this.inifiteScroll.complete();
  }, 100)

}

deleteOperatorGroupFilter() {
  this.operatorGroup = '';
  this._filterService.deleteOperatorGroup()
  this.operatorsGroup = [ ]
  this.operatorsGroupFullInput = [ ]
  successToast(this.deleteOperatorGroupFilterTranslate, this.toastCrl);

  this.modalCtrl.dismiss({
    delete: true
  });
}

  filterOperatorGroup() {
      this.modalCtrl.dismiss({
          operatorGroup: this.operatorGroupSelected,
         // operatorId: this.operatorGroupSelected
      });
    this._filterService.setOperatorGroup(this.operatorGroupSelected)
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }


  savePlaceholders() {
    this.translate.get(['turn_over.filters.deleteSuccessGroupOperator']).subscribe((translates) => {
      this.deleteOperatorGroupFilterTranslate = translates['turn_over.filters.deleteSuccessGroupOperator'];
    })
  }

}
