import { Till } from 'src/app/models';
import { TILL_CODE_LENGTH_START } from '../constants';

interface GetTillsFilteredTransactionQuery {
  tills: { tills: Till[]; tillsFull: Till[]; tillsCode: Till[] };
  operatorsIds: number[];
  clientId: number;
}

export const getTillsFilteredTransactionQuery = ({
  tills,
  operatorsIds,
  clientId,
}: GetTillsFilteredTransactionQuery) => {
  const operatorId = operatorsIds?.[0] || null;

  if (!operatorId && !clientId) {
    return tills;
  }

  let tillsFiltered: { tills: Till[]; tillsFull: Till[]; tillsCode: Till[] } = {
    tills: tills.tills,
    tillsFull: tills.tillsFull,
    tillsCode: [],
  };

  if (operatorId) {
    tillsFiltered.tills = getFilteredOperator(tillsFiltered.tills, operatorId);
    tillsFiltered.tillsFull = getFilteredOperator(
      tillsFiltered.tillsFull,
      operatorId
    );
  }

  if (clientId) {
    tillsFiltered.tills = getFilteredClient(tillsFiltered.tills, clientId);
    tillsFiltered.tillsFull = getFilteredClient(
      tillsFiltered.tillsFull,
      clientId
    );
  }

  tillsFiltered.tillsCode = getTillCodes(tillsFiltered.tillsFull);
  return tillsFiltered;
};

const getFilteredOperator = (tills: Till[], operatorId: number) => {
  return tills.filter((till) => till.operatorId === operatorId);
};

const getFilteredClient = (tills: Till[], clientId: number) => {
  return tills.filter((till) => till.clientId === clientId);
};

const getTillCodes = (tills: Till[]) => {
  let tillCodeFilter = [];
  if (tills.length < TILL_CODE_LENGTH_START && tills.length > 0) {
    tillCodeFilter.push(...tills.slice(0, tills.length));
  } else if (tills.length >= TILL_CODE_LENGTH_START) {
    tillCodeFilter.push(...tills.slice(0, TILL_CODE_LENGTH_START));
  }
  return tillCodeFilter;
};
