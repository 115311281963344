// models
import { ArticleClientDashboard, ArticleDashboardWelcome } from '../../models';

export const getArticlesDashboard = (
  articles: ArticleClientDashboard[],
  favoritesArticlesIds: number[]
): ArticleDashboardWelcome[] => {
  const articlesDashboard: ArticleDashboardWelcome[] = articles.map(article =>
    ({
      ...article,
      isSelected: false,
      isFavorite: favoritesArticlesIds.includes(article.ArticleID)
    })
  );

  return articlesDashboard;
};
