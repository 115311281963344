import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { TagStatus } from '../../../pages/user/cards/tag-status/models/tag-status';
import { AppConfig } from '../../../config/config';

@Injectable({
  providedIn: 'root'
})
export class TagStatusService {

  serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router
  ) { }

  getTagStatus(params: ParamsTagStatus){
    let endpoint = `tag_status`;
    let routeUrl = this.router.url;

    return this.httpClient.post<TagStatus>(this.serviceUrl + endpoint  + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }
}

interface ParamsTagStatus{
  cardID:number,
  operatorId?: number,
  operatorGroupId?: number,
  clientId?:number

}
