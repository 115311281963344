import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// Component
import { RegisteredComponent } from './share/components/registered/registered.component';

// Guards
import {
  AuthGuard,
  ClientOrOperatorVisibilityGuard,
  ClientVisibilityGuard,
  EcommerceVisibilityGuard,
  ExistSuperAdminGuard,
  HaveImageAPIGuard,
  IsClientesavGuard,
  MachineModuleGuard,
  MinClientGroupVisibilityGuard,
  MinCompanyVisibilityGuard,
  MinOperatorGroupVisibilityGuard,
  MinOperatorVisibilityGuard,
  NotCompanyVisibilityGuard,
  OperatorOrOperatorGroupGuard,
  PermissionGuard,
  PermissionWithParamsGuard,
  RouteDriverManagerGuard,
  RouteManagerGuard,
  RouteOrRouteDriverVisibilityGuard,
  SalesModuleGuard,
  SupervisorManagerGuard,
} from './share/guards';

// Resolvers
import {
  ClientResolverService,
  GenerateQRResolverService,
  HaveClientManagementResolverService,
  InventoryManagerResolverService,
  MachinesModuleResolverService,
  NotificationManagerAPIResolverService,
  OperatorGroupResolverService,
  OperatorResolverService,
  PermissionDashboardResolverService,
  RegisterCardResolverService,
  SendFilesAenaResolverService,
  SiteEloraResolverService,
  SubsidiesResolverService,
  TillMappingResolverService,
  TurnOverResolverService,
  VisibilityResolverService,
} from './share/resolvers';
import {
  OperatorManagerResolverService,
  OperatorGroupManagerResolverService,
  ClientManagerResolverService,
  SiteManagerResolverService,
  TillManagerResolverService,
  ArticleManagerResolverService,
  ClientGroupManagerResolverService,
} from './pages/user/data-manager/share/resolvers';

export const routes: Routes = [
  {
    path: 'sign-up',
    loadChildren: () =>
      import('./pages/sign-up/sign-up.module').then((m) => m.SignUpModule),
    canActivate: [IsClientesavGuard],
  },
  {
    path: 'account_activate/:code',
    loadChildren: () =>
      import('./pages/account_activate/account_activate.module').then(
        (m) => m.AccountActivateModule
      ),
  },
  {
    path: 'register-user/:code',
    loadChildren: () =>
      import('./pages/register-user/register-user.module').then(
        (m) => m.RegisterUserModule
      ),
  },
  {
    path: 'send-email-account',
    loadChildren: () =>
      import('./pages/send-email-account/send-email-account.module').then(
        (m) => m.SendEmailAccountModule
      ),
  },
  {
    path: 'activate-account',
    loadChildren: () =>
      import('./pages/activate-account/activate-account.module').then(
        (m) => m.ActivateAccountModule
      ),
  },
  {
    path: 'change-password/:type/:code',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
  },
  {
    path: 'change-password/:code',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
  },
  {
    path: 'recover-password',
    loadChildren: () =>
      import('./pages/recover-password/recover-password.module').then(
        (m) => m.RecoverPasswordModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'temporalticket/:code',
    loadChildren: () =>
      import(
        './pages/qr-last-ticket-machine/qr-last-ticket-machine.module'
      ).then((m) => m.QrLastTicketMachineModule),
  },
  {
    path: 'change-email/:code',
    loadChildren: () =>
      import('./pages/change-email/change-email.module').then(
        (m) => m.ChangeEmailModule
      ),
  },
  {
    path: 'admin/login/check',
    loadChildren: () =>
      import('./pages/admin-substitute/admin-substitute.module').then(
        (m) => m.AdminSubstituteModule
      ),
    canLoad: [ExistSuperAdminGuard],
  },
  {
    path: 'user',
    component: RegisteredComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomePageModule),
        canLoad: [AuthGuard],
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'transactions-query',
        loadChildren: () =>
          import(
            './pages/user/transactions/transactions-query/transactions-query.component.module'
          ).then((m) => m.TransactionQueryModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          visibility: VisibilityResolverService,
        },
      },
      {
        path: 'transaction',
        loadChildren: () =>
          import('./pages/user/cards/transactions/transactions.module').then(
            (m) => m.TransactionsModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'machines-status',
        loadChildren: () =>
          import(
            './pages/user/machines/machines-status/machines-status.component.module'
          ).then((m) => m.MachinesStatusModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'machines',
        loadChildren: () =>
          import(
            './pages/user/machines/machines/machines.component.module'
          ).then((m) => m.MachinesModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientVisibilityGuard],
      },
      {
        path: 'real-time-sale',
        loadChildren: () =>
          import(
            './pages/user/machines/real-time-sale/real-time-sale.component.module'
          ).then((m) => m.RealTimeSaleModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'ticket-check',
        loadChildren: () =>
          import('./pages/user/machines/ticket-check/ticket-check.module').then(
            (m) => m.TicketCheckModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'inventory',
        loadChildren: () =>
          import('./pages/user/machines/inventory/inventory.module').then(
            (m) => m.InventoryModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'ifree-vend-manager',
        loadChildren: () =>
          import(
            './pages/user/machines/ifree-vend-manager/ifree-vend-manager.module'
          ).then((m) => m.IFreeVendManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, OperatorOrOperatorGroupGuard],
      },

      {
        path: 'inventory-manager',
        loadChildren: () =>
          import(
            './pages/user/machines/inventory-manager/inventory-manager.module'
          ).then((m) => m.InventoryManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          useSupervisor: InventoryManagerResolverService,
        },
      },

      {
        path: 'machine-module',
        loadChildren: () =>
          import(
            './pages/user/route-management/machines-module/machines-module.module'
          ).then((m) => m.MachinesModuleModule),
        canLoad: [AuthGuard],
        // MachineModuleGuard
        canActivate: [PermissionGuard],
        resolve: {
          visibility: VisibilityResolverService,
        },
      },

      {
        path: 'machine-module/:routeId',
        loadChildren: () =>
          import(
            './pages/user/route-management/machines-module/machines-module.module'
          ).then((m) => m.MachinesModuleModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionWithParamsGuard],
        resolve: {
          visibility: VisibilityResolverService,
        },
      },

      {
        path: 'sales-module',
        loadChildren: () =>
          import(
            './pages/user/route-management/sales-module/sales-module.module'
          ).then((m) => m.SalesModuleModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, SalesModuleGuard],
        resolve: {
          isPicking: MachinesModuleResolverService,
        },
      },
      {
        path: 'ocs-alerts',
        loadChildren: () =>
          import(
            './pages/user/machines/ocs-alerts/ocs-alerts.component.module'
          ).then((m) => m.OcsAlertsModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, MinOperatorVisibilityGuard],
      },
      {
        path: 'tills-alerts',
        loadChildren: () =>
          import('./pages/user/machines/till-alert/till-alert.module').then(
            (m) => m.TillAlertModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, NotCompanyVisibilityGuard],
        resolve: {
          visibility: VisibilityResolverService,
        },
      },
      {
        path: 'tills-alerts-by-user',
        loadChildren: () =>
          import('./pages/user/machines/till-alert/till-alert.module').then(
            (m) => m.TillAlertModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, NotCompanyVisibilityGuard],
        resolve: {
          visibility: VisibilityResolverService,
        },
      },
      {
        path: 'click-collect-query',
        loadChildren: () =>
          import(
            './pages/user/click-collect/click-collect-query/click-collect.module'
          ).then((m) => m.ClickCollectModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'product-sold',
        loadChildren: () =>
          import(
            './pages/user/articles/product-sold/product-sold.component.module'
          ).then((m) => m.ProductSoldModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientVisibilityGuard],
      },
      {
        path: 'top5-sales',
        loadChildren: () =>
          import(
            './pages/user/articles/top5-sales/top5-sales.component.module'
          ).then((m) => m.Top5SalesModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'site',
        loadChildren: () =>
          import('./pages/user/machines/site/site.component.module').then(
            (m) => m.SiteModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          operatorGroupId: OperatorGroupResolverService,
        },
      },

      {
        path: 'site-elora',
        loadChildren: () =>
          import('./pages/user/machines/site-elora/site-elora.module').then(
            (m) => m.SiteEloraModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, RouteOrRouteDriverVisibilityGuard],
        resolve: {
          visibility: VisibilityResolverService,
          typePermissionSiteElora: SiteEloraResolverService,
        },
      },

      {
        path: 'webload/load-credit',
        loadChildren: () =>
          import(
            './pages/user/cards/webload/load-credit/load-credit.module'
          ).then((m) => m.LoadCreditModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'tag-status',
        loadChildren: () =>
          import('./pages/user/cards/tag-status/tag-status.module').then(
            (m) => m.TagStatusModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'card-activity',
        loadChildren: () =>
          import(
            './pages/user/cards/card-activity/card-activity.component.module'
          ).then((m) => m.CardActivityModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'register-cards',
        loadChildren: () =>
          import(
            './pages/user/cards/register-cards/register-cards.module'
          ).then((m) => m.RegisterCardsModule),
        canLoad: [AuthGuard],
        canActivate: [
          PermissionGuard,
          MinOperatorVisibilityGuard,
          NotCompanyVisibilityGuard,
        ],
        resolve: {
          havePermissions: RegisterCardResolverService,
        },
      },
      {
        path: 'check-cards',
        loadChildren: () =>
          import(
            './pages/user/cards/check-log-card/check-log-card.module'
          ).then((m) => m.CheckLogCardsModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'online-load',
        loadChildren: () =>
          import('./pages/user/cards/online-load/online-load.module').then(
            (m) => m.OnlineLoadModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      // success
      {
        path: 'status-online-load/:status/:cardnum/:subscription/:symbol',
        loadChildren: () =>
          import(
            './pages/user/information/status-online-webload/status-online-webload.module'
          ).then((m) => m.StatusOnlineLoadModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      // error
      {
        path: 'status-online-load/:status',
        loadChildren: () =>
          import(
            './pages/user/information/status-online-webload/status-online-webload.module'
          ).then((m) => m.StatusOnlineLoadModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'refill',
        loadChildren: () =>
          import('./pages/user/cards/refill/refill.module').then(
            (m) => m.RefillModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'qr-request',
        loadChildren: () =>
          import('./pages/user/machines/qr-request/qr-request.module').then(
            (m) => m.QrRequestModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'subsidies',
        loadChildren: () =>
          import(
            './pages/user/cards/subsidies/subsidies.component.module'
          ).then((m) => m.SubsidiesModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          isComercial: SubsidiesResolverService,
        },
      },

      {
        path: 'generate-qr',
        loadChildren: () =>
          import(
            './pages/user/QR/generate-qr/generate-qr.component.module'
          ).then((m) => m.GenerateQrModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientOrOperatorVisibilityGuard],
        resolve: {
          haveEdit: GenerateQRResolverService,
        },
      },

      {
        path: 'check-qr',
        loadChildren: () =>
          import('./pages/user/QR/check-qr/check-qr.component.module').then(
            (m) => m.CheckQrModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },

      {
        path: 'customer-support',
        loadChildren: () =>
          import('./pages/user/support/customer/customer.module').then(
            (m) => m.CustomerModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },

      {
        path: 'online-shop',
        loadChildren: () =>
          import(
            './pages/user/online-shop/online-shop/routes/online-shop/online-shop.component.module'
          ).then((m) => m.OnlineShopModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, EcommerceVisibilityGuard],
        resolve: {
          clientId: ClientResolverService,
        },
      },

      {
        path: 'show-buylist-online-shop',
        loadChildren: () =>
          import(
            './pages/user/online-shop/online-shop/routes/shop-buylist-online-shop/show-buylist-online-shop/show-buylist-online-shop.module'
          ).then((m) => m.ShowBuyListModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          clientId: ClientResolverService,
        },
      },
      {
        path: 'info-payment-online-shop/:status',
        loadChildren: () =>
          import(
            './pages/user/online-shop/online-shop/routes/info-payment-online-shop/info-payment-online-shop.module'
          ).then((m) => m.InfoPaymentOnlineShopModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },
      {
        path: 'info-payment-online-shop/:status/:order',
        loadChildren: () =>
          import(
            './pages/user/online-shop/online-shop/routes/info-payment-online-shop/info-payment-online-shop.module'
          ).then((m) => m.InfoPaymentOnlineShopModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },

      {
        path: 'orders-manager',
        loadChildren: () =>
          import(
            './pages/user/online-shop/orders-manager/orders-manager.module'
          ).then((m) => m.OrdersManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },

      {
        path: 'ecommerce-manager',
        loadChildren: () =>
          import(
            './pages/user/online-shop/ecommerce-manager/ecommerce-manager.module'
          ).then((m) => m.EcommerceManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, NotCompanyVisibilityGuard],
        resolve: {
          clientId: ClientResolverService,
        },
      },
      {
        path: 'access-query',
        loadChildren: () =>
          import(
            './pages/user/user-management/access-query/access-query.component.module'
          ).then((m) => m.AccessQueryModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, MinOperatorVisibilityGuard],
      },
      {
        path: 'access-web',
        loadChildren: () =>
          import(
            './pages/user/user-management/access-web/access-web.module'
          ).then((m) => m.AccessWebModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },

      {
        path: 'access-web-client',
        loadChildren: () =>
          import(
            './pages/user/user-management/access-web-client/access-web-client.module'
          ).then((m) => m.AccessWebClientModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },

      // {
      //   path: 'app-support',
      //   loadChildren: () =>
      //     import(
      //       './pages/user/cards/app-support/app-support.component.module'
      //     ).then((m) => m.AppSupportModule),
      //   canLoad: [AuthGuard],
      //   canActivate: [PermissionGuard],
      // },

      {
        path: 'check-orders',
        loadChildren: () =>
          import(
            './pages/user/online-shop/check-orders/check-orders.component.module'
          ).then((m) => m.CheckOrdersModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientOrOperatorVisibilityGuard],
      },

      {
        path: 'refunds',
        loadChildren: () =>
          import('./pages/user/cards/refunds/refunds.component.module').then(
            (m) => m.RefundsModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, HaveImageAPIGuard],
      },
      {
        path: 'turn-over',
        loadChildren: () =>
          import(
            './pages/user/billing/turn-over/turn-over.component.module'
          ).then((m) => m.TurnOverModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          visibility: VisibilityResolverService,
          sameHierarchy: TurnOverResolverService
        },
      },
      {
        path: 'image-manager',
        loadChildren: () =>
          import(
            './pages/user/application-management/image-manager/image-manager.module'
          ).then((m) => m.ImageManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, HaveImageAPIGuard],
      },
      {
        path: 'notification-panel',
        loadChildren: () =>
          import(
            './pages/user/application-management/notification-panel/notification-panel.module'
          ).then((m) => m.NotificationPanelModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, HaveImageAPIGuard],
      },
      {
        path: 'notification-manager',
        loadChildren: () =>
          import(
            './pages/user/application-management/notification-manager/notification-manager.module'
          ).then((m) => m.NotificationManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, HaveImageAPIGuard],
        resolve: {
          api: NotificationManagerAPIResolverService,
        },
      },

      {
        path: 'app-failed-charges',
        loadChildren: () =>
          import(
            './pages/user/application-management/app-failed-charges/app-failed-charges.module'
          ).then((m) => m.AppFailedChargesModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, OperatorOrOperatorGroupGuard],
        resolve: {
          visibility: VisibilityResolverService,
          permissions: TillMappingResolverService,
        },
      },
      {
        path: 'licenses-vtag-manager',
        loadChildren: () =>
          import(
            './pages/user/application-management/licenses-vtag-manager/licenses-vtag-manager.component.module'
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, NotCompanyVisibilityGuard],
        resolve: {
          visibility: VisibilityResolverService,
        },
      },
      {
        path: 'request-licenses-vtag',
        loadChildren: () =>
          import(
            './pages/user/application-management/request-licenses-vtag/request-licenses-vtag.component.module'
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientOrOperatorVisibilityGuard],
      },

      {
        path: 'assign-articles-families-aena',
        loadChildren: () =>
          import(
            './pages/user/aena/assign-articles-family-aena/assign-articles-family-aena.module'
          ).then((m) => m.AssignArticlesFamilyAenaModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientOrOperatorVisibilityGuard],
      },
      {
        path: 'aena/download-files',
        loadChildren: () =>
          import(
            './pages/user/aena/download-files-aena/download-files-aena.module'
          ).then((m) => m.DownloadFilesAenaModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientOrOperatorVisibilityGuard],
      },
      {
        path: 'till-mapping-aena',
        loadChildren: () =>
          import(
            './pages/user/aena/till-mapping-aena/till-mapping-aena.module'
          ).then((m) => m.TillMappingAenaModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientVisibilityGuard],
        resolve: {
          visibility: VisibilityResolverService,
        },
      },
      {
        path: 'regen-files-aena',
        loadChildren: () =>
          import(
            './pages/user/aena/send-files-aena/send-files-aena.module'
          ).then((m) => m.SendFilesAenaModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientVisibilityGuard],
        resolve: {
          isFile2: SendFilesAenaResolverService,
        },
      },
      {
        path: 'assign-subgroups-articles-aena',
        loadChildren: () =>
          import(
            './pages/user/aena/article-manager-aena/article-manager-aena.module'
          ).then((m) => m.ArticleManagerAenaModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientVisibilityGuard],
      },
      {
        path: 'check-subgroups-articles-aena',
        loadChildren: () =>
          import(
            './pages/user/aena/check-subgroups-articles-aena/check-articles-subgroup-aena.module'
          ).then((m) => m.CheckArticlesSubgroupAenaModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientOrOperatorVisibilityGuard],
      },
      {
        path: 'aena/check-article',
        loadChildren: () =>
          import(
            './pages/user/aena/check-articles-aena/check-articles-aena.module'
          ).then((m) => m.CheckArticlesAenaModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, ClientVisibilityGuard],
      },

      // {
      //   path: 'assign-subgroups-articles-aena',
      //   loadChildren: () =>
      //     import(
      //       './pages/user/aena/article-manager-aena/article-manager-aena.module'
      //     ).then((m) => m.ArticleManagerAenaModule),
      //   canLoad: [AuthGuard],
      //   canActivate: [PermissionGuard, ClientOrOperatorVisibilityGuard],
      // },

      {
        path: 'transactions-query-aena',
        loadChildren: () =>
          import(
            './pages/user/transactions/transactions-query/transactions-query.component.module'
          ).then((m) => m.TransactionQueryModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          visibility: VisibilityResolverService,
        },
      },

      {
        path: 'horeca/horeca-query',
        loadChildren: () =>
          import('./pages/user/horeca/horeca-query/horeca-query.module').then(
            (m) => m.HorecaQueryModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },

      {
        path: 'horeca/horeca-dashboard',
        loadChildren: () =>
          import(
            './pages/user/horeca/horeca-dashboard/horeca-dashboard.module'
          ).then((m) => m.HorecaDashboardModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },

      {
        path: 'data-manager/operator-group-manager',
        loadChildren: () =>
          import(
            './pages/user/data-manager/operator-group/operator-group.module'
          ).then((m) => m.OperatorGroupModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, MinCompanyVisibilityGuard],
        resolve: {
          highLevel: OperatorGroupManagerResolverService,
        },
      },
      {
        path: 'data-manager/operator-manager',
        loadChildren: () =>
          import(
            './pages/user/data-manager/operator-manager/operator-manager.module'
          ).then((m) => m.OperatorManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, MinOperatorGroupVisibilityGuard],
        resolve: {
          highLevel: OperatorManagerResolverService,
        },
      },
      {
        path: 'data-manager/client-group-manager',
        loadChildren: () =>
          import(
            './pages/user/data-manager/client-group-manager/client-group-manager.module'
          ).then((m) => m.ClientGroupManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, MinOperatorVisibilityGuard],
        resolve: {
          highLevel: ClientGroupManagerResolverService,
        },
      },
      {
        path: 'data-manager/client-manager',
        loadChildren: () =>
          import(
            './pages/user/data-manager/client-manager/client-manager.module'
          ).then((m) => m.ClientManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, MinClientGroupVisibilityGuard],
        resolve: {
          highLevel: ClientManagerResolverService,
        },
      },

      {
        path: 'data-manager/site-manager',
        loadChildren: () =>
          import(
            './pages/user/data-manager/site-manager/site-manager.module'
          ).then((m) => m.SiteManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          highLevel: SiteManagerResolverService,
        },
      },

      {
        path: 'data-manager/till-manager',
        loadChildren: () =>
          import(
            './pages/user/data-manager/till-manager/till-manager.module'
          ).then((m) => m.TillManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          highLevel: TillManagerResolverService,
        },
      },

      {
        path: 'data-manager/article-manager',
        loadChildren: () =>
          import(
            './pages/user/data-manager/article-manager/create_edit_articles.module'
          ).then((m) => m.CreateEditArticlesModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          permissionArticleManager: ArticleManagerResolverService,
          visibility: VisibilityResolverService,
        },
      },

      {
        path: 'data-manager/supervisor-manager',
        loadChildren: () =>
          import(
            './pages/user/data-manager/supervisor-manager/supervisor-manager.component.module'
          ).then((m) => m.SupervisorManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, SupervisorManagerGuard],
        resolve: {
          visibility: VisibilityResolverService,
        },
      },

      {
        path: 'data-manager/routeDriver-manager',
        loadChildren: () =>
          import(
            './pages/user/data-manager/route-driver-manager/route-driver-manager.module'
          ).then((m) => m.RoutesDriverManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, RouteDriverManagerGuard],
        resolve: {
          visibility: VisibilityResolverService,
        },
      },

      {
        path: 'data-manager/route-manager',
        loadChildren: () =>
          import(
            './pages/user/data-manager/route-manager/route-manager.module'
          ).then((m) => m.RoutesManagerModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, RouteManagerGuard],
        resolve: {
          visibility: VisibilityResolverService,
        },
      },

      {
        path: 'till-mapping',
        loadChildren: () =>
          import('./pages/user/machines/till-mapping/till-mapping.module').then(
            (m) => m.TillMappingModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          visibility: VisibilityResolverService,
          permissionsExtra: TillMappingResolverService,
        },
      },

      {
        path: 'list/reports',
        loadChildren: () =>
          import('./pages/user/listings/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },

      {
        path: 'dashboard/welcome',
        loadChildren: () =>
          import(
            './pages/user/dashboards/dashboard-welcome/dashboard-welcome.component.module'
          ).then((m) => m.DashboardWelcomeModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },

      {
        path: 'dashboard/refilling-dashboard',
        loadChildren: () =>
          import(
            './pages/user/dashboards/dashboard-refilling/dahboard-refilling.component.module'
          ).then((m) => m.DashboardRefillingModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
        resolve: {
          visibility: VisibilityResolverService,
          haveClientManagementRoute: HaveClientManagementResolverService,
        },
      },

      {
        path: 'dashboard/statusnet',
        loadChildren: () =>
          import(
            './pages/user/dashboards/dashboard-status-net/dashboard-status-net.component.module'
          ).then((m) => m.DashboardStatusNetModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, OperatorOrOperatorGroupGuard],
        resolve: {
          operatorId: OperatorResolverService,
        },
      },

      {
        path: 'dashboard/dashboard-selector',
        loadChildren: () =>
          import(
            './pages/user/dashboards/dashboard-selector/dashboard-selector.component.module'
          ).then((m) => m.DashboardSelectorModule),
        canLoad: [AuthGuard],
        resolve: {
          permissionDashboard: PermissionDashboardResolverService,
        },
      },
      {
        path: 'documentation',
        loadChildren: () =>
          import(
            './pages/user/documentation/user-documentation/user-documentation.component.module'
          ).then((m) => m.UserDocumentationModule),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard],
      },

      {
        path: 'transactions-wipay',
        loadChildren: () =>
          import(
            './pages/user/QPay/transactions-qpay/transactions-qpay.module'
          ),
        canLoad: [AuthGuard],
        canActivate: [PermissionGuard, OperatorOrOperatorGroupGuard],
      },

      {
        path: 'select-role',
        loadChildren: () =>
          import('./pages/user/multi-login/multi-login.module').then(
            (m) => m.MultiLoginModule
          ),
        canLoad: [AuthGuard],
      },

      // news
      {
        path: 'news/alliance',
        loadChildren: () =>
          import('./pages/user/documentation/news/news-alliance/news-allinace.component.module'
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'news/no-partner',
        loadChildren: () =>
          import('./pages/user/documentation/news/news-no-partner/news-no-partner.component.module'
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'news/partner',
        loadChildren: () =>
          import('./pages/user/documentation/news/news-partner/news-partner.component.module'
          ),
        canLoad: [AuthGuard],
      },
    ],
  },

  {
    path: '',
    redirectTo: 'user/home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'user/home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
