// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mt-5p {
  margin-top: 5px;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

.me-5 {
  margin-right: 10px;
}

.font-add-hour {
  font-size: 1.1em;
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-date-template/components/filter-date-doble-hour-in-modal/filter-date-doble-hour-in-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF","sourcesContent":[".mt-5p{\r\n  margin-top: 5px;\r\n}\r\n\r\n.d-flex-center{\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.me-5{\r\n  margin-right: 10px;\r\n}\r\n\r\n.font-add-hour{\r\n  font-size: 1.1em;\r\n}\r\n\r\n.d-flex-end{\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
