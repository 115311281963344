// models
import { TypeReport } from '../../models';

// constants
import { TYPE_REPORT_SHOW_OPERATORGROUP_AND_OPERATOR } from '../constants';

export const hasTypeReportExtraHierarchy = (
  typeReports: TypeReport[],
  typeReportIdSelected: number
) => {
  if (!typeReportIdSelected && typeReportIdSelected !== 0) {
    return true;
  }

  const typeReportSelected = typeReports.find(
    (typeReport) => typeReport.ID === typeReportIdSelected
  );

  if (!typeReportSelected) {
    return true;
  }

  return !TYPE_REPORT_SHOW_OPERATORGROUP_AND_OPERATOR.includes(
    typeReportSelected?.Name
  );
};
