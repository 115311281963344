// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Component
import { FilterClientIndividualComponent } from './filter-client-individual.component';

// Service
import { LanguageService } from '../../services/languages.service';
import { SkinMangementService } from 'src/app/services';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule,
        HttpClientModule,
    ],
    declarations: [
      FilterClientIndividualComponent
    ],
    providers: [
        LanguageService,
        SkinMangementService,
    ],
    exports: [
      FilterClientIndividualComponent
    ]
})

export class FilterClientIndividualModule { }
