
export const CONNECTIVITY_TYPE_ALERT: number = 6;
export const PERFORMANCE_TYPE_ALERT_IS_AENA: number = 4;
export const PERFOMANCE_TYPE_ALERT_NOT_AENA: number = 2;

export const CONNECTIVITY_TEXT_DB: string = 'Disconnected';
export const PERFORMANCE_TEXT_DB: string = 'Sales Performance';

export const WEEK_DAYS_MACHINE_STATUS: Record<string, string> = {
  'Sunday': 'machines_status.add_machines_status_alerts.sunday',
  'Monday': 'machines_status.add_machines_status_alerts.monday',
  'Tuesday': 'machines_status.add_machines_status_alerts.tuesday',
  'Wednesday': 'machines_status.add_machines_status_alerts.wednesday',
  'Thursday': 'machines_status.add_machines_status_alerts.thursday',
  'Friday': 'machines_status.add_machines_status_alerts.friday',
  'Saturday': 'machines_status.add_machines_status_alerts.saturday'
}

export const HOUR_START_DEFAULT_MACHINE_STATUS = '07:00';
export const HOUR_END_DEFAULT_MACHINE_STATUS = '22:00';

export const AENA_CLIENT_TEXT = 'aena';

export const URL_SUPPORT_MAX_LICENSE = 'https://www.v2vfactory.com/intranet/public/ticket/index.php';
export const MAX_LICENSE_MACHINE_STATUS = 50;
