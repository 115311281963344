import { WritableSignal } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Subscription } from 'rxjs';

// services
import { FixMenuService } from '../share/services';


export const openMainMenu = (menuController: MenuController) => {
  menuController.enable(true, 'first');
  menuController.open('first');
};

// ----- Get Menu fix -----
interface GetFixMenu {
  menuState: WritableSignal<boolean>;
  _fixMenuService: FixMenuService;
  fixMenuChange$: WritableSignal<Subscription>;
}

export const getMenuFix = ({
  menuState,
  fixMenuChange$,
  _fixMenuService,
}: GetFixMenu) => {
  menuState.set(_fixMenuService.getMenuFix());

  fixMenuChange$.set(
    _fixMenuService.fixMenuChange.subscribe((_) => {
      menuState.set(_fixMenuService.getMenuFix());
    })
  );
};
