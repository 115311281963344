export enum TypeReportOption {
  'Billiing Report Detail: Monthly' = 'Billiing Report Detail: Monthly',
  'Listado Qr diario' = 'Listado Qr diario',
  'Listado Qr mensual' = 'Listado Qr mensual',
  'Listado de Bonificaciones' = 'Listado de Bonificaciones',
  'Listado de Ventas (M)' = 'Listado de Ventas (M)',
  'Listado Categoria 2' = 'Listado Categoria 2',
  'Listado Tarjeta Bancaria (D)' = 'Listado Tarjeta Bancaria (D)',
  'Listado Tarjeta Bancaria (M)' = 'Listado Tarjeta Bancaria (M)',
  'Listado de Ventas (W)' = 'Listado de Ventas (W)',
  'Listado de Ventas con tarjeta Cautiva' = 'Listado de Ventas con tarjeta Cautiva',
  'Listado FreeVend' = 'Listado FreeVend'
}
