export interface UserManagementRoutesTranslates {
  accessWeb: string,
  accessQuery: string,
  accessWebClient: string
}

export const getUserManagementRoutes = (
  translates: UserManagementRoutesTranslates
): { route: string; name: string }[] => {
  return [
    { route: '/user/access-web', name: translates.accessWeb },
    { route: '/user/access-query', name: translates.accessQuery },
    { route: '/user/access-web-client', name: translates.accessWebClient },
  ]
}
