// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-title {
  font-size: 1.3em;
}

.hierarchy-label {
  font-size: 0.8em;
}

.w-100 {
  width: 100% !important;
}

.max-width100 {
  max-width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/modals/filter-template-guided-select/filter-template-guided-select.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAGA;EACE,sBAAA;AAAF;;AAGA;EACE,0BAAA;AAAF","sourcesContent":[".text-title{\r\n  font-size: 1.3em;\r\n}\r\n\r\n.hierarchy-label{\r\n  font-size: 0.8em;\r\n}\r\n\r\n\r\n.w-100{\r\n  width: 100% !important;\r\n}\r\n\r\n.max-width100{\r\n  max-width: 100% !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
