import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  NavController,
  ToastController,
  Platform,
  ModalController,
} from '@ionic/angular';

// rxjs
import { throwError, Subject, Observable, lastValueFrom } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';

// Config
import { AppConfig } from '../config/config';

// Service
import { TranslateService } from '@ngx-translate/core';
import { GlobalService, PermissionService, SkinMangementService } from './';
import { StorageService } from '../share/services/';
import { MainMenuService } from '../share/components/main-menu/service/main-menu.service';
import { MultiLoginService } from '../pages/user/multi-login/services/';

// Models
import {
  Credential,
  ValidUser,
  AuthLogin,
  ForcePassword,
  Company,
} from '../models';

// Utils
import { presentToast, TIME_OUT_ERROR, isAllianceDomain, UtilsTranslate } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  token: string = null;
  company: Company;
  logOutAction: Subject<boolean> = new Subject<boolean>();

  timeOutError: number = TIME_OUT_ERROR;

  haveMultiLogin$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private _permissionService: PermissionService,
    private translate: TranslateService,
    private _globalService: GlobalService,
    private _mainMenuService: MainMenuService,
    private skinService: SkinMangementService,
    private _storageService: StorageService,
    private _multiLoginService: MultiLoginService,
    private utils: UtilsTranslate
  ) {}

  login(credentials: Credential): Observable<AuthLogin> {
    let endpoint = `users/login`;
    let params: LoginParams = {};
    if (credentials.user) params['user'] = credentials.user;
    if (credentials.password) params['password'] = credentials.password;
    params.OS = this.platform.platforms().join(',').substring(0, 19);

    return this.httpClient
      .post<AuthLogin>(this.serviceUrl + endpoint, params)
      .pipe(
        map((res) => {
          return res;
        }),
        timeout(this.timeOutError),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  createPassword(id) {
    let endpoint = `/users/${id}`;
    return this.httpClient.put(this.serviceUrl + endpoint, {});
  }

  async guardarToken(token: string) {
    this.token = token;

    await this._storageService.set('token', token);

    await this.validaToken();
  }

  async cargarToken() {
    this.token = (await this._storageService.get('token')) || null;
  }

  async validaToken(): Promise<boolean> {
    this.company = this.skinService.getCompany();

    await this.cargarToken();

    if (!this.token) {
      this.navCtrl.navigateRoot('/login');
      return Promise.resolve(false);
    }

    return new Promise<boolean>((resolve) => {
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      });

      let endpoint = `users/validate`;
      this.httpClient.get(this.serviceUrl + endpoint, { headers }).subscribe(
        {next: (resp: {verify: boolean}) => {
          if (resp.verify) {
            this._permissionService.getPermissions();
            resolve(true);
            this._mainMenuService.setStarted(true);
          } else {
            this.navCtrl.navigateRoot('/login', { animated: true });

            resolve(false);
          }
        },
        error: () => {
          this.navCtrl.navigateRoot('/login', { animated: true });

          resolve(false);
        }}
      );
    });
  }

  validarTokenTest(token) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    let endpoint = `users/validate`;
    return this.httpClient.get(this.serviceUrl + endpoint, { headers });
  }

  async getToken() {
    return (await this._storageService.get('token')) || null;
  }

  getToken2() {
    return this.token;
  }

  validateUser(user: string): Observable<ValidUser> {
    let endpoint = `users/user-valid`;

    return this.httpClient
      .post<ValidUser>(this.serviceUrl + endpoint, { user })
      .pipe(
        map((res) => {
          return res;
        }),
        timeout(this.timeOutError),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  async saveStorage(resLogin: AuthLogin, isProfile = false) {
    await this._storageService.set('user', resLogin.user);
    await this._storageService.set('email', resLogin.email);
    await this._storageService.set('phone', resLogin.phone);
    await this._storageService.set('name', resLogin.name);
    await this._storageService.set('surname', resLogin.surname);
    await this._storageService.set('phonePrefix', resLogin?.phonePrefix);
    await this._storageService.set('role', resLogin?.roleName || '');
    if (!isProfile) {
      await this._storageService.set('minDate', resLogin.minDate);
    }
  }

  async removeToken() {
    await this._storageService.remove('token');
    await this._storageService.remove('user');
    await this._storageService.remove('email');
    await this._storageService.remove('phone');
    await this._storageService.remove('name');
    await this._storageService.remove('surname');
    await this._storageService.remove('minDate');
    await this._storageService.remove('phonePrefix');
    await this._storageService.remove('role');
  }

  // force-password-renewal
  public forcePassword(params: ForcePassword) {
    let endpoint = `users/force-password`;
    return this.httpClient.put(this.serviceUrl + endpoint, params).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        let messageError = !error?.error?.error?.message
          ? '504 Gateway timeout'
          : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  async logout() {
    this.token = '';
    await this.removeToken();
    this._globalService.logOut();
    this.logOutAction.next(true);

    this.navCtrl.navigateRoot('/login', { animated: true });
  }

  async getName() {
    return (
      ((await this._storageService.get('name')) || '') +
      ' ' +
      ((await this._storageService.get('surname')) || '')
    );
  }

  async getNameLanguage(locale) {
    if (locale == 'en-EN') {
      return (await this._storageService.get('surname'))
        ? ((await this._storageService.get('surname')) || '') +
            ', ' +
            ((await this._storageService.get('name')) || '')
        : (await this._storageService.get('name')) || '';
    } else {
      return (
        ((await this._storageService.get('name')) || '') +
        ' ' +
        ((await this._storageService.get('surname')) || '')
      );
    }
  }

  async getMinDate() {
    return (await this._storageService.get('minDate')) || null;
  }

  async getFirstName() {
    return (await this._storageService.get('name')) || '';
  }

  tokenExpired() {
    this.removeToken();
    this._globalService.logOut();
    this.logOutAction.next(true);

    this.modalCtrl.getTop().then((modal) => {
      if (modal) {
        this.modalCtrl.dismiss();
      }
    });

    this.navCtrl.navigateRoot('/login', { animated: true });

    setTimeout(() => {
      //'Sesión caducada'
      this.translate.get('expired_token').subscribe((translate) => {
        this.utils.presentToastWithoutTranslate(translate)
      });
    }, 100);
  }

  // recodar password con la opcion de forzar password
  sendEmailForcePassword(user: string) {
    let endpoint = `users/force-password`;
    return this.httpClient.post(this.serviceUrl + endpoint, { user });
  }


  async getEmail(){
    return (await this._storageService.get('email')) || '';
  }

  async saveMultiLogin(token: string) {
    await this._storageService.set('multiLogin', token);
    await this.haveMultiLogin()
  }

  async changeMultiLogin() {
    const tokenMultiLogin = await this._storageService.get('multiLogin');
    const token = await this._storageService.get('token');

    if (token !== tokenMultiLogin && tokenMultiLogin) {
      await this.guardarToken(tokenMultiLogin);
    }
  }

  async haveMultiLogin() {
    this.company = this.skinService.getCompany();
    if (!isAllianceDomain(this.company)) {
      this.haveMultiLogin$.next(false);
      return;
    }

    const token = await this._storageService.get('multiLogin');
    if (!token) {
      this.haveMultiLogin$.next(false);
      return;
    }
    try {
      const isValid = await lastValueFrom(this._multiLoginService
        .haveMultiLoginGuardByToken(token))

      // console.log('isValid', isValid);
      this.haveMultiLogin$.next(isValid);
    } catch (error) {
      return this.haveMultiLogin$.next(false);
    }
  }

  async removeMultiLogin(){
    await this._storageService.remove('multiLogin');
  }

  async saveMinDate(minDate: Date){
    await this._storageService.set('minDate', minDate);
  }
}

interface LoginParams {
  user?: string;
  password?: string;
  OS?: string;
}
