import { Injectable } from '@angular/core';

// models
import type { ListOrderType } from '../models/types';

@Injectable({
  providedIn: 'root',
})
export class ListOrderTillAlert {

  listOrder: { type: ListOrderType; isAscendingOrder: boolean }[] = [
    { type: 'enable', isAscendingOrder: true},
    { type: 'client', isAscendingOrder: true },
    { type: 'machine', isAscendingOrder: true },
    { type: 'email', isAscendingOrder: true },
    { type: 'status', isAscendingOrder: true },
    { type: 'priority', isAscendingOrder: true },
  ];

  constructor() {}

  addFilter(elementToMove: ListOrderType) {
    this.moveStart(elementToMove);
  }

  changeAscendingOrder(elementToChange: ListOrderType){
    const elem = this.listOrder.find(
      (orderType) => orderType.type === elementToChange
    );
    if(elem){
      elem.isAscendingOrder = !elem.isAscendingOrder;
    }
  }

  changeOrderAndPosition(elementToMove: ListOrderType){
    this.moveStart(elementToMove);
    this.changeAscendingOrder(elementToMove);
  }

  getListOrderDic(): Record<ListOrderType, boolean>{
    return {
      enable: this.listOrder.find(order => order.type === 'enable')?.isAscendingOrder,
      client: this.listOrder.find(order => order.type === 'client')?.isAscendingOrder,
      email: this.listOrder.find(order => order.type === 'email')?.isAscendingOrder,
      machine: this.listOrder.find(order => order.type === 'machine')?.isAscendingOrder,
      priority: this.listOrder.find(order => order.type === 'priority')?.isAscendingOrder,
      status: this.listOrder.find(order => order.type === 'status')?.isAscendingOrder,
    }
  }

  getListOrder() {
    return this.listOrder;
  }

  private moveStart(elementToMove: ListOrderType) {
    const from = this.listOrder.findIndex(
      (orderType) => orderType.type === elementToMove
    );
    if(from){
      this.listOrder.splice(0, 0, this.listOrder.splice(from, 1)[0]);
    }
  }

}
