import { Injectable } from "@angular/core";

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Service
import { PermissionService } from '../../../../../services/permission.service';


@Injectable({providedIn: 'root'})
export class SubsidiesResolverService {

    constructor(private _permissionService: PermissionService) {}

    resolve(): Observable<boolean>{
        return this._permissionService
                .getAllPermissions()
                    .pipe(
                        map(res =>
                             !!(res.find((permission) =>
                                permission?.route === '/user/subsidies' && permission?.name === 'Bonificaciones comerciales'
                            ))
                        )
                    );
    }

}
