// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.font-sm {
  font-size: 0.8em;
}

.font-icon-sm {
  font-size: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/arrow-template/arrow-template.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".ml-5{\r\n  margin-left: 5px;\r\n}\r\n\r\n.mr-5{\r\n  margin-right: 5px;\r\n}\r\n\r\n.font-sm{\r\n  font-size: 0.8em;\r\n}\r\n\r\n.font-icon-sm{\r\n  font-size: 1em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
