// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-sm {
  font-size: 0.8em;
}

.icon-calendar {
  color: var(--icon-color);
}

.icon-calendar:hover {
  color: var(--icon-color-hover);
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-date-template/components/share/filter-date-input/filter-date-input.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAMA;EACE,wBAAA;AAHF;;AAMA;EACE,8BAAA;AAHF","sourcesContent":[".font-sm{\r\n  font-size: 0.8em;\r\n}\r\n\r\n// .icon-calendar{\r\n//   color: var(--ion-color-alliance-text);\r\n// }\r\n\r\n.icon-calendar{\r\n  color: var(--icon-color)\r\n}\r\n\r\n.icon-calendar:hover{\r\n  color: var(--icon-color-hover);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
