import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Company } from 'src/app/models/';
import { SkinMangementService } from 'src/app/services';
import { LanguageRouteService } from 'src/app/share/services/languageRoute.ts/languagesRoute.services';
import { diffDaysDates, presentToast } from 'src/app/utils';
import { type } from 'src/app/utils/constants';
import { FilterDatePeriodNoCalendarService } from '../../service/filter-date-period-no-calendar.service';

@Component({
  selector: 'app-period-no-calendar-modal',
  templateUrl: './period-no-calendar-modal.component.html',
  styleUrls: ['./period-no-calendar-modal.component.scss'],
})
export class PeriodNoCalendarModalComponent implements OnInit {

  company: Company;
  type = type;

  startDate = new Date().toISOString();
  endDate = new Date().toISOString();

  startDateFormated:string = ''
  endDateFormated:string = ''

  startDateParams:string = ''
  endDateParams:string = ''

  @Input() minDate:string = ''
  @Input() locale:string = ''

  @ViewChild('period') period:any

  constructor(
    public readonly platform: Platform,
    private _skinService: SkinMangementService,
    private modalCtrl: ModalController,
    private _filterService: FilterDatePeriodNoCalendarService,
  ) { }

  ngOnInit() {

    this.company = this._skinService.getCompany();

    //set the current date by default
    const today = new Date()
    this.startDateFormated = today.getFullYear()+'-'+(today.getMonth() + 1).toString().padStart(2, "0");
    this.endDateFormated = today.getFullYear()+'-'+(today.getMonth() + 1).toString().padStart(2, "0");

    if (this._filterService.getStartPeriod()) {
      this.startDate = this._filterService.getStartPeriod()
    }

    if (this._filterService.getStartPeriod()) {
      this.endDate = this._filterService.getEndPeriod()
    }

    // const yearMonthColumn = document.getElementById('year-month-start').shadowRoot.children as HTMLCollectionOf<HTMLElement>
    // const monthColumn = document.getElementById('year-month-end').shadowRoot.children as HTMLCollectionOf<HTMLElement>

    // if (this.platform.width() < 600){

    //   if (yearMonthColumn){
    //   setTimeout(() => {
    //     let child = yearMonthColumn['0']
    //     let child2 = child?.getElementsByTagName('ion-picker-column-internal')
    //     child2[0].style.fontSize='14px'
    //     child2[1].style.fontSize='14px'
    //   }, 150);
    //   }

    // if (monthColumn){
    //   setTimeout(() => {
    //     let child = monthColumn['0']
    //     let child2 = child?.getElementsByTagName('ion-picker-column-internal')
    //     child2[0].style.fontSize='14px'
    //     child2[1].style.fontSize='14px'
    //   }, 150);
    //   }
    // }

  }



  applyChanges() {

    const date = new Date(this.startDate)
    const date2 = new Date(this.endDate)
    const today = new Date()

    if (this.startDate) {
      this._filterService.setPeriodStart(this.startDate)
      this.startDateParams = [date.getFullYear(),(date.getMonth() + 1).toString().padStart(2, "0"), +'01'.padStart(2, "0")].join('-');
      this.startDateFormated = [date.getFullYear(),(date.getMonth() + 1).toString().padStart(2, "0")].join('-');
      this._filterService.setPeriodStartFormated(this.startDateFormated)
    }

    if (this.endDate) {
      this._filterService.setPeriodEnd(this.endDate)
      const lastday = (y,m) => {
        return  new Date(y, m +1, 0).getDate();
      }

      //if the user just change the end date and not the start date
      let fecha = !this.startDate ? today : date
      this.endDateParams = [date2.getFullYear(),(date2.getMonth() + 1).toString().padStart(2, "0"),
      lastday(fecha.getFullYear(), date2.getMonth())].join('-');
      this.endDateFormated = [date2.getFullYear(),(date2.getMonth() + 1).toString().padStart(2, "0")].join('-');
      this._filterService.setPeriodEndFormated(this.endDateFormated)

    }

    this._filterService.setPeriodParams(this.startDateParams, this.endDateParams)


    this.modalCtrl.dismiss({
      startPeriod: this.startDateFormated,
      endPeriod: this.endDateFormated
    })
  }

  closeModal(){
    this.modalCtrl.dismiss({})
  }

}
