// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display-end {
  display: flex;
  justify-content: flex-end;
}

.d-align-center {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/arrow-back-select-all-position/arrow-back-select-all-position.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF","sourcesContent":[".display-end{\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n\r\n.d-align-center{\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
