import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subsidyDetails'
})
export class SubsidyDetailsPipe implements PipeTransform {

  transform(text: string, servicesTranslate: string, dayTranslate: string, monthTranslate: string,
    unlimitedSalesTranslate: string, monthlyAccumulative: string, monthlyServices: string,
    perMonth: string, yearTranslate:string, notSubsidyTranslate: string = 'Sin bonificación'): string {
  
     
    if(!text){
      return '';
    }

    //Ventas ilimitadas
    if(text == 'Ventas ilimitadas'){
      return unlimitedSalesTranslate;
    }

    if(text.includes('Mensuales acumulables') || text.includes('Mensuals acumulables')){
      return text.replace('Mensuales acumulables', monthlyAccumulative).replace('Mensuals acumulables', monthlyAccumulative);
    }

    if(text.includes('Servicios mensuales')){
      return text.replace('Servicios mensuales', monthlyServices);
    }

    if(text.includes('Mensuales') || text.includes('Mensuals')){
      return text.replace('Mensuales', perMonth).replace('Mensuals', perMonth);
    }

    if(text.includes('Sin bonificación')){
      return notSubsidyTranslate;
    }


    return text.replace('servicios', servicesTranslate).replace('dia', dayTranslate)
      .replace('mes', monthTranslate).replace('año', yearTranslate);
  }

}
