import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// services
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from '../../../../../../../services';

// models
import { Company } from 'src/app/models';

// utils
import { getLastDateDataCollection } from '../../../utils';

@Component({
  selector: 'app-dashboard-welcome-legend',
  templateUrl: './dashboard-welcome-legend.component.html',
  styleUrls: ['./dashboard-welcome-legend.component.scss'],
})
export class DashboardWelcomeLegendComponent implements OnInit {
  company: Company;

  lastDateDataCollection: Date = null;

  datePipeWithHour: string = '';

  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _skinService: SkinMangementService
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();
    this.lastDateDataCollection = getLastDateDataCollection();

    this.getTranslate();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  async getTranslate() {
    this.datePipeWithHour = await this.translate.instant('format_pipe24h');
  }
}
