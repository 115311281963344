import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Company } from 'src/app/models';

// Services
import { SkinMangementService } from 'src/app/services';

@Component({
  selector: 'app-legend-inventory-manager',
  templateUrl: './legend-inventory-manager.component.html',
  styleUrls: ['./legend-inventory-manager.component.scss'],
})
export class LegendInventoryManagerComponent implements OnInit {

  company: Company;

  constructor(
    private modalCtrl: ModalController,
    private skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this.skinService.getCompany()
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
