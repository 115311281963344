import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// services
import { Company } from 'src/app/models';

// models
import { SkinMangementService } from 'src/app/services';

// constants
import { DIMENSIONS_MAX_ANDROID, DIMENSIONS_MAX_IOS } from '../../../utils/image-manager-constants';


@Component({
  selector: 'app-legend-image-manager',
  templateUrl: './legend-image-manager.component.html',
  styleUrls: ['./legend-image-manager.component.scss'],
})
export class LegendImageManagerComponent implements OnInit {

  company: Company;


  readonly SPLASH_IOS = `${DIMENSIONS_MAX_IOS['Splash'][0]}x${DIMENSIONS_MAX_IOS['Splash'][1]}`
  readonly BANNER_IOS = `${DIMENSIONS_MAX_IOS['Banner'][0]}x${DIMENSIONS_MAX_IOS['Banner'][1]}`;
  readonly SPLASH_ANDROID =`${DIMENSIONS_MAX_ANDROID['Splash'][0]}x${DIMENSIONS_MAX_ANDROID['Splash'][1]}`;
  readonly BANNER_ANDROID = `${DIMENSIONS_MAX_ANDROID['Banner'][0]}x${DIMENSIONS_MAX_ANDROID['Banner'][1]}`;
  readonly FORMAT_IMAGE = '.jpeg';


  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
