// External Module
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

// Modules
import { FilterDateInputModule } from '../filter-date-input/filter-date-input.module';

// Components
import {
  DatepickerRangeMaterialInputAllianceComponent,
  DatepickerRangeMaterialInput,
  DatepickerRangeMaterialInputV2vComponent,
  DatepickerRangeMaterialInputRoccaComponent,

  DatepickerDateMaterialInputComponent,
  DatepickerDateMaterialInputV2vComponent,
  DatepickerDateMaterialInputAllianceComponent,
  DatepickerDateMaterialInputRoccaComponent,

} from '.';

// Services
import { LanguageService } from '../../../../../services/languages.service';

@NgModule({
  declarations: [
    DatepickerRangeMaterialInput,
    DatepickerRangeMaterialInputAllianceComponent,
    DatepickerRangeMaterialInputV2vComponent,
    DatepickerRangeMaterialInputRoccaComponent,

    DatepickerDateMaterialInputAllianceComponent,
    DatepickerDateMaterialInputComponent,
    DatepickerDateMaterialInputV2vComponent,
    DatepickerDateMaterialInputRoccaComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,

    // Material
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,

    FilterDateInputModule,
  ],
  providers: [
    LanguageService,
    DatePipe,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [
    DatepickerRangeMaterialInput,
    DatepickerRangeMaterialInputAllianceComponent,
    DatepickerRangeMaterialInputV2vComponent,

    DatepickerDateMaterialInputAllianceComponent,
    DatepickerDateMaterialInputComponent,
    DatepickerDateMaterialInputV2vComponent
  ],
})
export class DatePickerMaterialModule {}
