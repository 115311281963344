import { ComponentProps } from '@ionic/core';

// models
import {
  Client,
  Company,
  FilterTemplateObjectTripleInputsModalOptions,
  Supervisor,
  Till,
} from 'src/app/models';
import { FilterTemplateObjectTripleInputOneOption } from 'src/app/share/components/modals/filter-template-object-triple-inputs-modal';

// utils
import { FILTER_TRANSLATE_INVENTORY_MANAGER } from './constant';
import { ClientRoute, MachineRoute } from '../../../route-management/sales-module/models';

interface GetPropsFilterInventoryManager {
  company: Company;
  supervisors: Supervisor[];
  clients: ClientRoute[];
  machines: MachineRoute[];
}

export const getPropsFilterInventoryManager = ({
  company,
  supervisors,
  clients,
  machines,
}: GetPropsFilterInventoryManager):
  | ComponentProps
  | FilterTemplateObjectTripleInputsModalOptions => {
  const TRANSLATE_PATH = 'inventory_manager.filter.';

  return {
    company,
    title: FILTER_TRANSLATE_INVENTORY_MANAGER,
    textInput1: 'supervisor',
    textInput2: 'client',
    textInput3: 'till',
    textSearch1: `${TRANSLATE_PATH}supervisor_name`,
    textSearch2: `${TRANSLATE_PATH}client_name`,
    textSearch3: `${TRANSLATE_PATH}machine_code`,
    elementsInput1: getSupervisorElements(supervisors),
    elementsInput2: getCLientElements(clients),
    elementsInput3: getMachinesElements(machines),
  };
};

const getSupervisorElements = (
  supervisors: Supervisor[]
): FilterTemplateObjectTripleInputOneOption => {
  return {
    elements: supervisors,
    elementSelected: null,
    nameId: 'ID',
    nameSearchElement: 'Name',
  };
};

const getCLientElements = (
  clients: ClientRoute[]
): FilterTemplateObjectTripleInputOneOption => {

  return {
    elements: clients,
    elementSelected: null,
    nameId: 'clientID',
    nameSearchElement: 'client',
  };
};

const getMachinesElements = (
  machines: MachineRoute[]
): FilterTemplateObjectTripleInputOneOption => {
  return {
    elements: machines,
    elementSelected: null,
    nameId: 'machineID',
    nameSearchElement: 'machine',
  };
};
