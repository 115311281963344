import { UtilsTranslate } from 'src/app/utils';
import { ArticleEditable } from '../models';
import { TranslateService } from '@ngx-translate/core';

export const isValidArticleEditable = (
  article: ArticleEditable,
  utils: UtilsTranslate,
  plusInfo: boolean = false,
  translate: TranslateService,
  operatorGroupId: number,
): boolean => {


  if(!operatorGroupId && operatorGroupId !== 0){
    utils.presentToastTranslate(
      'create_edit_articles.create_articles.operator_group_required',
      true,
      0
    );
    return false;
  }

  if (!article?.typeGroupA && article?.typeGroupA !== 0) {
    utils.presentToastTranslate(
      'create_edit_articles.create_articles.select_type',
      true,
      0
    );
    return false;
  }

  if (!article?.typeGroupD && article?.typeGroupD !== 0) {
    utils.presentToastTranslate(
      'create_edit_articles.create_articles.select_subtype',
      true,
      0
    );
    return false;
  }

  if (!article?.code) {
    utils.presentToastTranslate(
      'create_edit_articles.create_articles.enter_code',
      true,
      0
    );
    return false;
  }

  if (!article?.description) {
    utils.presentToastTranslate(
      'create_edit_articles.create_articles.enter_description',
      true,
      0
    );
    return false;
  }

  if (article?.unitBox <= 0) {
    utils.presentToastTranslate('create_edit_articles.create_articles.units_box_positive');
    return false;
  }

  if (article?.vats?.length > 0) {
    for (let vat of article.vats) {
      if (!vat?.value && vat?.value !== 0) {
        utils.presentToastWithVariable(
          'create_edit_articles.create_articles.enter_tax',
          'tax',
          vat?.Description || 'tax'
        );
        return false;
      }
      if (vat?.value < 0) {
        utils.presentToastWithVariable(
          'create_edit_articles.create_articles.no_negative',
          'name',
          vat?.Description || 'tax'
        );
        return false;
      }
    }
  }

  if (plusInfo) {
    if (article.purchasePrice < 0) {
      utils.presentToastWithVariable(
        'create_edit_articles.create_articles.no_negative',
        'name',
        translate.instant('create_edit_articles.purchase_price')
      );
      return false;
    }

    if (article.fillingCost < 0) {
      utils.presentToastWithVariable(
        'create_edit_articles.create_articles.no_negative',
        'name',
        translate.instant('create_edit_articles.filling_cost')
      );
      return false;
    }

    if (article.structureCost < 0) {
      utils.presentToastWithVariable(
        'create_edit_articles.create_articles.no_negative',
        'name',
        translate.instant('create_edit_articles.structure_cost')
      );
      return false;
    }
  }

  return true;
};
