import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

// Models
import { Operator } from '../../../../models/Operator.model';

@Component({
  selector: 'app-filter-operator',
  templateUrl: './filter-operator.component.html',
  styleUrls: ['./filter-operator.component.scss'],
})
export class FilterOperatorComponent implements OnInit {

  @Input() loadingOperators = true;
  @Input() operators: Operator[] = [];
  @Input() allOptionDisabled: boolean = false
  @Input() operatorSelected: number = null;
  @Input() userLoading: boolean = false

  @Output() changeOperatorSelected = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    this.operators = (this.allOptionDisabled) ? this.operators.filter(op => op.id != -1): this.operators
  }

  changeOperator() {
    this.changeOperatorSelected.emit(this.operatorSelected);
  }

}
