import { FamilyDW } from "../../models";

interface HaveArticleOrFamilySelecteds{
  articleSelected: { id: number; description: string },
  familySelected: FamilyDW
}

export const haveArticleOrFamilySelecteds = (
    {articleSelected, familySelected }: HaveArticleOrFamilySelecteds)=> {

    if(articleSelected?.id){
      return true;
    }

    if(familySelected?.familyId){
      return true;
    }

    return false;
}
