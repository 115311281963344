// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-split-pane {
  --side-min-width: 100px;
  --side-max-width: 280px;
}

@media (min-width: 660px) and (max-width: 699px) {
  ion-split-pane {
    --side-min-width: 100px;
    --side-max-width: 240px;
  }
}
@media (min-width: 600px) and (max-width: 659px) {
  ion-split-pane {
    --side-min-width: 150px;
    --side-max-width: 200px;
  }
}
::ng-deep h1 {
  font-family: var(--font-family-h1-company) !important;
}

::ng-deep h2 {
  font-family: var(--font-family-other-company) !important;
}

::ng-deep h3 {
  font-family: var(--font-family-other-company) !important;
}

::ng-deep h4 {
  font-family: var(--font-family-other-company) !important;
}

::ng-deep h5 {
  font-family: var(--font-family-other-company) !important;
}

::ng-deep p {
  font-family: var(--font-family-other-company) !important;
}

::ng-deep span:not(.material-icons-outlined, .material-icons-round, .material-icons, .material-icons-sharp, .material-icons-two-tone) {
  font-family: var(--font-family-other-company) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAGA;EACE,uBAAA;EACA,uBAAA;AAFF;;AAKA;EACE;IACE,uBAAA;IACA,uBAAA;EAFF;AACF;AAKA;EACE;IACE,uBAAA;IACA,uBAAA;EAHF;AACF;AAOA;EACE,qDAAA;AALF;;AAQA;EACE,wDAAA;AALF;;AASA;EACE,wDAAA;AANF;;AASA;EACE,wDAAA;AANF;;AAUA;EACE,wDAAA;AAPF;;AAUA;EACE,wDAAA;AAPF;;AAUA;EACE,wDAAA;AAPF","sourcesContent":["// ion-menu{\r\n//     --width: 234px;\r\n//   }\r\nion-split-pane {\r\n  --side-min-width: 100px;\r\n  --side-max-width: 280px;\r\n}\r\n\r\n@media (min-width: 660px) and (max-width: 699px) {\r\n  ion-split-pane {\r\n    --side-min-width: 100px;\r\n    --side-max-width: 240px;\r\n  }\r\n}\r\n\r\n@media (min-width: 600px) and (max-width: 659px) {\r\n  ion-split-pane {\r\n    --side-min-width: 150px;\r\n    --side-max-width: 200px;\r\n  }\r\n}\r\n\r\n\r\n::ng-deep h1 {\r\n  font-family: var(--font-family-h1-company) !important;\r\n}\r\n\r\n::ng-deep h2 {\r\n  font-family: var(--font-family-other-company) !important;\r\n}\r\n\r\n\r\n::ng-deep h3 {\r\n  font-family: var(--font-family-other-company) !important;\r\n}\r\n\r\n::ng-deep h4 {\r\n  font-family: var(--font-family-other-company) !important;\r\n}\r\n\r\n\r\n::ng-deep h5 {\r\n  font-family: var(--font-family-other-company) !important;\r\n}\r\n\r\n::ng-deep p {\r\n  font-family: var(--font-family-other-company) !important;\r\n}\r\n\r\n::ng-deep span:not(.material-icons-outlined, .material-icons-round, .material-icons, .material-icons-sharp, .material-icons-two-tone) {\r\n  font-family: var(--font-family-other-company) !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
