import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';

// Models
import { Company } from 'src/app/models';

// Utils
import { timeReload, UtilsTranslate } from 'src/app/utils';

@Component({
  selector: 'app-filter-template-guided-object',
  templateUrl: './filter-template-guided-object.component.html',
  styleUrls: ['./filter-template-guided-object.component.scss'],
})
export class FilterTemplateGuidedObjectComponent implements OnInit {

  @Input() company: Company;
  @Input() search: string = ''
  @Input() loadingElement: boolean = false;

  @Input() elementSelected;

  @Input() nameSearchElement: string = 'description';
  @Input() nameID: string = 'ID';
  @Input() notFoundText: string = '';


  @Input() elements: any[] = [];
  elementsFilter: any[];
  elementsShower: any[];

  @Input() searchTextTrasnlate: string = '';

  @Output() changeElement = new EventEmitter<{element: any}>()

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private modalCtrl: ModalController,
    private utils: UtilsTranslate
  ) { }

  ngOnInit() {
    this.start()
  }

  start() {

    this.elementsFilter = this.elements;
    this.elementsShower = [];
    if (this.elementsFilter.length < 50) {
      this.elementsShower.push(...this.elementsFilter.slice(0, this.elementsFilter.length));
    } else {
      this.elementsShower.push(...this.elementsFilter.slice(0, 50));
    }

    if(this.search){
      this.changeSearch()
    }

  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.elements?.currentValue){
      this.start()
    }
  }


  closeModal() {
    this.modalCtrl.dismiss({});
  }


  changeSearch() {

    this.changeElement.emit(null)

    if (!this.search) {
      this.start();
    } else {
      this.elementsShower = [];
      this.elementsFilter = this.elements.filter(element =>
          element?.[this.nameSearchElement]?.toString().toLowerCase().trim()
        .includes(this.search?.toString().toLowerCase().trim()));
      if (this.elementsFilter.length < 50) {
        this.elementsShower.push(...this.elementsFilter.slice(0, this.elementsFilter.length));
      } else {
        this.elementsShower.push(...this.elementsFilter.slice(0, 50));
      }

      let elementFilter = this.elements.find(
        element => element?.[this.nameSearchElement]?.toString().toLowerCase().trim()
                      === this.search?.toString().toLowerCase().trim());
      // (elementFilter) ? this.elementSelected = elementFilter[this.nameID] : this.elementSelected = null;

    }

  }

  loadData(){

    setTimeout(() => {

      if (this.elementsShower.length >= this.elementsFilter.length) {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      let newElements = [];
      if (this.elementsFilter.length < this.elementsShower.length + 50) {
        newElements = this.elementsFilter.slice(this.elementsShower.length, this.elementsFilter.length);
      } else {
        newElements = this.elementsFilter.slice(this.elementsShower.length, this.elementsShower.length + 50);
      }
      this.elementsShower.push(...newElements);

      this.inifiteScroll.complete();
    }, timeReload);

  }

  changeElementSelected(){
    let elementFilter = this.elements.find(element => element?.[this.nameID] == this.elementSelected);
    if (elementFilter) {
      this.changeElement.emit(elementFilter);
    } else {
      this.utils.presentToastTranslate('error', true, 0)
    }
  }
}
