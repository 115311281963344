import { FilterTransactionsQueryService } from '../services';

export const getClientSelectedDescriptionCode = (
  _filterService: FilterTransactionsQueryService
) => {
  const clientDescription = _filterService.getNameClient() || '';
  const clientCode = _filterService.getCodeClient() || '';

  return { clientCode, clientDescription };
};
