// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-pointer {
  cursor: "default" !important;
}

.disabled-not-opacity[disabled] {
  opacity: 1;
}

.d-flex {
  display: flex;
}

.mt-6 {
  margin-top: 6px;
}

.w-100 {
  width: 100%;
}

.padding-vertical {
  padding-top: 10px;
  padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-date-template/components/filter-date-in-modal/filter-date-modal-hour/filter-date-modal-hour.component.scss"],"names":[],"mappings":"AAIA;EACE,4BAAA;AAHF;;AAOE;EACI,UAAA;AAJN;;AASA;EACE,aAAA;AANF;;AASA;EACE,eAAA;AANF;;AAUA;EACE,WAAA;AAPF;;AAUA;EACE,iBAAA;EACA,oBAAA;AAPF","sourcesContent":["// .bg-red{\r\n//   background-color: red;\r\n// }\r\n\r\n.no-pointer{\r\n  cursor: 'default' !important;\r\n}\r\n\r\n.disabled-not-opacity {\r\n  &[disabled] {\r\n      opacity: 1;\r\n  }\r\n}\r\n\r\n\r\n.d-flex{\r\n  display: flex;\r\n}\r\n\r\n.mt-6{\r\n  margin-top: 6px;\r\n}\r\n\r\n\r\n.w-100{\r\n  width: 100%;\r\n}\r\n\r\n.padding-vertical{\r\n  padding-top: 10px;\r\n  padding-bottom: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
