export type ActivitiesTillsValids =
  | 'BEBIDA'
  | 'BEBIDA CALIENTE'
  | 'BEBIDA FRÍA'
  | 'SNACKS'
  | 'MIXTA';


export enum ActivitiesTill {
  coldDrink = 'BEBIDA FRÍA',
  hotDrink = 'BEBIDA CALIENTE',
  snacks = 'SNACKS',
  mixed = 'MIXTA',
  undefined = 'UNDEFINED'
}
