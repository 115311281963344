import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Services
import { StorageService } from '../../../../../share/services/storage/storage.service';

// Models
import { InfoPayment, SelectedsShopArticle, ArticlesShop } from '../models/online-shop.model';
import { DetailsOnlineShop } from '../models/details-online-shop';

// Utils
import { compareTestInsensitive } from 'src/app/utils';

@Injectable({
  providedIn: 'root'
})
export class FilterOnlineShopService {



  buyListItemsSelected: ArticlesShop[] = []

  buyList2: ArticlesShop[] = []
  buyListAllElements: ArticlesShop[] = []

  filteredArticlesShop: ArticlesShop[] = []


  filterChange: Subject<FilterOnlineShop> = new Subject<FilterOnlineShop>();

  familySelected = '0';
  searchText = '';

  details: DetailsOnlineShop;


  constructor(
    private _storageService: StorageService
  ) { }

  saveShippinginformation(newDirection: string, newContactName: string, observation: string) {
    this._storageService.set('direction_shop', newDirection);
    this._storageService.set('contact_shop', newContactName);
    this._storageService.set('observation_shop', observation)
  }

  resetSearhText(){
    this.searchText = '';
  }

  getBuyListSelected() {
    return this.buyListItemsSelected
  }


  setBuyList2(newBuyList2) {
    this.buyList2 = newBuyList2
    this.filterChange.next({ shopArticles: this.buyList2 })
  }

  getBuyList2() {
    return this.buyList2
  }

  setBuyListAllEments(newBuyListAllElements) {
    this.buyListAllElements = newBuyListAllElements
    this.filteredArticlesShop = this.buyListAllElements
    this.filterChange.next({ loaded: true })
  }

  orderBuyList( articleShop: ArticlesShop[]){


    let articlesWithStock = articleShop.filter(article =>
      article.stock > 0
    )

    articlesWithStock= articlesWithStock.sort((a,b) =>  {
      if( a.Article.toLowerCase() < b.Article.toLowerCase())
          return -1;
      if (  a.Article.toLowerCase() < b.Article.toLowerCase())
          return 1;
        return 0;
     }).sort((a,b) => a.priority - b.priority)



    let articlesWithoutStock = articleShop.filter(article =>
      article.stock <= 0
    )

    articlesWithoutStock = articlesWithoutStock.sort((a,b) => {
      if( a.Article.toLowerCase() < b.Article.toLowerCase())
        return -1;
      if (  a.Article.toLowerCase() < b.Article.toLowerCase())
        return 1;
      return 0;
    }).sort((a,b) => a.priority - b.priority)

    articleShop = articlesWithStock.concat(articlesWithoutStock)
    return articleShop;
  }

  getBuyListAllElements() {
    return this.buyListAllElements
  }

  changeFamily(family: string) {

    this.familySelected = family;
    this.applyFilter()
  }

  changeSearchFilter(searchText) {
    this.searchText = searchText;
    this.applyFilter()
  }

  modifyShopArticle(position: number, quantity: number) {

    if (quantity > 0 && (this.filteredArticlesShop[position]?.unitySelected || 0 + quantity)
      > this.filteredArticlesShop[position].stock) {
      return;
    }

    if (quantity < 0 && (this.filteredArticlesShop[position]?.unitySelected || 0) <= 0) {
      return;
    }

    this.filteredArticlesShop[position].unitySelected = (this.filteredArticlesShop[position]?.unitySelected || 0) + quantity;
    this.filteredArticlesShop[position].priceSelected =
      (this.filteredArticlesShop[position].priceSelected || 0) +
      (quantity) * this.filteredArticlesShop[position].totalPrice;

    (this.filteredArticlesShop[position].unitySelected >= 1)
      ? this.filteredArticlesShop[position].positionSelected = true
      : this.filteredArticlesShop[position].positionSelected = false;

    if (this.filteredArticlesShop[position].unitySelected === 1 && quantity > 0) {

      // TODO borrar
      // if(this.buyListItemsSelected.some(list => list.id === this.filteredArticlesShop[position].id)){
      //   console.error('ya existe el item en la lista seleccionada')
      // }

      this.buyListItemsSelected.push(this.filteredArticlesShop[position])
    } else if (this.filteredArticlesShop[position].unitySelected === 0 && quantity < 0) {
      const indexRemove = this.buyListItemsSelected.findIndex(shopArticleSelected => {
        return shopArticleSelected.id === this.filteredArticlesShop[position].id;
      });
      this.buyListItemsSelected.splice(indexRemove, 1);
    }

    let selecteds: SelectedsShopArticle[] = [];
    this.buyListAllElements.filter(buyList => buyList?.unitySelected > 0)
      .forEach(selected => {
        selecteds.push({ id: selected.id, units: selected.unitySelected })
      });

    this.filterChange.next({ shopArticles: this.filteredArticlesShop, buyListItemsSelected: this.buyListItemsSelected })
    //this.storage.set('selectedShop', JSON.stringify(selecteds));
    this._storageService.set('selectedShop', JSON.stringify(selecteds));
  }

  modifyShopArticleSelecteds(item: ArticlesShop, quantity: number){

    item.unitySelected = item.unitySelected + quantity;
    item.priceSelected = item.priceSelected + (quantity * item.totalPrice)

    if(item.unitySelected <= 0){
      const indexRemove = this.buyListItemsSelected.findIndex(shopArticleSelected => {
        return shopArticleSelected.id === item.id;
      });
      this.buyListItemsSelected.splice(indexRemove, 1);
    }

    let selecteds: SelectedsShopArticle[] = [];
    this.buyListAllElements.filter(buyList => buyList?.unitySelected > 0)
      .forEach(selected => {
        selecteds.push({ id: selected.id, units: selected.unitySelected })
    });

    this.filterChange.next({ shopArticles: this.filteredArticlesShop, buyListItemsSelected: this.buyListItemsSelected })
    this._storageService.set('selectedShop', JSON.stringify(selecteds));
  }

  applyFilter() {

    if (this.familySelected === '0') {
      this.filteredArticlesShop = this.buyListAllElements;
    } else {
      this.filteredArticlesShop = this.buyListAllElements.filter((shopArticle => shopArticle?.family === this.familySelected))
    }

    if (this.searchText.trim()) {
      this.filteredArticlesShop =
        this.filteredArticlesShop.filter(shopArticle => compareTestInsensitive(shopArticle.Article, this.searchText.trim()))
    }

    this.filterChange.next({ shopArticles: this.filteredArticlesShop })

  }

  setDetails(details: DetailsOnlineShop) {
    this.details = details;
  }

  getDetails(): DetailsOnlineShop {
    return this.details;
  }

  getParamsOrder(): { ShoparticlesID: number[], ShoparticlesQuantity: number[] } {
    let ShoparticlesID = [];
    let ShoparticlesQuantity = []
    this.buyListItemsSelected.forEach(buySelected => {
      ShoparticlesID.push(buySelected.id);
      ShoparticlesQuantity.push(buySelected.unitySelected)
    })
    return { ShoparticlesID, ShoparticlesQuantity };
  }

  setSelected(selectedArticleShop: SelectedsShopArticle[]) {

    this.buyListItemsSelected = []
    let changeSelected = false;
    selectedArticleShop.forEach((selected) => {
      let index = this.buyListAllElements.findIndex(element => element.id === selected.id)
      if (index !== -1) {


        if(selected?.units && selected.units > 0) {
          this.buyListItemsSelected.push(this.buyListAllElements[index])
          let buyElement = this.buyListAllElements[index];

          buyElement['unitySelected'] =  (this.buyListAllElements[index].stock >= selected.units)
                ? selected.units : this.buyListAllElements[index].stock;

          buyElement.priceSelected = buyElement.totalPrice * selected.units

          if( selected.units != buyElement.unitySelected){
            selected.units = buyElement.unitySelected
            if(!changeSelected) changeSelected = true;
          }

        }



      }else{
        let indexRemove = selectedArticleShop.findIndex(element => element.id === selected.id);
        if(index !== -1){
          selectedArticleShop.splice(indexRemove, 1);
          if(!changeSelected) changeSelected = true;
        }
      }
    });

    this.buyListItemsSelected = this.buyListItemsSelected.filter(items => items.unitySelected > 0)

    if(changeSelected){
      this._storageService.set('selectedShop', JSON.stringify(selectedArticleShop));
    }

    this.filterChange.next({ shopArticles: this.filteredArticlesShop, buyListItemsSelected: this.buyListItemsSelected })
    return this.buyListItemsSelected;
  }

  removeItem(positionSelected: number) {

    if (this.buyListItemsSelected[positionSelected]) {
      this.buyListItemsSelected[positionSelected].unitySelected = 0;
      this.buyListItemsSelected[positionSelected].priceSelected = 0;
      const indexRemove = this.buyListAllElements.findIndex(shopArticleSelected =>
        shopArticleSelected.id === this.filteredArticlesShop[positionSelected].id)
      if (indexRemove !== -1) {
        this.buyListItemsSelected.splice(indexRemove, 1);
      }
    }

    let selecteds: SelectedsShopArticle[] = [];
    this.buyListAllElements.filter(buyList => buyList?.unitySelected > 0)
      .forEach(selected => {
        selecteds.push({ id: selected.id, units: selected.unitySelected })
      });


    // TODO splice no remove when not all elements
    //this.buyListItemsSelected = this.buyListItemsSelected.filter(itemSelected => itemSelected.unitySelected > 0)

    this.buyListItemsSelected = selecteds.map(selected => this.buyListAllElements
          .find(buyList => buyList.id === selected.id))


    this.filterChange.next({ shopArticles: this.filteredArticlesShop, buyListItemsSelected: this.buyListItemsSelected })
    // this.storage.set('selectedShop', JSON.stringify(selecteds));
    this._storageService.set('selectedShop', JSON.stringify(selecteds));

    // console.log('buyItemsSelectedNext', this.buyListItemsSelected, 'selecteds', selecteds)
    return this.buyListItemsSelected;
  }

  clearBuyListSelected() {
    this.buyList2 = [];
    this.buyListAllElements = [];
    this.buyListItemsSelected = [];
  }

  clearSelecteds() {
    this.buyListItemsSelected = [];

    this._storageService.remove('selectedShop');
    this._storageService.remove('direction_shop');
    this._storageService.remove('contact_shop');
    this._storageService.remove('observation_shop')
  }

  clearStorageOnline(){
    this.buyListItemsSelected = [];
    this.buyList2 = [];
    this.buyListAllElements = [];

    this._storageService.remove('selectedShop');
    this._storageService.remove('notVatProducts');
    this._storageService.remove('shopArticles');
    this._storageService.remove('observation_shop')
  }

  updateProducts(articleShop: ArticlesShop[], onlyStock: ArticlesShop[], selectedArticleShop: SelectedsShopArticle[]) {

    articleShop.forEach(article => {
      let index = onlyStock.findIndex(newArticle => newArticle.id === article.id)

      if (index !== -1) {
        if (onlyStock[index].stock !== article.stock) {

          article.stock = onlyStock[index].stock;
        }
      } else {
        console.error('Error en los artículos')
      }
    })

    articleShop = this.orderBuyList(articleShop);

    this._storageService.set('shopArticles', JSON.stringify(articleShop))

    this.setBuyListAllEments(articleShop);
    this.setSelected(selectedArticleShop);
  }

  saveNumberNotVatProducts(notVatProducts:number){
    this._storageService.set('notVatProducts', notVatProducts);
  }

  getNumberNotVatProducts(): Promise<number>{
    return this._storageService.get('notVatProducts')
  }

}
interface FilterOnlineShop {
  loaded?: boolean,
  shopArticles?: ArticlesShop[],
  buyListItemsSelected?: ArticlesShop[],
}

