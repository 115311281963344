import { CharacteristicsTillAlertSpider, StatusTillAlert } from "../models";

export const getCharacteristicsByStatusTillAlert = (
  status: StatusTillAlert[]
): CharacteristicsTillAlertSpider[] => {

  return status.map((state)=> ({
    id: state?.ID,
    description: (state?.Description || '').toLowerCase()
  }))

}
