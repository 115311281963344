

// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

// Modules



// Component
import { FilterTemplateGuidedSelectComponent } from './filter-template-guided-select.component';

// Service
import { LanguageService } from 'src/app/share/services';
import { SkinMangementService } from 'src/app/services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    FilterTemplateGuidedSelectComponent
  ],
  providers: [LanguageService, SkinMangementService],
  exports: [
    FilterTemplateGuidedSelectComponent
  ],
})
export class FilterTemplateGuidedSelectModule {}
