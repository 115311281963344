// route
export const ROUTE_SALES_MODULE = '/user/sales-module';

// permissions
export const NAME_REFILLING_SALES_MODULE = 'Gestión de producto';
export const NAME_PICKING_SALES_MODULE = 'Gestión de producto sin capacidad';


export const PRODUCT_PREPARED = 1.2;

export const PERCENTAGE_SALES_MODULE_PICKING: number[] = [
  5, 10, 15
]
