import { Operator } from 'src/app/models';
import { ClientDashboardWelcome } from '../../models';

export const getSymbolCurrency = (
  operators: Operator[],
  clientSelected: ClientDashboardWelcome
) => {
  const operator = operators.find(
    (operator) => clientSelected.OperatorId == operator.id
  );
  return operator?.Currency[0]?.Symbol || '€';
};
