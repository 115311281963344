// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from 'src/app/models';
import { alliance, Rocca, V2vFactory } from './companySetting';

// http://10.0.2.2:3000/'
// http://localhost:3000/
// https://elora-test.azurewebsites.net/
// https://elora.azurewebsites.net/
// 'http://localhost:3000/',
 // 'https://elora-test-aena.azurewebsites.net/'
export const environment: Environment = {
  production: false,
  // RTVnodeUrl  : 'https://elora-test-aena.azurewebsites.net/',
  // RTVnodeUrl  : 'https://elora-test.azurewebsites.net/',
  //RTVnodeUrl  : 'https://portal-test.api-elora.net/',
  RTVnodeUrl  : 'https://portal-test.api-elora.net/',
  //RTVnodeUrl  : 'https://portal-test.api-elora.net/',
  // RTVnodeUrl  : 'https://elora-rtvm.azurewebsites.net/',


  Redsys: 'https://sis-t.redsys.es:25443/sis/realizarPago',
  // baseUrlAlliance: `${alliance.url_local}:8100`,
  // baseUrlElora: `${V2vFactory.url_local}:8100`,
  baseUrlAlliance: `${alliance.url_test}:8100`,
  baseUrlElora: `${V2vFactory.url_test}:8100`,
  baseURLRocca: `${Rocca.url_test}:8110`,
  baseURLTriangle: `${Rocca.url_test}:8110`,
  baseURLImage:  'https://api-test.v2vfactory.net:8081/services/',
  RTVActionNodeUrl  : 'http://localhost:3001/',
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

