import { twoDecimalsTransform } from "src/app/utils";


export const applyTwoDecimalsTotals = (totals: { units: number[]; billing: number[] }) => {
  for (let unit of totals.units) {
    unit = twoDecimalsTransform(unit);
  }

  for (let billing of totals.billing) {
    billing = twoDecimalsTransform(billing);
  }
};
