// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer-version {
  font-size: 20px;
  line-height: 40px;
  color: var(--ion-color-light);
}

.text-link {
  color: #488aff;
  cursor: pointer;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/footer-version/footer-version.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,6BAAA;AACJ;;AACE;EACE,cAAA;EACA,eAAA;EACA,0BAAA;AAEJ","sourcesContent":["#footer-version {\r\n    font-size: 20px;\r\n    line-height: 40px;\r\n    color: var(--ion-color-light);\r\n  }\r\n  .text-link{\r\n    color: #488aff;\r\n    cursor: pointer;\r\n    text-decoration: underline;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
