import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

// utils
import { addTimeDate } from 'src/app/utils';

// ------------------------------ interfaces ------------------------------ //

export interface GetLabelsDayByWeek {
  dateSelected: Date;
  datePipe: DatePipe;
  _translateService: TranslateService;
}

interface TransformDate {
  datePipe: DatePipe;
  datePipeTranslate: string;
  dateSelected: Date;
  position: number;
}

// ------------------------------ functions ------------------------------ //
export const getLabelsDayByWeek = async ({
  dateSelected,
  datePipe,
  _translateService,
}: GetLabelsDayByWeek) => {
  const datePipeTranslate = _translateService.instant(
    'format_pipe_without_hour'
  );

  return [
    transformDate({ datePipe, datePipeTranslate, dateSelected, position: 0 }),
    transformDate({ datePipe, datePipeTranslate, dateSelected, position: 1 }),
    transformDate({ datePipe, datePipeTranslate, dateSelected, position: 2 }),
    transformDate({ datePipe, datePipeTranslate, dateSelected, position: 3 }),
    transformDate({ datePipe, datePipeTranslate, dateSelected, position: 4 }),
    transformDate({ datePipe, datePipeTranslate, dateSelected, position: 5 }),
    transformDate({ datePipe, datePipeTranslate, dateSelected, position: 6 }),
  ];
};

const transformDate = ({
  datePipe,
  datePipeTranslate,
  dateSelected,
  position,
}: TransformDate) => {
  const datePos = addTimeDate({
    startDate: dateSelected,
    increment: position,
    typeIncrement: 'days',
  });

  return datePipe.transform(datePos, datePipeTranslate);
};
