
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from '../../../../../../share/services/languageRoute.ts/languagesRoute.services';
import { Company } from 'src/app/models';

// service
import { SkinMangementService } from '../../../../../../services';

// utils
import { type } from '../../../../../../utils/constants';


@Component({
  selector: 'app-legend-card-activity',
  templateUrl: './legend-card-activity.component.html',
  styleUrls: ['./legend-card-activity.component.scss'],
})
export class LegendCardActivityComponent implements OnInit {

  // aspecto
  company: Company;
  type = type;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
