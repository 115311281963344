export enum PeriocityOptionReport{
  Daily =  1,
  Weekly = 2,
  Monthly = 3,
}

export const PeriocityTextOptionsReport = {
  1: 'Daily',
  2: 'Weekly',
  3: 'Monthly'
}


