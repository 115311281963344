// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  text-align: center;
  align-items: center;
  width: 100%;
  border-collapse: collapse;
}

th {
  color: var(--ion-color-light);
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  padding: 2px;
  height: 40px;
}

tr {
  font-size: 18px;
}

td,
th {
  padding: 2px;
  border: 1px solid var(--ion-color-light-table-th);
  text-align: left;
  text-align: center;
}

.h-50 {
  height: 50px;
}

.d-flex-center2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mr-5 {
  margin-right: 5px;
}

.icon-lg {
  font-size: 1.4em;
}

.font-lg {
  font-size: 1.2em;
}

.mt-10 {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/webload/load-credit/component/information-modal/information-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,yBAAA;AACF;;AAEA;EACE,6BAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;;EAEE,YAAA;EACA,iDAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAGA;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF","sourcesContent":["table {\r\n  text-align: center;\r\n  align-items: center;\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n}\r\n\r\nth {\r\n  color: var(--ion-color-light);\r\n  font-weight: bold;\r\n  text-align: center;\r\n  font-size: 18px;\r\n  padding: 2px;\r\n  height: 40px;\r\n}\r\n\r\ntr {\r\n  font-size: 18px;\r\n}\r\n\r\ntd,\r\nth {\r\n  padding: 2px;\r\n  border: 1px solid  var(--ion-color-light-table-th);\r\n  text-align: left;\r\n  text-align: center;\r\n}\r\n\r\n.h-50{\r\n  height: 50px;\r\n}\r\n\r\n\r\n.d-flex-center2{\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n}\r\n\r\n.mr-5{\r\n  margin-right: 5px;\r\n}\r\n\r\n.icon-lg{\r\n  font-size: 1.4em;\r\n}\r\n\r\n.font-lg{\r\n  font-size: 1.2em;\r\n}\r\n\r\n.mt-10{\r\n  margin-top: 10px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
