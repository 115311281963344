import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IonIconsRemoveTitleService {

  idIcons: string[] = []

  constructor() {}

  addIdIcon(idIcon: string){
    this.idIcons.push(idIcon)
  }

  haveIdIcon(idIcon: string){
    return this.idIcons.includes(idIcon)
  }

  destroy(){
    this.idIcons = [];
  }

}
