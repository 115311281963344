import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// services
import { ClientService } from '../../../services/';

// models
import { Client } from '../../../../models/';
import { GetClientsParams } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class ClientFilterService {
  code = '';
  name = '';
  idClient: number = null;

  loadedClient: boolean = false;
  loadingClient: boolean = false;

  filterChange: Subject<FilterInfoClient> = new Subject<FilterInfoClient>();
  changeState: Subject<LoadingClient> = new Subject<LoadingClient>();

  clients: Client[] = [];

  filtered: boolean = false;

  operatorId = null;
  clientGroupId = null;

  constructor(private _clientService: ClientService) {}

  startClients(
    operatorId = null,
    clientGroupId = null,
    operatorGroupId = null
  ) {
    let params: GetClientsParams = {};

    this.operatorId = operatorId;
    this.clientGroupId = clientGroupId;

    if (clientGroupId) {
      params.clientGroupId = clientGroupId;
    }

    if (operatorId && operatorId != -1) {
      params.operatorId = operatorId;
    }

    if (operatorGroupId || operatorGroupId === 0) {
      params.operatorGroupId = operatorGroupId;
    }

    if (!this.loadedClient) {
      this.loadingClient = true;
      this.clients = [];
      this.idClient = null;
      this.name = '';
      this.code = '';
      this.filterChange.next({
        idClient: this.idClient,
        name: this.name,
        code: this.code,
        haveOneClient: false,
      });
      this.changeState.next({
        loadingClient: this.loadingClient,
        loadedClient: this.loadedClient,
      });

      this._clientService.getClients(params).subscribe({
        next: (res: Client[]) => {
          this.clients = res;
          if (this.clients.length === 1) {
            this.idClient = this.clients[0].ID;
            this.name = this.clients[0].name;
            this.code = this.clients[0].code;
            this.filterChange.next({
              idClient: this.idClient,
              name: this.name,
              code: this.code,
              haveOneClient: true,
            });
          }
          this.loadedClient = true;
          this.loadingClient = false;
          this.changeState.next({
            loadingClient: this.loadingClient,
            loadedClient: this.loadedClient,
          });
        },
        error: (error) => {
          // console.log('error', error);
          this.clients = [];
          this.loadedClient = true;
          this.loadingClient = false;
        },
      });
    }
  }

  updateFilter(
    operatorId: number = null,
    clientGroupId: number = null,
    operatorGroupId: number = null
  ) {
    this.code = '';
    this.name = '';
    this.idClient = null;

    this.loadedClient = false;

    if (!this.filtered) this.filtered = true;
    this.filterChange.next({
      name: this.name,
      code: this.code,
      idClient: this.idClient,
    });
    this.startClients(operatorId, clientGroupId, operatorGroupId);
  }

  reload(operatorId = null, clientGroupId = null) {
    if (
      this.operatorId !== operatorId ||
      this.clientGroupId !== clientGroupId
    ) {
      this.updateFilter(operatorId, clientGroupId);
    }
  }

  getClients(): Client[] {
    return this.clients;
  }

  // clients
  getNameClient() {
    return this.name;
  }

  getCodeClient() {
    return this.code;
  }

  getClientId() {
    return this.idClient;
  }

  getInfoClient(): FilterInfoClient {
    return { name: this.name, code: this.code, idClient: this.idClient };
  }

  setClient(name: string, code: string, id: number) {
    this.name = name;
    this.code = code;
    this.idClient = id;
    this.filterChange.next({
      name: this.name,
      code: this.code,
      idClient: this.idClient,
    });
  }

  getFilterClient(): FilterClient {
    return {
      name: this.name,
      code: this.code,
      idClient: this.idClient,
      loadedClient: this.loadedClient,
      loadingClient: this.loadingClient,
    };
  }

  getLoaded(): boolean {
    return this.loadedClient;
  }

  clearFilter(clearClient = false) {
    this.code = null;
    this.name = null;
    this.idClient = null;
    this.filtered = false;
    this.filterChange.next({
      name: this.name,
      code: this.code,
      idClient: this.idClient,
    });
    if (clearClient) {
      this.loadedClient = false;
      this.loadingClient = false;
      this.clients = [];
      this.changeState.next({
        loadedClient: this.loadedClient,
        loadingClient: this.loadingClient,
      });
    }
  }
}

interface FilterInfoClient {
  name: string;
  code: string;
  idClient: number | null;
  haveOneClient?: boolean;
}

interface LoadingClient {
  loadingClient: boolean;
  loadedClient: boolean;
}

interface FilterClient {
  name: string;
  code: string;
  idClient: number;
  loadedClient: boolean;
  loadingClient: boolean;
}

interface paramsClient {
  operatorId?: number;
  clientGroupId?: number;
}
