import { errorsTypes } from 'src/app/utils';

export const getEmailAndMachineErrorMachineStatus = (error: String) => {
  let email = '';
  let machine = '';

  const machineAndEmail = error
    .replace(errorsTypes.error_register_alert_machine_status1, '')
    .replace(errorsTypes.error_register_alert_machine_status2, ',');

  const divideMahcineAndEmail = machineAndEmail.split(',');

  if (divideMahcineAndEmail.length > 1) {
    machine = divideMahcineAndEmail[0].trim();
    email = divideMahcineAndEmail[1].trim();
  }

  return { machine, email };
};
