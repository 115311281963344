import { iconMode, iconState } from 'src/app/utils';

// ------------------------------------------------------ ROUTES --------------------------------------------------------

export const TILL_ALERT_ROUTE = '/user/tills-alerts';
export const TILL_ALERT_BY_USER_ROUTE = '/user/tills-alerts-by-user';


export const MAX_LENGTH_CLIENT_SCREEN_SM = 15;

// ------------------------------------------------------ CHART --------------------------------------------------------

export const NUM_CHARTS = 10;

// ---------------------------------------------------- ICON  STATUS ----------------------------------------------------

interface IconStatus {
  name: string;
  color: string;
  text: string;
}

// ---------------------------------------------------- ICON STATUS ----------------------------------------------------
export const ICON_STATUS: Record<string, IconStatus> = {
  blocked: iconMode['Blocked'],
  failure: iconMode['failure'],
  disconnected: iconState['0'],
  'no sales': { name: 'production_quantity_limits', color: 'dark', text: 'till_alert.status.no_sales' },
};

export const STATUS_TILL_ALERT = ['failure', 'blocked', 'disconnected', 'no sales'];
export type IconStatusValids = 'failure' | 'blocked' | 'disconnected' | 'no sales';

export const ORDER_STATUS_TILL_ALERT: Record<IconStatusValids, number> = {
  failure: 1,
  blocked: 2,
  disconnected: 0,
  "no sales": 3
}

// ---------------------------------------------------- ICON PRIORITY ----------------------------------------------------
export const PRIORITIES_TYPES =  {
  alta: 'high',
  media: 'medium',
  baja: 'low'
}

export const ICON_PRIORITIES: Record<string, IconStatus> = {
  high: {
    color: 'success',
    name: 'keyboard_double_arrow_up',
    text: 'till_alert.priority.high',
  },
  medium: {
    color: 'warning',
    name: 'keyboard_capslock',
    text: 'till_alert.priority.medium',
  },
  low: {
    color: 'danger',
    name: 'keyboard_arrow_up',
    text: 'till_alert.priority.low',
  },
};

export type IconPriorityValids = 'high' | 'medium' | 'low';
export const PRIORITIES_TILL_ALERT = ['high' , 'medium' , 'low'];

export const ORDER_PRIORITIES_TILL_ALERT: Record<IconPriorityValids, number> = {
  high: 2,
  medium: 1,
  low: 0
}

// ---------------------------------------------------- Height Chart  ----------------------------------------------------
export const HEIGHT_CANVAS = {
  xl: 50,
  lg: 30,
  md: 40,
  sm: 34
}
