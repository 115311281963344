import { Company } from '../../app/models/';

export const TriangleSkin: Company = {
  tag: 'triangle',
  companySkin: 'Triangle',
  name: 'Triangle',
  environment: 'baseURLTriangle',
  appConfig: 'baseURLTriangle',

  nameCard: 'Tarjeta Cautiva',
  minCard: 6,
  maxCard: 8,
  url: 'triangle.v2vfactory.com',
  url_test: 'triangle.v2vfactory.com',
  url_local: 'localhost',

  companyId: 1,
  operatorGroupId: 2,

  color: 'triangle-skin',
  color2: 'alliance2',
  color3: 'alliance3',
  colorText: 'triangle-skin-text',
  colorText2: 'alliance-text2',
  colorFilter: 'filter-alliance',
  colorNavBar: 'triangle-skin',
  colorMenuText: 'dark',

  bgColor1: 'triangle-skin-color-toolbar',
  colorTextTitle: 'alliance-color-text-title',
  titlePage: 'triangle-skin-title-page',

  // rgb
  colorRGB: '#926c4f',

  // sliders
  colorBullets: 'color-bullet-alliance',

  // Menu
  activeLink: 'active-link-triangle-skin',
  groupItemMenu: 'group-item-menu-triangle-skin',
  itemMenu: 'group-item-menu-triangle-skin',

  // Profile
  ionCardHeader: 'ion-card-header-triangle-skin',
  ionCardText1: 'ion-card-text1-triangle-skin',
  ionCardText2: 'ion-card-text2-triangle-skin',
  ionTextCardFooter: 'text-card-footer-triangle-skin',

  // date-period
  ionSegmentButtonStyle: 'ion-segment-button-alliance-style',
  ionSegmentButtonStyle2: 'ion-segment-button-alliance-style2',
  tableFilter: 'filter-table-alliance',

  bgNavbar: 'bg-navbar-triangle-skin',
  openMenuColor: 'dark',

  // table
  bgTable: 'bg-table-triangle-skin',
  bgTable1: 'bg-table-switch-pair-triangle-skin',
  bgTable2: 'bg-table-switch-odd-triangle-skin',
  bgTable3: 'bg-filter-alliance',
  bgHoverTable: 'bg-hover-table-alliance',
  bgHoverTableWithoutFilter: 'bg-hover-table-sm-alliance',
  subHeaderTable: 'subheader-table-alliance',
  headerVerticalTable: 'header-vertical-table-triangle-skin',

  // spinner
  spinnerColor: 'spinner-color-alliance',

  // site
  blockTable: 'block-table-alliance',
  bgTableBlockHover: 'bg-table-block-hover-alliance',
  bgTableSelected: 'bg-table-selected-alliance',

  //label not found, warning
  labelDialog: 'label-dialog-triangle-skin',

  // top 5, product sold
  labelDialogLoading: 'label-dialog-loading-alliance',

  // card (load-credit-card)
  cardTop: 'card-top-alliance',
  cardBottom: 'card-bottom-alliance',

  infoAlert: 'info-alert-alliance',

  segmentIos: 'segment-ios-alliance',

  // chartColors
  colorRGBTitleDark: '#bda695',
  chartColors: [
    {
      backgroundColor: [
        '#926c4f',
        '#9c7a60',
        '#a78972',
        '#b29883',
        '#bda695',
        '#c8b5a7',
        '#d3c4b8',
        '#ded2ca',
        '#e9e1db',
        '#f4f0ed',
        '#fbfaf9',
      ],
      borderColor: [
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
      ],
    },
  ],
  chartColorsDark: [
    {
      backgroundColor: [
        '#f9f6f4',
        '#e4d7cd',
        '#ddcdc1',
        '#d7c4b5',
        '#d0baa9',
        '#c9b09c',
        '#c3a690',
        '#bc9c84',
        '#b69278',
        '#af896b',
        '#a87f5f',
      ],
      borderColor: [
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
      ],
    },
  ],

  ionColorToogle: 'ion-color-toogle-alliance',

  // online shop
  borderCard: 'border-card-alliance',
  borderCart: 'border-cart-alliance',
  border: 'border-alliance',

  // ecommerce-manager
  bgTable1Item: 'bg-table1-item-alliance',
  bgTable2Item: 'bg-table2-item-alliance',
  colorTextNoEditable: 'color-no-editable-text-alliance',
  colorTextNoEditableItem: 'var(--ion-color-alliance-not-editable2)',

  // notification-manager
  buttonInfo: {
    backgroundColor: '#815a2f',
    borderColor: '#815a2f',
    textColor: '#f2f2f2',
    text: '',
    url: 'https://www.alliancevending.es/',
  },

  // access-web
  segmentVertical: 'segment-alliance-vertical',
  swiperBgAndBorder: 'swiper-alliance-bg',
  itemCheckboxAccessWeb: 'item-checkbox-alliance-access-web',
  checkboxAccessWeb: 'checkbox-alliance-access-web',
  bgColorContent: 'bg-color-content-alliance',
  borderHeadDark: 'border-head-dark-alliance',

  // range slider (sales-module)
  colorBallRange: 'var(--ion-color-alliance-tint)',
  colorLineRange: 'var(--ion-color-alliance)',
  colorInsideBallRange: 'var(--ion-color-alliance-contrast)',
  colorInsideBallRangeActive: 'var(--ion-color-alliance-shade)',

  // pagination
  paginationCurrentBg: 'var(--ion-color-alliance)',
  paginationColorDark: 'var(--ion-color-dark)',
  paginationColorLight: 'var(--ion-color-light)',

  // tag status
  colorIconWithHoverTable: 'color-icon-with-hover-table-alliance',

  // pre login
  colorCompanyText: 'var(--ion-color-triangle-skin-text)',
  colorBorderCheckBox: 'var(--ion-color-login-triangle-checkbox)',
  colorCompanyText2: 'var(--ion-color-triangle-skin-text)',

  // custom alert
  customAlertStyle: 'custom-alert-triangle',

  // ecommerce manager
  colorButtonTable: 'alliance-button-table',
  colorButtonTableHover: 'var(--ion-color-alliance-button-table-hover)',

  // charts v4
  colorsCharts: [
    '#926c4f',
    '#9c7a60',
    '#a78972',
    '#b29883',
    '#bda695',
    '#c8b5a7',
    '#d3c4b8',
    '#ded2ca',
    '#e9e1db',
    '#f4f0ed',
  ],

  backgroundChart: [
    '#926c4f',
    '#9c7a60',
    '#a78972',
    '#b29883',
    '#bda695',
    '#c8b5a7',
    '#d3c4b8',
    '#ded2ca',
    '#e9e1db',
    '#f4f0ed',
  ],

  backgroundChartDark: [
    '#e4d7cd',
    '#ddcdc1',
    '#d7c4b5',
    '#d0baa9',
    '#c9b09c',
    '#c3a690',
    '#bc9c84',
    '#b69278',
    '#af896b',
    '#a87f5f',
  ],

  borderChart: [
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
  ],

  borderChartDark: [
    '#5b4331',
    '#5b4331',
    '#5b4331',
    '#5b4331',
    '#5b4331',
    '#5b4331',
    '#5b4331',
    '#5b4331',
    '#5b4331',
    '#5b4331',
    '#5b4331',
  ],
  donutBackground: ['rgba(146, 108, 79, 1)', 'rgba(217, 83, 79, 1)'],
  donutBackgroundDark: ['rgba(189, 166, 149, 1)', 'rgba(207, 102, 121, 1)'],

  colorTextVar: 'var(--ion-color-alliance-text)',
  colorTextHover: 'var(--ion-color-triangle-skin-text-hover)',

  // phone material
  colorTableVisibilityBg: 'var(--ion-color-alliance-tint-switch2)',
  colorTableVisibilityPlaceholder: '#d6c6b6',
  colorTablePhoneLightEg: 'medium',

  // till-alert, qr-request
  successTable: 'success',
  qrRequestColorTable: 'qr-request',

  // notification-panel
  colorAndroidIconTable: 'var(--ion-color-android-icon)',

  // dashboard welcome
  colorMonthBar: [
    '#F4F0ED',
    '#EBE4DF',
    '#E2D8D0',
    '#D9CCC2',
    '#D0C0B4',
    '#C7B4A5',
    '#BFA897',
    '#B69C88',
    '#AD907A',
    '#A4846C',
    '#9B785D',
    '#926C4F',
  ],

  colorMonthBarDark: [
    '#926C4F',
    '#9B785D',
    '#A4846C',
    '#AD907A',
    '#B69C88',
    '#BFA897',
    '#C7B4A5',
    '#D0C0B4',
    '#D9CCC2',
    '#E2D8D0',
    '#EBE4DF',
    '#F4F0ED',
  ],

  customPopoverDashboard: 'custom-popover-dashboard-v2v',

  homeBG: 'alliance-home',

  // User Documentation
  colorChipSuccess: 'var(--ion-color-alliance-text)',

  colorCardBg: 'alliance-card-bg',
};
