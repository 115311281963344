import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Config
import { AppConfig } from '../../../config/config';

// Models
import { OperatorGroup } from '../../../models/operator-group.model';
import {
  ParamsEditOperatorGroup,
  ParamsOperatorGroup,
  CreateOperatorGroupResponse,
} from 'src/app/pages/user/data-manager/operator-group/models';

@Injectable({
  providedIn: 'root',
})
export class OperatorGroupService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient, protected router: Router) {}

  getOperatorGroup(params: ParamsGetOperatorGroup = {}) {
    let endpoint = `operator-group/get-operator-group`;
    let routeUrl = this.router.url;
    return this.httpClient
      .post<OperatorGroup[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        {}
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getOperatorGroupById(operatorGroupId: number) {
    const params: ParamsGetOperatorGroup = { operatorGroupId };

    return this.getOperatorGroup(params);
  }

  createOperatorGroup(params: ParamsOperatorGroup) {
    let endpoint = 'operator-group';
    let routeUrl = this.router.url;
    return this.httpClient
      .post<CreateOperatorGroupResponse>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  editOperatorGroup(params: ParamsEditOperatorGroup) {
    let endpoint = 'editOperator-group';
    let routeUrl = this.router.url;
    return this.httpClient
      .post<any>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}

export interface ParamsGetOperatorGroup {
  operatorGroupId?: number;
}
