export const ORDER_VISIBILITY_ROUTES: string[] = [
  '/user/data-manager/operator-group-manager',
  '/user/data-manager/operator-manager',
  '/user/data-manager/client-group-manager',
  '/user/data-manager/client-manager',
  '/user/data-manager/site-manager',
  '/user/data-manager/till-manager',
  '/user/data-manager/article-manager',

  '/user/data-manager/supervisor-manager',
  '/user/data-manager/routeDriver-manager',
  '/user/data-manager/route-manager',
];
