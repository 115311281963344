import { WritableSignal } from '@angular/core';

// models
import { MultipleMachineSelected } from 'src/app/share/components/select-multiple-machine/models';
import {
  FilterClientOptionsRM,
  PositionVisibilityRouteManager,
  TillSelectedRM,
} from '../../models';

interface SelectMultipleMachineByInfoModalRM {
  tillsSelectedInfo: MultipleMachineSelected[];
  tills: WritableSignal<TillSelectedRM[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
  filterClientOptions: WritableSignal<FilterClientOptionsRM>;
  positionVisibility: WritableSignal<PositionVisibilityRouteManager>;
}

export const selectMultipleMachineByInfoModalRM = ({
  tillsSelectedInfo,
  tills,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
  filterClientOptions,
  positionVisibility,
}: SelectMultipleMachineByInfoModalRM) => {
  tillsSelected.set(tillsSelectedInfo);
  tillOneMachineSelected.set(null);

  filterClientOptions.set(FilterClientOptionsRM.filterClient);
  positionVisibility.set(PositionVisibilityRouteManager.client);

  let newTills = tills();
  let newTillsByOperator = tillsByOperator();

  for (let till of tillsSelected()) {
    let tillSelected = newTills.find((tillPos) => tillPos.id === till.id);
    if (tillSelected) {
      tillSelected.isSelected = true;
    }

    let tillSelectedByOperator = newTillsByOperator.find(
      (tillPos) => tillPos.id === till.id
    );
    if (tillSelectedByOperator) {
      tillSelectedByOperator.isSelected = true;
    }
  }

  tills.set(newTills);
  tillsByOperator.set(newTillsByOperator);
};
