import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterDatePeriodNoCalendarService {

  //date
  startDate
  startDateOriginal
  dateStart: string
  startDateParams: string
  startDateFormated:string = ''
  defaultDate;
  currentDate;

  //period
  startDateA:string = ''
  endDateA:string = ''
  dateEnd:string = ''
  startPeriod;
  endPeriod;
  endPeriodFormated:string = ''
  startPeriodFormated:string = ''
  periodSelected:boolean = false

  filterChange: Subject<FilterDateNoCalendar> = new Subject<FilterDateNoCalendar>();

  constructor() { }

  //---------save the date that the user will send, some functions save the value by default and others the value that user picks

  setDate(newDate: string) {
    this.startDateOriginal = newDate
  }

  getDate() {
    return this.startDateOriginal
  }

  setDateParams(newStartDate: string) {
    this.startDateParams = newStartDate
  }

  getDateParamsStart() {
    return this.startDateParams
  }

  getDateParamsEnd() {
    if (!this.startDateOriginal) return

    let newDateParamsEnd = new Date(this.startDateOriginal)
    const lastday = (y,m) => {
      return  new Date(y, m +1, 0).getDate();
    }

    let endDateParams = [newDateParamsEnd.getFullYear(), (newDateParamsEnd.getMonth() + 1).toString().padStart(2, "0"),
    + lastday(newDateParamsEnd.getFullYear(), newDateParamsEnd.getMonth() ) ].join('-');

    return endDateParams
  }

  setPeriodParams(dateStartA: string, dateEndA: string) {
    this.startDateA = dateStartA
    this.endDateA = dateEndA
  }

  getStartPeriodParams() {
    return this.startDateA
  }

  getEndPeriodParams() {
    return this.endDateA
  }


  //--------save the date with the original format------
  setPeriodStart(newPeriodStart: string) {
    this.startPeriod = newPeriodStart
  }

  setPeriodEnd(newPeriodEnd: string) {
    this.endPeriod = newPeriodEnd
  }

  getStartPeriod() {
    return this.startPeriod
  }

  getEndPeriod() {
    return this.endPeriod
  }

  //---------------

  //save the dates with the string format YY - M
  setStartDateFormated(newDateFormated: string) {
    this.startDateFormated = newDateFormated
  }

  getStartDateFormated() {
    return this.startDateFormated
  }
  //----

  resetDate() {
    this.startDate = null
    this.startPeriod = null
    this.endPeriod = null
    this.startDateOriginal = null
    this.startDateParams = ''
    this.periodSelected = false
    this.filterChange.next({reset: true})
  }

  getDateStart() {
      let dateFull = new Date();
      this.dateStart = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
        }`;
    return this.dateStart;
  }

  getTextExcel(){
    let dateFull = new Date();
    this.dateStart = `${dateFull.getFullYear()}-${
      dateFull.getMonth() + 1
      }`;
     if (this.getSegment()) {
      return {
        datainici: this.dateStart,
        datafinal: this.dateEnd,
      };

    } else {
      return {
        datainici: this.dateStart,
      };
    }

  }

  getDefaultDate() {
    let dateFull = new Date();
    this.defaultDate = `${dateFull.getFullYear()}-${
      dateFull.getMonth() + 1
    }-${'01'}`;
    return this.defaultDate;
  }

  getCurrentDay() {
    let dateFull = new Date();
    this.currentDate = `${dateFull.getFullYear()}-${
      dateFull.getMonth() + 1
    }-${dateFull.getDate().toString().padStart(2, "0") }`;
    return this.currentDate;
  }

  getCurrentDayWithDay() {
    let dateFull = new Date();
    this.currentDate = `${dateFull.getFullYear()}-${
      dateFull.getMonth() + 1
    }`;
    return this.currentDate;
  }

  //----------save the dates with the format YEAR - MONTH
  setPeriodStartFormated(startPeriodFormated: string) {
    this.startPeriodFormated = startPeriodFormated
  }

  setPeriodEndFormated(endPeriodFormated:string) {
    this.endPeriodFormated = endPeriodFormated
  }

  getPeriodStartFormated() {
    return this.startPeriodFormated
  }

  getPeriodEndFormated() {
    return this.endPeriodFormated
  }

  //------------


  getDateEnd() {
    if (!this.dateEnd) {
      let dateFull = new Date();
      this.dateEnd = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }`;
    }
    return this.dateEnd;
  }


  getYearSelected() {

    if (this.periodSelected && this.startPeriod) {
      let newPeriod = new Date(this.startPeriod)
      return newPeriod.getFullYear()
    }

    if (this.startDateOriginal) {
      let newYear = new Date(this.startDateOriginal)
      return newYear.getFullYear()
    }

    else {
      let currentYear = new Date()
      return currentYear.getFullYear()
    }

  }

  setCheckSegment(newSegment:string) {
    if (newSegment == 'period'){
      this.periodSelected = true
    }
    else{
      this.periodSelected = false
    }
  }

  getSegment() {
    return this.periodSelected
  }

  // setDateOnChange() {

  // }


}

interface FilterDateNoCalendar {
  reset?: boolean
}
