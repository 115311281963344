// models
import { Client } from 'src/app/models';
import { InfoMachineAlertSelected, MachineAlert } from '../models';

export const getMachinesSelectedsByClient = (
  tills: MachineAlert[],
  clients: Client[]
): {
  machinesSelectedsByClient: Record<number, InfoMachineAlertSelected>,
  moreOneMachine: boolean
  } => {
  let machinesSelectedsByClient: Record<number, InfoMachineAlertSelected> = {};

  const tillsValids = tills.filter((till) => till.isSelected);

  for (let till of tillsValids) {
    const clientId = till?.clientId;
    if (machinesSelectedsByClient?.[clientId]) {
      machinesSelectedsByClient[clientId].machineCodes.push(till.code);
    } else {
      const client = clients.find((client) => client.ID === clientId);
      machinesSelectedsByClient[clientId] = {
        client: client?.description || '',
        machineCodes: [till.code],
      };
    }
  }

  return {
    machinesSelectedsByClient: machinesSelectedsByClient,
    moreOneMachine: tillsValids.length > 0,
  };
};
