import { Component, Input, OnInit, ViewChild, WritableSignal } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';

// utils
import { MASTER_CHECK_TIME_OUT, timeReload } from 'src/app/utils';

// models
import { Company } from 'src/app/models';
import { MultipleMachineSelected } from '../models/multiple-machine-selected.model';

@Component({
  selector: 'app-select-multiple-machine-no-modal',
  templateUrl: './select-multiple-machine-no-modal.component.html',
  styleUrls: ['./select-multiple-machine-no-modal.component.scss'],
})
export class SelectMultipleMachineNoModalComponent implements OnInit {

  @Input() company: Company;

  @Input() tills: WritableSignal<MultipleMachineSelected[]>;
  @Input() tillsFiltered: MultipleMachineSelected[] = [];
  @Input() tillsSelected:WritableSignal<MultipleMachineSelected[]>;


  @Input() contentHeight: number;

  @Input() masterCheck: WritableSignal<boolean>;
  @Input() isIndeterminate: WritableSignal<boolean>;
  @Input() eventSelectAll: Observable<{
    masterCheck: boolean;
    clientId: number;
  }>;

  @Input() numElements = 50;

  search: string = '';

  tillsFilter: MultipleMachineSelected[] = [];
  tillsShower: MultipleMachineSelected[] = [];

  private eventSelectAllSubscription: Subscription;

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor() { }

  ngOnInit() {
    this.start();

    this.eventSelectAllSubscription = this.eventSelectAll.subscribe((res) =>
      this.selectAll(res)
    );
  }

  ngOnDestroy() {
    if (this.eventSelectAllSubscription) {
      this.eventSelectAllSubscription.unsubscribe();
    }
  }


  start() {

    setTimeout(() => {
      this.checkEvent();
    }, MASTER_CHECK_TIME_OUT);


    this.tillsFilter = this.tillsFiltered;
    this.tillsShower = [];
    if (this.tillsFilter.length < this.numElements) {
      this.tillsShower.push(
        ...this.tillsFilter.slice(0, this.tillsFilter.length)
      );
    } else {
      this.tillsShower.push(
        ...this.tillsFilter.slice(0, this.numElements)
      );
    }

    if (this.search) {
      this.changeSearch();
    }
  }

  changeSearch() {
    if (!this.search) {
      this.start();
    } else {
      this.tillsShower = [];
      this.tillsFilter = this.tillsFiltered.filter((element) =>
        element.code
          ?.toString()
          .toLowerCase()
          .trim()
          .includes(this.search.toString().toLowerCase().trim())
      );
      if (this.tillsFilter.length < this.numElements) {
        this.tillsShower.push(
          ...this.tillsFilter.slice(0, this.tillsFilter.length)
        );
      } else {
        this.tillsShower.push(
          ...this.tillsFilter.slice(0, this.numElements)
        );
      }
    }
  }

  loadData() {
    setTimeout(() => {
      if (this.tillsShower.length >= this.tillsFilter.length) {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      let newElements = [];
      if (
        this.tillsFilter.length <
        this.tillsShower.length + this.numElements
      ) {
        newElements = this.tillsFilter.slice(
          this.tillsShower.length,
          this.tillsFilter.length
        );
      } else {
        newElements = this.tillsFilter.slice(
          this.tillsShower.length,
          this.tillsShower.length + this.numElements
        );
      }
      this.tillsShower.push(...newElements);

      this.inifiteScroll.complete();
    }, timeReload);
  }

  checkIsSelected(till: MultipleMachineSelected) {
    const isSelectedCurrent = !till.isSelected;
    till.isSelected = isSelectedCurrent;

    this.applyClients(till)
  }

  applyClients(till: MultipleMachineSelected) {
    let tillsChanged = this.tills();
    let tillChanged = tillsChanged.find((tillPos) => tillPos.id === till.id);
    tillChanged.isSelected = till.isSelected;

    this.tills.set(tillsChanged);
    this.setTillsSelected();

    this.checkEvent();
  }

  selectAll({
    masterCheck,
    clientId,
  }: {
    masterCheck: boolean;
    clientId: number;
  }) {
    setTimeout(() => {
      masterCheck = !masterCheck;

      this.tillsFilter = this.tillsFilter.map((till) => ({
        ...till,
        isSelected: masterCheck,
      }));

      let tills = this.tills();
      tills = tills.map((till) => {
        if (till.clientId === clientId) {
          till.isSelected = masterCheck;
        }

        return till;
      });

      this.tills.set(tills);
      this.setTillsSelected();

      this.start();
    }, 20);
  }

  checkEvent() {
    const totalItems = this.tillsFiltered.length;
    const checked = this.getTillFilteredChecked();

    if (checked > 0 && checked < totalItems) {
      this.isIndeterminate.set(true);
      this.masterCheck.set(false);
    } else if (checked == totalItems) {
      this.masterCheck.set(true);
      this.isIndeterminate.set(false);
    } else {
      this.isIndeterminate.set(false);
      this.masterCheck.set(false);
    }
  }

  getTillFilteredChecked() {
    return this.tillsFiltered.reduce(
      (prev, { isSelected }) => prev + (isSelected ? 1 : 0),
      0
    );
  }

  setTillsSelected(){
    let tillsSelected = this.tills().filter(till => till.isSelected);

    tillsSelected = tillsSelected.sort((a,b)=>
      ((a.code || '').toString()).localeCompare((b.code || '').toString())
    )
    this.tillsSelected.set(tillsSelected);
  }

}
