import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ModalController,
  ToastController,
  IonInfiniteScroll,
} from '@ionic/angular';
import { Subscription } from 'rxjs';

// Service
import { TranslateService } from '@ngx-translate/core';
import { FilterTillService } from '../../service/filter-till.service';
import { LanguageRouteService } from '../../../../services/languageRoute.ts/languagesRoute.services';
import { SkinMangementService } from 'src/app/services';

// Models
import { Till, Company } from '../../../../../models';

// Utils
import {
  presentToast,
  successToast,
  timeReload,
  type,
  UtilsTranslate,
} from '../../../../../utils';

@Component({
  selector: 'app-filter-till-modal',
  templateUrl: './filter-till-modal.component.html',
  styleUrls: ['./filter-till-modal.component.scss'],
})
export class FilterTillModalComponent implements OnInit {
  @Input() code?: number;
  @Input() useExternalTills: boolean = false;
  @Input() tillsFull: Till[];

  tills: Till[];
  tillsCode: Till[];

  // aspecto
  company: Company;
  type = type;

  tillSelected = null;

  loadingTill = true;

  // translates
  deleteTillFilterTranslate = '';

  changeLanguage$: Subscription;
  changeState$: Subscription;

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private toastCrl: ToastController,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _filterService: FilterTillService,
    private utils: UtilsTranslate
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    this.code = this._filterService.getMachineNumber();
    this.tillSelected = this._filterService.getTillid();

    this._filterService.startTills();

    if (this._filterService.getLoaded()) {
      this.start();

      if (this.code) {
        this.changeCode();
      }

      this.loadingTill = false;
    }

    this.changeState$ = this._filterService.changeState.subscribe(
      (newState) => {
        if (this._filterService.getLoaded()) {
          this.start();
          this.loadingTill = false;
        } else {
          this.loadingTill = true;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if (this.changeState$) this.changeState$.unsubscribe();
  }

  start() {
    if (!this.useExternalTills) {
      this.tillsFull = this._filterService.getTills();
    }

    this.tills = this.tillsFull;
    this.tillsCode = [];
    if (this.tills.length < 10 && this.tills.length > 0) {
      this.tillsCode.push(...this.tills.slice(0, this.tills.length));
    } else if (this.tills.length >= 10) {
      this.tillsCode.push(...this.tills.slice(0, 10));
    }
  }

  changeCode() {
    if (!this.code) {
      this.start();
    } else {
      this.tillsCode = [];
      this.tills = this.tillsFull.filter((clientFull) =>
        (clientFull?.code.toString() || '')
          .toLowerCase()
          .trim()
          .includes(this.code?.toString().toLowerCase().trim())
      );
      if (this.tills.length < 10) {
        this.tillsCode.push(...this.tills.slice(0, this.tills.length));
      } else {
        this.tillsCode.push(...this.tills.slice(0, 10));
      }
    }
  }

  filterMachineNumber() {
    let selectFilter: Till = this.tillsFull.find(
      (tillFull) => tillFull.id == this.tillSelected
    );
    if (selectFilter) {
      this._filterService.setMachine(selectFilter.code, selectFilter.id);
      if (this.modalCtrl) {
        this.modalCtrl.dismiss({
          code: selectFilter.code,
          id: selectFilter.id,
        });
      }
    } else {
      this.utils.showError()
    }
  }

  deleteMachineNumber() {
    this._filterService.setMachine(null, null);
    successToast(this.deleteTillFilterTranslate, this.toastCrl);
    if (this.modalCtrl) {
      this.modalCtrl.dismiss({
        delete: true,
      });
    }
  }

  closeModal() {
    if (this.modalCtrl) {
      this.modalCtrl.dismiss({});
    }
  }

  loadData(event) {
    setTimeout(() => {
      if (this.tillsCode.length >= this.tills.length) {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      // console.log('valores', this.clientNameFilter.length, 'total', this.clients.length, this.clients );

      let nuevoArr2 = [];
      if (this.tills.length < this.tillsCode.length + 10) {
        nuevoArr2 = this.tills.slice(this.tillsCode.length, this.tills.length);
      } else {
        nuevoArr2 = this.tills.slice(
          this.tillsCode.length,
          this.tillsCode.length + 10
        );
      }
      this.tillsCode.push(...nuevoArr2);

      this.inifiteScroll.complete();
    }, timeReload);
  }

  savePlaceholders() {
    this.translate
      .get(['transaction_query.carousel-filter.machine_deleted'])
      .subscribe((translates) => {
        this.deleteTillFilterTranslate =
          translates['transaction_query.carousel-filter.machine_deleted'];
      });
  }
}
