
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from '../../../../../../services/skin-mangement.service';
import { LanguageRouteService } from '../../../../../../share/services/languageRoute.ts/languagesRoute.services';
import { type } from '../../../../../../utils/constants';
import { Company } from 'src/app/models/';
import { ColorModeService } from 'src/app/services';

@Component({
  selector: 'app-legend-subsidies',
  templateUrl: './legend-subsidies.component.html',
  styleUrls: ['./legend-subsidies.component.scss'],
})
export class LegendSubsidiesComponent implements OnInit {

  // aspecto
  company: Company;
  type = type;

  isLightColor: boolean = true;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
    private _colorModeService: ColorModeService,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.isLightColor = this._colorModeService.getIsLightColor()
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
