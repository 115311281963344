// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { FooterSaveModalModule } from './footer-save-modal';
import { FooterUpdateDeleteModalModule } from './footer-update-delete-modal';
import { FooterUpdateModalModule } from './footer-update-modal/footer-update-modal.component.module';

// Service
import { LanguageService } from 'src/app/share/services';
import { SkinMangementService } from 'src/app/services';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FooterSaveModalModule,
    FooterUpdateDeleteModalModule,
    FooterUpdateModalModule,
  ],
  declarations: [],
  providers: [LanguageService, SkinMangementService],
  exports: [
    FooterSaveModalModule,
    FooterUpdateDeleteModalModule,
    FooterUpdateModalModule,
  ],
})
export class FooterShareModule {}
