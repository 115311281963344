import { WritableSignal } from '@angular/core';

// service
import { TranslateService } from '@ngx-translate/core';

// utils
import { getTomorrowDay } from 'src/app/utils';
import { ONLY_DAYS_MONTH_REPORT } from '../constants';

interface GetMonthsMonthlyReport {
  monthsMonthlyReport: WritableSignal<{ month: number; name: string }[]>;
  _translateService: TranslateService;
}

export const getMonthsMonthlyReport = async ({
  monthsMonthlyReport,
  _translateService,
}: GetMonthsMonthlyReport) => {
  const tomorrow = getTomorrowDay();

  const advancePosition = getAdvancePositionMonth(tomorrow);

  let monthsInfo: { month: number; name: string }[] = [];
  const monthTranslates: string[] = [
    'ion-time.january',
    'ion-time.february',
    'ion-time.march',
    'ion-time.april',
    'ion-time.may',
    'ion-time.june',
    'ion-time.july',
    'ion-time.august',
    'ion-time.september',
    'ion-time.october',
    'ion-time.november',
    'ion-time.december',
  ];

  for (let i in monthTranslates) {
    const name = await _translateService.instant(monthTranslates[i]);
    monthsInfo.push({ month: Number(i) + 1, name });
  }

  monthsMonthlyReport.set(changesMonthPosition(monthsInfo, advancePosition));
};

const getAdvancePositionMonth = (minDate: Date) => {
  const maxDay = Math.max(...ONLY_DAYS_MONTH_REPORT);
  if (minDate.getDate() > maxDay) {
    return minDate.getMonth() + 1;
  }

  return minDate.getMonth();
};

const changesMonthPosition = (
  monthsInfo: { month: number; name: string }[],
  advance: number
) => {
  let monthsWithAdvance: { month: number; name: string }[] = new Array(
    monthsInfo.length
  );

  for (let i in monthsInfo) {
    monthsWithAdvance[Number(i)] =
      monthsInfo[(Number(i) + advance) % monthsInfo.length];
  }

  return monthsWithAdvance;
};
