import { TranslateService } from '@ngx-translate/core';
import { PeriodicityInfo } from '../models';


export class Periodicity {

  periocities: PeriodicityInfo[] = [
    { Periodicity: 'Daily', Name: 'Daily', ID: 1 },
    { Periodicity: 'Weekly', Name: 'Weekly', ID: 2 },
    { Periodicity: 'Monthly', Name: 'Monthly', ID: 3 },
    { Periodicity: 'Yearly', Name: 'Yearly', ID: 4 },
  ];

  translatesNames = ['reports.daily', 'reports.weekly','reports.monthly', 'reports.yearly']

  constructor(private translate: TranslateService) {}

  getPeriocities(): PeriodicityInfo[]{
    return [
      {
        Periodicity: 'Daily',
        Name: this.translate.instant(this.translatesNames[0]),
        ID: 1,
      },
      {
        Periodicity: 'Weekly',
        Name: this.translate.instant(this.translatesNames[1]),
        ID: 2,
      },
      {
        Periodicity: 'Monthly',
        Name: this.translate.instant(this.translatesNames[2]),
        ID: 3,
      },
      {
        Periodicity: 'Yearly',
        Name: this.translate.instant(this.translatesNames[3]),
        ID: 4,
      },
    ];
  }
}
