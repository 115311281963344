// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-hour-margin {
  margin-left: 22%;
}

.bold {
  font-weight: bold;
}

.ml-1 {
  margin-left: 4px;
}

.center-menu {
  margin: 50px;
  margin: auto;
  width: 75%;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-menu/transaction-menu-filter/menu-filter-transactions.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,YAAA;EACA,YAAA;EACA,UAAA;AACJ","sourcesContent":[".ion-hour-margin{\r\n    margin-left: 22%;\r\n}\r\n\r\n.bold{\r\n    font-weight: bold;\r\n}\r\n\r\n.ml-1{\r\n    margin-left: 4px;\r\n}\r\n\r\n.center-menu{\r\n    margin: 50px;\r\n    margin: auto;\r\n    width: 75%;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
