import { TypeReportOption } from '../../models';

const PATH_NAME_TRANSLATE = 'reports.type_report.';
const PATH_DESCRIPTION_TRANSLATE = 'reports.type_report_info_description.';
export const PROCESS_DESCRIPTION_TYPE_REPORT_TRANSLATE =
  'reports.type_report_info_description.not_exist';

type TypeReportsTranslate =
  | TypeReportName
  | TypeReportNameByCard
  | TypeReportNameNotCardAndDescriptionCard;

interface TypeReportName {
  name: string;
  descriptions: string[];

  nameByCard?: {
    alliancePay: string;
    close_cloop_card: string;
  };
  descriptionsByCard?: {
    alliancePay: string[];
    close_cloop_card: string[];
  };
}

interface TypeReportNameByCard {
  nameByCard: {
    alliancePay: string;
    close_cloop_card: string;
  };
  descriptionsByCard: {
    alliancePay: string[];
    close_cloop_card: string[];
  };

  name?: string;
  description?: string[];
}

interface TypeReportNameNotCardAndDescriptionCard {
  name: string;
  descriptionsByCard: {
    alliancePay: string[];
    close_cloop_card: string[];
  };

  description?: string[];
  nameByCard?: {
    alliancePay: string;
    close_cloop_card: string;
  };
}

export const TYPE_REPORTS_INFO_TRANSLATE: Record<
  TypeReportOption,
  TypeReportsTranslate
> = {
  [TypeReportOption['Billiing Report Detail: Monthly']]: {
    name: `${PATH_NAME_TRANSLATE}billing_report_detail_monthly`,
    descriptionsByCard: {
      alliancePay: [
        `${PATH_DESCRIPTION_TRANSLATE}billing_report_detail_monthly_1`,
        `${PATH_DESCRIPTION_TRANSLATE}billing_report_detail_monthly_2_alliance`,
      ],
      close_cloop_card: [
        `${PATH_DESCRIPTION_TRANSLATE}billing_report_detail_monthly_1`,
        `${PATH_DESCRIPTION_TRANSLATE}billing_report_detail_monthly_2_close_loop_card`,
      ],
    },
  },
  [TypeReportOption['Listado Qr diario']]: {
    name: `${PATH_NAME_TRANSLATE}daily_qr_list`,
    descriptions: [
      `${PATH_DESCRIPTION_TRANSLATE}daily_qr_list_1`
    ],
  },
  [TypeReportOption['Listado Qr mensual']]: {
    name: `${PATH_NAME_TRANSLATE}montly_qr_list`,
    descriptions: [
      `${PATH_DESCRIPTION_TRANSLATE}montly_qr_list_1`
    ],
  },
  [TypeReportOption['Listado de Bonificaciones']]: {
    name: `${PATH_NAME_TRANSLATE}list_subsidies`,
    descriptionsByCard: {
      alliancePay: [
        `${PATH_DESCRIPTION_TRANSLATE}list_subsidies_1`,
        `${PATH_DESCRIPTION_TRANSLATE}list_subsidies_2_alliance`,
      ],
      close_cloop_card: [
        `${PATH_DESCRIPTION_TRANSLATE}list_subsidies_1`,
        `${PATH_DESCRIPTION_TRANSLATE}list_subsidies_2_close_loop_card`,
      ],
    },
  },
  [TypeReportOption['Listado de Ventas (M)']]: {
    name: `${PATH_NAME_TRANSLATE}sales_list_m`,
    descriptions: [
      `${PATH_DESCRIPTION_TRANSLATE}sales_list_m_1`,
      `${PATH_DESCRIPTION_TRANSLATE}sales_list_m_2`
    ],
  },
  [TypeReportOption['Listado Categoria 2']]: {
    name: `${PATH_NAME_TRANSLATE}list_category_2`,
    descriptionsByCard: {
      alliancePay: [
        `${PATH_DESCRIPTION_TRANSLATE}list_category_2_1`,
        `${PATH_DESCRIPTION_TRANSLATE}list_category_2_2_alliance`,
      ],
      close_cloop_card: [
        `${PATH_DESCRIPTION_TRANSLATE}list_category_2_1`,
        `${PATH_DESCRIPTION_TRANSLATE}list_category_2_2_close_loop_card`,
      ],
    },
  },
  [TypeReportOption['Listado Tarjeta Bancaria (D)']]: {
    name: `${PATH_NAME_TRANSLATE}bank_card_list_d`,
    descriptions: [
      `${PATH_DESCRIPTION_TRANSLATE}bank_card_list_d_1`,
      `${PATH_DESCRIPTION_TRANSLATE}bank_card_list_d_2`,
      `${PATH_DESCRIPTION_TRANSLATE}bank_card_list_d_3`,
    ],
  },
  [TypeReportOption['Listado Tarjeta Bancaria (M)']]: {
    name: `${PATH_NAME_TRANSLATE}bank_card_list_m`,
    descriptions: [
      `${PATH_DESCRIPTION_TRANSLATE}bank_card_list_m_1`,
      `${PATH_DESCRIPTION_TRANSLATE}bank_card_list_m_2`,
    ],
  },
  [TypeReportOption['Listado de Ventas (W)']]: {
    name: `${PATH_NAME_TRANSLATE}sales_list_w`,
    descriptionsByCard: {
      alliancePay: [
        `${PATH_DESCRIPTION_TRANSLATE}sales_list_w_1`,
        `${PATH_DESCRIPTION_TRANSLATE}sales_list_w_2_alliance`,
      ],
      close_cloop_card: [
        `${PATH_DESCRIPTION_TRANSLATE}sales_list_w_1`,
        `${PATH_DESCRIPTION_TRANSLATE}sales_list_w_2_close_loop_card`,
      ],
    },
  },
  [TypeReportOption['Listado de Ventas con tarjeta Cautiva']]: {
    nameByCard: {
      alliancePay: `${PATH_NAME_TRANSLATE}alliance_sales_list`,
      close_cloop_card: `${PATH_NAME_TRANSLATE}close_loop_card_sales_list`,
    },
    descriptionsByCard: {
      alliancePay: [
        `${PATH_DESCRIPTION_TRANSLATE}alliance_sales_list_1`,
        `${PATH_DESCRIPTION_TRANSLATE}alliance_sales_list_2`,
      ],
      close_cloop_card: [
        `${PATH_DESCRIPTION_TRANSLATE}close_loop_card_sales_list_1`,
        `${PATH_DESCRIPTION_TRANSLATE}close_loop_card_sales_list_2`,
      ],
    },
  },
  [TypeReportOption['Listado FreeVend']]: {
    name: `${PATH_NAME_TRANSLATE}ifree_vend_listing`,
    descriptions: [
      `${PATH_DESCRIPTION_TRANSLATE}ifree_vend_listing_1`,
      `${PATH_DESCRIPTION_TRANSLATE}ifree_vend_listing_2`
    ],
  },
};
