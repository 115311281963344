export interface QRRoutesTranslates {
  generateQr: string,
  checkQr: string
}

export const getQRRoutes = (
  translates: QRRoutesTranslates
): { route: string; name: string }[] => {
  return [
    { route: '/user/generate-qr', name: translates.generateQr },
    { route: '/user/check-qr', name: translates.checkQr },
  ]
}
