import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

// models
import { Company } from 'src/app/models';
import { HaveVisibility } from '../../../../models';
import { LoadingVisibilityClientTillSelect } from '../models/loading-visibility-client-till-select.model';

// Service
import { FilterSubsidiesService } from 'src/app/pages/user/cards/subsidies/services/filter-subsidies.service';

// Utils
import { pcWidth } from '../../../../utils/constants';

@Component({
  selector: 'app-visibility-client-till-select-pc',
  templateUrl: './visibility-client-till-select-pc.component.html',
  styleUrls: ['./visibility-client-till-select-pc.component.scss'],
})
export class VisibilityClientTillSelectPcComponent implements OnInit {

  @Input() company: Company;
  @Input() haveClearButton: boolean = true;

  @Input() showClient: boolean = false;
  @Input() showTill: boolean = false;
  @Input() haveVisibility: HaveVisibility = { client: false, operator: false, operatorGroup: false }

  @Input() loadings: LoadingVisibilityClientTillSelect = {
    loadingClient: true, loadingTill: true, loadingMore1Client: true, loadingMore1Till: true
  };
  @Input() nameClient: string = '';
  @Input() codeMachine: number = null;

  @Input() haveMore1Client: boolean = false;
  @Input() haveMore1Till: boolean = false;

  @Input() pcWidth = pcWidth;

  @Output() showClientTillModal = new EventEmitter()
  @Output() clearHandle = new EventEmitter();

  filterChange$: Subscription;

  constructor(
   private _filterService: FilterSubsidiesService,
  ) { }

  ngOnInit() {
    this.filterChange$ = this._filterService.filterChange.subscribe(res => {
      if (res.reset){
        this.nameClient = ''
        this.codeMachine = null
      }
    })
  }

  ngOnDestroy(): void {
    if(this.filterChange$) this.filterChange$.unsubscribe()
  }


  showClientTillFilter(){
    this.showClientTillModal.emit()
  }

  clearFilter() {
    this.clearHandle.emit()
  }


}
