// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-indent {
  text-indent: 3%;
}

.margin-text {
  margin-left: 5px;
  margin-right: 5px;
}

.text-size-total {
  font-size: 1em;
}

.total-title {
  font-size: 1.2em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/card-activity/components/legend-card-activity/legend-card-activity.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".text-indent {\r\n  text-indent: 3%;\r\n}\r\n\r\n.margin-text{\r\n  margin-left: 5px;\r\n  margin-right: 5px;\r\n}\r\n\r\n.text-size-total{\r\n  font-size: 1em;\r\n}\r\n\r\n.total-title{\r\n  font-size: 1.2em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
