// models
import { Till } from 'src/app/models';

// services
import { FilterTransactionsQueryService } from '../../services';

// utils
import { isValidTillSelectedTransactionQuery } from './';

interface CheckClientFilteredChangeOperator {
  _filterService: FilterTransactionsQueryService;
  tills: Till[];
}

export const checkTillFilteredTransactionQuery = ({
  _filterService,
  tills,
}: CheckClientFilteredChangeOperator) => {
  const isValidTill = isValidTillSelectedTransactionQuery({
    operatorIds: _filterService.getOperatorIds(),
    clientId: _filterService.getClientId(),
    codeMachine: _filterService.getMachineNumber(),
    codeStartMachine: _filterService.getCodeStartMachine(),
    tills: tills,
  });

  if (!isValidTill) {
    if (_filterService.getMachineNumber()) _filterService.setMachine('');
    if (_filterService.getCodeStartMachine())
      _filterService.setCodeStartMachine('');
  }
};
