// models
import { AlertSpiderMachineStatus, TypeAlertMachineStatus } from '../../models';

export const getTypeAlertSelectedMachineStatus = (
  typeAlerts: TypeAlertMachineStatus[],
  alertSelected: AlertSpiderMachineStatus
) => {
  const { StatusTillAlertID: statusTillAlertId } = alertSelected;

  const typeAlertSelected = typeAlerts.find(
    (typeAlert) => typeAlert.id === statusTillAlertId
  );

  if (typeAlertSelected) {
    return typeAlertSelected;
  }

  return typeAlerts?.[0];
};
