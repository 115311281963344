// models
import { DashboardWelcomeIntervalParams, SelectDateValids } from '../../models';

// utils
import { getDaysInMonth } from '../';
import { addTimeDate, getShortDate } from 'src/app/utils';

// ------------------------ interfaces ------------------------ //
interface GetParamsDashboardDays {
  typeDate: SelectDateValids;
  dateSelected: Date;
  clientId: number;
  tillCode: number
}

interface GetParamsDashboarDaysByMonth {
  dateSelected: Date,
  clientId: number,
  tillCode: number
}


// ------------------------ functions ------------------------ //
export const getParamsDashboardDays = ({
  typeDate,
  dateSelected,
  clientId,
  tillCode
}: GetParamsDashboardDays) => {

  if(typeof dateSelected === 'string'){
    dateSelected = new Date(dateSelected)
  }

  if (typeDate === 'monthly') {
    return getParamsDashboarDaysByMonth({dateSelected, clientId, tillCode});
  }

  if (typeDate === 'weekly') {
    return getParamsDashboarDaysByWeek(dateSelected, clientId);
  }

  return {
    clientID: clientId,
    ShortDateStart: null,
    ShortDateEnd: null,
    StartDay: null,
    EndDay: null,
    tillCode: null
  };
};

const getParamsDashboarDaysByMonth = ({dateSelected, clientId, tillCode}: GetParamsDashboarDaysByMonth) => {

  const daysInMonth = getDaysInMonth(
    dateSelected.getMonth() + 1,
    dateSelected.getFullYear()
  );

  const dateEnd = addTimeDate({
    startDate: dateSelected,
    increment: daysInMonth - 1,
    typeIncrement: 'days',
  });

  let params: DashboardWelcomeIntervalParams = {
    clientID: clientId,
    ShortDateStart: getShortDate(dateSelected),
    ShortDateEnd: getShortDate(dateEnd),
    StartDay: dateSelected.getDate(),
    EndDay: dateEnd.getDate(),
  };

  return params;
};

const getParamsDashboarDaysByWeek = (
  dateSelected: Date,
  clientId: number
) => {

  if(typeof dateSelected === 'string'){
    dateSelected = new Date()
  }

  const dateEnd = addTimeDate({
    startDate: dateSelected,
    increment: 6,
    typeIncrement: 'days',
  });

  return {
    clientID: clientId,
    ShortDateStart: getShortDate(dateSelected),
    ShortDateEnd: getShortDate(dateEnd),
    StartDay: dateSelected.getDate(),
    EndDay: dateEnd.getDate(),
  };
};
