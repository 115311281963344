export interface ClickCollectRoutesTranslates {
  clickCollect: string
}

export const getClickCollectRoutes = (
  translates: ClickCollectRoutesTranslates
): { route: string; name: string }[] => {
  return [
    { route: '/user/click-collect-query', name: translates.clickCollect },
  ]
}
