import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

// Services
import { SkinMangementService } from '../../../../../../services/skin-mangement.service';

// Models
import { Company } from 'src/app/models/';
import { CardClient } from '../../../../../services/register-cards/register-cards.service';

// Utils
import { pcWidth, pcWidthMix } from '../../../../../../utils';

@Component({
  selector: 'app-assign-card-modal',
  templateUrl: './assign-card-modal.component.html',
  styleUrls: ['./assign-card-modal.component.scss'],
})
export class AssignCardModalComponent implements OnInit {

  company: Company;

  @Input() cardsWithClients: CardClient[] = []
  @Input() notExistCards: string[] = []

  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
    public readonly platform: Platform
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  changeClient(){
    this.modalCtrl.dismiss({
      changeClient: true
    });
  }

}
