import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

//Config
import { AppConfig } from 'src/app/config/config';

// Services
import { NotificationShareService } from '../../notification-share/services/notification-share.service';

// models
import {
  ImageIM,
  ParamsCheckImageIM,
  UpdateImageIM,
  ParamsUpdateImage,
  DeviceType,
  ParamsUploadImage
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class ImageManagerService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private _notificationShareService: NotificationShareService
    ) {}

  getTypeImages() {
    return this._notificationShareService.getImageTypes()
  }

  getTypeDevices() {
    let endpoint = 'api/getTypeDevices';
    let routeUrl = this.router.url;

    return this.httpClient
      .get<DeviceType[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          res = res.filter(
            (r) => r.Descripcion === 'Android' || r.Descripcion === 'IOS'
          );
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getListImage(imagetypeID: number, deviceTypeID: number) {
    let endpoint = 'getListImages';
    let routeUrl = this.router.url;

    const params: { ImagetypeID: number; deviceTypeID: number } = {
      ImagetypeID: imagetypeID,
      deviceTypeID: deviceTypeID,
    };

    return this.httpClient
      .post<ImageIM[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  updateImage( params: ParamsUpdateImage) {
    let endpoint = 'updateImage';
    let routeUrl = this.router.url;

    return this.httpClient
      .post<null>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  uploadImage(params: ParamsUploadImage){

    let endpoint = `uploadImage/${params?.imageTypeID}/${params?.deviceTypeID}/${params?.file?.name}`;
    let routeUrl = this.router.url;

    const formData: FormData = new FormData();
    formData.append('fileKey', params?.file, params?.file?.name);


    return this.httpClient
      .post<UpdateImageIM>(this.serviceUrl + endpoint + `?route=${routeUrl}`, formData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  deleteImage(imageID: number){
    let endpoint = `deleteImage`;
    let routeUrl = this.router.url;

    const params: {imageID: number} = {imageID}

    return this.httpClient
    .post<null>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
    .pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        let messageError = !error?.error?.error?.message
          ? '504 Gateway timeout'
          : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  getImage(imageID: number){
    let endpoint = `/api/GetImage/${imageID}`;
    let routeUrl = this.router.url;

    return this.httpClient
    .get(this.serviceUrl + endpoint + `?route=${routeUrl}`, { responseType: 'blob' })
    .pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        let messageError = !error?.error?.error?.message
          ? '504 Gateway timeout'
          : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  getURLImage(){
    return this._notificationShareService.getURLImage()
  }

  checkImage(params: ParamsCheckImageIM){
    return this._notificationShareService.checkImage(params);
  }


  getAPI(routeURL: string = null, group: string = null){
    return this._notificationShareService.getAPI(routeURL, group);
  }



}
