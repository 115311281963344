import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-permissions-skeleton',
  templateUrl: './carousel-permissions-skeleton.component.html',
  styleUrls: ['./carousel-permissions-skeleton.component.scss'],
})
export class CarouselPermissionsSkeletonComponent implements OnInit {


  @Input() positions: number = 8;
  positionList = [];


  constructor() {}

  ngOnInit() {
    this.positionList = new Array(this.positions);
  }

}
