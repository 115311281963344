import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map, timeout } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Config
import { AppConfig } from '../../../config/config';

// Service
import { PermissionService } from '../../../services';

// Models
import { transactionSendEmailParams, PermissionsTransactionsQueryNames } from '../../../pages/user/transactions/transactions-query/models/';

// Utils
import { TIME_OUT_DOWNLOAD } from '../../../utils';


@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  private serviceUrl = AppConfig.RTVnodeUrl;

  timeOutDownload = TIME_OUT_DOWNLOAD;

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private _permissionService: PermissionService
  ) { }

  getTickets(inputsParam: number, routeName = PermissionsTransactionsQueryNames.ticket){

    let endpoint = `tickets/${inputsParam}`;
    let routeUrl = this.router.url;
    endpoint = this.serviceUrl + endpoint + `?route=${routeUrl}`;
    let {permissionTicket} = this._permissionService.getTransactionPermissions();
    if(permissionTicket) endpoint += `&&name=${routeName}`

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    return this.httpClient.get(endpoint, { headers: headers, responseType: 'blob'}).pipe(
      map(res => {
        return res;
      }),
      timeout(this.timeOutDownload),
      catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );

  }

  sendEmail(params: transactionSendEmailParams){
    let endpoint = `/tickets/sendmail`;
    let routeUrl = this.router.url;
    endpoint = this.serviceUrl + endpoint + `?route=${routeUrl}`;
    let {permissionTicket} = this._permissionService.getTransactionPermissions();
    if(permissionTicket){
        endpoint += `&&name=${'Consulta General con ticket'}`
    }

    return this.httpClient.post<sendValid>( endpoint, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

}

interface sendValid{
  send: boolean
}
