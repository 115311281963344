import { Injectable } from '@angular/core';


// utils
import { UtilsTranslate, clientesavDomain } from '../../utils';

@Injectable({
  providedIn: 'root'
})
export class IsClientesavGuard  {

  constructor(private utils: UtilsTranslate){}

  canActivate(){
    if(!clientesavDomain()){
      this.utils.presentToastTranslate('invalid_route', true, 0)
      return false;
    }

    return true
  }

}
