import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

// config
import { AppConfig } from 'src/app/config/config';

// models
import { ParamsSupplantLogin } from '../models/params-supplant-login.model';

import { AuthLogin } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class AdminSupplantService {

  private serviceUrl = AppConfig.RTVnodeUrl;


  constructor(private httpClient: HttpClient, private platform: Platform) { }

  loginSupplant(params: ParamsSupplantLogin){
    let endpoint = `admin/supplant-login`;
    params.OS = this.platform.platforms().join(',').substring(0,19);
    return this.httpClient.post<AuthLogin>(this.serviceUrl + endpoint, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  isValidSuplantRoute(): Observable<boolean>{
    let endpoint = `admin/exist-super-sys-admin`;
    return this.httpClient.get<boolean>(this.serviceUrl + endpoint).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }
}
