// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 601px) and (max-width: 5000px) {
  ion-content {
    border-radius: 12px;
  }
  ion-modal {
    --border-radius: 8px!important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/idle/idle-modal/idle-modal.component.scss"],"names":[],"mappings":"AAAA;EACG;IACK,mBAAA;EACN;EACE;IACE,8BAAA;EACJ;AACF","sourcesContent":["@media (min-width: 601px) and (max-width: 5000px) {\r\n   ion-content{\r\n        border-radius: 12px;\r\n    }\r\n    ion-modal {\r\n      --border-radius: 8px!important;\r\n    }\r\n  }\r\n\r\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
