import { WritableSignal } from '@angular/core';

// models
import { RouteEditable } from '../../../models';

// utils
import { UtilsTranslate } from 'src/app/utils';
import { isValidRouteNumberRM } from './is-valid-route-number-rm';

interface IsValidCreateRouteManager {
  routeEditableCreate: WritableSignal<RouteEditable>;
  haveOperatorVisibility: boolean;
  utils: UtilsTranslate;
}

export const isValidCreateRouteManager = ({
  routeEditableCreate,
  haveOperatorVisibility,
  utils,
}: IsValidCreateRouteManager) => {
  if (
    !haveOperatorVisibility &&
    !routeEditableCreate().operatorId &&
    routeEditableCreate().operatorId !== 0
  ) {
    utils.presentToastTranslate(
      'route_manager.create_edit.error.operator_required'
    );
    return false;
  }

  if (
    !routeEditableCreate()?.routeNumber &&
    routeEditableCreate()?.routeNumber !== 0
  ) {
    utils.presentToastTranslate(
      'route_manager.create_edit.error.route_number_required'
    );
    return false;
  }

  if (!isValidRouteNumberRM(routeEditableCreate()?.routeNumber)) {
    utils.presentToastTranslate(
      'route_manager.create_edit.error.route_number_not_valid'
    );
    return false;
  }

  if (
    !routeEditableCreate()?.holderRouteDriver ||
    (!routeEditableCreate()?.holderRouteDriver?.routeDriverId &&
      routeEditableCreate()?.holderRouteDriver?.routeDriverId !== 0)
  ) {
    utils.presentToastTranslate(
      'route_manager.create_edit.error.holder_stocker_required'
    );
    return false;
  }

  return true;
};
