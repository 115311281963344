import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { Subscription, Observable } from 'rxjs';

// material phone
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

// services
import { ColorModeService } from '../../../services/color-mode.service';
import { SkinMangementService } from '../../../services/skin-mangement.service';

// models
import { Company } from 'src/app/models';
import {
  AppearancePhoneTemplate,
  PhoneInputCountry,
  PhoneInputAllEntries,
  PhonePrefixValid,
  PhoneTemplateEvents,
  PhoneTypeEvents,
} from './models';

// utils
import { getPhone, getPhoneAndPrefix, getPhoneStart } from 'src/app/utils';
import {
  PHONE_TABLE_STYLE_VISIBILITY_PLACEHOLDER,
  PHONE_TABLE_SYTLE_VISIBILITY,
  PLACEHOLDER_PHONE_TEMPLATE,
  PREFERRED_COUNTRIES_DEFAULT,
} from './utils/constant';

@Component({
  selector: 'app-phone-input-template',
  templateUrl: './phone-input-template.component.html',
  styleUrls: ['./phone-input-template.component.scss'],
})
export class PhoneInputTemplateComponent implements OnInit {
  @Input() phoneNumber: string = '';
  @Input() prefix: string = '';

  @Input() appearance: AppearancePhoneTemplate = 'fill';
  @Input() isEditable = true;
  @Input() isRequired: boolean = true;
  @Input() showError: boolean = true;
  @Input() showPlaceholder: boolean = true;
  @Input() extraClass: string = '';

  @Input() placeholderText = PLACEHOLDER_PHONE_TEMPLATE;

  @Input() eventsPhone: Observable<PhoneTemplateEvents>;
  private eventsPhone$: Subscription;

  @Input() idPhone = '';

  @Output() changePhone = new EventEmitter<PhonePrefixValid>();

  @ViewChild('phone', { static: false })
  phoneInput: NgxMatIntlTelInputComponent;

  company: Company;

  preferredCountries = PREFERRED_COUNTRIES_DEFAULT;

  phoneForm: FormGroup;
  phoneEntries: PhoneInputAllEntries = {
    phoneInputCountry: {
      dialCode: '',
      flagClass: '',
      iso2: '',
      name: '',
      priority: null,
    },
    phoneValid: '',
    phoneParcial: '',
    isValid: false,
  };

  formClass: string = '';

  eventPhone: Record<PhoneTypeEvents, Function>;

  isColorLight: boolean = true;

  PHONE_TABLE_STYLE_VISIBILITY = PHONE_TABLE_SYTLE_VISIBILITY;
  PHONE_TABLE_STYLE_VISIBILITY_PLACEHOLDER = PHONE_TABLE_STYLE_VISIBILITY_PLACEHOLDER;

  changeColor$: Subscription;
  changePhone$: Subscription;

  constructor(
    private elem: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _skinMangementService: SkinMangementService,
    private _colorModeService: ColorModeService
  ) {}

  ngOnInit() {
    this.getColorCompany();

    this.getEventsFunction();

    this.startPhone();

    this.getColors();

    this.getPhone();

    this.getEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (
      !this.isEditable &&
      this.extraClass.includes(this.PHONE_TABLE_STYLE_VISIBILITY) &&
      !this.extraClass.includes(this.PHONE_TABLE_STYLE_VISIBILITY_PLACEHOLDER)
      && this.company?.useDifferentColorPlaceholderTable
    ) {
      this.extraClass =
        this.extraClass + ' ' + this.PHONE_TABLE_STYLE_VISIBILITY_PLACEHOLDER;
    }
  }

  ngOnDestroy() {
    if (this.changeColor$) {
      this.changeColor$.unsubscribe();
    }
    if (this.changePhone$) {
      this.changePhone$.unsubscribe();
    }

    if (this.eventsPhone$) {
      this.eventsPhone$.unsubscribe();
    }
  }

  startPhone() {
    if (this.phoneNumber) {
      const { preferedCountries, valid } = getPhoneStart(
        this.phoneNumber,
        this.prefix
      );

      this.preferredCountries = preferedCountries;

      setTimeout(() => {
        this.phoneEntries = {
          phoneInputCountry: {
            dialCode: '',
            flagClass: '',
            iso2: '',
            name: '',
            priority: null,
          },
          phoneValid: '',
          phoneParcial: this.phoneNumber,
          isValid: valid,
        };
      }, 50);
    }

    this.phoneForm = this._fb.group({
      phone: new FormControl(
        this.phoneNumber,
        this.isRequired ? [Validators.required] : []
      ),
    });

    if (!this.isEditable) {
      this.phoneForm.get('phone')?.disable();
    }
  }

  getColors() {
    this.isColorLight = this._colorModeService.getIsLightColor();
    this.getClassForm();

    this.changeColor$ = this._colorModeService.changeColor$.subscribe(() => {
      this.isColorLight = this._colorModeService.getIsLightColor();
      this.getClassForm();
    });
  }

  getPhone() {
    this.changePhone$ = this.phoneForm
      .get('phone')
      .valueChanges.subscribe((phone) => {
        // console.log('phoneInput', this.phoneInput)
        this.phoneEntries.phoneParcial = this.phoneInput?.phoneNumber || '';
        this.phoneEntries.phoneValid = phone || '';
        this.phoneEntries.isValid = false;

        // console.log('phoneParcial 2', this.phoneEntries)

        if (phone) {
          const phoneValidate = getPhone(this.phoneEntries.phoneValid);
          if (phoneValidate) {
            this.phoneEntries.isValid = phoneValidate?.isValid;
          }
        }

        const phonePrefix: PhonePrefixValid = getPhoneAndPrefix(
          this.phoneEntries
        );

        this.changePhone.emit(phonePrefix);

        // phone
        // console.log('value', this.phoneEntries);
      });
  }

  changeCountry(ev: PhoneInputCountry) {
    this.phoneEntries.phoneInputCountry = ev;
  }

  getEventsFunction() {
    this.eventPhone = {
      change: (res: PhoneTemplateEvents) => this.changePhoneEv(res),
      remove: (res: PhoneTemplateEvents) => this.removePhoneEv(res),
    };
  }

  getClassForm() {
    if (!this.isColorLight && !this.isEditable) {
      this.formClass = 'dark-no-editable no-arrow-select';
      return;
    }

    this.formClass = '';
    if (!this.isColorLight) {
      this.formClass = 'dark-mode';
    }
    if (!this.isEditable) {
      this.formClass += ' no-arrow-select';
    }
  }

  getEvents() {
    if (this.eventsPhone) {
      this.eventsPhone$ = this.eventsPhone.subscribe((res) => {
        if (res.idPhone !== this.idPhone) {
          return;
        }

        if (this.eventPhone?.[res?.type]) {
          this.eventPhone[res.type](res);
        }
      });
    }
  }

  changePhoneEv(res: PhoneTemplateEvents) {
    if (res?.type === 'change') {
      const { preferedCountries, valid, phoneNumber, prefix } = getPhoneStart(
        res.phoneNumber,
        res.prefix
      );

      this.preferredCountries = preferedCountries;

      this.phoneEntries = {
        phoneInputCountry: {
          dialCode: prefix || '',
          flagClass: '',
          iso2: '',
          name: '',
          priority: null,
        },
        phoneValid: '',
        phoneParcial: phoneNumber,
        isValid: valid,
      };

      if (phoneNumber) {
        // this.phoneForm.get('phone').setValue(res.phoneNumber)
        this.phoneForm.controls.phone.setValue(`+${prefix}${phoneNumber}`);
        // this.phoneInput.phoneNumber = res.phoneNumber
        //console.log('valid', this.phoneForm.get('phone').valid)
        this.changeDetectorRef.detectChanges();
        // this.phoneInput.phoneNumber = res.phoneNumber
      } else {
        this.removePhone();
      }
    }
  }

  removePhoneEv(res: PhoneTemplateEvents) {
    if (res.type !== 'remove') {
      return;
    }

    this.phoneEntries = {
      phoneInputCountry: {
        dialCode: '',
        flagClass: '',
        iso2: '',
        name: '',
        priority: null,
      },
      phoneValid: '',
      phoneParcial: '',
      isValid: false,
    };

    this.removePhone();

    this.preferredCountries = PREFERRED_COUNTRIES_DEFAULT;
  }

  removePhone() {
    if (this.phoneForm) {
      this.phoneForm.reset();
    }
    if (this.phoneInput) {
      this.phoneInput.reset();
    }
  }

  getColorCompany() {
    this.company = this._skinMangementService.getCompany();

    this.elem.nativeElement.style.setProperty(
      '--color-table-visibility-bg',
      this.company.colorTableVisibilityBg
    );

    this.elem.nativeElement.style.setProperty(
      '--color-table-visibility-placeholder',
      this.company.colorTableVisibilityPlaceholder
    );
  }

  // ngAfterViewInit(): void {
  //   setTimeout(()=>{
  //     this.phoneInput.elRef.nativeElement.firstChild.children[0].disabled = 'true';
  //     console.log('entre',    this.phoneInput.elRef.nativeElement)
  //   },200)

  // }
}
