import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { PermissionService } from 'src/app/services';

// Utils
import {
  NAME_HIGH_LEVEL_ONLY_EDIT_TILL_MANAGER_ROUTE,
  NAME_HIGH_LEVEL_TILL_ROUTE,
  ROUTE_TILL_MANAGER,
} from '../utils/constant';

@Injectable({ providedIn: 'root' })
export class TillManagerResolverService {
  constructor(private _permissionService: PermissionService) {}

  resolve(): Observable<{haveHighLevel: boolean, isOnlyEdit: boolean}> {
    return this._permissionService.getAllPermissions().pipe(
      map((res) => {

        const routesHighLevel = [NAME_HIGH_LEVEL_TILL_ROUTE, NAME_HIGH_LEVEL_ONLY_EDIT_TILL_MANAGER_ROUTE];

        const haveHighLevel = res.find(
          (permission) =>
            permission.route === ROUTE_TILL_MANAGER &&
            routesHighLevel.includes(permission.name)
        );
        const isOnlyEdit = res.find(
          (permission) =>
            permission.route === ROUTE_TILL_MANAGER &&
            permission.name === NAME_HIGH_LEVEL_ONLY_EDIT_TILL_MANAGER_ROUTE
        );

        return {haveHighLevel: !!haveHighLevel, isOnlyEdit: !!isOnlyEdit};
      })
    );
  }
}
