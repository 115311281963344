import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { Company } from 'src/app/models';
import { pcWidth } from 'src/app/utils';

@Component({
  selector: 'app-double-label-visibility',
  templateUrl: './double-label-visibility.component.html',
  styleUrls: ['./double-label-visibility.component.scss'],
})
export class DoubleLabelVisibilityComponent implements OnInit {

  @Input() company: Company;
  @Input() textType: string = 'Description';
  @Input() description: string = '';

  pcWidth = pcWidth;

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}

}
