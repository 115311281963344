// models
import { IconSite, ImageSite } from '../../site/models';
import { ActivitiesTill } from '../models';

// utils
import { ColorModeValids, iconsSite, imagesSite } from 'src/app/utils';

const COLD_DRINK = ActivitiesTill.coldDrink.toLowerCase();
const HOT_DRINK = ActivitiesTill.hotDrink.toLowerCase();
const MIXED = ActivitiesTill.mixed.toLowerCase();
const SNACKS = ActivitiesTill.snacks.toLowerCase();

export const imagesSiteElora: { [x: string]: ImageSite } = {
  [COLD_DRINK]: imagesSite[0],
  [HOT_DRINK]: imagesSite[1],
  [SNACKS]: imagesSite[2],
  [MIXED]: imagesSite[3],
};

export const imageSiteEloraDefault: ImageSite = imagesSite[4];

export type SiteEloraStatusValids =
  | 'online'
  | 'disconnected'
  | 'operating'
  | 'failure'
  | 'locked'
  | 'default';

export const SiteEloraStatus: Record<SiteEloraStatusValids, string[]> = {
  online: ['online', 'connected'],
  disconnected: ['disconnected'],
  operating: ['operating', 'regular'],
  failure: ['failure'],
  locked: ['locked', 'blocked'],
  default: [],
};

export const SiteEloraStatusPos: Record<SiteEloraStatusValids, IconSite> = {
  online: iconsSite[1],
  disconnected: iconsSite[2],
  operating: iconsSite[3],
  failure: iconsSite[4],
  locked: iconsSite[5],
  default: iconsSite[0],
};

export const MAX_CHANNELS_SITE_ELORA: number = 3;
export const MAX_ARTICLES_SITE_ELORA: number = 3;

export const IMAGES_BROKEN_BOOTLE: Record<ColorModeValids, string> = {
  light: '../../../../../../../../assets/images/site_elora/broken_bottle_light.png',
  dark: '../../../../../../../../assets/images/site_elora/broken_bottle.png'
}

export const SEPARATOR_KEY_LIST_NOT_CAPACITY = ',';

export const ITEM_PER_PAGE_MOBILE_SITE_ELORA = 50;

// ------------------------------------ Permissions ------------------------------------
export const NAME_CHANNEL_SITE_ELORA = 'Propuesta de carga canal'
export const NAME_ARTICLE_SITE_ELORA: string = 'Propuesta de carga producto';
export const NAME_ARTICLE_HEADLINES_SITE_ELORA: string = 'Propuesta de carga producto titulares';
export const NAME_HIGH_LEVEL_CHANNEL_SITE_ELORA: string = 'High: Propuesta de carga canal';
export const NAME_HIGHT_LEVEL_ARTICLE_SITE_ELORA: string = 'High: Propuesta de carga producto';
export const NAME_HIGHT_LEVEL_ARTICLE_HEADLINES_SITE_ELORA: string = 'High: Propuesta de carga producto titulares';
export const ROUTE_SITE_ELORA: string = '/user/site-elora';

export const NAMES_HIGH_LEVEL_SITE_ELORA: string[] = [
  NAME_HIGH_LEVEL_CHANNEL_SITE_ELORA,
  NAME_HIGHT_LEVEL_ARTICLE_SITE_ELORA,
  NAME_HIGHT_LEVEL_ARTICLE_HEADLINES_SITE_ELORA
]

export const NAMES_ARTICLE_SITE_ELORA: string[] = [
  NAME_ARTICLE_SITE_ELORA,
  NAME_HIGHT_LEVEL_ARTICLE_SITE_ELORA,
  NAME_ARTICLE_HEADLINES_SITE_ELORA,
  NAME_HIGHT_LEVEL_ARTICLE_HEADLINES_SITE_ELORA
]


export const TIME_WAIT_TOOLTIP_SITE_ELORA = 10000;
