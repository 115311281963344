import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';


// Models
import { Company } from '../../../../models/';

// Utils
import { UtilsTranslate, timeReload } from '../../../../utils';

@Component({
  selector: 'app-filter-template-string',
  templateUrl: './filter-template-string.component.html',
  styleUrls: ['./filter-template-string.component.scss'],
})
export class FilterTemplateStringComponent implements OnInit {


  @Input() company: Company;
  @Input() idModal: string = 'closeFilterTemplateString'
  @Input() title: string = 'Filter'
  @Input() textLabel: string = 'text'

  @Input() textsSearch: string[] = [];
  @Input() textInput: string = '';

  @Input() haveDelete: boolean = true;

  textFilter: string[];
  textShower: string[];

  textSelected: string;
  successTranslate = ''

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private modalCtrl: ModalController,
    private utils: UtilsTranslate,
  ) { }

  ngOnInit() {
    this.start()

  }

  start() {

    this.textFilter = this.textsSearch;
    this.textShower = [];

    if (this.textFilter.length < 10 && this.textFilter.length > 0) {
      this.textShower.push(...this.textFilter.slice(0, this.textFilter.length));
    } else if (this.textFilter.length >= 10) {
      this.textShower.push(...this.textFilter.slice(0, 10));
    }

    if(this.textInput){
      this.changeTextInput()
    }

  }

  changeTextInput() {
    if (!this.textInput) {
      this.start();
    } else {
      this.textShower = [];
      this.textFilter = this.textsSearch.filter(textSearch => textSearch.toString().toLowerCase().trim()
        .includes((this.textInput || '').toString().toLowerCase().trim()));
      if (this.textFilter.length < 10) {
        this.textShower.push(...this.textFilter.slice(0, this.textFilter.length));
      } else {
        this.textShower.push(...this.textFilter.slice(0, 10));
      }
    }

  }

  closeModal() {
    if (this.modalCtrl) {
      this.modalCtrl.dismiss({});
    }
  }

  loadData() {
    setTimeout(() => {

      if (this.textShower.length >= this.textFilter.length) {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      let nuevoArr2 = [];
      if (this.textFilter.length < this.textShower.length + 10) {
        nuevoArr2 = this.textFilter.slice(this.textShower.length, this.textFilter.length);
      } else {
        nuevoArr2 = this.textFilter.slice(this.textShower.length, this.textShower.length + 10);
      }
      this.textShower.push(...nuevoArr2);

      this.inifiteScroll.complete();
    }, timeReload);
  }

  textSelectFilter() {
    let selectFilter = this.textsSearch.find(textSelect => textSelect.toLowerCase() == this.textSelected.toLowerCase());
    if (selectFilter) {
      if (this.modalCtrl) {
        this.modalCtrl.dismiss({
          selectFilter
        });
      }
    } else {
      this.utils.presentToastTranslate('error')
    }
  }

  deleteTextFilter() {
   this.utils.presentToastTranslate('successFilter',false,0)
    if (this.modalCtrl) {
      this.modalCtrl.dismiss({
        delete: true,
        selectFilter: ''
      });
    }
  }




}
