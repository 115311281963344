import { DayWeekMachineStatusAlert, WeekDayMachineAlert } from '../../models';

type WeekDaysDBOptions =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';


const FIRST_DAY_MONDAY: string = '1';

export const getWeekdayInfoMachineAlert = (
  weekDaysDB: string[],
  firstDayWeekTranslate: string
): WeekDayMachineAlert[] => {
  const PATH_TRANSLATE = 'machines_status.machine_status_alert.select_date.';
  const WEED_DAY_INFO: Record<WeekDaysDBOptions, WeekDayMachineAlert> = {
    sunday: {
      translateComplete: `${PATH_TRANSLATE}sunday`,
      translate: `${PATH_TRANSLATE}sunday_`,
      value: 0,
    },
    monday: {
      translateComplete: `${PATH_TRANSLATE}monday`,
      translate: `${PATH_TRANSLATE}monday_`,
      value: DayWeekMachineStatusAlert.monday,
    },
    tuesday: {
      translateComplete: `${PATH_TRANSLATE}tuesday`,
      translate: `${PATH_TRANSLATE}tuesday_`,
      value: DayWeekMachineStatusAlert.tuesday,
    },
    wednesday: {
      translateComplete: `${PATH_TRANSLATE}wednesday`,
      translate: `${PATH_TRANSLATE}wednesday_`,
      value: DayWeekMachineStatusAlert.wednesday,
    },
    thursday: {
      translateComplete: `${PATH_TRANSLATE}thursday`,
      translate: `${PATH_TRANSLATE}thursday_`,
      value: DayWeekMachineStatusAlert.thursday,
    },
    friday: {
      translateComplete: `${PATH_TRANSLATE}friday`,
      translate: `${PATH_TRANSLATE}friday_`,
      value: DayWeekMachineStatusAlert.friday,
    },
    saturday: {
      translateComplete: `${PATH_TRANSLATE}saturday`,
      translate: `${PATH_TRANSLATE}saturday_`,
      value: DayWeekMachineStatusAlert.saturday,
    },
  };

  if (weekDaysDB.length === 0) {
    let allDays: WeekDayMachineAlert[] = [];

    const keys = Object.keys(WEED_DAY_INFO);

    for (let key of keys) {
      allDays.push(WEED_DAY_INFO[key]);
    }

    return allDays;
  }

  let weekDaysInfo: WeekDayMachineAlert[] = [];

  for (let weekDayDB of weekDaysDB) {
    if (WEED_DAY_INFO?.[weekDayDB]) {
      weekDaysInfo.push(WEED_DAY_INFO?.[weekDayDB]);
    } else {
      console.error('not exist weekdaydb');
    }
  }

  orderWeekDaysInfo(weekDaysInfo, firstDayWeekTranslate);

  return weekDaysInfo;
};

const orderWeekDaysInfo = (
  weekDaysInfo: WeekDayMachineAlert[],
  firstDayWeekTranslate: string
) => {
  weekDaysInfo = weekDaysInfo.sort((a, b) => a.value - b.value);

  const includesSunday = weekDaysInfo.some((week) => week.value === 0);
  if (firstDayWeekTranslate === FIRST_DAY_MONDAY && includesSunday) {
    orderFirstMonday(weekDaysInfo);
  }
};

const orderFirstMonday = (weekDaysInfo: WeekDayMachineAlert[]) => {
  const sunday = weekDaysInfo.shift();
  weekDaysInfo.push(sunday);
};
