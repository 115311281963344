import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

// Service
import { PermissionService } from '../../services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class EcommerceVisibilityGuard {
  constructor(
    private _permissionService: PermissionService,
    public _router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._permissionService.getEcommerceVisibilityGuard();
  }
}
