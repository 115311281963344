import { Injectable } from '@angular/core';
import { Subject, lastValueFrom } from 'rxjs';

// services
import { ReportsService } from './reports.service';

// models
import { Client, Operator, OperatorGroup, Till } from 'src/app/models';
import {
  HierarchyOptionsReport,
  TypesSelectedReport,
  VisibilitySelectedReport,
  VisibilityValidateCardByTextReport,
} from '../models';

// utils
import { UtilsTranslate } from '../../../../../utils/utils-translate';

@Injectable({
  providedIn: 'root',
})
export class FilterReportService {
  operatorGroupsAll: OperatorGroup[] = [];
  operatorsAll: Operator[] = [];
  clientsAll: Client[] = [];
  tillsAll: Till[] = [];

  // POS 0 Filter
  // POS 1 CREATE/EDIT
  operators: [Operator[], Operator[]] = [[], []];
  clients: [Client[], Client[]] = [[], []];
  tills: [Till[], Till[]] = [[], []];

  operatorGroupSelected: OperatorGroup[] = [null, null];
  operatorSelected: Operator[] = [null, null];
  clientSelected: Client[] = [null, null];
  tillSelected: Till[] = [null, null];

  cardNumFilter: string = null;
  cards: { id: number; cardNum: string }[] = [];

  isValidFilter: boolean = false;

  loadingVisibility: boolean = true;

  changeLoadingVisibility$ = new Subject();
  changeVisibility$ = new Subject();

  constructor(
    private _reportService: ReportsService,
    private utils: UtilsTranslate
  ) {}

  start() {
    this.loadingVisibility = true;
    this.changeLoadingVisibility$.next(null);
    Promise.all([
      this.getOperatorGroupsByService(),
      this.getOperatorsPromiseByService(),
      this.getClientsByService(),
      this.getTillsByService(),
    ])
      .then(([operatorGroups, operators, clients, tills]) => {
        this.operatorGroupsAll = operatorGroups;
        this.operatorsAll = operators;
        this.clientsAll = clients;
        this.tillsAll = tills;

        this.selectOeratorGroup(TypesSelectedReport.filter);
        this.selectOeratorGroup(TypesSelectedReport.createEdit);

        this.loadingVisibility = false;

        this.changeLoadingVisibility$.next(null);
        this.changeVisibility$.next(null);
      })
      .catch(() => {
        this.utils.presentToastTranslate('error', true, 0);
        this.loadingVisibility = false;
        this.changeLoadingVisibility$.next(null);
      });
  }

  getOperatorGroupsByService() {
    return lastValueFrom(this._reportService.getOperatorsGroups());
  }

  getOperatorsPromiseByService() {
    return lastValueFrom(this._reportService.getOperators());
  }

  getClientsByService() {
    return lastValueFrom(this._reportService.getClients());
  }

  getTillsByService() {
    return lastValueFrom(this._reportService.getTills());
  }

  selectOeratorGroup(position: TypesSelectedReport) {
    if (this.operatorGroupsAll.length === 1) {
      this.operatorGroupSelected[position] = this.operatorGroupsAll[0];
      this.selectOperator(position);
    } else {
      this.operatorGroupSelected[position] = null;
      this.operatorSelected[position] = null;
      this.clientSelected[position] = null;
      this.tillSelected[position] = null;
    }
  }

  selectOperator(position: TypesSelectedReport) {
    this.operators[position] = this.filterOperator(position);
    if (this.operators[position].length === 1) {
      this.operatorSelected[position] = this.operators[position][0];
      this.clients[position] = this.filterClient(position);
    } else {
      this.operatorSelected[position] = null;
      this.clients[position] = [];
      this.tills[position] = [];
    }

    if ((this.clientsAll || []).length === 1) {
      this.clientSelected[position] = this.clientsAll[0];
    } else {
      this.clientSelected[position] = null;
    }

    this.tillSelected[position] = null;

    this.changeVisibility$.next(null);
  }

  selectClient(position: TypesSelectedReport) {
    this.clients[position] = this.filterClient(position);
    if (this.clients[position].length === 1) {
      this.clientSelected[position] = this.clients[position][0];
      this.tills[position] = this.tillsAll.filter(
        (till) =>
          till.operatorGroupId === this.operatorGroupSelected[position].ID &&
          till.operatorId === this.operatorSelected[position].id &&
          till.clientId === this.clientSelected[position].ID
      );
      // this.selectTill(position);
    } else {
      this.clientSelected[position] = null;
      this.tills[position] = [];
      // this.tillSelected[position]  = null;
    }
    this.tillSelected[position] = null;
    this.changeVisibility$.next(null);
  }

  selectTill(position: TypesSelectedReport) {
    if (!this.clientSelected[position]) {
      return;
    }
    this.tills[position] = this.filterTill(position);

    if (this.tills[position].length === 1) {
      this.tillSelected[position] = this.tills[position][0];
    } else {
      this.tillSelected[position] = null;
    }

    this.changeVisibility$.next(null);
  }

  filterOperator(position: TypesSelectedReport) {
    return this.operatorsAll.filter(
      (operator) =>
        operator.OperatorGroupId === this.operatorGroupSelected[position].ID
    );
  }

  filterClient(position: TypesSelectedReport) {
    return this.clientsAll.filter(
      (client) => client.OperatorId === this.operatorSelected[position].id
    );
  }

  filterTill(position: TypesSelectedReport) {
    return (this.tills[position] = this.tillsAll.filter(
      (till) =>
        till.operatorGroupId === this.operatorGroupSelected[position].ID &&
        till.operatorId === this.operatorSelected[position].id &&
        till.clientId === this.clientSelected[position].ID
    ));
  }

  getOperatorGroups() {
    return this.operatorGroupsAll;
  }

  getOperatorGroup(position: TypesSelectedReport) {
    return this.operatorGroupSelected[position];
  }

  getOperators(position: TypesSelectedReport) {
    return this.operators[position];
  }

  getOperator(position: TypesSelectedReport) {
    return this.operatorSelected[position];
  }

  getClients(position: TypesSelectedReport) {
    return this.clients[position];
  }

  getClient(position: TypesSelectedReport) {
    return this.clientSelected[position];
  }

  getTills(position: TypesSelectedReport) {
    return this.tills[position];
  }

  getTill(position: TypesSelectedReport) {
    return this.tillSelected[position];
  }

  getLoadingVisibility() {
    return this.loadingVisibility;
  }

  setOperatorGroup(operatorGroupId: number, position: TypesSelectedReport) {
    this.operatorGroupSelected[position] = this.operatorGroupsAll.find(
      (opeatorGroup) => opeatorGroup.ID === operatorGroupId
    );

    if (this.operatorGroupSelected[position]) {
      this.selectOperator(position);
    } else {
      this.operatorSelected[position] = null;
      this.clientSelected[position] = null;
      this.tillSelected[position] = null;
      if (position === TypesSelectedReport.filter) {
        this.cardNumFilter = null;
      }
      this.operators[position] = [];
      this.clients[position] = [];
      this.tills[position] = [];
    }
  }

  setOperator(operatorId: number, position: TypesSelectedReport) {
    this.operatorSelected[position] = this.operators[position].find(
      (operator) => operator.id === operatorId
    );
    if (this.operatorSelected[position]) {
      this.clients[position] = this.filterClient(position);
    } else {
      this.clients[position] = [];
    }
    this.clientSelected[position] = null;
    this.tillSelected[position] = null;

    if (position === TypesSelectedReport.filter) {
      this.cardNumFilter = null;
    }

    this.changeVisibility$.next(null);
  }

  setClient(client: Client, position: TypesSelectedReport) {
    this.clientSelected[position] = client;
    if (this.clientSelected[position]) {
      this.tills[position] = this.filterTill(position);
    } else {
      this.tills[position] = [];
    }
    this.tillSelected[position] = null;
    this.changeVisibility$.next(null);
  }

  setTill(till: Till, position: TypesSelectedReport) {
    this.tillSelected[position] = till;
    this.changeVisibility$.next(null);
  }

  setVisibility(
    visibility: VisibilitySelectedReport,
    position: TypesSelectedReport
  ) {
    this.operators[position] = [];
    this.clients[position] = [];
    this.tills[position] = [];
    this.operatorGroupSelected[position] = null;
    this.operatorSelected[position] = null;
    this.clientSelected[position] = null;
    this.tillSelected[position] = null;

    this.operatorGroupSelected[position] = this.operatorGroupsAll.find(
      (opeatorGroup) => opeatorGroup.ID === visibility.operatorGroupId
    );

    if (this.operatorGroupSelected[position]) {
      this.operators[position] = this.filterOperator(position);
      this.operatorSelected[position] = this.operators[position].find(
        (operator) => operator.id === visibility.operatorId
      );
    }

    if (this.operatorSelected[position]) {
      this.clients[position] = this.filterClient(position);
      if (visibility.clientId || visibility.clientId === 0) {
        this.clientSelected[position] = this.clients[position].find(
          (client) => client.ID === visibility.clientId
        );
      }
    }

    if (this.clientSelected[position]) {
      this.tills[position] = this.filterTill(position);
      if (visibility.tillId || visibility.tillId === 0) {
        this.tillSelected[position] = this.tills[position].find(
          (till) => till.id === visibility.tillId
        );
      }
    }

    if ((visibility?.cards || []).length > 0) {
      this.setCards(visibility.cards);
    }
  }

  clear(position: TypesSelectedReport) {
    this.selectOeratorGroup(position);
  }

  deleteAll() {
    this.operatorGroupsAll = [];
    this.operatorsAll = [];
    this.clientsAll = [];
    this.tillsAll = [];

    this.operators = [[], []];
    this.clients = [[], []];
    this.tills = [[], []];

    this.operatorGroupSelected = [null, null];
    this.operatorSelected = [null, null];
    this.clientSelected = [null, null];
    this.tillSelected = [null, null];
  }

  getCardNumFilter() {
    return this.cardNumFilter;
  }

  setCardNumFilter(cardNum: string) {
    this.cardNumFilter = cardNum;
  }

  getIsValidFilter() {
    return this.isValidFilter;
  }

  setIsValidFilter(isValid: boolean) {
    this.isValidFilter = isValid;
  }

  getTextVisibility(position: TypesSelectedReport) {
    if (!this.getOperatorGroup(position) || !this.isValidFilter) {
      return '';
    }
    const operatorGroup: string =
      this.getOperatorGroup(position)?.description || '';
    const operator: string = this.getOperator(position)?.description
      ? ' / ' + this.getOperator(position)?.description
      : '';
    const client: string = this.getClient(position)?.description
      ? '/ ' + this.getClient(position)?.description
      : '';
    const till: string = (this.getTill(position)?.code || '').toString()
      ? ' / ' + (this.getTill(position)?.code || '').toString()
      : '';
    const cardNum: string =
      position === (TypesSelectedReport.filter && this.getCardNumFilter())
        ? ' / ' + this.getCardNumFilter()
        : '';
    return `${operatorGroup}${operator}${client}${till}${cardNum}`;
  }

  getTextVisibilityByHierarchy(
    position: TypesSelectedReport
  ): Record<HierarchyOptionsReport, string> {
    return {
      operatorGroup: this.getOperatorGroup(position)?.description || '',
      operator: this.getOperator(position)?.description || '',
      client: this.getClient(position)?.description || '',
      till: (this.getTill(position)?.code || '').toString(),
      cardNum:
        position === TypesSelectedReport.filter
          ? this.getCardNumFilter() || ''
          : '',
    };
  }

  clearAll() {
    this.operatorGroupsAll = [];
    this.operatorsAll = [];
    this.clientsAll = [];
    this.tillsAll = [];

    this.operators = [[], []];
    this.clients = [[], []];
    this.tills = [[], []];

    this.operatorGroupSelected = [null, null];
    this.operatorSelected = [null, null];
    this.clientSelected = [null, null];
    this.tillSelected = [null, null];

    this.cardNumFilter = null;
    this.cards = [];

    this.isValidFilter = false;
    this.loadingVisibility = true;
  }

  getOperatorGroupReport(operatorGroupId) {
    const operatorGroup = this.operatorGroupsAll.find(
      (operatorGroup) => operatorGroup.ID === operatorGroupId
    );

    return {
      id: operatorGroupId,
      name: operatorGroup ? operatorGroup.description : '',
    };
  }

  getOperatorReport(operatorId) {
    const operator = this.operatorsAll.find(
      (operator) => operator.id === operatorId
    );

    return {
      id: operatorId,
      name: operator ? operator.description : '',
    };
  }

  getClientReport(clientId) {
    const client = this.clientsAll.find((client) => client.ID === clientId);

    return {
      id: clientId,
      name: client ? client.description : '',
    };
  }

  getTillReport(tillId) {
    const till = this.tillsAll.find((till) => till.id === tillId);

    return {
      id: tillId,
      code: till ? till?.code : null,
    };
  }

  getCards(): { id: number; cardNum: string }[] {
    if ((this.cards || []).length > 0) {
      return this.cards;
    }

    return [{ id: null, cardNum: '' }];
  }

  setCards(cards: { id: number; cardNum: string }[]) {
    this.cards = cards;
  }

  startCards() {
    this.cards = [{ id: null, cardNum: '' }];
  }

  getCardsId(): number[] {
    return this.cards
      .filter((card) => card?.id || card?.id === 0)
      .map((card) => card.id);
  }

  getValidCards(): { id: number; cardNum: string }[] {
    return (this.cards || []).filter((card) => card?.id || card?.id === 0);
  }

  getVisibilityCardText(): VisibilityValidateCardByTextReport {
    const position = TypesSelectedReport.createEdit;

    return {
      operatorGroupId: this.getOperatorGroup(position)
        ? this.getOperatorGroup(position)?.ID
        : null,
      operatorId: this.getOperator(position)
        ? this.getOperator(position)?.id
        : null,
      clientId: null,
    };
  }

  clearAddReport(position: TypesSelectedReport) {
    const operatorGroups = this.getOperatorGroups();
    const operatorGroupSelected = this.getOperatorGroup(position);

    if (operatorGroups.length > 1 && operatorGroupSelected) {
      this.setOperatorGroup(null, position);
      this.setOperator(null, position);
      this.setClient(null, position);
      this.setTill(null, position);
      return;
    }

    const operators = this.getOperators(position);
    const operatorSelected = this.getOperator(position);
    if (operators.length > 1 && operatorSelected) {
      this.setOperator(null, position);
      this.setClient(null, position);
      this.setTill(null, position);
      return;
    }

    this.setClient(null, position);
    this.setTill(null,position);
  }
}
