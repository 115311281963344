
// models
import { Client } from "../models";

// utils
import { replaceAll } from "./utils";


export const removeQuestionMarkForSpaceClient = (clients: Client[]): Client[] => {

  if(!clients){
    return []
  }

  return clients.map(client => {
    if(!(client?.description || '').includes('?')){
      return client
    }

    return {
      ...client,
      description: replaceAll(client.description, '?', ' ')
    }
  })
}
