import { Component, ElementRef, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// services
import { ColorModeService, SkinMangementService } from 'src/app/services';
import { addElemStyle } from 'src/app/utils';

@Component({
  selector: 'app-subsidies-manager-legend',
  templateUrl: './subsidies-manager-legend.component.html',
  styleUrls: ['./subsidies-manager-legend.component.scss'],
})
export class SubsidiesManagerLegendComponent implements OnInit {
  company: Company;

  isLightColor: boolean;

  constructor(
    private elem: ElementRef,
    private modalCtrl: ModalController,
    private _colorModeService: ColorModeService,
    private _skinService: SkinMangementService
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.isLightColor = this._colorModeService.getIsLightColor();

    this.addMarkerColorStyle()
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  addMarkerColorStyle(){
    addElemStyle(
      this.elem,
      '--color-marker-company',
      this.company.colorCompanyText
    );
  }
}
