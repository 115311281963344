import { publicIp, publicIpv4, publicIpv6 } from 'public-ip';


export const getPublicIp = async (): Promise<string> => {
  return await publicIp();
};

export const getPublicIpv6 = async (): Promise<string>  => {
  return await publicIpv6();
};

export const getPublicIpv4 = async (): Promise<string>  => {
  return await publicIpv4();
};
