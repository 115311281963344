// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-padding-x {
  margin-bottom: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-client-individual/filter-client-individual.component.scss"],"names":[],"mappings":"AACA;EACI,oBAAA;AAAJ","sourcesContent":["\r\n.ion-padding-x{\r\n    margin-bottom: 200px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
