import { WritableSignal } from '@angular/core';

// models
import { FilterDateServiceI, UserVisibility } from 'src/app/models';
import {
  DayWeekMachineStatusAlert,
  MachineAlert,
  MakeMachineAlertsParams,
  StatusMachineAlert,
  TypeActivationMachineAlert,
  TypeDateSelectedMachineAlert,
  TypeMachineAlertOptions,
} from '../../models';

// utils
import { getStatusIdByTypeMachineAlert } from '../get-status-id-by-type-machine-alert';
import { getResendMachineAlert } from '../resend';
import {
  GetIntervalTimesMachineStatus,
  getIntervalTimesMachineStatus,
} from '../hour/get-interval-times-machine-status';
import { getStartDateMachineAlert, transformDateMachineAlert } from '../date';
import { getWeekDaysMachineAlert } from './get-week-days-machine-alert';


interface GetMakeMachineAlertsParams {
  visibilities: UserVisibility;

  statusAlerts: StatusMachineAlert[];
  haveBankSales: WritableSignal<boolean>;
  typeAlertSelected: TypeMachineAlertOptions;

  tills: MachineAlert[];

  _filterDateService: FilterDateServiceI;
  minimumHour: number;
  haveExtraHour: boolean;
  _filterDateExtraHourService: FilterDateServiceI;
  typeActivationSelected: TypeActivationMachineAlert;
  typeSelectDate: TypeDateSelectedMachineAlert;
  daysWeek: WritableSignal<Record<DayWeekMachineStatusAlert, boolean>>,

  emails: string[];

}

export const getMakeMachineAlertsParams = ({
  visibilities,

  statusAlerts,
  haveBankSales,
  typeAlertSelected,

  tills,

  _filterDateService,
  minimumHour,
  haveExtraHour,
  _filterDateExtraHourService,
  typeActivationSelected,
  typeSelectDate,
  daysWeek,

  emails,
}: GetMakeMachineAlertsParams): MakeMachineAlertsParams => {
  const statusId = getStatusIdByTypeMachineAlert({
    statusAlert: statusAlerts,
    typeAlertSelected,
    haveBankSales,
  });

  const tillsSelected: number[] = tills
    .filter((till) => till.isSelected)
    .map((tillSelected) => tillSelected.id);

  let startDate = getStartDate(_filterDateService, typeSelectDate);

  let endDate =
    typeSelectDate === TypeDateSelectedMachineAlert.temporaral
      ? _filterDateService.getDateEnd()
      : null;

  const intervalTimes = getIntervalTimes({
    _filterDateService,
    haveExtraHour,
    _filterDateExtraHourService,
  });

  return {
    companyID: visibilities.companyId,
    operatorGroupID: visibilities.operatorGroupId,
    operatorID: visibilities.operatorGroupId,
    statusID: statusId,
    machinesID: tillsSelected,
    AvailableDateIni: transformDateMachineAlert(startDate),
    AvailableDateEnd: transformDateMachineAlert(endDate),
    CronHourTime: minimumHour,
    CronMinuteTime: 0,
    IntervalTimes: intervalTimes,
    emails,
    resend: getResendMachineAlert(typeActivationSelected),
    week_days: getWeekDaysMachineAlert({
      daysWeek,
      typeSelectDate,
      _filterDateService,
    })
  };
};

export const getIntervalTimes = ({
  _filterDateService,
  haveExtraHour,
  _filterDateExtraHourService,
}: GetIntervalTimesMachineStatus): { startTime: string; endTime: string }[] => {
  return getIntervalTimesMachineStatus({
    _filterDateService,
    haveExtraHour,
    _filterDateExtraHourService,
  });
};

const getStartDate = (
  _filterDateService: FilterDateServiceI,
  typeSelectDate: TypeDateSelectedMachineAlert
) => {
  return getStartDateMachineAlert(_filterDateService, typeSelectDate);
};

