// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border {
  border-width: 1px;
  border-style: solid;
  border-color: var(--ion-color-dark);
  padding: 15px;
}

.black {
  font-weight: 600;
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login/components/legal-notice-v2v/legal-notice-v2v.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,mBAAA;EACA,mCAAA;EACA,aAAA;AACF;;AAEA;EACI,gBAAA;EACA,gBAAA;AACJ","sourcesContent":[".border {\r\n  border-width: 1px;\r\n  border-style: solid;\r\n  border-color: var(--ion-color-dark);\r\n  padding: 15px;\r\n}\r\n\r\n.black {\r\n    font-weight: 600;\r\n    font-size: 0.9em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
