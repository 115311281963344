// services
import {
  CardsByTextReportService,
  FilterReportService,
} from '../../../../services';

interface UsePreviouslyValidatedCardTextReport {
  _filterReportService: FilterReportService;
  _cardsByTextReportService: CardsByTextReportService;
}

export const usePreviouslyValidatedCardTextReport = ({
  _filterReportService,
  _cardsByTextReportService,
}: UsePreviouslyValidatedCardTextReport): boolean => {
  if (!_cardsByTextReportService.isSameVisibility(_filterReportService)) {
    return false;
  }

  if (!_cardsByTextReportService.startedValidateCardText()) {
    return false;
  }

  if (_cardsByTextReportService.loadingValidateCardText()) {
    return false;
  }

  if (_cardsByTextReportService.isSameTextCard()) {
    return true;
  }

  return false;
};
