// models
import { Till } from "src/app/models";
import { MachineRoute } from "../../../route-management/sales-module/models";

export const getTillsByTillRouteInvManager = (tillsRoutes: MachineRoute[]): Till[] => {
  return tillsRoutes.map(till => ({
    id: till.machineID,
    code: till.machine
  }))
}
