import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastController, NavController } from '@ionic/angular';
import { Observable } from 'rxjs';

// config
import { AppConfig } from '../../config/config';

// Service
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '../../services/permission.service';

// Utils
import { UtilsTranslate } from '../../utils';


@Injectable({
  providedIn: 'root'
})
export class ClientVisibilityGuard  {


  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private _permissionService: PermissionService,
    private httpClient: HttpClient,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private navCtrl: NavController,
    private utils: UtilsTranslate,
    public _router: Router) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     return this._permissionService.getClientVisibility();
  }





}
