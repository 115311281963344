import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Platform } from '@ionic/angular';

// Models
import { Company } from '../../../../../models/';
import { CarouselType } from '../../models/carousel-type.type';

// Utils
import { pcWidthMix } from '../../../../../utils';

@Component({
  selector: 'app-arrow-position',
  templateUrl: './arrow-position.component.html',
  styleUrls: ['./arrow-position.component.scss'],
})
export class ArrowPositionComponent implements OnInit {

  @Input() slideName: CarouselType = null
  @Input() company: Company = null;
  @Input() isValidNext: boolean = true;

  @Input() haveBack: boolean = true;
  @Input() haveNext: boolean = true;

  @Input() backTranslate: string = 'back';
  @Input() nextTranslate: string = 'next';

  @Output() sendNext = new EventEmitter<CarouselType>();
  @Output() sendBack = new EventEmitter<CarouselType>();

  pcWidthMix = pcWidthMix

  // rutas sin margen vertical
  marginVertical: CarouselType[] = ['Client', 'CardNum']

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
  }

  next(){
    this.sendNext.emit(this.slideName);
  }

  back(){
    this.sendBack.emit(this.slideName);
  }

}
