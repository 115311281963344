import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateByDictionary'
})
export class TranslateByDictionaryPipe implements PipeTransform {

  transform(text: string, translates: {[x: string]: string}): string {
    return translates[text] || text;
  }

}
