export interface TranslateCards {
  tagStatus: string;
  cardActivity: string;
  subsidies: string;
  loadCredit: string;
  registerOrAssignCards: string;
  checkCard: string;
  onlineLoad: string;
  refill: string;
  refunds: string;
  transactions: string;
  appSupport: string;

  subsidyManager: string;
}

export const getCardRoutes = (
  translates: TranslateCards
): { route: string; name: string }[] => {
  return [
    { route: '/user/tag-status', name: translates.tagStatus },
    { route: '/user/card-activity', name: translates.cardActivity },
    { route: '/user/subsidies', name: translates.subsidies },
    { route: '/user/webload/load-credit', name: translates.loadCredit },
    { route: '/user/register-cards', name: translates.registerOrAssignCards },
    { route: '/user/check-cards', name: translates.checkCard },
    { route: '/user/online-load', name: translates.onlineLoad },
    { route: '/user/refill', name: translates.refill },
    { route: '/user/transaction', name: translates.transactions },
    // { route: '/user/app-support', name: translates.appSupport },
    { route: '/user/refunds', name: translates.refunds },


    { route: '/user/subsidy-manager', name: translates.subsidyManager },
    { route: '/user/subsidies-v2', name: translates.subsidies },
  ];
};
