import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

// Models
import { Company } from 'src/app/models/';

// Service
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from 'src/app/services';
import { LanguageRouteService, LanguageService } from '../../services/';

// Utils
import { imageFlags, type } from '../../../utils/constants';



@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {

  @Input() haveDiffStyleLetters = false;

  public showLanguages: boolean = false;
  public imageFlag: string = "";
  public nameFlag: string = "";
  imagesFlags:{image: string, locale: string}[] = imageFlags;


  public url: string = "";


  newLanguage: string;

  wasInside: boolean = false;

  modLanguage: boolean = false;

  public eventsSubscription: Subscription;

  @Input() events?: Observable<void>;

  company: Company;
  type = type;

  isLoginElora: boolean = false;

  changeLanguage$: Subscription;

  constructor(private _languageService: LanguageService,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private router: Router,
    private skinService: SkinMangementService
  ) {
  }

  async ngOnInit() {

    this.company = this.skinService.getCompany();

    await this._languageService.startLanguageAsync();
    this.translate.setDefaultLang(this._languageService.getLanguage());
    this.imageFlag = this._languageService.getImage();
    this.nameFlag = this._languageService.getAlt();

    this.url = this.router.url

    this.isLoginElora = this.router.url === '/login' && this.company?.companySkin === 'V2VFactory';

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {

      setTimeout(() => {
        this.newLanguage = valor.substring(3, 5)
        this.imageFlag = this._languageService.getImage();
        this.nameFlag = this._languageService.getAlt();
      }, 200)

    });

  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    if(this.changeLanguage$){
      this.changeLanguage$.unsubscribe()
    }
  }

  changeLanguage(newLanguage) {
    this._languageRouteService.sendChangeLanguage(newLanguage);
    this.modLanguage = true;
    this.showLanguages = false;
  }


  modShowLanguages(showLanguage) {
    this.showLanguages = showLanguage;
  }

  clickShowLanguage() {
    setTimeout(() => {
      if (!this.showLanguages) {
        this.showLanguages = true;
      }
    }, 100)
  }

  getUrl() {
    return this.router.url;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      if (this.showLanguages) {
        this.showLanguages = false;
      }
    }

    this.wasInside = false;
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

}
