import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

// Component
import { CreatedTextCardPcComponent } from '../../share/modals/confirmation/created-text-card-pc/created-text-card-pc.component';
import { AssignCardModalComponent } from '../../../../../../../share/components/carousel-filter-guided/components/select-register-card/assign-card-modal/assign-card-modal.component';
import { ModalSelectClientComponent } from '../../../../../../../share/components/modals/modal-select-client/modal-select-client.component';

// Service
import { TranslateService } from '@ngx-translate/core';
import {
  LoadingService,
  VisibilitySelectService,
  LanguageRouteService,
} from '../../../../../../../share/services/';
import { ClientFilterService } from '../../../../../../../share/components/filter-client/service/client-filter.service';
import { CardLengthRegisterService } from '../../../services/card-length-register.service';
import {
  RegisterCardParams,
  RegisterCardsService,
  AssignCardCheckParams,
} from '../../../../../../../share/services/register-cards/register-cards.service';

// Models
import { Company, Client } from '../../../../../../../models';
import { ParamsUnlinkClientCardByList } from '../../../models';

// Utils
import {
  pcWidth,
  errorsTypes,
  UtilsTranslate,
  ModalGenerator,
} from '../../../../../../../utils';


@Component({
  selector: 'app-text-input-card',
  templateUrl: './text-input-card.component.html',
  styleUrls: ['./text-input-card.component.scss'],
})
export class TextInputCardComponent implements OnInit {
  @Input() company: Company = null;
  @Input() cardsText = '';

  @Input() segment: string = '0';
  @Input() segmentType: string = '0';

  showError = false;
  messageError = '';
  showSelectOperator = false;

  operatorId = null;

  client: Client = null;
  errorColor = false;

  loadingText = false;

  // translates
  requiredCardsTranslates = '';
  clientRequiredTranslate = '';
  selectOperatorTranslate = '';
  cardInvalidTranslate = '';

  pcWidth = pcWidth;

  minCard: number = 6;
  maxCard: number = 8;

  changeLanguage$: Subscription;
  filterChange$: Subscription;

  constructor(
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private platform: Platform,
    private _languageRouteService: LanguageRouteService,
    private _visibilityService: VisibilitySelectService,
    private _clientFilter: ClientFilterService,
    private _registerCardsService: RegisterCardsService,
    private _loadingService: LoadingService,
    private _cardLengthService: CardLengthRegisterService,
    private utils: UtilsTranslate,
    private modalGenerator: ModalGenerator
  ) {}

  ngOnInit() {
    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    this.minCard = this._cardLengthService.getMinLengthCard();
    this.maxCard = this._cardLengthService.getMaxLengthCard();

    this.filterChange$ = this._visibilityService.filterChange.subscribe(
      (res) => {
        if (res && res?.operatorSelected && res?.operatorSelected != -1) {
          this.showSelectOperator = false;
          this.operatorId = res.operatorSelected;
          if (this.errorColor) {
            this.errorColor = false;
          }
          if (this.messageError == this.selectOperatorTranslate) {
            this.messageError = '';
          }
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.segment || changes?.segmentType) {
      this.cardsText = '';
      this.showError = false;
      this.messageError = '';
    }
  }

  ngOnDestroy(): void {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if (this.filterChange$) this.filterChange$.unsubscribe();
  }

  inputFocus() {
    let operatorSelected = this._visibilityService.getOperatorSelected();
    if (!operatorSelected || operatorSelected == -1) {
      this.showSelectOperator = true;
    } else {
      this.showSelectOperator = false;
    }
  }

  async showSelectClient() {
    const data = await this.modalGenerator.show(ModalSelectClientComponent);
    if (data?.client) {
      this.client = data.client;
      if (this.messageError == this.clientRequiredTranslate) {
        this.messageError = '';
      }
    }

    // let modal = await this.modalCtrl.create({
    //   component: ModalSelectClientComponent,
    //   cssClass: this.platform.width() > pcWidth && 'modal-border-radius',
    // });

    // if (modal) {
    //   await modal.present();

    //   const { data } = await modal.onWillDismiss();

    //   if (data?.client) {
    //     this.client = data.client;
    //     if (this.messageError == this.clientRequiredTranslate) {
    //       this.messageError = '';
    //     }
    //   }
    // }
  }

  changeCardValid(event) {
    this.messageError = '';
    if (event.length > 0) {
      let cardsSplit = this.cardsText
        .split(/(\s+)/)
        .filter((e) => e.trim().length > 0);

      for (let cardSplit of cardsSplit) {
        if (
          cardSplit.length < this.minCard ||
          cardSplit.length > this.maxCard
        ) {
          this.messageError = this.getErrorCardTranslate(cardSplit);
        }
      }
    } else {
      this.messageError = this.requiredCardsTranslates;
    }
  }

  async sendTextCards() {
    this.showError = true;

    if (this.messageError) {
      return;
    }

    if (this.cardsText.length == 0) {
      this.messageError = this.requiredCardsTranslates;
      return;
    }

    let cardsSplit = this.cardsText
      .split(/(\s+)/)
      .filter((e) => e.trim().length > 0);
    if (this.segment == '0') {
      // dar de alta
      this.register(cardsSplit);
    } else if (this.segment === '1') {
      // vincular cliente
      this.linkClient(cardsSplit);
    } else {
      // desvincular
      this.unlinkClientCard(cardsSplit);
    }
  }

  async register(cardsSplit: string[]) {
    if (this.showSelectOperator || !this.operatorId || this.operatorId == -1) {
      this.errorColor = true;
      this.messageError = this.selectOperatorTranslate;
      return;
    }

    this.showError = false;
    this.errorColor = false;

    this.loadingText = true;
    this._loadingService.present();

    let params: RegisterCardParams = {
      Operator_id: this._visibilityService.getOperatorSelected(),
      VT: cardsSplit,
    };

    try {
      let resRegisterList = await lastValueFrom(this._registerCardsService
        .registerCardList(params))

      // console.log('res', res);
      this.loadingText = false;
      this._loadingService.dismiss();
      if (resRegisterList?.not_created.length == 0) {
        this.utils.presentToastTranslate(
          this.company.nameCard == 'AlliancePay'
            ? 'register_cards.selected_interval.created_alliancepay'
            : 'register_cards.selected_interval.created_v2v',
          false,
          0
        );
        this.clearTextCards();
      } else {
        // mostrar modal con las tarjetas que ya existían
        this.showErrorCreated(resRegisterList.not_created);
      }
    } catch (error) {
      this.loadingText = false;
      this._loadingService.dismiss();
      if (error == errorsTypes.exist_card) {
        this.utils.presentToastTranslate(
          'register_cards.text_input_card.exist_cards',
          true,
          0
        );
      } else {
        this.utils.presentToastTranslate('error', true, 0);
      }
    }
  }

  async linkClient(cardsSplit: string[]) {
    if (!this.client || !this.client?.ID) {
      this.messageError = this.clientRequiredTranslate;
      this.showError = true;
      return;
    }

    this.showError = false;
    this.messageError = '';

    let params: AssignCardCheckParams = {
      Client_id: this.client.ID,
      VT: cardsSplit,
    };

    this.loadingText = true;
    this._loadingService.present();
    // console.log('params', params);
    try {
      let resCheck = await lastValueFrom(this._registerCardsService
        .checkCardList(params))

      if (
        resCheck.cards_with_clients.length > 0 ||
        resCheck.not_exist_cards.length > 0
      ) {
        await this.showAssignModal(resCheck, params);
        this.loadingText = false;
        this._loadingService.dismiss();
      } else {
        this.loadingText = false;
        this._loadingService.dismiss();
        this.changeClient(params);
      }
    } catch (error) {
      // console.log('error')
      this.utils.presentToastTranslate('error', true, 0);
      this.loadingText = false;
      this._loadingService.dismiss();
    }
  }

  async unlinkClientCard(cardsSplit: string[]) {
    this.showError = false;
    this.messageError = '';

    let params: ParamsUnlinkClientCardByList = {
      VT: cardsSplit,
    };

    try {
      this.loadingText = true;
      this._loadingService.present();
      await lastValueFrom(this._registerCardsService
        .unlinkClientCardByList(params))

      this.utils.presentToastTranslate(
        'register_cards.select_pc.unlinked_text_successfuly',
        false,
        0
      );
      this.cardsText = '';
      this.loadingText = false;
      this._loadingService.dismiss();
    } catch (error) {
      if (error === errorsTypes.incorrect_card_register_card) {
        this.utils.presentToastTranslate(
          this.company.nameCard === 'AlliancePay'
            ? 'register_cards.select_pc.unlinked_text_not_exist1'
            : 'register_cards.select_pc.unlinked_text_not_exist2',
          true,
          0
        );
      } else {
        this.utils.showError();
      }
      this.loadingText = false;
      this._loadingService.dismiss();
    }
  }

  clearTextCards() {
    this.cardsText = '';
    this.showError = false;
    this._visibilityService.resetParams();
    this._clientFilter.clearFilter();
    this.client = null;
  }

  savePlaceholders() {
    this.translate
      .get([
        'register_cards.text_input_card.card_required2',
        'register_cards.text_input_card.card_required',
        'register_cards.text_input_card.client_required',
        'register_cards.text_input_card.select_operator',
        'register_cards.text_input_card.card_invalid2',
        'register_cards.text_input_card.card_invalid',
      ])
      .subscribe((translates) => {
        this.clientRequiredTranslate =
          translates['register_cards.text_input_card.client_required'];
        this.selectOperatorTranslate =
          translates['register_cards.text_input_card.select_operator'];
        if (this.company.nameCard == 'AlliancePay') {
          this.requiredCardsTranslates =
            translates['register_cards.text_input_card.card_required2'];
          this.cardInvalidTranslate =
            translates['register_cards.text_input_card.card_invalid2'];
        } else {
          this.requiredCardsTranslates =
            translates['register_cards.text_input_card.card_required'];
          this.cardInvalidTranslate =
            translates['register_cards.text_input_card.card_invalid'];
        }
      });
  }

  getErrorCardTranslate(invalidCard) {
    return this.cardInvalidTranslate.replace('{{invalidCard}}', invalidCard);
  }

  async showErrorCreated(existCards) {
    // console.log('existCard', existCards);
    let modal = await this.modalCtrl.create({
      component: CreatedTextCardPcComponent,
      componentProps: {
        existCards,
      },
    });

    if (modal) {
      await modal.present();
    }
  }

  async showAssignModal(res, params: AssignCardCheckParams) {
    this.loadingText = false;
    this._loadingService.dismiss();
    const modal = await this.modalCtrl.create({
      component: AssignCardModalComponent,
      componentProps: {
        cardsWithClients: res?.cards_with_clients || [],
        notExistCards: res?.not_exist_cards || [],
      },
    });

    if (modal) {
      await modal.present();
      const { data } = await modal.onWillDismiss();

      if (data?.changeClient) {
        this.changeClient(params);
      }
    }
  }

  async changeClient(params) {
    this.loadingText = true;
    this._loadingService.present();
    this._registerCardsService.assignCardList(params).subscribe({
      next: (res) => {
        this.loadingText = false;
        this._loadingService.dismiss();
        if (res?.not_exist_cards && res?.not_exist_cards.length == 0) {
          this.utils.presentToastTranslate(
            this.company.nameCard == 'AlliancePay'
              ? 'register_cards.selected_interval.assigned_alliancepay'
              : 'register_cards.selected_interval.assigned_v2v',
            false,
            0
          );
          this.client = null;
          this._clientFilter.clearFilter();
          this.cardsText = '';
        } else {
          this.utils.presentToastTranslate('error', true, 0);
        }
      },
      error: () => {
        this.loadingText = false;
        this._loadingService.dismiss();
        this.utils.presentToastTranslate('error', true, 0);
      },
    });
  }
}
