import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Models
import { Company } from 'src/app/models';

// Environments
import { V2vFactory } from '../../../../../environments/companySetting/V2vFactory';

@Component({
  selector: 'app-confidentiality-v2v',
  templateUrl: './confidentiality-v2v.component.html',
  styleUrls: ['./confidentiality-v2v.component.scss'],
})
export class ConfidentialityV2vComponent implements OnInit {

  company: Company = V2vFactory;

  public scrolling = false;

  scrollDepthTriggered: boolean = false;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() { }

  closeModal(state: boolean = false) {
    if(this.modalCtrl){
      this.modalCtrl.dismiss({
        accept: state
      });
    }
  }


  async logScrolling($event) {

      if (this.scrollDepthTriggered) {
        return;
      }

      if ($event.target.localName != "ion-content") {
        return;
      }

      const scrollElement = await $event.target.getScrollElement();


      const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;


      const currentScrollDepth = $event.detail.scrollTop;


      const targetPercent = 80;

      let triggerDepth = ((scrollHeight / 100) * targetPercent);

      if (currentScrollDepth > triggerDepth) {
        this.scrollDepthTriggered = true;
      }
  }

}

