import { WritableSignal } from '@angular/core';

// services
import { StorageService } from 'src/app/share/services';

// models
import { ClientDashboardWelcome } from '../../models';
import { Client, Operator } from 'src/app/models';

// utils
import { setClientStorage } from '../storage-native';
import {
  getOperatorGroupIdByClient,
  startExtraInfoClientWithVisibilityClient,
  getExtraInfoClient,
} from './';

interface SelectClientDashboardFirstTime {
  clientSelected: WritableSignal<ClientDashboardWelcome>;
  client: Client;
  operators: Operator[];
  extraInfoClient: WritableSignal<string>;
  _storageService: StorageService;
  getDashboardWelcome: Function;
}

export const selectClientDashboardFirstTime = async ({
  clientSelected,
  client,
  operators,
  extraInfoClient,
  _storageService,
  getDashboardWelcome,
}: SelectClientDashboardFirstTime) => {
  clientSelected.set({
    ...client,
    operatorGroupId: getOperatorGroupIdByClient(operators, client.OperatorId),
  });

  const clientSelectedExtraInfo = startExtraInfoClientWithVisibilityClient(
    client,
    operators
  );

  extraInfoClient.set(getExtraInfoClient(clientSelectedExtraInfo));
  await setClientStorage(_storageService, clientSelectedExtraInfo);

  getDashboardWelcome();
};
