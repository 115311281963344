// External module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

// Component
import { ManualButtonComponent } from './manual-button.component';

// Service
import { SkinMangementService } from 'src/app/services';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [ManualButtonComponent],
  providers: [SkinMangementService],
  exports: [ManualButtonComponent],
})
export class ManualButtonModule {}
