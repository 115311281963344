import { ArticlesAena } from '../models';

export const applyFiltersGroupAena = (
  articles: ArticlesAena[],
  articleFilter: ArticlesAena = null,
  publicGroupFilter: string = '',
  privateGroupFilter: string = ''
): ArticlesAena[] => {

  let articlesFiltered: ArticlesAena[] = articles || [];

  if(articleFilter){
    articlesFiltered = articlesFiltered.filter(article => article.articleId === articleFilter.articleId)
  }

  if(publicGroupFilter){
    articlesFiltered = articlesFiltered.filter(article => article?.subgroupPublic ===  publicGroupFilter )
  }

  if(privateGroupFilter){
    articlesFiltered = articlesFiltered.filter(article => article?.subgroupPrivate === privateGroupFilter)
  }

  return articlesFiltered;
};
