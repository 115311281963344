import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderModalModule } from '../header-modal/header-modal.modal';

// Components
import { IdleComponent, IdleModalComponent } from './';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    HeaderModalModule,
  ],
  declarations: [IdleModalComponent, IdleComponent],
  exports: [IdleModalComponent, IdleComponent],
})
export class IdleModule {}
