import { pcWidth } from 'src/app/utils';
import {
  DIMENSION_PC_WIDTH_FIX_MENU,
  DIMENSION_SHOW_ONE_CHART,
  DIMENSION_SHOW_ONE_CHART_MENU,
} from '..';

const SIZE_CHANGE_ARTICLE_MD = 950;
const SIZE_CHANGE_ARTICLE_FIX_MENU_MD = 1200;
// const SIZE_CHANGE_ARTICLE_FIX_MENU_SM = DIMENSION_PC_WIDTH_FIX_MENU;
const SIZE_CHANGE_ARTICLE_FIX_MENU_SM = 850;

const CLASS_NAMES = {
  pc: 'filter-article',
  md: 'margin-filter-article-md',
  sm: 'center ion-margin-top',
};

const CLASS_NAMES_FIX_MENU = {
  pc: 'filter-article-fix-menu',
  md: 'margin-filter-article-fix-menu-md',
  sm: 'center ion-margin-top',
};

export const getClassNamesFilterArticle = (
  width: number,
  isOpenFixMenu: boolean
): string => {
  if (!isOpenFixMenu) {
    return getClassNamesFilterArticleNotFixMenu(width);
  } else {
    return getClassNamesFilterArticleFixMenu(width);
  }
};

const getClassNamesFilterArticleNotFixMenu = (width: number) => {
  if (width > DIMENSION_SHOW_ONE_CHART) {
    return CLASS_NAMES.pc;
  }

  if (width <= pcWidth) {
    return CLASS_NAMES.sm;
  }

  if (width > SIZE_CHANGE_ARTICLE_MD) {
    return CLASS_NAMES.md;
  }

  return '';
};

const getClassNamesFilterArticleFixMenu = (width: number) => {
  if (width > DIMENSION_SHOW_ONE_CHART_MENU) {
    return CLASS_NAMES_FIX_MENU.pc;
  }

  if (width <= SIZE_CHANGE_ARTICLE_FIX_MENU_SM) {
    return CLASS_NAMES_FIX_MENU.sm;
  }

  return '';
};
