export * from './get-aena-routes';
export * from './get-application-management-routes';
export * from './get-articles-routes';
export * from './get-billing-routes';
export * from './get-billing-routes';
export * from './get-card-routes';
export * from './get-click-collect-routes';
export * from './get-dashboard-routes';
export * from './get-data-manager-routes';
export * from './get-documentation-routes';
export * from './get-horeca-query-routes';
export * from './get-machines-routes';
export * from './get-online-shop-routes';
export * from './get-qpay-routes';
export * from './get-qr-routes';
export * from './get-query-routes';
export * from './get-query-routes';
export * from './get-reports-routes';
export * from './get-routes-management-routes';
export * from './get-user-management-routes';
