import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// service
import { ColorModeService } from 'src/app/services/';
import { FixMenuService } from '../../../services/';

// models
import { Company } from '../../../../models/';
import { CHECK_TICKET_URL, CREATE_TICKET_URL } from 'src/app/utils';

@Component({
  selector: 'app-sub-menu-support',
  templateUrl: './sub-menu-support.component.html',
  styleUrls: ['./sub-menu-support.component.scss'],
})
export class SubMenuSupportComponent implements OnInit {
  @Input() company: Company;
  @Input() color: string;
  @Input() create: boolean = false;
  @Input() check: boolean = false;
  @Input() support: boolean = false;

  @Input() createTicket = CREATE_TICKET_URL;
  @Input() checkTicket = CHECK_TICKET_URL;

  showSubmenu: boolean = false;

  isLightColor: boolean = true;

  isHoverGroup: boolean = false;

  changeColor$: Subscription;

  constructor(
    private _fixMenuService: FixMenuService,
    private _colorModeService: ColorModeService
  ) {}

  ngOnInit() {
    this.isLightColor = this._colorModeService.getIsLightColor();

    this.changeColor$ = this._colorModeService.changeColor$.subscribe((res) => {
      this.isLightColor = res;
    });
  }

  ngOnDestroy(): void {
    if (this.changeColor$) this.changeColor$.unsubscribe();
  }

  menuItemHandler(): void {
    this.showSubmenu = !this.showSubmenu;
  }

  openLink(type: LinkType): void {
    let link = '';
    if (type == 'create') link = this.createTicket;
    if (type == 'check') link = this.checkTicket;
    if (link) window.open(link, '_blank');
  }

  closeMenu() {
    this._fixMenuService.closeMenu();
  }

  selectHover(isHoverGroup: boolean) {
    this.isHoverGroup = isHoverGroup;
  }
}

type LinkType = 'create' | 'check';
