import { PERCENTAGE_SALES_MODULE_PICKING, PRODUCT_PREPARED } from './constant';

export const getPercentagePicking = (percentagePositions: boolean[]) => {
  const position = percentagePositions.findIndex((percentage) => percentage);

  if (position === -1) {
    return PRODUCT_PREPARED;
  }

  const percentage = PERCENTAGE_SALES_MODULE_PICKING?.[position] || 0;

  return percentage / 100 +  PRODUCT_PREPARED;
};
