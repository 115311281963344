// models
import {
  InfoShareEditMachineAlert,
  MachineAlertShowInfo,
  TypeActivationMachineAlert,
  TypeDateSelectedMachineAlert,
  TypeMachineAlertOptions,
} from '../../models';

// utils
import { getAlertsIdsUpdateMachineAlert } from './get-alerts-ids-update-machine-alert';
import { getTypeMachineAlert } from '../get-type-machine-alert';
import { PERFOMANCE_TYPE_ALERT } from '../constant';
import { getAllWeekDaysMachineAlert } from '../weekdays/get-all-weekdays-machine-alerts';

export const getInfoShareEditAlert = (
  alertsSelected: MachineAlertShowInfo[]
): InfoShareEditMachineAlert => {
  const {
    alertsIds,
    alertIdsRemove,
    alertsEdit,
  } = getAlertsIdsUpdateMachineAlert(alertsSelected);

  const infoShare: InfoShareEditMachineAlert = {
    alertsEdit,
    alertsIds,
    alertIdsRemove,
    multipleAlerts: (alertsSelected || []).length > 1,
    emails: getEmailsShare(alertsSelected),
    minDefaultPeriod: getMinDefaultPeriod(alertsSelected),
    typeAlert: getTypeAlert(alertsSelected),
    client: getClientShare(alertsSelected),
    machineIds: getMachinesShare(alertsSelected),
    dateSelected: getDateShare(alertsSelected),
    intervalTimes: getintervalTimesShare(alertsSelected),
    enable: true,
    typeActivationSelected: getTypeActivationSelected(alertsSelected),
    typeSelectDate: TypeDateSelectedMachineAlert.undefined,
    haveBankSales: false,
    weekDays: getWeekDaysShare(alertsSelected),
  };

  infoShare.typeSelectDate = getTypeDateSelectedMachineAlert(infoShare);

  //console.log('alertsSelected', alertsSelected, 'activation', getTypeActivationSelected(alertsSelected))

  infoShare.haveBankSales = getHaveBankSales(alertsSelected, infoShare);

  // console.log('info share prev', infoShare);

  return infoShare;
};

// -------------------- Client --------------------
const getClientShare = (
  alertsSelected: MachineAlertShowInfo[]
): { id: number; description: string } => {
  const clients = alertsSelected.map((alert) => alert.client.id);

  const clientsNotRepeat = Array.from(new Set(clients));

  return (clients || []).length === (clientsNotRepeat || []).length &&
    (clients || []).length > 0
    ? {
        id: alertsSelected?.[0]?.client?.id,
        description: alertsSelected?.[0]?.client?.description,
      }
    : null;
};

// -------------------- Machine --------------------
const getMachinesShare = (alertsSelected: MachineAlertShowInfo[]): number[] => {
  const machines = alertsSelected.map((alert) => alert.machine.id);

  const machineNotRepeat = Array.from(new Set(machines));

  return machineNotRepeat;
};

// -------------------- Date --------------------
const getDateShare = (
  alertsSelected: MachineAlertShowInfo[]
): { start: Date; end: Date } => {
  if ((alertsSelected || []).length === 0) {
    return null;
  }

  const dateSelectedStart = alertsSelected[0].dateSelected.start;
  const dateSelectedEnd = alertsSelected[0].dateSelected.end;

  for (let i = 1; i < alertsSelected.length; i++) {
    if (dateSelectedStart !== alertsSelected[i].dateSelected.start) {
      return null;
    }
    if (dateSelectedEnd !== alertsSelected[i].dateSelected.end) {
      return null;
    }
  }

  return alertsSelected[0].dateSelected;
  // return applyMinDateValid(alertsSelected[0].dateSelected);
};

// const applyMinDateValid = (dateSelected: { start: Date; end: Date }) => {
//   dateSelected.start = getDateValidMachineAlert(dateSelected.start);
//   dateSelected.end = getDateValidMachineAlert(dateSelected.end);

//   return dateSelected;
// };

// -------------------- Interval Times --------------------
const getintervalTimesShare = (
  alertsSelected: MachineAlertShowInfo[]
): { startTime: string; endTime: string }[] => {
  if ((alertsSelected || []).length === 0) {
    return [];
  }

  const intervalTimes = alertsSelected[0]?.intervalTimes;

  for (let i = 1; i < alertsSelected.length; i++) {
    if (!isSameInterval(intervalTimes, alertsSelected[i].intervalTimes)) {
      return null;
    }
  }

  return intervalTimes;
};

const isSameInterval = (
  intervalTimesPrev: { startTime: string; endTime: string }[],
  intervalTimesCurrent: { startTime: string; endTime: string }[]
) => {
  if ((intervalTimesPrev || []).length != (intervalTimesCurrent || []).length) {
    return false;
  }

  if (intervalTimesPrev[0].startTime !== intervalTimesCurrent[0].startTime) {
    return false;
  }

  if (intervalTimesPrev[0].endTime !== intervalTimesCurrent[0].endTime) {
    return false;
  }

  if (intervalTimesPrev.length > 1) {
    if (
      intervalTimesPrev[1].startTime !== intervalTimesCurrent?.[1]?.startTime
    ) {
      return false;
    }

    if (intervalTimesPrev[1].endTime !== intervalTimesCurrent?.[1]?.endTime) {
      return false;
    }
  }

  return true;
};

// -------------------- Type Activation --------------------
const getTypeActivationSelected = (
  alertsSelected: MachineAlertShowInfo[]
): TypeActivationMachineAlert => {
  if ((alertsSelected || []).length === 0) {
    return null;
  }

  const typeActivation = alertsSelected[0].typeActivationSelected;

  for (let i = 1; i < alertsSelected.length; i++) {
    if (typeActivation !== alertsSelected[i].typeActivationSelected) {
      return null;
    }
  }

  return alertsSelected[0].typeActivationSelected;
};

// -------------------- Min Date (Hour) --------------------
const getMinDefaultPeriod = (
  alertsSelected: MachineAlertShowInfo[]
): { hour: number } => {
  if ((alertsSelected || []).length === 0) {
    return null;
  }

  const hour = alertsSelected[0].minDefaultPeriod.hour;

  for (let i = 1; i < alertsSelected.length; i++) {
    if (hour !== alertsSelected[i].minDefaultPeriod.hour) {
      return null;
    }
  }

  return { hour };
};

// -------------------- Emails --------------------
const getEmailsShare = (alertsSelected: MachineAlertShowInfo[]): string[] => {
  if ((alertsSelected || []).length === 0) {
    return [];
  }

  const emails = alertsSelected[0].emails;

  for (let i = 1; i < alertsSelected.length; i++) {
    if (!areSameEmails(emails, alertsSelected[i].emails)) {
      return [];
    }
  }

  return alertsSelected[0].emails;
};

const areSameEmails = (emailsPrev: string[], emailsCurrent: string[]) => {
  if ((emailsPrev || []).length !== (emailsCurrent || []).length) {
    return false;
  }

  emailsPrev = emailsPrev.sort((a, b) => a.localeCompare(b));
  emailsCurrent = emailsCurrent.sort((a, b) => a.localeCompare(b));

  for (let i = 0; i < emailsPrev.length; i++) {
    if (emailsPrev?.[i] !== emailsCurrent?.[i]) {
      return false;
    }
  }

  return true;
};

// -------------------- get Type Alert --------------------
const getTypeAlert = (
  alertsSelected: MachineAlertShowInfo[]
): TypeMachineAlertOptions => {
  if ((alertsSelected || []).length === 0) {
    return null;
  }

  const typeAlert = alertsSelected[0].statusAlert.id;

  for (let i = 1; i < alertsSelected.length; i++) {
    if (typeAlert !== alertsSelected?.[i]?.statusAlert.id) {
      return null;
    }
  }

  return getTypeMachineAlert(alertsSelected[0].statusAlert.status);
};

const getTypeDateSelectedMachineAlert = (
  infoShare: InfoShareEditMachineAlert
): TypeDateSelectedMachineAlert => {
  if (infoShare?.dateSelected?.start && !infoShare?.dateSelected?.end) {
    return TypeDateSelectedMachineAlert.temporaral;
  }

  return TypeDateSelectedMachineAlert.undefined;
};

// -------------------- get Bank Sales --------------------
const getHaveBankSales = (
  alertsSelected: MachineAlertShowInfo[],
  infoShare: InfoShareEditMachineAlert
) => {
  if (!infoShare?.typeAlert) {
    return true;
  }

  const typeSelected = (
    alertsSelected?.[0]?.statusAlert?.status || ''
  ).toLowerCase();

  return typeSelected !== PERFOMANCE_TYPE_ALERT.notHaveBankSales.toLowerCase();
};

// -------------------- week Days --------------------
export const getWeekDaysShare = (
  alertsSelected: MachineAlertShowInfo[]
): number[] => {
  const weekDays = alertsSelected.map((alert) => alert.weekDays);

  const allPositionsSelected = getAllWeekDaysMachineAlert();

  if (weekDays.length > 0) {
    const weekDaysShare: number[] = weekDays?.[0]
      .map((weekDay) => weekDay.value)
      .sort((a, b) => a - b);
    for (let i = 1; i < weekDays.length; i++) {
      const weekDayPos: number[] = weekDays?.[i]
        .map((weekDay) => weekDay.value)
        .sort((a, b) => a - b);
      if (!isSameWeek(weekDaysShare, weekDayPos)) {
        return allPositionsSelected;
      }
    }
    return weekDaysShare;
  }

  return allPositionsSelected;
};

const isSameWeek = (weekDayShare: number[], weekDayPos: number[]): boolean => {
  if ((weekDayShare || []).length !== (weekDayPos || []).length) {
    return false;
  }

  return !weekDayShare.some((weekDay, i) => weekDay !== weekDayPos[i]);
};
