import { ModalController } from '@ionic/angular';
import { ComponentProps, ComponentRef } from '@ionic/core';

// constants
import { GRIDSIZE, pcWidth } from './constants';

export const showModal = async (
  modalCtrl: ModalController,
  component: ComponentRef,
  componentProps?: ComponentProps<ComponentRef>,
  cssClass?: string | string[],
  platformWidth?: number,
  isLightColor: boolean = true
) => {
  if (!cssClass && platformWidth) {
    cssClass =
      platformWidth > pcWidth
        ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
        : '';
  }

  let modal = await modalCtrl.create({
    component,
    componentProps,
    cssClass,
  });

  await modal.present();
  const { data } = await modal.onWillDismiss();

  return data;
};

export const getStyleModal = (isLightColor: boolean, width: number): string => {
  return !isLightColor && width > pcWidth ? 'modal-border-dark' : '';
};

export const getStyleSizeBiggerModal = (
  isLightColor: boolean,
  width: number
) => {
  const borderDark = !isLightColor ? 'modal-border-dark' : '';
  const sizeModal = width > GRIDSIZE.lg ? 'modal-wrapper3' : '';

  const styleModal =
    width > pcWidth ? `modal-border-radius ${borderDark} ${sizeModal}` : '';

  return styleModal;
};
