import { AlertSpiderMachineStatus, WeekDaysMachineStatus } from '../../models';

export const getWeekDaysActive = (
  weekDays: WeekDaysMachineStatus[],
  alertSelected: AlertSpiderMachineStatus
) => {
  desactivateWeekDays(weekDays);

  const weekDaysIdActive = (alertSelected.Weekdays || []).map(
    (weekDay) => weekDay.weekdayID
  );

  for (let id of weekDaysIdActive) {
    activateWeekDay(weekDays, id);
  }

  return weekDays;
};

const desactivateWeekDays = (weekDays: WeekDaysMachineStatus[]) => {
  for (let weekDay of weekDays) {
    weekDay.isActive = false;
  }
};

const activateWeekDay = (weekDays: WeekDaysMachineStatus[], id: number) => {
  let weekDay = weekDays.find((weekDay) => weekDay.id === id);
  if (weekDay) {
    weekDay.isActive = true;
  }
};
