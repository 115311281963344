import { Injectable } from '@angular/core';
import { AppConfig } from '../../../../../config/config';
import { map, catchError, timeout } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

// Services
import { SkinMangementService } from 'src/app/services';
import { StorageService } from 'src/app/share/services';

// Models
import {
  DetailsOnlineShop,
  ArticlesShop,
  ParamsShopOrder,
  ParamsRedysShopOrder,
  ShopOrder,
} from '../models';
import { Redsys } from '../../../../../models';

// utils
import { TIME_OUT_DOWNLOAD } from '../../../../../utils/constants';

// Environment
import { alliance } from '../../../../../../environments/companySetting/AllianceVending';



@Injectable({
  providedIn: 'root',
})
export class OnlineShopService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private _skinService: SkinMangementService,
    private _storageService: StorageService
  ) {}

  getArticlesShop(params: ParamsArticlesShop) {
    let endpoint = `/articlesShop`;
    let routeUrl = this.router.url;
    return this.httpClient
      .post<ArticlesShop[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getDetails(idClient?: number) {
    let endpoint = `/getShopDetails`;
    if (idClient) {
      endpoint = `/getShopDetails/${idClient}`;
    }

    let routeUrl = '/user/online-shop';

    return this.httpClient
      .get<DetailsOnlineShop>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  creditSaleOrder(paramsShopOrder: ParamsShopOrder) {
    let endpoint = '/MakeShopOrder';
    let routeUrl = '/user/online-shop';

    paramsShopOrder = {...paramsShopOrder, userTimezoneOffset:  -(Math.trunc(new Date().getTimezoneOffset() / 60))}


    return this.httpClient
      .post<saleOrderId>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        paramsShopOrder
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  redsysOrder(paramsShopOrder: ParamsRedysShopOrder) {
    let endpoint = 'createShopOrder';
    let routeUrl = '/user/online-shop';

    return this.httpClient
      .post<Redsys>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        paramsShopOrder
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getShopOrderById(id) {
    let endpoint = `shopOrder/${id}`;
    let routeUrl = '/user/online-shop';

    return this.httpClient
      .get<ShopOrder>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getInfoPaymentPDF(orderID:number) {
    let endpoint = `/getShopOrdersTicket/${orderID}`;
    let routeUrl = '/user/online-shop';
    endpoint = this.serviceUrl + endpoint + `?route=${routeUrl}`;

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    return this.httpClient
      .get(endpoint, { headers: headers, responseType: 'blob' })
      .pipe(
        map((res) => {
          return res;
        }),
        timeout(TIME_OUT_DOWNLOAD),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getURLOK() {
    // const baseUrl =
    //   this._skinService.getCompany() === alliance
    //     ? AppConfig.BaseURLAlliance
    //     : AppConfig.BaseURLElora;
    const baseUrl = this._skinService.getURLCallback()
    return `https://${baseUrl}/#/user/info-payment-online-shop/1/`;
  }

  getURLKO() {
    // const baseUrl =
    //   this._skinService.getCompany() === alliance
    //     ? AppConfig.BaseURLAlliance
    //     : AppConfig.BaseURLElora;
    const baseUrl = this._skinService.getURLCallback()
    return `https://${baseUrl}/#/user/info-payment-online-shop/0/`;
  }

  saveShopArticles(shopArticles: ArticlesShop[]) {
    this._storageService.set('shopArticles', JSON.stringify(shopArticles));
  }

  getShopArticleStorage() {
    return Promise.all([
      this._storageService.get('shopArticles'),
      this._storageService.get('selectedShop'),
    ]);
  }
}

export interface ParamsArticlesShop {
  clientID: number;
  images: boolean;
  onlyStock: boolean;
  limit?: number;
}

interface saleOrderId{
  orderID: number
}
