import { WritableSignal } from "@angular/core"

// models
import { Report } from "../../models";

interface GetReportsByTypeReportFilter{
  reports: WritableSignal<Report[]>,
  useFilterTypeReport: WritableSignal<boolean>;
  typeReportFilteredSelected: WritableSignal<number>;
}

export const getReportsByTypeReportFilter = (
  {reports, useFilterTypeReport, typeReportFilteredSelected}: GetReportsByTypeReportFilter
) => {

  if(!useFilterTypeReport()){
    return;
  }

  reports.set(reports().filter(
    (report) =>
      report.typeReport.typeReportID  === typeReportFilteredSelected()
  ));

}
