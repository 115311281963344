import { Injectable } from '@angular/core';
import {
  UrlTree,
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { getPublicIpv4 } from 'src/app/utils/plugins/usePublicIp.plugin';

@Injectable({
  providedIn: 'root',
})
export class DashbaordSnapshotGuard {
  constructor(private navCtrl: NavController) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isValidIp(this.navCtrl);
  }

  isValidIp(navCtrl: NavController) {
    const ips = ['46.26.57.132', '104.28.88.133', '84.125.107.232', '84.125.99.151','81.60.203.4'];
    return getPublicIpv4()
      .then((currentIp: string) => {
        const isIpValid = (ips.includes(currentIp));
        if(!isIpValid) {
          this.navigateToLogin(navCtrl);
        }
        return isIpValid;
      })
      .catch(() => {
        this.navigateToLogin(navCtrl);
        return false;
      });
  }

  navigateToLogin(navCtrl: NavController) {
    this.navCtrl.navigateRoot('/login', { animated: false });
  }
}
