// ==================== Editable New ====================
// only show new modal when it's true
export const SHOW_NEWS_REDIRECT: boolean = true;
// New Modal Identifier
export const SHOW_NEW_ID: number = 1;
// ======================================================

export const SHOW_NEW_NAME_STORAGE: string = 'NewInfo'

export const NEWS_ROUTES = [
  '/user/news/alliance',
  '/user/news/no-partner',
  '/user/news/partner'
]



