export interface QPayRoutesTranslates {
  transactionsQPay: string;
  terminalsQPay: string;
}

export const getQPayRoutes = (
  translates: QPayRoutesTranslates
): { route: string; name: string }[] => {
  return [
    { route: '/user/transactions-wipay', name: translates.transactionsQPay },
    { route: '/user/terminals-wipay', name: translates.terminalsQPay },
    // { route: '/user/dashboard-wipay/general', name: translates.transactionsQPay },
    // { route: '/user/check-balance-wipay', name: translates.transactionsQPay },
  ];
};
