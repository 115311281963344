import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegisteredFooterService {
  showFooterValue: boolean = false;

  filterChange: Subject<FilterRegisteredFooter> = new Subject<FilterRegisteredFooter>();

  setShowFooter(newShowFooterValue) {
    this.showFooterValue = newShowFooterValue;
    this.filterChange.next({ reset: this.showFooterValue });
  }

  getShowFooter() {
    return this.showFooterValue;
  }
}

interface FilterRegisteredFooter {
  reset?: boolean;
}
