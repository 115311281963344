import { lastValueFrom } from 'rxjs';

// models
import { CardNames, Company } from 'src/app/models';
import {
  StepCreateEditReport,
  ValidateExistCardsByReportParams,
} from '../../../../models';

// services
import {
  CardsByTextReportService,
  FilterReportService,
  ReportsService,
} from '../../../../services';

// utils
import { searchDuplicateNotRepeat, UtilsTranslate } from 'src/app/utils';
import { getCardsByTextReport } from '../../../get-cards-by-text-report';
import {
  getCardRepeatByTextTranslateReport,
  getCheckInvalidCardsTextTranslateReport,
} from '../../../translates';
import { getValidateExistCardsByReportParams } from './get-validate-exist-cards-by-report-params';

interface ValidateCardsTextReport {
  company: Company;
  _reportService: ReportsService;
  _filterReportService: FilterReportService;
  _cardsByTextReportService: CardsByTextReportService;
  showerStep: Record<StepCreateEditReport, boolean>;
  utils: UtilsTranslate;
}

export const validateCardsTextReport = async ({
  company,
  _reportService,
  _filterReportService,
  _cardsByTextReportService,
  showerStep,
  utils,
}: ValidateCardsTextReport) => {
  _cardsByTextReportService.invalidCardsByText.set({
    dupplicateCards: [],
    notExistCards: [],
    haveError: false,
  });

  _cardsByTextReportService.startedValidate();

  _cardsByTextReportService.setVisibility(_filterReportService);

  const cardsByTextCurrent = getCardsByTextReport(
    _cardsByTextReportService.cardsText()
  );
  _cardsByTextReportService.cardsByText.set(cardsByTextCurrent);

  if ((cardsByTextCurrent || []).length === 0) {
    utils.presentToastTranslate(
      company.nameCard === CardNames.arbitradePay
        ? 'reports.cards.card_required2'
        : 'reports.cards.card_required'
    );
    return;
  }

  const duplicates = searchDuplicateNotRepeat(cardsByTextCurrent);

  if (duplicates.length > 0) {
    _cardsByTextReportService.isValidCardText.set(false);

    const dupplicateCards = duplicates.sort((a, b) => a.localeCompare(b));

    _cardsByTextReportService.invalidCardsByText.set({
      dupplicateCards,
      notExistCards: [],
      haveError: false,
    });

    // const translateDuplicateCard = getCardRepeatByTextTranslateReport(
    //   company,
    //   duplicates.length
    // );

    // utils.presentToastWithVariable(
    //   translateDuplicateCard,
    //   'cards',
    //   duplicates.join(', '),
    //   true,
    //   5000
    // );
    return;
  }

  _cardsByTextReportService.loadingValidateCardText.set(true);

  try {
    const params: ValidateExistCardsByReportParams = getValidateExistCardsByReportParams(
      cardsByTextCurrent,
      _filterReportService
    );

    const res = await lastValueFrom(
      _reportService.validateExistCardsByReport(params)
    );

    if (!res) {
      utils.showError();
      _cardsByTextReportService.invalidCardsByText.set({
        dupplicateCards: [],
        notExistCards: [],
        haveError: true,
      });
      _cardsByTextReportService.loadingValidateCardText.set(false);
      _cardsByTextReportService.isValidCardText.set(false);
      return;
    }

    if ((res || []).length > 0) {
      // utils.presentToastWithVariable(
      //   getCheckInvalidCardsTextTranslateReport(company, res.length),
      //   'cards',
      //   res.join(', ')
      // );
      const notExistCards = res
        .map((invalidCard) => (invalidCard || '').toString())
        .sort((a, b) => a.localeCompare(b));
      _cardsByTextReportService.invalidCardsByText.set({
        dupplicateCards: [],
        notExistCards,
        haveError: true,
      });

      _cardsByTextReportService.loadingValidateCardText.set(false);
      _cardsByTextReportService.isValidCardText.set(false);

      return;
    }

    _cardsByTextReportService.isValidCardText.set(true);
    _cardsByTextReportService.loadingValidateCardText.set(false);
    if (!showerStep[StepCreateEditReport.Date]) {
      showerStep[StepCreateEditReport.Date] = true;
      showerStep[StepCreateEditReport.Emails] = true;
    }
  } catch (error) {
    _cardsByTextReportService.loadingValidateCardText.set(false);
    _cardsByTextReportService.invalidCardsByText.set({
      dupplicateCards: [],
      notExistCards: [],
      haveError: true,
    });
    _cardsByTextReportService.isValidCardText.set(false);
    utils.showError();
  }
};
