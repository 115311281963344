import { Component, Input, OnInit } from '@angular/core';

// Service
import { TranslateService } from '@ngx-translate/core';

// Models
import { Company } from '../../../../../../../models/';

@Component({
  selector: 'app-alert-select-operator',
  templateUrl: './alert-select-operator.component.html',
  styleUrls: ['./alert-select-operator.component.scss'],
})
export class AlertSelectOperatorComponent implements OnInit {

  @Input() company: Company = null;
  @Input() showSelectOperator: boolean = false;
  @Input() errorColor: boolean = false;

  constructor(private translate: TranslateService) { }

  ngOnInit() {}

}
