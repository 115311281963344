import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

// config
import { AppConfig } from 'src/app/config/config';

// models
import { ChangeRole, RoleMultiSelect } from '../models';

// utils
import { getIconRoutes } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class MultiLoginService {

  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
  ) { }

  getRoles(): Observable<RoleMultiSelect[]>{
    let endpoint = `multi-login/roles`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<RoleMultiSelect[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`
      )
      .pipe(
        map((res: RoleMultiSelect[]) => {
          res = getIconRoutes(res)
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  changeRole(roleId: Number): Observable<ChangeRole>{
    let endpoint = `multi-login/change-role/${roleId}`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<ChangeRole>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  haveMultiLoginGuard(){
    let endpoint = `multi-login/have-multi-login`;
    let routeUrl = this.router.url;

    return this.httpClient
    .get<boolean>(
      this.serviceUrl + endpoint + `?route=${routeUrl}`
    )
    .pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        let messageError = !error?.error?.error?.message
          ? '504 Gateway timeout'
          : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  haveMultiLoginGuardByToken(token: string): Observable<boolean>{
    let endpoint = `multi-login/have-multi-login`;
    let routeUrl = this.router.url;

    return this.httpClient
    .post<boolean>(
      this.serviceUrl + endpoint + `?route=${routeUrl}`, {token}
    )
    .pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        let messageError = !error?.error?.error?.message
          ? '504 Gateway timeout'
          : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }
}
