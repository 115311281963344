// services
import { StorageService } from 'src/app/share/services';

// models
import { ClientDashboardStorage, ClientDashboardWelcome } from '../../models';

// constant
import { NAME_STORAGE_CLIENT_DASHBOARD } from '..';

export const getClientStorage = async (
  _storageService: StorageService
): Promise<ClientDashboardStorage> => {
  try {
    const clientDashboard = await _storageService.get(NAME_STORAGE_CLIENT_DASHBOARD);
    if (clientDashboard) {
      return JSON.parse(clientDashboard) as ClientDashboardStorage;
    }
    return null;
  } catch (error) {
    await _storageService.set('clientDashboard', null);
    return null;
  }
};

export const setClientStorage = async (
  _storageService: StorageService,
  clientSelected: ClientDashboardWelcome
): Promise<void> => {
  if(!clientSelected){
    await _storageService.set(NAME_STORAGE_CLIENT_DASHBOARD, null )
    return;
  }

  const clientDashboard: ClientDashboardStorage = {
    clientId: clientSelected?.ID,
    haveAlliance: clientSelected?.haveAlliance,
    haveEnriqueTomas: clientSelected?.haveEnriqueTomas
  }

  await _storageService.set(NAME_STORAGE_CLIENT_DASHBOARD, JSON.stringify(clientDashboard) )

};

export const clearClientStorage = async (
  _storageService: StorageService
): Promise<void> => {
  setClientStorage(_storageService, null);
};
