import { Client } from 'src/app/models';

interface CheckClientTransactionQuery {
  operatorIds: Number[];
  clientId: Number;
  clients: Client[];
}

export const isValidClientSelectedTransactionQuery = ({
  operatorIds,
  clientId,
  clients,
}: CheckClientTransactionQuery) => {
  if (!clientId && clientId !== 0) {
    return true;
  }

  const operatorId = operatorIds?.[0] || null;

  if (!operatorId) {
    return true;
  }

  const clientSelected = clients.find((client) => client.ID === clientId);

  if (!clientSelected) {
    return false;
  }

  return clientSelected.OperatorId === operatorId;
};
