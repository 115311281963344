import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

// Config
import { PermissionService } from 'src/app/services';

// Services
import { AppConfig } from 'src/app/config/config';


@Injectable({
  providedIn: 'root'
})
export class ClientOrOperatorVisibilityGuard  {

  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private _permissionService: PermissionService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this._permissionService.clientOrOperatorVisibility();
  }

}
