import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { PermissionService } from 'src/app/services';

// Utils
import { NAME_HIGH_LEVEL_ROUTE, ROUTE_OPERATOR_GROUP_MANAGER } from '../utils';

@Injectable({ providedIn: 'root' })
export class OperatorGroupManagerResolverService  {
  constructor(private _permissionService: PermissionService) {}

  resolve(): Observable<boolean> {
    return this._permissionService.getAllPermissions().pipe(
      map((res) => {
        const haveHighLevel = res.find(
          (permission) => permission.route === ROUTE_OPERATOR_GROUP_MANAGER && permission.name === NAME_HIGH_LEVEL_ROUTE
        );
        return !!haveHighLevel;
      })
    );
  }
}
