import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Config
import { AppConfig } from '../../../../../../config/config';

// Service
import { CardService } from '../../../../../../share/services/card/card.service';

// Models
import { Updated } from '../../../../../../models/updated.model';

@Injectable({
  providedIn: 'root'
})
export class WebloadTransferService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
      private httpClient: HttpClient,
      protected router: Router,
      private _cardService: CardService) {}

  sendTransfer(cardId, webload, typeTransfer){

    let endpoint = `cards/webload/${typeTransfer}/${cardId}/${webload}`;
    let routeUrl = this.router.url;
    return this.httpClient.put<Updated>(this.serviceUrl + endpoint + `?route=${routeUrl}`, {}).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  getCardById(cardId: number){
    return this._cardService.getCardyById(cardId);
  }

 }
