import { Injectable, signal, WritableSignal } from '@angular/core';

// models
import {
  InvalidCardReport,
  VisibilityValidateCardByTextReport,
} from '../models';

// services
import { FilterReportService } from './filter-report.service';

// utils
import {
  isSamePreviouslyVisibilityAndReport,
  isSameTextCardReport,
  isSameVisibilityValidateReport,
  setVisibilityValidateCardByTextReport,
} from '../utils';

@Injectable({
  providedIn: 'root',
})
export class CardsByTextReportService {
  public cardsText: WritableSignal<string> = signal('');
  public cardsByText: WritableSignal<string[]> = signal([]);
  public isValidCardText: WritableSignal<boolean> = signal(false);
  public loadingValidateCardText: WritableSignal<boolean> = signal(false);
  public startedValidateCardText: WritableSignal<boolean> = signal(false);

  public invalidCardsByText: WritableSignal<InvalidCardReport> = signal({
    dupplicateCards: [],
    notExistCards: [],
    haveError: false,
  });
  public visibilityValidateCardText: WritableSignal<VisibilityValidateCardByTextReport> = signal(
    {
      operatorGroupId: null,
      operatorId: null,
    }
  );

  constructor() {}

  startedValidate() {
    if (!this.startedValidateCardText()) this.startedValidateCardText.set(true);
  }

  setVisibility(_filterReportService: FilterReportService) {
    setVisibilityValidateCardByTextReport({
      _filterReportService,
      visibilityValidateCardText: this.visibilityValidateCardText,
    });
  }

  isSameVisibility(_filterReportService: FilterReportService) {
    return isSamePreviouslyVisibilityAndReport({
      _filterReportService,
      visibilityValidateCardText: this.visibilityValidateCardText,
    });
  }

  isSameTextCard() {
    return isSameTextCardReport({
      cardsText: this.cardsText,
      cardsByText: this.cardsByText,
    });
  }

  setCards(cards: { id: number; cardNum: string }[], _filterReportService: FilterReportService){
    const cardsText: string[] = cards.map(card => card.cardNum);
    this.cardsByText.set(cardsText);
    this.cardsText.set(cardsText.join(' '))

    this.isValidCardText.set(true);
    this.loadingValidateCardText.set(false);
    this.startedValidateCardText.set(true);

    const reportVisibility: VisibilityValidateCardByTextReport = _filterReportService.getVisibilityCardText()
    this.visibilityValidateCardText.set(reportVisibility)
  }

  changeVisibility(_filterReportService: FilterReportService){
    const reportVisibility: VisibilityValidateCardByTextReport = _filterReportService.getVisibilityCardText();
    if(!isSameVisibilityValidateReport(this.visibilityValidateCardText,reportVisibility )){
      this.isValidCardText.set(false);
      this.loadingValidateCardText.set(false);
      this.startedValidateCardText.set(false);
      this.visibilityValidateCardText.set(reportVisibility)
    }
  }

  clearValidation(){
    this.cardsByText.set([]);
    this.isValidCardText.set(false);
    this.loadingValidateCardText.set(false);
    this.startedValidateCardText.set(false);
    this.invalidCardsByText.set(
      {
        dupplicateCards: [],
        notExistCards: [],
        haveError: false,
      }
    );
    this.visibilityValidateCardText.set({
      operatorGroupId: null,
      operatorId: null,
    })
  }

  reset() {
    this.cardsText = signal('');
    this.cardsByText = signal([]);
    this.isValidCardText = signal(false);
    this.loadingValidateCardText = signal(false);
    this.startedValidateCardText = signal(false);

    this.invalidCardsByText = signal({
      dupplicateCards: [],
      notExistCards: [],
      haveError: false,
    });
    this.visibilityValidateCardText = signal({
      operatorGroupId: null,
      operatorId: null,
      clientId: null,
    });
  }
}
