import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

// Service
import { SkinMangementService } from '../../../../../../../../services/skin-mangement.service';

// Models
import { Company } from '../../../../../../../../models/';

// Utils
import { pcWidthMix, pcWidth } from '../../../../../../../../utils';
import { MAX_INTERVAL_CARDS } from '../../../../utils';


@Component({
  selector: 'app-legend-register-card',
  templateUrl: './legend-register-card.component.html',
  styleUrls: ['./legend-register-card.component.scss'],
})
export class LegendRegisterCardComponent implements OnInit {

  company: Company;

  pcWidthMix = pcWidthMix;
  pcWidth = pcWidth;

  MAX_INTERVAL_CARDS = MAX_INTERVAL_CARDS;

  constructor(
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
