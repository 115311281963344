import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

// Service
import { TransactionsContentService } from './';
import { ArticleService } from '../../../../../share/services/';

// models
import {
  FilterTransation,
  checkSegments,
  Origins,
  ShowersPCTable,
  TransactionsParams,
} from '../models';
import { Family, FilterDateServiceI } from '../../../../../models';

// utils
import { regexEmnail } from 'src/app/utils';
import {
  LOAD,
  ONLINE,
  OTHER,
  BANK,
  CASH_LOADS,
  BANK_CARD_CHARGES,
} from '../utils';

@Injectable({
  providedIn: 'root',
})
export class FilterTransactionsQueryService implements FilterDateServiceI {
  online = ONLINE;
  load = LOAD;
  other = OTHER;
  bank = BANK;

  cashLoad = CASH_LOADS;
  bankCardCharges = BANK_CARD_CHARGES;

  isAena = false;

  // date - period
  checkSegment: checkSegments = 'other';
  dateStart = null;
  dateEnd = null;
  hourEnd = null;
  hourStart = null;

  // operator
  operatorsId: number[] = [];
  operatorText = '';

  // type of payment
  typePayment: { id: number; name: string }[] = [];
  textPaymentFilterTranslate = '';

  codeClient = '';
  nameClient = '';
  idClient: number;

  // machine
  codeMachine: string = '';
  codeStartMachine: string = '';

  // model
  model = '';

  // articles
  codeArticle = '';
  nameArticle = '';
  familyArticle = '';
  articleId = null;
  familyId = null;
  codeStartArticle = '';

  // pvp
  pvp = null;

  // card number
  cardNumber = '';
  cardId = null;

  bankCard = false;

  creditCard = '';

  // origins
  origin: Origins[] = ['Operador', 'Moneda', 'Loader'];
  originText: string = '';

  // email
  email: string = '';
  validEmail: boolean = true;

  // ticket aena
  numTicketAena: number = null;

  // paginator
  page = 0;

  // emite los cambios del filtro
  filterChange$: Subject<Object[]> = new Subject<Object[]>();

  // clear
  reset$: Subject<Object[]> = new Subject<Object[]>();

  // have tax
  changeHaveTax$: Subject<boolean> = new Subject<boolean>();

  // shower table
  showersTable: ShowersPCTable = {
    machine_model: true,
    machine_number: true,
    ticket: true,
    article: true,
    cardNumber: true,
    operator: false,
    status: false,
    client: false,
    date: true,
    details: true,
    origin: false,
    email: false,
    date_charge: false,
    ticketAena: false,
    filterOrigin: false,
    creditCard: false,
  };
  showOperator = false;
  showClient = false;

  isTotal = false;

  visibilityOperatorGroup: boolean = false;

  haveTax: boolean = true;

  constructor(
    private _articleService: ArticleService,
    private _transactionContent: TransactionsContentService,
    protected router: Router
  ) {}

  // date or period
  getCheckSegment() {
    return this.checkSegment;
  }

  setCheckSegment(newType: checkSegments) {
    this.checkSegment = newType;
    if (this.checkSegment == 'date') {
      this.filterChange$.next([
        { checkSegment: this.checkSegment },
        { dateStart: this.dateStart },
        { hourStart: this.hourStart },
        { hourEnd: this.hourEnd },
      ]);
    } else {
      // this.filterChange.next([{ checkSegment: this.checkSegment }, { dateStart: this.getDateStart() }, { dateEnd: this.getDateEnd() }]);
      this.filterChange$.next([
        { checkSegment: this.checkSegment },
        { dateStart: this.getDateStart() },
        { dateEnd: this.dateEnd },
      ]);
    }
  }

  getIsAena() {
    return this.isAena;
  }

  setIsAena(isAena) {
    this.isAena = isAena;
  }

  getDateStart(checkSegment = 'date') {
    if (!this.dateStart) {
      let dateFull = new Date();
      if (checkSegment === 'period') {
        dateFull = this.getYesterday();
      }
      this.dateStart = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;
      if (checkSegment === 'period') {
        this.filterChange$.next([{ dateStart: this.dateStart }]);
      }
    } else if (this.checkSegment === 'period') {
      let dateFullSeparated = this.dateStart.split('-');
      let dateFull = new Date(
        dateFullSeparated[0],
        dateFullSeparated[1] - 1,
        dateFullSeparated[2]
      );
      //console.log('diff', dateFull, 'ayer', this.getYesterday(), 'difference', dateFull > this.getYesterday())
      if (dateFull > this.getYesterday()) {
        dateFull = this.getYesterday();
        this.dateStart = `${dateFull.getFullYear()}-${
          dateFull.getMonth() + 1
        }-${dateFull.getDate()}`;
      }
    }

    // console.log('data start', this.dateStart);
    return this.dateStart;
  }

  getDateEnd() {
    if (!this.dateEnd) {
      // let dateFull = new Date();
      let dateFull = this.getYesterday();
      this.dateEnd = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;
    }
    return this.dateEnd;
  }

  getYesterday() {
    // console.log('yesteraday', new Date(new Date().getTime() - 24*60*60*1000))
    return new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  }

  // TODO cambio hora
  // datePos= 0 -> date, period pos 1 - datePos= 1 -> period pos 2
  setDate(dateNew, datePos) {
    // console.log('dateNew', dateNew, 'pos' , datePos)
    let dateFull = dateNew;

    // TODO solo para testear
    if (!(dateNew instanceof Date)) {
      dateFull = dateNew ? new Date(dateNew) : new Date();
    }

    let userTimezoneOffset = Math.trunc(dateFull.getTimezoneOffset() / 60);
    dateFull = new Date(
      dateFull.setHours(dateFull.getHours() - userTimezoneOffset)
    );

    // dateFull = new Date(dateFull.setHours(dateFull.getHours() + 2 ));

    if (datePos == 0) {
      this.dateStart = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;
      this.filterChange$.next([{ dateStart: this.dateStart }]);
    } else {
      this.dateEnd = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;
      this.filterChange$.next([{ dateEnd: this.dateEnd }]);
    }
  }

  getStartHour() {
    if (!this.hourStart) {
      this.hourStart = `00:00`;
    }
    return this.hourStart;
  }

  setHour(hour, posHour) {
    if (posHour == 0) {
      this.hourStart = hour;
      this.filterChange$.next([{ hourStart: this.hourStart }]);
    } else {
      this.hourEnd = hour;
      this.filterChange$.next([{ hourEnd: this.hourEnd }]);
    }
  }

  getEndHour() {
    if (!this.hourEnd) {
      this.hourEnd = '24:00';
    }
    return this.hourEnd;
  }

  // operators
  getOperatorIds() {
    return this.operatorsId;
  }

  getTextOperator() {
    return this.operatorText;
  }

  setOperators(operatorsIdValid: number[], operatorText: string) {
    this.operatorsId = operatorsIdValid;
    this.operatorText = operatorText;
    this.filterChange$.next([
      { operatorsId: this.operatorsId },
      { operatorText: this.operatorText },
    ]);
  }

  deleteOperator() {}

  // clients
  getNameClient() {
    return this.nameClient;
  }

  getClientId() {
    return this.idClient;
  }

  getCodeClient() {
    return this.codeClient;
  }

  setClient(name: string, code: string, id: number) {
    this.nameClient = name;
    this.codeClient = code;
    this.idClient = id;
    this.filterChange$.next([
      { nameClient: this.nameClient },
      { codeClient: this.codeClient },
    ]);
  }

  // machine
  getMachineNumber() {
    return this.codeMachine;
  }

  getCodeStartMachine() {
    return this.codeStartMachine;
  }

  setMachine(code: string) {
    this.codeMachine = code;
    this.codeStartMachine = '';
    this.filterChange$.next([
      { codeMachine: this.codeMachine },
      { codeStartMachine: this.codeStartMachine },
    ]);
  }

  setCodeStartMachine(codeStartMachine: string) {
    this.codeMachine = '';
    this.codeStartMachine = codeStartMachine;
    this.filterChange$.next([
      { codeMachine: this.codeMachine },
      { codeStartMachine: this.codeStartMachine },
    ]);
  }

  getModel() {
    return this.model;
  }

  setModel(model: string) {
    this.model = model;
    this.filterChange$.next([{ model: this.model }]);
  }

  // Aena
  getNumTicketAena() {
    return this.numTicketAena;
  }

  setNumTicketAena(numTicketAena: number) {
    this.numTicketAena = numTicketAena;
    this.filterChange$.next([{ ticketAena: this.numTicketAena }]);
  }

  // payments
  getTypePayment() {
    return this.typePayment;
  }

  getTextTypePayment() {
    return this.textPaymentFilterTranslate;
  }

  setTextTypePayment(textTranslate: string) {
    this.textPaymentFilterTranslate = textTranslate;
    this.filterChange$.next([{ typePayment: this.typePayment, textTranslate }]);
  }

  // si cambia de filtro se modifica el resto de filtros
  setTypePayment(
    payments: { id: number; name: string }[],
    textTranslate: string
  ) {
    //console.log('paymment', payments)

    if (this.cardNumber) {
      const includeCreditCard = payments.some(
        (payment) => payment.name === 'Tarjeta bancaria'
      );
      const includeCard = payments.some(
        (payment) =>
          payment.name === 'Tarjeta cautiva bonificada' ||
          payment.name === 'Tarjeta bancaria' ||
          payment.name === 'Carga de saldo' ||
          payment.name === 'Otra'
      );
      if (!includeCreditCard && !includeCard) {
        this.setCardNum('', null);
      }

      if (!includeCreditCard && this.cardNumber.includes('*')) {
        this.setCardNum('', null);
      }

      if (includeCreditCard && !this.cardNumber.includes('*')) {
        this.setCardNum('', null);
      }
    }

    if (this.creditCard) {
      const includeCreditCard = payments.some(
        (payment) => payment.name === BANK_CARD_CHARGES
      );

      if (!includeCreditCard) {
        this.setCreditCard('');
      }
    }

    const haveBasic = payments.some(
      (payment) =>
        payment.name === 'Tarjeta bancaria' ||
        payment.name === 'Venta gratuita' ||
        payment.name === 'Tarjeta cautiva' ||
        payment.name === 'Tarjeta cautiva bonificada' ||
        payment.name === 'Venta gratuita'
    );

    // TODO actualizar para la version extendida
    const haveOther = payments.some((payment) => payment.name === 'Otra');
    const haveload = payments.some(
      (payment) => payment.name === 'Carga de saldo'
    );

    // if(haveBasic){
    //   this.setOrigin([], '');
    //   this.setEmail('')
    // }

    if (!haveBasic) {
      this.setModel('');

      this.setPVP(null);
      // this.setEmail('')
      this.deleteArticle();
      // this.setOrigin([], '');

      const haveLoadPayment = payments.some(
        (payment) =>
          payment.name === CASH_LOADS || payment.name === BANK_CARD_CHARGES
      );
      if (!haveLoadPayment) {
        this.setMachine(null);
      }
    }

    this.setOrigin([], '');
    this.setEmail('');

    // if( !includeCreditCard && !includeCard){
    //     this.setCardNum('', null)
    // }

    // if(!includeCreditCard &&  this.cardNumber.includes('*')){
    //   this.setCardNum('', null)
    // }
    // if(payments.some(payment => payment.name === 'Tarjeta bancaria') && !this.cardNumber.includes('*'))

    // borrar filtros cliente
    // if ((!(payments.some(payment => payment.name === 'Tarjeta cautiva'))
    //   && !(payments.some(payment => payment.name === 'Tarjeta cautiva bonificada'))
    //   || this.cardNumber.includes('*'))
    // ) {
    //   this.setCardNum('', null)
    // }

    // eliminar filtros
    // this.typePayment.length == 0
    //   ||
    // if ( !(this.typePayment.every(payment => payments.some(paymentInput => paymentInput.name === payment.name)))) {
    //   console.log('borrar')
    //   this.setModel('')
    //   this.setMachine(null)
    //   this.setPVP(null)
    //   this.setEmail('')
    //   this.deleteArticle()
    //   this.setOrigin([], '');

    // }

    this._transactionContent.reset();

    this.typePayment = payments;
    this.textPaymentFilterTranslate = textTranslate;
    let { article: showArticle } = this.getShowTablePc();
    this._transactionContent.setValidType(showArticle);
    //console.log('text translate',textPayment );
    this.filterChange$.next([{ typePayment: this.typePayment, textTranslate }]);
  }

  // columnas tablas a partir de la visiblidad
  setVisibility(showOperator, showClient) {
    this.showOperator = showOperator;
    this.showClient = showClient;
    // console.log('visibility', this.showOperator, this.showClient )
  }

  getShowTablePc() {
    this.showersTable = {
      machine_model: true,
      machine_number: true,
      ticket: true,
      article: true,
      cardNumber: true,
      operator: this.showOperator,
      status: false,
      client: this.showClient,
      date: true,
      details: true,
      origin: false,
      email: false,
      date_charge: false,
      ticketAena: this.isAena,
      filterOrigin: false,
      creditCard: false,
    };

    if (this.typePayment.length === 0 || !this.typePayment) {
      return this.showersTable;
    }

    const posCash = this.typePayment.findIndex(
      (payment) => payment.name === 'Efectivo'
    );
    const posFree = this.typePayment.findIndex(
      (payment) => payment.name === 'Venta gratuita'
    );

    // efectivo o venta gratuita no muestra cardnums
    if (
      (posCash !== -1 && this.typePayment.length === 1) ||
      (posFree !== -1 && this.typePayment.length === 1) ||
      (posCash !== -1 && posFree !== -1 && this.typePayment.length === 2)
    ) {
      this.showersTable.cardNumber = false;
    } else if (this.typePayment.some((payment) => payment.name === 'Otra')) {
      this.showersTable.operator = false;
      this.showersTable.article = false;
      this.showersTable.ticket = false;
      this.showersTable.details = false;
    } else if (this.typePayment.some((payment) => payment.name === this.load)) {
      this.showersTable.article = false;
      this.showersTable.ticket = false;
      this.showersTable.origin = true;
      this.showersTable.machine_model = false;
      this.showersTable.filterOrigin = true;

      if (this.origin.length === 1 && this.origin.includes('Operador')) {
        this.showersTable.machine_number = false;
        this.showersTable.client = false;
        this.showersTable.details = false;
      }

      if (this.origin.includes('Operador')) {
        this.showersTable.date_charge = true;
      }
    } else if (
      this.typePayment.some(
        (payment) => payment.name.toLowerCase() === this.online.toLowerCase()
      )
    ) {
      this.showersTable.article = false;
      this.showersTable.ticket = false;
      this.showersTable.machine_model = false;
      this.showersTable.status = true;
      this.showersTable.client = false;
      this.showersTable.date = false;
      this.showersTable.email = true;
      this.showersTable.date_charge = true;
      this.showersTable.machine_number = false;
      this.showersTable.details = false;
    }

    if (
      this.typePayment.some(
        (payment) => payment.name.toLowerCase() === this.cashLoad.toLowerCase()
      ) ||
      this.typePayment.some(
        (payment) =>
          payment.name.toLowerCase() === this.bankCardCharges.toLowerCase()
      )
    ) {
      this.showersTable.origin = false;
      this.showersTable.article = false;
      this.showersTable.ticket = false;
      this.showersTable.machine_model = false;
      this.showersTable.date_charge = false;

      if (
        this.typePayment.some(
          (payment) =>
            payment.name.toLowerCase() === this.bankCardCharges.toLowerCase()
        )
      ) {
        this.showersTable.creditCard = true;
      }

      if (
        this.typePayment.some(
          (payment) =>
            payment.name.toLowerCase() === this.cashLoad.toLowerCase()
        ) &&
        this.typePayment.some(
          (payment) =>
            payment.name.toLowerCase() === this.bankCardCharges.toLowerCase()
        )
      ) {
        this.showersTable.origin = true;
        this.showersTable.filterOrigin = false;
      }
    }

    if (this.isAena) {
      this.showersTable.machine_model = false;
    }

    return this.showersTable;
  }

  getOrigins(): Origins[] {
    return this.origin;
  }

  // Carga de saldo tipo
  getOriginText() {
    return this.originText;
  }

  setOrigin(origins: Origins[], originText: string) {
    if (origins.length === 0 || !origins) {
      origins = ['Operador', 'Moneda', 'Loader'];
    }
    this.origin = origins;
    this.originText = originText;
    this.filterChange$.next([{ origins }, { originText }]);
  }

  changeOriginLanguage(originLanguage, allTranslate) {
    this.originText =
      this.origin.length === 3
        ? allTranslate
        : this.origin.map((origin) => originLanguage[origin]).join(',');
    this.filterChange$.next([{ originText: this.originText }]);
  }

  // articles
  getFamily() {
    return this.familyArticle;
  }

  getCodeArticle() {
    return this.codeArticle;
  }

  getNameArticle() {
    return this.nameArticle;
  }

  getCodeStartArticle() {
    return this.codeStartArticle;
  }

  setFamily(newFamily: Family) {
    this.familyArticle = newFamily.description;
    this.familyId = newFamily.id;
    this.codeArticle = '';
    this.nameArticle = '';
    this.articleId = null;

    this.filterChange$.next([
      { familyArticle: this.familyArticle },
      { codeArticle: '' },
      { nameArticle: '' },
    ]);
  }

  clearArticle() {
    this.familyArticle = '';
    this.familyId = null;
    this.codeArticle = '';
    this.nameArticle = '';
    this.articleId = null;

    this.filterChange$.next([
      { familyArticle: this.familyArticle },
      { codeArticle: '' },
      { nameArticle: '' },
    ]);
  }

  setArticle(code: string, name: string, idArticle: number, familyId: number) {
    this.codeArticle = code;
    this.nameArticle = name;
    this.articleId = idArticle;
    this.codeStartArticle = '';
    this.filterChange$.next([
      { codeArticle: this.codeArticle },
      { nameArticle: this.nameArticle },
      { codeStartArticle: this.codeStartArticle },
    ]);

    // this._articleService.getFamilyById(familyId)
    //   .subscribe((res: Family) => {
    //     this.familyArticle = res.description;
    //     this.filterChange.next([{ familyArticle: this.familyArticle }]);
    //   });
    // para poder utilizar el filter en el transactionService
    // this._transactionSevice.getFamilyById(familyId).subscribe((res: Family) => {
    //   this.familyArticle = res.description;
    //   this.filterChange.next([{ familyArticle: this.familyArticle }]);
    // });

    this._articleService.getFamilyById(familyId).subscribe((res: Family) => {
      this.familyArticle = res.description;
      this.filterChange$.next([{ familyArticle: this.familyArticle }]);
    });

    this._articleService.getFamilies();
  }

  deleteArticle() {
    this.codeArticle = '';
    this.nameArticle = '';
    this.familyArticle = '';
    this.articleId = null;
    this.familyId = null;

    this.filterChange$.next([
      { codeArticle: this.codeArticle },
      { nameArticle: this.nameArticle },
      { familyArticle: this.familyArticle },
      { codeStartArticle: this.codeStartArticle },
    ]);
  }

  setCodeStartArticle(codeStartArticle: string) {
    this.deleteArticle();
    this.codeStartArticle = codeStartArticle;
    this.filterChange$.next([{ codeStartArticle: this.codeStartArticle }]);
  }

  // PVP
  getPVP() {
    return this.pvp;
  }

  setPVP(newPVP) {
    this.pvp = newPVP;
    this.filterChange$.next([{ pvp: this.pvp }]);
  }

  // cardNum
  getCardNum() {
    if (this.bankCard) {
      return this.cardNumber;
    } else {
      return this.cardNumber;
    }
  }

  getOnlyCardNum() {
    if (this.bankCard) {
      return '';
    } else {
      return this.cardNumber;
    }
  }

  getBankCard() {
    if (this.bankCard) {
      return this.cardNumber;
    } else {
      return '';
    }
  }

  setCardNum(cardNum: string, id: number) {
    this.cardNumber = cardNum;
    this.cardId = id;
    this.bankCard = false;
    this.filterChange$.next([{ cardNumber: this.cardNumber }]);
  }

  // el typepayment tiene la opcion de tarjeta bancaria
  getBankCardOption() {
    // return this.typePayment.includes("1");
    return this.typePayment.some(
      (typePayment) => typePayment.name === this.bank
    );
  }

  // el typepayment tiene alguna opcion de cardnum
  getCardNumOption() {
    // return this.typePayment.includes("0") || this.typePayment.includes("2") || this.typePayment.includes("3")

    //   || this.typePayment.includes("4") || this.typePayment.includes("5");

    return (
      (this.typePayment.some((typePayment) => typePayment.name === this.bank) &&
        this.typePayment.length > 1) ||
      (this.typePayment.some((typePayment) => typePayment.name !== this.bank) &&
        this.typePayment.length > 0)
    );
  }

  setBankCard(cardNum) {
    this.cardNumber = cardNum;
    this.cardId = null;
    this.bankCard = true;
    this.filterChange$.next([{ cardNumber: this.cardNumber }]);
  }

  setPage(page: number) {
    this.page = page;
  }

  getEmail(): string {
    return this.email;
  }

  getValidEmail(): boolean {
    return this.validEmail;
  }

  setEmail(email: string) {
    this.email = email;
    this.validEmail = regexEmnail.test(this.email) || this.email === '';
    this.filterChange$.next([
      { email: this.email, validEmail: this.validEmail },
    ]);
  }

  getAllFilter() {
    let filterTransaction: FilterTransation = {};
    filterTransaction.checkSegment = this.checkSegment;
    filterTransaction.dateStart = this.dateStart;

    // console.log('checkment', this.checkSegment)

    if (this.checkSegment == 'date') {
      filterTransaction.hourStart = this.hourStart;
      filterTransaction.hourEnd = this.hourEnd;
    } else {
      filterTransaction.dateEnd = this.dateEnd;
    }

    // TODO
    // filterTransaction.typePayment = this.typePayment;
    filterTransaction.typePayment = this.typePayment.map((type) =>
      (type?.id).toString()
    );

    filterTransaction.codeClient = this.codeClient;
    filterTransaction.nameClient = this.nameClient;
    filterTransaction.codeMachine = this.codeMachine;
    filterTransaction.model = this.model;
    filterTransaction.codeArticle = this.codeArticle;
    filterTransaction.nameArticle = this.nameArticle;
    filterTransaction.familyArticle = this.familyArticle;
    filterTransaction.pvp = this.pvp;
    filterTransaction.cardNumber = this.cardNumber;
    filterTransaction.codeStartMachine = this.codeStartMachine;

    return filterTransaction;
  }

  // get body para el service
  getInputs() {
    let params: TransactionsParams = {};

    // type of payment
    // let paymentsFilter = [];
    // if (paymentDB.length >= 6) {
    //   for (let i in paymentDB) {
    //     if (this.typePayment.includes(i.toString())) {
    //       paymentsFilter.push(paymentDB[i]);
    //     }
    //   }
    // }
    // params['Tipo_pago'] = paymentsFilter;

    params['Tipo_pago'] = this.typePayment.map((type) => (type?.id).toString());
    if (this.typePayment.some((type) => type.name === this.load)) {
      params['origins'] = this.origin;
    }

    // operators
    // || this.operatorsId.length != 1
    if (
      !this.typePayment.some((payment) => payment.name === this.other) &&
      !this.operatorsId.includes(-1) &&
      this.operatorsId.length > 0
    ) {
      params['operator_ids'] = this.operatorsId;
    }

    // clients
    if (this.idClient) {
      params['clientIds'] = [this.idClient];
    }

    // machine number
    if (this.codeMachine) {
      params['machine_codes'] = [this.codeMachine];
    } else if (this.codeStartMachine) {
      params['machine_code'] = [
        this.codeStartMachine.substring(0, this.codeStartMachine.length - 1),
      ];
    }

    // machine model
    if (this.model) {
      params['machine_models'] = [this.model];
    }

    // articles
    if (this.articleId) {
      params['articleIds'] = [this.articleId];
    } else if (this.familyId) {
      // filter family
      params['articleFamilyIDs'] = [this.familyId];
    } else if (this.codeStartArticle) {
      params['articleCode'] = this.codeStartArticle.substring(
        0,
        this.codeStartArticle.length - 1
      );
    }

    // pvp
    if (this.pvp) {
      params['pvps'] = [this.pvp];
    }

    // card number
    if (this.cardNumber) {
      params['cardNums'] = [this.cardNumber];
      // params['cardNum'] = this.cardNumber;
      // params['bankCard']= this.bankCard;
    }

    if (this.creditCard) {
      params['creditCards'] = [this.creditCard];
    }

    // date
    // params['typeDate'] =  this.checkSegment;
    // if(this.checkSegment == 'date'){
    //   params['dateStart'] = this.dateStart;
    //   params['hourStart'] = this.hourStart;
    //   params['hourEnd'] = this.hourEnd;
    // }else{
    //   params['dateStart'] = this.dateStart;
    //   params['dateEnd'] = this.dateEnd;
    // }

    if (
      this.email &&
      this.validEmail &&
      this.typePayment.some(
        (payment) => payment.name.toLowerCase() === this.online.toLowerCase()
      )
    ) {
      params['email'] = this.email;
    }

    if (this.checkSegment == 'date' || this.checkSegment === 'other') {
      params['dateStart'] = this.dateStart;
      params['dateEnd'] = this.dateStart;
      params['hourStart'] =
        this.hourStart != '24:00' ? this.hourStart + ':00' : '23:59:59';
      params['hourEnd'] =
        this.hourEnd != '24:00' ? this.hourEnd + ':00' : '23:59:59';
    } else {
      params['dateStart'] = this.dateStart;
      params['dateEnd'] = this.dateEnd;
      params['hourStart'] =
        this.hourStart != '24:00' ? this.hourStart + ':00' : '23:59:59';
      params['hourEnd'] =
        this.hourEnd != '24:00' ? this.hourEnd + ':00' : '23:59:59';
    }

    params['fetch'] = 10;
    params['offset'] = this.page * 10;

    // page
    if (this.page) {
      params['page'] = this.page;
    }

    if (this.numTicketAena) {
      params['num_ticket_AENA'] = this.numTicketAena;
    }

    return params;
  }

  clearFilter() {
    this.setOrigin([], '');

    this.checkSegment = 'date';
    this.dateStart = null;
    this.dateEnd = null;
    this.hourEnd = null;
    this.hourStart = null;

    // this.dateStartText = null;
    // this.dateEndText = null;

    // operator
    this.operatorsId = [];
    this.operatorText = '';

    // type of payment
    this.typePayment = [];
    this.textPaymentFilterTranslate = '';

    this.codeClient = '';
    this.nameClient = '';
    this.idClient = null;

    // machine
    this.codeMachine = '';
    this.codeStartMachine = '';

    // model
    this.model = '';

    // articles
    this.codeArticle = '';
    this.nameArticle = '';
    this.familyArticle = '';
    this.articleId = null;
    this.familyId = null;
    this.codeStartArticle = '';

    // pvp
    this.pvp = null;

    // card number
    this.cardNumber = '';
    this.cardId = null;
    // si es tarjeta bancaria (cardNum tendra los ultimos 4 numeros)
    this.bankCard = false;

    this.creditCard = '';

    // paginator
    this.page = 0;

    (this.email = ''), (this.validEmail = true);

    // ticket aena
    this.numTicketAena = null;

    // reset Date/Period
    this.checkSegment = 'date';
    let dateFull = new Date();
    this.dateStart = `${dateFull.getFullYear()}-${
      dateFull.getMonth() + 1
    }-${dateFull.getDate()}`;
    this.dateEnd = `${dateFull.getFullYear()}-${
      dateFull.getMonth() + 1
    }-${dateFull.getDate()}`;
    this.reset$.next([true]);
    this.filterChange$.next([
      { checkSegment: this.checkSegment },
      { dateStart: this.dateStart },
      { hourStart: this.hourStart },
      { hourEnd: this.hourEnd },
      { dateEnd: this.dateEnd },
      { typePayment: this.typePayment },
      { operatorsId: this.operatorsId },
      { operatorText: this.operatorText },
      { nameClient: this.nameClient },
      { codeClient: this.codeClient },
      { codeMachine: this.codeMachine },
      { model: this.model },
      { codeArticle: this.codeArticle },
      { nameArticle: this.nameArticle },
      { familyArticle: this.familyArticle },
      { pvp: this.pvp },
      { cardNumber: this.cardNumber },
      { email: this.email },
      { codeStartArticle: this.codeStartArticle },
      { codeStartMachine: this.codeStartMachine },
      { ticketAena: this.numTicketAena },
      { creditCard: this.creditCard },
    ]);
  }

  getIsTotal() {
    return this.isTotal;
  }

  setIsTotal(total: boolean) {
    this.isTotal = total;
  }

  getVisibilityOperatorGroup() {
    return this.visibilityOperatorGroup;
  }

  setVisibilityOperatorGroup(visibilityOperatorGroup: boolean) {
    this.visibilityOperatorGroup = visibilityOperatorGroup;
  }

  applyMaxPeriod(maxPeriodDate: Date) {
    if (this.checkSegment !== 'period') {
      return;
    }

    // console.log('dateStart', this.dateStart)
    // console.log('dateEnd', this.dateEnd)
    // console.log('maxPeriodDate', maxPeriodDate )

    // console.log('diff', new Date(this.dateStart.toString().replace(/-/g, "/")).getTime() - maxPeriodDate.getTime())

    // if(new Date(this.dateStart.toString().replace(/-/g, "/")).getTime() - maxPeriodDate.getTime() > 0){
    //    console.log('max start')
    //    isApplied = true;
    // }

    // if(this.dateEnd && new Date(this.dateEnd.toString().replace(/-/g, "/")).getTime() - maxPeriodDate.getTime() > 0){
    //   console.log('max end')
    //   isApplied = true;
    // }
  }

  getCreditCard() {
    return this.creditCard;
  }

  setCreditCard(creditCard: string) {
    this.creditCard = creditCard;
    this.filterChange$.next([{ creditCard: this.creditCard }]);
  }

  getHaveTax() {
    return this.haveTax;
  }

  setHaveTax() {
    const payments = this.getTypePayment();

    this.haveTax = !payments.some(
      (payment) =>
        payment.name === CASH_LOADS || payment.name === BANK_CARD_CHARGES
    );

    this.changeHaveTax$.next(this.haveTax);
  }
}
