import { addTimeDate, removeTime } from 'src/app/utils';

export const getMinDateAndMaxDateMachineAlert = (): {
  minDate: Date;
  maxDate: Date;
} => {
  const currentDate = removeTime(new Date());
  const minDate = addTimeDate({
    startDate: currentDate,
    typeIncrement: 'days',
    increment: 1,
  });
  const maxDate = addTimeDate({
    startDate: currentDate,
    typeIncrement: 'years',
    increment: 100,
  });

  return { minDate, maxDate };
};
