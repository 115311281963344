import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, WritableSignal } from '@angular/core';
import { Platform } from '@ionic/angular';

// company
import { Company } from 'src/app/models';
import { TypeIconCardDropDown } from './models';

// utils
import { pcWidth } from 'src/app/utils';

@Component({
  selector: 'app-card-with-title-dropdown-template',
  templateUrl: './card-with-title-dropdown-template.component.html',
  styleUrls: ['./card-with-title-dropdown-template.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.1s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.4s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CardWithTitleDropdownTemplateComponent implements OnInit {

  @Input({required: true}) company: Company;
  @Input({required: true}) isLightColor: boolean = true;
  @Input({required: true}) showContent: WritableSignal<boolean>;

  @Input() title: string = '';
  @Input() titleHTML: string = '';

  @Input() typeIcon: TypeIconCardDropDown = TypeIconCardDropDown.arrow;

  pcWidth = pcWidth;

  TypeIconCardDropDown = TypeIconCardDropDown;

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}

  changeShowContent(){
    this.showContent.set(!this.showContent())
  }

}
