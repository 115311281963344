import { Component, Input, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

// service
import { ColorModeService } from '../../../../../../../services/color-mode.service';

@Component({
  selector: 'app-area-table-aena-check',
  templateUrl: './area-table-aena-check.component.html',
  styleUrls: ['./area-table-aena-check.component.scss'],
})
export class AreaTableAenaCheckComponent implements OnInit {

  @Input() areaText: string = ''
  @Input() isCheck = false;

  isLightColor: boolean = true;
  changeColor$: Subscription;

  constructor(
    private _colorModeService: ColorModeService
  ) { }

  ngOnInit() {
    this.isLightColor = this._colorModeService.getIsLightColor()
    this.changeColor$ = this._colorModeService.changeColor$.subscribe(isLightColor => this.isLightColor = isLightColor )
  }

  ngOnDestroy(){
    if(this.changeColor$) this.changeColor$.unsubscribe()
  }

}
