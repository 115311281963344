export * from 'src/app/pages/user/dashboards/dashboard-refilling/resolve/have-client-management.resolve.service';

export * from './operator-group.resolver.service';
export * from './operator.revolver.service';
export * from './visibility.resolver.service';
export * from './permission-dashboard.resolver.service';

export * from 'src/app/pages/user/QR/generate-qr/resolvers/generate-qr.resolver.service';
export * from 'src/app/pages/user/aena/send-files-aena/resolvers/send-files-aena.resolver.service';
export * from 'src/app/pages/user/application-management/notification-manager/resolvers/notification-manager-api.resolver.service';
export * from 'src/app/pages/user/cards/register-cards/resolvers/register-cards.resolver.service';
export * from 'src/app/pages/user/cards/subsidies/resolvers/subsidies.resolver.service';
export * from 'src/app/pages/user/machines/till-mapping/resolvers/till-mapping.resolver.service';
export * from 'src/app/pages/user/online-shop/online-shop/resolvers/online-shop.resolver.service';
export * from 'src/app/pages/user/route-management/sales-module/resolvers';
export * from 'src/app/pages/user/billing/turn-over/resolvers/turn-over.resolver.service';
export * from 'src/app/pages/user/machines/inventory-manager/resolvers/inventory-manager.resolver.service';

export * from '../../pages/user/machines/site-elora/resolver';

