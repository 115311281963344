// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { HeaderModalModule } from '../header-modal/header-modal.modal';

// Component
import { FilterCardInputComponent } from './filter-card-input';


// Service
import { LanguageService } from '../../services/languages.service';
import { SkinMangementService } from 'src/app/services';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule,
        HttpClientModule,
        HeaderModalModule
    ],
    declarations: [
      FilterCardInputComponent
    ],
    providers: [
        LanguageService,
        SkinMangementService,
    ],
    exports: [
      FilterCardInputComponent
    ]
})

export class FilterCardInputModule { }
