// Bar Color Month
export const BAR_DEFAULT_MONTH: string[] = [
  '#fd9fb2',
  '#84c5f0',
  '#fde097',
  '#4F9E4F',
  '#91d7d6',
  '#5B5D43',
  '#35A2D8',
  '#f88e8f',
  '#8ed7d4',
  '#fcd09a',
  '#2F4538',
  '#92969d',
  '#64a5aa',
  '#A2231D',
  '#EFA94A',
  '#45322E',
  '#308446',
  '#84C3BE',
  '#4C514A',
  '#999950',
  '#FAD201',
  '#C35831',
  '#bdc3cd',
  '#1E2460',
  '#1E5945',
  '#641C34',
  '#343B29',
  '#1D1E33',
  '#317F43',
  '#B32428',
  '#20214F',
];

export const BORDER_DEFAULT_COLOR_MONTH: string[] = [
  '#B77381',
  '#56819D',
  '#A69363',
  '#3D7B3D',
  '#73ABAA',
  '#55573E',
  '#2A80AB',
  '#C57171',
  '#71ABA8',
  '#D1AC7F',
  '#2A3E32',
  '#74777D',
  '#64a5aa',
  '#6F0000',
  '#AD7A36',
  '#322421',
  '#2B763F',
  '#608D8A',
  '#444942',
  '#79793F',
  '#CFAE01',
  '#AF4F2C',
  '#969BA3',
  '#1C2259',
  '#1B503E',
  '#5D1A30',
  '#2B3122',
  '#1A1B2E',
  '#2E763E',
  '#A72225',
  '#1D1E47',
];

export const BAR_DEFAULT_MONTH_DARK: string[] = [
  '#fd9fb2',
  '#84c5f0',
  '#fde097',
  '#4F9E4F',
  '#1E2460',
  '#45322E',
  '#e8e8e7',
  '#f88e8f',
  '#8ed7d4',
  '#fcd09a',
  '#bdc3cd',
  '#308446',
  '#64a5aa',
  '#A2231D',
  '#EFA94A',
  '#bfd4de',
  '#343B29',
  '#84C3BE',
  '#4C514A',
  '#999950',
  '#FAD201',
  '#C35831',
  '#2F4538',
  '#91d7d6',
  '#1E5945',
  '#641C34',
  '#92969d',
  '#1D1E33',
  '#317F43',
  '#B32428',
  '#20214F',
];

export const BORDER_DEFAULT_COLOR_MONTH_DARK: string[] = [
  '#B77381',
  '#56819D',
  '#A69363',
  '#3D7B3D',
  '#1B2056',
  '#322421',
  '#C8C8C7',
  '#C57171',
  '#71ABA8',
  '#D1AC7F',
  '#969BA3',
  '#286D3A',
  '#64a5aa',
  '#6F0000',
  '#AD7A36',
  '#707C82',
  '#2B3122',
  '#608D8A',
  '#444942',
  '#79793F',
  '#CFAE01',
  '#AF4F2C',
  '#2A3E32',
  '#73ABAA',
  '#1B503E',
  '#5D1A30',
  '#74777D',
  '#1A1B2E',
  '#2E763E',
  '#A72225',
  '#1D1E47',
];
