// models
import { UserVisibility } from 'src/app/models';
import { MachineAlertShowInfo, UpdateMachineAlertParams } from '../../models';

// utils
import { getResendMachineAlert } from '../resend';
import { getDateValidMachineAlert } from '../date/get-date-valid-machine-alert';
import { getWeekdayByAlertInfoMachineAlert } from '../weekdays/get-week-day-by-alert-info-machine-alert';

interface GetUpdateMachineAlertParams {
  alert: MachineAlertShowInfo;
  visibilities: UserVisibility;
  changeEnable?: boolean;
}

export const getUpdateStatusMachineAlertParams = ({
  alert,
  visibilities,
  changeEnable = false,
}: GetUpdateMachineAlertParams): UpdateMachineAlertParams => {

  const enable: number =  getEnableAlert(alert, changeEnable);
  const dateStart = getDateSelected(alert.dateSelected.start, enable)
  const dateEnd = getDateSelected(alert.dateSelected.end, enable)

  const params: UpdateMachineAlertParams = {
    alerts: [{alertID: alert.alertId, machineID: alert.machine.id}],
    companyID: visibilities.companyId,
    operatorGroupID: visibilities.operatorGroupId,
    AvailableDateIni: dateStart,
    AvailableDateEnd: dateEnd,
    CronHourTime: alert.minDefaultPeriod.hour,
    CronMinuteTime: 0,
    IntervalTimes: alert.intervalTimes,
    email: alert.emails,
    enable: getEnableAlert(alert, changeEnable),
    resend: getResendMachineAlert(alert?.typeActivationSelected),
    week_days: getWeekdayByAlertInfoMachineAlert(alert.weekDays),
  };

  return params;
};

const getEnableAlert = (alert: MachineAlertShowInfo, changeEnable: boolean) => {

  if(changeEnable){
    return Number(!alert.enable)
  }

  return Number(alert.enable);
}


const getDateSelected = (date: Date, enable: number) => {

  if(!enable){
    return date;
  }

  if(!date){
    return null;
  }

  return getDateValidMachineAlert(date);

}
