import { TillAlertSteps } from "../models";


export const getTillAlertsStepsWithoutTill = (i: number) => {
  const positions: TillAlertSteps[] = [
    TillAlertSteps.SelectTypeTillAlert,
    TillAlertSteps.SelectDate,
    TillAlertSteps.AddEmail,
    TillAlertSteps.Publish
  ]

  return positions[i]
}


export const getTillAlertSteps = (i: number) => {
  const positions: TillAlertSteps[] = [
    TillAlertSteps.SelectTypeTillAlert,
    TillAlertSteps.SelectDate,
    TillAlertSteps.SelectTill,
    TillAlertSteps.AddEmail,
    TillAlertSteps.Publish
  ]

  return positions[i];
}


