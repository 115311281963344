import { Component, OnInit, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';

// Service
import { SkinMangementService } from 'src/app/services';

// Pipe
import { SanitizeSafePipe } from '../../pipes/sanitize-safe/sanitize-safe.pipe';

// Models
import { Company } from 'src/app/models';

// Utils
import { pcWidth, pcWidthMix } from '../../../utils';

const ROUTE_GENERATE_QR = '/user/generate-qr';

@Component({
  selector: 'app-modal-pdf',
  templateUrl: './modal-pdf.component.html',
  styleUrls: ['./modal-pdf.component.scss'],
})
export class ModalPdfComponent implements OnInit {
  @Input() name: string = '';
  @Input() marginTop: boolean = false;


  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;
  company: Company;
  heightModal: number = 500;

  routePDF = '';


  headerTitle = 'user_manual';

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
    public readonly platform: Platform,
    private sanitizerPipe: SanitizeSafePipe,
    private router: Router,
  ) { }


  ngOnInit() {
    this.headerTitle =
      ROUTE_GENERATE_QR === this.router.url
        ? 'generateQR.listings'
        : 'user_manual';

    this.company = this._skinService.getCompany();
    this.routePDF = `../../../../assets/info/Elora_${this.name}.pdf`;
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  clearURL(url) {
    return this.sanitizerPipe.transform(url, 'resourceUrl');
  }
}
