// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transparent {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/modals/filter-template-guided-object-doble-inputs/filter-template-guided-object-doble-inputs.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF","sourcesContent":[".transparent{\r\n  background-color: transparent;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
