// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-invert-svg {
  filter: brightness(0) invert(1) contrast(100%);
}

.p-horizontal-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-horizontal-8 {
  padding-left: 8px;
  padding-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/fab-br/fab-br.component.scss"],"names":[],"mappings":"AAAA;EAEE,8CAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;AACF","sourcesContent":[".color-invert-svg{\r\n  -webkit-filter: brightness(0) invert(1);\r\n  filter: brightness(0) invert(1) contrast(100%);\r\n}\r\n\r\n.p-horizontal-10{\r\n  padding-left: 10px;\r\n  padding-right: 10px;\r\n}\r\n\r\n.p-horizontal-8{\r\n  padding-left: 8px;\r\n  padding-right: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
