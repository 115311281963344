// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-size-sm {
  font-size: 0.8em !important;
}

.font-size-pc {
  font-size: 1.4em !important;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

@media (min-width: 0px) and (max-width: 599px) {
  .header {
    font-size: 0.9em !important;
  }
}
@media (min-width: 600px) {
  .header {
    font-size: 1.2em !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-date/date-period-no-calendar/components/period-no-calendar-modal/period-no-calendar-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;;AAEA;EACI,2BAAA;AACJ;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAGA;EACI;IACI,2BAAA;EAAN;AACF;AAIA;EACI;IACI,2BAAA;EAFN;AACF","sourcesContent":[".font-size-sm {\r\n    font-size: 0.8em !important;\r\n}\r\n\r\n.font-size-pc {\r\n    font-size: 1.4em  !important;\r\n}\r\n\r\n.d-flex-center{\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n\r\n@media (min-width: 0px) and (max-width: 599px) {\r\n    .header{\r\n        font-size: 0.9em !important;\r\n    }\r\n\r\n}\r\n\r\n@media (min-width: 600px)  {\r\n    .header{\r\n        font-size: 1.2em !important;\r\n    }\r\n\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
