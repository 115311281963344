// utils
import { addTimeDate, getLastMondayByDate } from 'src/app/utils';
import { getLastDayWithInfo } from './';

export const getLastWeeks = (last: number) => {
  const lastDay = getLastDayWithInfo();

  let lastMonday = getLastMondayByDate(lastDay);

  let mondaysValids: Date[] = [lastMonday];

  for (let i = 0; i < last; i++) {
    lastMonday = addTimeDate({
      startDate: lastMonday,
      increment: -7,
      typeIncrement: 'days',
    });

    mondaysValids.push(lastMonday);
  }

  return mondaysValids;
};

export const generateLastWeeks = (
  last: number
): { startDate: Date; endDate: Date }[] => {

  const lastWeeks = getLastWeeks(last);

  let lastWeeksComplete: { startDate: Date; endDate: Date }[] = [];

  for (let lastWeek of lastWeeks) {
    lastWeeksComplete.push({
      startDate: lastWeek,
      endDate: addTimeDate({
        startDate: lastWeek,
        increment: 6,
        typeIncrement: 'days',
      }),
    });
  }

  return lastWeeksComplete;

};
