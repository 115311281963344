import { WritableSignal } from '@angular/core';

// models
import { Report, VisibilityReport } from '../../models';

interface GetReportsByVisibility {
  reports: WritableSignal<Report[]>;
  filterVisibility: VisibilityReport;
  useFiterVisibility: WritableSignal<boolean>;
}

export const getReportsByVisibility = ({
  reports,
  filterVisibility,
  useFiterVisibility,
}: GetReportsByVisibility) => {
  if (filterVisibility && useFiterVisibility()) {
    filterOperatorGroup(reports, filterVisibility);
    filterOperator(reports, filterVisibility);
    filterClient(reports, filterVisibility);
    filterTill(reports, filterVisibility);
    filterCard(reports, filterVisibility);
  }
};

const filterOperatorGroup = (
  reports: WritableSignal<Report[]>,
  filterVisibility: VisibilityReport
) => {
  if (
    filterVisibility?.operatorGroupId ||
    filterVisibility?.operatorGroupId === 0
  ) {
    reports.set(
      reports().filter(
        (report) =>
          report?.OperatorGroup?.id === filterVisibility.operatorGroupId
      )
    );
  }
};

const filterOperator = (
  reports: WritableSignal<Report[]>,
  filterVisibility: VisibilityReport
) => {
  if (filterVisibility?.operatorId || filterVisibility?.operatorId === 0) {
    reports.set(
      reports().filter(
        (report) => report.Operator.id === filterVisibility.operatorId
      )
    );
  }
};

const filterClient = (
  reports: WritableSignal<Report[]>,
  filterVisibility: VisibilityReport
) => {
  if (filterVisibility?.clientId || filterVisibility.clientId === 0) {
    reports.set(
      reports().filter(
        (report) => report.Client.id === filterVisibility.clientId
      )
    );
  }
};

const filterTill = (
  reports: WritableSignal<Report[]>,
  filterVisibility: VisibilityReport
) => {
  if (filterVisibility?.tillId || filterVisibility.tillId === 0) {
    reports.set(
      reports().filter((report) => report.Till.id === filterVisibility.tillId)
    );
  }
};

const filterCard = (
  reports: WritableSignal<Report[]>,
  filterVisibility: VisibilityReport
) => {
  if (filterVisibility?.cardNum) {
    reports.set(
      reports().filter((report) =>
        (report.cards || []).some(
          (card) => card.cardNum === filterVisibility.cardNum
        )
      )
    );
  }
};
