import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Services
import { SkinMangementService } from 'src/app/services';

// Models
import { Company } from 'src/app/models';

// Utils
import { MAX_CODE_ARTICLE, MAX_DESCRIPTION_ARTICLE, MAX_NEW_TYPE_SUBTYPE } from '../../../utils';
import { WIDTH_MAX_ARTICLE_IMAGE, HEIGHT_MAX_ARTICLE_IMAGE } from '../../../utils/create-edit-article-constants';

@Component({
  selector: 'app-create-edit-articles-legend',
  templateUrl: './create-edit-articles-legend.component.html',
  styleUrls: ['./create-edit-articles-legend.component.scss'],
})
export class CreateEditArticlesLegendComponent implements OnInit {

  company: Company;

  // constants
  MAX_CODE_ARTICLE = MAX_CODE_ARTICLE;
  MAX_DESCRIPTION_ARTICLE = MAX_DESCRIPTION_ARTICLE;
  MAX_NEW_TYPE_SUBTYPE = MAX_NEW_TYPE_SUBTYPE;
  WIDTH_MAX_ARTICLE_IMAGE = WIDTH_MAX_ARTICLE_IMAGE;
  HEIGHT_MAX_ARTICLE_IMAGE = HEIGHT_MAX_ARTICLE_IMAGE;


  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
