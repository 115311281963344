

const CLASS_NAMES = {
  fix_menu: 'title-articles-unit-fix-menu',
  not_fix_menu: 'title-articles-unit',
}

export const getClassNamesTitleArticleUnit = (
  width: number,
  isOpenFixMenu: boolean
): string => {

  if(isOpenFixMenu){
    return CLASS_NAMES.fix_menu;
  }

  return CLASS_NAMES.not_fix_menu;
}
