// models
import { FilterDateServiceI } from "src/app/models";
import { TypeDateSelectedMachineAlert } from "../../models";

// utils
import { addTimeDate, convertDateToString } from "src/app/utils";

export const getStartDateMachineAlert = (
  _filterDateService: FilterDateServiceI,
  typeSelectDate: TypeDateSelectedMachineAlert
) => {
  if (typeSelectDate === TypeDateSelectedMachineAlert.temporaral) {
    return _filterDateService.getDateStart();
  }

  const minDate = addTimeDate({
    startDate: new Date(),
    increment: 1,
    typeIncrement: 'days',
  });

  return convertDateToString(minDate);
}
