import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Company } from 'src/app/models';
import { SkinMangementService } from 'src/app/services';
import { LanguageRouteService } from 'src/app/share/services';
import { pcWidth, pcWidthMix, type } from 'src/app/utils';

@Component({
  selector: 'app-legend-turn-over',
  templateUrl: './legend-turn-over.component.html',
  styleUrls: ['./legend-turn-over.component.scss'],
})
export class LegendTurnOverComponent implements OnInit {

  company: Company;
  type = type;
  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal(){
    this.modalCtrl.dismiss()
  }

}
