import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models';
import { Subscription } from 'rxjs';

// servcices
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from '../../../../../../services';
import { MatLanguageService, FilterDateInModalService } from 'src/app/share/services/';

// models
import { RangeDate } from '../../../models';

// utils
import { addDays } from 'src/app/utils';
import { ADD_DAYS_IN_MODAL } from '../../../helpers';


@Component({
  selector: 'app-filter-date-modal',
  templateUrl: './filter-date-modal.component.html',
  styleUrls: ['./filter-date-modal.component.scss'],
})
export class FilterDateModalComponent implements OnInit {
  company: Company;
  startDate: string;
  datePipe: string;

  rangeDate: RangeDate = {
    minDate: new Date(),
    maxDate: addDays(ADD_DAYS_IN_MODAL),
  };

  filterChange$: Subscription;

  constructor(
    private translate: TranslateService,
    private _skinMangementService: SkinMangementService,
    public _filterDateService: FilterDateInModalService,
    private _matLanguageService: MatLanguageService
  ) {
    this.datePipe = this.translate.instant('format_pipe_without_hour');
  }

  ngOnInit() {
    this.company = this._skinMangementService.getCompany();
    this.startDate = this._filterDateService.getDateStart();

    this.getLanguage();

    this.filterChange$ = this._filterDateService.filterChange$.subscribe(() => {
      this.startDate = this._filterDateService.getDateStart();
    })
  }

  ngOnDestroy(): void {
    if(this.filterChange$) this.filterChange$.unsubscribe()
  }

  async getLanguage() {
    const locale = await this.translate.instant('locale');
    this._matLanguageService.setLanguage(locale);
  }

  changeDate(startDate: Date) {
    if (!startDate) {
      return;
    }

    this._filterDateService.setDate(startDate, 0);
  }
}
