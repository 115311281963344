import { Injectable } from '@angular/core';
import { Operator } from '../../../models/Operator.model';
import { OperatorGroup } from '../../../models/operator-group.model';
import { Subject } from 'rxjs';
import {
  VisibilityFilter,
  VisibilitySegment,
} from '../../../models/';

@Injectable({
  providedIn: 'root',
})
export class VisibilitySelectService {
  operators: Operator[] = [];
  operatorsGroup: OperatorGroup[] = [];

  loadingOperators: boolean = true;
  loadingOperatorsGroup: boolean = true;

  operatorGroupSelected = null;
  operatorGroupSelected2 = null;
  operatorSelected = null;
  operatorSelected2 = null;

  currency: string = '';

  // emite los cambios del filtro
  filterChange: Subject<VisibilityFilter> = new Subject<VisibilityFilter>();
  filterChange2: Subject<VisibilityFilter> = new Subject<VisibilityFilter>();
  filterChangeOperator: Subject<VisibilityFilter> = new Subject<VisibilityFilter>();

  // emite los cambios de segments validos
  segmentChange: Subject<VisibilitySegment> = new Subject<VisibilitySegment>();

  // clear
  reset: Subject<{
    reset: Boolean;
    url?: string;
    groupName?: string;
  }> = new Subject<{ reset: Boolean; url?: string; groupName?: string }>();

  // emite cambios de operator
  operatorsChange: Subject<Operator[]> = new Subject<Operator[]>();

  existOperator = true;
  existGroupOperator = true;
  loaded = false;

  prevRoute: string = '';
  prevGroup: string = '';

  started: boolean = true;
  changeDestroy$: Subject<boolean> = new Subject<boolean>()

  constructor() {}

  setOperators(operators: Operator[]) {
    this.operators = operators;
    this.operatorsChange.next(this.operators);
  }

  getOperators(): Operator[] {
    if (!this.loadingOperators && !this.loadingOperatorsGroup) {
      return this.operators;
    } else {
      return null;
    }
  }

  getOperatorsGroup(): OperatorGroup[] {
    if (!this.loadingOperatorsGroup) {
      return this.operatorsGroup;
    } else {
      return null;
    }
  }

  setOperatorsGroup(operatorGroup: OperatorGroup[]) {
    this.operatorsGroup = operatorGroup;
  }

  getLoadingOperator(): boolean {
    return this.loadingOperators;
  }

  setloadingOperators(loadingOperator: boolean) {
    this.loadingOperators = loadingOperator;
  }

  getloadingOperatorsGroup(): boolean {
    return this.loadingOperatorsGroup;
  }

  setloadingOperatorsGroup(loadingOperatorsGroup) {
    this.loadingOperatorsGroup = loadingOperatorsGroup;
  }

  getOperatorGroupSelected() {
    return this.operatorGroupSelected;
  }

  setOperatorGroupSelected(operatorGroupSelected: number) {
    this.operatorGroupSelected = operatorGroupSelected;
  }

  setOperatorGroupSelectedSecondPosition(operatorGroupSelected2: number) {
    this.operatorGroupSelected2 = operatorGroupSelected2;
  }

  getOperatorGroupSelectedSecondPosition() {
    return this.operatorGroupSelected2;
  }

  getOperatorSelected(): number {
    return this.operatorSelected;
  }

  setOperatorSelected(operatorSelected: number) {
    this.operatorSelected = operatorSelected;
  }

  setOperatorSelectedSecondPosition(operatorSelected2: number) {
    this.operatorSelected2 = operatorSelected2;
  }

  getOperatorSelectedSecondPosition() {
    return this.operatorSelected2;
  }

  getParams() {
    return {
      operatorGroupSelected: this.operatorGroupSelected,
      operatorSelected: this.operatorSelected,
    };
  }

  resetParams() {
    this.prevGroup = '';
    this.prevRoute = '';

    this.reset.next({ reset: true });
  }

  resetParamsWithUrl(url: string, groupName: string) {
    this.prevGroup = groupName;
    this.prevRoute = url;

    this.reset.next({ reset: true, url, groupName });
  }

  changeFilter() {
    this.currency =
      this.operators.find(
        (operator) => operator.id == Number(this.operatorSelected)
      )?.Currency[0].Symbol || '';
    this.filterChange.next({
      loadingOperators: this.loadingOperators,
      loadingOperatorsGroup: this.loadingOperatorsGroup,
      operatorGroupSelected: this.operatorGroupSelected,
      operatorSelected: this.operatorSelected,
      operatorName:
        this.operators.find(
          (operator) => operator.id == Number(this.operatorSelected)
        )?.description || '',
      currency: this.currency,
      operatorGroupName:
        this.operatorsGroup.find(
          (og) => og.ID == Number(this.operatorGroupSelected)
        )?.description || '',
    });
  }

  changeFilterSecondPosition() {
    this.currency =
      this.operators.find(
        (operator) => operator.id == Number(this.operatorSelected)
      )?.Currency[0].Symbol || '';
    this.filterChange2.next({
      loadingOperators: this.loadingOperators,
      loadingOperatorsGroup: this.loadingOperatorsGroup,
      operatorGroupSelected: this.operatorGroupSelected,
      operatorSelected: this.operatorSelected,
      operatorName:
        this.operators.find(
          (operator) => operator.id == Number(this.operatorSelected)
        )?.description || '',
      currency: this.currency,
      operatorGroupName:
        this.operatorsGroup.find(
          (og) => og.ID == Number(this.operatorGroupSelected)
        )?.description || '',
    });
  }

  changeOperatorSelected() {
    this.currency =
      this.operators.find(
        (operator) => operator.id == Number(this.operatorSelected)
      )?.Currency[0].Symbol || '';
    this.filterChangeOperator.next({
      loadingOperators: this.loadingOperators,
      loadingOperatorsGroup: this.loadingOperatorsGroup,
      operatorGroupSelected: this.operatorGroupSelected,
      operatorSelected: this.operatorSelected,
      operatorName:
        this.operators.find(
          (operator) => operator.id == Number(this.operatorSelected)
        )?.description || '',
      currency: this.currency,
      operatorGroupName:
        this.operatorsGroup.find(
          (og) => og.ID == Number(this.operatorGroupSelected)
        )?.description || '',
    });
  }

  getOptionsValids(): { operator: boolean; operatorGroup: boolean } {
    let operatorGroup: boolean =
      !this.loadingOperatorsGroup && this.operatorsGroup.length > 1;
    let operator: boolean =
      !this.loadingOperators &&
      (this.operators.length > 1 || this.operatorsGroup.length > 1);

    return { operator, operatorGroup };
  }

  setOptionsValids() {
    let operatorGroup: boolean =
      !this.loadingOperatorsGroup && this.operatorsGroup.length > 1;
    let operator: boolean =
      !this.loadingOperators &&
      (this.operators.length > 1 || this.operatorsGroup.length > 1);

    this.existOperator = operator;
    this.existGroupOperator = operatorGroup;
    this.loaded = true;

    this.segmentChange.next({
      operator,
      operatorGroup,
    });
  }

  changeAll(allTranslate) {
    if (this.operators.length > 1) {
      let operatorPos = this.operators.findIndex((res) => res.id == -1);
      if (operatorPos >= 0) {
        this.operators[operatorPos].description = allTranslate;
      }
    }
  }

  getExistVision(): { operator: boolean; operatorGroup: boolean } {
    return {
      operator: this.existOperator,
      operatorGroup: this.existGroupOperator,
    };
  }

  getLoaded() {
    return this.loaded;
  }

  getOperatorGroupName(): string {
    if (this.operatorGroupSelected) {
      return (
        this.operatorsGroup.find(
          (og) => og.ID == Number(this.operatorGroupSelected)
        )?.description || ''
      );
    }

    return '';
  }

  getOperatorName(): string {
    if (this.operatorSelected && this.operatorSelected != -1) {
      return (
        this.operators.find(
          (operator) => operator.id == Number(this.operatorSelected)
        )?.description || ''
      );
    }
    if (this.operatorSelected == -1) {
      return 'All';
    }
    return '';
  }

  resetAll() {
    this.operators = [];
    this.operatorsGroup = [];

    this.loadingOperators = true;
    this.loadingOperatorsGroup = true;

    this.operatorGroupSelected = null;
    this.operatorSelected = null;
  }

  getCurrency() {
    return this.currency;
  }

  setPrevRoute(route: string , groupName: string){
    this.prevRoute = route;
    this.prevGroup = groupName;
  }

  getPrevRoute(){
    return {route: this.prevRoute, groupName: this.prevGroup}
  }

  setStarted(started: boolean){
    if(!this.started) this.changeDestroy$.next(true)
    this.started = started;
  }

  getStarted(){
    return this.started;
  }
}
