import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Service
import { FixMenuService } from '../../services/fix-menu/fix-menu.service';
import { SkinMangementService } from 'src/app/services/skin-mangement.service';

// Models
import { Company } from '../../../models';

// Utils
import { MULTI_LOGIN_ROUTE, type } from '../../../utils/constants';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  company: Company;
  type = type;

  dropdown = false;
  fixMenu = false;
  showMenu: boolean = true;

  fixMenuChange$: Subscription;
  fixChange$: Subscription;
  routerEvents$: Subscription;

  constructor(
    private menu: MenuController,
    public readonly platform: Platform,
    private _fixMenuService: FixMenuService,
    private skinService: SkinMangementService,
    private  readonly router: Router,
  ) { }

  ngOnInit() {
    this.company = this.skinService.getCompany();

    this.fixMenu = this._fixMenuService.getMenuFix();

    this.fixMenuChange$ = this._fixMenuService.fixMenuChange.subscribe((menuActivated: boolean) => {
      this.fixMenu = menuActivated;
    });

    this.fixMenuHandle()

    this.routeHandle()
  }

  ngOnDestroy(): void {
    if( this.fixChange$ )  this.fixChange$.unsubscribe()
    if (this.routerEvents$) this.routerEvents$.unsubscribe()
  }

  // menu
  async openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');

    let b = await this.menu.toggle('first');
  }

  fixMenuHandle(){
    this.fixMenu = this._fixMenuService.getMenuFix();

    this.fixChange$ = this._fixMenuService.fixMenuChange.subscribe((menuActivated: boolean) => {
      this.fixMenu = menuActivated;
    });
  }

  routeHandle(){
    this.routerEvents$ = this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd){
        this.showMenu = this.router.url !== MULTI_LOGIN_ROUTE
      }
    })
  }

}

