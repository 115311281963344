import { WritableSignal } from '@angular/core';

// models
import { RouteDriver } from 'src/app/models';
import { RouteEditable } from '../../models';

export const validateHolderRouteDriverRM = (
  routeParticalSelected: RouteEditable,
  routeDrivers: WritableSignal<RouteDriver[]>
) => {
  const routeDriveId = routeParticalSelected?.holderRouteDriver?.routeDriverId;

  if (routeDriveId || routeDriveId === 0) {
    const routeDriverSelected = routeDrivers().find(
      (routeDriver) => routeDriver?.routeDriver_id === routeDriveId
    );
    if (!routeDriverSelected) {
      console.error('Route driver invalid');
      routeParticalSelected.holderRouteDriver = {
        enable: true,
        name: '',
        routeDriverId: null,
      };
    }
  }
};
