// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-red {
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-date-template/components/filter-date-with-hours/filter-date-with-hours.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF","sourcesContent":[".bg-red{\r\n  background-color: red;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
