import {
  CharacteristicsTillAlertSpiderWithExtra,
  TypeLevelTillAlert,
} from '../models';
import { PRIORITIES_TYPES } from './constant';

export const getCharacteristicsByTypeTillAlert = (
  typesAlert: TypeLevelTillAlert[]
): CharacteristicsTillAlertSpiderWithExtra[] => {
  return typesAlert.map((typeAlert) => {
    const typeAlertDescription = (typeAlert?.Name || '').toLowerCase();

    return {
      id: typeAlert?.ID,
      description:
        PRIORITIES_TYPES?.[typeAlertDescription] || typeAlertDescription,
      hour: typeAlert?.Hour,
      pos: typeAlert?.Number,
    };
  });
};
