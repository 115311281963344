import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// models
import { TypePermissionSiteElora } from '../models/type-permission-site-elora.model';

// services
import { PermissionService } from 'src/app/services';
import {
  NAMES_ARTICLE_SITE_ELORA,
  NAMES_HIGH_LEVEL_SITE_ELORA,
  ROUTE_SITE_ELORA,
} from '../utils/constant';


@Injectable({ providedIn: 'root' })
export class SiteEloraResolverService {
  constructor(private _permissionService: PermissionService) {}

  resolve(): Observable<TypePermissionSiteElora> {
    return this._permissionService.getAllPermissions().pipe(
      map((res) => {
        const haveHighLevel = res.find(
          (permission) =>
            permission.route === ROUTE_SITE_ELORA &&
            NAMES_HIGH_LEVEL_SITE_ELORA.includes(permission.name)
        );

        const isTypeArticle = res.find(
          (permission) =>
            permission.route === ROUTE_SITE_ELORA &&
            NAMES_ARTICLE_SITE_ELORA.includes(permission.name)
        );

        return { isHighLevel: !!haveHighLevel, isTypeArticle: !!isTypeArticle };
      })
    );
  }
}
