import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// services
import { SkinMangementService } from 'src/app/services';

// models
import { Company } from 'src/app/models';

// constants
import { MAX_LENGTH_BODY, MAX_LENGTH_TITLE, MAX_LENGTH_TITLE_WITHOUT_STYLE }
       from '../../../constant/constant-notification-manager';

@Component({
  selector: 'app-notification-manager-legend',
  templateUrl: './notification-manager-legend.component.html',
  styleUrls: ['./notification-manager-legend.component.scss'],
})
export class NotificationManagerLegendComponent implements OnInit {

  company: Company;

  MAX_LENGTH_BODY = MAX_LENGTH_BODY;
  MAX_LENGTH_TITLE_WITHOUT_STYLE = MAX_LENGTH_TITLE_WITHOUT_STYLE;
  MAX_LENGTH_TITLE = MAX_LENGTH_TITLE

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
