import { Company } from "../models";

export const colorBulletByCompany = (company: Company) => {
  let bullets = document.getElementsByClassName('swiper-pagination-bullet');

  for (let i = 0; i < bullets.length; i++) {
    (bullets[i] as any).style.backgroundColor = company.colorRGB;
  }
  setTimeout(() => {
    bullets = document.getElementsByClassName('swiper-pagination-bullet');

    for (let i = 0; i < bullets.length; i++) {
      (bullets[i] as any).style.backgroundColor = company.colorRGB;
      (bullets[i] as any).classList.add('bg-red');
    }
  }, 300);

};

export const closeTooltip = () => {
  setTimeout(() => {
    let tooltip = document.getElementsByClassName('tooltip');
    if (tooltip && tooltip.length > 0) {
      tooltip[0].className =
        'tooltip tooltip-shadow tooltip-top tooltip-hidden';
    }
  }, 500);
};


export const changeColorPaginator = (colorBG: string, colorContrast: string, previusHTML: any[]) => {
  const current = document.getElementsByClassName("current");
  if(previusHTML.length > 0){
    if(previusHTML?.[0]?.style){
      previusHTML[0].style.color = 'none'
      previusHTML[0].style.backgroundColor = 'transparent'
    }
    if(previusHTML?.[1]?.style){
      previusHTML[1].style.color = 'none'
      previusHTML[1].style.backgroundColor = 'transparent'
    }
  }
  if(current?.[0]){
    (current[0] as any).style.color = colorContrast;
    (current[0] as any).style.backgroundColor = colorBG;
  }

  if(current?.[1]){
    (current[1] as any).style.color = colorContrast;
    (current[1] as any).style.backgroundColor = colorBG;
  }


  return [current?.[0], current?.[1]]

}


export const applyColorButtonAlerts = (company: Company, constratColor: string) =>{
  const alertYes = document.getElementsByClassName('alert-button-confirm');

  if(alertYes.length > 0){
    (alertYes[0] as any).style.backgroundColor = company.colorRGB;
    (alertYes[0] as any).style.color = constratColor;
  }

  const alertNo = document.getElementsByClassName('alert-button-cancel');
  if(alertNo.length > 0){
    (alertNo[0] as any).style.backgroundColor = constratColor;
    (alertNo[0] as any).style.color = company.colorRGB;
  }
}

export const difumineModal = (modalId: string, activate: boolean = true) => {
  const difumine = document.getElementById(modalId)
  if(!difumine)
    return;

  if(activate){
    difumine.style.filter = 'blur(1px)'
  }else{
    difumine.style.filter = 'blur(0px)'
  }
}

export const getColorButtonClear = (isLightColor: boolean) => {
  return (isLightColor) ? 'light' : 'medium'
}
