import { WritableSignal } from '@angular/core';

// models
import { TypesSelectedReport, VisibilityValidateCardByTextReport } from "../../../../models";

// services
import { FilterReportService } from "../../../../services";
import { Client, Operator, OperatorGroup } from 'src/app/models';

interface IsSamePreviouslyVisibilityAndReport{
  _filterReportService: FilterReportService;
  visibilityValidateCardText: WritableSignal<VisibilityValidateCardByTextReport>;
}

export const isSamePreviouslyVisibilityAndReport = (
  {_filterReportService, visibilityValidateCardText}: IsSamePreviouslyVisibilityAndReport
) => {

  const operatorGroup: OperatorGroup = _filterReportService.getOperatorGroup(TypesSelectedReport.createEdit)
  if(operatorGroup?.ID !== visibilityValidateCardText().operatorGroupId){
    return false;
  }

  const operator: Operator = _filterReportService.getOperator(TypesSelectedReport.createEdit)
  if(operator?.id !== visibilityValidateCardText().operatorId){
    return false;
  }

  const client: Client = _filterReportService.getClient(TypesSelectedReport.createEdit)
  if(client?.ID !== visibilityValidateCardText().clientId){
    return false;
  }

  return true;
}
