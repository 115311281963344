import { MachineAlertShowInfo } from "../../models";

export const getAlertsIdsUpdateMachineAlert = (
  alertsSelected: MachineAlertShowInfo[]
): {alertsIds: number[], alertIdsRemove: number[], alertsEdit: {alertId: number, machineId: number}[]} => {

  let machines: number[] = [];
  let alertsIds: number[] = [];
  let alertIdsRemove: number[] = [];
  let alertsEdit: {alertId: number, machineId: number}[] = [];

  for(let alert of alertsSelected){
    if(machines.includes(alert?.machine.id)){
      alertIdsRemove.push(alert.alertId)
    }else{
      alertsIds.push(alert.alertId)
      machines.push(alert.machine.id)
      alertsEdit.push({alertId: alert.alertId, machineId: alert.machine.id})
    }
  }

  return {alertsIds, alertIdsRemove, alertsEdit};
}
