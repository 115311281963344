// constant
import {
  FILTER_TRANSLATE_INVENTORY_MANAGER,
  FILTER_TRANSLATE_INVENTORY_MANAGER_SUPERVISOR,
} from '../constant';

export const getTranslateFilterInvManager = (useSupervisor: boolean) => {
  return useSupervisor
    ? FILTER_TRANSLATE_INVENTORY_MANAGER_SUPERVISOR
    : FILTER_TRANSLATE_INVENTORY_MANAGER;
};
