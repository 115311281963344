export const isNumber = (input: string) => {
  return /^-?\d+$/.test(input);
};

// Email
export const regexEmnail = new RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);

export const validateEmail = (email: string) => {
  if (!email) email = '';
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

// Dates
export const diffDaysDates = (d1, d2) => {
  d1 = new Date(d1);
  d2 = new Date(d2);
  const diffTime = d2 - d1;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const getISO = (hourPick: string) => {
  if (hourPick && hourPick.includes(':')) {
    const hourPickPos = hourPick.split(':');
    const today = new Date();
    let startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      Number(hourPickPos[0]),
      Number(hourPickPos[1]),
      0
    );
    let userTimezoneOffset = startDate.getTimezoneOffset() * 60000;
    startDate = new Date(startDate.getTime() - userTimezoneOffset);
    return startDate.toISOString();
  }
  return new Date().toISOString();
};

// String
export const compareTestInsensitive = (url, search) => {
  let urlInsensitive = genereateInsensitive(url);
  let searchInsensitive = genereateInsensitive(search);
  return urlInsensitive.includes(searchInsensitive);
};

export const genereateInsensitive = (text: string) => {
  let textInsensitive = text.toLowerCase();
  textInsensitive = textInsensitive.replace(/[àáâãäå]/, 'a');
  textInsensitive = textInsensitive.replace(/[eéèëê]/, 'e');
  textInsensitive = textInsensitive.replace(/['iíìïî']/, 'i');
  textInsensitive = textInsensitive.replace(/['oóòõöô']/, 'o');
  textInsensitive = textInsensitive.replace(/['uúùüû']/, 'u');
  textInsensitive = textInsensitive.replace(/['ç']/, 'c');
  return textInsensitive;
};

export const isFirstPositionVowel = (letter: string) => {
  if (!letter) return false;
  letter = letter.toLowerCase();
  letter = letter.replace(/[àáâãäå]/, 'a');
  letter = letter.replace(/[eéèëê]/, 'e');
  letter = letter.replace(/['iíìïî']/, 'i');
  letter = letter.replace(/['oóòõöô']/, 'o');
  letter = letter.replace(/['uúùüû']/, 'u');

  return (
    letter[0] === 'a' ||
    letter[0] === 'e' ||
    letter[0] === 'i' ||
    letter[0] === 'o' ||
    letter[0] === 'u'
  );
};

export const isTypeExcelValid = (mimeType: string) => {
  if (
    !mimeType.includes('text/csv') &&
    !mimeType.includes('application/vnd.ms-excel') &&
    !mimeType.includes(
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    )
  ) {
    return false;
  }

  return true;
};

export const isValidObjectId = (id: number) => {
  return id || id === 0;
};

export const isValidEmail = (email: string) => {
  return regexEmnail.test(email);
};
