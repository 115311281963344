// models
import { DateFilterFn } from '@angular/material/datepicker';
import { DatePickerFilterOptions } from '../../models';

// utils
import { onlyDaysMonthReportDatePicker } from './only-days-month-report-date-picker';
import { onlyMondayDatePicker } from './only-monday-date-picker';

export const getMatDatePickerFilter = (
  datePickerFilter: DatePickerFilterOptions
): DateFilterFn<any> => {
  if (!datePickerFilter) {
    return null;
  }

  const datePickerFilterSelector: Record<
    DatePickerFilterOptions,
    DateFilterFn<any>
  > = {
    [DatePickerFilterOptions.OnlyMonday]: onlyMondayDatePicker,
    [DatePickerFilterOptions.OnlyDaysMonthReport]: onlyDaysMonthReportDatePicker,
  };

  return datePickerFilterSelector?.[datePickerFilter] || null;
};
