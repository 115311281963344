import { WritableSignal } from '@angular/core';

// models
import { VisibilityValidateCardByTextReport } from '../../../../models';

export const isSameVisibilityValidateReport = (
  visibilityValidateCardText: WritableSignal<VisibilityValidateCardByTextReport>,
  reportVisibility: VisibilityValidateCardByTextReport
) => {
  if (
    visibilityValidateCardText().operatorGroupId !==
    reportVisibility.operatorGroupId
  ) {
    return false;
  }

  if (visibilityValidateCardText().operatorId !== reportVisibility.operatorId) {
    return false;
  }

  return true;
};
