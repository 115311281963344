import { WritableSignal } from '@angular/core';

// models
import { TillSelectedRM } from '../../models';

// utils
import { clearTillsRM } from './clear-tills-rm';

interface ClearTillSelectedRM {
  tills: WritableSignal<TillSelectedRM[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
}

export const clearTillSelectedRM = ({
  tills,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
}: ClearTillSelectedRM) => {
  clearTillsRM(tills)
  clearTillsRM(tillsByOperator)
  tillsSelected.set([]);
  tillOneMachineSelected.set(null);
};

