// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Component
import { FilterTemplateGuidedObjectComponent } from './filter-template-guided-object.component';

// Service
import { LanguageService } from 'src/app/share/services';
import { SkinMangementService } from 'src/app/services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    HttpClientModule,
  ],
  declarations: [
    FilterTemplateGuidedObjectComponent
  ],
  providers: [LanguageService, SkinMangementService],
  exports: [
    FilterTemplateGuidedObjectComponent
  ],
})
export class FilterTemplateGuidedObjectModule {}
