import { Component, Input, OnInit } from '@angular/core';

// models
import { Company } from 'src/app/models';

// utils
import { type } from 'src/app/utils';

@Component({
  selector: 'app-logo-navbar',
  templateUrl: './logo-navbar.component.html',
  styleUrls: ['./logo-navbar.component.scss'],
})
export class LogoNavbarComponent implements OnInit {

  @Input() company: Company;

  type = type;

  constructor() { }

  ngOnInit() {}

}
