export interface QueryRoutesTranslates {
  transactionQuery: string,
}

export const getQueryRoutes = (
  translates: QueryRoutesTranslates
): { route: string; name: string }[] => {
  return [
    {
      route: '/user/transactions-query',
      name: translates.transactionQuery
    },
  ]
}
