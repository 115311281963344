import { WritableSignal } from '@angular/core';

// models
import { FilterDateServiceI, UserVisibility } from 'src/app/models';
import {
  DayWeekMachineStatusAlert,
  StatusMachineAlert,
  TypeActivationMachineAlert,
  TypeDateSelectedMachineAlert,
  TypeMachineAlertOptions,
  UpdateMachineAlertParams,
} from '../../models';

//utils
import { getStatusIdByTypeMachineAlert } from '../get-status-id-by-type-machine-alert';
import { getIntervalTimesMachineStatus } from '../hour';
import { getResendMachineAlert } from '../resend';
import { getStartDateMachineAlert, transformDateMachineAlert } from '../date';
import { getWeekDaysMachineAlert } from './get-week-days-machine-alert';

interface GetUpdateMachineAlertParams {
  visibilities: UserVisibility;
  alertsEdit: { alertId: number; machineId: number }[];

  statusAlerts: StatusMachineAlert[];
  typeAlertSelected: TypeMachineAlertOptions;
  haveBankSales: WritableSignal<boolean>;

  _filterDateService: FilterDateServiceI;
  minimumHour: number;
  haveExtraHour: boolean;
  _filterDateExtraHourService: FilterDateServiceI;
  typeActivationSelected: TypeActivationMachineAlert;
  typeSelectDate: TypeDateSelectedMachineAlert;
  daysWeek: WritableSignal<Record<DayWeekMachineStatusAlert, boolean>>,

  emails: string[];
}

export const getUpdateMachineAlertParams = ({
  visibilities,
  alertsEdit,
  statusAlerts,
  typeAlertSelected,
  haveBankSales,

  _filterDateService,
  minimumHour,
  haveExtraHour,
  _filterDateExtraHourService,
  typeActivationSelected,
  typeSelectDate,
  daysWeek,

  emails,
}: GetUpdateMachineAlertParams): UpdateMachineAlertParams => {
  const statusId = getStatusIdByTypeMachineAlert({
    statusAlert: statusAlerts,
    typeAlertSelected,
    haveBankSales,
  });

  let startDate = getStartDate(_filterDateService, typeSelectDate);

  let endDate =
    typeSelectDate === TypeDateSelectedMachineAlert.temporaral
      ? _filterDateService.getDateEnd()
      : null;

  return {
    alerts: getAlerts(alertsEdit),
    companyID: visibilities.companyId,
    operatorGroupID: visibilities.operatorGroupId,
    statusID: statusId,
    CronHourTime: minimumHour,
    CronMinuteTime: 0,
    AvailableDateIni: transformDateMachineAlert(startDate),
    AvailableDateEnd: transformDateMachineAlert(endDate),
    IntervalTimes: getIntervalTimesMachineStatus({
      _filterDateService,
      haveExtraHour,
      _filterDateExtraHourService,
    }),
    email: emails,
    enable: 1,
    resend: getResendMachineAlert(typeActivationSelected),
    week_days: getWeekDaysMachineAlert({
      daysWeek,
      typeSelectDate,
      _filterDateService,
    })
  };
};

const getAlerts = (
  alertsEdit: { alertId: number; machineId: number }[]
): {
  alertID: number;
  machineID: number;
}[] => {
  return alertsEdit.map((alert) => ({
    alertID: alert?.alertId,
    machineID: alert?.machineId,
  }));
};

const getStartDate = (
  _filterDateService: FilterDateServiceI,
  typeSelectDate: TypeDateSelectedMachineAlert
) => {
  return getStartDateMachineAlert(_filterDateService, typeSelectDate);
};
