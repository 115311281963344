import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// Service
import { TransactionsContentService } from '../../../../pages/user/transactions/transactions-query/services/';
import { SendFilter } from './service/';

// Model
import { typeDeleteMenu } from './model/';
import { FilterTransation } from '../../../../pages/user/transactions/transactions-query/models';


@Component({
  selector: 'app-menu-filter-transactions',
  templateUrl: './menu-filter-transactions.component.html',
  styleUrls: ['./menu-filter-transactions.component.scss'],
})
export class MenuFilterTransactionsComponent implements OnInit {
  filterTransaction: FilterTransation;
  language = 'es-Es';
  startDateFormat;
  endDateFormat;

  textTypePayment = '';

  // translate
  pvpDeletedFilterTranslate = '';
  deleteClientFilterTranslate = '';
  deleteMachineFilterTranslate = '';
  deleteModelFilterTranslate = '';
  deleteFamilyFilterTranslate = '';
  deleteArticleTranslate = '';
  deleteCardNumFilterTranslate = '';
  allTranslate = '';

  filterValues$: Subscription;

  constructor(
    public _sendFilter: SendFilter,
    private _transactionContentService: TransactionsContentService,
  ) {}

  ngOnInit() {

    this.filterValues$ = this._sendFilter.filterValues.subscribe(
      (newFilter: any) => {
        this.filterTransaction = newFilter.filterTransaction;
        this.textTypePayment = newFilter.textTypePayment;
        this.startDateFormat = newFilter.startDateFormat;
        this.endDateFormat = newFilter.endDateFormat;
      }
    );


  }

  ngOnDestroy(): void {
    if (this.filterValues$) this.filterValues$.unsubscribe();
  }

  deleteFilter(typeDelete: typeDeleteMenu) {
    this._sendFilter.typeDelete.next(typeDelete);
  }

  clearFilter() {
    this._transactionContentService.reset();
    this._sendFilter.resetFilter.next(true);
  }
}
