import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';
import { Subject } from 'rxjs';
import { read, utils } from 'xlsx';

import { FileSystemMultiplataform } from '../../../utils/';

// constant
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=UTF-8';


@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  fabExcel: Subject<excelSubject> = new Subject<excelSubject>();

  loadingDownloadExcel = false;

  constructor(private fileSystem: FileSystemMultiplataform) {}

  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    header = false
  ): void {
    const sheetName = excelFileName.trim();
    excelFileName = (excelFileName + new Date().getTime().toString()).substring(
      0,
      20
    );
    let heading = [[sheetName], Object.keys(json[0])];
    let pos = 2;
    if (header) {
      heading = [Object.keys(json[0])];
      pos = 1;
    }
    const worksheet = XLSX.utils.aoa_to_sheet(heading);

    // Configure sheet with heading, data & options
    XLSX.utils.sheet_add_json(worksheet, json, {
      header: Object.keys(json[0]),
      skipHeader: true,
      origin: 1,
    });

    // Create & save the book
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  public saveAsExcelFileHeader(
    json: any,
    fileName: string,
    header = false,
    addDateTime = true
  ): void {
    const sheetName = fileName;
    let heading = [[sheetName], Object.keys(json[0])];
    let pos = 2;
    if (header) {
      heading = [Object.keys(json[0])];
      pos = 1;
    }

    const worksheet = XLSX.utils.aoa_to_sheet(heading);

    // Configure sheet with heading, data & options
    XLSX.utils.sheet_add_json(worksheet, json, {
      header: Object.keys(json[0]),
      skipHeader: true,
      origin: pos,
    });

    // Create & save the book
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const dateTime = (addDateTime) ?  String(new Date().getTime()) : '';
    this.saveAsExcelFile(excelBuffer, fileName + dateTime);
  }

  public exportToCsv(
    rows: object[],
    fileName: string,
    columns?: string[],
    separator = ','
  ): string {
    if (!rows || !rows.length) {
      return;
    }

    const keys = Object.keys(rows[0]).filter((k) => {
      if (columns?.length) {
        return columns.includes(k);
      } else {
        return true;
      }
    });

    const csvContent =
      keys.join(separator) +
      '\n' +
      rows
        .map((row) => {
          return keys
            .map((k) => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              cell =
                cell instanceof Date
                  ? cell.toLocaleString()
                  : cell.toString().replace(/"/g, '""');
              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            })
            .join(separator);
        })
        .join('\n');

    this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
  }


  downLoadExcel() {
    this.fabExcel.next({ download: true });
  }


  getLoading(): boolean {
    return this.loadingDownloadExcel;
  }

  changeLoading(loading) {
    this.loadingDownloadExcel = loading;
    this.fabExcel.next({ loading: this.loadingDownloadExcel });
  }

  public saveAsExcelFileHeader2(
    json: any,
    fileName: string,
    header = false
  ): File {
    const sheetName = fileName;
    let heading = [[sheetName], Object.keys(json[0])];
    let pos = 2;
    if (header) {
      heading = [Object.keys(json[0])];
      pos = 1;
    }
    const worksheet = XLSX.utils.aoa_to_sheet(heading);
    // Configure sheet with heading, data & options
    XLSX.utils.sheet_add_json(worksheet, json, {
      header: Object.keys(json[0]),
      skipHeader: true,
      origin: pos,
    });

    // Create & save the book
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    return new File([data], 'file');
  }

  // TODO adaptarlo
  public exportAsExcelFileWithTotals(
    json: any[],
    excelFileName: string,
    totals: string[]
  ): void {
    const sheetName = excelFileName.trim();
    excelFileName = (excelFileName + new Date().getTime().toString()).substring(
      0,
      20
    );

    let heading1 = [[totals[0], totals[1]], Object.keys(json[0])];
    let worksheet = XLSX.utils.aoa_to_sheet(heading1);
    XLSX.utils.sheet_add_json(worksheet, json, {
      header: Object.keys(json[0]),
      skipHeader: true,
      origin: 2,
    });

    // Create & save the book
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  convertExcelToJson(file: File) {
    let reader = new FileReader();
    let workbookkk;
    let XL_row_object;
    let json_object;
    let csv;
    reader.readAsBinaryString(file);
    return new Promise((resolve, reject) => {
      reader.onload = function () {
        //  alert(reader.result);
        let data = reader.result;
        workbookkk = read(data, { type: 'binary' });
        //console.log(workbookkk);
        workbookkk.SheetNames.forEach(function (sheetName) {
          // Here is your object
          XL_row_object = utils.sheet_to_json(workbookkk.Sheets[sheetName]);
          //console.log('csv', "\ufeff" + utils.sheet_to_csv(workbookkk.Sheets[sheetName]));
          json_object = JSON.stringify(XL_row_object);
          csv = '\ufeff' + utils.sheet_to_csv(workbookkk.Sheets[sheetName]);
          //resolve(XL_row_object);
          resolve(csv);
        });
      };
    });
  }

  convertExcelToObj(file: File): Promise<any[]> {
    let reader = new FileReader();
    let workbookkk;
    let XL_row_object: any[];
    reader.readAsBinaryString(file);
    return new Promise((resolve, reject) => {
      reader.onload = function () {
        let data = reader.result;
        workbookkk = read(data, { type: 'binary' });
        workbookkk.SheetNames.forEach(function (sheetName) {
          XL_row_object = utils.sheet_to_json(workbookkk.Sheets[sheetName]);
          resolve(XL_row_object);
        });
      };
    });
  }

  downloadFileByBlob(path: string, blob: Blob){
    this.fileSystem.downloadFileByBlob(path, blob)
  }

  saveAsFile(buffer: any, fileName: string, fileType: string): void {
    // FileSaver.saveAs(data, fileName);

    const data: Blob = new Blob([buffer], { type: fileType });
    this.downloadFileByBlob(fileName, data);
  }


  downloadPdf(excel, text) {
    //FileSaver.saveAs(excel, `${text}.pdf`);

    this.downloadFileByBlob(`${text}.pdf`, excel);
  }

  downloadCSV(csv: Blob, name) {
    //FileSaver.saveAs(csv, name);

    this.downloadFileByBlob(name, csv);
  }

  downloadTicket(excel: any, transactionId: number = 0) {
    //FileSaver.saveAs(excel, `ticket${transactionId}.pdf`);

    this.downloadFileByBlob(`ticket${transactionId}.pdf`, excel);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {

    //FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);

    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    this.downloadFileByBlob(fileName + EXCEL_EXTENSION, data)
  }

}

interface excelSubject {
  download?: boolean;
  loading?: boolean;
}
