//  External Modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgChartsModule } from 'ng2-charts';

// Modules
import {
  CanvasNg2ChartModule,
  CarouselGuidedModule,
  ColorChangeButtonModule,
  DatePeriodNoCalendarModule,
  FilterCardInputModule,
  FilterClientIndividualModule,
  FilterClientModule,
  FilterClientTillModule,
  FilterOperatorIndividualModule,
  IconSystemOperativeModule,
  IdleModule,
  LanguageSelectorModule,
  LoadCreditCardModule,
  MainMenuModule,
  ShareModalsModule,
  UICustomModule,
  VisibilityClientSelectModule,
  VisibilityClientTillSelectModule,
  FilterDateTemplateModule,
  HeaderModalModule,
  VisibilitySelectModule,
  PhoneInputTemplateModule,
} from './modules';
import { LegendModule } from './components/legend/legend.component.module';
import { ManualButtonModule } from './components/manual-button/manual-button.component.module';
import { SelectMultipleMachineModule } from './components/select-multiple-machine/select-multiple-machine.module';
import { FilterClientByOperatorModule } from './components/filter-client-by-operator';
import { FooterShareModule } from './components/footers';

// Components
import {
  CarouselPermissionsSkeletonComponent,
  FabBRComponent,
  FilterCardNumComponent,
  FilterGroupOperatorModalComponent,
  FilterMenuComponent,
  FilterOperatorModalComponent,
  FilterTillComponent,
  FilterTillModalComponent,
  FilterUserCardsComponent,
  FooterLoginComponent,
  FooterVersionComponent,
  ImageHexComponent,
  LicenseVtagLegendModule,
  LoadCreditCardComponent,
  LogoNavbarComponent,
  MenuFilterTransactionsComponent,
  ModalPdfComponent,
  NavbarComponent,
  PaginatorComponent,
  RegisteredComponent,
  SelectClientMultipleMachineModalComponent,
  ShowHidePasswordComponent,
} from './components';
import { DashboardRefillingLegendComponent } from './components/legends/dashboard-refilling-legend/dashboard-refilling-legend.component';
import { IfreeVendLegendShareComponent } from './components/legends/ifree-vend-legend-share/ifree-vend-legend-share.component';
import {
  FilterTemplateGuidedObjectDobleInputsNoModalComponent,
  FilterTemplateObjectNoModalComponent,
} from './components/filter-template-no-modal';
import { ArrowBackSelectAllPositionComponent } from './components/arrow-back-select-all-position/arrow-back-select-all-position.component';

// Directive
import { IoniconsRemoveTitleDirective } from './directives';

// Service
import { LanguageService, ImageHexService, FixMenuService } from './services';

// Pipe
import {
  SanitizeSafePipe,
  NumberLanguagePipe,
  FamilyLanguagePipe,
  TypeLanguagePipe,
  SubsidyDetailsPipe,
  SanitizeHtmlPipe,
  TwoDecimalsPipe,
  TranslateByDictionaryPipe,
} from './pipes';

@NgModule({
  declarations: [
    // Components
    ArrowBackSelectAllPositionComponent,
    CarouselPermissionsSkeletonComponent,
    FabBRComponent,
    FilterCardNumComponent,
    FilterGroupOperatorModalComponent,
    FilterMenuComponent,
    FilterOperatorModalComponent,
    FilterTillComponent,
    FilterTillModalComponent,
    FilterUserCardsComponent,
    FooterLoginComponent,
    FooterVersionComponent,
    ImageHexComponent,
    MenuFilterTransactionsComponent,
    ModalPdfComponent,
    LogoNavbarComponent,
    NavbarComponent,
    PaginatorComponent,
    RegisteredComponent,
    ShowHidePasswordComponent,
    FilterTemplateGuidedObjectDobleInputsNoModalComponent,
    IfreeVendLegendShareComponent,
    FilterTemplateObjectNoModalComponent,

    DashboardRefillingLegendComponent,
    ArrowBackSelectAllPositionComponent,
    SelectClientMultipleMachineModalComponent,

    // Directives
    IoniconsRemoveTitleDirective,

    // Pipe
    FamilyLanguagePipe,
    NumberLanguagePipe,
    SanitizeHtmlPipe,
    SubsidyDetailsPipe,
    TypeLanguagePipe,
    SanitizeSafePipe,
    TranslateByDictionaryPipe,
    TwoDecimalsPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    RouterModule,
    PdfViewerModule,
    NgxPaginationModule,
    NgChartsModule,

    // modules
    CanvasNg2ChartModule,
    CarouselGuidedModule,
    ColorChangeButtonModule,
    FilterCardInputModule,
    FilterClientIndividualModule,
    FilterClientModule,
    FilterClientTillModule,
    FilterOperatorIndividualModule,
    IconSystemOperativeModule,
    LoadCreditCardModule,
    MainMenuModule,
    ShareModalsModule,
    UICustomModule,
    VisibilityClientSelectModule,
    VisibilityClientTillSelectModule,
    VisibilitySelectModule,
    IdleModule,
    LegendModule,
    ManualButtonModule,

    FilterDateTemplateModule,
    DatePeriodNoCalendarModule,
    HeaderModalModule,
    PhoneInputTemplateModule,

    SelectMultipleMachineModule,
    FilterClientByOperatorModule,
    FooterShareModule,

    LicenseVtagLegendModule,
  ],
  providers: [
    LanguageService,
    ImageHexService,
    FixMenuService,
    SanitizeSafePipe,
  ],
  exports: [
    // Modules
    CanvasNg2ChartModule,
    CarouselGuidedModule,
    ColorChangeButtonModule,
    FilterClientIndividualModule,
    FilterClientModule,
    FilterClientTillModule,
    FilterOperatorIndividualModule,
    IconSystemOperativeModule,
    LanguageSelectorModule,
    MainMenuModule,
    ShareModalsModule,
    UICustomModule,
    VisibilityClientSelectModule,
    VisibilityClientTillSelectModule,
    VisibilitySelectModule,
    IdleModule,
    FilterDateTemplateModule,
    DatePeriodNoCalendarModule,
    HeaderModalModule,
    PhoneInputTemplateModule,
    LegendModule,
    ManualButtonModule,
    SelectMultipleMachineModule,
    FilterClientByOperatorModule,
    FooterShareModule,
    LicenseVtagLegendModule,

    // Components
    ArrowBackSelectAllPositionComponent,
    CarouselPermissionsSkeletonComponent,
    FabBRComponent,
    FilterCardNumComponent,
    FilterGroupOperatorModalComponent,
    FilterMenuComponent,
    FilterOperatorModalComponent,
    FilterTemplateGuidedObjectDobleInputsNoModalComponent,
    FilterTillComponent,
    FilterTillModalComponent,
    FilterUserCardsComponent,
    FooterLoginComponent,
    FooterVersionComponent,
    IfreeVendLegendShareComponent,
    ImageHexComponent,
    LoadCreditCardComponent,
    ModalPdfComponent,
    NavbarComponent,
    PaginatorComponent,
    RegisteredComponent,
    ShowHidePasswordComponent,
    FilterTemplateObjectNoModalComponent,
    DashboardRefillingLegendComponent,
    FilterTemplateGuidedObjectDobleInputsNoModalComponent,
    ArrowBackSelectAllPositionComponent,
    SelectClientMultipleMachineModalComponent,

    // Directives
    IoniconsRemoveTitleDirective,

    // Pipe
    FamilyLanguagePipe,
    NumberLanguagePipe,
    SanitizeHtmlPipe,
    SubsidyDetailsPipe,
    TypeLanguagePipe,
    SanitizeSafePipe,
    TranslateByDictionaryPipe,
    TwoDecimalsPipe,
  ],
})
export class ShareModule {}
