import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { Company } from 'src/app/models';
import { SkinMangementService } from 'src/app/services';

// utils
import { getISO } from 'src/app/utils';

@Component({
  selector: 'app-hours-picker-modal',
  templateUrl: './hours-picker-modal.component.html',
  styleUrls: ['./hours-picker-modal.component.scss'],
})
export class HoursPickerModalComponent implements OnInit {

  @Input() company: Company
  @Input() hourPick1
  @Input() hourPick2
  @Input() onlyOneHour: boolean = false;

  hourValue: string = null;
  hourValue2: string = null;

  hourPickStart = null;
  hourPickEnd = null;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
  ) { }

  ngOnInit() {

    this.company = this._skinService.getCompany();

    if(this.hourPick1) this.hourValue = getISO(this.hourPick1)
    if(this.hourPick2) this.hourValue2 = getISO(this.hourPick2)
  }

  closeModal(){
    this.modalCtrl.dismiss({})
  }

  saveHour(){

    let times = (this.hourPickStart || '').toString().split('T')

    const hourStart= (times.length > 1 && times[1].length >= 4)
        ? `${times[1][0]}${times[1][1]}:${times[1][3]}${times[1][4]}`
        : '00:00'

    times = (this.hourPickEnd || '').toString().split('T')
    const hourEnd= (times.length > 1 && times[1].length >= 4)
        ? `${times[1][0]}${times[1][1]}:${times[1][3]}${times[1][4]}`
        : '23:59'
    this.modalCtrl.dismiss({hourStart, hourEnd, updated: true})
  }

  clearHour(){
    this.modalCtrl.dismiss({hourStart: '00:00', hourEnd: '23:59', updated: true})
  }

  changeHour(event, position){

    if(position === 0){
      this.hourPickStart = event?.detail?.value || '00:00';
    }else{
      this.hourPickEnd = event?.detail?.value || '23:59';
    }
  }

}
