// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sangria-text {
  text-indent: 3%;
}

.toolbar-title {
  text-overflow: inherit;
  white-space: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login/components/confidentiality/confidentiality.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAGA;EACI,sBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".sangria-text{\r\n    text-indent: 3%;\r\n}\r\n\r\n\r\n.toolbar-title {\r\n    text-overflow: inherit;\r\n    white-space: normal;\r\n }\r\n \r\n// .toolbar-title {\r\n//     text-overflow: unset;\r\n//     white-space: unset;\r\n//  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
