/**
 * INFO
 * Modal Machine -> Channel (ModalMachineChannel)
 * > 1 Machine
 * Select Channel (selectMultipleChannel)
 * 1 Machine, > 3 Channel
 * Segment Channel (someChannels)
 * 1 Machine, > 1 <=3 Channel
 * Not Filter (oneChannel)
 * 1 Machine, 1 Channel
 * Not Started
 * Initialize type refilling
 */
export type TypeFilterRefillingProposal =
  | FilterRefillingProposal.modalMachineChannel
  | FilterRefillingProposal.selectMultipleChannel
  | FilterRefillingProposal.someChannels
  | FilterRefillingProposal.oneChannel
  | FilterRefillingProposal.notStarted;

export enum FilterRefillingProposal {
  modalMachineChannel = 'ModalMachineChannel',
  selectMultipleChannel = 'SelectMultipleChannel',
  someChannels = 'SomeChannels',
  oneChannel = 'OneChannel',
  notStarted = 'NotStarted'
}
