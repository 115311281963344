import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { Company } from 'src/app/models';
import { SkinMangementService } from 'src/app/services';

@Component({
  selector: 'app-legend-machines-module',
  templateUrl: './legend-machines-module.component.html',
  styleUrls: ['./legend-machines-module.component.scss'],
})
export class LegendMachinesModuleComponent implements OnInit {

  company: Company;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal(){
    this.modalCtrl.dismiss()
  }

}
