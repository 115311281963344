// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mt-60 {
  margin-top: 60px;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-content: center;
}

.mt-10 {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/register-cards/components/subtypes/unlinked-card/unlinked-card-by-client-selected/unlinked-card-by-client-selected.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,qBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".mt-60{\r\n  margin-top: 60px;\r\n}\r\n\r\n.d-flex-center{\r\n  display: flex;\r\n  justify-content: center;\r\n  align-content: center;\r\n}\r\n\r\n.mt-10{\r\n  margin-top: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
