import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';

// Service
import {
  SkinMangementService,
  VersionService,
  ColorModeService,
} from './services';
import { StorageService, FixMenuService } from './share/services';

// Company
import { Company } from './models';

// // Utils
import {
  type,
  pcWidth,
  MULTI_LOGIN_ROUTE,
  isV2VDomain,
  addElemStyle,
} from './utils';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AppComponent implements OnInit {
  company: Company;
  type = type;
  pcWidth = pcWidth;

  alwaysOpen: boolean = false;
  openActivated: boolean = false;

  constructor(
    private elem: ElementRef,
    public readonly platform: Platform,
    private readonly router: Router,
    private _skinService: SkinMangementService,
    private menuController: MenuController,
    private _fixMenuService: FixMenuService,
    private versionService: VersionService,
    private _storageService: StorageService,
    private colorModeService: ColorModeService
  ) {}

  ngOnInit() {
    this.colorModeService.start();

    this.versionService.versionActions();

    this.resizeScreen();
    this.menuController.enable(false, 'first');
    this.company = this._skinService.getCompany();

    this.startMenu();

    this.addFontFamilyByCompany();
  }


  addFontFamilyByCompany() {
    addElemStyle(
      this.elem,
      '--font-family-h1-company',
      this.company.familiesFont.h1
    );

    addElemStyle(
      this.elem,
      '--font-family-other-company',
      this.company.familiesFont.other
    );
  }

  startMenu() {


    this._storageService.get('fixmenu').then((fixMenuStorage) => {
      const fixMenu = JSON.parse(fixMenuStorage) === true;
      setTimeout(() => {
        if (fixMenu && this.platform.width() > pcWidth) {
          this.alwaysOpen = true;
          // this._fixMenuService.setFixMenu(this.alwaysOpen);
          if (
            isV2VDomain(this.company) ||
            this.router.url !== MULTI_LOGIN_ROUTE
          ) {
            this._fixMenuService.setFixMenu(this.alwaysOpen);
          }
        } else if (fixMenu) {
          this.openActivated = true;
        } else if(this.company.companySkin === 'AllianceVending' ){
          this.alwaysOpen = true;
          this._fixMenuService.setFixMenu(this.alwaysOpen);
        }
      }, 150);
    });
  }

  async changeOpen() {
    this.alwaysOpen = !this.alwaysOpen;
    this.openActivated = false;
    this._fixMenuService.setFixMenu(this.alwaysOpen);
    await this._storageService.set('fixmenu', this.alwaysOpen);
  }

  resizeScreen() {
    this.platform.ready().then(() => {
      this.platform.resize.subscribe(() => {
        if (this.platform.width() <= pcWidth && this.alwaysOpen) {
          this.alwaysOpen = false;
          this.openActivated = true;
          this._fixMenuService.setFixMenu(this.alwaysOpen);
        } else if (this.platform.width() > pcWidth && this.openActivated) {
          this.alwaysOpen = true;
          this._fixMenuService.setFixMenu(this.alwaysOpen);
        }
      });
    });
  }
}
