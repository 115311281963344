import { WritableSignal } from '@angular/core';

// models
import { TillSelectedRM } from '../models';

// utils
import { clearTillsRM } from './select-route';

export const changeSelectTillsByTillOperator = (
  tills: WritableSignal<TillSelectedRM[]>,
  tillsSelected: WritableSignal<TillSelectedRM[]>
) => {
  clearTillsRM(tills);

  const tillsSlectedIds = tillsSelected().map((till) => till.id);
  let changeTillSelected: TillSelectedRM[] = tills().map((till) => {
    return {
      ...till,
      isSelected: tillsSlectedIds.includes(till.id),
    };
  });

  tills.set(changeTillSelected);
};
