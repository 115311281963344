
export interface DateAllInfo{
  type: DateAllInfoValids,
  dateStart: string,
  dateEnd: string,
  hourStart: string,
  hourEnd: string,
}


export enum DateAllInfoValids {
  date= 0,
  period = 1
}
