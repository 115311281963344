import { Injectable } from '@angular/core';

// service
import { TransactionService } from '../../../../../share/services/transaction/transaction.service';
import {
  ArticleService,
  ClientService,
  TicketsService,
  CardService,
  OperatorService,
  ParamsGetOperators,
  TillService
} from '../../../../../share/services/';

// models
import { ParamsGetCardByCardNum, ParamsFiltersCards } from 'src/app/models';
import { TransactionsParams, PermissionsTransactionsQueryNames } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TransactionsQueryService {
  ticketRoute: PermissionsTransactionsQueryNames = null;
  nameRouteTotal: string = '';

  constructor(
    private _clientService: ClientService,
    private _operatorService: OperatorService,
    private _tillService: TillService,
    private _articleService: ArticleService,
    private _cardService: CardService,
    private _transactionService: TransactionService,
    private _ticketService: TicketsService
  ) {}

  getClients(params: { operatorId?: number; clientGroupId?: number } = {}) {
    return this._clientService.getClients(params);
  }

  getOperators(params: ParamsGetOperators = {}) {
    return this._operatorService.getOperators(params);
  }

  getTills() {
    return this._tillService.getTills();
  }

  getArticles() {
    return this._articleService.getArticles();
  }

  getFamilies() {
    return this._articleService.getFamilies();
  }

  getFamilyById(id: number) {
    return this._articleService.getFamilyById(id);
  }

  getCards(cardNum = '', skip = 0) {
    let params: ParamsFiltersCards = {
      cardNum,
      skip,
    };
    return this._cardService.getCards(params);
  }

  getTransactions(paramsFilter: TransactionsParams) {
    // console.log('paramsFilter entre', paramsFilter);
    return this._transactionService.getTransactions(paramsFilter);
  }

  getCountTransactions(paramsFilter) {
    return this._transactionService.getCountTransactions(
      paramsFilter,
      this.nameRouteTotal
    );
  }

  getTicket(transactionId: number) {
    return this._ticketService.getTickets(transactionId, this.ticketRoute);
  }

  getSaleTypes() {
    return this._transactionService.getSaleTypes();
  }

  getCardByCardNum(params: ParamsGetCardByCardNum) {
    return this._cardService.getCardByCardnum(params);
  }

  setTicketRoute(nameRoute: PermissionsTransactionsQueryNames) {
    this.ticketRoute = nameRoute;
  }

  setNameRouteTotal(nameRouteTotal: PermissionsTransactionsQueryNames) {
    this.nameRouteTotal = nameRouteTotal;
  }
}
