import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

// Models
import { Company } from '../../../models/';

// Constant
import { pcWidth } from '../../../utils/constants';

@Component({
  selector: 'app-header-modal',
  templateUrl: './header-modal.component.html',
  styleUrls: ['./header-modal.component.scss'],
})
export class HeaderModalComponent implements OnInit {

  @Input() company: Company;
  @Input() title: string = '';
  @Input() iconName: string = ''
  @Input() id: string = 'closeConfHeaderModal';
  @Input() titleWithTranslate: string = '';
  @Input() loading = false;
  @Input() useAlwaysBackArrow = false;
  @Input() haveLegend: boolean = false;
  @Input() useContent: boolean;

  @Output() closeModal = new EventEmitter()
  @Output() showLegendClicked = new EventEmitter()

  pcWidth = pcWidth;

  isLoginElora: boolean = false;

  constructor(
    public readonly platform: Platform,
    protected router: Router
  ) { }

  ngOnInit() {
    this.isLoginElora = this.router.url === '/login' && this.company?.companySkin === 'V2VFactory';
  }

  closeHandle(){
    this.closeModal.emit()
  }

  showLegend(){
    this.showLegendClicked.emit()
  }

}
