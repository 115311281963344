export const ONLINE = 'Carga Online';
export const LOAD = 'Carga de saldo';
export const OTHER = 'Otra';
export const BANK = 'Tarjeta bancaria';
export const CLOSE_LOOP_CARD_SUBSIDIES = 'Tarjeta cautiva bonificada'
export const CLOSE_LOOP_CARD = 'Tarjeta cautiva'
export const UNDEFINED = 'Sin Definir'
export const FREE_SALE = 'Venta gratuita'

export const CASH_LOADS = 'Cargas en efectivo';
export const BANK_CARD_CHARGES = 'Cargas con tarjeta bancaria';

export const ROUTE_AENA = '/user/transactions-query-aena';

export const HOUR_BANNER: number[] = [22, 6];

export const TILL_CODE_LENGTH_START: number = 10;
