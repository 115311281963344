import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// config
import { AppConfig } from 'src/app/config/config';

// services
import {
  OperatorService,
  ClientService,
  TillService,
} from 'src/app/share/services';

// models
import {
  ParamsCreateTillAlertSpider,
  ParamsDeleteTillAlert,
  ParamsUpdateTillAlertSpider,
  ParamsGetTillAlertSpider,
  ParamsGetTop10TillAlertSpiderByUser,
  TillAlertsSpiderByEmail,
  TillAlertSpider,
  StatusTillAlert,
  TypeLevelTillAlert,
} from '../../../pages/user/machines/till-alert/models';
import {
  AlertSpiderMachineStatus,
  TillAlertSpiderPeriod,
} from 'src/app/pages/user/machines/machines-status/share/models';

// utils
import { adaptMachinesStatusRoute } from 'src/app/pages/user/machines/machines-status/share/utils';
import { MakeTillAlertSpiderParams } from 'src/app/pages/user/machines/machines-status/share/models/params/make-till-alert-spider-params.model';
import { DeleteTillAlertParams, UpdateTillAlertSpiderParams } from 'src/app/pages/user/machines/machines-status/share/models/params';

@Injectable({
  providedIn: 'root',
})
export class TillAlertService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private _operatorService: OperatorService,
    private _clientService: ClientService,
    private _tillService: TillService
  ) {}

  getOperators() {
    return this._operatorService.getOperators();
  }

  getClients() {
    return this._clientService.getClients();
  }

  getTills() {
    return this._tillService.getTills();
  }

  getStatus() {
    let endpoint = `tills-alerts/getStatus`;
    // let routeUrl = this.router.url;

    let routeUrl = adaptMachinesStatusRoute(this.router.url);

    return this.httpClient
      .get<StatusTillAlert[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getTypeAlert() {
    let endpoint = `tills-alerts/getTypeAlert`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<TypeLevelTillAlert[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }


  getTillAlertsSpiderByEmail(params: ParamsGetTop10TillAlertSpiderByUser) {
    let endpoint = `tills-alerts/alertsByEmail`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<TillAlertsSpiderByEmail[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }





  getLastPeriods() {
    let endpoint = `tills-alerts/getPeriods`;

    let routeUrl = adaptMachinesStatusRoute(this.router.url);

    return this.httpClient
      .get<TillAlertSpiderPeriod[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getAlerts(params: ParamsGetTillAlertSpider) {
    let endpoint = `tills-alerts/getAlerts`;
    // let routeUrl = this.router.url;

    let routeUrl = adaptMachinesStatusRoute(this.router.url);

    return this.httpClient
      .post<AlertSpiderMachineStatus[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  makeTillAlertSpider(params: MakeTillAlertSpiderParams) {
    let endpoint = `tills-alerts/makeAlert`;

    let routeUrl = adaptMachinesStatusRoute(this.router.url);

    return this.httpClient
      .post<void>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  updateTillAlertSpider(params: UpdateTillAlertSpiderParams) {
    let endpoint = `tills-alerts/updateAlert`;

    let routeUrl = adaptMachinesStatusRoute(this.router.url);

    return this.httpClient
      .post<void>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  deleteTillAlertSpider(params: DeleteTillAlertParams) {
    let endpoint = `tills-alerts/deleteAlert`;

    let routeUrl = adaptMachinesStatusRoute(this.router.url);

    return this.httpClient
      .post<void>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  // -------------------------------- TODO deprecated till-alert --------------------------------
  createTillAlertSpider(params: ParamsCreateTillAlertSpider) {
    let endpoint = `tills-alerts/makeAlert`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<void>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getAlertsSpider(params: ParamsGetTillAlertSpider) {
    let endpoint = `tills-alerts/getAlerts`;
    // let routeUrl = this.router.url;

    let routeUrl = adaptMachinesStatusRoute(this.router.url);

    return this.httpClient
      .post<TillAlertSpider[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  updateTillAlert(params: ParamsUpdateTillAlertSpider) {
    let endpoint = `tills-alerts/updateAlert`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<void>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  deleteTillAlert(params: ParamsDeleteTillAlert) {
    let endpoint = `tills-alerts/deleteAlert`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<void>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}
