import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CardService } from '../../../services/card/card.service';

@Injectable({
  providedIn: 'root'
})
export class FilterUserCardsService {

  cardNum: string = '';
  cardId = null;
  clean: boolean = false

  filterChange$: Subject<CardFilter> = new Subject<CardFilter>();

  constructor() { }


  setCard(newCardId, cardNum){
    this.cardId = newCardId
    this.cardNum = cardNum
    this.filterChange$.next({cardId: this.cardId, cardNum: this.cardNum})
    //console.log('d', this.cardId)
  }

  getCardId(){
    return this.cardId
  }

  getCardNum(){
    return this.cardNum;
  }

  resetParams(){
      this.cardId = null
      this.cardNum = ''
      this.clean = true
      this.filterChange$.next({ cardNum: this.cardNum, cardId: this.cardId, clean: this.clean})

}

}

interface CardFilter {
  cardNum?: string,
  cardId?: number,
  clean?: boolean

}


