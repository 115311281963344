// models
import { ArticleDashboardWelcome } from '../../models';

export const removeSelectedArticles = (
  articles: ArticleDashboardWelcome[]
): ArticleDashboardWelcome[] => {
  return articles.map((article) => {
    if(article.isSelected){
      article.isSelected = false;
    }
    return article
  });
};
