import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// config
import { AppConfig } from 'src/app/config/config';

// models
import {
  ArticleRoute,
  ParamsArticleRoute,
  ParamsSaleRoute,
  SaleRoute,
  RangeStatus,
} from '../../../pages/user/route-management/sales-module/models';

// services
import { RoutesShareService } from '../routes-share';

// models
import {
  ClientRoute,
  RoutesRoute,
} from '../../../pages/user/route-management/sales-module/models';

// utils
import {
  CLIENT_MANAGEMENT_ROUTE,
  ROUTE_MANAGEMENT_GROUP_NAME_ROUTE,
} from 'src/app/utils';
import { removeUMTDate } from '../../../utils/useDate';


@Injectable({
  providedIn: 'root',
})
export class MachinesSalesModulesService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private _routeShareService: RoutesShareService
  ) {}

  getClientsRoute(params: { routeID?: number } = {}) {
    let endpoint = `route/client`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<ClientRoute[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getRoutesRoute() {
    let endpoint = `route/routes`;
    let routeUrl = this.getRouteUrl();

    return this.httpClient
      .get<RoutesRoute[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getMachinesRoute(params: { clientID?: number; routeID?: number }) {
    // let endpoint = `route/machines`;
    // let routeUrl = this.router.url;

    // return this.httpClient
    //   .post<MachineRoute[]>(
    //     this.serviceUrl + endpoint + `?route=${routeUrl}`,
    //     params
    //   )
    //   .pipe(
    //     map((res) => {
    //       return res;
    //     }),
    //     catchError((error) => {
    //       let messageError = !error?.error?.error?.message
    //         ? '504 Gateway timeout'
    //         : error?.error?.error?.message;
    //       return throwError(() => messageError);
    //     })
    //   );

    return this._routeShareService.getMachinesRoute(params);
  }

  getArticlesRoute(params: ParamsArticleRoute) {
    let endpoint = `route/articles`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<ArticleRoute[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getSalesRoute(params: ParamsSaleRoute, status: RangeStatus = null) {
    let endpoint = `route/sales`;
    let routeUrl = this.getRouteUrl();

    return this.httpClient
      .post<SaleRoute[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          res.map((sale) => {
            sale.lastRefillingDate = removeUMTDate(sale.lastRefillingDate);
            sale.status = !sale?.capacity
              ? 0
              : ((sale.capacity - sale.sales) / sale.capacity) * 100;

            return sale;
          });

          if (status) {
            res = res.filter(
              (sale) =>
                sale?.status >= status.startValue &&
                sale?.status <= status.endValue
            );
          }

          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getRouteUrl() {
    let routeUrl = this.router.url;

    if (
      routeUrl.includes(CLIENT_MANAGEMENT_ROUTE) &&
      routeUrl !== CLIENT_MANAGEMENT_ROUTE
    ) {
      routeUrl = `${CLIENT_MANAGEMENT_ROUTE}&group=${ROUTE_MANAGEMENT_GROUP_NAME_ROUTE}`;
    }

    return routeUrl;
  }
}
