// models
import { MachineAlertInfo, TypeActivationMachineAlert } from '../../models';

export const getTypeActivationSelectedByResend = (
  machineAlert: MachineAlertInfo
): TypeActivationMachineAlert => {
  if (machineAlert.resend === 1) {
    return TypeActivationMachineAlert.allActivations;
  }

  return TypeActivationMachineAlert.firstActivation;
};
