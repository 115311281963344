import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Models
import { Company } from 'src/app/models';

// Services
import { SkinMangementService } from 'src/app/services';

@Component({
  selector: 'app-legends-send-file-aena',
  templateUrl: './legends-send-file-aena.component.html',
  styleUrls: ['./legends-send-file-aena.component.scss'],
})
export class LegendsSendFileAenaComponent  implements OnInit {

  company: Company;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
