import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// material
import { DateFilterFn, MatDateRangePicker } from '@angular/material/datepicker';

// models
import { Company, FilterDateServiceI } from 'src/app/models';
import { DatePickerFilterOptions, RangeDate } from '../../../../../models';

// utils
import { getMatDatePickerFilter } from 'src/app/share/components/filter-date-template/utils';

@Component({
  selector: 'app-datepicker-date-material-input-v2v',
  templateUrl: '../share/datepicker-date-material-input.component.html',
  styleUrls: ['./datepicker-date-material-input-v2v.component.scss'],
})
export class DatepickerDateMaterialInputV2vComponent implements OnInit {
  @Input() company: Company;
  @Input() dateText: string = '';
  @Input() _filterDate: FilterDateServiceI;
  @Input() rangeDate: RangeDate;
  @Input() hideIconBySize: boolean = false;

  @Input() datePickerFilter: DatePickerFilterOptions = null;

  @Output() changeDate = new EventEmitter<Date>();

  datePicker: FormGroup;

  @ViewChild('picker') picker: MatDateRangePicker<any>;

  filterChange$: Subscription;

  minDate: Date;
  maxDate: Date;

  matDatepickerFilter: DateFilterFn<any> = null;

  constructor(public readonly platform: Platform) {}

  ngOnInit() {
    this.datePicker = new FormGroup({
      start: new FormControl('', Validators.required),
    });

    this.setValueDatePicker();

    this.filterChange$ = this._filterDate.filterChange$.subscribe(() => {
      this.setValueDatePicker();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.rangeDate) {
      this.minDate = this.rangeDate.minDate;
      this.maxDate = this.rangeDate.maxDate;
    }

    if (
      changes?.datePickerFilter?.currentValue ||
      (changes?.datePickerFilter?.currentValue === null &&
        changes?.datePickerFilter?.previousValue !== null)
    ) {
      this.getDatePickerFilter();
    }
  }

  ngOnDestroy(): void {
    if (this.filterChange$) this.filterChange$.unsubscribe();
  }

  reOpenCalendar() {
    this.picker.open();
  }

  closed() {
    this.changeDate.emit(this.datePicker.get('start').value);
  }

  setValueDatePicker() {
    this.datePicker.controls.start.setValue(
      new Date(this._filterDate.getDateStart())
    );
  }

  getDatePickerFilter() {
    this.matDatepickerFilter = getMatDatePickerFilter(this.datePickerFilter);
  }
}
