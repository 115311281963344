import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

// service
import { SkinMangementService } from '../../../../../../../services/';
import { FilterTransactionsQueryService } from '../../../services';

// models
import { Company } from 'src/app/models/';

// utils
import { ROUTE_AENA } from '../../../utils';


@Component({
  selector: 'app-legend-transaction-query',
  templateUrl: './legend-transaction-query.component.html',
  styleUrls: ['./legend-transaction-query.component.scss'],
})
export class LegendTransactionQueryComponent implements OnInit {

  @Input() clientVisibility: number = null;
  @Input() permissionAllTypes: boolean = false;
  @Input() visibilityOperatorGroup: boolean = false;


  company: Company;

  isTotal: boolean = false;

  isAena: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private _skinService: SkinMangementService,
    private _filterTransactionsQueryService: FilterTransactionsQueryService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
    this.isTotal = this._filterTransactionsQueryService.getIsTotal()
    this.isAena = this.router.url === ROUTE_AENA
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
