import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { NgChartsModule } from 'ng2-charts';

// Components
import { CanvasNg2ChartComponent } from './canvas-ng2-chart.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    SwiperModule,
    NgChartsModule,
  ],
  declarations: [
    CanvasNg2ChartComponent
  ],
  exports: [
    CanvasNg2ChartComponent
  ],
})
export class CanvasNg2ChartModule {}
