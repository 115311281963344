import { WritableSignal } from '@angular/core';

// utils
import { getTomorrowDay } from 'src/app/utils';
import { ONLY_DAYS_MONTH_REPORT } from '../constants';

interface SetEditDaySelectedMonthlyReport {
  dateSelected: Date;
  dayReportMonthly: WritableSignal<number>;
  monthReportMonthly: WritableSignal<number>;
}

interface UseOtherDay extends  SetEditDaySelectedMonthlyReport{
  tomorrow: Date
}

const MAX_MONTHS_YEAR = 12;
const FIRST_MONTH_YEAR = 1;

export const setEditDaySelectedMonthlyReport = ({
  dateSelected,
  dayReportMonthly,
  monthReportMonthly,
}: SetEditDaySelectedMonthlyReport) => {

  if (!dateSelected) {
    return null;
  }

  const tomorrow = getTomorrowDay();

  if (dateSelected.getTime() > tomorrow.getTime()) {
    useDateSelected({dateSelected, dayReportMonthly, monthReportMonthly})
    return;
  }

  useOtherDay({ dateSelected, dayReportMonthly, monthReportMonthly, tomorrow });
};

const useDateSelected = ({
  dateSelected,
  dayReportMonthly,
  monthReportMonthly,
}: SetEditDaySelectedMonthlyReport) => {

  if (ONLY_DAYS_MONTH_REPORT.includes(dateSelected.getDate())) {
    dayReportMonthly.set(dateSelected.getDate());
    monthReportMonthly.set(dateSelected.getMonth() + 1);
    return;
  }

  if (dateSelected.getDate() < ONLY_DAYS_MONTH_REPORT?.[1]) {
    dayReportMonthly.set(ONLY_DAYS_MONTH_REPORT[1]);
    monthReportMonthly.set(dateSelected.getMonth() + 1);
    return;
  }

  if (dateSelected.getDate() < ONLY_DAYS_MONTH_REPORT?.[2]) {
    dayReportMonthly.set(ONLY_DAYS_MONTH_REPORT[2]);
    monthReportMonthly.set(dateSelected.getMonth() + 1);
    return;
  }

  dayReportMonthly.set(ONLY_DAYS_MONTH_REPORT[0]);
  let month = dateSelected.getMonth() + 2;
  if (month > MAX_MONTHS_YEAR) {
    month = FIRST_MONTH_YEAR;
  }

  monthReportMonthly.set(month);
};

const getNextDayValid = (dateSelected: Date): number => {
  let daySelected = dateSelected.getDate();
  if (!ONLY_DAYS_MONTH_REPORT.includes(daySelected)) {
    if (daySelected <= ONLY_DAYS_MONTH_REPORT?.[1]) {
      return ONLY_DAYS_MONTH_REPORT[1];
    }

    if (daySelected <= ONLY_DAYS_MONTH_REPORT?.[2]) {
      daySelected = ONLY_DAYS_MONTH_REPORT[2];
      return ONLY_DAYS_MONTH_REPORT[2];
    }

    return ONLY_DAYS_MONTH_REPORT[0];
  }

  return daySelected;
};

const useOtherDay = ({
  dateSelected,
  dayReportMonthly,
  monthReportMonthly,
  tomorrow
}: UseOtherDay) => {

  const daySelected = getNextDayValid(dateSelected);
  dayReportMonthly.set(daySelected);

  const dateOtherDay = new Date(
    `${dateSelected.getFullYear().toString()}-${(dateSelected.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${daySelected.toString().padStart(2, '0')}`
  );

  let month = (tomorrow.getMonth() + 1);
  if(dateOtherDay.getTime() < tomorrow.getTime()){
    month = month + 1;
    if(month > MAX_MONTHS_YEAR){
      month = FIRST_MONTH_YEAR;
    }
  }

  monthReportMonthly.set(month);

};
