// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-indent-title {
  text-indent: 10px;
}

.font-title {
  font-size: 1.5em;
}

.w-100 {
  width: 100% !important;
}

.d-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
}

.max-width100 {
  max-width: 100% !important;
}

.mb-50 {
  margin-bottom: 50px;
}

::ng-deep .size-pc {
  --width: 95%;
  --max-width: 400px;
}

::ng-deep .size-sm {
  --width: 100%;
  --max-width: 500px;
}

.color-dark {
  color: var(--ion-color-dark);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/listings/reports/components/modals/filters-report-modal/filters-report-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF;;AAGA;EACE,0BAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAKA;EAEE,YAAA;EACA,kBAAA;AAHF;;AAMA;EAEE,aAAA;EACA,kBAAA;AAJF;;AAOA;EACE,4BAAA;AAJF","sourcesContent":[".text-indent-title{\r\n  text-indent: 10px;\r\n}\r\n\r\n.font-title{\r\n  font-size: 1.5em;\r\n}\r\n\r\n.w-100{\r\n  width: 100% !important;\r\n}\r\n\r\n.d-flex-between{\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.d-flex-end{\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n\r\n\r\n.max-width100{\r\n  max-width: 100% !important;\r\n}\r\n\r\n.mb-50{\r\n  margin-bottom: 50px;\r\n}\r\n\r\n\r\n\r\n::ng-deep .size-pc\r\n{\r\n  --width: 95%;\r\n  --max-width: 400px;\r\n}\r\n\r\n::ng-deep .size-sm\r\n{\r\n  --width: 100%;\r\n  --max-width: 500px;\r\n}\r\n\r\n.color-dark{\r\n  color: var(--ion-color-dark);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
