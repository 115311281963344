import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { present } from '@ionic/core/dist/types/utils/overlays';
import { Subject, Subscription } from 'rxjs';
import { getTills, Subsidies, SubsidiesParams, addCardSubsidy, Subsidy } from 'src/app/pages/user/cards/subsidies/models/subsidies.model';
import { ClientFilterService } from 'src/app/share/components/filter-client/service/client-filter.service';
import { FilterTillService } from 'src/app/share/components/filter-till/service/filter-till.service';
import { ClientService } from 'src/app/share/services/client/client.service';
import { FilterDateService } from 'src/app/share/services/filter-date/filter-date.service';
import { TillService } from 'src/app/share/services/till/till.service';
import { presentToast } from 'src/app/utils';
import { VisibilitySegment, VisibilityFilter } from '../../../../../models';
import { FilterCardNumService } from '../../../../../share/components/filter-card-num/service/filter-card-num.service';
import { VisibilitySelectService } from '../../../../../share/services/visibility-select/visibility-select.service';
//import { FilterClientTillComponent } from '../../../machines/machines-status/till-state/components/filter-client-till/filter-client-till.component';
import { FilterClientTillService } from '../../../../../share/services/filter-client-till-service/filter-client-till.service';
import { SubsidiesService } from './subsidies.service';
//import { FilterClientSubsidiesComponent } from '../components/filter-client-subsidies/filter-client-subsidies.component';
import { FilterClientTillComponent } from 'src/app/share/components/filter-client-till/filter-client-till.component';
import { filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class FilterSubsidiesService {

  // CAMPOS NECESARIOS
  cardID: number = null;
  cardNumber: string = '';
  machineID: number = null
  operatorId = null;
  operatorGroupId = null;
  cardSelected = {}
  codeMachine: Number = null

  filterChangeId: Subject<number> = new Subject<number>();
  filterChangeDescription: Subject<string> = new Subject<string>();

  filterChange: Subject<FilterSubsidies> = new Subject<FilterSubsidies>();
  changeFilter: Subject<boolean> = new Subject<boolean>();
  segmentChange: Subject<VisibilitySegment> = new Subject<VisibilitySegment>();
  resetSubsidies: Subject<boolean> = new Subject<boolean>();
  changeFilter$: Subject<boolean> = new Subject<boolean>();


  //filterChangeList: Subject<number> = new Subject<number>();
  //filterChangeCardSelected: Subject<object> = new Subject<object>();

  deleteClick: boolean = false
  name: string = '';
  clientId: number = null;
  tillId: number = null;
  code: number = null;
  codeClient: string = '';
  nameClient = '';


  //get the values with dismmiss
  subsidyPosition: { id: number, name: string, cardID?: number }[] = []
  cardPosition: { cardID: number, cardNumber: string }[] = []

  //pagina
  page = 1;
  skip = 0
  config: any;


  //subsidySelect: SubsidiesPosition = null;
  //subsidySelect2: SubsidiesPosition = null;
  subsidySelect: string = ' '
  subsidySelect2: string = ' '

  subsidies: Subsidy[] = []
  subsidies2: Subsidy[] = []

  acceptAgreement: boolean = false
  acceptAgreement2: boolean = false

  //cardTotalSelected = []
  cardTotalSelected2: {cardID: number, cardNum: string, subsidy:string, subsidyID:number, position: number,subsidy_expired_date?:Date }[] = []


  //bonificaciones

  id: number = null;
  description: string;

  id2: number = null;
  description2: string;

  //loadings Filter Bonificaciones
  loadedSubsidy: boolean = false
  loadingSubsidy: boolean = false

  loadedSubsidy2: boolean = false
  loadingSubsidy2: boolean = false

  changeState: Subject<loadingSubsidy> = new Subject<loadingSubsidy>();
  changeState2: Subject<loadingSubsidy2> = new Subject<loadingSubsidy2>();

  updated: boolean = false

  filterChange$: Subscription;
  segmentChange$: Subscription;
  clientChange$: Subscription;

  constructor(
    private _filterCardService: FilterCardNumService,
    private _visibilityService: VisibilitySelectService,
    private _clientService: ClientService,
    private _tillService: TillService,
    private _filterDateService: FilterDateService,
    private _filterClientTillService: FilterClientTillService,
    private _subsidiesService: SubsidiesService,
    private _toastCtrl: ToastController,
    private _filterClientService: ClientFilterService

  ) {

    this.cardNumber = this._filterCardService.getCardNum();
    this.operatorId = this._visibilityService.getOperatorSelected();
    this.operatorGroupId = this._visibilityService.getOperatorGroupSelected();

    // operator and group operator
    this.filterChange$ = this._visibilityService.filterChange.subscribe((res: VisibilityFilter) => {
      this.operatorId = this._visibilityService.getOperatorSelected();
      this.operatorGroupId = this._visibilityService.getOperatorGroupSelected();
      this._filterCardService.resetCard();
    });

    // show operator, operatorGroup
    this.segmentChange$ = this._visibilityService.segmentChange.subscribe(res => {
      this.segmentChange.next(res)
    })

    this.clientChange$ = this._filterClientService.filterChange.subscribe(res => {
      if (res?.idClient || res?.code || res.name) {
        this.nameClient = res.name
        this.codeClient = res.code
        this.filterChange.next({ codeClient: this.codeClient, nameClient: this.nameClient });
      }
    })

  }

  ngOnDestroy(): void {
    if(this.filterChange$) this.filterChange$.unsubscribe()
    if(this.segmentChange$) this.segmentChange$.unsubscribe()
    if(this.clientChange$) this.clientChange$.unsubscribe()
  }

  getPage() {
    return this.page;
  }

  setPage(newPage: number) {
    this.skip = (newPage - 1) * 10
    this.page = newPage;
    this.changeFilter.next(true);
  }

  setUpdated(updated) {
    this.updated = updated
    //console.log('updated', updated)
    this.filterChange.next({ updated: this.updated })

  }

  getUpdated() {
    return this.updated
  }


  getOptionsValids() {
    return this._visibilityService.getOptionsValids();
  }



  getClientID() {
    return this.clientId
  }

  started() {
    if (!this._visibilityService.getOperatorsGroup()) {
      return false;
    }
    return true;
  }

  getTextExcel() {
    let text = "Subsidies-"

    let dateSelected = this._filterDateService.getDate();
    if (dateSelected?.datafinal) {
      text += dateSelected?.datainici + '-' + dateSelected?.datafinal
    }
    if (this.cardNumber) {
      text += this.cardNumber
    }
    return text.substring(0, 29);
  }


  getLoaded(): boolean {
    return this.loadedSubsidy;
  }


  getLoaded2(): boolean {
    return this.loadedSubsidy2;
  }


  getSubsidyId() {
    return this.id

  }

  getSubsidyId2() {
    return this.id2

  }

  setCardIdList(cardID) {
    this.cardID = cardID
    this.filterChange.next({cardID: this.cardID})
   // console.log("the carID", cardID)
  }

  getCardIdList() {
    return this.cardID
  }


 // setCardSelected(cardSelected) {
 //   this.cardSelected = cardSelected
 //   if (this.cardTotalSelected.length < 5) {
 //     this.cardTotalSelected.push(this.cardSelected)
 //     console.log('lista,', this.cardTotalSelected)
 //     this.filterChange.next({cardSelected: this.cardSelected})
 //     this.filterChangeCardSelected.next(this.cardTotalSelected)
 //     console.log('cardSelected', cardSelected)

 //   }

 // }

  //getCardTotalSelected() {
  //  return this.cardTotalSelected

  //}

 // getCardSelected() {
 //   return this.cardSelected

 // }

  getDeleteClick() {
    return this.deleteClick

  }


  setDeleteClick(newDeleteClick) {
    this.deleteClick = newDeleteClick
   // console.log('entras?', this.deleteClick)
    this.filterChange.next({ deleteClick: this.deleteClick })
    //the reason is because if I don't put the setTimeOut I will send deleteClick = true
    setTimeout(() => {
      this.deleteClick = false
    }, 500);

  }

  addSubsidiesList({cardID}: Subsidies, {cardNum}:Subsidies, {subsidy}:Subsidies, {subsidyID}:Subsidies, {subsidy_expired_date}:Subsidies,position: number ){
    const index = this.cardTotalSelected2.findIndex(filter => filter.position === position)
    //console.log('index', index)
    //console.log('prueba', this.cardTotalSelected2.length)

    if(index === -1){
      this.cardTotalSelected2.push({cardID: cardID, cardNum: cardNum, subsidy:subsidy, subsidyID:subsidyID,subsidy_expired_date: subsidy_expired_date ,position})
     // console.log('cardTotalSelected', this.cardTotalSelected2)
    }
   else{
      this.cardTotalSelected2.splice(index, 1);
     // console.log('deseleccionado', this.cardTotalSelected2)
    }
    this.changeFilter$.next(true);
  }

  clearSubsidiesList(){
    this.cardTotalSelected2 = [ ]
  }

  setSubsidiesList(newCardSelected){
    this.cardTotalSelected2 = newCardSelected
  }

  getCardTotalList(){
    return this.cardTotalSelected2
  }

  getPositions(){
    //console.log(this.cardTotalSelected2)
    return this.cardTotalSelected2.map(filter => filter.position)
  }


  //the params for update cards usign excel it could be till or cliente.
  getParams() {
    let parametros = this._filterClientTillService.getFilterSubsidies()
   // console.log('clienid', parametros)
    if (parametros.clientID) {
      let params = {
        clientID: this._filterClientTillService.getFilterSubsidies2(),
        subsidyID: this.getSubsidyId(),
        apply: this.getValueAcceptAgreement(),
        cardID: this.getCardIdList(),

      }

     // console.log('els params de ClientID: ', params)
      return params
    }

    else {
      let params = {
        subsidyID: this.getSubsidyId(),
        apply: this.getValueAcceptAgreement(),
        cardID: this.getCardIdList(),
        tillID: this._filterClientTillService.getFilterSubsidies2()

      }

      //console.log('els params de TillID: ', params)
      return params

    }

  }


  getParams2() {
    let parametros = this._filterClientTillService.getFilterSubsidies()
    //console.log('clienid', parametros)
    if (parametros.clientID) {
      let params = {
        clientID: this._filterClientTillService.getFilterSubsidies2(),
        subsidyID: this.getSubsidyId2(),
        apply: this.getValueAcceptAgreement(),
        cardID: this.getCardIdList(),
      }

     // console.log('els params de ClientID: ', params)
      return params
    }

    else {
      let params = {
        subsidyID: this.getSubsidyId2(),
        apply: this.getValueAcceptAgreement(),
        cardID: this.getCardIdList(),
        tillID: this._filterClientTillService.getFilterSubsidies2()

      }

     // console.log('els params de TillID: ', params)
      return params

    }

  }



  removeSubsidy() {
    let params = {
      cardID: this.getCardIdList()
    }

    //console.log('els params que hi ha: ', params)
    return params

  }



  getValueAcceptAgreement() {
   // console.log('llega', this.acceptAgreement)
    return this.acceptAgreement;
  }

  getValueAcceptAgreement2() {
    // console.log('llega', this.acceptAgreement)
     return this.acceptAgreement2;
   }


  setValueAcceptAgreement(NewAcceptAgreement) {
    this.acceptAgreement = NewAcceptAgreement;
   // console.log("boolen", this.acceptAgreement)
  }


  setValueAcceptAgreement2(NewAcceptAgreement2) {
    this.acceptAgreement = NewAcceptAgreement2;
   // console.log("boolen", this.acceptAgreement)
  }



  setSubsidy(id: number, description: string, positionCardNum: number = 0) {
    this.id = id;
    this.description = description;
   // console.log("set of", description, id)
    //this.filterChange.next({subsidyId: this.id, description: this.description })
    this.filterChangeId.next(this.id);
    this.filterChangeDescription.next(this.description)
   // this.filterChangeDescription.next(this.description)
  }

  setSubsidy2(id2: number, description2: string, positionCardNum: number = 0) {
    this.id2 = id2;
    this.description2 = description2;
    //this.filterChange.next({subsidyId: this.id2, description: this.description2 })

  //  console.log("set of", this.description2, this.id2)
    this.filterChangeId.next(this.id2);
    this.filterChangeDescription.next(this.description2)
  }




  getSubsidyDescription() {
   // console.log("pilla la description? ", this.description)
    return this.description
  }

  getSubsidyDescription2() {

    //console.log("pilla la description? ", this.description)
    return this.description2
  }


  startSubsidies() {
    if (!this.loadedSubsidy) {
      this.loadingSubsidy = true;
      this.subsidies = [];
      this.changeState.next({ loadingSubsidy: this.loadingSubsidy, loadedSubsidy: this.loadedSubsidy })
      this._subsidiesService.getSubsidies().subscribe({next: (res: any[]) => {
        this.subsidies = res;
        //console.log("Something", this.subsidies)
        this.subsidies = this.getSubsidies()

        this.loadedSubsidy = true;
        this.loadingSubsidy = false;
        this.changeState.next({ loadingSubsidy: this.loadingSubsidy, loadedSubsidy: this.loadedSubsidy })
      },error: (error) => {
        this.subsidies = [];
        this.loadedSubsidy = true;
        this.loadingSubsidy = false;
      }});

    }
  }

  startSubsidies2() {
    if (!this.loadedSubsidy2) {
      this.loadingSubsidy2 = true;
      this.subsidies2 = [];
      this.changeState2.next({ loadingSubsidy2: this.loadingSubsidy2, loadedSubsidy2: this.loadedSubsidy2 })
      this._subsidiesService.getSubsidies2().subscribe({next: (res: any[]) => {
        this.subsidies2 = res;
        //console.log("Something", this.subsidies2)
        this.subsidies2 = this.getSubsidies2()

        this.loadedSubsidy2 = true;
        this.loadingSubsidy2 = false;
        this.changeState2.next({ loadingSubsidy2: this.loadingSubsidy2, loadedSubsidy2: this.loadedSubsidy2 })
      }, error: (error) => {
        this.subsidies2 = [];
        this.loadedSubsidy2 = true;
        this.loadingSubsidy2 = false;
      }});

    }
  }


  getSubsidies() {
    //console.log("list of subsidies?", this.subsidies)
    return this.subsidies
  }

  getSubsidies2() {
   // console.log("list of subsidies?", this.subsidies2)
    return this.subsidies2
  }



  getSubsidySelect() {
    return this.subsidySelect;
  }


  //for reset the subsidies filter in table-mix
  resetSubsidiesSelect() {
    this.subsidySelect = null;
  //  this.cardTotalSelected = []
    this.acceptAgreement = false
    this.id = null;
    this.description = '';
    this.filterChangeId.next(this.id);
    this.filterChangeDescription.next(this.description)
    //this.filterChange.next({description: ' ', subsidyId: null})

    this.resetSubsidies.next(true);
  }

  //for reset the subsidies filter inside of modal-actives
  resetSubsidiesSelect2() {
    this.subsidySelect2 = null;
    this.acceptAgreement = false
    this.id2 = null;
    this.description2 = '';
    this.filterChangeId.next(this.id2);
    this.filterChangeDescription.next(this.description2)
    //this.filterChange.next({description: '', subsidyId: null})
    this.resetSubsidies.next(true);
  }


  getClients(operatorId: number) {
    this.operatorId = operatorId
    let params = { }
    params = {
      operatorId: this.operatorId
    }

    return this._clientService.getClients(params);
  }

  getTills(operatorId: number, clientId: number) {
    let params = {}
    params = {
      operatorID: operatorId,
      clientId: clientId
    }

    return this._tillService.getTills(params);
  }

  //machine
  getTillCode() {
    return this.code;
  }

  setClientFilter(clientId, name) {
    this.tillId = null;
    this.code = null;
    this.clientId = clientId;
    this.name = name;
  }

  setTillFilter(tillId, code) {
    this.tillId = tillId;
    this.code = code;
    this.clientId = null;
    this.name = '';
  }

  //for get operator
  getOperator(): number {
    return this.operatorId;
  }

  //for get operator group
  getOperatorGroup(): number {
    return this.operatorGroupId;
  }

  getCardNum() {
    return this.cardNumber;
  }

  setCardNum(cardNum: string, id: number) {
    this.cardNumber = cardNum;
    this.cardID = id;
    this.filterChange.next({ cardNumber: this.cardNumber });
  }



  resetParams() {

    //this.subsidyPosition = this.getSubsidyPosition()
    this.setSubsidy(null, null);
    //this.cardPosition = this.getCardPosition()
    this.cardPosition = []
    this.subsidyPosition = []


    //reset the filter if user have original permission
    this._filterClientTillService.reset();

    //reset the filter if user have isComercial permission
    this._filterClientService.clearFilter()

    this.filterChange.next({ reset: true, subsidyPositionList: this.subsidyPosition, cardPositionList: this.cardPosition });


  }

  setSubsidyPosition(newSubsidyPosition) {
    this.subsidyPosition = newSubsidyPosition
    this.filterChange.next({ subsidyPositionList: this.subsidyPosition });

  }

  resetVisibility(){
    this._visibilityService.resetParams();
  }


  //getSubsidyPosition() {
  //  return this.subsidyPosition;

  //}


  setCardNumPosition(newCardPosition) {
    this.cardPosition = newCardPosition
    this.filterChange.next({ cardPositionList: this.cardPosition });
  }

  startSegment(){
    this._filterDateService.setCheckSegment('date');
  }

  clearDate(){
    this._filterDateService.resetDate();
  }

}

interface FilterSubsidies {
  cardNumber?: string,
  description?: string,
  cardID?: number,
  codeMachine?: number,
  cardID2?: number
  position?: number,
  reset?: boolean,
  name?: string,
  code?: number,
  clientId?: number,
  nameClient?: string,
  tillId?: number,
  machineID?: number,
  codeClient?: string
  deleteClick?: boolean,
  cardSelected?: {},
  cardTotalSelected?: [],
  subsidyPositionList?: {},
  cardPositionList?: {},
  updated?: boolean,
  subsidyId?: number

}

interface loadingSubsidy {
  loadingSubsidy: boolean,
  loadedSubsidy: boolean,
}

interface loadingSubsidy2 {
  loadingSubsidy2: boolean,
  loadedSubsidy2: boolean,

}



