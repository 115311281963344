import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

// utils
import { pcWidth } from 'src/app/utils';

@Component({
  selector: 'app-info-success-alert',
  templateUrl: './info-success-alert.component.html',
  styleUrls: ['./info-success-alert.component.scss'],
})
export class InfoSuccessAlertComponent implements OnInit {

  @Input({required: true}) textTranslate = '';

  pcWidth = pcWidth;

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}

}
