import { Preferences } from '@capacitor/preferences';

import { StorageI } from '../storage.interface';

export class StorageCapacitor implements StorageI {
  constructor() {}

  async get(key: string): Promise<String>{
    const get = await Preferences.get({ key });
    return get?.value || null;
  }

  async set(key: string, value: any) {
    await Preferences.set({
      key,
      value,
    });
  }

  async remove(key: string){
    await Preferences.remove({ key});
  }

  async clear(){
    await Preferences.clear()
  }
}
