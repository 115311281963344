// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.start-line {
  margin-top: 2%;
  text-align: left;
  font-size: 0.8em;
  color: var(--ion-color-danger);
}

.p-15 {
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login/components/force-password-renewal/force-password-renewal.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,8BAAA;AACJ;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".start-line{\r\n    margin-top: 2%;\r\n    text-align: left;\r\n    font-size: 0.8em;\r\n    color: var(--ion-color-danger);\r\n}\r\n\r\n.p-15 {\r\n  margin-bottom: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
