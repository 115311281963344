import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  ModalController,
  ToastController,
  NavController,
  MenuController,
  Platform,
} from '@ionic/angular';
import { Subject, Subscription, lastValueFrom } from 'rxjs';

import { SwiperComponent } from 'swiper/angular';

// component
import { ForcePasswordRenewalComponent } from '../force-password-renewal/force-password-renewal.component';
import { ConfidentialityV2vComponent } from '../confidentiality-v2v/confidentiality-v2v.component';
import { ConfidentialityComponent } from '../confidentiality/confidentiality.component';
import { SendEmailForcePasswordComponent } from '../send-email-force-password/send-email-force-password.component';
import { WarningNotEmailComponent } from '../warning-not-email/warning-not-email.component';

// multilanguage
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../share/services/languages.service';

// services
import { AuthService } from '../../../../services/auth.service';
import { SkinMangementService } from '../../../../services/skin-mangement.service';
import { PermissionService } from '../../../../services/permission.service';
import { MainMenuService } from '../../../../share/components/main-menu/service/main-menu.service';
import { GlobalService } from '../../../../services/global.service';
import { ColorModeService } from '../../../../services/color-mode.service';

// models
import { Company, Credential, AuthLogin, ColorTheme } from '../../../../models';

// utils
import {
  durationErrorToast,
  pcWidth,
  pcWidthMix,
  type,
  setCrypto,
  clientesavDomain,
  errorsTypes,
  UtilsTranslate,
  MULTI_LOGIN_ROLE,
  addElemStyle,
  SIZE_FOOTER_V2V,
  isAllianceDomain,
  openMainMenu,
} from '../../../../utils';
import {
  IMAGE_LOGIN_ELORA_FRONT,
  LOGO_ELORA_CENTRAL_DARK,
  LOGO_ELORA_CENTRAL_LIGHT,
  LOGO_ELORA_HORIZONTAL_DARK,
  LOGO_ELORA_HORIZONTAL_LIGHT,
} from '../../utils';

@Component({
  selector: 'app-login-elora',
  templateUrl: './login-elora.component.html',
  styleUrls: ['./login-elora.component.scss'],
})
export class LoginEloraComponent implements OnInit {

  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;
  plataforma: string = '';

  loginUser: Credential = {
    user: '',
    password: '',
  };
  // pos slide
  screenNext: number = 0;
  showErrorEmail = false;
  showErrorAgreement = false;

  acceptAgreement: boolean = false;
  fecha = new Date();

  company: Company;
  type = type;

  eventsSubjectClick: Subject<boolean> = new Subject<boolean>();

  loading = false;

  showPassword = false;

  forcePasswordActive = false;

  isClientSav: boolean = false;

  isLightColor: boolean = true;
  colorTheme: ColorTheme = 'light';

  changeLanguage$: Subscription;
  changeColor$: Subscription;
  logOutAction$: Subscription;

  sizeFooterV2V = SIZE_FOOTER_V2V;

  IMAGE_LOGIN_ELORA_FRONT = IMAGE_LOGIN_ELORA_FRONT;
  LOGO_ELORA_HORIZONTAL_LIGHT = LOGO_ELORA_HORIZONTAL_LIGHT;
  LOGO_ELORA_HORIZONTAL_DARK = LOGO_ELORA_HORIZONTAL_DARK;
  LOGO_ELORA_CENTRAL_LIGHT = LOGO_ELORA_CENTRAL_LIGHT;
  LOGO_ELORA_CENTRAL_DARK = LOGO_ELORA_CENTRAL_DARK;

  // size
  IMAGE_FRONT_LG = 1200;
  IMAGE_FRONT_MD = 950;
  FONT_SIZE_COPYRIGHT = 1300;

  // translates
  errorServerTranslate = '';
  errorLoginTranslate = '';
  errorLogin2Translate = '';
  errorLogin3Translate = '';
  errorTranslate = '';

  @ViewChild('Field1', { static: false }) loginInput?: any;

  constructor(
    public readonly platform: Platform,
    public menuController: MenuController,
    private modalCtrl: ModalController,
    private toastController: ToastController,
    private navCtrl: NavController,
    private _globalService: GlobalService,
    private _languageService: LanguageService,
    private translate: TranslateService,
    private _authService: AuthService,
    private _permissionService: PermissionService,
    private skinService: SkinMangementService,
    private _mainMenuService: MainMenuService,
    private _colorModeService: ColorModeService,
    private utils: UtilsTranslate,
    private elem: ElementRef
  ) {}

  ngOnInit() {
    this.menuController.enable(false, 'first');
    this.menuController.enable(false, 'custom');

    this.setCurrentPlatform();
    this.savePlaceholders();
    this.changeLanguage$ = this._languageService.languageChange.subscribe(
      (value) => {
        this.translate.setDefaultLang(value);
        this.savePlaceholders();
      }
    );

    this.company = this.skinService.getCompany();

    this.forcePasswordActive = false;

    this.logOutAction$ = this._authService.logOutAction.subscribe((logout) => {
      if (logout) this.loading = false;
    });

    setTimeout((_) => {
      this.loginInput.setFocus();
    }, 200);

    this.isClientSav = clientesavDomain();

    this.getColorMode();

    this.setColorVariable();
  }

  ngOnDestroy() {
    if (this.changeColor$) this.changeColor$.unsubscribe();
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if (this.logOutAction$) this.logOutAction$.unsubscribe();
  }

  getColorMode() {
    this.isLightColor = this._colorModeService.getIsLightColor();
    this.colorTheme = this._colorModeService.getColorTooltipTheme();

    this.changeColor$ = this._colorModeService.changeColor$.subscribe(
      (isLightColor) => {
        this.isLightColor = isLightColor;
        this.colorTheme = this._colorModeService.getColorTooltipTheme();
      }
    );
  }

  setColorVariable() {
    addElemStyle(
      this.elem,
      '--color-text-company',
      this.company.colorCompanyText
    );

    addElemStyle(
      this.elem,
      '--color-border-check-box-company',
      this.company.colorBorderCheckBox
    );

    addElemStyle(
      this.elem,
      '--color-text-hover-company',
      this.company.colorTextHover
    );
  }

  setCurrentPlatform() {
    if (this.platform.is('ios')) {
      this.plataforma = 'ios';
    } else {
      this.plataforma = 'android';
    }
  }

  async showAgreement() {
    const isLightColor = this._colorModeService.getIsLightColor();

    let modal: any;
    if (this.company.companySkin == this.type.alliance) {
      modal = await this.modalCtrl.create({
        component: ConfidentialityComponent,
        cssClass:
          this.platform.width() > this.pcWidth
            ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
            : '',
      });
    } else {
      modal = await this.modalCtrl.create({
        component: ConfidentialityV2vComponent,
        cssClass:
          this.platform.width() > this.pcWidth
            ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
            : '',
      });
    }

    if (modal) {
      await modal.present();

      const { data } = await modal.onWillDismiss();

      if (data?.accept) {
        this.acceptAgreement = true;
      } else {
        this.acceptAgreement = false;
      }
    }
  }

  activateCheckbox() {
    this.acceptAgreement = !this.acceptAgreement;
  }

  createNewUser() {
    this.navCtrl.navigateRoot('/sign-up', { animated: true });
  }

  activateUser() {
    this.navCtrl.navigateRoot('/activate-account', { animated: true });
  }

  async sendLogin() {
    if (!this.loginUser.user || !this.loginUser.password) {
      return;
    }

    if (this.loading) {
      return;
    }

    if (!this.acceptAgreement) {
      this.showErrorAgreement = true;
      this.presentToast(this.errorLogin3Translate);
      return;
    }

    this.loading = true;

    try {
      const validateUser = await lastValueFrom(
        this._authService.validateUser(this.loginUser.user)
      );

      if (validateUser.showEmail) {
        this.showNoEmail();
        this.loading = false;
        return;
      }

      if (validateUser.showChangePassword) {
        this.showChangePassword();
        this.loading = false;
        return;
      }

      let userData: Credential = {
        user: this.loginUser.user,
        password: setCrypto(this.loginUser.password),
      };

      try {
        this.loading = true;
        this._globalService.logOut();
        let resLogin: AuthLogin = await lastValueFrom(
          this._authService.login(userData)
        );

        await this._authService.guardarToken(resLogin.token);
        await this._authService.saveStorage(resLogin);

        this._permissionService.getPermissions(true);

        this._mainMenuService.newLogin();

        this.loading = false;

        this.navigateLogin(resLogin);
      } catch (error) {
        this.loading = false;

        if (error == errorsTypes.login_error) {
          this.presentToast(this.errorLoginTranslate);
        } else if (error === '504 Gateway timeout') {
          this.utils.presentToastTranslate('error_timeout', true, 0);
        } else {
          this.presentToast(this.errorLogin2Translate);
        }
      }
    } catch (error) {
      if (error === '504 Gateway timeout') {
        this.utils.presentToastTranslate('error_timeout', true, 0);
      } else {
        this.utils.showError();
      }
      this.loading = false;
    }
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: durationErrorToast,
      position: 'top',
      color: 'danger',
    });

    if (toast) {
      toast.present();
    }
  }

  async showNoEmail() {
    const isLightColor = this._colorModeService.getIsLightColor();

    const modal = await this.modalCtrl.create({
      component: WarningNotEmailComponent,
      cssClass:
        this.platform.width() > this.pcWidth
          ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
          : '',
    });

    if (modal) await modal.present();
  }

  async showChangePassword() {
    const isLightColor = this._colorModeService.getIsLightColor();

    const modal = await this.modalCtrl.create({
      component: ForcePasswordRenewalComponent,
      componentProps: {
        username: this.loginUser.user,
      },
      cssClass:
        this.platform.width() > this.pcWidth
          ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
          : '',
    });

    if (modal) await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data?.remember) {
      await this.showSendEmailForcePassword();
      this._authService.sendEmailForcePassword(this.loginUser.user).subscribe({
        next: () => {},
        error: () => {
          this.presentToast(this.errorTranslate);
        },
      });
    }
  }

  showRecoverPassword() {
    this.navCtrl.navigateRoot('/recover-password', { animated: true });
  }

  changeShowPassword(event) {
    this.showPassword = event?.detail?.checked;
  }

  async showSendEmailForcePassword() {
    const modal = await this.modalCtrl.create({
      component: SendEmailForcePasswordComponent,
    });

    if (modal) await modal.present();
  }

  changeAcceptAgreement(event) {
    if (
      this.forcePasswordActive &&
      this.acceptAgreement &&
      this.screenNext == 1
    ) {
      this.showChangePassword();
    }
  }

  navigateLogin(resLogin: AuthLogin) {
    if (
      resLogin?.roleName === MULTI_LOGIN_ROLE &&
      isAllianceDomain(this.company)
    ) {
      this._authService.saveMultiLogin(resLogin.token).then(() => {
        this.navCtrl.navigateRoot('/user/select-role', { animated: true });
      });

      return;
    }

    this._authService.haveMultiLogin();

    this.openMenu();

    if (clientesavDomain()) {
      this.navCtrl.navigateRoot('/user/home', { animated: false });
    } else {
      this.navCtrl.navigateRoot('/user/home', { animated: true });
    }
  }

  openMenu() {
    // if (this.platform.width() < pcWidth) {
    //   this.menuController.enable(true, 'first');
    //   this.menuController.open('first');
    // }
    openMainMenu(this.menuController)
  }

  gotoContact() : void {
    window.open("https://www.v2vfactory.tech/", "_blank");
  }

  savePlaceholders() {
    this.translate
      .get([
        'login.error_server',
        'login.error_message',
        'login.error_login2',
        'login.error_login3',
        'login.error_acceptAgreement',
        'error',
      ])
      .subscribe((translates) => {
        this.errorServerTranslate = translates['login.error_server'];
        this.errorLoginTranslate = translates['login.error_message'];
        this.errorLogin2Translate = translates['login.error_login2'];
        this.errorLogin3Translate = translates['login.error_login3'];
        this.errorTranslate = translates['error'];
      });
  }
}
