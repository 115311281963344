// models
import { GroupsNameRouteValids } from '../models';
import { Company } from '../../../../models';

// utils
import { type } from 'src/app/utils';
import { ICONS_GROUP_ROUTE } from '../constant';

export const getIconByGroup = (
  group: GroupsNameRouteValids,
  company: Company
) => {
  if (ICONS_GROUP_ROUTE[group]?.['icon2']) {
    const icon =
      company.companySkin == type.alliance
        ? ICONS_GROUP_ROUTE[group]?.['icon']
        : ICONS_GROUP_ROUTE[group]?.['icon2']

    return {icon}
  }
  return ICONS_GROUP_ROUTE[group];
};


