import {
  Component,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  Input,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

// material
import { MatDateRangePicker } from '@angular/material/datepicker';

// models
import { Company, FilterDateServiceI } from '../../../../../../../../models';
import { CheckSegment, RangeDate } from '../../../../../models';

@Component({
  selector: 'app-datepicker-range-material-input-rocca',
  templateUrl:
'../share/datepicker-range-material-input-company.component.html',
  styleUrls: ['./datepicker-range-material-input-rocca.component.scss'],
})
export class DatepickerRangeMaterialInputRoccaComponent implements OnInit {
  @Input() company: Company;
  @Input() dateText: string = '';
  @Input() _filterDate: FilterDateServiceI;
  @Input() rangePeriod: RangeDate;
  @Input() checkSegment: CheckSegment;

  @Output() changePeriod = new EventEmitter<{
    startDate: Date | null;
    endDate: Date | null;
  }>();

  range: FormGroup;

  minDate: Date;
  maxDate: Date;

  isLightColor: boolean = true;

  changeColor$: Subscription;
  filterChange$: Subscription;

  styleCompany = '';

  @ViewChild('picker') picker: MatDateRangePicker<any>;

  constructor() {}

  ngOnInit() {
    this.range = new FormGroup({
      start: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required),
    });

    this.setRange()
    this.filterChange$ = this._filterDate.filterChange$.subscribe(() => {
      this.setRange()
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.rangePeriod) {
      this.minDate = this.rangePeriod.minDate;
      this.maxDate = this.rangePeriod.maxDate;
    }
  }

  ngOnDestroy(): void {
    if (this.filterChange$) this.filterChange$.unsubscribe();
  }

  reOpenCalendar() {
    this.picker.open();
  }

  closed() {
    if (this.range.get('start').value && this.range.get('end').value) {
      this.changePeriod.emit({
        startDate: this.range.get('start').value,
        endDate: this.range.get('end').value,
      });
    }
  }

  setRange(){
    this.range.controls.start.setValue(
      new Date(this._filterDate.getDateStart())
    );
    this.range.controls.end.setValue(new Date(this._filterDate.getDateEnd()));
  }
}
