import { Injectable } from '@angular/core';
import { Operator } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class FilterOperatorModalService {
  operator:Operator = null

  constructor() { }


  setOperator(newOperator){
    this.operator = newOperator
  }

  getOperator(){
   // console.log('this', this.operator)
    return this.operator
  }

  getOperatorName(){
    return this.operator.description
  }

  getOperatorId(){
    
    return this.operator?.id

  }

  deleteOperator(){
    this.operator = null
  }
}
