export interface OnlineShopRoutesTranslates {
  onlineShop: string;
  ecommerceManager: string;
  checkOrders: string;
  ordersManager: string;
}

export const getOnlineShopRoutes = (
  translates: OnlineShopRoutesTranslates
): { route: string; name: string }[] => {
  return [
    { route: '/user/online-shop', name: translates.onlineShop },
    {
      route: '/user/ecommerce-manager',
      name: translates.ecommerceManager,
    },
    { route: '/user/check-orders', name: translates.checkOrders },
    { route: '/user/orders-manager', name: translates.ordersManager },
  ];
};
