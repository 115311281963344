// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-flex-center {
  display: flex;
  justify-content: center;
}

.text-start {
  text-align: start;
  margin-left: 5px;
}

.text-title {
  font-size: 1.1em;
}

.text-title-sm {
  font-size: 0.9em;
}

.center-spinner {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/charts/canvas-ng2-chart/canvas-ng2-chart.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF","sourcesContent":[".d-flex-center{\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.text-start{\r\n  text-align: start;\r\n  margin-left: 5px;\r\n}\r\n\r\n.text-title{\r\n  font-size: 1.10em;\r\n}\r\n\r\n.text-title-sm{\r\n  font-size: 0.9em;\r\n}\r\n\r\n.center-spinner{\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
