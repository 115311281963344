// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.size-sm {
  font-size: 0.8em;
}

.dark-color {
  color: var(--ion-color-dark) !important;
}

.height-auto {
  height: auto;
  width: auto;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-hover-icon-button:hover {
  color: var(--color-button-table-hover-company) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/aena/share/components/ui/area-table-aena/area-table-aena.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,uCAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,yDAAA;AACF","sourcesContent":[".size-sm {\r\n  font-size: 0.8em;\r\n}\r\n\r\n.dark-color {\r\n  color: var( --ion-color-dark) !important\r\n}\r\n\r\n.height-auto {\r\n  height: auto;\r\n  width: auto;\r\n}\r\n\r\n.d-flex {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.color-hover-icon-button:hover{\r\n  color: var(--color-button-table-hover-company) !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
