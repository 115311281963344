import { pcWidth } from "src/app/utils";
import { DIMENSION_PC_WIDTH_FIX_MENU, DIMENSION_SHOW_ONE_CHART, DIMENSION_SHOW_ONE_CHART_MENU } from "..";

const SIZE_CHANGE_PAYMENT_MD = 940;
const SIZE_CHANGE_PAYMENT_FIX_MENU_MD = 1200;
// const SIZE_CHANGE_PAYMENT_FIX_MENU_SM = DIMENSION_PC_WIDTH_FIX_MENU;
const SIZE_CHANGE_PAYMENT_FIX_MENU_SM = 850;

const CLASS_NAMES = {
  pc: 'filter-payments',
  md: 'margin-payments-md d-flex-center-100',
  md2: 'd-flex-center-100 d-flex-filter-payment',
  sm: 'center'
}

const CLASS_NAMES_FIX_MENU = {
  pc: 'filter-payments-fix-menu',
  md: 'margin-payments-fix-menu-md d-flex-center-100',
  md2: 'd-flex-center-100',
  sm: 'center'
}

export const getClassNamesPayments = (width: number, isOpenFixMenu: boolean): string => {
    if(!isOpenFixMenu){
      return getClassNamesPaymentsNotFixMenu(width)
    }else{
      return getClassNamesPaymentsFixMenu(width)
    }
}


const getClassNamesPaymentsNotFixMenu = (width: number) => {
  if(width > DIMENSION_SHOW_ONE_CHART){
    return CLASS_NAMES.pc;
  }

  if(width <= pcWidth ){
    return CLASS_NAMES.sm;
  }

  if(width > SIZE_CHANGE_PAYMENT_MD){
    return CLASS_NAMES.md;
  }

  return CLASS_NAMES.md2;
}


const getClassNamesPaymentsFixMenu = (width: number) => {
  if(width > DIMENSION_SHOW_ONE_CHART_MENU){
    return CLASS_NAMES_FIX_MENU.pc;
  }

  if(width <= SIZE_CHANGE_PAYMENT_FIX_MENU_SM ){
    return CLASS_NAMES_FIX_MENU.sm;
  }

  if(width > SIZE_CHANGE_PAYMENT_FIX_MENU_MD){
    return CLASS_NAMES_FIX_MENU.md;
  }

  return CLASS_NAMES_FIX_MENU.md2;
}
