// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeholder-label {
  color: rgba(0, 0, 0, 0.3);
}

.text-size {
  font-size: 1.2em;
}

.mt-12 {
  margin-top: 12px;
}

@media (min-width: 602px) and (max-width: 1000) {
  .text-size {
    font-size: 0.8em;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-client/filter-client.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI;IACI,gBAAA;EACN;AACF","sourcesContent":[".placeholder-label{\r\n    color: rgba(black, 0.3);\r\n}\r\n\r\n.text-size{\r\n    font-size: 1.2em;\r\n}\r\n\r\n.mt-12{\r\n    margin-top: 12px;\r\n}\r\n\r\n@media (min-width: 602px) and (max-width: 1000) {\r\n    .text-size{\r\n        font-size: 0.8em;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
