import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

// Service
import { TranslateService } from '@ngx-translate/core';
import { AenaShareService } from '../../../services';
import { FilterAenaShareService } from '../../../services/filter-aena-share.service';


// models
import { Company } from 'src/app/models';
import { ClientAena, TillAena } from '../../../models';

// utils
import { pcWidthMix, UtilsTranslate } from 'src/app/utils';

@Component({
  selector: 'app-filter-guided-till-aena',
  templateUrl: './filter-guided-till-aena.component.html',
  styleUrls: ['./filter-guided-till-aena.component.scss'],
})
export class FilterGuidedTillAenaComponent implements OnInit {

  pcWidthMix = pcWidthMix;

  config: SwiperOptions = {
    pagination: { clickable: false },
    keyboard: false,
  };

  @Input() company: Company;
  @Input() idCloseModal: string = 'closeFilterObjectModal'
  @Input() titles: string[] = []

   @Input() clients: ClientAena[] = [];
   tills: TillAena[] = [];

   client: ClientAena = null;
   clientText: string = '';

   till: TillAena = null;
   tillText: string = '';

   pos: number = 0;
   loadingTill: boolean = true;

   searchTillTranslate = '';

   @ViewChild('swiperTillAenaFilter', { static: false }) swiper: SwiperComponent;

  constructor(
    private modalCtrl: ModalController,
    public readonly platform: Platform,
    private utils: UtilsTranslate,
    private translate: TranslateService,
    private _assignArticlesFamilyAenaService: AenaShareService,
    private _filterAenaShareService: FilterAenaShareService
  ) { }

  ngOnInit() {
    this.searchTillTranslate = this.translate.instant('code')
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  changeClientAena(client){
    this.client = client as ClientAena;

    this._filterAenaShareService.setClientId(this.client?.clientID)
    if(this.client){
      this.clientText = this.client.client;
      this.goTill()
      this.loadingTill = true;
      this._assignArticlesFamilyAenaService.getTills(this.client?.clientID).subscribe(res => {
        this.loadingTill = false;
        this.tills = res;
      }, (error) => {
        this.utils.presentToastTranslate('error', true, 0)
        this.loadingTill = false;
      })
    }
  }

  goTill(){
    this.pos = 1;
    this.swiper.swiperRef.slideNext(300);
  }

  goClient(){
    this.pos = 0;
    this.swiper.swiperRef.slidePrev(300);
  }

  changeTillAena(till: any){
    this.till = till as TillAena;
    if(till){
      this.tillText = (this.till?.machineCode || '').toString()
      this.modalCtrl.dismiss(
        {element: this.till}
      )
    }
  }
}
