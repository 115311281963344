// company
import { CardNames, Company } from '../../app/models/';

// utils
import { FONT_FAMILY } from 'src/app/utils';

export const alliance: Company = {
  tag: 'alliance',
  companySkin: 'AllianceVending',
  name: 'Arbitrade',
  environment: 'baseUrlAlliance',
  appConfig: 'BaseURLAlliance',

  nameCard: CardNames.arbitradePay,
  minCard: 6,
  maxCard: 8,
  url: 'arbitrade.v2vfactory.com',
  url_test: 'testing.v2vfactory.com',
  url_local: 'localhost',

  companyId: 1,
  operatorGroupId: 2,

  color: 'alliance',
  color2: 'alliance2',
  color3: 'alliance3',
  colorCancel: 'alliance3',

  colorText: 'alliance-text',
  colorText2: 'alliance-text2',
  colorFilter: 'filter-alliance',

  colorNavBar: 'dark-always',
  colorMenuText: 'light-always',
  colorIconMenuNavbar: 'light-always',
  colorErrorToast: 'dark',

  bgColor1: 'alliance-color-toolbar',
  colorTextTitle: 'alliance-color-text-title',
  titlePage: 'alliance-title-page',

  // rgb
  colorRGB: '#E84B1E',

  // sliders
  colorBullets: 'color-bullet-alliance',

  // Menu
  activeLink: 'active-link-alliance',
  groupItemMenu: 'group-item-menu-alliance',
  itemMenu: 'item-menu-alliance',

  // Profile
  ionCardHeader: 'ion-card-header-alliance',
  ionCardText1: 'ion-card-text1-alliance',
  ionCardText2: 'ion-card-text2-alliance',
  ionTextCardFooter: 'text-card-footer-alliance',

  // date-period
  ionSegmentButtonStyle: 'ion-segment-button-alliance-style',
  ionSegmentButtonStyle2: 'ion-segment-button-alliance-style2',
  tableFilter: 'filter-table-alliance',

  bgNavbar: 'bg-navbar-alliance',
  openMenuColor: 'alliance',

  // table
  bgTable: 'bg-table-alliance',
  bgTable1: 'bg-table-switch-pair-alliance',
  bgTable2: 'bg-table-switch-odd-alliance',
  bgTable3: 'bg-filter-alliance',
  bgHoverTable: 'bg-hover-table-alliance',
  bgHoverTableWithoutFilter: 'bg-hover-table-sm-alliance',
  subHeaderTable: 'subheader-table-alliance',
  headerVerticalTable: 'header-vertical-table-alliance',

  // spinner
  spinnerColor: 'spinner-color-alliance',

  // site
  blockTable: 'block-table-alliance',
  bgTableBlockHover: 'bg-table-block-hover-alliance',
  bgTableSelected: 'bg-table-selected-alliance',

  //label not found, warning
  labelDialog: 'label-dialog-alliance',

  // top 5, product sold
  labelDialogLoading: 'label-dialog-loading-alliance',
  textDialogLoadingColor: 'dark-always',

  // card (load-credit-card)
  cardTop: 'card-top-alliance',
  cardBottom: 'card-bottom-alliance',

  infoAlert: 'info-alert-alliance',

  segmentIos: 'segment-ios-alliance',

  // chartColors
  colorRGBTitleDark: '#EEE4D5',
  chartColors: [
    {
      backgroundColor: [
        '#E84B1E',
        '#E95A30',
        '#E96A43',
        '#EA7955',
        '#EA8867',
        '#EB987A',
        '#ECA78C',
        '#ECB69E',
        '#EDC5B0',
        '#EDD5C3',
        '#EEE4D5',
      ],
      borderColor: [
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
      ],
    },
  ],
  chartColorsDark: [
    {
      backgroundColor: [
        '#EEE4D5',
        '#EDD5C3',
        '#EDC5B0',
        '#ECB69E',
        '#ECA78C',
        '#EB987A',
        '#EA8867',
        '#EA7955',
        '#E96A43',
        '#E95A30',
        '#E84B1E',
      ],
      borderColor: [
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
        '#5b4331',
      ],
    },
  ],

  ionColorToogle: 'ion-color-toogle-alliance',

  // online shop
  borderCard: 'border-card-alliance',
  borderCart: 'border-cart-alliance',
  border: 'border-alliance',

  // ecommerce-manager
  bgTable1Item: 'bg-table1-item-alliance',
  bgTable2Item: 'bg-table2-item-alliance',
  colorTextNoEditable: 'color-no-editable-text-alliance',
  colorTextNoEditableItem: 'var(--ion-color-alliance-not-editable2)',

  // notification-manager
  buttonInfo: {
    backgroundColor: '#E84B1E',
    borderColor: '#E84B1E',
    textColor: '#EEE4D5',
    text: '',
    url: 'https://arbitrade.es/',
  },

  // access-web
  segmentVertical: 'segment-alliance-vertical',
  swiperBgAndBorder: 'swiper-alliance-bg',
  itemCheckboxAccessWeb: 'item-checkbox-alliance-access-web',
  checkboxAccessWeb: 'checkbox-alliance-access-web',
  bgColorContent: 'bg-color-content-alliance',
  borderHeadDark: 'border-head-dark-alliance',

  // range slider (sales-module)
  colorBallRange: 'var(--ion-color-alliance-tint)',
  colorLineRange: 'var(--ion-color-alliance)',
  colorInsideBallRange: 'var(--ion-color-alliance-contrast)',
  colorInsideBallRangeActive: 'var(--ion-color-alliance-shade)',

  // pagination
  paginationCurrentBg: 'var(--ion-color-alliance)',
  paginationColorDark: 'var(--ion-color-dark)',
  paginationColorLight: 'var(--ion-color-light)',

  // tag status
  colorIconWithHoverTable: 'color-icon-with-hover-table-alliance',

  // pre login
  colorCompanyText: 'var(--ion-color-alliance-text)',
  colorBorderCheckBox: 'var(--ion-color-login-alliance-checkbox)',
  colorCompanyText2: 'var(--ion-color-alliance-text3)',

  // custom alert
  customAlertStyle: 'custom-alert-alliance',

  // ecommerce manager
  colorButtonTable: 'alliance-button-table',
  colorButtonTableHover: 'var(--ion-color-alliance-button-table-hover)',

  // charts v4
  colorsCharts: [
    '#E84B1E',
    '#E95A30',
    '#E96A43',
    '#EA7955',
    '#EA8867',
    '#EB987A',
    '#ECA78C',
    '#ECB69E',
    '#EDC5B0',
    '#EDD5C3',
    '#EEE4D5',
  ],

  backgroundChart: [
    '#E84B1E',
    '#E95A30',
    '#E96A43',
    '#EA7955',
    '#EA8867',
    '#EB987A',
    '#ECA78C',
    '#ECB69E',
    '#EDC5B0',
    '#EDD5C3',
    '#EEE4D5',
  ],

  backgroundChartDark: [
    '#EEE4D5',
    '#EDD5C3',
    '#EDC5B0',
    '#ECB69E',
    '#ECA78C',
    '#EB987A',
    '#EA8867',
    '#EA7955',
    '#E96A43',
    '#E95A30',
    '#E84B1E',
  ],

  borderChart: [
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
  ],

  borderChartDark: [
    '#BA3C18',
    '#BA3C18',
    '#BA3C18',
    '#BA3C18',
    '#BA3C18',
    '#BA3C18',
    '#BA3C18',
    '#BA3C18',
    '#BA3C18',
    '#BA3C18',
    '#BA3C18',
  ],

  donutBackground: ['rgba(238, 228, 213, 1)', 'rgba(217, 83, 79, 1)'],
  donutBackgroundDark: ['rgba(238, 228, 213, 1)', 'rgba(207, 102, 121, 1)'],
  donutBackgroundBorder: ['rgba(238, 228, 213, 1)', 'rgba(217, 83, 79, 1)'],
  donutBackgroundDarkBorder: ['rgba(238, 228, 213, 1)', 'rgba(207, 102, 121, 1)'],

  colorTextVar: 'var(--ion-color-alliance-text)',
  colorTextHover: 'var(--ion-color-alliance-text-hover)',

  // phone material
  colorTableVisibilityBg: 'var(--ion-color-alliance-tint-switch2)',
  colorTableVisibilityPlaceholder: '#EEE4D5',
  colorTablePhoneLightEg: 'medium',
  useDifferentColorPlaceholderTable: true,

  // till-alert, qr-request
  successTable: 'success',
  qrRequestColorTable: 'qr-request',

  // notification-panel
  colorAndroidIconTable: 'var(--ion-color-alliance-android-icon)',

  // dashboard welcome
  colorMonthBar: [
    '#F4F0ED',
    '#EBE4DF',
    '#E2D8D0',
    '#D9CCC2',
    '#D0C0B4',
    '#C7B4A5',
    '#BFA897',
    '#B69C88',
    '#AD907A',
    '#A4846C',
    '#9B785D',
    '#926C4F',
  ],

  colorMonthBarDark: [
    '#926C4F',
    '#9B785D',
    '#A4846C',
    '#AD907A',
    '#B69C88',
    '#BFA897',
    '#C7B4A5',
    '#D0C0B4',
    '#D9CCC2',
    '#E2D8D0',
    '#EBE4DF',
    '#F4F0ED',
  ],

  customPopoverDashboard: 'custom-popover-dashboard-alliance',

  homeBG: 'alliance-home',

  // User Documentation
  colorChipSuccess: 'var(--ion-color-alliance-chip-success)',
  colorIconChipSuccess: 'alliance-chip-icon-success',

  colorCardBg: 'alliance-card-bg',

  textLink: 'text-link-alliance',

  familiesFont: {
    h1: FONT_FAMILY.INTER,
    other: FONT_FAMILY.INTER,
  },

  documentation: {
    title: {
      fontFamily: FONT_FAMILY.INTER,
      color: 'alliance-text',
    },
    card: {
      fontFamilyTitle: FONT_FAMILY.INTER,
      fontFamilySubtitle: FONT_FAMILY.INTER,
      path: 'arbitrade'
    },
  },

  borderDarkCard: 'border-card-dark-allince',
  // subsidy
  colorInvalidIconButton: 'dark',
};
