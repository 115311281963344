import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// services
import { ColorModeService } from '../../../services/color-mode.service';

// models

import { Company } from '../../../models';


@Component({
  selector: 'app-color-change-button',
  templateUrl: './color-change-button.component.html',
  styleUrls: ['./color-change-button.component.scss'],
})
export class ColorChangeButtonComponent implements OnInit {
  @Input() company: Company;
  @Input() isFab: boolean = false;
  @Input() sizePC: boolean = false;

  isLightColor: boolean = true;
  changeColor$: Subscription;

  isLoginElora: boolean = false;
  routerEvents$: Subscription;

  constructor(
    private _colorModeService: ColorModeService,
    protected router: Router
  ) {}

  ngOnInit() {
    this.isLoginElora =
      this.router.url === '/login' &&
      this.company?.companySkin === 'V2VFactory';

    this._colorModeService.getIsLightColor();
    this.changeColor$ = this._colorModeService.changeColor$.subscribe((res) => {
      this.isLightColor = res;
    });

    if (this.company?.companySkin === 'V2VFactory') {
      this.routerEvents$ = this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationEnd) {
          this.isLoginElora =
            this.router.url === '/login' &&
            this.company?.companySkin === 'V2VFactory';
        }
      });
    }
  }

  changeModeColor() {
    this._colorModeService.changeColor();
  }

  ngOnDestroy(): void {
    if (this.changeColor$) this.changeColor$.unsubscribe();
    if (this.routerEvents$) this.routerEvents$.unsubscribe();
  }
}
