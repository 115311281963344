import { addTimeDate } from 'src/app/utils';
import { DIFF_LAST_DAY_WITH_INFO } from '../';

export const getLastDayWithInfo = () => {
  const dateCurrent = new Date();

  return addTimeDate({
    startDate: dateCurrent,
    increment: DIFF_LAST_DAY_WITH_INFO,
    typeIncrement: 'days',
  });
};
