export const getDaysByShortDate = (shortDate: number) => {
  const year = Number(shortDate.toString().substring(0, 4));
  const month = Number(shortDate.toString().substring(4, 6));

  return getDaysInMonth(month, year);
};

export const getDaysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};
