import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-filter-template-selected',
  templateUrl: './filter-template-selected.component.html',
  styleUrls: ['./filter-template-selected.component.scss'],
})
export class FilterTemplateSelectedComponent implements OnInit {

  @Input() company: Company;
  @Input() id: string = 'filterTemplateId';
  @Input() title: string ='select';
  @Input() subtitle: string = '';
  @Input() nameID: string = 'id';
  @Input() haveDelete: boolean = true;

  @Input() elements: any[] = []
  @Input() elementSelectedId: number = null;


  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.elements.map(element => element.selected = false)
    const operator = this.elements.find(element => element[this.nameID] === this.elementSelectedId)
    operator.selected = true;
  }

  removeOther() {
    this.elements.map(element => element.selected = false)
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  filterTemplate(){
    const operatorSelected = this.elements.find(operator => operator.selected)
    this.modalCtrl.dismiss({element: operatorSelected})
  }

  deleteTemplate(){
    this.modalCtrl.dismiss({delete: true})
  }

}

