import { Component, OnInit, Input, EventEmitter, Output, ElementRef } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { Company } from 'src/app/models';
import { ArticlesAena } from '../../../../share/models';

// utils
import { addElemStyle, pcWidth, substringHTMLWith3Points } from 'src/app/utils';
import { MAX_LENGTH_FAMILIY_SCREEN_SM } from '../../../utils';

@Component({
  selector: 'app-table-assign-article-aena',
  templateUrl: './table-assign-article-aena.component.html',
  styleUrls: ['./table-assign-article-aena.component.scss'],
})
export class TableAssignArticleAenaComponent implements OnInit {

  @Input() company: Company;
  @Input() firstTime: boolean = true;
  @Input() loading = true;
  @Input() havePublicZone = false;
  @Input() havePrivateZone = false;
  @Input() totalArticles: number = 0;
  @Input() totalArticlesWithoutFilter: number = 0;
  @Input() articlesPage: ArticlesAena[] = [];
  @Input() isCheck: boolean = false;
  @Input() haveDelete: boolean = true;
  @Input() haveArticleClicked: boolean = false;

  @Output() openModalPublic = new EventEmitter<number>()
  @Output() openModalPrivate = new EventEmitter<number>()
  @Output() openDeleteAlert = new EventEmitter<number>()

  // filters
  @Input() useFilter: boolean = true;
  @Input() articleFilter: string = '';
  @Input() publicGroupFilter: string = '';
  @Input() privateGroupFilter: string = '';

  @Output() openFilterArticle = new EventEmitter()
  @Output() openFilterPublic = new EventEmitter()
  @Output() openFilterPrivate = new EventEmitter()
  @Output() articleClicked = new EventEmitter<{articleId: number, article: string}>()

  // reload
  @Input() haveReload: boolean = false;
  @Output() reloadArticles = new EventEmitter<number>()

  pcWidth = pcWidth;

  substringHTMLWith3Points = substringHTMLWith3Points;
  maxLengthFamilyScreenSM = MAX_LENGTH_FAMILIY_SCREEN_SM;

  constructor(
    public readonly platform: Platform,
    private elem:ElementRef,
  ) { }

  ngOnInit() {
    addElemStyle(this.elem, '--color-button-table-hover-company', this.company.colorButtonTableHover )
  }

  openPrivateFamily(pos: number){
    this.openModalPrivate.emit(pos)
  }

  openPublicFamily(pos:number){
    this.openModalPublic.emit(pos)
  }

  presentDeleteAlert(pos: number){
    this.openDeleteAlert.emit(pos)
  }

  reload(){
    this.reloadArticles.emit()
  }

  showFilterArticle(){
    this.openFilterArticle.emit()
  }

  showFilterPublic(){
    this.openFilterPublic.emit()
  }

  showFilterPrivate(){
    this.openFilterPrivate.emit()
  }

  showMachinesByArticles(articleId: number, article: string){
    this.articleClicked.emit({articleId, article})
  }
}
