// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-weight-bold {
  text-indent: 1.5em;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/webload/load-credit/component/load-credit-card-unlimited/components/legend-load-credit-unlimited.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".font-weight-bold{\r\n    text-indent: 1.5em;\r\n    font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
