export const MAX_LENGTH_CITY_OPERATOR_GROUP: number = 50;
export const MAX_LENGTH_REFERENCE_OPERATOR_GROUP: number = 150;
export const MAX_LENGTH_DESCRIPTION_OPERATOR_GROUP: number = 200;
export const MAX_LENGTH_CIF_OPERATOR_GROUP: number = 20;
export const MAX_LENGTH_EMAIL_OPERATOR_GROUP: number = 50;
export const MAX_LENGTH_ADDRESS_OPERATOR_GROUP: number = 20;


export const NAME_HIGH_LEVEL_ROUTE: string = 'High level: Create/Edit Op Group';
export const ROUTE_OPERATOR_GROUP_MANAGER: string = '/user/data-manager/operator-group-manager'

export const START_PHONE_OPERATOR_GROUP: { phoneNumber: string; prefix: string } = {
  phoneNumber: '',
  prefix: '34',
};


export const EXTRA_CLASS_PHONE_OPERATOR_GROUP_LIGHT = 'table-visibility';
export const EXTRA_CLASS_PHONE_OPERATOR_GROUP_DARK = 'table-visibility table-visibility-dark';
export const EXTRA_CLASS_PHONE_TABLE_PLACEHOLDER = 'table-visibility table-visibility-dark table-visibility-dark-placeholder'
