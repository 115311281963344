import { WritableSignal } from "@angular/core";

// utils
import { getTomorrowDay } from "src/app/utils";
import { ONLY_DAYS_MONTH_REPORT } from "../constants";


interface GetDaysByMonthReport{
  month: number,
 daysMonthlyReport: WritableSignal<number[]>
}

export const getDaysByMonthReport = (
  {daysMonthlyReport, month}: GetDaysByMonthReport
) => {
  const tomorrow = getTomorrowDay();

  if(tomorrow.getMonth() === month -1 ){
    daysMonthlyReport.set(getDaysBySameMonth(tomorrow.getDate()))
    return;
  }

  daysMonthlyReport.set([...ONLY_DAYS_MONTH_REPORT]);
}


const getDaysBySameMonth = (day: number) => {

  let onlyDays = [...ONLY_DAYS_MONTH_REPORT];
  let daysBySameMonth = [];

  for(let i of onlyDays){
    if(i >= day){
      daysBySameMonth.push(i);
    }
  }

  // console.log('dayFun', daysBySameMonth)

  return daysBySameMonth;
}
