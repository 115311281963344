import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  ModalController,
  ToastController,
  IonInfiniteScroll,
} from '@ionic/angular';
import { Subscription } from 'rxjs';

// Service
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from '../../services/languageRoute.ts/languagesRoute.services';
import { SkinMangementService } from '../../../services/skin-mangement.service';
import { FilterCardInputService } from './service/filter-card-input.service';

// Models
import { Company, Card } from 'src/app/models';

// Utils
import { type } from '../../../utils/constants';


@Component({
  selector: 'app-filter-card-input',
  templateUrl: './filter-card-input.html',
  styleUrls: ['./filter-card-input.scss'],
})
export class FilterCardInputComponent implements OnInit {
  // todos los models
  //@Input() cards: string[] = [];
  // models que cumplen el filtro
  cardFilter: string[] = [];

  // models que se muestran
  cards: string[] = [];

  cardFull: Card[];
  card: Card[];
  Cards: Card[];

  @Input() cardsInput: string[] = [];
  @Input() positionCardNum: number = 0;
  @Input() close: boolean = false;

  @Input() cardNum: string = ' ';
  // aspecto
  company: Company;
  type = type;

  // entrada
  model: string = '';
  // model seleccionado
  modelSelected = null;

  deleteModelFilterTranslate = '';

  filterChange$: Subscription;
  changeLanguage$: Subscription;

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private toastCrl: ToastController,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _filterService: FilterCardInputService
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    let { cardNum, cardId } = this._filterService.getCard(this.positionCardNum);

    this.cardNum = cardNum;

    this.filterChange$ = this._filterService.filterChange.subscribe((res) => {
      if (res.positionCardNum == this.positionCardNum) {
        this.cardNum = res.cardNum;
      }

      if (this.cardNum) {
        this.changeCardNum();
      }
    });

    this.start();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe((valor) => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });
  }

  ngOnDestroy(): void {
    if( this.changeLanguage$) this.changeLanguage$.unsubscribe()
    if(this.filterChange$) this.filterChange$.unsubscribe()
  }

  start() {
    if (this.cardsInput.length > 50) {
      this.cards = this.cardsInput.slice(0, 50);
    } else {
      this.cards = this.cardsInput.slice(0, this.cardsInput.length);
    }
  }

  changeCardNum() {
    this.inifiteScroll.disabled = false;


    this.cards = [];
    this.cardFilter = this.cardsInput.filter((card) =>
      card.trim().includes(this.cardNum.trim())
    );

    if (this.cardFilter.length < 50) {
      this.cards.push(...this.cardFilter.slice(0, this.cardFilter.length));
    } else {
      this.cards.push(...this.cardFilter.slice(0, 50));
    }

  }



  loadData(event) {
    setTimeout(() => {
      this.cardFilter = this.cardsInput.filter((card) =>
        this.cardNum.trim().includes(card.trim())
      );

      if (this.cards.length >= this.cardFilter.length) {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }
      let nuevoArr2 = [];
      if (this.cardFilter.length < this.cards.length + 50) {
        nuevoArr2 = this.cardFilter.slice(
          this.cards.length,
          this.cardFilter.length
        );
      } else {
        nuevoArr2 = this.cardFilter.slice(
          this.cards.length,
          this.cards.length + 50
        );
      }
      this.cards.push(...nuevoArr2);
      this.inifiteScroll.complete();
    }, 100);
  }

  deleteCardFilter() {
    this.cardNum = '';
    this._filterService.deleteCard();

    this.modalCtrl.dismiss({
      cardNum: null,
    });
  }

  filterCard() {
    this.modalCtrl.dismiss({
      cardNum: this.cardNum,
    });

    this._filterService.setCardNum(this.cardNum);
  }



  closeModal() {
    if (this.modalCtrl) {
      this.modalCtrl.dismiss({
        close: true,
      });
    }
  }

  savePlaceholders() {
    this.translate
      .get(['transaction_query.carousel-filter.model_deleted'])
      .subscribe((translates) => {
        this.deleteModelFilterTranslate =
          translates['transaction_query.carousel-filter.model_deleted'];
      });
  }
}
