import { WritableSignal } from '@angular/core';

// models
import { Report } from '../../models';

// utils
import { addTimeDate } from 'src/app/utils';
import { PERIODICITIES_REPORTS_BY_OPTIONS } from '../constants';

export const nextExecutionReport = (
  nextExecution: WritableSignal<Date | string>,
  reportSelected: WritableSignal<Report>
) => {
  if (!reportSelected()?.lastExecutionDate) {
    nextExecution.set(reportSelected()?.firtsExecutionDate);
  } else {
    const lastExecution = reportSelected().lastExecutionDate;

    const periodicity =
      PERIODICITIES_REPORTS_BY_OPTIONS[
        reportSelected()?.typeReport?.periodicity
      ] || 'months';

    // console.log('periodcity', periodicity)

    nextExecution.set(
      addTimeDate({
        startDate: lastExecution,
        increment: 1,
        typeIncrement: periodicity,
      })
    );
  }
};
