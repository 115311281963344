// models
import { Visibility } from 'src/app/models';
import { TypeReport, TypeReportVisibilityOptions } from '../models';

/**
 * level 1 : company
 * level 2: operator group
 * level 3: operator
 * level 4: client
 */
export const getTypeReportsByVisibility = (
  typeReports: TypeReport[],
  userVisibility: Visibility
): TypeReport[] => {
  const TYPE_REPORT_BY_VISIBILITY_OPTIONS: Record<
    TypeReportVisibilityOptions,
    number[]
  > = {
    [TypeReportVisibilityOptions.company]: [1, 2, 3, 4],
    [TypeReportVisibilityOptions.operatorGroup]: [2, 3, 4],
    [TypeReportVisibilityOptions.operator]: [3, 4],
    [TypeReportVisibilityOptions.client]: [4],
  };

  const visibility: TypeReportVisibilityOptions = getTypeVisibility(
    userVisibility
  );

  const typeReportByVisibility = TYPE_REPORT_BY_VISIBILITY_OPTIONS[visibility];

  //console.log('typeReport', typeReportByVisibility);

  return typeReports.filter((typeReport) =>
    typeReportByVisibility.includes(typeReport.Level)
  );
};

const getTypeVisibility = (
  userVisibility: Visibility
): TypeReportVisibilityOptions => {
  if (notHaveVisibility(userVisibility.operatorGroupId)) {
    return TypeReportVisibilityOptions.company;
  }

  if (notHaveVisibility(userVisibility.operatorId)) {
    return TypeReportVisibilityOptions.operatorGroup;
  }

  if (notHaveVisibility(userVisibility.clientId)) {
    return TypeReportVisibilityOptions.operator;
  }

  return TypeReportVisibilityOptions.client;
};

const notHaveVisibility = (visibility: number) => {
  return !visibility && visibility !== 0;
};
