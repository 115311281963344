import { Injectable } from '@angular/core';

import { Observable, lastValueFrom } from 'rxjs';

// Service
import { AdminSupplantService } from '../services/admin-supplant.service';
import { NavController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class ExistSuperAdminGuard  {
  constructor( private _adminSupplantService: AdminSupplantService,
      private navCtrl: NavController
    ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean  {

    return lastValueFrom(this._adminSupplantService.isValidSuplantRoute()).then(
       (res) => {
        if(!res){
          this.navCtrl.navigateRoot('/login', { animated: true })
        }
        return Promise.resolve(res)
      }
    ).catch(error => Promise.resolve(false));

  }

}
