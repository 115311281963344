import { CardNames, Company } from 'src/app/models';
import { replaceNameCard } from 'src/app/utils';

export const getTranslateTypesTransactionQuery = (
  translates: { [x: string]: string },
  company: Company
): { [x: string]: string } => {
  const translatesTypes: { [x: string]: string } = {
    'Sin Definir': translates['transaction_query.type_payment.undefined'],
    Efectivo: translates['transaction_query.type_payment.cash'],
    'Tarjeta bancaria': translates['transaction_query.type_payment.credit'],
    'Tarjeta cautiva':
      company?.nameCard === CardNames.arbitradePay
        ? CardNames.arbitradePay
        : translates['close_loop_card'],
    'Tarjeta cautiva bonificada':
      company?.nameCard === CardNames.arbitradePay
        ? replaceNameCard(translates['transaction_query.type_payment.subsidy_alliance'])
        : translates['transaction_query.type_payment.subsidy_close_loop_card'],
    'Venta gratuita': translates['transaction_query.type_payment.free_sale'],
    Otra: translates['transaction_query.type_payment.other'],
    'Carga de saldo': translates['transaction_query.type_payment.loader'],
    'Carga online': translates['transaction_query.type_payment.online_load'],
    'Carga Online': translates['transaction_query.type_payment.online_load'],
    'Cargas en efectivo':
      translates['transaction_query.type_payment.cash_loads'],
    'Cargas con tarjeta bancaria':
      translates['transaction_query.type_payment.bank_card_charges'],
  };

  return translatesTypes;
};
