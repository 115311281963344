// models
import { Company, LinkRoute, LinkRouteGroup } from 'src/app/models';
import { GroupsNameRouteValids } from '../models';

import { getIconByGroup } from './';

export const startGroupRoutes = (
  translates: Record<GroupsNameRouteValids, string>,
  company: Company
): LinkRouteGroup[] => {
  const groupLinks: LinkRouteGroup[] = [];

  for (let pos in translates) {
    const groupRoute = getGroupRoutes({
      company: company,
      nameGroup: translates[pos],
      linkRoute: [],
      pos: pos as GroupsNameRouteValids,
    });

    groupLinks.push(groupRoute  as LinkRouteGroup);
  }

  return groupLinks;
};

export const setGroupRoutes = (
  translates: Record<GroupsNameRouteValids, string>,
  company: Company,
  validRoutes: Record<
    GroupsNameRouteValids,
    { routes: LinkRoute[]; valids: LinkRoute[]; order?: Function }
  >
): LinkRouteGroup[] => {
  const groupLinks: LinkRouteGroup[] = [];

  for (let pos in translates) {
    const groupRoute = getGroupRoutes({
      company: company,
      nameGroup: translates[pos],
      linkRoute: validRoutes?.[pos].valids,
      pos: pos as GroupsNameRouteValids,
    });

    groupLinks.push(groupRoute);
  }

  return groupLinks;
};

const getGroupRoutes = ({
  pos,
  company,
  nameGroup,
  linkRoute,
}: {
  pos: GroupsNameRouteValids;
  company: Company;
  nameGroup: string;
  linkRoute: LinkRoute[];
}) => {
  const icon = getIconByGroup(pos, company);
  let groupRoutes = {
    nameGroup,
    linkRoute,
  };

  groupRoutes = {
    ...groupRoutes,
    ...icon,
  };

  return groupRoutes as LinkRouteGroup;
};
