import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Company } from 'src/app/models/';
import { SkinMangementService } from 'src/app/services';
import { LanguageRouteService } from 'src/app/share/services';
import { type } from 'src/app/utils/constants';
import { FilterDatePeriodNoCalendarService } from '../../service/filter-date-period-no-calendar.service';

@Component({
  selector: 'app-date-no-calendar-modal',
  templateUrl: './date-no-calendar-modal.component.html',
  styleUrls: ['./date-no-calendar-modal.component.scss'],
})
export class DateNoCalendarModalComponent implements OnInit {

  company: Company;
  type = type;

  startDate = new Date().toISOString()
  startDateFormated:string = ''
  startDateParams:string = ''

  @Input() minDate:string
  @Input() locale:string

  constructor(
    private _languageRouteService: LanguageRouteService,
    private translate: TranslateService,
    private _skinService: SkinMangementService,
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private _filterService: FilterDatePeriodNoCalendarService
  ) { }

  ngOnInit() {

    this.company = this._skinService.getCompany();

    // set by default the current date
    const today = new Date()
    this.startDateFormated = today.getFullYear()+'-'+(today.getMonth()+1);
    this.startDateParams = [today.getFullYear(), (today.getMonth() + 1).toString().padStart(2, "0") ,+ +'01'.padStart(2, "0")].join('-');
    this._filterService.setDateParams(this.startDateParams)

    if (this._filterService.getDate()){
      this.startDate = this._filterService.getDate()
    }
  }

  applyChanges() {

    if (this.startDate){
      this._filterService.setDate(this.startDate)
      const date = new Date(this.startDate)
      this.startDateFormated = [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, "0")].join('-');
      this.startDateParams = [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, "0") ,+'01'.padStart(2, "0")].join('-');
      this._filterService.setDateParams(this.startDateParams)
      this._filterService.setStartDateFormated(this.startDateFormated)
    }

    this.modalCtrl.dismiss({
      date: this.startDateFormated
    })
  }


  closeModal(){
    this.modalCtrl.dismiss({})
  }


}


