import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { map, catchError } from 'rxjs/operators';
import { Subject, throwError, of } from 'rxjs';

// config
import { AppConfig } from '../config/config';

// models
import { Permission, UserVisibility } from '../models';
import {
  PermissionsTransactionsQuery,
  PermissionsTransactionsQueryNames,
} from '../pages/user/transactions/transactions-query/models';

// utils
import {
  UtilsTranslate,
  clientesavDomain,
  NAME2MACHINESTATUS,
  ROUTETILLSTATE,
  ROUTEMACHINESTATE,
  ROUTECURRENTSTATE,
  ROUTEHOME,
  NAMEMACHINESTATUS,
  LOW_LEVEL_REGISTER_CARD,
  MID_LEVEL_REGISTER_CARD,
  HIGH_LEVEL_REGISTER_CARD,
  ECOMMERCE_ROUTE,
  OPERATOR_GROUP_LINK_ARBITRADE,
  ARBITRADE_ECOMMERCE_LINK,
} from '../utils';
import { StorageService } from '../share/services';
import { RemoveStorageService } from './remove-storage.service';
import {
  MACHINE_STATUS_NAME,
  MACHINE_STATUS_ROUTE,
} from '../pages/user/machines/machines-status/machine-status-alert/utils';
import { ROLES_SUPERVISOR_MAANAGER_VALIDS } from '../pages/user/data-manager/supervisor-manager/utils';
import { ROLES_ROUTE_DRIVER_MAANAGER_VALIDS } from '../pages/user/data-manager/route-manager/utils';


@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private serviceUrl = AppConfig.RTVnodeUrl;
  loadPermission = false;
  permissions: Permission[] = [];

  permissionsChange: Subject<Permission[]> = new Subject<Permission[]>();

  promise;

  userVisibilty: UserVisibility = null;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private _storageService: StorageService,
    private _removeStorageService: RemoveStorageService,
    private utils: UtilsTranslate,
    private navCtrl: NavController
  ) {}

  getPermissions(isLogin: boolean = false) {
    if (isLogin) {
      this.loadPermission = false;
      this.permissions = [];
      this.userVisibilty = null;
    }
    if (!this.loadPermission) {
      let endpointVisibility = `user-visibility`;
      let endpoint = `user-permissions/`;
      this.loadPermission = false;
      this.httpClient.get(this.serviceUrl + endpointVisibility).subscribe({
        next: (resVisibility: UserVisibility) => {
          this.userVisibilty = resVisibility[0];
          return this.httpClient.get(this.serviceUrl + endpoint).subscribe({
            next: (res: Permission[]) => {
              this.permissions = res;
              this.loadPermission = true;
              // console.log('permissions', this.permissions);
              this.permissionsChange.next(this.permissions);
            },
            error: () => {
              this.loadPermission = true;
              this.permissions = [];
            },
          });
        },
        error: () => {
          this.loadPermission = true;
          this.permissions = [];
        },
      });
    }
  }

  getAllPermissions() {
    let endpoint = `user-permissions/`;

    return this.httpClient.get<Permission[]>(this.serviceUrl + endpoint);
  }

  logout() {
    this.loadPermission = false;
    this.permissions = [];
  }

  reloadPermission() {
    if (this.permissions.length == 0) {
      this.getPermissions();
    }
  }

  permissionValidate(url) {
    let endpoint = `user-permissions`;

    if (!this.loadPermission || this.permissions.length == 0) {
      return this.httpClient.get(this.serviceUrl + endpoint).pipe(
        map((permissions: Permission[]) => {
          return this.havePermission(permissions, url);
        })
      );
    } else {
      return this.havePermission(this.permissions, url);
    }
  }

  havePermission(permissions, url) {
    if (url.includes('/user/status-online-load/')) {
      url = '/user/online-load';
    }

    if (
      url.includes('show-buylist-online-shop') ||
      url.includes('info-payment-online-shop')
    ) {
      url = '/user/online-shop';
    }

    if (permissions.some((permission) => permission.route == url)) {
      return true;
    }

    // machine status
    // estado maquinas
    if (url == ROUTETILLSTATE) {
      if (
        permissions.some(
          (permission) =>
            permission.name == NAME2MACHINESTATUS &&
            permission.route == ROUTEMACHINESTATE
        )
      ) {
        return true;
      }
    }
    // estado actual maquinas
    if (
      url == ROUTECURRENTSTATE &&
      permissions.some((permission) => permission.name == NAMEMACHINESTATUS)
    ) {
      return true;
    }

    if (!clientesavDomain() || url !== '/user/online-shop') {
      this.utils.presentToastTranslate('invalid_route', true, 800);
    }

    if (
      clientesavDomain() &&
      permissions.some((permission) => permission.route == '/user/online-shop')
    ) {
      this.router.navigate(['/user/online-shop']);
      return false;
    }

    this.router.navigate(['/user/home']);
    return false;
  }

  permissionValidateWithParams(url: string) {
    let endpoint = `user-permissions`;

    if (!this.loadPermission || this.permissions.length == 0) {
      return this.httpClient.get(this.serviceUrl + endpoint).pipe(
        map((permissions: Permission[]) => {
          return this.havePermissionWithParams(permissions, url);
        })
      );
    } else {
      return this.havePermissionWithParams(this.permissions, url);
    }
  }

  havePermissionWithParams(permissions: Permission[], url: string) {
    const havePermission = permissions.some((permission) =>
      url.includes(permission.route)
    );
    if (!havePermission) {
      this.router.navigate(['/user/home']);
    }

    return havePermission;
  }

  getUserVisibilityByHierarchy(hierarchy: Hierarchy): number {
    if (!this.userVisibilty || !this.loadPermission) {
      return -1;
    } else {

      let visibilities = {
        Company: this.userVisibilty.companyId,
        OperatorGroup: this.userVisibilty.operatorGroupId,
        Operator: this.userVisibilty.operatorId,
        ClientGroup: this.userVisibilty.clientGroupId,
        Client: this.userVisibilty.clientId,
        Ubication: this.userVisibilty.ubicationId,
        Till: this.userVisibilty.tillId,
        routeID: this.userVisibilty?.routeID,
        routeDriverID: this.userVisibilty?.routeDriverID,
        Supervisor: this.userVisibilty?.SupervisorID,
      };
      return visibilities[hierarchy];
    }
  }

  haveClientVisibility() {
    if (!this.loadPermission) {
      let endpointVisibility = `user-visibility`;
      let endpoint = `user-permissions/`;
      this.loadPermission = true;
      this.httpClient
        .get(this.serviceUrl + endpointVisibility)
        .subscribe((resVisibility: UserVisibility) => {
          this.userVisibilty = resVisibility[0];
          return this.userVisibilty?.clientId ? true : false;
        });
    } else {
      return this.userVisibilty?.clientId ? true : false;
    }
  }

  getAllVisibity(): UserVisibility {
    if (!this.loadPermission) {
      return null;
    } else {
      return this.userVisibilty;
    }
  }

  routeMachineState() {
    if (this.loadPermission) {
      return this.routeMachinesWithLoadPermission();
    } else {
      let i = 0;
      let route = '/user/home';
      let intervalWaitPermission = setInterval(() => {
        if (this.loadPermission) {
          let route = this.routeMachinesWithLoadPermission();
          clearInterval(intervalWaitPermission);
          return route;
        }
        i++;
        if (i == 10) {
          clearInterval(intervalWaitPermission);
          return route;
        }
      }, 1000);
    }
  }

  routeMachinesWithLoadPermission() {
    if (
      (this.userVisibilty.clientId != null ||
        this.userVisibilty.clientGroupId != null) &&
      this.permissions.some(
        (permission) =>
          (permission.name == NAMEMACHINESTATUS ||
            permission.name === MACHINE_STATUS_NAME.current_state.isAena) &&
          permission.route == ROUTEMACHINESTATE
      )
    ) {
      return ROUTECURRENTSTATE;
    } else if (
      this.permissions.some(
        (permission) =>
          (permission.route == ROUTEMACHINESTATE ||
            MACHINE_STATUS_NAME.till_state.isAena) &&
          permission.name == NAME2MACHINESTATUS
      )
    ) {
      return ROUTETILLSTATE;
    } else {
      if (
        this.userVisibilty.clientId === null &&
        this.userVisibilty.clientGroupId === null &&
        this.permissions.some(
          (permission) =>
            permission.name == NAMEMACHINESTATUS &&
            permission.route == ROUTEMACHINESTATE
        )
      ) {
        this.utils.presentToastTranslate(
          'guards.client_grop_or_client_required'
        );
      } else {
        this.utils.presentToastTranslate('invalid_route');
      }
      return ROUTEHOME;
    }
  }

  isCurrentStatusOrTillStatusAena() {
    return this.permissions.some(
      (permission) =>
        permission.route === MACHINE_STATUS_ROUTE &&
        (permission.name === MACHINE_STATUS_NAME.till_state.isAena ||
          permission.name ===  MACHINE_STATUS_NAME.current_state.isAena)
    );
  }

  getLoadPermssion() {
    return this.loadPermission;
  }

  getClientVisibility() {
    if (this.getUserVisibilityByHierarchy('Client') != -1) {
      setTimeout(() => {
        if (!this.haveClientVisibility()) {
          this.utils.presentToastTranslate('guards.client_required');
          this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        }
      }, 800);
      return this.haveClientVisibility();
    } else {
      this.getClientVisibilityLoad().then((value) => {
        if (!value) {
          this.utils.presentToastTranslate('guards.client_required', true, 800);
          this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        }
      });

      return this.getClientVisibilityLoad();
    }
  }

  getClientVisibilityLoad() {
    return new Promise<boolean>((resolve) => {
      let endpoint = `user-visibility`;
      this.httpClient.get(this.serviceUrl + endpoint).subscribe(
        (resp: UserVisibility[]) => {
          if (resp.length > 0 && resp[0].clientId) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
        (error) => {
          resolve(false);
        }
      );
    });
  }

  getTransactionPermissions(): PermissionsTransactionsQuery {
    let permissionsTransactions = {
      permissionTotal: false,
      permissionTicket: false,
      loadPermission: false,
      permissionAllTypes: false,
      isAena: false,
      nameRoute: null,
    };

    if (this.router.url === '/user/transactions-query-aena') {
      return {
        permissionTotal: false,
        permissionTicket: false,
        loadPermission: true,
        permissionAllTypes: false,
        isAena: true,
        nameRoute: null,
      };
    }

    if (this.loadPermission) {
      permissionsTransactions.loadPermission = true;
      if (
        this.permissions.some(
          (permission) =>
            permission.name === PermissionsTransactionsQueryNames.totals
        )
      ) {
        permissionsTransactions.permissionTotal = true;
        permissionsTransactions.nameRoute =
          PermissionsTransactionsQueryNames.totals;
      }
      if (
        this.permissions.some(
          (permission) =>
            permission.name === PermissionsTransactionsQueryNames.ticket
        )
      ) {
        permissionsTransactions.permissionTicket = true;
        permissionsTransactions.nameRoute =
          PermissionsTransactionsQueryNames.ticket;
      }
      if (
        this.permissions.some(
          (permission) =>
            permission.name === PermissionsTransactionsQueryNames.allTypes
        )
      ) {
        permissionsTransactions.permissionTicket = true;
        permissionsTransactions.permissionTotal = true;
        permissionsTransactions.permissionAllTypes = true;
        permissionsTransactions.nameRoute =
          PermissionsTransactionsQueryNames.allTypes;
      }
      if (
        this.permissions.some(
          (permission) =>
            permission.name ===
            PermissionsTransactionsQueryNames.ticketAndTotals
        )
      ) {
        permissionsTransactions.permissionTicket = true;
        permissionsTransactions.permissionTotal = true;
        permissionsTransactions.nameRoute =
          PermissionsTransactionsQueryNames.ticketAndTotals;
      }
    }
    return permissionsTransactions;
  }

  havePermissionByName(PermissionName) {
    if (
      this.permissions.some((permission) => permission.name == PermissionName)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getSupport(): {
    loadedPermission: boolean;
    createTicket: boolean;
    checkTicket: boolean;
    customerSupport: boolean;
  } {
    return {
      loadedPermission: this.loadPermission,
      createTicket: this.permissions.some(
        (permission) =>
          permission.route == '/ticket/create_ticket.php' &&
          permission.enabled == 1
      )
        ? true
        : false,
      checkTicket: this.permissions.some(
        (permission) =>
          permission.route == '/ticket/view.php' && permission.enabled == 1
      )
        ? true
        : false,
      customerSupport: this.permissions.some(
        (permission) =>
          permission.route == '/user/customer-support' &&
          permission.enabled == 1
      )
        ? true
        : false,
    };
  }

  getRegisterCard(): {
    loadPermission: boolean;
    isAssignedClient: boolean;
    isCreate: boolean;
  } {
    let permissionsRegisterCard = {
      loadPermission: false,
      isAssignedClient: false,
      isCreate: false,
    };

    if (this.loadPermission) {
      permissionsRegisterCard.loadPermission = true;
      if (
        this.permissions.some((permission) =>
          LOW_LEVEL_REGISTER_CARD.includes(permission.name)
        )
      ) {
        permissionsRegisterCard.isAssignedClient = true;
      }
      if (
        this.permissions.some((permission) =>
          MID_LEVEL_REGISTER_CARD.includes(permission.name)
        )
      ) {
        permissionsRegisterCard.isCreate = true;
      }
      if (
        this.permissions.some((permission) =>
          HIGH_LEVEL_REGISTER_CARD.includes(permission.name)
        )
      ) {
        permissionsRegisterCard.isCreate = true;
        permissionsRegisterCard.isAssignedClient = true;
      }
    }
    return permissionsRegisterCard;
  }

  getCheckLogCard(): {
    loadPermission: boolean;
    isLog: boolean;
    isCheck: boolean;
  } {
    let permissionsRegisterCard = {
      loadPermission: false,
      isLog: false,
      isCheck: false,
    };

    if (this.loadPermission) {
      permissionsRegisterCard.loadPermission = true;
      if (
        this.permissions.some(
          (permission) =>
            permission.name == 'Comprobar tarjeta' &&
            permission.route == '/user/check-cards'
        )
      ) {
        permissionsRegisterCard.isCheck = true;
      }
      if (
        this.permissions.some(
          (permission) =>
            permission.name == 'Low Level: Log card' &&
            permission.route == '/user/check-cards'
        )
      ) {
        permissionsRegisterCard.isLog = true;
      }
      if (
        this.permissions.some(
          (permission) =>
            permission.name == 'High Level: Log card' &&
            permission.route == '/user/check-cards'
        )
      ) {
        permissionsRegisterCard.isCheck = true;
        permissionsRegisterCard.isLog = true;
      }
    }

    return permissionsRegisterCard;
  }

  getTagStatusPermissions(): {
    loadPermission: boolean;
    permissionTransfer: boolean;
    permissionAlert: boolean;
  } {
    let permissionsTransactions = {
      permissionAlert: false,
      permissionTransfer: false,
      loadPermission: false,
    };

    if (this.loadPermission) {
      permissionsTransactions.loadPermission = true;
      if (
        this.permissions.some(
          (permission) => permission.name == 'Estado Tarjeta Transfer'
        )
      ) {
        permissionsTransactions.permissionTransfer = true;
      }
      if (
        this.permissions.some(
          (permission) => permission.name == 'Estado Tarjeta con aviso'
        )
      ) {
        permissionsTransactions.permissionAlert = true;
      }
    }
    return permissionsTransactions;
  }

  getLoadCreditPermissions(): {
    loadPermission: boolean;
    permissionUnlimited: boolean;
    permissionLimited: boolean;
  } {
    let permissionsLoadCredit = {
      permissionUnlimited: false,
      permissionLimited: false,
      loadPermission: false,
    };

    if (this.loadPermission) {
      permissionsLoadCredit.loadPermission = true;
      if (
        this.permissions.some(
          (permission) => permission.name == 'Carga de saldo ilimitada'
        )
      ) {
        permissionsLoadCredit.permissionUnlimited = true;
      }
      if (
        this.permissions.some(
          (permission) => permission.name == 'Carga de saldo ATC'
        )
      ) {
        permissionsLoadCredit.permissionLimited = true;
      }
    }
    return permissionsLoadCredit;
  }

  getVisibility() {
    let endpoint = `user-visibility`;
    return this.httpClient
      .get<UserVisibility[]>(this.serviceUrl + endpoint)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getMinOperatorVisibility() {
    if (this.getUserVisibilityByHierarchy('Client') != -1) {
      if (
        this.getUserVisibilityByHierarchy('Client') ||
        this.getUserVisibilityByHierarchy('ClientGroup')
      ) {
        this.utils.presentToastTranslate('guards.operator_required', true, 50);
        this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        return false;
      } else {
        return true;
      }
    } else {
      return new Promise<boolean>((resolve) => {
        let endpoint = `user-visibility`;
        this.httpClient.get(this.serviceUrl + endpoint).subscribe({
          next: (resp: UserVisibility[]) => {
            if (
              resp.length > 0 &&
              !resp[0].clientId &&
              !resp[0].clientGroupId
            ) {
              resolve(true);
            } else {
              this.utils.presentToastTranslate(
                'guards.operator_required',
                true,
                800
              );
              this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
              resolve(false);
            }
          },
          error: (error) => {
            resolve(false);
          },
        });
      });
    }
  }

  getMinClientGroupVisibility() {
    if (this.getUserVisibilityByHierarchy('Client') != -1) {
      if (
        this.getUserVisibilityByHierarchy('Client') ||
        this.getUserVisibilityByHierarchy('Client') === 0
      ) {
        this.utils.presentToastTranslate('guards.operator_required', true, 50);
        this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        return false;
      } else {
        return true;
      }
    } else {
      return new Promise<boolean>((resolve) => {
        let endpoint = `user-visibility`;
        this.httpClient.get(this.serviceUrl + endpoint).subscribe({
          next: (resp: UserVisibility[]) => {
            if (
              resp.length > 0 &&
              !resp[0].clientId &&
              resp[0].clientId !== 0
            ) {
              resolve(true);
            } else {
              this.utils.presentToastTranslate(
                'guards.operator_required',
                true,
                800
              );
              this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
              resolve(false);
            }
          },
          error: () => {
            resolve(false);
          },
        });
      });
    }
  }

  getMinOperatorGroupVisibility() {
    if (this.getUserVisibilityByHierarchy('Client') != -1) {
      if (
        this.getUserVisibilityByHierarchy('Client') ||
        this.getUserVisibilityByHierarchy('ClientGroup') ||
        this.getUserVisibilityByHierarchy('Operator')
      ) {
        this.utils.presentToastTranslate(
          'guards.operator_group_required',
          true,
          50
        );
        this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        return false;
      } else {
        return true;
      }
    } else {
      return new Promise<boolean>((resolve) => {
        let endpoint = `user-visibility`;
        this.httpClient.get(this.serviceUrl + endpoint).subscribe({
          next: (resp: UserVisibility[]) => {
            if (
              resp.length > 0 &&
              !resp[0].clientId &&
              !resp[0].clientGroupId &&
              !resp[0].operatorId
            ) {
              resolve(true);
            } else {
              this.utils.presentToastTranslate(
                'guards.operator_group_required',
                true,
                800
              );
              this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
              resolve(false);
            }
          },
          error: () => {
            resolve(false);
          },
        });
      });
    }
  }

  getMinCompanyVisibility() {
    if (this.getUserVisibilityByHierarchy('OperatorGroup') != -1) {
      if (
        this.getUserVisibilityByHierarchy('Client') ||
        this.getUserVisibilityByHierarchy('ClientGroup') ||
        this.getUserVisibilityByHierarchy('Operator') ||
        this.getUserVisibilityByHierarchy('OperatorGroup')
      ) {
        this.utils.presentToastTranslate('guards.company_required', true, 50);
        this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        return false;
      } else {
        return true;
      }
    } else {
      return new Promise<boolean>((resolve) => {
        let endpoint = `user-visibility`;
        this.httpClient.get(this.serviceUrl + endpoint).subscribe({
          next: (resp: UserVisibility[]) => {
            if (
              resp.length > 0 &&
              !resp[0].clientId &&
              !resp[0].clientGroupId &&
              !resp[0].operatorId &&
              !resp[0].operatorGroupId
            ) {
              resolve(true);
            } else {
              this.utils.presentToastTranslate(
                'guards.company_required',
                true,
                800
              );
              this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
              resolve(false);
            }
          },
          error: () => {
            resolve(false);
          },
        });
      });
    }
  }

  notCompanyVisibility() {
    if (this.getUserVisibilityByHierarchy('OperatorGroup') != -1) {
      if (
        this.getUserVisibilityByHierarchy('OperatorGroup') ||
        this.getUserVisibilityByHierarchy('OperatorGroup') === 0
      ) {
        return true;
      } else {
        this.utils.presentToastTranslate('guards.company_not_valid', true, 50);
        this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        return false;
      }
    } else {
      return new Promise<boolean>((resolve) => {
        let endpoint = `user-visibility`;
        this.httpClient.get(this.serviceUrl + endpoint).subscribe({
          next: (resp: UserVisibility[]) => {
            if (
              resp.length > 0 &&
              (resp[0].operatorGroupId || resp[0].operatorGroupId === 0)
            ) {
              resolve(true);
            } else {
              this.utils.presentToastTranslate(
                'guards.company_not_valid',
                true,
                800
              );
              this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
              resolve(false);
            }
          },
          error: () => {
            resolve(false);
          },
        });
      });
    }
  }

  getGroupName() {
    let currentRoute = this.router.url;

    if (this.router.url.includes('/user/machines-status')) {
      currentRoute = '/user/machines-status';
    }

    if (this.router.url.includes('/user/show-buylist-online-shop')) {
      currentRoute = '/user/online-shop';
    }

    if (this.router.url.includes('/user/info-payment-online-shop')) {
      currentRoute = '/user/online-shop';
    }

    const permissionRoute = this.permissions.find(
      (permission) => permission.route === currentRoute
    );
    return (permissionRoute?.nameGroup || '').toString();
  }

  getPermissionsSubscribe() {
    if (this.loadPermission) {
      return of(this.permissions);
    } else {
      let endpoint = `user-permissions/`;
      return this.httpClient.get<Permission[]>(this.serviceUrl + endpoint);
    }
  }

  clientOrOperatorVisibility() {
    if (this.getUserVisibilityByHierarchy('Client') != -1) {
      if (
        (this.getUserVisibilityByHierarchy('Client') ||
          this.getUserVisibilityByHierarchy('ClientGroup') ||
          this.getUserVisibilityByHierarchy('Operator')) &&
        (this.getUserVisibilityByHierarchy('OperatorGroup') ||
          this.getUserVisibilityByHierarchy('OperatorGroup') === 0) &&
        this.getUserVisibilityByHierarchy('Company')
      ) {
        return true;
      } else {
        this.utils.presentToastTranslate(
          'guards.client_or_operator_required',
          true,
          50
        );
        this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        return false;
      }
    } else {
      return new Promise<boolean>((resolve) => {
        let endpoint = `user-visibility`;
        this.httpClient.get(this.serviceUrl + endpoint).subscribe({
          next: (resp: UserVisibility[]) => {
            if (
              resp.length > 0 &&
              (resp[0].clientId ||
                resp[0].clientGroupId ||
                resp[0].operatorId) &&
              (resp[0].operatorGroupId || resp[0].operatorGroupId === 0) &&
              resp[0].companyId
            ) {
              resolve(true);
            } else {
              this.utils.presentToastTranslate(
                'guards.client_or_operator_required',
                true,
                800
              );
              this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
              resolve(false);
            }
          },
          error: () => {
            resolve(false);
          },
        });
      });
    }
  }

  isOperatorOrOperatorGroup() {
    if (this.getUserVisibilityByHierarchy('Operator') != -1) {
      if (
        this.getUserVisibilityByHierarchy('Client') ||
        this.getUserVisibilityByHierarchy('ClientGroup') ||
        !this.getUserVisibilityByHierarchy('OperatorGroup')
      ) {
        this.utils.presentToastTranslate(
          'guards.operator_or_group_operator',
          true,
          50
        );
        this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        return false;
      } else {
        return true;
      }
    } else {
      return new Promise<boolean>((resolve) => {
        let endpoint = `user-visibility`;
        this.httpClient.get(this.serviceUrl + endpoint).subscribe({
          next: (resp: UserVisibility[]) => {
            if (
              resp.length > 0 &&
              !resp[0].clientId &&
              !resp[0].clientGroupId &&
              resp[0].operatorGroupId
            ) {
              resolve(true);
            } else {
              this.utils.presentToastTranslate(
                'guards.operator_or_group_operator',
                true,
                800
              );
              this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
              resolve(false);
            }
          },
          error: () => {
            resolve(false);
          },
        });
      });
    }
  }

  isOperatorOrRouteId() {
    if (this.getUserVisibilityByHierarchy('Operator') != -1) {
      if (
        this.getUserVisibilityByHierarchy('Client') ||
        this.getUserVisibilityByHierarchy('ClientGroup') ||
        !this.getUserVisibilityByHierarchy('OperatorGroup') ||
        this.getUserVisibilityByHierarchy('routeDriverID') ||
        (!this.getUserVisibilityByHierarchy('routeID') &&
          !this.getUserVisibilityByHierarchy('Operator'))
      ) {
        this.utils.presentToastTranslate(
          'machines_module.guard.error_visibility',
          true,
          50
        );
        this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        return false;
      } else {
        return true;
      }
    } else {
      return new Promise<boolean>((resolve) => {
        let endpoint = `user-visibility`;
        this.httpClient.get(this.serviceUrl + endpoint).subscribe({
          next: (resp: UserVisibility[]) => {
            if (
              resp.length > 0 &&
              !resp[0].clientId &&
              !resp[0].clientGroupId &&
              resp[0].operatorGroupId &&
              !resp[0].routeDriverID &&
              (resp[0].operatorId || resp[0].routeID)
            ) {
              resolve(true);
            } else {
              this.utils.presentToastTranslate(
                'machines_module.guard.error_visibility',
                true,
                800
              );
              this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
              resolve(false);
            }
          },
          error: () => {
            resolve(false);
          },
        });
      });
    }
  }

  isOperatorOrRouteDriveId() {
    if (this.getUserVisibilityByHierarchy('Operator') != -1) {
      if (
        // this.getUserVisibilityByHierarchy('Client') ||
        // this.getUserVisibilityByHierarchy('ClientGroup') ||
        !this.getUserVisibilityByHierarchy('OperatorGroup') ||
        // this.getUserVisibilityByHierarchy('routeID') ||
        (!this.getUserVisibilityByHierarchy('routeDriverID') &&
          !this.getUserVisibilityByHierarchy('Operator'))
      ) {
        this.utils.presentToastTranslate(
          'sales_module.guard.error_visibility',
          true,
          50
        );
        this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        return false;
      } else {
        return true;
      }
    } else {
      return new Promise<boolean>((resolve) => {
        let endpoint = `user-visibility`;
        this.httpClient.get(this.serviceUrl + endpoint).subscribe({
          next: (resp: UserVisibility[]) => {
            if (
              resp.length > 0 &&
              // !resp[0].clientId &&
              // !resp[0].clientGroupId &&
              resp[0].operatorGroupId &&
              // !resp[0].routeID &&
              (resp[0].operatorId || resp[0].routeDriverID)
            ) {
              resolve(true);
            } else {
              this.utils.presentToastTranslate(
                'sales_module.guard.error_visibility',
                true,
                800
              );
              this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
              resolve(false);
            }
          },
          error: () => {
            resolve(false);
          },
        });
      });
    }
  }

  isRouteOrRouteDriverVisibility() {
    if (this.getUserVisibilityByHierarchy('Operator') != -1) {
      if (
        !this.getUserVisibilityByHierarchy('routeDriverID') &&
        !this.getUserVisibilityByHierarchy('routeID')
      ) {
        this.utils.presentToastTranslate(
          'guards.route_or_route_driver',
          true,
          50
        );
        this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        return false;
      } else {
        return true;
      }
    } else {
      return new Promise<boolean>((resolve) => {
        let endpoint = `user-visibility`;
        this.httpClient.get(this.serviceUrl + endpoint).subscribe({
          next: (resp: UserVisibility[]) => {
            if (
              this.getUserVisibilityByHierarchy('routeDriverID') ||
              this.getUserVisibilityByHierarchy('routeID')
            ) {
              resolve(true);
            } else {
              this.utils.presentToastTranslate(
                'guards.route_or_route_driver',
                true,
                800
              );
              this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
              resolve(false);
            }
          },
          error: () => {
            resolve(false);
          },
        });
      });
    }
  }

  getVisibilies() {
    let endpoint = 'user-visibility';

    return this.httpClient
      .get<UserVisibility[]>(this.serviceUrl + endpoint)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getEcommerceVisibilityGuard() {
    if (this.getUserVisibilityByHierarchy('Client') != -1) {
      if (
        this.userVisibilty?.operatorGroupId === OPERATOR_GROUP_LINK_ARBITRADE
      ) {
        return this.redirectArbitradeEcommerce();
      }

      setTimeout(() => {
        if (!this.haveClientVisibility()) {
          this.utils.presentToastTranslate('guards.client_required');
          this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        }
      }, 800);
      return this.haveClientVisibility();
    } else {
      this.getEcommerceVisibilityLoad().then((value) => {
        if (!value) {
          this.utils.presentToastTranslate('guards.client_required', true, 800);
          this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
        }
      });

      return this.getEcommerceVisibilityLoad();
    }
  }

  getEcommerceVisibilityLoad() {
    return new Promise<boolean>((resolve) => {
      let endpoint = `user-visibility`;
      this.httpClient.get(this.serviceUrl + endpoint).subscribe({
        next: (resp: UserVisibility[]) => {
          if (
            this.userVisibilty?.operatorGroupId ===
            OPERATOR_GROUP_LINK_ARBITRADE
          ) {
            return this.redirectArbitradeEcommerce();
          }

          if (resp.length > 0 && resp[0].clientId) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
        error: () => {
          resolve(false);
        },
      });
    });
  }

  redirectArbitradeEcommerce() {
    this._removeStorageService.removeStorage();
    window.location.href = ARBITRADE_ECOMMERCE_LINK;
    return false;
  }

  havePermissionSupervisorManagerValid() {
    return new Promise<boolean>((resolve) => {
      let endpoint = `user-visibility`;
      this.httpClient.get(this.serviceUrl + endpoint).subscribe({
        next: (resp: UserVisibility[]) => {
          this._storageService.get('role').then((role) => {
            // console.log('role',  role, 'resp', resp[0]);
            if (!ROLES_SUPERVISOR_MAANAGER_VALIDS.includes(role)) {
              this.utils.presentToastTranslate(
                'supervisor_manager.guard.invalid_route',
                true,
                50
              );
              resolve(false);
            }

            if (
              resp[0].routeDriverID ||
              resp[0].routeDriverID === 0 ||
              resp[0].routeID ||
              resp[0].routeID === 0 ||
              resp[0].clientId ||
              resp[0].clientId === 0 ||
              resp[0].clientGroupId ||
              resp[0].clientGroupId === 0
            ) {
              this.utils.presentToastTranslate(
                'supervisor_manager.guard.invalid_route',
                true,
                50
              );
              resolve(false);
            }

            resolve(true);
          });
        },
        error: () => {
          resolve(false);
        },
      });
    });
  }

  havePermissionRouteDriverManagerValid() {
    return new Promise<boolean>((resolve) => {
      let endpoint = `user-visibility`;
      this.httpClient.get(this.serviceUrl + endpoint).subscribe({
        next: (resp: UserVisibility[]) => {
          this._storageService.get('role').then((role) => {
            // console.log('role',  role, 'resp', resp[0]);

            if (!ROLES_ROUTE_DRIVER_MAANAGER_VALIDS.includes(role)) {
              this.utils.presentToastTranslate(
                'supervisor_manager.guard.invalid_route',
                true,
                50
              );
              resolve(false);
            }

            if (
              resp[0].routeDriverID ||
              resp[0].routeDriverID === 0 ||
              resp[0].routeID ||
              resp[0].routeID === 0
            ) {
              this.utils.presentToastTranslate(
                'supervisor_manager.guard.invalid_route',
                true,
                50
              );
              resolve(false);
            }

            resolve(true);
          });
        },
        error: () => {
          resolve(false);
        },
      });
    });
  }

  havePermissionRouteManagerValid() {
    return new Promise<boolean>((resolve) => {
      let endpoint = `user-visibility`;
      this.httpClient.get(this.serviceUrl + endpoint).subscribe({
        next: (resp: UserVisibility[]) => {
          this._storageService.get('role').then((role) => {
            // console.log('role',  role, 'resp', resp[0]);

            if (!ROLES_ROUTE_DRIVER_MAANAGER_VALIDS.includes(role)) {
              this.utils.presentToastTranslate(
                'route_manager.guard.invalid_route',
                true,
                50
              );
              resolve(false);
            }

            if (
              resp[0].routeDriverID ||
              resp[0].routeDriverID === 0 ||
              resp[0].routeID ||
              resp[0].routeID === 0
            ) {

              this.utils.presentToastTranslate(
                'route_manager.guard.invalid_route',
                true,
                50
              );
              resolve(false);

            }

            resolve(true);
          });
        },
        error: () => {
          resolve(false);
        },
      });
    });
  }
}

type Hierarchy =
  | 'Company'
  | 'OperatorGroup'
  | 'Operator'
  | 'ClientGroup'
  | 'Client'
  | 'Till'
  | 'Ubication'
  | 'routeID'
  | 'routeDriverID'
  | 'Supervisor';
