// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-height-30 {
  line-height: 30x;
  font-size: 1.2em;
}

.text-indent-2 {
  text-indent: 2%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/register-cards/components/share/modals/legend-register-card/legend-register-card.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF;;AAGA;EACE,eAAA;AAAF","sourcesContent":[".line-height-30{\r\n  line-height:30x;\r\n  font-size: 1.2em;\r\n\r\n}\r\n\r\n.text-indent-2{\r\n  text-indent: 2%;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
