import { Component, Input, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { pcWidth, addElemStyle } from '../../../../../../../utils/';

@Component({
  selector: 'app-area-table-aena',
  templateUrl: './area-table-aena.component.html',
  styleUrls: ['./area-table-aena.component.scss'],
})
export class AreaTableAenaComponent implements OnInit {

  @Input() company: Company;
  @Input() haveSubgroup: boolean = false;
  @Input() pos: number = 0;
  @Input() areaText: string = '';

  @Output() selectArea = new EventEmitter<number>()

  pcWidth = pcWidth;


  constructor(
    public readonly platform: Platform,
    private elem:ElementRef,
  ) { }

  ngOnInit() {
    addElemStyle(this.elem, '--color-button-table-hover-company', this.company.colorButtonTableHover )
  }

  openArea(){
    this.selectArea.emit(this.pos)
  }

}
