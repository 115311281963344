import {
  ACTIVITY_STATUS_ALERT_NAME_DB,
  CONNECTIVITY_STATUS_ALERT_NAME_DB,
} from '../../utils';

export enum TypeMachineAlertOptions {
  Connectivity = 1,
  Activity = 2,
}

export const STATUS_NAME_BY_TYPE_MACHINE_ALERT_OPTIONS: Record<
  TypeMachineAlertOptions,
  string
> = {
  [TypeMachineAlertOptions.Activity]: ACTIVITY_STATUS_ALERT_NAME_DB,
  [TypeMachineAlertOptions.Connectivity]: CONNECTIVITY_STATUS_ALERT_NAME_DB,
};
