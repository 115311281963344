import { Client, Till } from 'src/app/models';
import { TillAlertSpider, TillAlertSpiderTable } from '../models';
import { PRIORITIES_TYPES } from './constant';

export const getTillAlertSiderTable = (
  tillAlertsSpider: TillAlertSpider[],
  clients: Client[],
  machines: Till[]
): TillAlertSpiderTable[] => {


  let tills: TillAlertSpiderTable[] = tillAlertsSpider.map(

    (tillAlertSpider) => {
      const client = clients.find(
        (client) => client.ID === tillAlertSpider?.ClientID
      );

      const machine = machines.find(
        (till) => till.id === tillAlertSpider?.TillID
      );

      const typeAlertDescription = (
        tillAlertSpider?.TypeLevelAlertSpider?.Name || ''
      ).toLowerCase();

      return {
        id: tillAlertSpider.ID,
        enable: tillAlertSpider?.Enable > 0,
        companyId: tillAlertSpider?.CompanyID,
        operatorGroupId: tillAlertSpider?.OperatorGroupID,
        operatorId: tillAlertSpider?.OperatorID,
        client: {
          clientId: client?.ID || null,
          clientCode: client?.code || '',
          client: client?.description || '',
        },
        machine: { machineId: machine?.id, machineCode: machine?.code },
        email: tillAlertSpider?.Email,
        status: {
          id: tillAlertSpider?.Status_Spider?.ID,
          description: (
            tillAlertSpider?.Status_Spider?.Description || ''
          ).toLowerCase(),
        },
        typeAlert: {
          id: tillAlertSpider?.TypeLevelAlertSpider?.ID,
          description:
            PRIORITIES_TYPES?.[typeAlertDescription] || typeAlertDescription,
        },
      };
    }
  );

  return tills;
};
