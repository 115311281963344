export interface DocumentationRoutesTranslates {
  documnentation: string;
  newsAlliance: string;
  newsNoPartner: string;
  newsPartner: string;
}

export const getDocumentation = (
  translates: DocumentationRoutesTranslates
): { route: string; name: string }[] => {
  return [
    { route: '/user/documentation', name: translates.documnentation },
    { route: '/user/news/alliance', name: translates.newsAlliance },
    { route: '/user/news/no-partner', name: translates.newsNoPartner },
    { route: '/user/news/partner', name: translates.newsPartner },
  ];
};
