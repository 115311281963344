import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// models
import { checkSegments } from 'src/app/pages/user/transactions/transactions-query/models/filter-transaction.model';
import {
  DateAllInfo,
  DateAllInfoValids,
  FilterDateServiceI,
} from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class FilterDateService implements FilterDateServiceI {
  // date - period
  checkSegment: checkSegments = 'other';
  dateStart: string = null;
  dateEnd: string = null;
  hourEnd: string = null;
  hourStart: string = null;

  // emite los cambios del filtro
  filterChange$: Subject<Object[]> = new Subject<Object[]>();

  // clear
  reset$: Subject<Object[]> = new Subject<Object[]>();

  constructor() {}

  // date or period
  getCheckSegment() {
    return this.checkSegment;
  }

  setCheckSegment(newType: checkSegments) {
    if (newType != this.checkSegment) {
      this.checkSegment = newType;
      if (this.checkSegment == 'date') {
        this.filterChange$.next([
          { checkSegment: this.checkSegment },
          { dateStart: this.dateStart },
          { hourStart: this.hourStart },
          { hourEnd: this.hourEnd },
        ]);
      } else {
        this.filterChange$.next([
          { checkSegment: this.checkSegment },
          { dateStart: this.dateStart },
          { dateEnd: this.dateEnd },
        ]);
      }
    }
  }

  getDateStart() {
    if (!this.dateStart) {
      let dateFull = new Date();
      this.dateStart = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;
    }
    return this.dateStart;
  }

  getDateEnd() {
    if (!this.dateEnd) {
      let dateFull = new Date();
      this.dateEnd = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;
    }
    return this.dateEnd;
  }

  getYear() {
    if (this.dateStart) {
      let dateFull = new Date(this.dateStart);
      return dateFull.getFullYear();
    }
  }

  // datePos= 0 -> date, period pos 1 - datePos= 1 -> period pos 2
  setDate(dateNew, datePos) {
    let dateFull = new Date(dateNew);
    dateFull = new Date(dateFull.setHours(dateFull.getHours() + 2));
    if (datePos == 0) {
      this.dateStart = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;

      this.filterChange$.next([{ dateStart: this.dateStart }]);
    } else {
      this.dateEnd = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;
      this.filterChange$.next([{ dateEnd: this.dateEnd }]);
    }
  }

  getStartHour() {
    if (!this.hourStart) {
      this.hourStart = `00:00`;
    }
    return this.hourStart;
  }

  setHour(hour, posHour) {
    if (posHour == 0) {
      this.hourStart = hour;
      this.filterChange$.next([{ hourStart: this.hourStart }]);
    } else {
      this.hourEnd = hour;
      this.filterChange$.next([{ hourEnd: this.hourEnd }]);
    }
  }

  getEndHour() {
    if (!this.hourEnd) {
      this.hourEnd = '24:00';
    }
    return this.hourEnd;
  }

  resetDate() {
    let dateFull = new Date();
    this.dateStart = `${dateFull.getFullYear()}-${
      dateFull.getMonth() + 1
    }-${dateFull.getDate()}`;
    this.dateEnd = `${dateFull.getFullYear()}-${
      dateFull.getMonth() + 1
    }-${dateFull.getDate()}`;
    this.hourStart = `00:00`;
    this.hourEnd = '24:00';
    this.checkSegment = 'date';

    this.reset$.next([true]);
  }

  getDate() {
    if (this.checkSegment == 'period') {
      return {
        datainici: this.dateStart,
        datafinal: this.dateEnd,
      };
    } else {
      return {
        datainici: this.dateStart,
      };
    }
    // return {
    //   checkSegment: this.checkSegment,
    //   dateStart: this.dateStart,
    //   dateEnd: this.dateEnd,
    //   hourEnd: this.hourEnd,
    //   hourStart: this.hourStart
    // }
  }

  getDateWithoutHourParams(): FilterDateParams {
    if (this.checkSegment == 'period') {
      return {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        hourStart: '00:00:00',
        hourEnd: '23:59:59',
      };
    } else {
      return {
        dateStart: this.dateStart,
        dateEnd: this.dateStart,
        hourStart: '00:00:00',
        hourEnd: '23:59:59',
      };
    }
  }

  getDateWithHour() {
    if (this.checkSegment == 'period') {
      return {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        hourStart: '00:00:00',
        hourEnd: '23:59:59',
      };
    } else {
      return {
        dateStart: this.dateStart,
        dateEnd: this.dateStart,
        hourStart: this.hourStart,
        hourEnd: this.hourEnd,
      };
    }
  }

  applyMaxPeriod(maxPeriodDate: Date) {
    if (this.checkSegment !== 'period') {
      return;
    }

    let isApplied: boolean = false;

    if (
      new Date(this.dateStart.toString().replace(/-/g, '/')).getTime() -
        maxPeriodDate.getTime() >
      0
    ) {
      isApplied = true;
    }

    if (
      new Date(this.dateEnd.toString().replace(/-/g, '/')).getTime() -
        maxPeriodDate.getTime() >
      0
    ) {
      isApplied = true;
    }
  }

  getAllDateInfo(): DateAllInfo {
    return {
      type:
        this.checkSegment === 'date'
          ? DateAllInfoValids.date
          : DateAllInfoValids.period,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      hourStart: this.hourStart || '00:00',
      hourEnd: this.hourEnd || '23:59',
    };
  }
}

export interface FilterDateParams {
  dateStart: string;
  dateEnd: string;
  hourStart: string;
  hourEnd: string;
}
