import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Service
import { SkinMangementService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from 'src/app/share/services';

// Models
import { Company } from 'src/app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-legend-check-qr',
  templateUrl: './legend-check-qr.component.html',
  styleUrls: ['./legend-check-qr.component.scss'],
})
export class LegendCheckQrComponent implements OnInit {

  company: Company;
  statusFilterTranslates: { [x: string]: string } = {
    "redimido": 'Redimido',
    "pendiente": 'Pendiente',
    "emitido": "Emitido"
  }

  changeLanguage$: Subscription;

  constructor(
    private _skinService: SkinMangementService,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });

  }

  ngOnDestroy(): void {
    if(this.changeLanguage$) this.changeLanguage$.unsubscribe()
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  savePlaceholders() {
    this.translate.get([

      'generateQR.contentCheckCode.redeemed', 'generateQR.contentCheckCode.pending',
      'generateQR.contentCheckCode.issued']).subscribe((translates) => {
        this.statusFilterTranslates = {
          "redimido": translates['generateQR.contentCheckCode.redeemed'],
          "pendiente": translates['generateQR.contentCheckCode.pending'],
          "emitido": translates['generateQR.contentCheckCode.issued']
        }
      });
  }
}
