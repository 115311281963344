import { WritableSignal } from '@angular/core';

// models
import { TillSelectedRM } from '../../models';

interface GetTillsByOperatorRM {
  tills: WritableSignal<TillSelectedRM[]>;
  loadingTills: WritableSignal<boolean>;
  tillsFilter: WritableSignal<TillSelectedRM[]>;
  operatorId: number;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
}

export const getTillsByOperatorRM = ({
  tills,
  loadingTills,
  tillsFilter,
  operatorId,
  tillsSelected,
  tillOneMachineSelected
}: GetTillsByOperatorRM) => {
  if ((tills() || []).length === 0 || !loadingTills()) {
    tillsFilter.set([]);
  }
  const tillsNotSelecteds: TillSelectedRM[] = tills().map((till) => ({
    ...till,
    isSelected: false,
  }));

  tills.set(tillsNotSelecteds);

  // tillsFilter.set([]);
  const tillsByOperator = tills().filter(
    (till) => till.operatorId === operatorId
  );
  // setTimeout(()=> {
    tillsFilter.set(tillsByOperator);
  // }, 100)

  tillsSelected.set([]);
  tillOneMachineSelected.set(null);
};
