import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

// components
import { FilterClientTillComponent } from '../filter-client-till/filter-client-till.component';

// services
import { TranslateService } from '@ngx-translate/core';
import {
  SkinMangementService,
  PermissionService,
  ColorModeService,
} from '../../../services';
import {
  ClientService,
  VisibilitySelectService,
  LanguageRouteService,
  TillService,
  FilterClientTillService,
} from '../../services';
import { VisibilityClientTillSelectService } from './service';
import { FilterSubsidiesService } from 'src/app/pages/user/cards/subsidies/services/filter-subsidies.service';

// models
import { Client, Company, HaveVisibility, Till } from 'src/app/models';
import { LoadingVisibilityClientTillSelect } from './models/loading-visibility-client-till-select.model';

// utils
import { UtilsTranslate, pcWidth } from 'src/app/utils';

@Component({
  selector: 'app-visibility-client-till-select',
  templateUrl: './visibility-client-till-select.component.html',
  styleUrls: ['./visibility-client-till-select.component.scss'],
})
export class VisibilityClientTillSelectComponent implements OnInit, OnDestroy {
  @Input() haveClearButton: boolean = true;
  @Input() loadingSended = false;
  @Input() pcWidth = pcWidth;
  @Output() sendFilter = new EventEmitter();
  @Output() clearFilter = new EventEmitter();

  company: Company;

  haveVisibility: HaveVisibility = {
    client: true,
    operator: true,
    operatorGroup: true,
  };
  loadings: LoadingVisibilityClientTillSelect = {
    loadingClient: true,
    loadingTill: true,
    loadingMore1Client: true,
    loadingMore1Till: true,
  };
  loadingVisibility: boolean = true;
  canSend: boolean = true;

  operatorId = null;
  operatorGroupId = null;

  clients: Client[] = [];
  haveMore1Client: boolean = false;
  clientSelected: Client = null;
  nameClient: string = '';
  showClient: boolean = false;

  tills: Till[] = [];
  haveMore1Till: boolean = false;
  tillSelected: Till = null;
  codeMachine: number = null;
  showTill: boolean = false;

  changeOperator$: Subscription = null;
  // changeClientSelected$: Subscription = null;

  filterChange$: Subscription;
  getClients$: Subscription;
  getTill$: Subscription;
  getClientsFilter$: Subscription;
  getTillFilter$: Subscription;
  startChange$: Subscription = null;
  changeFilterInit$: Subscription;
  changeLanguage$: Subscription;

  constructor(
    public readonly platform: Platform,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _permissionService: PermissionService,
    private _visibilityService: VisibilitySelectService,
    private _clientService: ClientService,
    private _filterService: FilterSubsidiesService,
    private _tillService: TillService,
    private _filterClientTillService: FilterClientTillService,
    private visibilityClientTillSelectService: VisibilityClientTillSelectService,
    private _colorModeService: ColorModeService,
    private utils: UtilsTranslate,
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.changeFilterInit$ = this._filterService.filterChange.subscribe(
      (res) => {
        if (res.reset) {
          this.nameClient = '';
          this.codeMachine = null;
        }
      }
    );

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    this.startChange$ = this.visibilityClientTillSelectService.reload$.subscribe(
      (res) => {
        if (res) {
          this.start();
        }
      }
    );
  }

  start() {
    this.getClientVisibility();

    this.getMore1Clients();
    this.getMore1Tills();
  }

  ngOnDestroy(): void {
    if (this.changeLanguage$) {
      this.changeLanguage$.unsubscribe();
    }

    if (this.startChange$) {
      this.startChange$.unsubscribe();
    }

    if (this.getTill$) {
      this.getTill$.unsubscribe();
    }

    if (this.filterChange$) {
      this.filterChange$.unsubscribe();
    }

    if (this.getClients$) {
      this.getClients$.unsubscribe();
    }

    if (this.getClientsFilter$) {
      this.getClientsFilter$.unsubscribe();
    }

    if (this.getTillFilter$) {
      this.getTillFilter$.unsubscribe();
    }

    if (this.startChange$) {
      this.startChange$.unsubscribe();
    }

    if (this.changeFilterInit$) {
      this.changeFilterInit$.unsubscribe();
    }
  }

  getClientVisibility() {
    let loadPermission = this._permissionService.getLoadPermssion();
    if (loadPermission) {
      this.changeVisibility();
    } else {
      let i = 0;
      let intervalClient = setInterval(() => {
        let loadPermission = this._permissionService.getLoadPermssion();
        if (loadPermission) {
          this.changeVisibility();
          clearInterval(intervalClient);
        } else if (i == 20) {
          this.loadingVisibility = false;
          this.haveVisibility = {
            client:
              this._permissionService.getUserVisibilityByHierarchy('Client') !==
              null,
            operator:
              this._permissionService.getUserVisibilityByHierarchy(
                'Operator'
              ) !== null,
            operatorGroup:
              this._permissionService.getUserVisibilityByHierarchy(
                'OperatorGroup'
              ) !== null,
          };
        }
        i++;
      }, 250);
    }
  }

  changeVisibility() {
    this.loadingVisibility = false;
    this.haveVisibility = {
      client:
        this._permissionService.getUserVisibilityByHierarchy('Client') !== null,
      operator:
        this._permissionService.getUserVisibilityByHierarchy('Operator') !==
        null,
      operatorGroup:
        this._permissionService.getUserVisibilityByHierarchy(
          'OperatorGroup'
        ) !== null,
    };
    if (this.haveVisibility.client) {
      this.canSend = true;
      this.showClient = false;
    }

    // if (this.haveVisibility.operator && this.changeOperator$) {
    //   this.changeOperator$.unsubscribe();
    // }

    if (this.haveVisibility.operator || this.haveVisibility.client) {
      this.getClientAndTill();
    } else {
      this.updateClientsAndTills();
    }
  }

  getMore1Clients() {
    this.loadings.loadingMore1Client = true;
    this.getClients$ = this._clientService.getClients().subscribe({
      next: (res) => {
        if (res && res.length > 1) {
          this.haveMore1Client = true;
        } else {
          this.haveMore1Client = false;
        }
        this.loadings.loadingMore1Client = false;
      },
      error: (error) => {
        this.utils.presentToastTranslate('error', true, 0);
        this.loadings.loadingMore1Client = false;
      },
    });
  }

  getMore1Tills() {
    this.getTill$ = this._tillService.getTills().subscribe({
      next: (res) => {
        if (res && res.length > 1) {
          this.haveMore1Till = true;
        } else {
          this.haveMore1Till = false;
        }
        this.loadings.loadingMore1Till = false;
      },
      error: () => {
        this.utils.presentToastTranslate('error', true, 0);
        this.loadings.loadingMore1Till = false;
      },
    });
  }

  updateClientsAndTills() {
    this.filterChange$ = this._visibilityService.filterChange.subscribe(
      (res) => {
        if (
          this.operatorId != res?.operatorSelected &&
          !res?.loadingOperators &&
          !res?.loadingOperatorsGroup
        ) {
          // console.log('entre2', this.operatorId, res?.operatorSelected)
          this.getClientAndTill(res.operatorSelected);
        }
      }
    );
  }

  getClientAndTill(operatorSelected: number = null) {
    if (this.getClientsFilter$) {
      this.getClientsFilter$.unsubscribe();
    }

    if (this.getTillFilter$) {
      this.getTillFilter$.unsubscribe();
    }

    this.clientSelected = null;
    this.tillSelected = null;

    this.nameClient = '';
    this.codeMachine = null;

    this.operatorId = operatorSelected;
    // if(this.isComercial){
    //   this._filterClientService.updateFilter(this.operatorId)
    // }
    this.loadings.loadingClient = true;
    this.loadings.loadingTill = true;

    this._filterClientTillService.setClientFilter(null, '');
    this._filterClientTillService.setTillFilter(null, null, null);

    this.codeMachine = null;
    this.nameClient = '';

    this.getClientsFilter$ = this._clientService
      .getClients({ operatorId: this.operatorId })
      .subscribe({
        next: (res) => {
          this.clients = res;
          this.loadings.loadingClient = false;
          if (res.length === 1) {
            this.showClient = false;
            if (!this.codeMachine) {
              this.clientSelected = res[0];
              this.nameClient = res[0].description;
              this._filterClientTillService.setClientFilter(
                res[0].ID,
                res[0].description
              );
              // if (!this.loadingSubsidies) this.loadingSubsidies = true;
              // this._filterClientService.setClient(this.clientSelected.name, this.clientSelected.code, this.clientSelected.ID)
            }
          } else {
            this.showClient = true;
          }
        },
        error: (error) => {
          this.utils.presentToastTranslate('error', true, 0);
        },
      });

    this.getTillFilter$ = this._tillService
      .getTills({ operatorID: this.operatorId })
      .subscribe({
        next: (res) => {
          this.tills = res;

          this.loadings.loadingTill = false;
          if (res.length === 1) {
            this.showTill = false;
            this.tillSelected = res[0];
            this.codeMachine = res[0].code;
            //console.log('codeMachine', this.codeMachine)
            this._filterClientTillService.setTillFilter(
              res[0].id,
              res[0].code,
              res[0].clientId
            );
          } else {
            this.showTill = true;
          }
        },
        error: () => {
          this.utils.presentToastTranslate('error', true, 0);
        },
      });
  }

  async showClientTillFilter() {
    const isLightColor = this._colorModeService.getIsLightColor();

    let modal = await this.modalCtrl.create({
      component: FilterClientTillComponent,
      componentProps: {
        clients: this.clients,
        tills: this.tills,
        showClientFilter: this.showClient && !this.haveVisibility.client,
        title:
          this.showClient && !this.haveVisibility.client
            ? 'machines_status.till_state.filter_machine_client'
            : 'machines_status.till_state.filter_machine',
      },
      cssClass:
        this.platform.width() > pcWidth &&
        `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`,
    });
    if (modal) {
      await modal.present();
      const { data } = await modal.onWillDismiss();
      // console.log('data:', data)
      //let clientId = this._filterClientTillService.getFilter()
      //console.log('test',clientId)
      this.nameClient = this._filterClientTillService.getNameClient();
      this.codeMachine = this._filterClientTillService.getTillCode();

      // if (this.codeMachine || this.nameClient) {
      // this.loadingSubsidies = true;
      // }

      if (data?.name == '' && data?.code == '') {
        this.clear();
      }
    }
  }

  clear() {
    this.clearFilter.emit();
  }

  savePlaceholders() {}
}
