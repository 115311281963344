import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendFilesFilterService {

  fileTransaction: File = null;
  fileDaily: File = null;

  clearEvent$: Subject<void> = new Subject<void>();

  constructor() { }

  getFile(isTransactional: boolean): File{
    return (isTransactional)  ? this.fileTransaction : this.fileDaily;
  }

  setFile(file: File, isTransactional: boolean){
    if(isTransactional){
      this.fileTransaction = file
    }else{
      this.fileDaily = file
    }
  }

  clearFile(isTransactional: boolean){
    if(isTransactional){
      this.fileTransaction = null;
    }else{
      this.fileDaily = null;
    }
  }

  clearFiles(){
    this.clearFile(false);
    this.clearFile(true);
  }


  clear(){
    this.clearFiles()
    this.clearEvent$.next()
  }
}
