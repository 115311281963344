import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

// Models
import { Permission } from 'src/app/models';

// Service
import { PermissionService } from 'src/app/services';

// utils
import {
  DASHBOARD_GROUP_NAME_ROUTE,
  DASHBOARD_HORECA_ROUTE_TEST,
} from 'src/app/utils';

@Injectable({ providedIn: 'root' })
export class PermissionDashboardResolverService {
  constructor(private _permissionService: PermissionService) {}

  resolve(): Observable<Permission[]> {
    return this._permissionService.getAllPermissions().pipe(
      map((res) => {
        let permissionDashboard = res.filter(
          (permission) =>
            permission.nameGroup === DASHBOARD_GROUP_NAME_ROUTE &&
            permission.route !== DASHBOARD_HORECA_ROUTE_TEST
        );
        return permissionDashboard;
      })
    );
  }
}
