import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Company } from 'src/app/models';
import { ColorModeService, SkinMangementService } from 'src/app/services';

@Component({
  selector: 'app-ifree-vend-legend-share',
  templateUrl: './ifree-vend-legend-share.component.html',
  styleUrls: ['./ifree-vend-legend-share.component.scss'],
})
export class IfreeVendLegendShareComponent implements OnInit {

  company: Company;
  isLightColor:boolean = true;

  constructor(
    private modalCtrl: ModalController,
    private _colorModeService: ColorModeService,
    private _skinService: SkinMangementService,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.isLightColor = this._colorModeService.getIsLightColor();
  }


  closeModal() {
    this.modalCtrl.dismiss({});
  }


}
