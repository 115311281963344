// External Module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Module
import { InfoBannerAlertModule } from './info-banner-alert';
import { CardNamePipeModule } from '../../pipes';

// Components
import {
  DoubleLabelVisibilityComponent,
  InfoSuccessAlertComponent,
  InputCompanyComponent,
} from './';

// Services
import { LanguageService } from '../../services/languages.service';


@NgModule({
  declarations: [
    DoubleLabelVisibilityComponent,
    InputCompanyComponent,
    InfoSuccessAlertComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,

    InfoBannerAlertModule,
    CardNamePipeModule
  ],
  providers: [LanguageService],
  exports: [
    InfoBannerAlertModule,

    DoubleLabelVisibilityComponent,
    InputCompanyComponent,
    InfoSuccessAlertComponent,
  ],
})
export class UICustomModule {}
