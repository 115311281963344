import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';


// Services
import { ClientFilterService } from 'src/app/share/components/filter-client/service/client-filter.service';
import { FilterDateService } from 'src/app/share/services/filter-date/filter-date.service';
import { VisibilitySelectService } from '../../../../../share/services/visibility-select/visibility-select.service';
import { FabBrService } from '../../../../../share/components/fab-br/services/fab-br.service';
import { VisibilityClientSelectService }
  from '../../../../../share/components/visibility-client-select/service/visibility-client-select.service';


// models
import { ParamsCheckQR, StatusQRCustomer } from '../../models';
import { VisibilitySegment } from '../../../../../models';


@Injectable({
  providedIn: 'root'
})
export class FilterCheckQrService {
  status: StatusQRCustomer = null;
  qrFilter: string = '';

  operatorId = null;
  operatorGroupId = null;



  // subjects
  changeFilter$: Subject<boolean> = new Subject<boolean>();
  changeQRFilter$: Subject<string> = new Subject<string>()
  segmentChange$: Subject<VisibilitySegment> = new Subject<VisibilitySegment>();
  visibilityChange$: Subscription;


  constructor(
    private _visibilityService: VisibilitySelectService,
    private _filterDateService: FilterDateService,
    private _filterClientService: ClientFilterService,
    private _fabBrService: FabBrService,
    private _visibilityClientSelectService: VisibilityClientSelectService
  ) {

    this.visibilityChange$ = this._visibilityService.segmentChange.subscribe(res => {
      this.segmentChange$.next(res)
    })

  }

  ngOnDestroy(): void {
    if(this.visibilityChange$) this.visibilityChange$.unsubscribe()
  }


  getOptionsValids() {
    return this._visibilityService.getOptionsValids();
  }


  setStatus(status: StatusQRCustomer) {
    this.status = status;
    this.changeFilter$.next(true);
  }

  getStatus() {
    return this.status;
  }

  getQR(){
    return this.qrFilter;
  }

  setQR(qrFilter: string){
    this.qrFilter = qrFilter;
    this.changeQRFilter$.next(qrFilter);
  }


  getParams(): ParamsCheckQR{

    let dateSelected = this._filterDateService.getDate();
    let clientId = this._filterClientService.getClientId()
    const typeDate = this._visibilityClientSelectService.getTypeDate()

    //console.log('cliendId', clientId);

    let params:ParamsCheckQR = {
      statusQR: this.status || 'emitido',
    }

    if(typeDate === 'create'){
      params = {...params,
        dateStartCreate: dateSelected?.datainici,
        dateEndCreate: (dateSelected.datafinal) ? dateSelected.datafinal : dateSelected?.datainici
      }
    }else{
      params = {
        ...params,
        dateStart: dateSelected?.datainici,
        dateEnd: (dateSelected.datafinal) ? dateSelected.datafinal : dateSelected?.datainici}
    }

    if(clientId){
      params = {...params, clientId}
    }

    if(this.qrFilter){
      params = {...params, qr: this.qrFilter}
    }

    // console.log('params', params)
    return params;

  }


  resetParams() {
    this._visibilityService.resetParams()
    this._filterClientService.clearFilter();
    this._filterDateService.resetDate();
  }

  setExcelValid(valid: boolean){

    this._fabBrService.setShowDownloadExcel(valid, '/user/check-qr')

  }
}
