
export const MAX_CODE_ARTICLE: number = 15;
export const MAX_DESCRIPTION_ARTICLE: number = 40;

// Type
export const MAX_NEW_TYPE_SUBTYPE: number = 8;
export const MAX_NEW_TYPE_DESCRIPTION: number = 40;
export const MAX_NEW_TYPE_NOTE: number = 40;

// Image
export const HEIGHT_MAX_ARTICLE_IMAGE: number =  150;
export const WIDTH_MAX_ARTICLE_IMAGE: number =  150;

// Default Family
export const DEFAULT_GROUP_ARTICLE = 'Default Group'
