import { WritableSignal } from "@angular/core";

import { MultipleMachineSelected } from "src/app/share/components/select-multiple-machine/models";
import { FilterClientOptionsRM, PositionVisibilityRouteManager, TillSelectedRM } from "../../models";


interface SelectOneMachineByInfoModalRM {
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
  tillOneMachineSelectedInfo: MultipleMachineSelected;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  filterClientOptions: WritableSignal<FilterClientOptionsRM>;
  positionVisibility: WritableSignal<PositionVisibilityRouteManager>;
}

export const selectOneMachineByInfoModalRM = ({
  tillOneMachineSelected,
  tillOneMachineSelectedInfo,
  tillsSelected,
  filterClientOptions,
  positionVisibility
}: SelectOneMachineByInfoModalRM) => {

  filterClientOptions.set(FilterClientOptionsRM.filterOneMachine);
  positionVisibility.set(PositionVisibilityRouteManager.client);

  tillsSelected.set([])

  tillOneMachineSelected.set(tillOneMachineSelectedInfo);

};
