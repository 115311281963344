import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import {
  IonInfiniteScroll,
  ToastController,
  ModalController,
} from '@ionic/angular';
import { Subscription } from 'rxjs';

// Service
import { TranslateService } from '@ngx-translate/core';
import { CardService } from 'src/app/share/services/';
import {
  VisibilitySelectService,
  LanguageRouteService,
} from '../../../../services';
import { SkinMangementService } from '../../../../../services';
import { FilterCardNumService } from '../../../filter-card-num/service/filter-card-num.service';

// Models
import { Company, Card } from '../../../../../models';
import { TypeFilterCardFilter } from '../../../filter-card-num/filter-card-num.component';

// Utils
import { UtilsTranslate, presentToast } from '../../../../../utils';

@Component({
  selector: 'app-filter-card-individual',
  templateUrl: './filter-card-individual.component.html',
  styleUrls: ['./filter-card-individual.component.scss'],
})
export class FilterCardIndividualComponent implements OnInit {
  @Input() skip: number = null;
  @Input() operatorGroupId: number = null;
  @Input() operatorId: number = null;
  @Input() cardNum: string = '';
  @Input() positionCardNum: number = 0;
  @Input() filterOperatorGroup: boolean = false;
  @Input() filterOperator: boolean = false;
  @Input() valid: number = 1;
  @Input() inifiteScrollActive = true;

  @Input() haveBankCardOption: boolean = false;

  @Output() sendCard = new EventEmitter<CardEmitter>();
  @Output() moveUp = new EventEmitter<void>();

  // aspecto
  company: Company;

  cardSelected = null;

  cards: Card[] = [];

  typeFilter: TypeFilterCardFilter = 'cardNum';
  bankCardNum: string = '';
  bankCardNumAnterior: string = '';

  loading = true;
  loadingChange = false;

  // translates
  bankCardErrorTranslate = '';

  filterCard$: Subscription = null;
  filterChange$: Subscription;
  changeLanguage$: Subscription;

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private toastCrl: ToastController,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _filterService: FilterCardNumService,
    private _cardService: CardService,
    private _visibilityFilterService: VisibilitySelectService,
    private utils: UtilsTranslate
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    if (this.haveBankCardOption) {
      this.typeFilter = this._filterService.getBankCardOption();
    }

    let { cardNum, cardId } = this._filterService.getCard(this.positionCardNum);

    this.cardNum = cardNum;
    this.cardSelected = cardId;

    let params = {
      operatorId: this.operatorId,
      operatorGroupId: this.operatorGroupId,
      cardNum: this.cardNum,
      skip: this.cards.length,
      valid: this.valid,
    };

    if (!this.filterOperatorGroup) {
      delete params.operatorGroupId;
    }
    if (!this.filterOperator) {
      delete params.operatorId;
    }

    this._filterService.getCards(params).subscribe({
      next: (res) => {
        this.cards = res;
        this.loading = false;
      },
      error: (error) => {
        this.utils.presentToastTranslate('error', true, 0);
        this.loading = false;
      },
    });

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    this.filterChange$ = this._filterService.filterChange.subscribe((res) => {
      if (res.positionCardNum == this.positionCardNum) {
        this.cardNum = res.cardNum;
        this.cardSelected = res.cardId;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.filterOperatorGroup?.previousValue == undefined) {
      return;
    }
    this._filterService.resetCard();
    this.cardNum = '';
    this.cardSelected = null;

    this.loading = true;
    let params = {
      operatorId: this.operatorId,
      operatorGroupId: this.operatorGroupId,
      cardNum: this.cardNum,
      skip: this.cards.length,
      valid: this.valid,
    };

    if (!this.filterOperatorGroup) {
      delete params.operatorGroupId;
    }
    if (!this.filterOperator) {
      delete params.operatorId;
    }

    this._filterService.getCards(params).subscribe((res) => {
      this.cards = res;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if (this.filterChange$) this.filterChange$.unsubscribe();
  }

  changeCardNum() {
    this.inifiteScroll.disabled = false;

    let params = {
      operatorId: this.operatorId,
      operatorGroupId: this.operatorGroupId,
      cardNum: this.cardNum,
      skip: 0,
      valid: this.valid,
    };
    if (!this.filterOperatorGroup) {
      delete params.operatorGroupId;
    }
    if (!this.filterOperator) {
      delete params.operatorId;
    }

    if (this.filterCard$) {
      this.filterCard$.unsubscribe();
    }
    this.cards = [];
    // console.log('params', params)
    this.loadingChange = true;

    // console.log('params', params)
    this.filterCard$ = this._filterService.getCards(params).subscribe({
      next: (res) => {
        this.cards = res;
        this.loadingChange = false;
      },
      error: () => {
        this.utils.presentToastTranslate('error', true, 0);
        this.loadingChange = false;
      },
    });
  }

  //change filter
  // changeFilter(newValue) {
  //   this.typeFilter = newValue;
  // }

  closeModal() {
    if (this.modalCtrl) {
      this.modalCtrl.dismiss({});
    }
  }

  filterCardFilter() {
    //console.log('typeFIlter', this.typeFilter, 'bankCardNum', this.bankCardNum)

    if (this.typeFilter == 'cardNum') {
      let selectFilter: Card | null = this.cards.find(
        (card) => card.id == this.cardSelected
      );
      if (selectFilter) {
        // console.log("selectFilter", selectFilter)
        this._filterService.setCardNum(
          selectFilter.cardNum,
          selectFilter.id,
          this.positionCardNum
        );
        this.modalCtrl.dismiss({});
      } else {
        this.utils.showError()
      }
    } else {
      if (this.bankCardNum.length != 16 || !this.bankCardNum.includes('*')) {
        this.utils.presentToastWithoutTranslate(this.bankCardErrorTranslate)
      } else {
        // 6N + 6* + 4N
        for (let i = 0; i < this.bankCardNum.length; i++) {
          let typePos = i > 5 && i < 12 ? '*' : 'number';
          if (typePos == '*' && this.bankCardNum[i] != '*') {
            //console.log('*', this.bankCardNum[i], i)
            this.utils.presentToastWithoutTranslate(this.bankCardErrorTranslate)
            return;
          } else if (
            typePos == 'number' &&
            (this.bankCardNum[i] < '0' || this.bankCardNum[i] > '9')
          ) {
            //console.log('number', this.bankCardNum[i], i)
            this.utils.presentToastWithoutTranslate(this.bankCardErrorTranslate)
            return;
          }
        }
        this._filterService.setBankCard(this.bankCardNum);
        this.modalCtrl.dismiss({});
      }
    }
  }

  deleteCardFilter() {
    this.cardNum = '';
    this.bankCardNum = '';
    // this.haveBankCardOption = false;
    this.typeFilter = 'cardNum';

    this._filterService.resetCard(this.positionCardNum);
    this.modalCtrl.dismiss({});
  }

  loadData(event) {
    let params = {
      operatorId: this.operatorId,
      operatorGroupId: this.operatorGroupId,
      cardNum: this.cardNum,
      skip: this.cards.length,
      valid: this.valid,
    };

    if (!this.filterOperatorGroup) {
      delete params.operatorGroupId;
    }
    if (!this.filterOperator) {
      delete params.operatorId;
    }

    this._filterService.getCards(params).subscribe({
      next: (res) => {
        if (res.length > 0) {
          this.cards.push(...res);
          this.inifiteScroll.complete();
          this.moveUp.emit();
        } else {
          this.inifiteScroll.complete();
          this.inifiteScroll.disabled = true;
        }
      },
      error: () => {
        this.utils.presentToastTranslate('error', true, 0);
      },
    });
  }

  //TARJETA BANCARIA
  bankCardChange(event) {
    //console.log('cards', this.bankCardNum, this.bankCardNumAnterior,  this.bankCardNum.length, this.bankCardNum.length)
    if (this.bankCardNum.length == 0) {
      this.bankCardNumAnterior = this.bankCardNum;
    }

    if (
      this.bankCardNum.length > this.bankCardNumAnterior.length &&
      this.bankCardNum.length == 6 &&
      !this.bankCardNum.includes('*')
    ) {
      this.bankCardNum += '******';
    }
    this.bankCardNumAnterior = this.bankCardNum;
  }

  savePlaceholders() {
    this.translate
      .get(['transaction_query.error_4_digits'])
      .subscribe((translates) => {
        this.bankCardErrorTranslate =
          translates['transaction_query.error_4_digits'];
      });
  }

  changeCardSelected() {
    if (!this.cardSelected) {
      return;
    }

    if (this.typeFilter == 'cardNum') {
      let selectFilter: Card | null = this.cards.find(
        (card) => card.id == this.cardSelected
      );
      if (selectFilter) {
        this._filterService.setCardNum(
          selectFilter.cardNum,
          selectFilter.id,
          this.positionCardNum
        );
        this.sendCard.emit({
          id: selectFilter.id,
          cardnum: selectFilter.cardNum,
          isBankCard: false,
        });
      } else {
        this.utils.showError()
      }
    } else {
      if (this.bankCardNum.length != 16 || !this.bankCardNum.includes('*')) {
        this.utils.presentToastWithoutTranslate(this.bankCardErrorTranslate)
      } else {
        // 6N + 6* + 4N
        for (let i = 0; i < this.bankCardNum.length; i++) {
          let typePos = i > 5 && i < 12 ? '*' : 'number';
          if (typePos == '*' && this.bankCardNum[i] != '*') {
            //console.log('*', this.bankCardNum[i], i)
            this.utils.presentToastWithoutTranslate(this.bankCardErrorTranslate)
            return;
          } else if (
            typePos == 'number' &&
            (this.bankCardNum[i] < '0' || this.bankCardNum[i] > '9')
          ) {
            //console.log('number', this.bankCardNum[i], i)
            this.utils.presentToastWithoutTranslate(this.bankCardErrorTranslate)
            return;
          }
        }
        this._filterService.setBankCard(this.bankCardNum);
        this.sendCard.emit({
          cardnum: this.bankCardNum,
          isBankCard: true,
          id: null,
        });
      }
    }
  }
}

export interface CardEmitter {
  isBankCard: boolean;
  cardnum: string;
  id: number;
}
