import { WritableSignal } from '@angular/core';

// models
import { Client, Supervisor } from 'src/app/models';
import { TillSelectedRM } from '../../models';

interface RemoveInfoInvalidOperator {
  haveOperatorVisibility: boolean;
  clientsByOperator: WritableSignal<Client[]>;
  clientAssigmentSelected: WritableSignal<Client>;
  supervisorsByOperator: WritableSignal<Supervisor[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
}

export const removeInfoInvalidOperator = ({
  haveOperatorVisibility,
  clientsByOperator,
  clientAssigmentSelected,
  supervisorsByOperator,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
}: RemoveInfoInvalidOperator) => {
  if (!haveOperatorVisibility) {
    clientsByOperator.set([]);
    clientAssigmentSelected.set(null);
    supervisorsByOperator.set([]);
    tillsByOperator.set([]);
  }

  tillsSelected.set([]);
  tillOneMachineSelected.set(null);
};
