// models
import { FilterTableTillAlert, TillAlertSpiderTable } from '../../models';

// utils
import { removeDuplicateObject } from 'src/app/utils';
import { getTillAlertsByFilter } from '.';

export const getTillAlertFilterClient = (
  tillsAlerts: TillAlertSpiderTable[],
  filtersTable: FilterTableTillAlert
) => {
  let filterTableWithTypeFilter = getTillAlertsByFilter(
    tillsAlerts,
    filtersTable,
    'client'
  );

  let clients: {
    client: string;
    clientId: number;
    clientCode: string;
  }[] = filterTableWithTypeFilter.map((tillAlert) => ({
    client: tillAlert.client.client,
    clientId: tillAlert.client.clientId,
    clientCode: tillAlert.client.clientCode,
  }));

  clients = removeDuplicateObject(clients, 'clientId');

  return clients.sort((a, b) => a.client.localeCompare(b.client));
};
