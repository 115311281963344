import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs/internal/Subscription';

// Service
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from '../../../services/languageRoute.ts/languagesRoute.services';
import { SkinMangementService } from '../../../../services/skin-mangement.service';

// Models
import { Company } from 'src/app/models/';

// Utils
import { type } from 'src/app/utils/constants';


@Component({
  selector: 'app-idle-modal',
  templateUrl: './idle-modal.component.html',
  styleUrls: ['./idle-modal.component.scss'],
})
export class IdleModalComponent implements OnInit {

  @Input() timeText: number = null;

   // aspecto
   company: Company;
   type = type;


   languageChange$: Subscription;
   onTimeoutWarning$: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private idle: Idle,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.languageChange$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
    });

    // actualizar tiempo
    this.onTimeoutWarning$ = this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.timeText = countdown;
    })

  }

  ngOnDestroy(){
    if(this.languageChange$) this.languageChange$.unsubscribe()
    if(this.onTimeoutWarning$) this.onTimeoutWarning$.unsubscribe()
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  exit(){
    this.modalCtrl.dismiss({exit: true});
  }

}
