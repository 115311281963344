import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Service
import { SkinMangementService } from '../../../../../../../services/skin-mangement.service';
import { PermissionService } from '../../../../../../../services/permission.service';

// Models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-legend-ticket-check',
  templateUrl: './legend-ticket-check.component.html',
  styleUrls: ['./legend-ticket-check.component.scss'],
})
export class LegendTicketCheckComponent implements OnInit {

  @Input() company: Company;
  @Input() showOG: boolean = true;
  @Input() showOperator: boolean = true;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
    private _permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
    let loadPermission = this._permissionService.getLoadPermssion();
    if (loadPermission) {
      this.showOG =  this._permissionService.getUserVisibilityByHierarchy('Operator') !== null
      this.showOperator =  this._permissionService.getUserVisibilityByHierarchy('OperatorGroup') !== null
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }


}
