import * as moment from 'moment';

//constant
import { THREE_MONTHS } from '../constants';

export type dateTypes = 'days' | 'weeks' | 'months' | 'years';

interface AddTimeDate {
  startDate: Date | string;
  increment: number;
  typeIncrement: dateTypes;
}

export const addTimeDate = ({
  startDate,
  increment,
  typeIncrement,
}: AddTimeDate): Date => {
  return moment(startDate).add(increment, typeIncrement).toDate();
};

export const getDateByFormats = (date: string, formats: string[]): Date => {
  return moment(date, formats).toDate() || null;
};

export const useChangeFormatDate = (date: string | Date, format: string): string => {
  return moment(date).format(format)
}

export const stringToDate = (date: string)=> {
  return moment(date).toDate()
}

export const diffDates = (
  dateStart: Date,
  dateEnd: Date,
  typeDiff: dateTypes = 'days'
): number => {
  return moment(dateEnd).diff(dateStart, typeDiff);
};

export const diffDatesLess3Months = (
  dateStart: Date,
  dateEnd: Date
): boolean => {
  return diffDates(dateStart, dateEnd, 'months') <= 3;
};

export const diffDatesLess90Days = (
  dateStart: Date,
  dateEnd: Date
): boolean => {
  return diffDates(dateStart, dateEnd, 'days') <= THREE_MONTHS;
};

export const getLastMondayByDate = (date: Date) => {
  const weekFormat = getWeekFormat(date);

  return getDayByWeekFormat(weekFormat)
};

const getWeekFormat = (date: Date) => {
  const WEEK =  moment(date).weeks() < 10 ? 'W0' : 'W';
  return `${moment(date).year()}-${WEEK}${moment(date).weeks()}`;
};

const getDayByWeekFormat = (weekFormat: string) => {
  return moment(weekFormat).toDate();
};
