import { pcWidth } from 'src/app/utils';
import {
  DIMENSION_SHOW_ONE_CHART,
  DIMENSION_SHOW_ONE_CHART_MENU,
  getDimensionOneChart,
} from '..';

const WIDTH_ASPECT_RATIO = {
  pc: 2,
  md: 1.5,
  sm: 0.8,
};

const HEIGHT_VH_BY_WIDTH = {
  pc: 37.5,
  md: 55,
};

export const getHeightVh = (width: number, isOpenFixMenu: boolean) => {
  const dimension = getDimensionOneChart(isOpenFixMenu);
  return width > dimension ? HEIGHT_VH_BY_WIDTH.pc : HEIGHT_VH_BY_WIDTH.md;
};

export const getAspectRatio = (width: number, isOpenFixMenu: boolean) => {
  if (!isOpenFixMenu) {
    return getAspectRatioNotFixMenu(width);
  } else {
    return getAspectRatioFixMenu(width);
  }
};

export const getAspectRatioNotFixMenu = (width: number) => {
  if (width > DIMENSION_SHOW_ONE_CHART) {
    return WIDTH_ASPECT_RATIO.pc;
  }

  if (width > pcWidth) {
    return WIDTH_ASPECT_RATIO.md;
  }

  return WIDTH_ASPECT_RATIO.sm;
};

export const getAspectRatioFixMenu = (width: number) => {
  if (width > DIMENSION_SHOW_ONE_CHART_MENU) {
    return WIDTH_ASPECT_RATIO.pc;
  }

  if (width > pcWidth) {
    return WIDTH_ASPECT_RATIO.md;
  }

  return WIDTH_ASPECT_RATIO.sm;
};

