export const MIN_DEFAULT_HOUR_MAX_MACHINE_ALERT = 24;
export const MACHINE_ALERT_MODAL_PC_SIZE = 1250;
export const POSITION_START_SCROLL_MACHINE_ALERT = 20;
export const TIME_RELOAD_DATE_EDIT_TO_CREATE = 250;

export const WEEK_MAX_DAYS = 7;

// --------------------- Permissions Routes ---------------------
export const MACHINE_STATUS_ROUTE = '/user/machines-status';

export const MACHINE_STATUS_NAME = {
  till_state: {
    notIsAena: 'Machine Status',
    isAena: 'Machine Status (AENA)',
  },
  current_state: {
    notIsAena: 'Estado actual máquinas',
    isAena: 'Estado actual máquinas (AENA)',
  },
};

// --------------------- Default Hour --------------------
export const DEFAULT_HOUR_TYPE_ALERT_MACHINE_ALERT = {
  connectivity: 6,
  innactivity: {
    isAena: 4,
    notIsAena: 2,
  },
};

export const MIN_DEFAULT_HOUR_MACHINE_ALERT = 2;

// --------------------- Type alert ---------------------
export const CONNECTIVITY_STATUS_ALERT_NAME_DB = 'disconnected';
export const ACTIVITY_STATUS_ALERT_NAME_DB = 'sales performance';

export const CONNECTIVITY_STATUS_ALERT_NAME_TRANSLATE =
  'machines_status.machine_status_alert.machine_alert_modal.connectivity';
export const ACTIVITY_STATUS_ALERT_NAME_TRANSLATE =
  'machines_status.machine_status_alert.machine_alert_modal.activity';

export const STATUS_MACHINE_ALERT = {
  [CONNECTIVITY_STATUS_ALERT_NAME_DB]:
    CONNECTIVITY_STATUS_ALERT_NAME_TRANSLATE,
  [ACTIVITY_STATUS_ALERT_NAME_DB]:
    ACTIVITY_STATUS_ALERT_NAME_TRANSLATE,
};

// export const TYPE_ALERT_OPTION_BY_DB = {
//   [CONNECTIVITY_STATUS_ALERT_NAME_DB]: TypeMachineAlertOptions.Connectivity,
//   [ACTIVITY_STATUS_ALERT_NAME_DB]: TypeMachineAlertOptions.Activity,
// }


export const PERFOMANCE_TYPE_ALERT = {
  haveBankSales: 'Sales Performance Bank Card',
  notHaveBankSales: 'Sales Performance',
}


