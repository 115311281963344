import { WritableSignal } from '@angular/core';

// models
import {
  ArticleClientDashboard,
  FilterDashboards,
  FamilyStorageDashboard,
} from '../../models';

// ---------------------------------------- Interfaces ----------------------------------------  //

interface ValidateFamilyId {
  articles: ArticleClientDashboard[];
  familiesStorage: FamilyStorageDashboard;
  filtersDashboard: WritableSignal<FilterDashboards>;
}

interface ValidateFamilyIdArea extends ValidateFamilyId {
  area: AreasValids;
}

interface ExistFamilyInArticles {
  familyArea: { id: number; description: string };
  articles: ArticleClientDashboard[];
  filtersDashboard: WritableSignal<FilterDashboards>;
  area: AreasValids;
}

interface IsValidFamilyNotFirstTime {
  articles: ArticleClientDashboard[],
  filtersDashboard: WritableSignal<FilterDashboards>,
}

interface IsValidFamilyPosNotFirstTime {
  articles: ArticleClientDashboard[],
  filtersDashboard: WritableSignal<FilterDashboards>,
  area: AreasValids;
}

type AreasValids = 'areaA' | 'areaB';

// ---------------------------------------- Functions ----------------------------------------  //
export const validateFamilyId = ({
  articles,
  familiesStorage,
  filtersDashboard,
}: ValidateFamilyId) => {
  if (familiesStorage.isFirtTime) {
    familiesStorage.isFirtTime = false;
    return isValidFamilyFirstTime({
      articles,
      familiesStorage,
      filtersDashboard,
    });
  } else {
    return isValidFamilyNotFirstTime({
      articles,
      filtersDashboard,
    });
  }
};

const isValidFamilyFirstTime = ({
  articles,
  familiesStorage,
  filtersDashboard,
}: ValidateFamilyId) => {
  const isValidFamilyAreaAFirstTime = isValidFamilyPosFirstTime({
    articles,
    familiesStorage,
    filtersDashboard,
    area: 'areaA',
  });

  const isValidFamilyAreaBFirstTime = isValidFamilyPosFirstTime({
    articles,
    familiesStorage,
    filtersDashboard,
    area: 'areaB',
  });

  return isValidFamilyAreaAFirstTime && isValidFamilyAreaBFirstTime;
};

const isValidFamilyPosFirstTime = ({
  articles,
  familiesStorage,
  filtersDashboard,
  area,
}: ValidateFamilyIdArea) => {
  const familyArea =
    area === 'areaA' ? familiesStorage.familyA : familiesStorage.familyB;

  if (!familyArea || !familyArea?.id) {
    return true;
  }

  if (
    filtersDashboard().articles?.[area]?.article?.id &&
    familyArea?.description
  ) {
    filtersDashboard().articles[area].family = null;
    return false;
  }

  return existFamilyInArticles({
    familyArea,
    articles,
    filtersDashboard,
    area,
  });
};

const isValidFamilyNotFirstTime = ({
  articles,
  filtersDashboard,
}: IsValidFamilyNotFirstTime) => {
  const isValidFamilyAreaA = isValidFamilyPosNotFirstTime({
    articles,
    filtersDashboard,
    area: 'areaA',
  });

  const isValidFamilyAreaB = isValidFamilyPosNotFirstTime({
    articles,
    filtersDashboard,
    area: 'areaB',
  });

  return isValidFamilyAreaA || isValidFamilyAreaB;
};

const isValidFamilyPosNotFirstTime = ({
  articles,
  filtersDashboard,
  area,
}: IsValidFamilyPosNotFirstTime) => {
  const familyArea = filtersDashboard().articles[area].family;

  if(!familyArea){
    return true;
  }

  const existArticle = articles.some(
    (article) => article.FamilyID === familyArea.familyId
  );

  if(!existArticle){
    filtersDashboard().articles[area].family = null;
    return false
  }
  return true;
};

const existFamilyInArticles = ({
  familyArea,
  articles,
  filtersDashboard,
  area,
}: ExistFamilyInArticles) => {
  const existArticle = articles.some(
    (article) => article.FamilyID === familyArea.id
  );
  if (existArticle) {
    filtersDashboard().articles[area].family = {
      family: familyArea.description,
      familyId: familyArea.id,
    };
    return true;
  }

  return false;
};
