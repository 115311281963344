import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { pcWidth } from '../../../../../../../utils/';

@Component({
  selector: 'app-buttons-send-aena-share',
  templateUrl: './buttons-send-aena-share.component.html',
  styleUrls: ['./buttons-send-aena-share.component.scss'],
})
export class ButtonsSendAenaShareComponent implements OnInit {


  @Input() company: Company;
  @Input() disabledButtonSave = false;
  @Input() disabledButtonDelete = false;
  @Input() haveRemoveAll = true;

  @Output() saveFamiliesClicked = new EventEmitter()
  @Output() showAlertDeleteAll = new EventEmitter()

  pcWidth = pcWidth;

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}


  saveFamilies(){
    this.saveFamiliesClicked.emit()
  }

  showAlertDeleteAllRelation(){
    this.showAlertDeleteAll.emit()
  }

}
