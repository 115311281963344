import { environment } from '../../environments/environment';

export class AppConfig {
    public static RTVnodeUrl = environment.RTVnodeUrl;
    public static RedsysUrl = environment.Redsys;
    public static BaseURLAlliance = environment.baseUrlAlliance;
    public static BaseURLElora = environment.baseUrlElora;
    public static BaseURLRocca = environment.baseURLRocca;
    public static BaseURLTriangle = environment.baseURLTriangle;
    public static BaseURLImage = environment.baseURLImage
    public static RTVActionNodeUrl = environment.RTVActionNodeUrl;
}
