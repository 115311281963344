import { WritableSignal } from '@angular/core';

// models
import { DatePickerFilterOptions } from 'src/app/share/components/filter-date-template/models';

// utils
import { UtilsTranslate } from 'src/app/utils';
import { DURATION_TOAST_ONLY_MONDAY_REPORT } from './constants';

// constant
const ATTRIBUTE_DISABLED = 'aria-disabled';
const CLASSNAME_MAT_CALENDAR = 'mat-calendar-body-cell';
const CLASSNAME_MAT_CALENDAR_CHILD = 'mat-calendar-body-cell-content';
const TYPE_MAT_CALENDAR = 'button';

interface InvalidDateClickedReport {
  ev: { target: HTMLInputElement };
  datePickerFilter: WritableSignal<DatePickerFilterOptions>;
  utils: UtilsTranslate;
}

export const invalidDateClickedReport = ({
  ev,
  datePickerFilter,
  utils,
}: InvalidDateClickedReport) => {
  if (datePickerFilter() !== DatePickerFilterOptions.OnlyMonday) {
    return;
  }

  if (!ev) {
    return;
  }

  const target = ev?.target;

  if (!target) {
    return;
  }

  if (isMatCalendar(target)) {
    isMatCalendarDisabled(target, utils);
    return;
  }

  isMatCalendarChildDisabled(target, utils);
};

const isMatCalendar = (target) => {
  if (!target?.className) {
    return false;
  }

  if (!target?.type) {
    return false;
  }

  return (
    (target?.className || []).includes(CLASSNAME_MAT_CALENDAR) &&
    (target?.type || '').toLowerCase() === TYPE_MAT_CALENDAR
  );
};

const isMatCalendarChildDisabled = (target, utils: UtilsTranslate) => {
  if (!isMatCalendarChild(target)) {
    return;
  }
  const parent = target?.parentElement || null;
  isMatCalendarDisabled(parent, utils);
};

const isMatCalendarChild = (target) => {

  if (!target?.className) {
    return false;
  }

  return (target?.className || []).includes(CLASSNAME_MAT_CALENDAR_CHILD);
};

const isMatCalendarDisabled = (target, utils: UtilsTranslate) => {

  if (!target?.getAttribute) {
    return;
  }

  if (target.getAttribute(ATTRIBUTE_DISABLED)) {
    utils.presentToastTranslate(
      'reports.create_edit.periocity_week_clicked',
      true,
      0,
      DURATION_TOAST_ONLY_MONDAY_REPORT
    );
  }
};
