import { WritableSignal } from '@angular/core';

// models
import { MachineAlert } from '../../models';

interface DeactivateAllTillsMachineStatus {
  tills: WritableSignal<MachineAlert[]>;
  tillsByClient: WritableSignal<MachineAlert[]>;
  machineOneSelected: WritableSignal<MachineAlert>;
}

export const deactivateAllTillsMachineStatus = ({
  tills,
  machineOneSelected,
  tillsByClient,
}: DeactivateAllTillsMachineStatus) => {
  deactivateTills(tills);

  deactivateTills(tillsByClient);

  machineOneSelected.set(null);
};

const deactivateTills = (tills: WritableSignal<MachineAlert[]>) => {
  const tillsAllDeactivate = tills().map((till) => ({
    ...till,
    isSelected: false,
  }));
  tills.set(tillsAllDeactivate);
};
