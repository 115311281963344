// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-100 {
  width: 90% !important;
}

.min-height-250 {
  min-height: 250px;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-client-by-operator/filter-client-by-operator.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".d-flex-center{\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.w-100{\r\n  width: 90% !important;\r\n}\r\n\r\n.min-height-250{\r\n  min-height: 250px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
