import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { PermissionService } from 'src/app/services';

// Utils
import {
  NAME_BASIC_CAPACITY_TILL_MAPPING,
  NAME_COMPLEX_CAPACITY_TILL_MAPPING,
  NAME_COMPLEX_TILL_MAPPING,
  ROUTE_TILL_MAPPING,
} from '../utils/constant';

@Injectable({ providedIn: 'root' })
export class TillMappingResolverService {
  constructor(private _permissionService: PermissionService) {}

  resolve(): Observable<{ complex: boolean; capacity: boolean }> {
    return this._permissionService.getAllPermissions().pipe(
      map((res) => {
        const complex = res.find(
          (permission) =>
            permission.route === ROUTE_TILL_MAPPING &&
            (permission.name === NAME_COMPLEX_TILL_MAPPING ||
              permission.name === NAME_COMPLEX_CAPACITY_TILL_MAPPING)
        );

        const capacity = res.find(
          (permission) =>
            permission.route === ROUTE_TILL_MAPPING &&
            (permission.name === NAME_BASIC_CAPACITY_TILL_MAPPING ||
              permission.name === NAME_COMPLEX_CAPACITY_TILL_MAPPING)
        );

        return { complex: !!complex, capacity: !!capacity };
      })
    );
  }
}
