// models
import { Till } from 'src/app/models';

// utils
import { isValidObjectId } from 'src/app/utils';

// -------------------------------------------- interfaces ----------------------------------
interface IsValidTillSelectedTransactionQuery {
  operatorIds: number[];
  clientId: number;
  codeMachine: string;
  codeStartMachine: string;
  tills: Till[];
}

interface IsValidCodeMachine {
  codeMachine: string;
  tillsFiltered: Till[];
}

interface IsValidCodeStartMachine {
  codeStartMachine: string;
  tillsFiltered: Till[];
}

interface GetTillsFiltered {
  tills: Till[];
  operatorId: number;
  clientId: number;
}

// -------------------------------------------- functions ----------------------------------
export const isValidTillSelectedTransactionQuery = ({
  operatorIds,
  clientId,
  codeMachine,
  codeStartMachine,
  tills,
}: IsValidTillSelectedTransactionQuery) => {
  if (!codeMachine && !codeStartMachine) {
    return true;
  }

  const operatorId = operatorIds?.[0] || null;

  if (!operatorId && !clientId) {
    return true;
  }

  const tillsFiltered = getTillsFiltered({ tills, operatorId, clientId });

  if ((tillsFiltered || []).length === 0) {
    return false;
  }

  if (codeMachine) {
    return isValidCodeMachine({ codeMachine, tillsFiltered });
  }

  return isValidCodeStartMachine({
    codeStartMachine,
    tillsFiltered,
  });
};

const isValidCodeMachine = ({
  codeMachine,
  tillsFiltered,
}: IsValidCodeMachine) => {
  return tillsFiltered.some((till) => till.code.toString() === codeMachine);
};

const isValidCodeStartMachine = ({
  tillsFiltered,
  codeStartMachine,
}: IsValidCodeStartMachine) => {
  return tillsFiltered.some((till) =>
    till.code.toString().startsWith(codeStartMachine)
  );
};

const getTillsFiltered = ({
  operatorId,
  clientId,
  tills,
}: GetTillsFiltered) => {
  let tillsFiltered = tills;

  if (isValidObjectId(operatorId)) {
    tillsFiltered = tills.filter((till) => till.operatorId === operatorId);
  }

  if (isValidObjectId(clientId)) {
    tillsFiltered = tills.filter((till) => till.clientId === clientId);
  }

  return tillsFiltered;
};
