import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { PermissionService } from 'src/app/services';

// Utils
import { TURN_OVER_HIGH_LEVEL_NAME, TURN_OVER_ROUTE } from '../utils/constant';

@Injectable({ providedIn: 'root' })
export class TurnOverResolverService {
  constructor(private _permissionService: PermissionService) {}

  resolve(): Observable<{ sameHierarchy: boolean }> {
    return this._permissionService.getAllPermissions().pipe(
      map((res) => {
        const sameHierarchy = res.find(
          (permission) =>
            permission.route ===  TURN_OVER_ROUTE &&
            (permission.name === TURN_OVER_HIGH_LEVEL_NAME )
        );

        return { sameHierarchy: !!sameHierarchy};
      })
    );
  }
}
