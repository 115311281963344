import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

// Service
import { PermissionService } from 'src/app/services';

@Injectable({ providedIn: 'root' })
export class OperatorGroupResolverService  {
  constructor(private _permissionService: PermissionService) {}

  resolve(): Observable<any> {
    return this._permissionService.getVisibility().pipe(
      take(1),
      map((res) => res[0].operatorGroupId)
    );
  }
}
