// External modules
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { CardsWithTitleCompanyTemplateModule } from './card-with-title-company-template/card-with-title-company-template.component.module';
import { CardsWithTitleDropDownTemplateModule } from 'src/app/share/components/cards-template/card-with-title-dropdown-template/cards-with-title-dropdown-template.component.module';

// Component

// Service
import { LanguageService } from '../../services';
import { SkinMangementService } from 'src/app/services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    HttpClientModule,

    CardsWithTitleCompanyTemplateModule,
    CardsWithTitleDropDownTemplateModule,
  ],
  declarations: [],
  providers: [LanguageService, SkinMangementService],
  exports: [
    CardsWithTitleCompanyTemplateModule,
    CardsWithTitleDropDownTemplateModule,
  ],
})
export class CardsTemplateModule {}
