import { WritableSignal } from '@angular/core';

// models
import { Report } from '../../models';

// utils
import { getActivationTotalReport } from './get-total-active-report';

interface SetTotalsActiveReports {
  reports: Report[];
  totalActive: WritableSignal<number>;
  totalInactive: WritableSignal<number>;
}

export const setTotalsActiveReports = ({
  reports,
  totalActive,
  totalInactive,
}: SetTotalsActiveReports) => {
  getActivationTotalReport({
    reports,
    total: totalActive,
    isActive: true,
  });

  getActivationTotalReport({
    reports,
    total: totalInactive,
    isActive: false,
  });
};
