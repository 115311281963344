// models
import { CardNames, Company } from 'src/app/models';

// utils
import {
  PROCESS_DESCRIPTION_TYPE_REPORT_TRANSLATE,
  TYPE_REPORTS_INFO_TRANSLATE,
} from './type-reports-info-translate';

export const getTypeReportDescriptionTranslate = (
  typeReportName: string,
  company: Company
): string[] => {
  if (!TYPE_REPORTS_INFO_TRANSLATE?.[typeReportName]) {
    return [PROCESS_DESCRIPTION_TYPE_REPORT_TRANSLATE];
  }

  if (TYPE_REPORTS_INFO_TRANSLATE?.[typeReportName]?.descriptions) {
    return TYPE_REPORTS_INFO_TRANSLATE[typeReportName].descriptions;
  }

  if (TYPE_REPORTS_INFO_TRANSLATE?.[typeReportName]?.descriptionsByCard) {
    if (company.nameCard === CardNames.arbitradePay) {
      return TYPE_REPORTS_INFO_TRANSLATE[typeReportName].descriptionsByCard
        ?.alliancePay;
    } else {
      return TYPE_REPORTS_INFO_TRANSLATE[typeReportName].descriptionsByCard
        ?.close_cloop_card;
    }
  }

  return [PROCESS_DESCRIPTION_TYPE_REPORT_TRANSLATE];
};
