import { Injectable } from '@angular/core';

// Service
import { PermissionService } from './';
import {
  StorageService,
  VisibilitySelectService,
  FilterDateService,
  FilterTillService,
  FilterCardNumService,
  ClientFilterService,
} from '../share/services';
import {
  TransactionsContentService,
  FilterTransactionsQueryService,
} from '../pages/user/transactions/transactions-query/services/';
import { FilterOnlineShopService } from '../pages/user/online-shop/online-shop/services/';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  constructor(
    private _permissionService: PermissionService,
    private _filterCardNumService: FilterCardNumService,
    private _filterClienService: ClientFilterService,
    private _visibilitySelectService: VisibilitySelectService,
    private _tillSerice: FilterTillService,
    private _filterTransactionService: FilterTransactionsQueryService,
    private _transactionContent: TransactionsContentService,
    private _filterDate: FilterDateService,
    private _filterOnlieShop: FilterOnlineShopService,
    private _storageService: StorageService
  ) {}

  logOut() {
    this._permissionService.logout();
    this._filterCardNumService.resetAllCards();
    this._filterClienService.clearFilter(true);
    this._visibilitySelectService.resetAll();
    this._tillSerice.clearFilter(true);
    this._filterTransactionService.clearFilter();
    this._transactionContent.reset();
    this._filterDate.resetDate();

    this._visibilitySelectService.setStarted(false);

    // online shop
    this._filterOnlieShop.clearBuyListSelected();

    this._storageService.remove('direction_shop');
    this._storageService.remove('contact_shop');
    this._storageService.remove('selectedShop');
    this._storageService.remove('shopArticles');
    this._storageService.remove('notVatProducts');
    this._storageService.remove('observation_shop');
    this._storageService.remove('multiLogin')

  }
}
