import { signal, WritableSignal } from '@angular/core';
import { ComponentProps } from '@ionic/core';

// models
import { Client, Company } from 'src/app/models';
import { FilterClientOptionsRM, TillSelectedRM } from '../../models';
import {
  ClientMultipleMachineOrOneMachineShare,
  SelectClientMultipleMachineModalInputs,
} from 'src/app/share/components/select-multiple-machine/models';

// utils
import { copyNewObjet } from 'src/app/utils';

interface GetPropsShowSelectMachineModalRM {
  company: Company;
  clientsByOperator: WritableSignal<Client[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
  filterClientOptions: WritableSignal<FilterClientOptionsRM>;
}

export const getPropsShowSelectMachineModalRM = ({
  company,
  clientsByOperator,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
  filterClientOptions,
}: GetPropsShowSelectMachineModalRM) => {
  const tills: WritableSignal<TillSelectedRM[]> = signal(
    copyNewObjet(tillsByOperator())
  );
  const tillsSelectedCopy: WritableSignal<TillSelectedRM[]> = signal(
    copyNewObjet(tillsSelected())
  );

  const props: ComponentProps & SelectClientMultipleMachineModalInputs = {
    company,
    clients: clientsByOperator(),
    tills,
    tillsSelected: tillsSelectedCopy,
    machineOneSelected: tillOneMachineSelected(),
    segment: getSegment(filterClientOptions),
  };

  return props;
};

const getSegment = (
  filterClientOptions: WritableSignal<FilterClientOptionsRM>
): ClientMultipleMachineOrOneMachineShare => {
  return filterClientOptions() === FilterClientOptionsRM.filterOneMachine
    ? ClientMultipleMachineOrOneMachineShare.oneMachine
    : ClientMultipleMachineOrOneMachineShare.clientMultipleMachine;
};


