import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

// Service
import { AuthService } from 'src/app/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor( private authService: AuthService ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean  {
    return this.authService.validaToken();
  }

}
