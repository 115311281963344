import { itemPerPage, ITEMSPERPAGE } from './constants';
export const searchDuplicate = (arr: any[]) =>
  arr.filter((item, index) => arr.indexOf(item) !== index);

export const searchDuplicateNotRepeat = (arr: string[]) =>
  Array.from(new Set(arr.filter((item, index) => arr.indexOf(item) !== index)));

export const searchDuplicateObj = (arr: any, attr: string): any[] => {
  return arr
    .map((e) => e[attr])
    .map((e, i, final) => final.indexOf(e) !== i && i)
    .filter((obj) => arr[obj])
    .map((e) => arr[e][attr]);
};

export const sortAlphabetically = <T>(arr: T[], searchAttr: string): T[] => {
  return arr.sort((a, b) => {
    if (a?.[searchAttr] < b?.[searchAttr]) return -1;
    if (a?.[searchAttr] > b?.[searchAttr]) return 1;
    return 0;
  });
};

export const changePositionArray = (
  arr: any[],
  fromIndex: number,
  toIndex: number
) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

export const getLoadPage = <T>(
  arrayComplete: T[],
  page: number
): T[] | null => {
  if (!arrayComplete || arrayComplete.length == 0) {
    return [];
  }

  let arrayPage = [];

  if (arrayComplete && arrayComplete.length >= -1 + page * 10) {
    arrayPage = [...arrayComplete.slice((page - 1) * 10, page * 10)];
  } else if (arrayComplete) {
    arrayPage = [...arrayComplete.slice((page - 1) * 10, arrayComplete.length)];
  }

  return arrayPage;
};

export const getLoadPageWithItemPerPage = <T>(
  arrayComplete: T[],
  page: number,
  itemPerPage: number = ITEMSPERPAGE
): T[] | null => {
  if (!arrayComplete || arrayComplete.length == 0) {
    return [];
  }

  let arrayPage = [];

  if (arrayComplete && arrayComplete.length >= -1 + page * itemPerPage) {
    arrayPage = [
      ...arrayComplete.slice((page - 1) * itemPerPage, page * itemPerPage),
    ];
  } else if (arrayComplete) {
    arrayPage = [
      ...arrayComplete.slice((page - 1) * itemPerPage, arrayComplete.length),
    ];
  }

  return arrayPage;
};

export const removeDuplicateObject = <T>(
  arr: T[],
  id1: string,
  id2?: string
): T[] => {
  if (!id2) id2 = id1;

  return arr.filter(
    (arrPos, index, self) =>
      index === self.findIndex((t) => t?.[id1] === arrPos?.[id2])
  );
};

export const getMaxElements = (elements: number[]) => {
  return Math.max(...elements);
};

export const removeDuplicatesArr = (arr: any[]) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const removeDuplicatesArrString = (arr: string[]) => {
  return [...new Set(arr)];
};
export const getRangeArr = (
  positionOrdered: number[],
  startName: string = 'start',
  endName: string = 'end'
) => {
  let rangeArr: any[] = [];

  const ranges: number[][] = getGroupRanges(positionOrdered);

  for (let rangePos of ranges) {
    let range = {};

    range[startName] = rangePos[0].toString();
    range[endName] = rangePos[rangePos.length - 1].toString();

    rangeArr.push(range);
  }

  return rangeArr;
};

export const getGroupRanges = (positionOrdered: number[]): number[][] => {
  if ((positionOrdered || []).length === 0) {
    return [];
  }

  let ranges: number[][] = [];
  let prevPos = positionOrdered[0];
  let range: number[] = [positionOrdered[0]];

  if (positionOrdered.length === 1) {
    ranges.push(range);
  }

  for (let i = 1; i < positionOrdered.length; i++) {
    const currentPos = positionOrdered[i];
    if (currentPos === prevPos + 1) {
      range.push(currentPos);
      prevPos = currentPos;
      if (i === positionOrdered.length - 1) {
        ranges.push(range);
      }
    } else {
      ranges.push(range);
      range = [currentPos];
      prevPos = currentPos;
      if (i === positionOrdered.length - 1) {
        ranges.push(range);
      }
    }
  }

  return ranges;
};

export const getRangeSize = (size: number, startAt: number = 0): number[] => {
  return [...Array(size).keys()].map((i) => i + startAt);
};

export const convertToArray = (elem) => {
  return [].concat(elem);
}
export const searchDuplicateCompleteObj = <T>(arr: any, attr: string): T[] => {
  return arr
    .map((e) => e[attr])
    .map((e, i, final) => final.indexOf(e) !== i && i)
    .filter((obj) => arr[obj])
    .map((e) => arr[e]);
};

export const joinArrWithStrongTag = (elements: string[]) => {
  let elementWithStrongTag = '';
  elements.forEach((elem, i) => {
    const elemPos = `<strong>${elem}</strong>${
      i !== elements.length - 1 ? ', ' : ''
    }`;
    elementWithStrongTag += elemPos;
  });
  return elementWithStrongTag;
};

export const getPositionsArrayByElements = (nElements: number): number[] => {
  return new Array(nElements).fill(0).map((_, i) => i + 1);
};

export const convertArrToString = (elem: any[]): string[] => {
  return elem.map((el) => (el || '').toString());
};
