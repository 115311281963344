import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisibilityClientSelectService {

  reload$: Subject<boolean> = new Subject<boolean>();

  // 0 create 1 redeption
  typeDate: number = 0;

  typeDateParam = {0: 'create', 1: 'redemption'}

  constructor() { }

  reload(){
    this.reload$.next(true)
  }

  changeTypeDate(typeDate){
    this.typeDate = typeDate;
  }

  getTypeDate(){
    return this.typeDateParam[this.typeDate];
  }
}
