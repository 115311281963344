// utils
import { getMinDateAndMaxDateMachineAlert } from "../get-min-date";

export const getDateValidMachineAlert = (date: Date) => {

  if(!date){
    return null;
  }
  const dateTime = date.getTime();
  const { minDate } = getMinDateAndMaxDateMachineAlert();
  const minDateTime = minDate.getTime();

  if(dateTime < minDateTime){
    return minDate;
  }

  return date;
}
