import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Service
import { AuthService } from '../../services/auth.service';

// Utils
import { errorToken, errorToken2 } from '../../utils/constants';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private _injector: Injector,
                private _authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError(err => {

        if (err?.error?.error?.message == errorToken || err?.error?.error?.message == errorToken2 ) {
          this._authService.tokenExpired();
          return null;
        }
        return throwError(() => err);

      }));
  }

}
