import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import * as FileSaver from 'file-saver';

// utils
import { isApkAndroid, UtilsTranslate } from './';
import { writeFileApk, writeFileApkbyBlob } from './useApkAndroid';


@Injectable({
  providedIn: 'root',
})
export class FileSystemMultiplataform {
  constructor(
    private platform: Platform,
    private utils: UtilsTranslate
    ) {}

  downloadFile(path: string, data: string){
    if (isApkAndroid(this.platform)) {
      writeFileApk(path, data);
    } else {
      FileSaver.saveAs(data, path);
    }
  }

  async downloadFileByBlob(path: string, data: Blob){
    if (isApkAndroid(this.platform)) {
      // const base64 = await this.convertBlodToBase64(data) as string;
      writeFileApkbyBlob(path, data, this.utils);
    } else {
      FileSaver.saveAs(data, path);
    }
  }

  convertBlodToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });


}

