// models
import { Client } from 'src/app/models';
import { UserDashboard } from '../../models';

interface GetClientsByStatus {
  clientsActive: Client[];
  clientsAdd: Client[];
}

export const getClientsByStatus = (
  clients: Client[],
  userDashboard: UserDashboard[]
): GetClientsByStatus => {
  let clientsActive: Client[] = [];
  let clientsAdd: Client[] = [];

  clients.forEach((client) => {
    const isActive = userDashboard.some(
      (userActive) => client.ID === userActive.clientID
    );
    if (isActive) {
      clientsActive.push(client);
    } else {
      clientsAdd.push(client);
    }
  });

  return { clientsActive, clientsAdd };
};
