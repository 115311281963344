import { Component, ElementRef, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { CardNames, Company } from 'src/app/models';

// services
import { SkinMangementService } from '../../../../services/skin-mangement.service';
import { ColorModeService } from '../../../../services/color-mode.service';

// utils
import { addElemStyle, ALLIANCEPAY_CARD_NAME_PLURAL } from 'src/app/utils';

@Component({
  selector: 'app-subsidies-legend',
  templateUrl: './subsidies-legend.component.html',
  styleUrls: ['./subsidies-legend.component.scss'],
})
export class SubsidiesLegendComponent implements OnInit {
  company: Company;

  isLightColor: boolean;

  cardNames = CardNames;

  ARBITRADEPAYS = ALLIANCEPAY_CARD_NAME_PLURAL

  constructor(
    private elem: ElementRef,
    private modalCtrl: ModalController,
    private _colorModeService: ColorModeService,
    private _skinService: SkinMangementService,
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.isLightColor = this._colorModeService.getIsLightColor();

    this.addMarkerColorStyle()
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

    addMarkerColorStyle(){
      addElemStyle(
        this.elem,
        '--color-marker-company',
        this.company.colorCompanyText
      );
    }
}
