import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisibilityClientTillSelectService {

  reload$: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  reload(){
    this.reload$.next(true)
  }
  
}
