import { Pipe, PipeTransform } from '@angular/core';

// utils
import { isNaturalNumber } from 'src/app/utils';

@Pipe({
  name: 'numberLanguage',
})
export class NumberLanguagePipe implements PipeTransform {
  transform(
    value: number,
    locale: string,
    decimals: number = 0,
    coin: string = ''
  ): string {
    if (!coin) coin = '';
    if (!value) value = 0;

    if (
      decimals === 0 &&
      locale === 'es-ES' &&
      !coin &&
      value > 999 &&
      value < 10000 &&
      (value || '').toString().length === 4 &&
      isNaturalNumber(value)
    ) {
      const valueText = (value || '').toString();
      const [firstPosition, ...nextPoint] = valueText;
      return firstPosition + '.' + nextPoint.join('');
    }

    if (decimals === null) {
      return (
        new Intl.NumberFormat(locale, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(Number(value)) + coin
      );
    }

    if (coin !== '$') {
      return (
        new Intl.NumberFormat(locale, {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(Number(value)) + coin
      );
    } else {
      return (
        coin +
        new Intl.NumberFormat(locale, {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(Number(value))
      );
    }
  }
}
