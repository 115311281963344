import { WritableSignal } from '@angular/core';

// models
import { ArticleClientDashboard, FilterDashboards } from '../../models';

export const validateArticlesFavorites = (
  articles: ArticleClientDashboard[],
  filtersDashboard: WritableSignal<FilterDashboards>
) => {
  const favorites = filtersDashboard().articles.favorites;
  const newFavorites: number[] = [];

  if ((favorites || []).length === 0) {
    return true;
  }

  for (let favorite of favorites) {
    const exist = articles.find((article) => article.ArticleID === favorite);

    if (exist) {
      newFavorites.push(favorite);
    }
  }

  if (newFavorites.length !== favorites.length) {
    filtersDashboard.mutate(
      (filterDashboard) =>
        (filterDashboard.articles.favorites = newFavorites)
    );
    return false;
  }

  return true;
};
