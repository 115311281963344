import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';


// Service
import { TransactionsContentService } from '../../../../pages/user/transactions/transactions-query/services/';

@Injectable({
  providedIn: 'root'
})
export class FabBrService {

  validChange: Subject<{valid: boolean, route: string}> = new Subject<{valid: boolean, route: string}>();

  showCardActivity = false;
  showDownloadExcel = false;

  fabEmitter: Subject<TypesFabEmitter> = new Subject<TypesFabEmitter>();
  showLegend = new Subject();

  closeFab: Subject<boolean> = new Subject<boolean>();
  validChange$: Subscription;

  constructor(
    private _transactionsContentService: TransactionsContentService,
    protected router: Router,
    ) {
      this.validChange$ = this._transactionsContentService.validChange.subscribe(res => {
      // this.validChange.next({route: '/user/transactions-query', valid: res})
      this.validChange.next({route: this.router.url, valid: res})
    })
  }

  ngOnDestroy(): void {
    if(this.validChange$) this.validChange$.unsubscribe()
  }

  getShowDonwload(){
    return this._transactionsContentService.getValid()
  }

  getShowCardActivity(){
    return this.showCardActivity;
  }

  getShowDownloadExcel(){
    return this.showDownloadExcel;
  }

  setShowDownloadExcel(valid: boolean, route: string){
    this.showDownloadExcel = valid;
    this.validChange.next({route, valid})
  }

  setShowCardActivity(newCardActivity){
    this.showCardActivity = newCardActivity
    this.validChange.next({route: '/user/card-activity', valid: newCardActivity})
  }

  fabEmit(typeEmitter: TypesFabEmitter){
    this.fabEmitter.next(typeEmitter)
  }

  closeFabEmit(){
    this.closeFab.next(true)
  }

  openLegendModal(){
    this.showLegend.next(null)
  }

}

export type TypesFabEmitter = 'TagStatus' | 'Print' | 'Reload' | null
