import { WritableSignal } from '@angular/core';

// models
import { Report } from '../../models';

interface GetActivationTotalReport {
  reports: Report[];
  total: WritableSignal<number>;
  isActive: boolean;
}

export const getActivationTotalReport = ({
  reports,
  total,
  isActive,
}: GetActivationTotalReport) => {

  let countActivation = 0;

  if (isActive) {
    countActivation = reports.filter((res) => res.enabled).length;
  } else {
    countActivation = reports.filter((res) => !res.enabled).length;
  }

  total.set(countActivation);

};
