// services
import { TranslateService } from '@ngx-translate/core';

// models
import { AlertSpiderMachineStatus, AlertTextMachineStatus } from '../../models';

// constant
import { CONNECTIVITY_TEXT_DB, PERFORMANCE_TEXT_DB } from '../constant';
import { PERFOMANCE_TYPE_ALERT } from '../../../machine-status-alert/utils';

export const getAlertTextMachineStatus = async (
  alerts: AlertSpiderMachineStatus[],
  translate: TranslateService
): Promise<AlertTextMachineStatus[]> => {
  let alertTexts: AlertTextMachineStatus[] = [];

  const connectivityTranslate = await translate.instant(
    'machines_status.add_machines_status_alerts.connectivity'
  );
  const performanceTranslate = await translate.instant(
    'machines_status.add_machines_status_alerts.performance'
  );

  const statusTranslate = await translate.instant(
    'machines_status.add_machines_status_alerts.type'
  );

  for (let alert of alerts) {

    const alertText = getAlertText(alert, {connectivityTranslate, performanceTranslate, statusTranslate})

    alertTexts.push({
      description: alertText,
      id: alert.alertID,
    });
  }

  return alertTexts;
};

const getAlertText = (
  alert: AlertSpiderMachineStatus,
  {
    connectivityTranslate,
    performanceTranslate,
    statusTranslate,
  }: TextAlertTranslates
) => {
  // const emailText = alert.Emails.length > 1 ? 'Email:' : 'Emails:';
  // const email = `${emailText} ${alert.Emails.join(', ')}`;
  const email = `${alert.Emails.join(', ')}`;

  let textStatus = getTextStatus(alert.StatusTillAlert, {
    connectivityTranslate,
    performanceTranslate,
  });

  const startedHour = getHour(alert.StartTime);
  const endHour = getHour(alert.EndTime);

  // return `${email} // ${statusTranslate}: ${textStatus} // ${startedHour} - ${endHour}`;
  return `${email} // ${textStatus} // ${startedHour} - ${endHour}`;
};

const getTextStatus = (
  textStatus: string,
  { connectivityTranslate, performanceTranslate }: TextStatusTranslates
) => {
  let textStatusTranslate = textStatus;
  if (textStatus === CONNECTIVITY_TEXT_DB) {
    textStatusTranslate = connectivityTranslate;
  } else if (textStatus === PERFORMANCE_TEXT_DB) {
    textStatusTranslate = performanceTranslate;
  }

  // TODO
  return textStatusTranslate;
};

const getHour = (date: Date) => {
  return date.toString().replace('.000', '');
};

interface TextStatusTranslates {
  connectivityTranslate: string;
  performanceTranslate: string;
}

interface TextAlertTranslates {
  connectivityTranslate: string;
  performanceTranslate: string;
  statusTranslate: string;
}
