import { WritableSignal } from '@angular/core';

// models
import {
  FilterTemplateObjectTripleInputOneOption,
  TypeFilterTripleInput,
} from '../models';

interface GetElementFilterTemplateTripleInputs {
  elementSelected1: WritableSignal<number | string>;
  elementSelected2: WritableSignal<number | string>;
  elementSelected3: WritableSignal<number | string>;

  elementsInput1: FilterTemplateObjectTripleInputOneOption;
  elementsInput2: FilterTemplateObjectTripleInputOneOption;
  elementsInput3: FilterTemplateObjectTripleInputOneOption;

  typeFilter: TypeFilterTripleInput;
}

export const getElementFilterTemplateTripleInputs = ({
  elementSelected1,
  elementSelected2,
  elementSelected3,
  elementsInput1,
  elementsInput2,
  elementsInput3,
  typeFilter,
}: GetElementFilterTemplateTripleInputs) => {
  if (typeFilter === 'input1') {
    return {
      elementFilter: getElemFilter({
        elementsInput: elementsInput1,
        elementSelected: elementSelected1,
      }),
      pos: 1,
    };
  }

  if (typeFilter === 'input2') {
    return {
      elementFilter: getElemFilter({
        elementsInput: elementsInput2,
        elementSelected: elementSelected2,
      }),
      pos: 2,
    };
  }

  return {
    elementFilter: getElemFilter({
      elementsInput: elementsInput3,
      elementSelected: elementSelected3,
    }),
    pos: 3,
  };
};

interface GetElemFilter {
  elementsInput: FilterTemplateObjectTripleInputOneOption;
  elementSelected: WritableSignal<number | string>;
}

const getElemFilter = ({ elementsInput, elementSelected }: GetElemFilter) => {
  const { elements, nameId } = elementsInput;

  let elementFilter = elements.find(
    (element) =>
      (element?.[nameId] || '').toString().toLowerCase() ==
      (elementSelected() || '').toString().toLowerCase()
  );

  return elementFilter;
};
