export interface AenaTranslates {
  assignArticleFamiliesAena: string,
  downloadFileAena: string,
  tillMapping: string,
  sendFilesAena: string,
  checkArticleAena: string,
  transactionQuery: string,
  articlesManagerAena: string,
  checkSubgroupArticleAena: string
}

export const getAenaRoutes = (
  translates: AenaTranslates
): { route: string; name: string }[] => {
  return [
    {
      route: '/user/assign-articles-families-aena',
      name: translates.assignArticleFamiliesAena,
    },
    {
      route: '/user/aena/download-files',
      name: translates.downloadFileAena,
    },
    { route: '/user/till-mapping-aena', name: translates.tillMapping },
    { route: '/user/regen-files-aena', name: translates.sendFilesAena },
    {
      route: '/user/aena/check-article',
      name: translates.checkArticleAena,
    },
    {
      route: '/user/transactions-query-aena',
      name: translates.transactionQuery,
    },
    {
      route: '/user/assign-subgroups-articles-aena',
      name: translates.articlesManagerAena,
    },
    {
      route: '/user/check-subgroups-articles-aena',
      name: translates.checkSubgroupArticleAena,
    },
  ]
}
