import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

// config
import { AppConfig } from '../../../config/config';

// models
import {
  TypeSale,
  Transaction,
  CountTransaction,
  TransactionsParams,
} from 'src/app/pages/user/transactions/transactions-query/models';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  nameRouteTotal = '';

  constructor(private httpClient: HttpClient, protected router: Router) {}

  getTransactions(inputsParam: TransactionsParams) {
    let routeUrl = this.router.url;

    let endpoint =
      routeUrl === '/user/transactions-query-aena'
        ? 'AENA/transactions'
        : `transactions`;

    return this.httpClient
      .post<Transaction[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        inputsParam
      )
      .pipe(
        map((res) =>
          res.map((item) => {
            let newDate = null;

            if (item?.Transaction_date) {
              newDate = new Date(item.Transaction_date);
              let userTimezoneOffset = Math.trunc(
                newDate.getTimezoneOffset() / 60
              );
              item.transactionDate = new Date(
                newDate.setHours(newDate.getHours() + userTimezoneOffset)
              );
            }

            if (item?.charged_date) {
              newDate = new Date(item.charged_date);
              let userTimezoneOffset = Math.trunc(
                newDate.getTimezoneOffset() / 60
              );
              item.charged_date = new Date(
                newDate.setHours(newDate.getHours() + userTimezoneOffset)
              );
            }

            if (item?.payment_date) {
              newDate = new Date(item.payment_date);
              let userTimezoneOffset = Math.trunc(
                newDate.getTimezoneOffset() / 60
              );
              item.payment_date = new Date(
                newDate.setHours(newDate.getHours() + userTimezoneOffset)
              );
            }

            if (
              item?.Transaction_id &&
              !item?.transaction_id &&
              item?.transaction_id !== 0
            ) {
              item.transaction_id = item?.Transaction_id;
            }

            if (item?.Operator === 'Sin Definir') {
              item.Operator = '';
            }

            return { ...item };
          })
        ),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getCountTransactions(inputsParam: TransactionsParams, nameRouteTotal: string = '') {
    let routeUrl = this.router.url;
    let endpoint =
      routeUrl === '/user/transactions-query-aena'
        ? 'AENA/transactions/count'
        : `transactions/count`;

    let routeComplete = this.serviceUrl + endpoint + `?route=${routeUrl}`;

    if (nameRouteTotal) {
      routeComplete += `&name=${nameRouteTotal}`;
    }

    return this.httpClient
      .post<CountTransaction[]>(routeComplete, inputsParam)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getSaleTypes() {
    let endpoint = `getSaletypes`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<TypeSale[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}
