import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { IonSegment, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Swiper
import { SwiperComponent } from 'swiper/angular';
import Swiper, { Pagination, SwiperOptions } from 'swiper';

// services
import { FilterCheckQrService } from '../../../../pages/user/QR/check-qr/services/filter-check-qr.service';

// models
import { Company, HaveVisibility } from 'src/app/models';

// utils
import {
  pcWidth,
  TIME_OUT_EXTRA,
  TIME_OUT_EXTRA_APK,
  isApkAndroid,
} from '../../../../utils';

@Component({
  selector: 'app-visibility-client-select-sm',
  templateUrl: './visibility-client-select-sm.component.html',
  styleUrls: ['./visibility-client-select-sm.component.scss'],
})
export class VisibilityClientSelectSmComponent implements OnInit {
  @Input() company: Company;
  @Input() haveSend: boolean = true;
  @Input() haveClear: boolean = true;
  @Input() haveDateFilter: boolean = false;
  @Input() showClientFilter: boolean = false;
  @Input() loadingSended: boolean = false;
  @Input() canSend: boolean = false;
  @Input() haveVisibility: HaveVisibility = {
    client: false,
    operator: false,
    operatorGroup: false,
  };
  @Input() haveMore1Client: boolean = false;
  @Input() pcWidth = pcWidth;

  @Input() dobleDateFilter: boolean = false;
  @Input() showHour: boolean = false;

  @Output() sendFilter = new EventEmitter();
  @Output() clearFilter = new EventEmitter();

  // checkqr
  @Input() isCheckQR: boolean = false;
  @Input() statusSelectedTranslate: string = '';
  @Output() filterStatus = new EventEmitter();
  qrInput: string = '';

  // filter client
  // @Input() showCodeClient = true;

  config: SwiperOptions = {
    pagination: { clickable: true, type: 'bullets', dynamicBullets: true },
    keyboard: true,
  };

  segment = '0';
  segmentOperator = '2';

  positions: { [x: string]: number } = {
    '0': 0,
    '1': null,
    '2': null,
    '3': null,
    '4': 1,
    '5': 2,
  };

  changeQRFilter$: Subscription;

  @ViewChild(IonSegment) segment2: IonSegment;
  @ViewChild('swiperVisibilityClientSelect', { static: false })
  swiper: SwiperComponent;

  constructor(
    private platform: Platform,
    private _filterCheckQRService: FilterCheckQrService,
  ) {}

  ngOnInit() {
    Swiper.use([Pagination]);

    this.qrInput = this._filterCheckQRService.getQR();
    this.changeQRFilter$ = this._filterCheckQRService.changeQRFilter$.subscribe((res) => {
      if (res != this.qrInput) {
        this.qrInput = res;
      }
    });

    this.getPositions();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.showClientFilter || changes.haveVisibility) {
      this.getPositions(false);
    }
  }

  ngOnDestroy(): void {
    if(this.changeQRFilter$ ) this.changeQRFilter$.unsubscribe()
  }

  getPositions(moveSlideAndSegment = true) {
    setTimeout(
      () => {
        this.segmentOperator = '2';
        this.positions =
          this.isCheckQR && this.haveDateFilter
            ? { '0': 0, '1': null, '2': null, '3': null, '4': 1, '5': 2 }
            : this.haveDateFilter
            ? { '0': 0, '1': null, '2': null, '3': null }
            : { '0': null, '1': null, '2': null };

        if (this.showClientFilter) {
          this.positions =
            this.isCheckQR && this.haveDateFilter
              ? {
                  '0': 0,
                  '1': null,
                  '2': null,
                  '3': 1,
                  '4': 2,
                  '5': 3,
                }
              : this.haveDateFilter
              ? {
                  '0': 0,
                  '1': null,
                  '2': null,
                  '3': 1,
                }
              : {
                  '1': null,
                  '2': null,
                  '3': 0,
                };
        }

        if (
          !this.haveVisibility.operator &&
          this.haveVisibility.operatorGroup
        ) {
          this.positions =
            this.isCheckQR && this.haveDateFilter
              ? {
                  '0': 0,
                  '1': null,
                  '2': 1,
                  '3': this.showClientFilter ? 2 : null,
                  '4': this.showClientFilter ? 3 : 2,
                  '5': this.showClientFilter ? 4 : 3,
                }
              : this.haveDateFilter
              ? {
                  '0': 0,
                  '1': null,
                  '2': 1,
                  '3': this.showClientFilter ? 2 : null,
                }
              : {
                  '1': null,
                  '2': 0,
                  '3': this.showClientFilter ? 1 : null,
                };
        }

        if (
          !this.haveVisibility.operator &&
          !this.haveVisibility.operatorGroup
        ) {
          this.positions =
            this.isCheckQR && this.haveDateFilter
              ? {
                  '0': 0,
                  '1': 1,
                  '2': 2,
                  '3': this.showClientFilter ? 3 : null,
                  '4': this.showClientFilter ? 4 : 3,
                  '5': this.showClientFilter ? 5 : 4,
                }
              : this.haveDateFilter
              ? {
                  '0': 0,
                  '1': 1,
                  '2': 2,
                  '3': this.showClientFilter ? 3 : null,
                }
              : {
                  '1': 0,
                  '2': 1,
                  '3': this.showClientFilter ? 2 : null,
                };
        }

        if (moveSlideAndSegment) {
          let key = Object.keys(this.positions).find(
            (key) => this.positions[key] === 0
          );

          this.segment = key;
          this.swiper?.swiperRef?.slideTo(0);
        }
      },
      isApkAndroid(this.platform) ? TIME_OUT_EXTRA_APK : TIME_OUT_EXTRA
    );
  }

  qrInputChange() {
    this._filterCheckQRService.setQR(this.qrInput);
  }

  segmentChanged() {
    this.swiper?.swiperRef?.slideTo(this.positions[Number(this.segment)]);
  }

  async slideChange() {
    let active = this.swiper?.swiperRef?.activeIndex;
    let key = Object.keys(this.positions).find(
      (key) => this.positions[key] === active
    );
    this.segment2.value = key.toString();

    document.getElementById('segment-' + this.segment).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }

  send() {
    this.sendFilter.emit();
  }

  clear() {
    this.clearFilter.emit();
  }

  async showFilterStatus() {
    this.filterStatus.emit();
  }
}
