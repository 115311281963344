import { NavController } from '@ionic/angular';
import { UtilsTranslate } from './utils-translate';

// class
export * from './file-system-multiplataform.class';
export * from './modal-generator.class';

// functions
export * from './use-record';
export * from './use-show-footer';
export * from './useApkAndroid';
export * from './useArray';
export * from './useChangeAttributeDom';
export * from './useCrypto';
export * from './useDate';
export * from './useDomain';
export * from './useStyle';
export * from './useToast';
export * from './useValidation';
export * from './validate-route';
export * from './validators';
export * from './use-client';
export * from './use-menu-controller';
export * from './use-name-cards';
export * from './use-file';

// Constants
import { ROUTEHOME } from './constants';
export * from './translates';

// Pipe
import { DecimalPipe } from '@angular/common';

// Models
import { PhoneResult, phone } from 'phone';
import { Company, Phone } from '../models';

export const randomNumber = (min, max) => {
  return Math.trunc(Math.random() * (max - min) + min);
};

export const sleep = async (msec: number) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};

export const removeSpaces = (text: string) => {
  if (!text) text = '';
  return text.replace(/\s/g, '');
};

export const isNaturalNumber = (x: number) => {
  return x % 1 === 0 && x >= 0;
};

export const isNaturalNumberValid = (x: number) => {
  let pattern = /^(0|([1-9]\d*))$/;
  return pattern.test(x.toString());
};

export const isNaturalAndGreaterThanZero = (x: number) => {
  if (!isNaturalNumber(x)) {
    return false;
  }

  return x > 0;
};

export const substringHTMLWith3Points = (text: string, maxLength: number) => {
  return (text || '').length > maxLength
    ? text.substring(0, maxLength) + '...'
    : text;
};

export const substringHTMLWith3PointsSameLength = (
  text: string,
  maxLength: number
) => {
  if (maxLength < 3) {
    maxLength = 3;
  }

  return (text || '').length > maxLength
    ? text.substring(0, maxLength - 3) + '...'
    : text;
};

export const invalidRoute = (
  errorText: string,
  navCtrl: NavController,
  utils: UtilsTranslate,
  newRoute: string = ROUTEHOME,
  isError: boolean = true
) => {
  utils.presentToastTranslate(errorText, isError, 0);
  navCtrl.navigateRoot(newRoute, { animated: false });
};

export const updateHaveTwoDecimals = (
  value: number,
  decimalPipe: DecimalPipe
) => {
  if (!value && value !== 0) value = 0;
  return decimalPipe.transform(value, '1.2-2');
};

export const twoDecimalsTransform = (value: number) => {
  return Number(value.toFixed(2));
};

export const replaceAllSpaces = (input: string) => {
  if (!input) input = '';
  return input.replace(/ /g, '');
};

export const replaceAll = (str: string, find: string, replace: string = '') => {
  return str.split(find).join(replace);
};

export const scrollIntoViewDocument = (document: Document, id: string) => {
  document
    .getElementById(id)
    .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
};

export const replaceVariableText = (
  textTranslate: string,
  value: string,
  nameVariable: string
): string => {
  return (textTranslate || '').replace(`{{${nameVariable}}}`, value);
};

export const getWordsByText = (text: string) => {
  const wordsByText = text.replace(/^\s+|\s+$/g, '').split(/\s+/);

  if ((wordsByText || []).length === 1 && wordsByText?.[0] === '') {
    return [];
  }

  return wordsByText;
};

export const copyNewObjet = <T>(copy: T): T => JSON.parse(JSON.stringify(copy));

export const convertNumber = (value: any) => {
  return Number(value);
};

export const checkEventSelectAll = (
  totalItems: number,
  checked: number
): { isIndeterminate: boolean; masterCheck: boolean } => {
  if (checked > 0 && checked < totalItems) {
    return { isIndeterminate: true, masterCheck: false };
  } else if (checked == totalItems) {
    return { isIndeterminate: false, masterCheck: true };
  } else {
    return { isIndeterminate: false, masterCheck: false };
  }
}

export const getOnlyNumbersByString = (text: string) => {
  return Number(text.replace(/\D/g, ''));
};


