import { Component, OnInit, Input } from '@angular/core';
import { ComponentProps } from '@ionic/core';
import { Subscription } from 'rxjs/internal/Subscription';

// components
import { SearchClientByOperatorComponent } from '../../../share/modals';

// services
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/share/services';
import {
  FilterVisibilityRegisterCardService,
  RegisterCardsService,
} from '../../../../services';

// models
import { Card, CardNames, Client, Company } from 'src/app/models';
import { ParamsUnlinkClientCardByList } from '../../../../models';

// utils
import {
  changeColorPaginator,
  errorsTypes,
  getLoadPageWithItemPerPage,
  ModalGenerator,
  UtilsTranslate,
} from 'src/app/utils';


@Component({
  selector: 'app-unlinked-card-by-client',
  templateUrl: './unlinked-card-by-client.component.html',
  styleUrls: ['./unlinked-card-by-client.component.scss'],
})
export class UnlinkedCardByClientComponent implements OnInit {
  @Input() company: Company;
  @Input() itemPerPage = 16;

  loadingVisibilities: boolean = true;
  client: Client = null;
  cards: Card[] = [];
  loadingCardsByClient: boolean;

  changeVisibility$: Subscription;

  // paginator
  cardsPage: Card[] = [];
  collection: { count: number; data: Card[] } = {
    count: 0,
    data: [],
  };
  config: {
    id: string;
    itemsPerPage: number;
    currentPage: number;
    totalItems: number;
  };
  page: number = 1;
  idPaginate: string = 'UnlinkedCardsPaginate';
  previusHTML: any[] = [];

  cardNames = CardNames;

  constructor(
    private translate: TranslateService,
    private _loadingService: LoadingService,
    private _registerCard: RegisterCardsService,
    private _filterVisibilityRegisterCardService: FilterVisibilityRegisterCardService,
    private utils: UtilsTranslate,
    private modalGenerator: ModalGenerator
  ) {}

  ngOnInit() {
    this.loadingVisibilities = this._filterVisibilityRegisterCardService.getLoadingVisibilities();
    this.changeVisibility$ = this._filterVisibilityRegisterCardService.changeVisibility$.subscribe(
      (visibilities) => {
        this.loadingVisibilities = visibilities?.loadingVisibilities;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.changeVisibility$) this.changeVisibility$.unsubscribe();

    this.removeInfo();

    this._filterVisibilityRegisterCardService.resetSelected();
  }

  removeInfo() {
    this.client = null;
    this.cards = [];
    this.cardsPage = [];

    this.collection = {
      count: 0,
      data: [],
    };

    this.config = {
      id: null,
      itemsPerPage: this.itemPerPage,
      currentPage: 1,
      totalItems: 0,
    };
    this.page = 1;
    this.idPaginate = 'UnlinkedCardsPaginate';
    this.previusHTML = [];
  }

  async showSelectClient() {
    let operatorGroups = this._filterVisibilityRegisterCardService
      .getOperatorGroups()
      .map((operatorGroup) => ({ ...operatorGroup }));

    if (operatorGroups.length > 1) {
      operatorGroups.unshift({
        ID: -1,
        description: this.translate.instant('all'),
      });
    }

    let operatorsAll = this._filterVisibilityRegisterCardService
      .getOperatorsAll()
      .map((operator) => ({ ...operator }));
    if (operatorGroups.length === 1 && operatorsAll.length > 1) {
      operatorsAll.unshift({
        id: -1,
        description: this.translate.instant('all'),
      });
    }

    const props: ComponentProps = {
      company: this.company,
      showOperatorGroup: this._filterVisibilityRegisterCardService.getShowOperatorGroup(),
      showOperator: this._filterVisibilityRegisterCardService.getShowOperator(),
      operatorGroups,
      operatorsAll,
      clientsAll: this._filterVisibilityRegisterCardService.getClientsAll(),
    };

    const data = await this.modalGenerator.show(
      SearchClientByOperatorComponent,
      props
    );

    if (data?.client) {
      this.client = data.client;
      this.getCardByClient();
    }
  }

  getCardByClient() {
    this.loadingCardsByClient = true;
    this.cards = [];
    this.page = 1;
    this._registerCard.getCardsByClientId(this.client?.ID).subscribe({
      next: (res) => {
        this.cards = res;
        this.loadingCardsByClient = false;
        this.loadPage();
      },
      error: () => {
        this.utils.showError();
        this.cards = [];
        this.loadingCardsByClient = false;
      },
    });
  }

  changePage(page: number) {
    this.page = page;
    this.loadPage();
  }

  updatePage() {
    this.collection = {
      count: this.cards?.length,
      data: this.cardsPage,
    };
    this.config = {
      id: this.idPaginate,
      itemsPerPage: this.itemPerPage,
      currentPage: this.page,
      totalItems: this.cards.length,
    };

    if (this.config.totalItems > 0) {
      setTimeout(() => {
        this.previusHTML = changeColorPaginator(
          this.company.colorRGB,
          'white',
          this.previusHTML
        );
      }, 50);
    }
  }

  loadPage() {
    this.updatePage();

    this.cardsPage = [];

    if (this.cards.length > 0) {
      this.cardsPage = getLoadPageWithItemPerPage<Card>(
        this.cards,
        this.page,
        this.itemPerPage
      );
    }
  }

  removeCard(pos: number) {
    this._loadingService.present();

    const cardNum = this.cards[(this.page - 1) * this.itemPerPage + pos]
      ?.cardNum;
    const params: ParamsUnlinkClientCardByList = {
      VT: [cardNum],
    };

    this._registerCard.unlinkClientCardByList(params).subscribe({
      next: () => {
        this.cards.splice((this.page - 1) * this.itemPerPage + pos, 1);

        if (
          (this.page - 1) * this.itemPerPage >= this.cards.length &&
          this.page > 1
        ) {
          this.page--;
        }

        this.utils.presentToastWithoutTranslate(
          this.translate.instant(
            this.company.nameCard === this.cardNames.arbitradePay
              ? 'register_cards.unlinked_card.unlinked_card2'
              : 'register_cards.unlinked_card.unlinked_card',
            { cardNum, client: this.client?.description }
          ),
          false
        );
        this.loadPage();
        this._loadingService.close();
      },
      error: (error) => {
        if (error === errorsTypes.invalid_card_register_card) {
          this.utils.presentToastTranslate(
            'register_cards.unlinked_card.invalid_card',
            true,
            0
          );
        } else {
          this.utils.presentToastTranslate(
            'register_cards.unlinked_card.error_unlinked',
            true,
            0
          );
        }

        this._loadingService.close();
      },
    });
  }
}
