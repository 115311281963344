import { WritableSignal } from '@angular/core';

// models
import { SelectClientMultipleMachineModalOutputsInfo } from 'src/app/share/components/select-multiple-machine/models';
import {
  FilterClientOptionsRM,
  PositionVisibilityRouteManager,
  TillSelectedRM,
} from '../../models';

// utils
import { clearTillsRM } from '../select-route';
import {
  selectMultipleMachineByInfoModalRM,
  selectOneMachineByInfoModalRM,
} from './';

interface SelectMachinesByInfoModalRM {
  info: SelectClientMultipleMachineModalOutputsInfo;
  tills: WritableSignal<TillSelectedRM[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
  filterClientOptions: WritableSignal<FilterClientOptionsRM>;
  positionVisibility: WritableSignal<PositionVisibilityRouteManager>;
}

export const selectMachinesByInfoModalRM = ({
  info,
  tills,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
  filterClientOptions,
  positionVisibility,
}: SelectMachinesByInfoModalRM) => {
  const {
    isMultipleMachine,
    tillsSelected: tillsSelectedInfo,
    tillOneSelected: tillOneMachineSelectedInfo,
  } = info;

  clearTillsRM(tills);
  clearTillsRM(tillsByOperator);

  if (isMultipleMachine) {
    selectMultipleMachineByInfoModalRM({
      tillsSelectedInfo,
      tills,
      tillsByOperator,
      tillsSelected,
      tillOneMachineSelected,
      filterClientOptions,
      positionVisibility,
    });
  } else {
    selectOneMachineByInfoModalRM({
      tillOneMachineSelected,
      tillOneMachineSelectedInfo,
      tillsSelected,
      filterClientOptions,
      positionVisibility,
    });
  }
};
