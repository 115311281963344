// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-10-5 {
  margin-bottom: 5px;
  margin-left: 10px;
}

.ml-2 {
  margin-left: 2px;
}

.add-size {
  font-size: 1.2em;
}

.delete-size {
  font-size: 2.5em;
}

.save-size {
  font-size: 1.5em;
  margin-right: 10px;
  margin-top: 4px;
}

.light-color {
  color: #FCFCFC;
}

.dark-color {
  color: #222428 !important;
}

.height-auto {
  height: auto;
  width: auto;
}

.icon-sm {
  font-size: 8px;
}

.size-lg {
  font-size: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/aena/share/components/ui/buttons-send-aena-share/buttons-send-aena-share.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;AACF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,YAAA;EACA,WAAA;AAAF;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,gBAAA;AADF","sourcesContent":[".d-flex{\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.m-10-5{\r\n  margin-bottom: 5px;\r\n  margin-left: 10px;\r\n}\r\n\r\n\r\n.ml-2{\r\n  margin-left: 2px;\r\n}\r\n\r\n.add-size{\r\n  font-size: 1.2em;\r\n}\r\n\r\n.delete-size{\r\n  font-size: 2.5em;\r\n}\r\n\r\n.save-size{\r\n  font-size: 1.5em;\r\n  margin-right: 10px;\r\n  margin-top: 4px;\r\n}\r\n\r\n.light-color{\r\n  color: #FCFCFC\r\n}\r\n\r\n.dark-color{\r\n  color: #222428 !important;\r\n}\r\n\r\n.height-auto{\r\n  height: auto;\r\n  width: auto;\r\n}\r\n\r\n\r\n.icon-sm{\r\n  font-size: 8px\r\n}\r\n\r\n.size-lg{\r\n  font-size: 1.5em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
