import { WritableSignal } from '@angular/core';

// utils
import { getCardsByTextReport } from '../../../get-cards-by-text-report';

interface IsSameTextCardReport {
  cardsText: WritableSignal<string>;
  cardsByText: WritableSignal<string[]>;
}

export const isSameTextCardReport = ({
  cardsText,
  cardsByText,
}: IsSameTextCardReport) => {
  const cardsByTextCurrent = getCardsByTextReport(cardsText()).join(' ');
  const cardByTextPrev = cardsByText().join(' ');

  return cardsByTextCurrent === cardByTextPrev;
};
