// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  text-align: center;
  align-items: center;
  width: 100%;
  border-collapse: collapse;
}

tr:nth-of-type(odd) {
  background: #eee;
}

th {
  color: white;
  font-weight: bold;
  text-align: center;
}

td,
th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
  text-align: center;
}

.h-60 {
  height: 60px;
}

::ng-deep .width-size-lg {
  --width: 95%;
  --max-width: 400px;
}

.height-50 {
  height: 50px;
}

.size-sm {
  font-size: 0.8em;
}

.min-width-25 {
  min-width: 100px;
}

.light-color {
  color: #FCFCFC;
}

.dark-color {
  color: #222428 !important;
}

.height-auto {
  height: auto;
  width: auto;
}

.add-size {
  font-size: 2em;
}

.color-hover-icon-button:hover {
  color: var(--color-button-table-hover-company) !important;
}

.color-hover-icon:hover {
  color: var(--ion-color-icon-hover-ecommerce-manager) !important;
}

.h-60 {
  height: 60;
}

.font-sm {
  font-size: 0.8em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/aena/share/components/table/table-assign-article-aena/table-assign-article-aena.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,yBAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AAAA;EACE,YAAA;EACA,iBAAA;EACA,kBAAA;AAGF;;AAAA;;EAEE,YAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;AAGF;;AAAA;EACI,YAAA;AAGJ;;AAAA;EACE,YAAA;EACA,kBAAA;AAGF;;AAAA;EACE,YAAA;AAGF;;AAAA;EACE,gBAAA;AAGF;;AAAA;EACE,gBAAA;AAGF;;AAAA;EACE,cAAA;AAGF;;AAAA;EACE,yBAAA;AAGF;;AAAA;EACE,YAAA;EACA,WAAA;AAGF;;AAAA;EACE,cAAA;AAGF;;AAAA;EACE,yDAAA;AAGF;;AAAA;EACE,+DAAA;AAGF;;AAAA;EACE,UAAA;AAGF;;AAAA;EACE,gBAAA;AAGF","sourcesContent":["table {\r\n  text-align: center;\r\n  align-items: center;\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n}\r\ntr:nth-of-type(odd) {\r\n  background: #eee;\r\n}\r\nth {\r\n  color: white;\r\n  font-weight: bold;\r\n  text-align: center;\r\n}\r\n\r\ntd,\r\nth {\r\n  padding: 6px;\r\n  border: 1px solid #ccc;\r\n  text-align: left;\r\n  text-align: center;\r\n}\r\n\r\n.h-60 {\r\n    height: 60px;\r\n}\r\n\r\n::ng-deep .width-size-lg {\r\n  --width: 95%;\r\n  --max-width: 400px;\r\n}\r\n\r\n.height-50 {\r\n  height: 50px;\r\n}\r\n\r\n.size-sm {\r\n  font-size: 0.8em;\r\n}\r\n\r\n.min-width-25{\r\n  min-width: 100px;\r\n}\r\n\r\n.light-color{\r\n  color: #FCFCFC\r\n}\r\n\r\n.dark-color{\r\n  color: #222428 !important;\r\n}\r\n\r\n.height-auto{\r\n  height: auto;\r\n  width: auto;\r\n}\r\n\r\n.add-size{\r\n  font-size: 2em;\r\n}\r\n\r\n.color-hover-icon-button:hover{\r\n  color: var(--color-button-table-hover-company) !important;\r\n}\r\n\r\n.color-hover-icon:hover{\r\n  color: var(--ion-color-icon-hover-ecommerce-manager) !important;\r\n}\r\n\r\n.h-60{\r\n  height: 60;\r\n}\r\n\r\n.font-sm{\r\n  font-size: 0.8em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
