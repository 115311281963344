// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-indent-10 {
  text-indent: 10px;
}

.font-size-text {
  font-size: 1.2em;
}

.font-size-text-sm {
  font-size: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/data-manager/till-manager/components/modals/till-manager-legend/till-manager-legend.component.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,cAAA;AAAF","sourcesContent":["\r\n.text-indent-10{\r\n  text-indent: 10px;\r\n}\r\n\r\n.font-size-text{\r\n  font-size: 1.2em;\r\n}\r\n\r\n.font-size-text-sm{\r\n  font-size: 1em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
