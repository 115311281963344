import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ToastController, IonInfiniteScroll } from '@ionic/angular';
import { Subscription } from 'rxjs';

// services
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from 'src/app/services';
import { LanguageRouteService } from '../../services';
import { FilterClientTillService } from 'src/app/share/services';

// models
import { Company, Client, Till } from 'src/app/models';

// utils
import { type,timeReload,successToast, UtilsTranslate } from 'src/app/utils';

@Component({
  selector: 'app-filter-till-state',
  templateUrl: './filter-client-till.component.html',
  styleUrls: ['./filter-client-till.component.scss'],
})
export class FilterClientTillComponent implements OnInit {

  @Input() clients: Client[] = [];
  @Input() tills: Till[] = [];
  @Input() showClientFilter: boolean = false
  @Input() title: string = 'machines_status.till_state.filter_machine_status';

  deleteClientFilterTranslate = '';

  // aspecto
  company: Company;
  type = type;

  typeFilter: string = 'client'

  typeSelected = null;

  nameClient = '';
  tillCode = null;

  typesFiltered: any[] = []
  types: any[];
  typesFull: any[] = [];

  isTillStatus: boolean = false;

  changeLanguage$: Subscription;

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private modalCtrl: ModalController,
    private toastCrl: ToastController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _filterService: FilterClientTillService,
    private utils: UtilsTranslate,
    private router: Router,
  ) { }

  ngOnInit() {

    this.company = this._skinService.getCompany();

    this.isTillStatus = this.router.url === '/user/machines-status/till-state'

    this.title =  (this.isTillStatus) ? 'machines_status.till_state.filter_machine_status' : 'filter_client_till';


    this.showClientFilter = this.showClientFilter || (!this.showClientFilter && this.clients.length !== 1)

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });


    if (!this.showClientFilter || (this.tillCode && !this.nameClient)){
        this.typeFilter = 'till';
    }

    if(this.isTillStatus && this.clients.length <= 1){
      this.typeFilter = 'till'
    }

    this.start();
  }

  ngOnDestroy(): void {
    if( this.changeLanguage$) this.changeLanguage$.unsubscribe()
  }

  start() {
    if (this.typeFilter == 'client') {
      this.typesFull = this.clients;
      this.types = this.clients;
      this.typesFiltered = [];
      if (this.types.length < 10) {
        this.typesFiltered.push(...this.types.slice(0, this.types.length));
      } else {
        this.typesFiltered.push(...this.types.slice(0, 10));
      }
    } else {
      this.typesFull = this.tills;
      this.types = this.tills;
      this.typesFiltered = [];
      if (this.types.length < 10) {
        this.typesFiltered.push(...this.types.slice(0, this.types.length));
      } else {
        this.typesFiltered.push(...this.types.slice(0, 10));
      }
    }
  }

  changeFilter() {

    if (this.typeFilter === 'name') {
      this.changeNameClient()
    } else {
      this.changeTillCode();
    }
  }

  changeNameClient() {
    if (!this.nameClient) {
      this.start();
    } else {
      this.typesFiltered = [];
      this.types = this.typesFull.filter(typeFull => typeFull.description?.toString().toLowerCase().trim()
        .includes(this.nameClient?.toString().toLowerCase().trim()));
      if (this.types.length < 10) {
        this.typesFiltered.push(...this.types.slice(0, this.types.length));
      } else {
        this.typesFiltered.push(...this.types.slice(0, 10));
      }
    }
  }
  changeTillCode() {
    if (!this.tillCode) {
      this.start();
    } else {
      this.typesFiltered = [];
      this.types = this.typesFull.filter(typeFull => typeFull.code?.toString().toLowerCase().trim()
        .includes(this.tillCode?.toString().toLowerCase().trim()));
      if (this.types.length < 10) {
        this.typesFiltered.push(...this.types.slice(0, this.types.length));
      } else {
        this.typesFiltered.push(...this.types.slice(0, 10));
      }
    }
  }

  loadData(event) {
    setTimeout(() => {

      if (this.typesFiltered.length >= this.types.length) {

        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      let nuevoArr2 = [];
      if (this.clients.length < this.typesFiltered.length + 10) {
        nuevoArr2 = this.types.slice(this.typesFiltered.length, this.types.length);
      } else {
        nuevoArr2 = this.types.slice(this.typesFiltered.length, this.typesFiltered.length + 10);
      }
      this.typesFiltered.push(...nuevoArr2);

      this.inifiteScroll.complete();
    }, timeReload);
  }

  filterType() {
    if (this.typeFilter == 'client'){
      let selectFilter: Client = this.typesFull.find(typeFull => typeFull.ID == this.typeSelected);
      if (selectFilter) {
        this._filterService.setClientFilter(selectFilter.ID, selectFilter.description);
        if (this.modalCtrl) {
          this.modalCtrl.dismiss({
            name: selectFilter.description,
          });
        }
      } else {
        this.utils.presentToastTranslate('error', true, 0)
      }
    } else {
      let selectFilter: Till = this.typesFull.find(typeFull => typeFull.id == this.typeSelected);
      if (selectFilter) {
        this._filterService.setTillFilter(selectFilter.id, selectFilter.code, selectFilter.clientId);
        if (this.modalCtrl) {
          this.modalCtrl.dismiss({
            code: selectFilter.code
          });
        }
      } else {
        this.utils.presentToastTranslate('error', true, 0)
      }
    }

  }

  deleteFilterType() {
    this._filterService.setTillFilter(null, null, null);
    this._filterService.setClientFilter(null, '');
    if(this.modalCtrl){
      this.modalCtrl.dismiss({
        name: '',
        code: '',
        delete: true
      });
    }
    successToast(this.deleteClientFilterTranslate,this.toastCrl );
  }


  closeModal() {
    this.modalCtrl.dismiss({});
  }

  savePlaceholders() {
    this.translate.get(['transaction_query.carousel-filter.client_deleted']).subscribe((translates) => {
      this.deleteClientFilterTranslate = translates['transaction_query.carousel-filter.client_deleted'];
    })
  }

}

type typeFilterValues = 'client' | 'till' | null;
