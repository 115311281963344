import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'familyLanguage'
})
export class FamilyLanguagePipe implements PipeTransform {

  transform(family: string, hotDrinks: string, coldDrinks: string ): string {
    if(family == 'Bebidas Calientes') return hotDrinks;
    if(family == 'Bebidas Frías') return coldDrinks;
    return family;
  }

}
