// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  text-align: center;
  align-items: center;
  width: 100%;
  border-collapse: collapse;
  height: 88%;
}

/* Zebra striping */
tr:nth-of-type(odd) {
  border: 1px solid var(--ion-color-light-table-zebra);
}

th {
  color: var(--ion-color-light);
  font-weight: bold;
  text-align: center;
}

td,
th {
  padding: 6px;
  border: 1px solid #000;
  text-align: left;
  text-align: center;
}

ion-icon {
  size: large;
}

::ng-deep ul.ngx-pagination > li:not(.active) > a {
  background-color: var(--ion-color-light);
  border-color: var(--ion-color-medium);
  color: var(--ion-color-dark);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/QR/check-qr/components/modals/legend-check-qr/legend-check-qr.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,yBAAA;EACA,WAAA;AAAJ;;AAEE,mBAAA;AACA;EACE,oDAAA;AACJ;;AACE;EACE,6BAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;;AACE;;EAEE,YAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;AAEJ;;AACE;EACE,WAAA;AAEJ;;AACE;EACE,wCAAA;EACA,qCAAA;EACA,4BAAA;AAEJ","sourcesContent":["\r\n  table {\r\n    text-align: center;\r\n    align-items: center;\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n    height: 88%;\r\n  }\r\n  /* Zebra striping */\r\n  tr:nth-of-type(odd) {\r\n    border: 1px solid  var(--ion-color-light-table-zebra);\r\n  }\r\n  th {\r\n    color: var(--ion-color-light);\r\n    font-weight: bold;\r\n    text-align: center;\r\n  }\r\n\r\n  td,\r\n  th {\r\n    padding: 6px;\r\n    border: 1px solid #000;\r\n    text-align: left;\r\n    text-align: center;\r\n  }\r\n\r\n  ion-icon{\r\n    size: large\r\n  }\r\n\r\n  ::ng-deep ul.ngx-pagination > li:not(.active) > a {\r\n    background-color: var(--ion-color-light);\r\n    border-color: var(--ion-color-medium);\r\n    color: var(--ion-color-dark);\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
