// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dimension-pc {
  width: 40px;
  height: 40px;
}

.dimension-sm {
  width: 30px;
  height: 30px;
}

.font-light-mode {
  font-size: 1.2em;
}

.font-light-mode-sm {
  font-size: 1em;
}

.elora-color-button {
  --background: var(--ion-color-light-always);
  --color: var(--ion-color-dark-always);
  --background-hover: var(--ion-color-elora1-hover) !important;
  --background-hover-opacity: var(--ion-color-elora1-hover) !important;
  --color-hover: var(--ion-color-light-always);
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/color-change-button/color-change-button.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAGA;EACE,2CAAA;EACA,qCAAA;EACA,4DAAA;EACA,oEAAA;EACA,4CAAA;AAAF","sourcesContent":[".dimension-pc{\r\n  width: 40px;\r\n  height: 40px;\r\n}\r\n\r\n.dimension-sm{\r\n  width: 30px;\r\n  height: 30px;\r\n}\r\n\r\n.font-light-mode{\r\n  font-size: 1.2em;\r\n}\r\n\r\n.font-light-mode-sm{\r\n  font-size: 1em;\r\n}\r\n\r\n// -------------------------------- elora -----------------------\r\n.elora-color-button{\r\n  --background: var(--ion-color-light-always);\r\n  --color: var(--ion-color-dark-always);\r\n  --background-hover: var(--ion-color-elora1-hover) !important;\r\n  --background-hover-opacity: var(--ion-color-elora1-hover) !important;\r\n  --color-hover: var(--ion-color-light-always);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
