import { Client, Operator } from 'src/app/models';

export const getClientsFilteredTransactionQuery = (
  clients: Client[],
  operatorsSelecteds: number[],
) => {
  const operatorIdSelected = operatorsSelecteds?.[0] || null;

  if (!operatorIdSelected) {
    return clients;
  }

  return clients.filter((client) => client.OperatorId === operatorIdSelected);
};
