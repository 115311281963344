import { CardNames, Company } from 'src/app/models';

export const getTextRepeatCardTranslateReport = (
  company: Company,
  cardLength: number
) => {
  if (company.nameCard === CardNames.arbitradePay) {
    return getTextRepeatAlliancePay(cardLength);
  } else {
    return getTextRepeatCloseLoopCard(cardLength);
  }
};

export const getTextRepeatAlliancePay = (cardLength: number) => {
  return cardLength > 1
    ? 'reports.create_edit.repeat_cards_alliance'
    : 'reports.create_edit.repeat_card_alliance';
};

export const getTextRepeatCloseLoopCard = (cardLength: number) => {
  return cardLength > 1
    ? 'reports.create_edit.repeat_close_loop_cards'
    : 'reports.create_edit.repeat_close_loop_card';
};
