import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-manual-button',
  templateUrl: './manual-button.component.html',
  styleUrls: ['./manual-button.component.scss'],
})
export class ManualButtonComponent implements OnInit {
  @Input() company: Company;
  @Input() isLightColor: boolean = true;
  @Input() disabled: boolean = false;

  @Output() showManual = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  showManualModal() {
    this.showManual.emit();
  }
}
