import { WritableSignal } from '@angular/core';

// services
import { TranslateService } from '@ngx-translate/core';

// models
import { DayWeekMachineStatusAlert } from '../../models';

// utils
import { getCountDayWeekSelectedsMachineAlert } from './get-count-day-week-selecteds-machine-alert';

interface GetWekDaysTextMachineAlerts {
  _translateService: TranslateService;
  daysWeek: WritableSignal<Record<DayWeekMachineStatusAlert, boolean>>;
  daysWeekOrder: DayWeekMachineStatusAlert[];
  weekDaysText: WritableSignal<string>;
}

export const getWekDaysTextMachineAlerts = async ({
  _translateService,
  daysWeek,
  daysWeekOrder,
  weekDaysText,
}: GetWekDaysTextMachineAlerts) => {
  const countDayWeek = getCountDayWeekSelectedsMachineAlert(daysWeek);
  const TRANSLATE_PATH = 'machines_status.machine_status_alert.select_date.';


  if (countDayWeek === Object.keys(daysWeek()).length) {
    const allDaysWeek = await _translateService.instant(
      `${TRANSLATE_PATH}all_days_week`
    );
    weekDaysText.set(allDaysWeek);
    return;
  }

  if (countDayWeek === 0) {
    weekDaysText.set('');
    return;
  }

  const TRASNLATES_PATHS: Record<DayWeekMachineStatusAlert, string> = {
    [DayWeekMachineStatusAlert.sunday]: `${TRANSLATE_PATH}sunday`,
    [DayWeekMachineStatusAlert.monday]: `${TRANSLATE_PATH}monday`,
    [DayWeekMachineStatusAlert.tuesday]: `${TRANSLATE_PATH}tuesday`,
    [DayWeekMachineStatusAlert.wednesday]: `${TRANSLATE_PATH}wednesday`,
    [DayWeekMachineStatusAlert.thursday]: `${TRANSLATE_PATH}thursday`,
    [DayWeekMachineStatusAlert.friday]: `${TRANSLATE_PATH}friday`,
    [DayWeekMachineStatusAlert.saturday]: `${TRANSLATE_PATH}saturday`,
  };

  const translates = await _translateService.instant(
    Object.values(TRASNLATES_PATHS)
  );

  const translatesArr: string[] = Object.values(translates);

  const TRANSLATES_WEEKDAY: Record<DayWeekMachineStatusAlert, string> = {
    [DayWeekMachineStatusAlert.sunday]: translatesArr?.[0] || '',
    [DayWeekMachineStatusAlert.monday]: translatesArr?.[1] || '',
    [DayWeekMachineStatusAlert.tuesday]: translatesArr?.[2] || '',
    [DayWeekMachineStatusAlert.wednesday]: translatesArr?.[3] || '',
    [DayWeekMachineStatusAlert.thursday]: translatesArr?.[4] || '',
    [DayWeekMachineStatusAlert.friday]: translatesArr?.[5] || '',
    [DayWeekMachineStatusAlert.saturday]: translatesArr?.[6] || '',
  };

  let translatesText: string[] = [];
  for (let dayWeekPos of daysWeekOrder) {
    if (daysWeek()?.[dayWeekPos]) {
      translatesText.push(TRANSLATES_WEEKDAY[dayWeekPos]);
    }
  }

  // console.log('translateText', translatesText);

  weekDaysText.set(translatesText.join(', '));
};
