import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ElementRef,
  WritableSignal,
  EventEmitter,
  Output,
  signal,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// service
import { TranslateService } from '@ngx-translate/core';
import {
  FilterDateExtraHourService,
  FilterDateFutureService,
  FilterDateService,
  LanguageRouteService,
} from 'src/app/share/services';
import { AuthService, SkinMangementService } from '../../../../../services';
import { FilterTransactionsQueryService } from 'src/app/pages/user/transactions/transactions-query/services';
import { MatLanguageService } from 'src/app/share/services/mat-language/mat-language.service';

// models
import { Company, FilterDateServiceI } from 'src/app/models';
import { CheckSegment, RangeDates } from '../../models';

// utils
import { convertDateToString, pcWidth } from 'src/app/utils';
import { MIN_DATE_FILTER, getFilterDateService } from '../../helpers';

import { v4 as uuidv4 } from 'uuid';
import { removeUMTDate } from '../../../../../utils/useDate';

@Component({
  selector: 'app-filter-date-doble-hour-in-modal',
  templateUrl: './filter-date-doble-hour-in-modal.component.html',
  styleUrls: ['./filter-date-doble-hour-in-modal.component.scss'],
})
export class FilterDateDobleHourInModalComponent implements OnInit {
  // properties
  @Input() maxDatePeriod: Date = null;
  @Input() minDate: Date = null;
  @Input() showHour: boolean = true;
  @Input() onlyPeriod: boolean = false;

  @Input() showTextHourComplete: boolean = true;
  @Input() showHourPeriod: boolean = true;
  @Input() showOptionExtraHour: boolean = true;
  @Input() haveExtraHour: WritableSignal<boolean>;

  @Input() checkSegment: WritableSignal<CheckSegment> = signal('date');

  startDate: string;
  endDate: string;
  startHour: string;
  endHour: string;

  startHourExtra: string;
  endHourExtra: string;

  url: string = '';
  filterDateId: string = null;

  company: Company;


  _filterDateTemplateService: FilterDateServiceI;

  pcWidth = pcWidth;

  changeLanguage$: Subscription;

  datePipe = 'dd/MM/y';

  ranges: RangeDates = {
    oneDate: {
      minDate: MIN_DATE_FILTER,
      maxDate: new Date(),
    },
    period: {
      minDate: MIN_DATE_FILTER,
      maxDate: new Date(),
    },
  };

  applyValidator: Record<string, Function> = {};

  changeRoute$: Subscription;
  filterChange$: Subscription;
  clearFilter$: Subscription;
  platformResize$: Subscription;
  filterChangeExtraHour$: Subscription;

  isActiveSubscriptions = false;

  // enableHour = true;

  versionDate;

  waitChangeInvalidDate: boolean = false;

  constructor(
    public readonly platform: Platform,
    private router: Router,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinMangementService: SkinMangementService,
    private _authService: AuthService,
    private _filterTransactionQueryService: FilterTransactionsQueryService,
    private _filterDateService: FilterDateService,
    private _filterDateFutureService: FilterDateFutureService,
    public readonly _filterDateExtraHourService: FilterDateExtraHourService,
    private _matLanguageService: MatLanguageService
  ) {
    this.url = this.router.url;
    this.versionDate = uuidv4();

    this._filterDateTemplateService = getFilterDateService({
      routeUrl: this.router.url,
      _filterDateService: this._filterDateService,
      _filterTransactionQueryService: this._filterTransactionQueryService,
      _filterDateFutureService: this._filterDateFutureService,
    });

    this.getValidators();
  }

  ngOnInit() {
    this.company = this._skinMangementService.getCompany();

    if (
      this._filterDateTemplateService.getCheckSegment() === 'period' &&
      !this.onlyPeriod
    ) {
      this.checkSegment.set(this._filterDateTemplateService.getCheckSegment());
    }
    this.startRange();

    this.getCurrent();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    this.changeRoute$ = this.router.events.subscribe((ev: NavigationEnd) => {
      if (ev instanceof NavigationEnd) {
        if (this.url !== ev.url) {
          this.destroy();
        }
        if (this.url === ev.url) {
          this.startSegment();
        }
      }
    });

    this.platformResize$ = this.platform.resize.subscribe(() => {
      if (
        this.checkSegment() !== this._filterDateTemplateService.getCheckSegment()
      ) {
        this.checkSegment.set('other');
        setTimeout(() => {
          if (this.onlyPeriod) {
            this.checkSegment.set('period');
          } else {
            this.checkSegment.set(this._filterDateTemplateService.getCheckSegment());
            if (this.checkSegment() === 'other') {
              this.checkSegment.set('date');
            }
          }
        }, 50);
      }
    });

    this.getDates();
    this.getExtraHour();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.maxDatePeriod) {
      this.ranges.period.maxDate = this.maxDatePeriod;
      this.ranges.oneDate.maxDate = this.maxDatePeriod;
    }

    if (this.onlyPeriod) {
      this.checkSegment.set(this._filterDateTemplateService.getCheckSegment());
      if (
        this.checkSegment() &&
        (!this.checkSegment() ||
          this.checkSegment() === 'date' ||
          this.checkSegment() === 'other')
      ) {
        // console.log('editar');
        this.checkSegment.set('period');
        this._filterDateTemplateService.setCheckSegment('period');
      }
    }
  }

  getCurrent() {
    this.startDate = this._filterDateTemplateService.getDateStart();
    this.endDate = this._filterDateTemplateService.getDateEnd();
    this.startHour = this._filterDateTemplateService.getStartHour();
    this.endHour = this._filterDateTemplateService.getEndHour();

    this.startHourExtra = this._filterDateExtraHourService.getStartHour();
    this.endHourExtra = this._filterDateExtraHourService.getEndHour();
  }

  startSegment() {
    this.checkSegment.set(this._filterDateTemplateService.getCheckSegment());

    if (
      (!this.checkSegment() || this.checkSegment() === 'other') &&
      !this.onlyPeriod
    ) {
      this.checkSegment.set('date');
      this._filterDateTemplateService.setCheckSegment('date');
    }
  }

  getDates() {
    if (!this.filterChange$) {
      this.filterChange$ = this._filterDateTemplateService.filterChange$.subscribe(
        () => {
          this.startDate = this._filterDateTemplateService.getDateStart();
          this.endDate = this._filterDateTemplateService.getDateEnd();
          this.startHour = this._filterDateTemplateService.getStartHour();
          this.endHour = this._filterDateTemplateService.getEndHour();

          this.validateDate();
        }
      );
    }

    if (!this.clearFilter$) {
      this.clearFilter$ = this._filterDateTemplateService.reset$.subscribe(
        () => {
          if (this.url === this.router.url) {
            this.checkSegment.set(this._filterDateTemplateService.getCheckSegment());

            if (
              this.onlyPeriod &&
              this.checkSegment() &&
              (!this.checkSegment() ||
                this.checkSegment() === 'date' ||
                this.checkSegment() === 'other')
            ) {
              this.checkSegment.set('period');
              this._filterDateTemplateService.setCheckSegment('period');
            }

            this.startDate = this._filterDateTemplateService.getDateStart();
            this.endDate = this._filterDateTemplateService.getDateEnd();
            this.startHour = this._filterDateTemplateService.getStartHour();
            this.endHour = this._filterDateTemplateService.getEndHour();

            this.validateDate();
          }
        }
      );
    }

    this.isActiveSubscriptions = true;
  }

  destroy() {
    this.isActiveSubscriptions = false;
  }

  ngOnDestroy() {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if (this.changeRoute$) this.changeRoute$.unsubscribe();
    if (this.filterChange$) this.filterChange$.unsubscribe();
    if (this.clearFilter$) this.clearFilter$.unsubscribe();
    if (this.platformResize$) this.platformResize$.unsubscribe();
    if (this.filterChangeExtraHour$) this.filterChangeExtraHour$.unsubscribe();
  }

  startRange() {
    if (this.minDate) {
      this.assignRange(new Date(this.minDate));
    } else {
      this._authService.getMinDate().then((minDate) => {
        this.assignRange(minDate as Date);
      });
    }
  }

  assignRange(minDate: Date) {
    this.ranges.oneDate.minDate = minDate || this.ranges.oneDate.minDate;
    this.ranges.period.minDate = minDate || this.ranges.oneDate.minDate;

    if (this.maxDatePeriod) {
      this.ranges.period.maxDate = this.maxDatePeriod;
    }
  }

  changePeriod(event) {
    this.checkSegment.set(event)
    this._filterDateTemplateService.setCheckSegment(this.checkSegment());
  }

  savePlaceholders() {
    this.translate
      .get(['locale', 'format_pipe_without_hour'])
      .subscribe((translates) => {
        this.datePipe = translates['format_pipe_without_hour'];
        this._matLanguageService.setLanguage(translates['locale']);
      });
  }

  validateDate() {
    for (const key in this.applyValidator) {
      this.applyValidator?.[key] && this.applyValidator[key]();
    }
  }

  getValidators() {
    this.applyValidator = {
      minDatePeriod: () => this.changeMinDatePeriod(),
      maxDatePeriod: () => this.changeMaxDatePeriod(),
    };
  }

  changeMinDatePeriod() {
    if (
      !this.ranges?.period?.minDate ||
      !this.startDate ||
      this.checkSegment() !== 'period'
    ) {
      return;
    }
    const minDate = new Date(this.ranges.period.minDate);
    if (!minDate) {
      return;
    }

    let userTimezoneOffset = Math.trunc(minDate.getTimezoneOffset() / 60);
    const minDateCurrent = new Date(minDate);
    const startDateCurrent = new Date(this.startDate);

    if (
      startDateCurrent.getTime() - minDateCurrent.getTime() <
        userTimezoneOffset * 60 * 60 * 1000 &&
      !this.waitChangeInvalidDate
    ) {
      this._filterDateTemplateService.setDate(minDate, 0);
      this.applyInvalidChangeDate();
    }
  }

  changeMaxDatePeriod() {
    if (
      !this.ranges?.period?.maxDate ||
      !this.endDate ||
      this.checkSegment() !== 'period'
    ) {
      return;
    }
    const minDate = new Date(this.ranges.period.minDate);
    if (!minDate) {
      return;
    }

    let userTimezoneOffset = Math.trunc(minDate.getTimezoneOffset() / 60);
    const minDateCurrent = new Date(minDate);
    const startDateCurrent = new Date(this.endDate);

    if (
      startDateCurrent.getTime() - minDateCurrent.getTime() <
        userTimezoneOffset * 60 * 60 * 1000 &&
      !this.waitChangeInvalidDate
    ) {
      this._filterDateTemplateService.setDate(this.maxDatePeriod, 1);
      this.applyInvalidChangeDate();
    }
  }

  applyInvalidChangeDate() {
    this.waitChangeInvalidDate = true;
    setTimeout(() => {
      this.waitChangeInvalidDate = true;
    }, 100);
  }

  changeDate(startDate: Date) {
    if (!startDate) {
      return;
    }

    this._filterDateTemplateService.setDate(startDate, 0);
  }

  addTimeSlot() {
    this.haveExtraHour.set(!this.haveExtraHour());
  }

  getExtraHour() {
    if (!this.filterChangeExtraHour$) {
      this.filterChangeExtraHour$ = this._filterDateExtraHourService.filterChange$.subscribe(
        () => {
          this.startHourExtra = this._filterDateExtraHourService.getStartHour();
          this.endHourExtra = this._filterDateExtraHourService.getEndHour();
        }
      );
    }
  }
}
