import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// Paginator
import { NgxPaginationModule } from 'ngx-pagination';

// MultiLanguage
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// PDF
import { PdfViewerModule } from 'ng2-pdf-viewer';

// QR
import { QRCodeModule } from 'angularx-qrcode';

// Idle
import { Keepalive, NgIdleKeepaliveModule } from '@ng-idle/keepalive';

// Printer
import { Printer } from '@awesome-cordova-plugins/printer/ngx';

// External modules
import { ShareModule } from './share/share.module';
import { AppRoutingModule } from './app-routing.module';

// Component
import { AppComponent } from './app.component';

// services
import { StorageService } from './share/services';

// interceptor
import { RequestInterceptor } from './share/interceptor/request.interceptor';
import { ResponseInterceptor } from './share/interceptor/response.interceptor';

// Storage
import { StorageCapacitor } from './share/services/storage/storage-capacitor/storage-capacitor.class';

registerLocaleData(localeEs, 'es');
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,

    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
    NgxPaginationModule,
    // ChartsModule,

    AppRoutingModule,
    HttpClientModule,
    ShareModule,
    FormsModule,
    PdfViewerModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient],
      },
    }),
    QRCodeModule,
    BrowserAnimationsModule,
  ],
  providers: [
    StorageService,
    StorageCapacitor,
    Printer,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'es' },
    Keepalive,
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
