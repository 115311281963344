// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Component
import {
  AlertMaxCardsIntervalComponent,
} from '../';

// Service
import { LanguageService } from '../../../../../../../share/services/languages.service';
import { SkinMangementService } from '../../../../../../../services/skin-mangement.service';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
  declarations: [
    AlertMaxCardsIntervalComponent,
  ],
  providers: [LanguageService, SkinMangementService],
  exports: [
    AlertMaxCardsIntervalComponent,
  ],
})
export class AlertMaxCardIntervalModule {}
