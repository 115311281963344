import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError, timeout } from 'rxjs/operators';
import { throwError } from 'rxjs';

// config
import { AppConfig } from '../../../../../config/config';

// models
import { CheckFileAena } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SendFilesAenaService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient, protected router: Router) {}

  getCheckState(isTransactional: boolean) {
    let endpoint = (isTransactional) ? '/AENA/checkRegenTickets' : '/AENA/checkRegenDaily';
    let routeUrl = this.router.url;

    return this.httpClient
    .get<CheckFileAena>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
    .pipe(
      map((res) => {
        return res;
      }),
      timeout(200000),
      catchError((error) => {
        let messageError = !error?.error?.error?.message
          ? '504 Gateway timeout'
          : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );

  }

  sendFile(file: File, isTransactional: boolean){

      let endpoint = (isTransactional) ? '/AENA/pushRegenTickets' : 'AENA/pushRegenDaily';
      let routeUrl = this.router.url;

      const formData: FormData = new FormData();
      formData.append('file', file, file?.name);

      return this.httpClient
        .post(this.serviceUrl + endpoint + `?route=${routeUrl}`, formData)
        .pipe(
          map((res) => {
            return res;
          }),
          timeout(200000),
          catchError((error) => {
            let messageError = !error?.error?.error?.message
              ? '504 Gateway timeout'
              : error?.error?.error?.message;
            return throwError(() => messageError);
          })
        );
  }
}
