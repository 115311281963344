import { WritableSignal } from '@angular/core';

// models
import { RouteDriverRM, RouteEditable } from '../../models';
import { RouteDriver } from 'src/app/models';

export const validateSubstituteRouteDriverRM = (
  routeParticalSelected: RouteEditable,
  routeDrivers: WritableSignal<RouteDriver[]>
) => {
  const substitutesRouteDriver: RouteDriverRM[] =
    routeParticalSelected?.substituteRouteDriver;

  if (
    (substitutesRouteDriver || []).length === 1 &&
    substitutesRouteDriver?.[0].routeDriverId === null
  ) {
    return;
  }

  let substitutesRouteDriverValidates: RouteDriverRM[] = [];
  for (let routeDriverPos of substitutesRouteDriver) {
    const existRouteDriver = routeDrivers().find(
      (routeDriver) =>
        routeDriver.routeDriver_id === routeDriverPos.routeDriverId
    );

    if (existRouteDriver) {
      substitutesRouteDriverValidates.push(routeDriverPos);
    } else {
      console.error('Invalid sustitute route driver');
    }
  }

  if (
    (substitutesRouteDriverValidates || []).length ===
    (substitutesRouteDriver || []).length
  ) {
    return;
  }

  if((substitutesRouteDriverValidates || []).length === 0){
    substitutesRouteDriverValidates.push({enable: true, name: '', routeDriverId: null})
  }

  routeParticalSelected.substituteRouteDriver = substitutesRouteDriverValidates;
};
