// External Module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { HeaderModalModule } from '../header-modal/header-modal.modal';

// Components
import { FilterClientTillComponent } from './filter-client-till.component';

// Services
import { LanguageService } from '../../services/languages.service';

@NgModule({
    declarations: [
        FilterClientTillComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      ReactiveFormsModule,
      TranslateModule,
      HeaderModalModule,
    ],
    providers: [
      LanguageService,
    ],
    exports: [
      FilterClientTillComponent
    ]
  })
  export class FilterClientTillModule { }
