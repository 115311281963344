// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-flex-center {
  display: flex;
  justify-content: center;
}

::ng-deep .ngx-slider .ngx-slider-selection {
  background: var(--color-line) !important;
}
::ng-deep .ngx-slider .ngx-slider-pointer {
  background-color: var(--color-ball) !important;
}
::ng-deep .ngx-slider .ngx-slider-pointer::after {
  background-color: var(--color-inside-ball) !important;
}
::ng-deep .ngx-slider .ngx-slider-pointer.ngx-slider-active::after {
  background-color: var(--color-inside-ball-active) !important;
}

.h-40 {
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/slider-step-translate/slider-step-translate.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;;AAME;EACE,wCAAA;AAHJ;AAME;EACE,8CAAA;AAJJ;AAOE;EACE,qDAAA;AALJ;AAQE;EACE,4DAAA;AANJ;;AAWA;EACE,YAAA;AARF","sourcesContent":[".d-flex-center {\r\n  display: flex;\r\n  justify-content: center\r\n}\r\n\r\n\r\n\r\n::ng-deep {\r\n\r\n  .ngx-slider .ngx-slider-selection {\r\n    background: var(--color-line) !important;\r\n  }\r\n\r\n  .ngx-slider .ngx-slider-pointer {\r\n    background-color: var(--color-ball) !important;\r\n  }\r\n\r\n  .ngx-slider .ngx-slider-pointer::after {\r\n    background-color: var(--color-inside-ball) !important;\r\n  }\r\n\r\n  .ngx-slider .ngx-slider-pointer.ngx-slider-active::after {\r\n    background-color: var(--color-inside-ball-active) !important;\r\n  }\r\n\r\n}\r\n\r\n.h-40 {\r\n  height: 40px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
