// External module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

// Component
import { LegendComponent } from './legend.component';

// Service
import { SkinMangementService } from 'src/app/services';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [LegendComponent],
  providers: [SkinMangementService],
  exports: [LegendComponent],
})
export class LegendModule {}
