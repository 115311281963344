import { addTimeDate } from 'src/app/utils';

export const getLastDateDataCollection = () => {
  const date = addTimeDate({
    startDate: new Date(),
    increment: -1,
    typeIncrement: 'days',
  });

  date.setHours(23);
  date.setMinutes(53);
  date.setSeconds(59);

  return date;
};
