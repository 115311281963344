// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rocca-img {
  max-height: 50px;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/navbar/components/logo-navbar/logo-navbar.component.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,YAAA;AAAF","sourcesContent":["\r\n.rocca-img{\r\n  max-height: 50px;\r\n  height: 50px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
