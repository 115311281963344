import { WritableSignal } from '@angular/core';

// models
import { RouteManagerService } from '../../services';

// service
import { RouteDriver } from 'src/app/models';

// utils
import { UtilsTranslate } from 'src/app/utils';

interface GetRouteDriverByOperatorRM {
  _routeManagerService: RouteManagerService;
  routeDrivers: WritableSignal<RouteDriver[]>;
  loadingRouteDrivers: WritableSignal<boolean>;
  operatorId: number;
  utils: UtilsTranslate;
}

export const getRouteDriverByOperatorRM = ({
  _routeManagerService,
  routeDrivers,
  loadingRouteDrivers,
  operatorId,
  utils,
}: GetRouteDriverByOperatorRM) => {
  routeDrivers.set([]);
  loadingRouteDrivers.set(true);

  _routeManagerService.getRouteDrivers({ OperatorID: operatorId }).subscribe({
    next: (res) => {
      routeDrivers.set(res);
      loadingRouteDrivers.set(false);
    },
    error: () => {
      utils.showError();
      routeDrivers.set([]);
      loadingRouteDrivers.set(false);
    },
  });
};
