export const onlyMondayDatePicker = (date) => {

  if(!date){
    return false;
  }

  const day = date.getDay();

  return day === 1;
};
