// services
import { TranslateService } from '@ngx-translate/core';

// models
import { StatusTillAlert } from '../../../../till-alert/models';

// utils
import {
  CONNECTIVITY_TEXT_DB,
  CONNECTIVITY_TYPE_ALERT,
  PERFOMANCE_TYPE_ALERT_NOT_AENA,
  PERFORMANCE_TEXT_DB,
  PERFORMANCE_TYPE_ALERT_IS_AENA,
} from '../constant';
import { TypeAlertMachineStatus } from '../../models';

interface GetTypeAlerts {
  translate: TranslateService;
  isAena: boolean;
  status: StatusTillAlert[];
}

export const getTypeAlerts = async ({
  translate,
  isAena,
  status,
}: GetTypeAlerts): Promise<
TypeAlertMachineStatus[]
> => {
  const connectivityTranslate = await translate.instant(
    'machines_status.add_machines_status_alerts.connectivity'
  );
  const performanceTranslate = await translate.instant(
    'machines_status.add_machines_status_alerts.performance'
  );

  let typeAlerts:
  TypeAlertMachineStatus[] = [
    {
      id: getStatusId(status, CONNECTIVITY_TEXT_DB),
      description: connectivityTranslate,
      hour: CONNECTIVITY_TYPE_ALERT,
      originalText: CONNECTIVITY_TEXT_DB
    },
    {
      id: getStatusId(status, PERFORMANCE_TEXT_DB),
      description: performanceTranslate,
      hour: getPerformanceByAena(isAena),
      originalText: PERFORMANCE_TEXT_DB
    },
  ];

  return typeAlerts;
};

const getPerformanceByAena = (isAena: boolean) => {
  return isAena
    ? PERFORMANCE_TYPE_ALERT_IS_AENA
    : PERFOMANCE_TYPE_ALERT_NOT_AENA;
};

const getStatusId = (status: StatusTillAlert[], textDB: string) => {
  const statusSelected = status.find(
    (statusPos) => statusPos.Description === textDB
  );

  if(statusSelected){
    return statusSelected?.ID;
  }

  return textDB === CONNECTIVITY_TEXT_DB ? 1 : 0;

};
