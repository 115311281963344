import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { UtilsTranslate, timeReload } from 'src/app/utils';

const NUM_ELEMENTS_SHOW = 50;
@Component({
  selector: 'app-filter-template-object-no-modal',
  templateUrl: './filter-template-object-no-modal.component.html',
  styleUrls: ['./filter-template-object-no-modal.component.scss'],
})
export class FilterTemplateObjectNoModalComponent implements OnInit {
  @Input() company: Company;

  @Input() search: string = '';

  @Input() elementSelected;

  @Input() nameSearchElement: string = 'description';
  @Input() nameID: string = 'ID';

  @Input() haveClear: boolean = true;

  @Input() elements: any[] = [];
  @Input() inputLabelText: string = 'name';

  @Input() notExistText: string = '';

  @Input() heightContent: number = 320;
  @Input() numElementsShow: number = NUM_ELEMENTS_SHOW;

  @Input() isColorCard: boolean = false;

  elementsFilter: any[];
  elementsShower: any[];

  @Output() changeElement = new EventEmitter<{ element: any }>();

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(private utils: UtilsTranslate) {}

  ngOnInit() {
    this.start();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(
      (changes?.elements?.currentValue || []).length > 0
      &&  (changes?.elements?.previousValue || []).length > 0
      && (changes?.elements?.previousValue || []).length  !== (changes?.elements?.currentValue || []).length
    ){
      this.start();
    }
  }

  start() {
    this.elementsFilter = this.elements;
    this.elementsShower = [];
    if (this.elementsFilter.length < this.numElementsShow) {
      this.elementsShower.push(
        ...this.elementsFilter.slice(0, this.elementsFilter.length)
      );
    } else {
      this.elementsShower.push(
        ...this.elementsFilter.slice(0, this.numElementsShow)
      );
    }

    if (this.search) {
      this.changeName();
    }
  }

  changeName() {
    if (!this.search) {
      this.start();
    } else {
      this.elementsShower = [];
      this.elementsFilter = this.elements.filter((element) =>
        element?.[this.nameSearchElement]
          ?.toString()
          .toLowerCase()
          .trim()
          .includes(this.search?.toLowerCase().trim())
      );
      if (this.elementsFilter.length < this.numElementsShow) {
        this.elementsShower.push(
          ...this.elementsFilter.slice(0, this.elementsFilter.length)
        );
      } else {
        this.elementsShower.push(
          ...this.elementsFilter.slice(0, this.numElementsShow)
        );
      }

      // let elementFilter = this.elements.find(
      //   element => element?.[this.nameSearchElement]?.toString().toLowerCase().trim()
      //                 === this.search?.toLowerCase().trim());
      // (elementFilter) ? this.elementSelected = elementFilter[this.nameID] : this.elementSelected = null;
    }
  }

  loadData() {
    setTimeout(() => {
      if (this.elementsShower.length >= this.elementsFilter.length) {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      let newElements = [];
      if (
        this.elementsFilter.length <
        this.elementsShower.length + this.numElementsShow
      ) {
        newElements = this.elementsFilter.slice(
          this.elementsShower.length,
          this.elementsFilter.length
        );
      } else {
        newElements = this.elementsFilter.slice(
          this.elementsShower.length,
          this.elementsShower.length + this.numElementsShow
        );
      }
      this.elementsShower.push(...newElements);

      this.inifiteScroll.complete();
    }, timeReload);
  }

  changeElementSelected() {
    let elementFilter = this.elements.find(
      (element) => element?.[this.nameID] == this.elementSelected
    );
    if (elementFilter) {
      this.changeElement.emit(elementFilter);
    } else {
      this.utils.presentToastTranslate('error', true, 0);
    }
  }
}
