import { WritableSignal } from "@angular/core";

// models
import { DayWeekMachineStatusAlert } from "../../models";

export const getCountDayWeekSelectedsMachineAlert = (
  daysWeek: WritableSignal<Record<DayWeekMachineStatusAlert, boolean>>
) => {
  let weekSelecteds = 0;
  for (let weekDay of Object.values(daysWeek())) {
    if (weekDay) {
      weekSelecteds++;
    }
  }
  return weekSelecteds;
}
