import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { FilterDateServiceI } from 'src/app/models';
import { pcWidth } from '../../../../../../../utils';

@Component({
  selector: 'app-filter-hour',
  templateUrl: './filter-hour.component.html',
  styleUrls: ['./filter-hour.component.scss'],
})
export class FilterHourComponent implements OnInit {

  @Input() company;
  @Input() startHour;
  @Input() endHour;
  @Input() showTextHourComplete: boolean = false;
  @Input() showTextHourSm: boolean = true;


  @Input() _filterDate: FilterDateServiceI;

  pcWidth = pcWidth;

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}

  setHour({hour, pos}){
    this._filterDate.setHour(hour, pos);
  }

  setHourDouble({hourStart, hourEnd}: {hourStart: string, hourEnd: string}){
    this.setHour({hour: hourStart, pos: 0})
    this.setHour({hour: hourEnd, pos: 1})
  }

}
