export interface ReportRoutesTranslates {
  reports: string
}

export const getReportRoutes = (
  translates: ReportRoutesTranslates
): { route: string; name: string }[] => {
  return [
    { route: '/user/list/reports', name: translates.reports },
  ]
}
