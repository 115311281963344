import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from 'src/app/share/services';
import { ColorModeService, SkinMangementService } from 'src/app/services';

// models
import { Company } from 'src/app/models';

// utils
import { getISO } from 'src/app/utils';
import { GRIDSIZE } from '../../../../../../../../utils/constants';

@Component({
  selector: 'app-filter-hour-picker',
  templateUrl: './filter-hour-picker.component.html',
  styleUrls: ['./filter-hour-picker.component.scss'],
})
export class FilterHourPickerComponent implements OnInit {

  @Input() hourPick;
  @Input() start: boolean = true;
  @Input() showTextHourComplete: boolean = false;
  @Input() hourCompleteTranslate: string = 'Hora: '
  @Input() showTextHourSm: boolean = true;

  @Output() hourSelected = new EventEmitter();

  company: Company;

  // translates
  doneTranslate = '';
  cancelTranslate = '';

  placeHolderHour = '00:00';

  hourValue;

  isLightColor: boolean = true;

  changeLanguage$: Subscription;
  changeColor$: Subscription;

  isOpen = false;

  sizeMd = GRIDSIZE.md;

  @ViewChild('popover') popover;

  constructor(
    public readonly platform: Platform,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _colorModeService: ColorModeService
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    if (!this.start) this.placeHolderHour = '24:00';
    if (this.hourPick) this.hourValue = getISO(this.hourPick);

    this.getColors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hourPick) this.hourValue = getISO(this.hourPick);
  }

  ngOnDestroy() {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if(this.changeColor$) this.changeColor$.unsubscribe()
  }

  getColors() {
    this.isLightColor = this._colorModeService.getIsLightColor();

    this.changeColor$ = this._colorModeService.changeColor$.subscribe(
      (isLightColor) => (this.isLightColor = isLightColor)
    );
  }

  changeHour() {
    this.hourSelected.emit(this.hourPick);
  }


  changeHourPopover(time: string | string[]) {
    let times = time.toString().split('T');

    this.hourPick =
      times.length > 1 && times[1].length >= 4
        ? `${times[1][0]}${times[1][1]}:${times[1][3]}${times[1][4]}`
        : this.placeHolderHour;

    this.hourSelected.emit(this.hourPick);
    if (this.hourPick) this.hourValue = getISO(this.hourPick);
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  savePlaceholders() {
    this.translate.get(['done', 'cancel']).subscribe((translates) => {
      this.doneTranslate = translates['done'];
      this.cancelTranslate = translates['cancel'];
    });
  }

}
