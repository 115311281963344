// External Module
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Module
import { HeaderModalModule } from '../../../../header-modal/header-modal.modal';

// Components
import {
 FilterHourComponent,
 FilterHourPcComponent,
 FilterHourPickerComponent,
 FilterHourSmComponent,
 HoursPickerModalComponent
} from '.';

// Services
import { LanguageService } from '../../../../../services/languages.service';

@NgModule({
  declarations: [
    FilterHourComponent,
    FilterHourPcComponent,
    FilterHourSmComponent,
    FilterHourPickerComponent,
    HoursPickerModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    HeaderModalModule,
  ],
  providers: [LanguageService, DatePipe],
  exports: [
    FilterHourComponent,
    FilterHourPickerComponent,
    HoursPickerModalComponent,
    FilterHourPcComponent,
    FilterHourSmComponent,
  ],
})
export class FilterHourModule {}
