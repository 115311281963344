// models
import { Client } from 'src/app/models';
import { ClientRoute } from '../../../route-management/sales-module/models';

export const getClientsByClientRouteInvManager = (
  clientRoutes: ClientRoute[]
): Client[] => {
  return clientRoutes.map((client) => ({
    ID: client?.clientID,
    description: client?.client,
    code: client?.clientCode,
    TypeId: null,
  }));
};
