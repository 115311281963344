import { Injectable } from '@angular/core';
import { OperatorGroup } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class FilterGroupOperatorModalService {
  operatorGroup:OperatorGroup = null

  constructor() { }

  setOperatorGroup(newOperatorGroup){
    this.operatorGroup = newOperatorGroup
  }

  getOperatorGroup(){
    return this.operatorGroup
  }

  getOperatorGroupName(){
    return this.operatorGroup.description
  }

  getOperatorGroupId(){
    return this.operatorGroup?.ID
  }

  deleteOperatorGroup(){
    this.operatorGroup = null
  }
}
