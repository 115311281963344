const TRANSLATE_PATH_MONTH = 'ion-time.';

export const MONTH_TRANLSATES: { translate: string; value: number }[] = [
  {
    translate: `${TRANSLATE_PATH_MONTH}january`,
    value: 0,
  },
  {
    translate: `${TRANSLATE_PATH_MONTH}february`,
    value: 1,
  },
  {
    translate: `${TRANSLATE_PATH_MONTH}march`,
    value: 2,
  },
  {
    translate: `${TRANSLATE_PATH_MONTH}april`,
    value: 3,
  },
  {
    translate: `${TRANSLATE_PATH_MONTH}may`,
    value: 4,
  },
  {
    translate: `${TRANSLATE_PATH_MONTH}june`,
    value: 5,
  },
  {
    translate: `${TRANSLATE_PATH_MONTH}july`,
    value: 6,
  },
  {
    translate: `${TRANSLATE_PATH_MONTH}august`,
    value: 7,
  },
  {
    translate: `${TRANSLATE_PATH_MONTH}september`,
    value: 8,
  },
  {
    translate: `${TRANSLATE_PATH_MONTH}october`,
    value: 9,
  },
  {
    translate: `${TRANSLATE_PATH_MONTH}november`,
    value: 10,
  },
  {
    translate: `${TRANSLATE_PATH_MONTH}december`,
    value: 11,
  },
];

const PATH_WEEKDAYS = 'subsidies_v2.assign.select_date.'
export const WEEKDAY_TRANSLATE = [
  `${PATH_WEEKDAYS}sunday`,
  `${PATH_WEEKDAYS}monday`,
  `${PATH_WEEKDAYS}tuesday`,
  `${PATH_WEEKDAYS}wednesday`,
  `${PATH_WEEKDAYS}thursday`,
  `${PATH_WEEKDAYS}friday`,
  `${PATH_WEEKDAYS}saturday`,
]
