import { WritableSignal } from '@angular/core';

// models
import { RouteEditable } from '../../models';

export const removeRouteEditableByOperator = (
  routeEditable: WritableSignal<RouteEditable>
) => {
  routeEditable.mutate((route) => {
    route.supervisor = { supervisor: '', supervisorId: null };
    route.holderRouteDriver = { enable: true, name: '', routeDriverId: null };
    route.substituteRouteDriver = [
      { enable: true, name: '', routeDriverId: null },
    ];
    route.tills = [];
  });
};
