import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Platform, IonSegment } from '@ionic/angular';

import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

// Swiper
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper';

// Services
import { SendFilesAenaService } from '../../services/send-files-aena.service';
import { SendFilesFilterService } from '../../services/send-files-filter.service';

// Models
import { ColorTheme, Company } from 'src/app/models';
import { CheckFileAena, SendFilesAenaConrollerEvents } from '../../models';

// utils
import {
  TIME_OUT_GROUP,
  UtilsTranslate,
  pcWidth,
} from '../../../../../../utils';

@Component({
  selector: 'app-send-files-aena-controller',
  templateUrl: './send-files-aena-controller.component.html',
  styleUrls: ['./send-files-aena-controller.component.scss'],
})
export class SendFilesAenaControllerComponent implements OnInit {
  @Input() company: Company;
  @Input() isFile2: boolean = false;
  @Input() eventsController: Observable<SendFilesAenaConrollerEvents>;
  @Input() colorTheme: ColorTheme = 'light';

  config: SwiperOptions = {
    pagination: { clickable: true },
    keyboard: true,
  };

  pcWidth = pcWidth;

  segment = '0';

  loadingStateTransactional: boolean = true;
  stateTransactional: CheckFileAena;

  loadingStateDaily: boolean = true;
  stateDaily: CheckFileAena;

  private eventsControllerSubscription$: Subscription;
  changeSize$: Subscription;

  @ViewChild(IonSegment) segment2: IonSegment;
  @ViewChild('swiperInfoAena', { static: false }) swiper: SwiperComponent;

  constructor(
    public readonly platform: Platform,
    private _sendFilesAenaService: SendFilesAenaService,
    private _filterService: SendFilesFilterService,
    private utils: UtilsTranslate
  ) {}

  ngOnInit() {
    this.eventsControllerSubscription$ = this.eventsController.subscribe(
      (ev) => {
        if (ev === 'start') {
          this.start();
          return;
        }

        if (ev === 'destroy') {
          this.destroy();
          return;
        }
      }
    );

    this.changeSize$ = this.platform.resize.subscribe(() => {
      if (this.segment == '1') this.segment = '0';
    });
  }

  ngOnDestroy() {
    if (this.eventsControllerSubscription$)
      this.eventsControllerSubscription$.unsubscribe();
    if (this.changeSize$) this.changeSize$.unsubscribe();
  }

  start() {
    setTimeout(() => {
      this.getCheckTransaccional();
      this.getCheckDaily();
    }, TIME_OUT_GROUP);
  }

  getCheckTransaccional() {
    this.loadingStateTransactional = true;
    this._sendFilesAenaService.getCheckState(true).subscribe({
      next: (res) => {
        this.stateTransactional = res;
        this.loadingStateTransactional = false;
      },
      error: () => {
        this.utils.showError();
        this.stateTransactional = { state: 'error' };
        this.loadingStateTransactional = false;
      },
    });
  }

  getCheckDaily() {
    this.loadingStateDaily = true;
    this._sendFilesAenaService.getCheckState(false).subscribe({
      next: (res) => {
        this.stateDaily = res;
        this.loadingStateDaily = false;
      },
      error: () => {
        this.utils.showError();
        this.stateDaily = { state: 'error' };
        this.loadingStateDaily = false;
      },
    });
  }

  destroy() {
    this.stateTransactional = null;
    this.stateDaily = null;
    this.loadingStateDaily = true;
    this.loadingStateTransactional = true;
    this._filterService.clear();
  }

  segmentChanged() {
    this.swiper?.swiperRef?.slideTo(Number(this.segment));
  }

  async slideChange() {
    this.segment2.value = (this.swiper?.swiperRef?.activeIndex).toString();
  }
}
