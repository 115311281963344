// charts
import { BaseChartDirective } from 'ng2-charts';

// services
import { TranslateService } from '@ngx-translate/core';

// models
import {
  AreasTranslates,
  DatePipeDashboardValids,
  PaymentDashboardType,
} from '../../models';
import { ALLIANCEPAY_CARD_NAME } from 'src/app/utils';

interface GetTranslateDashboardWelcomeInputs {
  translate: TranslateService;
  getLabels: Function;
  nameCard: string;
}

interface GetTranslateDashboardWelcome {
  monthsTranslates: string[];
  locale: string;
  barChartLabels: BaseChartDirective['labels'];
  typePayments: Record<PaymentDashboardType, string>;
  areaTranslates: AreasTranslates;
  datePipe: Record<DatePipeDashboardValids, string>;
}

export const getTranslateDashboardWelcome = async ({
  translate,
  getLabels,
  nameCard,
}: GetTranslateDashboardWelcomeInputs): Promise<GetTranslateDashboardWelcome> => {
  const translates = await translate.instant([
    'ion-time.january',
    'ion-time.february',
    'ion-time.march',
    'ion-time.april',
    'ion-time.may',
    'ion-time.june',
    'ion-time.july',
    'ion-time.august',
    'ion-time.september',
    'ion-time.october',
    'ion-time.november',
    'ion-time.december',
    'dashboard.cold_drink',
    'dashboard.hot_drink',
    'dashboard.snacks',
    'dashboard.others',
    'dashboard.filter_payment.cash',
    'dashboard.filter_payment.credit_card',
    'dashboard.filter_payment.close_loop_card',
    'dashboard.filter_payment.subsidy_alliance',
    'dashboard.filter_payment.subsidy_close_loop_card',
    'dashboard.filter_payment.free_sale',
    'dashboard.filter_payment.all',
    'dashboard.areas.area1_title',
    'dashboard.areas.units',
    'dashboard.areas.gross_sales',
    'dashboard.all_family',
    'locale',
    'format_pipe_without_hour',
    'format_pipe_month_year2',
  ]);

  const monthsTranslates = [
    translates['ion-time.january'],
    translates['ion-time.february'],
    translates['ion-time.march'],
    translates['ion-time.april'],
    translates['ion-time.may'],
    translates['ion-time.june'],
    translates['ion-time.july'],
    translates['ion-time.august'],
    translates['ion-time.september'],
    translates['ion-time.october'],
    translates['ion-time.november'],
    translates['ion-time.december'],
  ];

  const locale = translates['locale'];

  const barChartLabels = getLabels();

  // TODO test
  // const typePayments = {
  //   cash: translates['dashboard.filter_payment.cash'],
  //   creditCard: translates['dashboard.filter_payment.credit_card'],
  //   cardNum:
  //     nameCard === 'AlliancePay'
  //       ? 'AlliancePay'
  //       : translates['dashboard.filter_payment.close_loop_card'],
  //   subsidy:
  //     nameCard === 'AlliancePay'
  //       ? translates['dashboard.filter_payment.subsidy_alliance']
  //       : translates['dashboard.filter_payment.subsidy_close_loop_card'],
  //   freeSale: translates['dashboard.filter_payment.free_sale'],
  //   all: translates['dashboard.filter_payment.all'],
  // };

   const typePayments = {
    cash: translates['dashboard.filter_payment.cash'],
    creditCard: translates['dashboard.filter_payment.credit_card'],
    cardNum:
      nameCard === ALLIANCEPAY_CARD_NAME
        ? ALLIANCEPAY_CARD_NAME
        : translates['dashboard.filter_payment.close_loop_card'],
    subsidy:
      nameCard === ALLIANCEPAY_CARD_NAME
        ? translates['dashboard.filter_payment.subsidy_alliance']
        : translates['dashboard.filter_payment.subsidy_close_loop_card'],
    freeSale: translates['dashboard.filter_payment.free_sale'],
    all: translates['dashboard.filter_payment.all'],
  };

  const areaTranslates = {
    averageDailyGross: {
      labelsSet: [
        translates['dashboard.all_family'],
        translates['dashboard.cold_drink'],
        translates['dashboard.hot_drink'],
        translates['dashboard.snacks'],
        translates['dashboard.others'],
      ],
    },
    totalGross: {
      titleLabel: translates['dashboard.areas.area1_title'],
      labelsSet: [
        translates['dashboard.cold_drink'],
        translates['dashboard.hot_drink'],
        translates['dashboard.snacks'],
        translates['dashboard.others'],
      ],
    },
    totalArticles: {
      units: translates['dashboard.areas.units'],
      billing: translates['dashboard.areas.gross_sales'],
    },
  };

  const datePipe = {
    withoutHour: translates['format_pipe_without_hour'],
    monthYear: translates['format_pipe_month_year2'],
    withHour: translates['format_pipe24h']
  };

  return {
    monthsTranslates,
    locale,
    barChartLabels,
    typePayments,
    areaTranslates,
    datePipe,
  };
};
