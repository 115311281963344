// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mt-40 {
  margin-top: 40px;
}

.text-indent {
  text-indent: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/machines/ticket-check/components/modals/legend-ticket-check/legend-ticket-check.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".mt-40{\r\n  margin-top: 40px;\r\n}\r\n\r\n.text-indent{\r\n  text-indent: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
