import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Config
import { AppConfig } from '../../../config/config';

// Models
import { Family } from '../../../models/Family.model';

@Injectable({
  providedIn: 'root'
})
export class FamilyService {

  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient) { }

  getFamilies() {
    let endpoint = `families`;
    return this.httpClient.get<Family[]>(this.serviceUrl + endpoint).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }
}
