import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { UtilsTranslate, timeReload } from 'src/app/utils';

const NUM_ELEMENTS_SHOW = 50;

@Component({
  selector: 'app-filter-template-guided-object-doble-inputs-no-modal',
  templateUrl:
    './filter-template-guided-object-doble-inputs-no-modal.component.html',
  styleUrls: [
    './filter-template-guided-object-doble-inputs-no-modal.component.scss',
  ],
})
export class FilterTemplateGuidedObjectDobleInputsNoModalComponent
  implements OnInit {
  @Input() company: Company;
  @Input() nameSearchElement1: string = 'description';
  @Input() nameSearchElement2: string = 'code';
  @Input() nameID: string = 'ID';

  @Input() search1: string = '';
  @Input() search2: string = '';

  @Input() elements: any[] = [];

  @Input() notFoundText: string = '';
  @Input() numElementsShow: number = NUM_ELEMENTS_SHOW;
  @Input() heightContent: number = 320;

  @Input() isColorCard: boolean = false;

  @Output() changeElement = new EventEmitter<{ element: any }>();

  elementsFilter: any[];
  elementsShower: any[];

  elementSelected: any;

  typeFilter: TypeFilter = 'input1';

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(private utils: UtilsTranslate) {}

  ngOnInit() {
    // this.start();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (
      (changes?.elements?.currentValue || []).length > 0 &&
      // (changes?.elements?.previousValue || []).length > 0 &&
      (changes?.elements?.previousValue || []).length !==
        (changes?.elements?.currentValue || []).length
    ) {
      this.start();
    }
  }

  start() {
    this.elementsFilter = this.elements;
    this.elementsShower = [];
    if (this.elementsFilter.length < this.numElementsShow) {
      this.elementsShower.push(
        ...this.elementsFilter.slice(0, this.elementsFilter.length)
      );
    } else {
      this.elementsShower.push(
        ...this.elementsFilter.slice(0, this.numElementsShow)
      );
    }

    if (this.search1 && this.typeFilter === 'input1') {
      this.changeInput1();
    }

    if (this.search2 && this.typeFilter === 'input2') {
      this.changeInput2();
    }
  }

  changeFilter() {
    if (this.typeFilter === 'input1') {
      this.changeInput1();
    } else {
      this.changeInput2();
    }
  }

  changeInput1() {
    if (!this.search1) {
      this.start();
    } else {
      this.elementsShower = [];
      this.elementsFilter = this.elements.filter((element) =>
        element?.[this.nameSearchElement1]
          ?.toString()
          .toLowerCase()
          .trim()
          .includes(this.search1?.toString().toLowerCase().trim())
      );
      if (this.elementsFilter.length < this.numElementsShow) {
        this.elementsShower.push(
          ...this.elementsFilter.slice(0, this.elementsFilter.length)
        );
      } else {
        this.elementsShower.push(
          ...this.elementsFilter.slice(0, this.numElementsShow)
        );
      }
    }
  }

  changeInput2() {
    if (!this.search2) {
      this.start();
    } else {
      this.elementsShower = [];
      this.elementsFilter = this.elements.filter((element) =>
        element?.[this.nameSearchElement2]
          ?.toString()
          .toLowerCase()
          .trim()
          .includes(this.search2?.toString().toLowerCase().trim())
      );
      if (this.elementsFilter.length < this.numElementsShow) {
        this.elementsShower.push(
          ...this.elementsFilter.slice(0, this.elementsFilter.length)
        );
      } else {
        this.elementsShower.push(
          ...this.elementsFilter.slice(0, this.numElementsShow)
        );
      }
    }
  }

  loadData() {
    setTimeout(() => {
      if (this.elementsShower.length >= this.elementsFilter.length) {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      let newElements = [];
      if (
        this.elementsFilter.length <
        this.elementsShower.length + this.numElementsShow
      ) {
        newElements = this.elementsFilter.slice(
          this.elementsShower.length,
          this.elementsFilter.length
        );
      } else {
        newElements = this.elementsFilter.slice(
          this.elementsShower.length,
          this.elementsShower.length + this.numElementsShow
        );
      }
      this.elementsShower.push(...newElements);

      this.inifiteScroll.complete();
    }, timeReload);
  }

  changeElementSelected() {
    let elementFilter = this.elements.find(
      (element) => element?.[this.nameID] == this.elementSelected
    );
    if (elementFilter) {
      this.changeElement.emit(elementFilter);
    } else {
      this.utils.presentToastTranslate('error', true, 0);
    }
  }
}

type TypeFilter = 'input1' | 'input2';
