import { ONLY_DAYS_MONTH_REPORT } from 'src/app/pages/user/listings/reports/utils';

export const onlyDaysMonthReportDatePicker = (date) => {
  if (!date) {
    return false;
  }
  const day = date.getDate();

  return ONLY_DAYS_MONTH_REPORT.includes(day);
};
