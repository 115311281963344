import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { FixMenuService } from '../../../services/fix-menu/fix-menu.service';
import { ColorModeService } from '../../../../services/color-mode.service';

// Models
import { Company, LinkRouteGroup } from '../../../../models';
import {
  COLOR_STYLE_ARTICLE_SUBMENU,
  COLOR_STYLE_INVERT_SUBMENU,
  COLOR_STYLE_WHITE_SUBMENU,
  isArticleGroupSubMenu,
} from './utils';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
})
export class SubMenuComponent implements OnInit {
  // nameIcon: iconos de ionic
  // icon: iconos de ruta .svg

  @Input() routeGroup: LinkRouteGroup;
  @Input() color: string;
  @Input() showComponent: boolean;
  @Input() company: Company;

  changeColor$: Subscription;
  isLightColor: boolean = true;
  changeColorWorking = ['../../../assets/custom-icons/articles.svg'];
  styleIcon = '';

  showSubmenu: boolean = false;

  isHoverGroup: boolean = false;


  constructor(
    private _fixMenuService: FixMenuService,
    private _colorModeService: ColorModeService
  ) {}

  ngOnInit() {
    this.isLightColor = this._colorModeService.getIsLightColor();
    this.getStyle();

    this.changeColor$ = this._colorModeService.changeColor$.subscribe((res) => {
      this.isLightColor = res;
      this.getStyle();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.routeGroup) {
      this.getStyle();
    }
  }

  ngOnDestroy(): void {
    if (this.changeColor$) this.changeColor$.unsubscribe();
  }

  menuItemHandler(): void {
    this.showSubmenu = !this.showSubmenu;
  }

  closeMenu() {
    this._fixMenuService.closeMenu();
  }

  getStyle() {
    this.styleIcon = '';
    if (
      ((!this.isLightColor && !this.isHoverGroup) ||
        (this.isLightColor && this.isHoverGroup)) &&
      this.routeGroup?.icon
    ) {
      this.styleIcon = this.changeColorWorking.includes(this.routeGroup.icon)
        ? COLOR_STYLE_WHITE_SUBMENU
        : COLOR_STYLE_INVERT_SUBMENU;
    }

    if (
      isArticleGroupSubMenu(this.routeGroup) &&
      this.styleIcon === COLOR_STYLE_WHITE_SUBMENU &&
      this.isLightColor
    ) {
      this.styleIcon = COLOR_STYLE_ARTICLE_SUBMENU;
    }
  }

  selectHover(isHoverGroup: boolean) {
    this.isHoverGroup = isHoverGroup;

    this.getStyle();
  }
}
