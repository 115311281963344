export enum CreateEditRouteManagerOptions{
  create = 0,
  edit = 1,
}

export const CreateEditRoteManagerOptionPos: Record<
  number,
  CreateEditRouteManagerOptions
> = {
  0: CreateEditRouteManagerOptions.create,
  1: CreateEditRouteManagerOptions.edit,
};
