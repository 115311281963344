import { Component, OnInit, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs/internal/Subscription';

// Component
import { FilterTillModalComponent } from './component/filter-till-modal/filter-till-modal.component';

// Service
import { SkinMangementService } from '../../../services/skin-mangement.service';
import { FilterTillService } from './service/filter-till.service';

// Models
import { Company } from '../../../models';

// Utils
import { pcWidth, pcWidthMix, type, ModalGenerator } from 'src/app/utils';

@Component({
  selector: 'app-filter-till',
  templateUrl: './filter-till.component.html',
  styleUrls: ['./filter-till.component.scss'],
})
export class FilterTillComponent implements OnInit {

  @Input() colTill: number = 3;
  @Input() colCodeNumber: number = 4.5;
  @Input() pcWidth = pcWidth;
  @Input() pcWidthMix = pcWidthMix;

   // aspecto
   company: Company;
   type = type;

   machineNumber:number = null;

   filterChange$: Subscription;

  constructor(
    public readonly platform: Platform,
    private _skinService: SkinMangementService,
    private _tillService: FilterTillService,
    private modalGenerator: ModalGenerator
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.machineNumber = this._tillService.getMachineNumber();

    this.filterChange$ = this._tillService.filterChange.subscribe(res => {
      this.machineNumber = res;
    })
  }

  ngOnDestroy(): void {
    if(this.filterChange$) this.filterChange$.unsubscribe()
  }

  async showFilterTill(){
    const data = await this.modalGenerator.show(FilterTillModalComponent)
    if (data) {
      this.machineNumber = this._tillService.getMachineNumber();
    }
  }

}
