// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-danger {
  background: var(--ion-color-danger);
}

.size-icon-lg {
  font-size: 1.4em;
  margin-right: 10px;
}

.d-flex-center2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/register-cards/components/share/alert-select-operator/alert-select-operator.component.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;AACJ;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AACF","sourcesContent":[".bg-danger{\r\n    background: var(--ion-color-danger);\r\n}\r\n\r\n.size-icon-lg{\r\n  font-size: 1.4em;\r\n  margin-right: 10px;\r\n}\r\n\r\n.d-flex-center2{\r\n  display: flex;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
