import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from '../../../services';

// models
import { OperatorGroup } from 'src/app/models';

@Component({
  selector: 'app-filter-operator-group',
  templateUrl: './filter-operator-group.component.html',
  styleUrls: ['./filter-operator-group.component.scss'],
})
export class FilterOperatorGroupComponent implements OnInit {


  @Input() operatorsGroup: OperatorGroup[] = [];
  @Input() loadingOperatorsGroup: boolean = true;
  @Input() operatorGroupSelected: number = null;
  @Input() showSelect = true;

  @Output() changeOperatorGroupSelected = new EventEmitter<number>();


  loadingOperatorGroup: boolean = true;

  constructor(
    private _languageRouteService: LanguageRouteService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {}

  changeOperatorGroup() {
    this.changeOperatorGroupSelected.emit(this.operatorGroupSelected)
  }

}





