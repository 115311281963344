import { WritableSignal } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { lastValueFrom } from 'rxjs';

// services
import { TranslateService } from '@ngx-translate/core';
import { DashboardWelcomeService } from '../../services';
import { LoadingService } from 'src/app/share/services';

// models
import { ClientDashboardWelcome } from '../../models';
import { Client } from 'src/app/models';

// utils
import { UtilsTranslate } from 'src/app/utils';
import { getClientsByStatus } from '../start-dashboard-welcome';

interface ActivateClientSelected {
  alertCtrl: AlertController;
  modalCtrl: ModalController;

  translate: TranslateService;
  _dashboardWelcomeService: DashboardWelcomeService,
  _loadingService: LoadingService,

  client: ClientDashboardWelcome;
  clients: Client[],

  customAlertStyle: string;
  utils: UtilsTranslate,

  clientsActive: WritableSignal<Client[]>,
  clientsAdd: WritableSignal<Client[]>,
}

export const activateClientSelected = async ({
  translate,
  _dashboardWelcomeService,
  _loadingService,
  client,
  customAlertStyle,
  alertCtrl,
  modalCtrl,
  utils,
  clientsActive,
  clientsAdd,
  clients
}: ActivateClientSelected) => {
  const header = await translate.instant('dashboard.request_add_client.title');
  const cancel = await translate.instant('cancel');
  const activate = await translate.instant(
    'dashboard.request_add_client.activar'
  );
  const message = await translate.instant(
    'dashboard.request_add_client.message',
    { client: client.description }
  );

  const alert = await alertCtrl.create({
    cssClass: customAlertStyle,
    header,
    message,
    buttons: [
      {
        text: cancel,
        role: 'cancel',
        cssClass: 'cancel-alert-button',
        handler: () => {},
      },
      {
        text: activate,
        cssClass: 'ok-alert-button',
        handler: async() => {
          try{
            _loadingService.present()
            await lastValueFrom(_dashboardWelcomeService.requestClientDashboard(client.ID));
            const userDashboard = await lastValueFrom(_dashboardWelcomeService.getUserDasbhoard())

            const {clientsActive: newClientActive, clientsAdd: newClientAdd} = getClientsByStatus(
              clients,
              userDashboard
            );
            clientsActive.set(newClientActive);
            clientsAdd.set(newClientAdd);

            _loadingService.close()

            utils.presentToastWithVariable(
              'dashboard.not_found_dashboard_client.activate_client',
              'client',
              client.description,
              false,
            )

            modalCtrl.dismiss({isActive: false, client});
          }catch(error){
            _loadingService.close()
            utils.showError()
          }
        },
      },
    ],
  });

  if (alert) {
    await alert.present();
  }
};
