import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { timeReload } from 'src/app/utils';

@Component({
  selector: 'app-filter-template-guided-string',
  templateUrl: './filter-template-guided-string.component.html',
  styleUrls: ['./filter-template-guided-string.component.scss'],
})
export class FilterTemplateGuidedStringComponent implements OnInit {
  @Input() company: Company;
  @Input() textLabel: string = 'text';
  @Input() textInput: string = '';
  @Input() textsSearch: string[] = [];

  @Output() changeElement = new EventEmitter<string>();

  textFilter: string[];
  textShower: string[];

  textSelected: string;

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor() {}

  ngOnInit() {
    this.start();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.textsSearch?.currentValue){
      this.start()
    }
  }


  start() {
    this.textFilter = this.textsSearch;
    this.textShower = [];

    if (this.textFilter.length < 10 && this.textFilter.length > 0) {
      this.textShower.push(...this.textFilter.slice(0, this.textFilter.length));
    } else if (this.textFilter.length >= 10) {
      this.textShower.push(...this.textFilter.slice(0, 10));
    }

    if (this.textInput) {
      this.changeTextInput();
    }
  }

  changeTextInput() {
    this.changeElement.emit(null);

    if (!this.textInput) {
      this.start();
    } else {
      this.textShower = [];
      this.textFilter = this.textsSearch.filter((textSearch) =>
        textSearch
          .toString()
          .toLowerCase()
          .trim()
          .includes((this.textInput || '').toString().toLowerCase().trim())
      );
      if (this.textFilter.length < 10) {
        this.textShower.push(
          ...this.textFilter.slice(0, this.textFilter.length)
        );
      } else {
        this.textShower.push(...this.textFilter.slice(0, 10));
      }
    }
  }

  loadData() {
    setTimeout(() => {
      if (this.textShower.length >= this.textFilter.length) {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      let nuevoArr2 = [];
      if (this.textFilter.length < this.textShower.length + 10) {
        nuevoArr2 = this.textFilter.slice(
          this.textShower.length,
          this.textFilter.length
        );
      } else {
        nuevoArr2 = this.textFilter.slice(
          this.textShower.length,
          this.textShower.length + 10
        );
      }
      this.textShower.push(...nuevoArr2);

      this.inifiteScroll.complete();
    }, timeReload);
  }

  changeElementSelected() {
    this.changeElement.emit(this.textSelected);
  }
}
