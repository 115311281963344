import { WritableSignal } from '@angular/core';

// models
import {
  MachineRM,
  RouteDriverRM,
  RouteEditable,
  RouteInfoRM,
  SupervisorRM,
} from '../../models';


export const getRoutePartialSelectedRM = (
  routeSelected: WritableSignal<RouteInfoRM>
) => {

  let routeParticalSelected: RouteEditable = {
    operatorId: routeSelected()?.operator?.operatorId,
    routeNumber: Number(routeSelected()?.routeNumber) || null,
    routeName: routeSelected()?.routeName,
    supervisor: getSupervisorByRouteSelected(routeSelected),
    holderRouteDriver: getHolderRouteDriverByRouteSelected(routeSelected),
    substituteRouteDriver: getSubstituteRouteDriverByRouteSelected(routeSelected),
    tills: getTillsByRouteSelected(routeSelected),
    enabled: routeSelected()?.enabled
  };

  return routeParticalSelected;
};

const getSupervisorByRouteSelected = (
  routeSelected: WritableSignal<RouteInfoRM>
): SupervisorRM => {
  if (routeSelected()?.supervisor?.supervisorId >= 0) {
    return {
      supervisorId: routeSelected()?.supervisor?.supervisorId,
      supervisor: routeSelected()?.supervisor?.supervisor,
    };
  }

  return { supervisorId: null, supervisor: '' };
};

const getHolderRouteDriverByRouteSelected = (
  routeSelected: WritableSignal<RouteInfoRM>
): RouteDriverRM => {
  if (routeSelected()?.routeDrivers?.holder?.routeDriverId >= 0) {
    return {
      enable: routeSelected()?.routeDrivers?.holder?.enable,
      name: routeSelected()?.routeDrivers?.holder?.name,
      routeDriverId: routeSelected()?.routeDrivers?.holder?.routeDriverId,
    };
  }

  return {
    enable: true,
    name: '',
    routeDriverId: null,
  };
};

export const getSubstituteRouteDriverByRouteSelected = (
  routeSelected: WritableSignal<RouteInfoRM>
): RouteDriverRM[] => {
  if ((routeSelected()?.routeDrivers?.substitutes || []).length === 0) {
    return [{ enable: true, name: '', routeDriverId: null }];
  } else {
    return routeSelected()?.routeDrivers?.substitutes;
  }
};

export const getTillsByRouteSelected = ( routeSelected: WritableSignal<RouteInfoRM>): MachineRM[]  => {
  if ((routeSelected()?.machines || []).length === 0) {
    return [];
  }
  return routeSelected()?.machines;
}
