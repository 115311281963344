import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDecimals'
})
export class TwoDecimalsPipe implements PipeTransform {

  transform(value: number): unknown {
    return parseFloat((value).toFixed(2));
  }

}
