import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';

// Service
import { StorageService } from './storage/storage.service';
import { LanguageRouteService } from './';

// Utils
import { imageFlags, isApkAndroid } from '../../utils';
import { MatLanguageService } from './mat-language/mat-language.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public activeLang = 'es-ES';

  languageChange: Subject<string> = new Subject<string>();

  imageFlags: { image: string; locale: string }[] = imageFlags;

  languageActive$: Subscription;

  constructor(
    private platform: Platform,
    private _storageService: StorageService,
    private _languageRouteService: LanguageRouteService
  ) {}

  async startLanguageAsync() {
    let languagesValids: string[] = this.imageFlags.map(
      (imagesFlag) => imagesFlag.locale
    );

    await this.isAPKFirstTime();

    let storageLanguage = await this._storageService.get('language');

    if (storageLanguage && languagesValids.includes(storageLanguage)) {
      this.activeLang = storageLanguage;
    } else if (navigator?.language) {
      this.activeLang = navigator.language;
      let search = true;
      for (let i = 0; i < languagesValids.length && search; i++) {
        if (
          this.activeLang.length >= 2 &&
          this.activeLang[0] == languagesValids[i][0].toLowerCase() &&
          this.activeLang[1] == languagesValids[i][1].toLowerCase()
        ) {
          this.activeLang = languagesValids[i];
          search = false;
        }
      }

      if (search || !languagesValids.includes(this.activeLang || '')) {
        this.activeLang = 'es-ES';
        await this._storageService.set('language', this.activeLang);
      } else {
        await this._storageService.set('language', this.activeLang);
      }
    } else {
      this.activeLang = 'es-ES';
      await this._storageService.set('language', this.activeLang);
    }

    this.languageChange.next(this.activeLang);

    this._languageRouteService.sendChangeLanguage(this.activeLang);

    this.languageActive$ = this._languageRouteService.languageActive.subscribe(
      (value) => {
        this.setLanguage(value);
      }
    );
  }

  setLanguage(language: string) {
    this.activeLang = language;
    this._storageService.set('language', language);
    this.languageChange.next(language);
  }

  getLanguage() {
    return this.activeLang;
  }

  getImage() {
    const { image } = this.imageFlags.find(
      (imageFlag) => imageFlag.locale == this.activeLang
    );
    return image;
  }

  getImagesArray() {
    return this.imageFlags;
  }

  getAlt() {
    const { locale } = this.imageFlags.find(
      (imageFlag) => imageFlag.locale == this.activeLang
    );
    return (locale[0] + locale[1]).toUpperCase();
  }

  destroy() {
    if (this.languageActive$) this.languageActive$.unsubscribe();
  }

  async isAPKFirstTime() {
    const langaugeSelected = await this._storageService.get('language');
    if (isApkAndroid(this.platform) && !langaugeSelected) {
      await this._storageService.set('language', 'es-ES');
    }
  }
}
