export const MAX_LENGTH_MODEL_TILL: number = 50;
export const MAX_LENGTH_VERSION_TILL: number = 50;
export const MAX_LENGTH_SERIAL_NUMBER_TILL: number = 40;
export const MAX_LENGTH_MAC_ADDRESS_TILL: number = 40;
export const MAX_LENGTH_ICCID_TILL: number = 20;
export const MAX_LENGTH_IP_TILL: number = 20;
export const MAX_LENGTH_NOTES_TILL: number = 200;
export const MAX_LENGTH_CODE: number = 10;

// Coin Mech
export const MAX_LENGTH_COIN_MECH_TILL: number = 20;
export const MAX_LENGTH_MANUFACTURER_COIN_MECH_TILL: number = 40;
export const MAX_LENGTH_SERIAL_NUMBER_COIN_MECH_TILL: number = 20;
export const MAX_LENGTH_SW_VERSIION_COIN_MECH_TILL: number = 20;

// Bill Validator
export const MAX_LENGTH_BILL_VALIDATOR_MODEL_TILL: number = 20;
export const MAX_LENGTH_MANUFACTURER_BILL_VALIDATOR_TILL: number = 40;
export const MAX_LENGTH_SERIAL_NUMBER_BILL_VALIDATOR_TILL: number = 20;
export const MAX_LENGTH_SW_VERSION_BILL_VALIDATOR_TILL: number = 20;

// Model Brand
export const MAX_LENGTH_MODEL_TILL_MANAGER: number = 100;
export const MAX_LENGTH_BRAND_TILL_MANAGER: number = 100;

// High Level
export const NAME_HIGH_LEVEL_TILL_ROUTE: string = 'High Level: Create/Edit Till';
export const ROUTE_TILL_MANAGER: string = '/user/data-manager/till-manager'

// Only edit
export const NAME_HIGH_LEVEL_ONLY_EDIT_TILL_MANAGER_ROUTE: string = 'High Level: Edit Till';

// translates
export const ACTIVITIES_TRANSLATES: { [x: string]: string } = {
  'bebida caliente': 'till_manager.activities.hot_drink',
  'bebida fría': 'till_manager.activities.cold_drink',
  'snacks': 'till_manager.activities.snacks',
  'mixta': 'till_manager.activities.mixed',
  'bebida': 'till_manager.activities.drink',
  'tabaco': 'till_manager.activities.tobacco',
  'loader': 'till_manager.activities.loader',
  'horeca': 'till_manager.activities.horeca',
  'fuente agua': 'till_manager.activities.water_fountain',
  'génerico': 'till_manager.activities.generic'
};
