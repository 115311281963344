import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs/internal/Subscription';

// Service
import { SkinMangementService, PermissionService } from '../../../services';
import { VisibilitySelectService } from '../../services';
import { ClientFilterService } from '../filter-client/service/client-filter.service';
import { ClientService } from '../../services/client/client.service';
import { VisibilityClientSelectService } from './service/';

// models
import { Company, HaveVisibility } from 'src/app/models';

// utils
import { pcWidth, UtilsTranslate } from '../../../utils';

@Component({
  selector: 'app-visibility-client-select',
  templateUrl: './visibility-client-select.component.html',
  styleUrls: ['./visibility-client-select.component.scss'],
})
export class VisibilityClientSelectComponent implements OnInit, OnDestroy {

  @Input() haveSend: boolean = true;
  @Input() haveClear: boolean = true;
  @Input() haveDateFilter: boolean = false;
  @Input() loadingSended: boolean = false;

  // fecha creacion y redencion
  @Input() dobleDateFilter:boolean = false;
  @Input() showHour: boolean = false;

  @Output() sendFilter =  new EventEmitter();
  @Output() clearFilter = new EventEmitter();

  // checkqr
  @Input() isCheckQR: boolean = false;
  @Input() statusSelectedTranslate: string = '';
  @Output() filterStatus = new EventEmitter()

  // filter client
  @Input() showCodeClient = true;

  company: Company;
  @Input() pcWidth = pcWidth;

  haveVisibility: HaveVisibility = {client: false, operator: false, operatorGroup: false}
  loadingVisibility: boolean = true;
  canSend: boolean = true;

  operatorId = null;
  operatorGroupId = null;
  showClientFilter = true;

  changeOperator$: Subscription = null;
  changeClientSelected$: Subscription = null;

  haveMore1Client: boolean = false;
  loadingClientMore1: boolean = true;
  getClients$: Subscription;

  startChange$: Subscription = null;

  constructor(
    public readonly platform: Platform,
    private _skinService: SkinMangementService,
    private _permissionService: PermissionService,
    private _visibilityService: VisibilitySelectService,
    private _filterClientService: ClientFilterService,
    private _clientService: ClientService,
    private _visibilityClientSelectService: VisibilityClientSelectService,
    private utils: UtilsTranslate,
  ) { }

  ngOnInit() {

    this.company = this._skinService.getCompany();

    // this._visibilityService.resetParams()

    this.changeClientSelected$ = this._filterClientService.filterChange.subscribe(res => {
      if(res?.haveOneClient || this.haveVisibility.client){
        this.showClientFilter = false;
        this.canSend = true;
      }else{
        this.showClientFilter = true;
        if(!this._filterClientService.getClientId()){
          this.canSend = false;
        }else{
          this.canSend = true;
        }
      }
    })

    this.startChange$ = this._visibilityClientSelectService.reload$.subscribe(res => {
      if(res){
        this.start()
      }
    })
  }

  start(){
    this.getClientVisibility()

    this.getMore1Clients();

    if(!this._visibilityService.getLoadingOperator()){
      this.operatorId = this._visibilityService.getOperatorSelected();
      this.operatorGroupId = this._visibilityService.getOperatorGroupSelected();
      // console.log('operatorGroupId', this.operatorGroupId)
      this._filterClientService.updateFilter(this.operatorId, null, this.operatorGroupId);
    }
  }

  ngOnDestroy(): void {

    if(this.startChange$){
      this.startChange$.unsubscribe();
    }

    if(this.changeClientSelected$){
      this.changeClientSelected$.unsubscribe()
    }

    if(this.changeOperator$){
      this.changeOperator$.unsubscribe()
    }

    if(this.getClients$){
      this.getClients$.unsubscribe()
    }

  }

  getClientVisibility(){
    let loadPermission = this._permissionService.getLoadPermssion();
    if(loadPermission){
      this.changeVisibility()
    }else{
      let i = 0;
      let intervalClient = setInterval(() => {
        let loadPermission = this._permissionService.getLoadPermssion();
        if (loadPermission) {
          this.changeVisibility()
          clearInterval(intervalClient);
        } else if (i == 20) {
          this.loadingVisibility = false;
          this.haveVisibility ={
            client: (this._permissionService.getUserVisibilityByHierarchy('Client') !== null),
            operator: (this._permissionService.getUserVisibilityByHierarchy('Operator') !== null),
            operatorGroup: (this._permissionService.getUserVisibilityByHierarchy('OperatorGroup') !== null)
          }
        }
        i++}, 250)
    }
  }

  changeVisibility(){
    this.loadingVisibility = false;
    this.haveVisibility ={
      client: (this._permissionService.getUserVisibilityByHierarchy('Client') !== null),
      operator: (this._permissionService.getUserVisibilityByHierarchy('Operator') !== null),
      operatorGroup: (this._permissionService.getUserVisibilityByHierarchy('OperatorGroup') !== null)
    }

    if(this.haveVisibility.client){
      this.canSend = true;
      this.showClientFilter = false;
    }

    if(this.haveVisibility.operator && this.changeOperator$){
      this.changeOperator$.unsubscribe();
    }

    if(this.haveVisibility.client){
      this._filterClientService.updateFilter();
    }else if(this.haveVisibility.operator){
      this._filterClientService.updateFilter(this._permissionService.getUserVisibilityByHierarchy('Operator'));
    }else if(this.haveVisibility.operatorGroup){
      this._filterClientService.updateFilter(
        this._permissionService.getUserVisibilityByHierarchy('Operator'),
        );
      this.getChangeOperator()
    } else{
      this._filterClientService.updateFilter(
        this._permissionService.getUserVisibilityByHierarchy('Operator'),
        null,
        this._permissionService.getUserVisibilityByHierarchy('OperatorGroup'),
        );
      this.getChangeOperatorGroup()
    }

  }

  getMore1Clients() {
    this.loadingClientMore1 = true;
    this.getClients$ = this._clientService.getClients().subscribe({next: res => {
      if (res && res.length > 1) {
        this.haveMore1Client = true;
      } else {
        this.haveMore1Client = false;
      }
      this.loadingClientMore1 = false;
    }, error: (error) => {
      this.utils.presentToastTranslate('error', true, 0)
      this.loadingClientMore1 = false;
    }})
  }

  getChangeOperator(){
    this.changeOperator$ = this._visibilityService.filterChange.subscribe(res => {
      if (this.operatorId != res?.operatorSelected) {
        this.operatorId = res?.operatorSelected;
        this._filterClientService.updateFilter(this.operatorId);
      }
    })
  }

  getChangeOperatorGroup(){
    this.changeOperator$ = this._visibilityService.filterChange.subscribe(res => {
      if (this.operatorId != res?.operatorSelected || this.operatorGroupId != res?.operatorGroupSelected) {
        this.operatorId = res?.operatorSelected;
        this.operatorGroupId = res?.operatorGroupSelected
        this._filterClientService.updateFilter(this.operatorId, null, this.operatorGroupId);
      }
    })
  }

  send(){
    this.sendFilter.emit()
  }

  clear(){
    this.clearFilter.emit()
  }

  showStatusFilter(){
    this.filterStatus.emit()
  }

}
