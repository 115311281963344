// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.strong {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/register-cards/components/subtypes/interval-card/confirmations/created-intervals-card-pc/created-intervals-card-pc.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF","sourcesContent":[".strong{\r\n  font-weight: 600;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
