export enum ImageTypes  {
  Banner = 'Banner',
  Splash = 'Splash',
  Header = 'Notification Header',
  Body = 'Notification Body'
}

export enum NotificationType {
  all = 'Todos los usuarios',
  product = 'Producto comprado'
}


export const DIFFS_DAYS_NOTIFICATION: number = 360
