import { InventoryManager } from "../models";


export const getLastInventoryDateInvManager = (inventoriesManager: InventoryManager[]): Date => {

  let lastInventoryDate: Date = null;

  if((inventoriesManager || []).length === 0){
    return null;
  }

  for(let inventoryManager of inventoriesManager){

    if(inventoryManager?.LastUpdatedRealStockDate){
      const lastDatePos =  new Date(inventoryManager.LastUpdatedRealStockDate);
      if(!lastInventoryDate){
        lastInventoryDate = lastDatePos;
      }else if(lastInventoryDate.getTime() < lastDatePos.getTime() ){
        lastInventoryDate = lastDatePos;
      }
    }

  }

  return lastInventoryDate;

}
