import { WritableSignal } from '@angular/core';

// models
import {
  TypesSelectedReport,
  VisibilityValidateCardByTextReport,
} from '../../../../models';

// services
import { FilterReportService } from '../../../../services';

interface SetVisibilityValidateCardByTextReport {
  _filterReportService: FilterReportService;
  visibilityValidateCardText: WritableSignal<VisibilityValidateCardByTextReport>;
}

export const setVisibilityValidateCardByTextReport = ({
  _filterReportService,
  visibilityValidateCardText,
}: SetVisibilityValidateCardByTextReport) => {
  const newVisibility: VisibilityValidateCardByTextReport = {
    operatorGroupId: _filterReportService.getOperatorGroup(
      TypesSelectedReport.createEdit
    )?.ID,
    operatorId: _filterReportService.getOperator(TypesSelectedReport.createEdit)
      ?.id,
    clientId: _filterReportService.getClient(TypesSelectedReport.createEdit)
      ?.ID,
  };

  visibilityValidateCardText.set(newVisibility);
};
