import { TranslateService } from '@ngx-translate/core';

// models
import { DayWeekMachineStatusAlert } from '../../models';

export const getWeekDayOrderByLanguage = async (
  translateService: TranslateService
) => {
  const DAYS_WEEK_ORDER_FIRST_SUNDAY: DayWeekMachineStatusAlert[] = [
    DayWeekMachineStatusAlert.sunday,
    DayWeekMachineStatusAlert.monday,
    DayWeekMachineStatusAlert.tuesday,
    DayWeekMachineStatusAlert.wednesday,
    DayWeekMachineStatusAlert.thursday,
    DayWeekMachineStatusAlert.friday,
    DayWeekMachineStatusAlert.saturday,
  ];

  const DAYS_WEEK_ORDER_FIRST_MONDAY: DayWeekMachineStatusAlert[] = [
    DayWeekMachineStatusAlert.monday,
    DayWeekMachineStatusAlert.tuesday,
    DayWeekMachineStatusAlert.wednesday,
    DayWeekMachineStatusAlert.thursday,
    DayWeekMachineStatusAlert.friday,
    DayWeekMachineStatusAlert.saturday,
    DayWeekMachineStatusAlert.sunday,
  ];

  const firstDay = await translateService.instant('first_day_week');

  return firstDay === '0'
    ? DAYS_WEEK_ORDER_FIRST_SUNDAY
    : DAYS_WEEK_ORDER_FIRST_MONDAY;
};
