// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  text-align: center;
  align-items: center;
  width: 97%;
  border-collapse: collapse;
  height: 88%;
}

/* Zebra striping */
tr:nth-of-type(odd) {
  border: 1px solid var(--ion-color-light-table-zebra);
}

th {
  color: var(--ion-color-light);
  font-weight: bold;
  text-align: center;
}

td,
th {
  padding: 6px;
  border: 1px solid #000;
  text-align: left;
  text-align: center;
}

::ng-deep .width-size-lg {
  --width: 95%;
  --max-width: 400px;
}

::ng-deep ul.ngx-pagination > li:not(.active) > a {
  background-color: var(--ion-color-light);
  border-color: var(--ion-color-medium);
  color: var(--ion-color-dark);
}

.d-center {
  margin: auto;
}

.d-flex-alert {
  display: flex;
  align-items: center;
}

.mb-20 {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/machines/qr-request/components/legend-qr-request/legend-qr-request.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;EACA,UAAA;EACA,yBAAA;EACA,WAAA;AACJ;;AACE,mBAAA;AACA;EACE,oDAAA;AAEJ;;AAAE;EACE,6BAAA;EACA,iBAAA;EACA,kBAAA;AAGJ;;AAAE;;EAEE,YAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;AAGJ;;AACE;EACE,YAAA;EACA,kBAAA;AAEJ;;AACE;EACE,wCAAA;EACA,qCAAA;EACA,4BAAA;AAEJ;;AAEE;EACE,YAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;AACJ;;AAEE;EACE,mBAAA;AACJ","sourcesContent":["table {\r\n    text-align: center;\r\n    align-items: center;\r\n    width: 97%;\r\n    border-collapse: collapse;\r\n    height: 88%;\r\n  }\r\n  /* Zebra striping */\r\n  tr:nth-of-type(odd) {\r\n    border: 1px solid  var(--ion-color-light-table-zebra);\r\n  }\r\n  th {\r\n    color: var(--ion-color-light);\r\n    font-weight: bold;\r\n    text-align: center;\r\n  }\r\n\r\n  td,\r\n  th {\r\n    padding: 6px;\r\n    border: 1px solid #000;\r\n    text-align: left;\r\n    text-align: center;\r\n  }\r\n\r\n\r\n  ::ng-deep .width-size-lg {\r\n    --width: 95%;\r\n    --max-width: 400px;\r\n  }\r\n\r\n  ::ng-deep ul.ngx-pagination > li:not(.active) > a {\r\n    background-color: var(--ion-color-light);\r\n    border-color: var(--ion-color-medium);\r\n    color: var(--ion-color-dark);\r\n  }\r\n\r\n\r\n  .d-center{\r\n    margin: auto;\r\n  }\r\n\r\n  .d-flex-alert{\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n\r\n  .mb-20{\r\n    margin-bottom: 20px;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
