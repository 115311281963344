import { Component, Input, OnInit, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ToastController, IonInfiniteScroll } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Service
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService, VisibilitySelectService } from '../../../services';
import { SkinMangementService } from '../../../../services';
import { ClientFilterService } from '../service/client-filter.service';

// Models
import { Client, Company } from '../../../../models';

// Utils
import { presentToast, successToast, timeReload, type } from '../../../../utils';

@Component({
  selector: 'app-filter-client-modal',
  templateUrl: './filter-client-modal.component.html',
  styleUrls: ['./filter-client-modal.component.scss'],
})
export class FilterClientModalComponent implements OnInit {

  @Input() useFilter = false;

  @Input() useExternalClients = false;
  @Input() clientsExternal: Client[] = [];

  code: string = '';
  name: string = '';

  // aspecto
  company: Company;
  type = type;

  typeFilter = "name";

  clientNameFilter: any[] = [];
  clientSelected = null;

  // todos los clientes
  clientsFull: Client[] = [];
  // clients filtrado
  clients: Client[] = [];

  // translates
  deleteClientFilterTranslate = '';
  errorTranslate = '';

  clientsDB: Client[] = [];
  loadingClient = true;

  changeState$: Subscription;
  changeLanguage$: Subscription;


  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private modalCtrl: ModalController,
    private toastCrl: ToastController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _filterService: ClientFilterService,
    private operatorService: VisibilitySelectService,
    private router: Router
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });

    this.name = this._filterService.getNameClient();
    this.code = this._filterService.getCodeClient();
    this.clientSelected = this._filterService.getClientId();

    if (!this.useExternalClients) {

      if (this.useFilter) {
        this._filterService.startClients(this.operatorService.getOperatorSelected());
      } else {
        this._filterService.startClients();
      }

      if (this._filterService.getLoaded()) {
        this.start();
        if (this.name) {
          this.changeName();
        }
        this.loadingClient = false;
      }


      this.changeState$ = this._filterService.changeState.subscribe(newState => {
        if (this._filterService.getLoaded()) {
          this.start();
          this.loadingClient = false;
        }
      })
    }else{
      this.start()
      this.loadingClient = false;
    }

  }

  ngOnDestroy(){
    if(this.changeLanguage$) this.changeLanguage$.unsubscribe()
    if(this.changeState$) this.changeState$.unsubscribe()
  }

  start() {

    this.clientsFull = (!this.useExternalClients) ? this._filterService.getClients() : this.clientsExternal;
    this.clients = this.clientsFull;
    this.clientNameFilter = [];
    if (this.clients.length < 10) {
      this.clientNameFilter.push(...this.clients.slice(0, this.clients.length));
    } else {
      this.clientNameFilter.push(...this.clients.slice(0, 10));
    }
  }

  changeFilter() {
    if (this.typeFilter === 'name') {
      this.changeName()
    } else {
      this.changeCode();
    }
  }

  changeName() {
    if (!this.name) {
      this.start();
    } else {
      this.clientNameFilter = [];
      this.clients = this.clientsFull.filter(clientFull => clientFull.description?.toLowerCase().trim()
        .includes(this.name?.toLowerCase().trim()));
      if (this.clients.length < 10) {
        this.clientNameFilter.push(...this.clients.slice(0, this.clients.length));
      } else {
        this.clientNameFilter.push(...this.clients.slice(0, 10));
      }
    }
  }

  changeCode() {
    if (!this.code) {
      this.start();
    } else {
      this.clientNameFilter = [];
      this.clients = this.clientsFull.filter(clientFull => clientFull.code?.toLowerCase().trim()
        .includes(this.code?.toLowerCase().trim()));
      if (this.clients.length < 10) {
        this.clientNameFilter.push(...this.clients.slice(0, this.clients.length));
      } else {
        this.clientNameFilter.push(...this.clients.slice(0, 10));
      }
    }
  }

  filterClient() {
    let selectFilter: Client = this.clientsFull.find(clientFull => clientFull.ID == this.clientSelected);
    if (selectFilter) {
      this._filterService.setClient(selectFilter.description, selectFilter.code, selectFilter.ID);
      if (this.modalCtrl) {

        this.modalCtrl.dismiss({
          name: selectFilter.description,
          code: selectFilter.code,
          id: selectFilter.ID
        });
      }
    } else {
      presentToast(this.errorTranslate, this.toastCrl);
    }

  }


  deleteFilterClient() {
    this._filterService.setClient('', '', null);
    if (this.router.url == '/user/access-web-client') this._filterService.clearFilter()
    if (this.modalCtrl) {
      this.modalCtrl.dismiss({
        name: '',
        code: '',
        delete: true
      });
    }
    successToast(this.deleteClientFilterTranslate, this.toastCrl);
  }

  loadData(event) {

    setTimeout(() => {

      if (this.clientNameFilter.length >= this.clients.length) {

        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }


      let nuevoArr2 = [];
      if (this.clients.length < this.clientNameFilter.length + 10) {
        nuevoArr2 = this.clients.slice(this.clientNameFilter.length, this.clients.length);
      } else {
        nuevoArr2 = this.clients.slice(this.clientNameFilter.length, this.clientNameFilter.length + 10);
      }
      this.clientNameFilter.push(...nuevoArr2);

      this.inifiteScroll.complete();
    }, timeReload);

  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }


  savePlaceholders() {
    this.translate.get(['transaction_query.carousel-filter.client_deleted', 'error']).subscribe((translates) => {
      this.deleteClientFilterTranslate = translates['transaction_query.carousel-filter.client_deleted'];
      this.errorTranslate = translates['error'];
    })
  }

}
