// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.height-100 {
  height: 100%;
}

.pdf-sm-margin {
  width: 100vw;
  height: calc(100vh - 200px);
  margin-top: 60px;
}

.pdf-sm {
  width: 100vw;
  height: calc(100vh - 200px);
}

.pdf-pc {
  height: calc(100vh - 400px);
}

.pdf-pc2 {
  height: calc(100vh - 400px);
}

.pdf-pc3 {
  height: calc(100vh - 400px);
}

.h-100vh {
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/modal-pdf/modal-pdf.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACE,YAAA;EACA,2BAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,2BAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAGA;EACE,2BAAA;AAAF;;AAGA;EACE,aAAA;AAAF","sourcesContent":[".height-100 {\r\n    height: 100%\r\n}\r\n\r\n.pdf-sm-margin{\r\n  width: 100vw;\r\n  height: calc(100vh - 200px);\r\n  margin-top: 60px;\r\n}\r\n\r\n.pdf-sm{\r\n  width: 100vw;\r\n  height: calc(100vh - 200px);\r\n}\r\n\r\n.pdf-pc{\r\n  height: calc(100vh - 400px);\r\n}\r\n\r\n.pdf-pc2{\r\n  height: calc(100vh - 400px);\r\n}\r\n\r\n\r\n.pdf-pc3{\r\n  height: calc(100vh - 400px);\r\n}\r\n\r\n.h-100vh{\r\n  height: 100vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
