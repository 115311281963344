import { PositionWeek } from "../../models";

interface FindPositionWeek {
  positionsWeek: PositionWeek[];
  shortDate: number;
  day: number;
}

export const findPositionWeek = ({
  shortDate,
  day,
  positionsWeek,
}: FindPositionWeek) => {
  const positionWeek = positionsWeek.find(
    (positionWeekPos) =>
      positionWeekPos.shortDate === shortDate && positionWeekPos.day === day
  );

  if (positionWeek) {
    return positionWeek.position;
  }

  return null;
};
