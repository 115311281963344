// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-time {
  border: 1px var(--ion-color-select-shadow) solid;
  border-radius: 5px;
  padding: 2px;
}

.font-sm {
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-date-template/components/share/filter-hour/pc/filter-hour-picker/filter-hour-picker.component.scss"],"names":[],"mappings":"AAAA;EACE,gDAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".border-time{\r\n  border:  1px var(--ion-color-select-shadow) solid;\r\n  border-radius: 5px;\r\n  padding: 2px;\r\n}\r\n\r\n.font-sm{\r\n  font-size: 0.9em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
