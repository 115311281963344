import { getLastDayWithInfo } from '../date-helpers';

interface LabelOrder {
  locale: string;
  monthsTranslates: string[];
  currentMonth: number;
  i: number;
}

export const getLabelsMonthsByYearly = (
  locale: string,
  monthsTranslates: string[]
) => {
  const currentMonth = getLastDayWithInfo().getMonth();

  return new Array(13)
    .fill(null)
    .map((_, i) =>
      getLabelOrder({ currentMonth, i, locale, monthsTranslates })
    );
};

export const getLabelOrder = ({
  currentMonth,
  i,
  locale,
  monthsTranslates,
}: LabelOrder) => {
  return locale === 'en-EN'
    ? `${getYearLabel(i)}, ${monthsTranslates[(currentMonth + i) % 12]}`
    : `${monthsTranslates[(currentMonth + i) % 12]} ${getYearLabel(i)}`;
};

export const getYearLabel = (position: number) => {
  const currentMonth = getLastDayWithInfo().getMonth();
  const currentYear = getLastDayWithInfo().getFullYear();

  return currentMonth + position + 1 > 12 ? currentYear : currentYear - 1;
};
