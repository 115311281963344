// External Module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Modules
// import { FilterDateModule } from '../filter-date/filter-date-module';
import { HeaderModalModule } from '../header-modal/header-modal.modal';

// Components
import { FilterClientModalComponent } from './filter-client-modal/filter-client-modal.component';
import { FilterClientComponent } from './filter-client.component';

// Services
import { LanguageService } from '../../services/languages.service';




@NgModule({
    declarations: [
      FilterClientModalComponent,
      FilterClientComponent
    ],
    imports: [
      CommonModule,
      // FilterDateModule,
      FormsModule,
      IonicModule,
      ReactiveFormsModule,
      TranslateModule,
      HeaderModalModule
    ],
    providers: [
      LanguageService,
    ],
    exports: [
      FilterClientModalComponent,
      FilterClientComponent
    ]
  })
  export class FilterClientModule { }
