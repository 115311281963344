import { Component, OnInit, Input } from '@angular/core';

// Models
import { ColorTheme } from 'src/app/models';
import { CheckFileAena } from '../../models';

@Component({
  selector: 'app-send-files-aena-state',
  templateUrl: './send-files-aena-state.component.html',
  styleUrls: ['./send-files-aena-state.component.scss'],
})
export class SendFilesAenaStateComponent  implements OnInit {

  @Input() checkState: CheckFileAena;
  @Input() loadingState: boolean = true;
  @Input() colorTheme: ColorTheme = 'light';

  constructor() { }

  ngOnInit() {
  }

}
