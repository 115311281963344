import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// services
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from 'src/app/services';
import { FilterDateInModalService, MatLanguageService } from 'src/app/share/services';

// models
import { Company } from 'src/app/models';
import { RangeDate } from '../../../models';

// utils
import { addDays } from 'src/app/utils';
import { DIFF_DAYS_REPORT_MODAL } from '../../../helpers';

@Component({
  selector: 'app-filter-date-modal-hour',
  templateUrl: './filter-date-modal-hour.component.html',
  styleUrls: ['./filter-date-modal-hour.component.scss'],
})
export class FilterDateModalHourComponent implements OnInit {

  company: Company;
  startDate: string;
  startHour: string;
  datePipe: string;

  rangeDate: RangeDate = {
    minDate: new Date(),
    maxDate: addDays(DIFF_DAYS_REPORT_MODAL),
  };

  filterChange$: Subscription;


  constructor(
    private translate: TranslateService,
    private _skinMangementService: SkinMangementService,
    public _filterDateService: FilterDateInModalService,
    private _matLanguageService: MatLanguageService
  ) {
    this.datePipe = this.translate.instant('format_pipe_without_hour');
  }

  ngOnInit() {
    this.company = this._skinMangementService.getCompany();
    this.startDate = this._filterDateService.getDateStart();
    this.startHour = this._filterDateService.getStartHour();

    this.getLanguage();

    this.filterChange$ = this._filterDateService.filterChange$.subscribe(() => {
      this.startDate = this._filterDateService.getDateStart();
      this.startHour = this._filterDateService.getStartHour();
    })
  }

  ngOnDestroy(): void {
    if(this.filterChange$) this.filterChange$.unsubscribe()
  }

  async getLanguage() {
    const locale = await this.translate.instant('locale');
    this._matLanguageService.setLanguage(locale);
  }

  changeDate(startDate: Date) {
    if (!startDate) {
      return;
    }

    this._filterDateService.setDate(startDate, 0);
  }

  setHourStart(hour){
    this._filterDateService.setHour(hour, 0)
  }

}
