// services
import { TranslateService } from '@ngx-translate/core';
import { InfoInventoryManagerService } from '../../services';

interface GetAlertQuestionInvManager {
  _translate: TranslateService;
  _infoService: InfoInventoryManagerService;
}

const TRANSLATE_PATH = 'inventory_manager.force_inventory.';
export const getAlertQuestionInvManager = async ({
  _translate,
  _infoService,
}: GetAlertQuestionInvManager): Promise<string> => {
  if (_infoService.isSupervisorFilter()) {
    const supervisor =
      _infoService.visibilitySelected()?.supervisor?.name || '';
    return await _translate.instant(`${TRANSLATE_PATH}supervisor_question`, {
      supervisor,
    });
  }

  if (_infoService.isClientFilter()) {
    const client = _infoService.visibilitySelected()?.client?.name || '';
    return await _translate.instant(`${TRANSLATE_PATH}client_question`, {
      client,
    });
  }

  const machine = (
    _infoService.visibilitySelected()?.machine?.code || ''
  ).toString();
  return await _translate.instant(`${TRANSLATE_PATH}machine_question`, {
    machine,
  });
};
