import { WritableSignal } from '@angular/core';

// models
import { Client, Operator } from 'src/app/models';

interface SetClientsByOperatorSelectedRM {
  operatorSelected: WritableSignal<Operator>;
  clients: WritableSignal<Client[]>;
  clientsByOperator: WritableSignal<Client[]>;
  clientAssigmentSelected: WritableSignal<Client>;
}

export const setClientsByOperatorSelectedRM = ({
  operatorSelected,
  clients,
  clientsByOperator,
  clientAssigmentSelected,
}: SetClientsByOperatorSelectedRM) => {
  if (!operatorSelected) {
    return;
  }

  const clientsByOperatorSelected = clients().filter(
    (client) => client?.OperatorId === operatorSelected()?.id
  );

  clientsByOperator.set(clientsByOperatorSelected);
  clientAssigmentSelected.set(null);
};
