import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  WritableSignal,
} from '@angular/core';

// models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-filter-input-search-share',
  templateUrl: './filter-input-search-share.component.html',
  styleUrls: ['./filter-input-search-share.component.scss'],
})
export class FilterInputSearchShareComponent implements OnInit {
  @Input() company: Company;

  @Input() search: WritableSignal<string>;
  @Input() textSearch: string;

  @Output() changeSearch = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  changeSearchHandle(event) {
    this.search.set(event);
    this.changeSearch.emit();
  }
}
