import { FilterDateFutureService } from "src/app/share/services";
import { getMinDateAndMaxDateMachineAlert } from '../get-min-date';

export const removeDateMachineAlert = (
  _filterDateService: FilterDateFutureService,
) => {
  const {minDate} = getMinDateAndMaxDateMachineAlert()
  _filterDateService.setDate(minDate, 0)
  _filterDateService.setDate(minDate, 1)
}
