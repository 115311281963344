// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Component
import { FooterSaveModalComponent } from './footer-save-modal.component';

// Service
import { LanguageService } from 'src/app/share/services';
import { SkinMangementService } from 'src/app/services';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule],
  declarations: [FooterSaveModalComponent],
  providers: [LanguageService, SkinMangementService],
  exports: [FooterSaveModalComponent],
})
export class FooterSaveModalModule {}
