import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';

// config
import { AppConfig } from 'src/app/config/config';

@Injectable({
  providedIn: 'root',
})
export class PingService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient) {}

  ping() {
    let endpoint = `ping`;

    return this.httpClient.get<LoopbackPing>(this.serviceUrl + endpoint).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        let messageError = !error?.error?.error?.message
          ? '504 Gateway timeout'
          : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }
}

interface LoopbackPing {
  greeting: string;
  date: Date;
  url: string;
  headers: {
    accept: string;
    host: string;
    'user-agent': string,
    'accept-encoding': string,
    'max-forwards': string,
    'postman-token': string,
    'x-arr-log-id': string
    'client-ip': string,
    'disguised-host': string,
    'x-site-deployment-id': string,
    'was-default-hostname': string,
    'x-forwarded-proto': string,
    'x-appservice-proto': string,
    'x-arr-ssl': string,
    'x-forwarded-tlsversion': string,
    'x-forwarded-for': string,
    'x-original-url': string,
    'x-waws-unencoded-url': string,
    'x-client-ip': string,
    'x-client-port': string,
  };
}
