import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { FixMenuService } from 'src/app/share/services';

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {

  // modificar nombre menu
  profileMenu: Subject<ProfileMenu> = new Subject<ProfileMenu>();

  // se realiza login actualizar rutas y nombre
  loginMenu: Subject<boolean> = new Subject<boolean>();

  // se ha clicado logout eliminar logout
  logoutMenu: Subject<boolean> = new Subject<boolean>();

  started = false;

  changeFixMenu: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _fixMenu: FixMenuService
  ) { }

  changeProfile(name, surname){
    this.profileMenu.next({name, surname})
  }

  newLogin(){
    if(!this.started){
      this.started = true;
      this.loginMenu.next(false)
    }else{
      this.loginMenu.next(true);
    }

  }

  removeIdle(){
    this.logoutMenu.next(true)
  }

  setStarted(started){
    this.started = started;
  }

  sendChangeFixMenu(isFixMenu: boolean){
    this.changeFixMenu.next(isFixMenu);
  }

  getFixMenu(){
    return this._fixMenu.getMenuFix()
  }

}

interface ProfileMenu {
  name: string,
  surname: string
}
