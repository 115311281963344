import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// Model
import { Operator } from 'src/app/models/Operator.model';

// Service
import { VisibilitySelectService } from '../../services/visibility-select/visibility-select.service';


@Component({
  selector: 'app-filter-operator-individual',
  templateUrl: './filter-operator-individual.component.html',
  styleUrls: ['./filter-operator-individual.component.scss'],
})
export class FilterOperatorIndividualComponent implements OnInit {


  @Input() showSelect = true;
  @Input() allOptionDisabled:boolean = false

  operators: Operator[] = [];
  loadingOperators: boolean = true;
  operatorSelected: number = null;

  filterChange$: Subscription;

  constructor(private _visibilityService: VisibilitySelectService) { }

  ngOnInit() {
    this.operators = this._visibilityService.getOperators();
    this.operators = (this.allOptionDisabled) ? this.operators.filter(op => op.id != -1): this.operators
    this.operatorSelected = this._visibilityService.getOperatorSelected();
    this.loadingOperators = this._visibilityService.getLoadingOperator();

    this.filterChange$ = this._visibilityService.filterChange.subscribe(res => {
      if(res){
        this.operators = this._visibilityService.getOperators();
        this.operators = (this.allOptionDisabled) ? this.operators.filter(op => op.id != -1): this.operators
        this.operatorSelected = this._visibilityService.getOperatorSelected();
        this.loadingOperators = this._visibilityService.getLoadingOperator();
      }
    })
  }

  ngOnDestroy(): void {
    if(this.filterChange$) this.filterChange$.unsubscribe()
  }

  changeOperatorSel(newOperatorSelected: number) {
    this.operatorSelected = newOperatorSelected;
    this._visibilityService.setOperatorSelected(this.operatorSelected);
    this._visibilityService.changeFilter();
  }

  changeOperatorSelected(newOperator: number) {
    this.changeOperatorSel(newOperator);
    this._visibilityService.changeFilter();
  }


}
