// models
import { MachineAlertInfo, MachineAlertShowInfo } from '../../models';

// utils
import {
  ACTIVITY_STATUS_ALERT_NAME_TRANSLATE,
  PERFOMANCE_TYPE_ALERT,
  STATUS_MACHINE_ALERT,
} from '../constant';
import { getWeekdayInfoMachineAlert } from '../weekdays/get-weekday-info-machine-alert';
import { getTypeActivationSelectedByResend } from '../resend';

export const getMachinesAlertShowInfo = (
  machinesAlerts: MachineAlertInfo[],
  firstDayWeekTranslate: string
): MachineAlertShowInfo[] => {
  let machinesInfo: MachineAlertShowInfo[] = [];

  for (let machineAlert of machinesAlerts) {
    const machineInfo = getMachineAlertShowInfo(machineAlert, firstDayWeekTranslate);
    machinesInfo.push(machineInfo);
  }

  return machinesInfo;
};

const getMachineAlertShowInfo = (
  machineAlert: MachineAlertInfo,
  firstDayWeekTranslate: string
): MachineAlertShowInfo => {
  // machineAlert.weekDays
  return {
    alertId: machineAlert.alertID,
    emails: machineAlert.Emails,
    minDefaultPeriod: getMinDefaultPeriod(machineAlert),
    enable: machineAlert.Enable === 1,
    statusAlert: {
      id: machineAlert.StatusTillAlertID,
      statusTranslate: getStatusTranstate(
        (machineAlert?.StatusTillAlert || '').toLowerCase()
      ),
      status: machineAlert?.StatusTillAlert,
    },
    client: { id: machineAlert.ClientID, description: machineAlert.client },
    machine: { id: machineAlert.machineID, code: machineAlert.machine },
    dateSelected: getDateSselected(machineAlert),
    intervalTimes: getIntervalTimes(machineAlert),
    selected: false,
    typeActivationSelected: getTypeActivationSelectedByResend(machineAlert),
    weekDays: getWeekdayInfoMachineAlert(
      machineAlert.week_days.map((weekDay) =>
        (weekDay.WeekDay || '').toLowerCase()
      ),
      firstDayWeekTranslate
    ),
  };
};

const getMinDefaultPeriod = (
  machineAlert: MachineAlertInfo
): { hour: number; minute: number } => {
  return {
    hour: machineAlert.CronHourTime,
    minute: machineAlert.CronMinuteTime,
  };
};

const getDateSselected = (
  machineAlert: MachineAlertInfo
): { start: Date; end: Date } => {
  return {
    start: new Date(machineAlert.AvailableDateIni),
    end: machineAlert.AvailableDateEnd
      ? new Date(machineAlert.AvailableDateEnd)
      : null,
  };
};

const getIntervalTimes = (
  machineAlert: MachineAlertInfo
): { startTime: string; endTime: string }[] => {
  let intervalTimes: { startTime: string; endTime: string }[] = [];
  for (let machineIntervalTime of machineAlert.IntervalTimes) {
    const intervalTime: { startTime: string; endTime: string } = {
      startTime: getIntervalTime(machineIntervalTime.StartTime),
      endTime: getIntervalTime(machineIntervalTime.EndTime),
    };

    intervalTimes.push(intervalTime);
  }

  return intervalTimes;
};

const getIntervalTime = (time: string): string => {
  const timeSelected = time.split('T')?.[1];
  return `${timeSelected?.[0] || '0'}${timeSelected?.[1] || '0'}:${
    timeSelected?.[3] || '0'
  }${timeSelected?.[4] || '0'}`;
};

const getStatusTranstate = (status: string) => {
  if (STATUS_MACHINE_ALERT?.[status]) {
    return STATUS_MACHINE_ALERT[status];
  }

  if (
    (status || '').toLowerCase() ===
    PERFOMANCE_TYPE_ALERT.haveBankSales.toLowerCase()
  ) {
    return ACTIVITY_STATUS_ALERT_NAME_TRANSLATE;
  }

  return '';
};
