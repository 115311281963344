import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

// Services
import { PermissionService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class MinCompanyVisibilityGuard  {


  constructor(
    private _permissionService: PermissionService,
    public _router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._permissionService.getMinCompanyVisibility();
  }

}
