import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, throwError } from 'rxjs';

// models
import { Supervisor } from 'src/app/models';
import { CreateSupervisorParams, EditSupervisorParams } from './models';

// utils
import { SUPERVISOR_MANAGER_ROUTE } from './utils';

// config
import { AppConfig } from 'src/app/config/config';


@Injectable({
  providedIn: 'root',
})
export class SupervisorService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient, protected router: Router) {}

  getSupervisors(params: { OperatorID?: number; SupervisorID?: number }) {
    let endpoint = 'getsupervisors';
    let routeUrl = this.router.url;

    const isEditSupervisor = routeUrl === SUPERVISOR_MANAGER_ROUTE;

    return this.httpClient
      .post<Supervisor[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          if(!isEditSupervisor){
            res = res.filter(supervisor => supervisor.Enable === 1)
          }
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  createSupervisor(params: CreateSupervisorParams) {
    let endpoint = 'supervisor/create';
    let routeUrl = this.router.url;

    return this.httpClient
      .post<Supervisor>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  editSupervisor(params: EditSupervisorParams) {
    let endpoint = 'supervisor/edit';
    let routeUrl = this.router.url;

    return this.httpClient
      .post<Supervisor>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  deleteSupervisor(params: { supervisorID: number }) {
    let endpoint = 'supervisor/delete';
    let routeUrl = this.router.url;

    return this.httpClient
      .post<void>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}
