import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateStringLanguage'
})
export class DateStringLanguagePipe implements PipeTransform {

  constructor(private datePipe: DatePipe){}

  transform(value: string | Date, pipeLocale: string): string {

    if(!value){
      return '';
    }

    let dateSelected: Date;
    if(value instanceof Date){
      dateSelected = value;
    }else{
      dateSelected = new Date(value.toString().replace(/-/g, "/"));
      let userTimezoneOffset = Math.trunc(dateSelected.getTimezoneOffset() / 60);
      dateSelected =  new Date(dateSelected.setHours(dateSelected.getHours() - userTimezoneOffset));
    }
    return this.datePipe.transform(dateSelected, pipeLocale, 'utc')
  }

}
