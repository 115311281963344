import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Component
import { CardClientWithNewClient } from '../../interval-card-pc/interval-card-pc.component';

// Service
import { SkinMangementService } from '../../../../../../../../../services/skin-mangement.service';

// Models
import { CardNames, Company } from '../../../../../../../../../models/';

// Modal para mostrar las tarjetas asignadas
@Component({
  selector: 'app-assigned-interval-card-pc',
  templateUrl: './assigned-interval-card-pc.component.html',
  styleUrls: ['./assigned-interval-card-pc.component.scss'],
})
export class AssignedIntervalCardPcComponent implements OnInit {
  company: Company;

  @Input() cardsWithClients: CardClientWithNewClient[] = [];
  @Input() notExistCards: string[] = [];
  @Input() cardsWithoutClients: string[] = [];

  cardsOrderClient = {};

  cardNames = CardNames;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    for (let cardWithClient of this.cardsWithClients) {
      if (!this.cardsOrderClient[cardWithClient.newClient]) {
        this.cardsOrderClient[cardWithClient.newClient] = [];
      }
      this.cardsOrderClient[cardWithClient.newClient].push(cardWithClient);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  changeClient() {
    this.modalCtrl.dismiss({
      changeClient: true,
    });
  }
}
