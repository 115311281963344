import { Component, Input, OnInit } from '@angular/core';

// Models
import { ImageManagerSO } from '../../../pages/user/application-management/image-manager/models';

@Component({
  selector: 'app-icon-system-operative',
  templateUrl: './icon-system-operative.component.html',
  styleUrls: ['./icon-system-operative.component.scss'],
})
export class IconSystemOperativeComponent implements OnInit {

  @Input() SO: ImageManagerSO;
  @Input() haveText: boolean = true;

  constructor() { }

  ngOnInit() {}

}
