import { WritableSignal } from "@angular/core";
import { isTypeExcelValid, UtilsTranslate } from ".";

// -------------------------------- Excel --------------------------------
interface OnFileSelectedExcel{
  event: any,
  file: WritableSignal<File>,
  utils: UtilsTranslate
}

export const onFileSelectedExcel = (
  {
    event, file, utils
  }: OnFileSelectedExcel
) => {
  try {
    file.set(null);

    if (event?.target?.files.length === 0) {
      return;
    }

    const fileEv: File = event?.target?.files[0];
    const mimeType = fileEv.type;

    if (!isTypeExcelValid(mimeType)) {
      utils.presentToastTranslate('send_files_aena.card.invalid_format');
      return;
    }

    file.set(fileEv);
  } catch (error) {
    utils.showError();
  }
}
