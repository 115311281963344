// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.strong {
  font-weight: bold;
}

.text-indent-20 {
  text-indent: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/route-management/machines-module/components/modals/legend-machines-module/legend-machines-module.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".strong{\r\n  font-weight: bold;\r\n}\r\n\r\n.text-indent-20{\r\n  text-indent: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
