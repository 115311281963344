// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-indent-10 {
  text-indent: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/listings/reports/components/modals/invalid-cards-by-text-report-modal/invalid-cards-by-text-report-modal.component.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;AAAF","sourcesContent":["\r\n.text-indent-10{\r\n  text-indent: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
