export const MAX_LENGTH_CITY_OPERATOR: number = 200;
export const MAX_LENGTH_REFERENCE_OPERATOR: number = 150;
export const MAX_LENGTH_NAME_OPERATOR: number = 200;
export const MAX_LENGTH_CIF_OPERATOR: number = 20;
export const MAX_LENGTH_EMAIL_OPERATOR: number = 50;
export const MAX_LENGTH_ADDRESS_OPERATOR: number = 100;
export const MAX_LENGTH_WEBPAGE_OPERATOR: number = 50;


export const NAME_HIGH_LEVEL_OPERATOR_ROUTE: string = 'High level: Create/Edit Operator';
export const ROUTE_OPERATOR_MANAGER: string = '/user/data-manager/operator-manager'
