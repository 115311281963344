import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { PermissionService } from 'src/app/services';

// Utils
import { AENA_REGEN_FILES2, AENA_REGEN_FILE_ROUTE } from '../../share/utils';

@Injectable({ providedIn: 'root' })
export class SendFilesAenaResolverService  {
  constructor(private _permissionService: PermissionService) {}

  resolve(): Observable<boolean> {
    return this._permissionService.getAllPermissions().pipe(
      map((res) => {
        const haveFile2 = res.find(
          (permission) => permission.route === AENA_REGEN_FILE_ROUTE && permission.name === AENA_REGEN_FILES2
        );
        return !!haveFile2;
      })
    );
  }
}
