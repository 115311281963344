import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

// Utils
import { sleep } from 'src/app/utils';


@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  isLoading = false;

  constructor(
    private loadingController: LoadingController
  ) {}

  async present() {
    this.isLoading = true;
    return await this.loadingController
      .create({
        // duration: 5000,
      })
      .then((a) => {
        if (a) {
          a.present().then(() => {
            if (!this.isLoading) {
              a.dismiss().then(() => {});
            }
          });
        }
      });
  }

  async dismiss() {
    this.isLoading = false;
    try {
      await this.loadingController.dismiss().then(() => {});
      await sleep(200);
      const exist = await this.exist();
      if (exist) {
        this.dismiss();
      }
    } catch (error) {

    }
  }

  async close() {
    this.isLoading = false;
    await this.loadingController.dismiss().then(() => {});
  }

  exist() {
    return this.loadingController.getTop();
  }

  async presentWithMessage(message: string){
    this.isLoading = true;
    return await this.loadingController
      .create({
        message
      })
      .then((a) => {
        if (a) {
          a.present().then(() => {
            if (!this.isLoading) {
              a.dismiss().then(() => {});
            }
          });
        }
      });
  }
}
