// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-center {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/register-cards/components/subtypes/unlinked-card/unlinked-card-by-client/unlinked-card-by-client.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF","sourcesContent":[".d-center{\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
