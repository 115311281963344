// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border {
  border-bottom: 1px solid var(--ion-color-dark);
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.border-dark {
  border: 1px solid var(--ion-color-medium);
  box-shadow: 0px 0px 17px 2px rgba(128, 128, 128, 0.8);
}

.border2 {
  border-bottom: 1px solid var(--ion-color-dark);
}

.quantity {
  margin-top: 1.1rem;
  font-size: 3.2em;
}

.title-card {
  margin-top: 1.1rem;
  font-size: 1.2em;
}

.pb-5 {
  padding-bottom: 50px;
}

@media (max-width: 992px) and (min-width: 769px) {
  .quantity {
    margin-top: 1rem;
    font-size: 3em;
  }
  .title-card {
    margin-top: 1rem;
    font-size: 1em;
  }
}
@media (max-width: 768px) {
  .quantity {
    margin-top: 0.5rem;
    font-size: 2.5em;
  }
  .title-card {
    margin-top: 0.5rem;
    font-size: 0.8em;
  }
  .text-button {
    font-size: 0.8em;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/webload/load-credit/component/load-credit-card/load-credit-card.component.scss"],"names":[],"mappings":"AACE;EACE,8CAAA;EACA,mGAAA;AAAJ;;AAGE;EACE,yCAAA;EACA,qDAAA;AAAJ;;AAGE;EACE,8CAAA;AAAJ;;AAIE;EACE,kBAAA;EACA,gBAAA;AADJ;;AAIE;EACE,kBAAA;EACA,gBAAA;AADJ;;AAIE;EACE,oBAAA;AADJ;;AAIE;EACE;IACE,gBAAA;IACA,cAAA;EADJ;EAIE;IACE,gBAAA;IACA,cAAA;EAFJ;AACF;AAKE;EACE;IACE,kBAAA;IACA,gBAAA;EAHJ;EAME;IACE,kBAAA;IACA,gBAAA;EAJJ;EAME;IACE,gBAAA;EAJJ;AACF","sourcesContent":["\r\n  .border{\r\n    border-bottom: 1px solid var(--ion-color-dark);\r\n    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;\r\n  }\r\n\r\n  .border-dark{\r\n    border: 1px solid var(--ion-color-medium);\r\n    box-shadow: 0px 0px 17px 2px rgb(128,128,128, 0.8);\r\n  }\r\n\r\n  .border2{\r\n    border-bottom: 1px solid var(--ion-color-dark);\r\n  }\r\n\r\n\r\n  .quantity{\r\n    margin-top: 1.1rem;\r\n    font-size: 3.2em;\r\n  }\r\n\r\n  .title-card{\r\n    margin-top: 1.1rem;\r\n    font-size: 1.2em;\r\n  }\r\n\r\n  .pb-5{\r\n    padding-bottom: 50px;\r\n  }\r\n\r\n  @media (max-width: 992px) and (min-width: 769px) {\r\n    .quantity{\r\n      margin-top: 1rem;\r\n      font-size: 3em;\r\n    }\r\n\r\n    .title-card{\r\n      margin-top: 1rem;\r\n      font-size: 1em;\r\n    }\r\n  }\r\n\r\n  @media (max-width: 768px) {\r\n    .quantity{\r\n      margin-top: 0.5rem;\r\n      font-size: 2.5em;\r\n    }\r\n\r\n    .title-card{\r\n      margin-top: 0.5rem;\r\n      font-size: 0.8em;\r\n    }\r\n    .text-button{\r\n      font-size: 0.8em;\r\n    }\r\n  }\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
