import { WritableSignal } from '@angular/core';
import { lastValueFrom } from 'rxjs';

// models
import { CardNames, Company } from 'src/app/models';
import { EditReportParams } from '../../models';

// services
import { LoadingService } from 'src/app/share/services';
import { ReportsService } from '../../services';

// utils
import { errorsTypes, UtilsTranslate } from 'src/app/utils';

interface EditReport {
  company: Company;
  _loadingService: LoadingService;
  _reportService: ReportsService;
  params: EditReportParams;
  utils: UtilsTranslate;
  success: WritableSignal<boolean>;
  isLoadingDisplayed: boolean;
}

export const editReport = async ({
  company,
  _loadingService,
  _reportService,
  params,
  utils,
  success,
  isLoadingDisplayed,
}: EditReport) => {

  const cardNames = CardNames;

  if (!isLoadingDisplayed) {
    _loadingService.present();
  }

  try {
    await lastValueFrom(_reportService.editReport(params));
    utils.presentToastTranslate(
      'reports.table.report_edit_successfully',
      false,
      0
    );
    // this.reloadReport.emit();
    success.set(true);
    _loadingService.dismiss();
  } catch (error) {
    if (error === errorsTypes.error_type_not_card_report) {
      utils.presentToastTranslate(
        company.nameCard === cardNames.arbitradePay
          ? 'reports.create_edit.not_card_type_alliancepay'
          : 'reports.create_edit.not_card_type_close_loop_card'
      );
    }
    else if(error === errorsTypes.error_client_required_report){
      utils.presentToastTranslate('reports.create_edit.client_required')
    }
    else {
      utils.presentToastTranslate('reports.table.report_edit_error', true, 0);
    }
    //this.reloadReport.emit();
    success.set(false);
    _loadingService.dismiss();
  }
};
