import {
  DIMENSION_SHOW_ONE_CHART,
  DIMENSION_SHOW_ONE_CHART_MENU,
} from '../constants';

const CLASS_NAMES = {
  center: 'd-flex-center-100',
  not_center: 'd-flex',
};

export const getClassNamesPositionFilterArticle = (
  width: number,
  isOpenFixMenu: boolean
): string => {
  if (isOpenFixMenu && width <= DIMENSION_SHOW_ONE_CHART_MENU) {
    return CLASS_NAMES.center;
  }

  if (!isOpenFixMenu && width <= DIMENSION_SHOW_ONE_CHART) {
    return CLASS_NAMES.center;
  }

  return CLASS_NAMES.not_center;
};
