// models
import { FilterTableTillAlert, TillAlertSpiderTable } from '../../models';

// utils
import { getTillAlertsByFilter } from '.';

export const getTillAlertFilterEmail = (
  tillsAlerts: TillAlertSpiderTable[],
  filtersTable: FilterTableTillAlert
) => {
  let filterTableWithTypeFilter = getTillAlertsByFilter(
    tillsAlerts,
    filtersTable,
    'email'
  );

  return Array.from(new Set(filterTableWithTypeFilter.map((till) => till.email)));
};
