import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError} from 'rxjs/operators';
import { throwError } from 'rxjs';

// config
import { AppConfig } from 'src/app/config/config';

// Models
import {
  ClientGroup,
  ParamsClientGroup,
} from 'src/app/models/client-group.model';
import {
  ParamsCreateClientGroup,
  ParamsEditClientGroup,
} from '../../../pages/user/data-manager/client-group-manager/models';

@Injectable({
  providedIn: 'root',
})
export class ClientGroupService {
  serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient, protected router: Router) {}

  getClientGroups(params: ParamsClientGroup = {}, highLevel: boolean = false) {
    let endpoint = `client-group/get-client-group`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<ClientGroup[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          res.map((clientGroup) => {
            clientGroup.description =
              clientGroup?.description || clientGroup?.name;
          });
          if (!highLevel) {
            res = res.filter((clientGroup) => clientGroup.is_valid === 1);
          }
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  createClientGroup(params: ParamsCreateClientGroup) {
    let endpoint = `client-group`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<ClientGroup[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  editClientGroup(params: ParamsEditClientGroup) {
    let endpoint = `editClientGroup`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<ClientGroup[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}
