import { WritableSignal } from '@angular/core';

// models
import { DayWeekMachineStatusAlert } from '../../models';

// utils
import { getCountDayWeekSelectedsMachineAlert } from '../date';

interface CheckEventWeekDayMachineAlert {
  daysWeek: WritableSignal<Record<DayWeekMachineStatusAlert, boolean>>;
  daysWeekOrder: DayWeekMachineStatusAlert[];
  isIndeterminate: WritableSignal<boolean>;
  masterCheck: WritableSignal<boolean>;
}

export const checkEventWeekDayMachineAlert = ({
  daysWeek,
  daysWeekOrder,
  isIndeterminate,
  masterCheck,
}: CheckEventWeekDayMachineAlert) => {
  const totalItems = Object.keys(daysWeekOrder).length;
  const checked = getCountDayWeekSelectedsMachineAlert(daysWeek);

  if (checked > 0 && checked < totalItems) {
    isIndeterminate.set(true);
    masterCheck.set(false);
  } else if (checked == totalItems) {
    masterCheck.set(true);
    isIndeterminate.set(false);
  } else {
    isIndeterminate.set(false);
    masterCheck.set(false);
  }
};
