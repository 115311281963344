// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-weight-bold {
  text-indent: 1.5em;
  font-weight: bold;
}

.text {
  font-size: 18px;
}

.rounded {
  --border-radius: 50px ;
}

.box-shadow-card {
  box-shadow: 0px 0px 3px 1px var(--ion-color-shop-online-border-card);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/subsidies/components/legend-subsidies/legend-subsidies.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AACA;EACE,sBAAA;AAEF;;AACA;EACE,oEAAA;AAEF","sourcesContent":[".font-weight-bold{\r\n    text-indent: 1.5em;\r\n    font-weight: bold;\r\n}\r\n\r\n.text{\r\n    font-size: 18px;\r\n}\r\n.rounded{\r\n  --border-radius: 50px\r\n}\r\n\r\n.box-shadow-card{\r\n  box-shadow: 0px 0px 3px 1px  var(--ion-color-shop-online-border-card);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
