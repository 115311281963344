import { FilterStatusOptionsReport } from "../../models";

interface GetFiltersActivesReport{
  haveFilterVisibility: boolean,
  statusSelected: FilterStatusOptionsReport,
  periodSelected: number,
}

export const getFiltersActivesReport = (
  {haveFilterVisibility, statusSelected, periodSelected }: GetFiltersActivesReport
): number => {

  let filtersActive: number = 0;

  if(haveFilterVisibility){
    filtersActive++;
  }

  if(statusSelected !== null && statusSelected !== undefined){
    filtersActive++;
  }

  if(periodSelected !== null && periodSelected !== undefined){
    filtersActive++;
  }

  return filtersActive;
}
