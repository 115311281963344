import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// config
import { AppConfig } from 'src/app/config/config';

// Service
import { PermissionService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class MinOperatorVisibilityGuard  {

  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private _permissionService: PermissionService,
    public _router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this._permissionService.getMinOperatorVisibility();
  }

}
