import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';

// service
import { TranslateService } from '@ngx-translate/core';

// models
import { Company } from 'src/app/models';
import { TranslateByDictionaryPipe } from 'src/app/share/pipes';

// utils
import { timeReload } from 'src/app/utils';

@Component({
  selector: 'app-filter-template-guided-string-dictionary',
  templateUrl: './filter-template-guided-string-dictionary.component.html',
  styleUrls: ['./filter-template-guided-string-dictionary.component.scss'],
})
export class FilterTemplateGuidedStringDictionaryComponent implements OnInit {
  @Input() company: Company;
  @Input() textLabel: string = 'text';
  @Input() textInput: string = '';
  @Input() untranslatedTextsSearch: string[] = [];
  @Input() translates: { [x: string]: string } = {};

  @Input() numElementShower: number = 50;

  @Input() textSelected: string = 'text';

  @Output() changeElement = new EventEmitter<string>();

  textsSearch: TextSearchTemplate[] = [];
  textFilter: TextSearchTemplate[];
  textShower: TextSearchTemplate[];

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private _translateService: TranslateService,
    private translateByDictionary: TranslateByDictionaryPipe
  ) {}

  ngOnInit() {
    this.getTranslates();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.textsSearch?.currentValue) {
      this.start();
    }
  }

  start() {
    this.textFilter = this.textsSearch;
    this.textShower = [];

    if (
      this.textFilter.length < this.numElementShower &&
      this.textFilter.length > 0
    ) {
      this.textShower.push(...this.textFilter.slice(0, this.textFilter.length));
    } else if (this.textFilter.length >= this.numElementShower) {
      this.textShower.push(...this.textFilter.slice(0, this.numElementShower));
    }

    if (this.textInput) {
      this.changeTextInput();
    }
  }

  changeTextInput() {
    this.changeElement.emit(null);

    if (!this.textInput) {
      this.start();
    } else {
      this.textShower = [];

      this.textFilter = this.textsSearch.filter((textSearch) =>
        textSearch.textTranslate
          .toString()
          .toLowerCase()
          .trim()
          .includes((this.textInput || '').toString().toLowerCase().trim())
      );
      if (this.textFilter.length < this.numElementShower) {
        this.textShower.push(
          ...this.textFilter.slice(0, this.textFilter.length)
        );
      } else {
        this.textShower.push(
          ...this.textFilter.slice(0, this.numElementShower)
        );
      }
    }
  }

  loadData() {
    setTimeout(() => {
      if (this.textShower.length >= this.textFilter.length) {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      let nuevoArr2 = [];
      if (this.textFilter.length < this.textShower.length + 10) {
        nuevoArr2 = this.textFilter.slice(
          this.textShower.length,
          this.textFilter.length
        );
      } else {
        nuevoArr2 = this.textFilter.slice(
          this.textShower.length,
          this.textShower.length + 10
        );
      }
      this.textShower.push(...nuevoArr2);

      this.inifiteScroll.complete();
    }, timeReload);
  }

  changeElementSelected() {
    this.changeElement.emit(this.textSelected);
  }

  async getTranslates() {
    this.textsSearch = [];

    await this.untranslatedTextsSearch.forEach(async (text) => {
      const textTranslate = await this.getTextTranslate(text);
      const textWithTranslate: TextSearchTemplate = {
        text,
        translate: this.getTranslate(text),
        textTranslate,
      };

      this.textsSearch.push(textWithTranslate);
    });

    setTimeout(() => {
      this.textsSearch.sort((a,b)=> a.textTranslate.localeCompare(b.textTranslate));
      this.start();
    }, 100);
  }

  getTranslate(text: string) {
    return (
      this.translateByDictionary.transform(
        text.toLowerCase(),
        this.translates
      ) || (text || '').toUpperCase()
    );

  }

  async getTextTranslate(text: string) {

    if (this.translates?.[text.toLowerCase()]) {
      return await this._translateService.instant(
        this.translates[text.toLowerCase()]
      );
    }

    return text;
  }
}

interface TextSearchTemplate {
  text: string;
  translate: string;
  textTranslate: string;
}
