import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { Subscription, lastValueFrom } from 'rxjs';

// Component
import {
  CreatedIntervalCardPcComponent,
  AssignedIntervalCardPcComponent,
} from '../confirmations/';
import { ModalSelectClientComponent } from '../../../../../../../../share/components/modals/modal-select-client/modal-select-client.component';

// Service
import { TranslateService } from '@ngx-translate/core';
import {
  LoadingService,
  VisibilitySelectService,
  LanguageRouteService,
} from '../../../../../../../../share/services';
import { CardLengthRegisterService } from '../../../../services/card-length-register.service';
import {
  RegisterCardInterval,
  RegisterCardsService,
  AssignIntervalCardParams,
  CardClient,
} from '../../../../../../../../share/services/register-cards/register-cards.service';
import { ColorModeService } from '../../../../../../../../services/color-mode.service';

// Models
import { IntervalCard } from '../../../../models';
import { CardNames, Company } from '../../../../../../../../models';
import { ParamsUnlinkClientCard } from '../../../../models';

// Utils
import {
  presentToast,
  UtilsTranslate,
  errorsTypes,
  isNumber,
  ModalGenerator,
  replaceVariableText,
  showModal,
  durationErrorToast,
  durationSuccessToast,
} from '../../../../../../../../utils';
import { MAX_INTERVAL_CARDS } from '../../../../utils';
import { ComponentProps } from '@ionic/core';
import { CreatedIntervalsCardPcComponent } from '../confirmations/created-intervals-card-pc/created-intervals-card-pc.component';

@Component({
  selector: 'app-interval-card-pc',
  templateUrl: './interval-card-pc.component.html',
  styleUrls: ['./interval-card-pc.component.scss'],
})
export class IntervalCardPcComponent implements OnInit {
  @Input() company: Company;
  @Input() intervalCards: IntervalCard[] = [];

  @Input() segment: string = '0';
  @Input() segmentType: string = '0';

  @Output() addInterval = new EventEmitter<number>();
  @Output() removeInterval = new EventEmitter<number>();

  // error translates
  firstColumnRequiredTranslate = '';
  hasNumberTranslate = '';
  diffLengthTranslate = '';
  diffDiffTranslate = '';
  diffLessZeroTranslate = '';
  min6Translate = '';
  maxErrorTranslate = '';
  repeatCardTranslate = '';

  showSelectOperator = false;

  loadingInterval = false;

  showError = false;

  errorColor = false;

  operatorId = null;

  minCard: number = 6;
  maxCard: number = 8;

  useFontSm = 1650;

  cardNames = CardNames;

  changeLangauge$: Subscription;
  filterChange$: Subscription;

  constructor(
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _visibitilySelect: VisibilitySelectService,
    private utils: UtilsTranslate,
    private _registerCardsService: RegisterCardsService,
    private _loadingService: LoadingService,
    private _cardLengthService: CardLengthRegisterService,
    private _colorModeService: ColorModeService,
    private modalGenerator: ModalGenerator
  ) {}

  ngOnInit() {
    this.changeLangauge$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    this.minCard = this._cardLengthService.getMinLengthCard();
    this.maxCard = this._cardLengthService.getMaxLengthCard();

    this.filterChange$ = this._visibitilySelect.filterChange.subscribe(
      (res) => {
        if (res && res?.operatorSelected && res?.operatorSelected != -1) {
          this.showSelectOperator = false;
          this.operatorId = res.operatorSelected;
          if (this.errorColor) {
            this.errorColor = false;
          }
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.segment || changes?.segmentType) {
      this.intervalCards = [{ posStart: '', posEnd: '' }];
      this.showError = false;
    }
  }

  ngOnDestroy(): void {
    if (this.changeLangauge$) this.changeLangauge$.unsubscribe();
    if (this.filterChange$) this.filterChange$.unsubscribe();
  }

  handleAddInterval(pos: number) {
    //this.addInterval.emit(pos)
    this.intervalCards.splice(pos + 1, 0, { posStart: '', posEnd: '' });
  }

  handleRemoveInterval(pos: number) {
    //this.removeInterval.emit(pos)
    this.intervalCards.splice(pos, 1);
  }

  validateInput(
    start: string,
    end: string
  ): { error1: boolean; error2: boolean; message: string } {
    // 6 caracteres
    if (
      (start && start.length < this.minCard) ||
      (end && end.length < this.minCard)
    ) {
      return {
        error1: start && start.length < this.minCard,
        error2: end && end.length < this.minCard,
        message: this.min6Translate,
      };
    }

    // 8 o 9 caracteres segun company
    if (
      (start && start.length > this.maxCard) ||
      (end && end.length > this.maxCard)
    ) {
      return {
        error1: start && start.length > this.maxCard,
        error2: end && end.length > this.maxCard,
        message: this.maxErrorTranslate,
      };
    }

    // intervalo sin el primer valor
    if (!start && end) {
      return {
        error1: true,
        error2: false,
        message: this.firstColumnRequiredTranslate,
      };
    }

    // intervalo sin numeros
    if (start && end && (!isNumber(start) || !isNumber(end))) {
      return {
        error1: !isNumber(start),
        error2: !isNumber(end),
        message: this.hasNumberTranslate,
      };
    }

    // intervalo misma longitud
    if (start && end && start.length != end.length) {
      return { error1: false, error2: true, message: this.diffLengthTranslate };
    }

    // diferencia superior a 500 numeros
    if (start && end && Number(end) - Number(start) > MAX_INTERVAL_CARDS) {
      return { error1: false, error2: true, message: this.diffDiffTranslate };
    }

    // segundo valor del intervalo inferior
    if (start && end && Number(end) - Number(start) < 0) {
      return {
        error1: true,
        error2: false,
        message: this.diffLessZeroTranslate,
      };
    }

    return { error1: false, error2: false, message: '' };
  }

  savePlaceholders() {
    this.translate
      .get([
        'register_cards.interval_pc.first_interval_required',
        'register_cards.interval_pc.be_number',
        'register_cards.interval_pc.same_length',
        'register_cards.interval_pc.diff_100',
        'register_cards.interval_pc.diff_0',
        'register_cards.select_pc.min_card_length',
        'register_cards.select_pc.max_card_length',
        'register_cards.interval_pc.duplicated_card',
        'register_cards.interval_pc.diff_max',
      ])
      .subscribe((translates) => {
        this.firstColumnRequiredTranslate =
          translates['register_cards.interval_pc.first_interval_required'];
        this.hasNumberTranslate =
          translates['register_cards.interval_pc.be_number'];
        this.diffLengthTranslate =
          translates['register_cards.interval_pc.same_length'];

        this.diffDiffTranslate = replaceVariableText(
          translates['register_cards.interval_pc.diff_max'] || '',
          MAX_INTERVAL_CARDS.toString(),
          'MAX_INTERVAL_CARDS'
        );

        this.diffLessZeroTranslate =
          translates['register_cards.interval_pc.diff_0'];
        this.min6Translate = this.getMinTranslate(
          translates['register_cards.select_pc.min_card_length'],
          this.minCard
        );
        this.maxErrorTranslate = this.getMaxTranslate(
          translates['register_cards.select_pc.max_card_length'],
          this.maxCard
        );
        this.repeatCardTranslate =
          translates['register_cards.interval_pc.duplicated_card'];
      });
  }

  getMinTranslate(minTranslate, min) {
    return minTranslate.replace('{{min}}', min);
  }

  getMaxTranslate(maxTranslate, max) {
    return maxTranslate.replace('{{max}}', max);
  }

  getRepeatCardTranslate(repeat) {
    return this.repeatCardTranslate.replace('{{repeat}}', repeat);
  }

  inputFocus() {
    let operatorSelected = this._visibitilySelect.getOperatorSelected();
    if (!operatorSelected || operatorSelected == -1) {
      this.showSelectOperator = true;
    } else {
      this.showSelectOperator = false;
    }
  }

  async showSelectClient(pos: number) {
    // let modal = await this.modalCtrl.create({
    //   component: ModalSelectClientComponent,
    //   cssClass: 'modal-border-radius',
    // });

    const data = await this.modalGenerator.show(ModalSelectClientComponent);
    if (data?.client) {
      this.intervalCards[pos].client = data.client;
    }
  }

  async sendInterval() {
    this.showError = true;

    let intervalValids: IntervalCard[] = [];

    for (let interval of this.intervalCards) {
      let { message } = this.validateInput(interval.posStart, interval.posEnd);
      if (message) {
        return;
      }
      if (!interval.posEnd && interval.posStart)
        intervalValids.push({ ...interval, posEnd: interval.posStart });
      else if (interval.posStart) intervalValids.push(interval);
    }

    if (intervalValids.length == 0) {
      this.utils.presentToastTranslate(
        'register_cards.interval_pc.error_interval_empty',
        true,
        0
      );
      return;
    }

    let { repeat, valueRepeat } = this.intervalRepeat(intervalValids);
    if (repeat) {
      this.utils.presentToastWithoutTranslate(this.getRepeatCardTranslate(valueRepeat))
      return;
    }

    if (this.segment == '0') {
      this.register(intervalValids);
    } else if (this.segment === '1') {
      // Vincultar clientes
      this.linkCard(intervalValids);
    } else {
      // Desvincular clientes
      this.unlinkedClientCard(intervalValids);
    }
  }

  async register(intervalValids: IntervalCard[]) {
    if (this.showSelectOperator || !this.operatorId || this.operatorId == -1) {
      this.errorColor = true;
      return;
    }
    this.loadingInterval = true;
    this._loadingService.present();
    let existCreated = false;

    intervalValids = intervalValids.map(({notCreateds, ...interval}) => interval )

    for (let interval of intervalValids) {
      delete interval?.notCreateds;
      let params: RegisterCardInterval = {
        Operator_id: this._visibitilySelect.getOperatorSelected(),
        VT_interval1: interval.posStart.trim(),
        VT_interval2: interval.posEnd.trim(),
      };
      try {
        let registerCard = await lastValueFrom(this._registerCardsService
          .registerCardInterval(params))

        if (registerCard?.not_created.length > 0) {
          this.loadingInterval = false;
          interval.notCreateds = registerCard?.not_created;
          if (!existCreated) existCreated = true;
          break;
        }else{
          interval.notCreateds = []
        }
      } catch (error) {
        if (error == errorsTypes.all_exist_card) {
          interval.notCreateds = ['all'];
          if (!existCreated) existCreated = true;
        } else {
          this.loadingInterval = false;
          this._loadingService.dismiss();
          this.utils.presentToastTranslate('error', true, 0);
          return;
        }
      }
    }



    if (existCreated) {
      // this.showCreatedInterval(intervalValids);
      this.showCreatedIntervals(intervalValids)
    } else {
      this.utils.presentToastTranslate(
        this.company.nameCard == this.cardNames.arbitradePay
          ? 'register_cards.selected_interval.created_alliancepay'
          : 'register_cards.selected_interval.created_v2v',
        false,
        0
      );

      if (!this.showSelectOperator) {
        this._visibitilySelect.resetParams();
      }
      this.intervalCards = [{ posStart: '', posEnd: '' }];
    }

    this.loadingInterval = false;
    this._loadingService.dismiss();
  }

  async linkCard(intervalValids: IntervalCard[]) {
    // test client
    for (let intervalPos of intervalValids) {
      if (!intervalPos?.client) {
        return;
      }
    }
    this.loadingInterval = true;
    this._loadingService.present();
    let cardsWithClients: CardClientWithNewClient[] = [];
    let notExistCards: string[] = [];
    let cardsWithoutClients: number[] = [];
    // check
    try {
      for (let intervalPos of intervalValids) {
        let params: AssignIntervalCardParams = {
          Client_id: intervalPos?.client?.ID,
          VT_interval1: intervalPos.posStart.trim(),
          VT_interval2: intervalPos.posEnd
            ? intervalPos.posEnd.trim()
            : intervalPos.posStart.trim(),
        };

        let resCheck = await lastValueFrom(this._registerCardsService
          .checkCardList(params))

        if (resCheck?.cards_with_clients.length > 0) {
          let cardsWithNewClient: CardClientWithNewClient[] = [];
          for (let cardWithClient of resCheck?.cards_with_clients) {
            cardsWithNewClient.push({
              ...cardWithClient,
              newClient: intervalPos?.client?.description,
            });
          }
          cardsWithClients.push(...cardsWithNewClient);
        }
        if (resCheck.not_exist_cards.length > 0) {
          notExistCards.push(...resCheck.not_exist_cards);
        }
        if (resCheck.cards_without_clients.length > 0) {
          cardsWithoutClients.push(...resCheck.cards_without_clients);
        }
      }
    } catch (error) {
      this._loadingService.dismiss();
      this.loadingInterval = false;
      this.utils.presentToastTranslate('error', true, 0);
      return;
    }

    try {
      if (cardsWithClients.length > 0 || notExistCards.length > 0) {
        this._loadingService.dismiss();
        await this.showAssignedInterval(
          cardsWithClients,
          notExistCards,
          intervalValids,
          cardsWithoutClients
        );
        this.loadingInterval = false;
      } else {
        await this.changeClients(intervalValids);
      }
    } catch (error) {
      this._loadingService.dismiss();
    }
  }

  async unlinkedClientCard(intervalValids: IntervalCard[]) {
    this.loadingInterval = true;
    this._loadingService.present();

    try {
      for (let intervalPos of intervalValids) {
        let params: ParamsUnlinkClientCard = {
          VT_interval1: intervalPos.posStart.trim(),
          VT_interval2: intervalPos.posEnd
            ? intervalPos.posEnd.trim()
            : intervalPos.posStart.trim(),
        };
        await lastValueFrom(this._registerCardsService.unlinkCLientCard(params))
      }

      this.utils.presentToastTranslate(
        'register_cards.select_pc.unlinked_text_successfuly',
        false,
        0
      );
      this.intervalCards = [{ posStart: '', posEnd: '' }];
      this._loadingService.dismiss();
      this.loadingInterval = false;
    } catch (error) {
      if (error === errorsTypes.incorrect_interval_card) {
        this.utils.presentToastCardNameTranslate(
          this.company.nameCard === this.cardNames.arbitradePay
            ? 'register_cards.select_pc.unlinked_interval_not_exist1'
            : 'register_cards.select_pc.unlinked_interval_not_exist2',
          true,
          0,
          durationErrorToast,
          true
        );
      } else {
        this.utils.showError();
      }
      this._loadingService.dismiss();
      this.loadingInterval = false;
    }
  }

  clearInterval() {
    this.intervalCards = [{ posStart: '', posEnd: '' }];
  }

  async showCreatedInterval(intervals: IntervalCard[]) {
    const isLightColor = this._colorModeService.getIsLightColor();

    const modal = await this.modalCtrl.create({
      component: CreatedIntervalCardPcComponent,
      componentProps: {
        intervals,
      },
      cssClass: `modal-border-radius ${
        !isLightColor ? 'modal-border-dark' : ''
      }`,
    });
    if (modal) await modal.present();

    await modal.onWillDismiss();

    // Si no crea ninguno no elimina el operador
    let someNotCreated = false;
    // console.log('intervals', intervals)
    for (let i = 0; i < intervals.length && !someNotCreated; i++) {
      if (intervals[i]?.notCreateds) {
        someNotCreated = true;
      }
    }

    if (someNotCreated) {
      return;
    }

    if (!this.showSelectOperator) {
      this._visibitilySelect.resetParams();
    }

    this.intervalCards = [{ posStart: '', posEnd: '' }];
  }

  async showCreatedIntervals(intervals: IntervalCard[]){

    const props: ComponentProps = {
      intervals
    }

    await showModal(this.modalCtrl, CreatedIntervalsCardPcComponent,  props,
      '',
      this.platform.width())

  }

  async showAssignedInterval(
    cardsWithClients: CardClientWithNewClient[],
    notExistCards: string[],
    intervalValids: IntervalCard[],
    cardsWithoutClients: number[]
  ) {
    const isLightColor = this._colorModeService.getIsLightColor();

    const modal = await this.modalCtrl.create({
      component: AssignedIntervalCardPcComponent,
      componentProps: {
        cardsWithClients,
        notExistCards,
        intervalValids,
        cardsWithoutClients,
      },
      cssClass: `modal-border-radius ${
        !isLightColor ? 'modal-border-dark' : ''
      }`,
    });

    if (modal) {
      await modal.present();
      const { data } = await modal.onWillDismiss();

      if (data?.changeClient) {
        this.loadingInterval = true;
        // this._loadingService.present();
        await this.changeClients(intervalValids);
      }
    }
  }

  async changeClients(intervalValids: IntervalCard[]) {
    this.loadingInterval = true;
    this._loadingService.present();
    let showErrorNotCreated = false;
    try {
      for (let intervalPos of intervalValids) {
        let params: AssignIntervalCardParams = {
          Client_id: intervalPos?.client?.ID,
          VT_interval1: intervalPos.posStart.trim(),
          VT_interval2: intervalPos.posEnd
            ? intervalPos.posEnd.trim()
            : intervalPos.posStart.trim(),
        };

        let assigCardInterval = await lastValueFrom(this._registerCardsService
          .assignCardInterval(params))

        if (
          assigCardInterval?.not_exist_cards &&
          assigCardInterval?.not_exist_cards.length > 0
        ) {
          showErrorNotCreated = true;
        }
      }

      this.loadingInterval = false;
      this._loadingService.dismiss();
      this.showError = false;

      if (!showErrorNotCreated) {
        this.utils.presentToastCardNameTranslate(
          this.company.nameCard == this.cardNames.arbitradePay
            ? 'register_cards.selected_interval.assigned_alliancepay'
            : 'register_cards.selected_interval.assigned_v2v',
          false,
          0,
          durationSuccessToast,
          true
        );

        if (!this.showSelectOperator) {
          this._visibitilySelect.resetParams();
        }

        this.intervalCards = [{ posStart: '', posEnd: '' }];
      } else {
        this.utils.presentToastTranslate('error', true, 0);
      }
    } catch (error) {
      this._loadingService.dismiss();
      this.utils.presentToastTranslate('error', true, 0);
      this.loadingInterval = false;
    }
  }

  // si hay algun valor del intervalo repetido
  intervalRepeat(
    intervalValid: IntervalCard[]
  ): { repeat: boolean; valueRepeat: string } {
    let repeat = false;
    let valueRepeat = '';
    for (let i = 0; i < intervalValid.length && !repeat; i++) {
      for (let j = i + 1; j < intervalValid.length && !repeat; j++) {
        if (
          isNumber(intervalValid[i].posStart) &&
          isNumber(intervalValid[i].posEnd) &&
          isNumber(intervalValid[j].posStart) &&
          isNumber(intervalValid[j].posEnd)
        ) {
          // console.log('i', intervalValid[i].posStart, intervalValid[i].posEnd, 'j', intervalValid[j].posStart, intervalValid[j].posEnd)
          // console.log('comparacion start', (intervalValid[j].posStart >= intervalValid[i].posStart
          //   && intervalValid[j].posStart <= intervalValid[i].posEnd),
          //   intervalValid[j].posStart >= intervalValid[i].posStart,
          //   intervalValid[j].posStart <= intervalValid[i].posEnd
          // )
          // console.log('comparacion end', (intervalValid[j].posEnd >= intervalValid[i].posStart
          //   && intervalValid[j].posEnd <= intervalValid[i].posEnd),
          //   intervalValid[j].posEnd >= intervalValid[i].posStart,
          //   intervalValid[j].posEnd <= intervalValid[i].posEnd,
          // )

          if (
            intervalValid[i].posStart == intervalValid[j].posStart ||
            (intervalValid[i].posStart >= intervalValid[j].posStart &&
              intervalValid[i].posStart <= intervalValid[j].posEnd)
          ) {
            valueRepeat = intervalValid[i].posStart;
            repeat = true;
          } else if (
            intervalValid[i].posEnd == intervalValid[j].posEnd ||
            (intervalValid[i].posEnd >= intervalValid[j].posStart &&
              intervalValid[i].posEnd <= intervalValid[j].posEnd)
          ) {
            valueRepeat = intervalValid[i].posEnd;
            repeat = true;
          }
        } else if (intervalValid[i].posStart == intervalValid[j].posStart) {
          valueRepeat = intervalValid[i].posStart;
          repeat = true;
        }
      }
    }
    if (intervalValid.length >= 2) {
      let lastInterval = intervalValid[intervalValid.length - 1];
      for (let i = 0; i < intervalValid.length - 1 && !repeat; i++) {
        if (
          lastInterval.posStart == intervalValid[i].posStart ||
          (lastInterval.posStart >= intervalValid[i].posStart &&
            lastInterval.posStart <= intervalValid[i].posEnd)
        ) {
          valueRepeat = lastInterval.posStart;
          repeat = true;
        } else if (
          lastInterval.posEnd == intervalValid[i].posEnd ||
          (lastInterval.posEnd >= intervalValid[i].posStart &&
            lastInterval.posEnd <= intervalValid[i].posEnd)
        ) {
          valueRepeat = lastInterval.posEnd;
          repeat = true;
        }
      }
    }
    return { repeat, valueRepeat };
  }
}

export interface CardClientWithNewClient extends CardClient {
  newClient: string;
}
