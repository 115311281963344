import { Injectable } from "@angular/core";

import { Observable } from 'rxjs';
import {  map } from 'rxjs/operators';

// services
import { PermissionService } from '../../../../../services/permission.service';

// utils
import { NAMEASSIGNCARD, NAMEREGISTERCARD, NAMEREGISTERCARDANDASSIGN, ROUTEREGISTERCARD } from "src/app/utils";

@Injectable({providedIn: 'root'})
export class RegisterCardResolverService {

    constructor(private _permissionService: PermissionService) {}

    resolve(): Observable<{assign: boolean, create: boolean ,is8: boolean, is9: boolean}>{
        return this._permissionService
                .getAllPermissions()
                    .pipe(
                        map(res =>
                            (
                               {
                                   assign: !!(res.find((permission) =>
                                        permission?.route === ROUTEREGISTERCARD
                                        &&
                                        (
                                            permission?.name.includes(NAMEREGISTERCARDANDASSIGN)
                                            || permission?.name.includes(NAMEASSIGNCARD)
                                        ))),
                                    create: !!(res.find((permission) =>
                                        permission?.route === ROUTEREGISTERCARD
                                        &&
                                        (
                                            permission?.name.includes(NAMEREGISTERCARDANDASSIGN)
                                            || permission?.name.includes(NAMEREGISTERCARD)
                                        ))),
                                    is8: !!(res.find((permission) =>
                                          permission?.route === ROUTEREGISTERCARD
                                          &&
                                          (
                                            permission?.name === 'High Level:Alta Alliance (6-8 dígitos)'
                                            || permission?.name === 'Low Level:Alta Alliance (6-8 dígitos)'
                                            || permission?.name === 'Mid Level: Alta Alliance (6-8 dígitos)'
                                          ))),
                                    is9: !!(res.find((permission) =>
                                            permission?.route === ROUTEREGISTERCARD
                                          &&
                                          (
                                            permission?.name === 'High Level:Alta Alliance (6-9 dígitos)'
                                            || permission?.name === 'Mid Level: Alta Alliance (6-9 dígitos)'
                                            || permission?.name === 'Low Level:Alta Alliance (6-9 dígitos)'
                                          ))),
                                  }
                            )
                        )
                    );
     }

}
