export interface DataManagerRoutesTranslates {
  operatorGroup: string,
  operator: string,
  clientGroup: string,
  client: string,
  site: string,
  till: string,
  article: string,
  supervisor: string,
  routeDriver: string,
  route: string
}

export const getDataManagerRoutes = (
  translates: DataManagerRoutesTranslates
): { route: string; name: string }[] => {
  return [
    {
      route: '/user/data-manager/operator-group-manager',
      name: translates.operatorGroup,
    },
    {
      route: '/user/data-manager/operator-manager',
      name: translates.operator,
    },
    {
      route: '/user/data-manager/client-group-manager',
      name: translates.clientGroup,
    },
    {
      route: '/user/data-manager/client-manager',
      name: translates.client,
    },
    { route: '/user/data-manager/site-manager', name: translates.site },
    { route: '/user/data-manager/till-manager', name: translates.till },
    {
      route: '/user/data-manager/article-manager',
      name: translates.article,
    },
    {
      route: '/user/data-manager/supervisor-manager',
      name: translates.supervisor,
    },
    {
      route: '/user/data-manager/routeDriver-manager',
      name: translates.routeDriver,
    },
    {
      route: '/user/data-manager/route-manager',
      name: translates.route,
    },
  ]
}
