import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

// Services
import { SkinMangementService } from 'src/app/services';
import { FilterTillMappingService } from '../../../services/filter-till-mapping.service';

// Models
import { Company } from 'src/app/models/';

// Utils
import { pcWidth, pcWidthMix } from 'src/app/utils/constants';

@Component({
  selector: 'app-legend-till-mapping',
  templateUrl: './legend-till-mapping.component.html',
  styleUrls: ['./legend-till-mapping.component.scss'],
})
export class LegendTillMappingComponent implements OnInit {

  company: Company;
  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;

  isComplex: boolean = false;

  constructor(
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
    private _filterTillMappingService: FilterTillMappingService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
    this.isComplex = this._filterTillMappingService.getIsComplex()
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }
}
