import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

// services
import { PermissionService } from 'src/app/services';

@Injectable({
  providedIn: 'root',
})
export class RouteDriverManagerGuard {
  constructor(private _permissionService: PermissionService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._permissionService.havePermissionRouteDriverManagerValid();
  }
}
