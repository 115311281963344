// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item ion-label {
  overflow: visible;
}

.font-sm {
  font-size: 0.8em;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/modals/info-selected-header/info-selected-header.component.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF","sourcesContent":["\r\nion-item ion-label {\r\n  overflow: visible;\r\n}\r\n\r\n.font-sm{\r\n  font-size: 0.8em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
