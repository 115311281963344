import { Component, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';
import { Options } from 'ngx-slider-v2';
import { Company } from 'src/app/models';

@Component({
  selector: 'app-slider-step-translate',
  templateUrl: './slider-step-translate.component.html',
  styleUrls: ['./slider-step-translate.component.scss'],
})
export class SliderStepTranslateComponent implements OnInit {

  @Input() company: Company;
  @Input() posActive: number = 0;
  @Input() floor = 0;
  @Input() ceil = 4;
  @Input() translatesByPos: Record<number, string> = {};

  endValue: number = null;
  options: Options = {
    floor: 0,
    ceil: 4,
    disabled: true,
    showTicksValues: true,
    translate: (pos: number) => pos.toString(),
  };

  // reload translates
  changeInfoSlider: boolean = false;

  constructor(private elem: ElementRef) {}

  ngOnInit() {
    this.setColorVariables();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.options.floor !== this.floor ||
      this.options.ceil !== this.ceil ||
      changes?.translatesByPos?.currentValue !==
        changes.translatesByPos?.previousValue
    ) {
      this.options.floor = this.floor;
      this.options.ceil = this.ceil;

      this.options.translate = (pos: number) => {
        return this.translatesByPos?.[pos] || '';
      };

      this.changeInfoSlider = true;
      setTimeout(() => {
        this.changeInfoSlider = false;
      }, 1)
    }
  }

  setColorVariables() {
    this.elem.nativeElement.style.setProperty(
      '--color-ball',
      this.company.colorBallRange
    );
    this.elem.nativeElement.style.setProperty(
      '--color-line',
      this.company.colorLineRange
    );
    this.elem.nativeElement.style.setProperty(
      '--color-inside-ball',
      this.company.colorInsideBallRange
    );
    this.elem.nativeElement.style.setProperty(
      '--color-inside-ball-active',
      this.company.colorInsideBallRangeActive
    );
  }

}
