import { Component, OnInit, Input } from '@angular/core';
import { Platform } from '@ionic/angular';

// charts
import { ChartConfiguration, ChartOptions, Plugin } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

// services
import { SkinMangementService } from 'src/app/services';

// models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-canvas-ng2-chart',
  templateUrl: './canvas-ng2-chart.component.html',
  styleUrls: ['./canvas-ng2-chart.component.scss'],
})
export class CanvasNg2ChartComponent implements OnInit {
  // Title
  @Input() title = '';
  @Input() loaded: boolean = true;

  // Charts
  @Input() type: ChartConfiguration['type'] = 'bar';
  @Input() labels: BaseChartDirective['labels'] = [];
  @Input() datasets: ChartConfiguration['data']['datasets'] = [];
  @Input() options: ChartOptions = {};
  @Input() legend: boolean = true;
  @Input() plugins: Plugin[] = [];
  @Input() data: ChartConfiguration['data'] = null;

  // styles
  @Input() heightVh: number = 60;
  @Input() centerChart: boolean = false;
  @Input() marginTopSpinner: number = 0;

  company: Company;

  constructor(
    public readonly platform: Platform,
    private skinService: SkinMangementService
  ) {}

  ngOnInit() {
    this.company = this.skinService.getCompany();
    if(!this.marginTopSpinner){
      this.marginTopSpinner = this.heightVh / 3;
    }
  }

}
