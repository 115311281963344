export const addDays = (numOfDays: number, date = new Date()) => {
  date.setDate(date.getDate() + numOfDays);
  return date;
};

export const removeUMTDate = (dateInput: string | Date) => {
  if (dateInput) {
    let newDate = new Date(dateInput);
    if (!isValidDate(newDate)) {
      return null;
    }
    let userTimezoneOffset = Math.trunc(newDate.getTimezoneOffset() / 60);
    newDate = new Date(
      newDate.setHours(newDate.getHours() + userTimezoneOffset)
    );
    return newDate;
  } else {
    return null;
  }
};

export const addUMTDate = (dateInput: string | Date) => {
  if (dateInput) {
    let newDate = new Date(dateInput);
    if (!isValidDate(newDate)) {
      return null;
    }
    let userTimezoneOffset = Math.trunc(newDate.getTimezoneOffset() / 60);
    newDate = new Date(
      newDate.setHours(newDate.getHours() - userTimezoneOffset)
    );
    return newDate;
  } else {
    return null;
  }
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d.getTime());
};

export const sameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export const randomDate = (start, end) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
};

export const getShortDate = (date: Date) => {
  return Number(
    date.getFullYear().toString() +
      (date.getMonth() + 1).toString().padStart(2, '0')
  );
};

export const removeTime = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const setStartHourDate = (date: Date) => {
  const time = '00:00:00.000';
  return setHourDate(date, time);
};

export const setLastHourDate = (date: Date) => {
  const time = '23:59:59.999';
  return setHourDate(date, time);
};

const setHourDate = (date: Date, time: string) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const fullDate = `${year}-${month
    .toString()
    .padStart(2, '0')}-${day.toString().padStart(2, '0')}T${time}Z`;
  return new Date(fullDate).toISOString();
};

export const daysInMonth = (year, month) => new Date(year, month, 0).getDate();

export const getHourAndMinute = (hourAndMinute: string) => {
  const INVALID_HOUR_AND_MINUTE = { hour: 0, minute: 0 };

  if (!hourAndMinute) {
    console.error('Invalid Hour');
    return INVALID_HOUR_AND_MINUTE;
  }

  const separteHourAndMinute = hourAndMinute.split(':');
  if ((separteHourAndMinute || []).length !== 2) {
    console.log('Invalid hour');
    return INVALID_HOUR_AND_MINUTE;
  }

  return {
    hour: Number(separteHourAndMinute[0]),
    minute: Number(separteHourAndMinute[1]),
  };
};

export const getTimeByHourAndMinute = (hourAndMinute: string) => {
  const { hour, minute } = getHourAndMinute(hourAndMinute);

  return hour * 60 + minute;
};

export const convertDateToString = (date: Date) => {
  if (!date) {
    return null;
  }

  const month = date.getMonth() + 1;
  return `${date.getFullYear()}-${month
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

export const getTimeByHour = (hourAndMinute: string) => {
  if (!hourAndMinute) return 0;

  if ((hourAndMinute.split(':') || []).length !== 2) return 0;

  const [hour, minute] = hourAndMinute.split(':');

  return Number(hour) * 60 + Number(minute);
};

export const isDate1GreaterThanDate2 = (d1: Date, d2: Date) => {
  const date1 = new Date(d1).getTime();
  const date2 = new Date(d2).getTime();

  return date1 < date2;
};
