export const MAX_LENGTH_TITLE: number = 200;
export const MAX_LENGTH_TITLE_WITHOUT_STYLE: number = 60;

export const MAX_TITLE_LENGTH: number = 100;

export const MAX_LENGTH_BODY: number = 1000;
export const MAX_LENGTH_BODY_WITH_STYLE: number = 2000;

export const PLACEHOLDER_TITLE: string =
  '<h1 style="font-weight: bold;font-size: 1.8em;font-family: Calibri, sans-serif">{{title}}</h1>';
export const PLACEHOLDER_BODY: string =
  '<p style="text-align: left;">{{title}}</p>';

export const HEADER_ANDROID_MAX: [number, number] = [1920, 1360];
export const HEADER_IOS_MAX: [number, number] = [480, 340];

export const WIDTH_IMAGE_BODY_MAX: number = 1920;

export const MAX_HTML_FILE: number = 3500;
export const MAX_URL: number = 50;

export const URL_BUTTONS_API_BY_CODE: { [code: string]: string } = {
  CODE100: 'https://arbitrade.es/',
  CODE200: 'https://arbitrade.es/',
  CODE300: 'https://www.izagavending.com',
  CODE400: 'https://www.amfmvending.com',
  OTHER: 'https://arbitrade.es/',
};
