// services
import { FilterTransactionsQueryService } from 'src/app/pages/user/transactions/transactions-query/services';
import {
  FilterDateFutureService,
  FilterDateService,
} from 'src/app/share/services';

// models
import { FilterDateServiceI } from 'src/app/models';

// utils
import {
  FUTURE_DATE_SERVICES_ROUTES,
  TRANSACTION_QUERY_SERVICE_ROUTES,
} from './constant';

interface GetFilterDateService {
  routeUrl: string;
  _filterTransactionQueryService: FilterTransactionsQueryService;
  _filterDateService: FilterDateService;
  _filterDateFutureService: FilterDateFutureService;
}

export const getFilterDateService = ({
  routeUrl,
  _filterTransactionQueryService,
  _filterDateService,
  _filterDateFutureService,
}: GetFilterDateService): FilterDateServiceI => {
  if (FUTURE_DATE_SERVICES_ROUTES.includes(routeUrl)) {
    return _filterDateFutureService;
  }

  return TRANSACTION_QUERY_SERVICE_ROUTES.includes(routeUrl)
    ? _filterTransactionQueryService
    : _filterDateService;
};
