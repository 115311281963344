import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, IonInput, ModalController } from '@ionic/angular';

// Models
import { Company } from '../../../../models/';

// Utils
import { timeReload, UtilsTranslate } from '../../../../utils';

@Component({
  selector: 'app-template-filter-object-modal',
  templateUrl: './filter-template-object-modal.component.html',
  styleUrls: ['./filter-template-object-modal.component.scss'],
})
export class FilterTemplateObjectModalComponent implements OnInit {

  @Input() company: Company;
  @Input() idCloseModal: string = 'closeFilterObjectModal'
  @Input() title: string = 'filter'

  @Input() search: string = ''

  @Input() elementSelected;


  @Input() nameSearchElement: string = 'description';
  @Input() nameID: string = 'ID';

  @Input() haveClear: boolean = true;

  @Input() elements: any[] = [];
  @Input() inputLabelText: string = 'name'

  @Input() notExistText: string = ''

  elementsFilter: any[];
  elementsShower: any[];

  @Input() successText = ''

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private modalCtrl: ModalController,
    private utils: UtilsTranslate
  ) { }

  ngOnInit() {
    this.start()
  }

  start() {

    this.elementsFilter = this.elements;
    this.elementsShower = [];
    if (this.elementsFilter.length < 50) {
      this.elementsShower.push(...this.elementsFilter.slice(0, this.elementsFilter.length));
    } else {
      this.elementsShower.push(...this.elementsFilter.slice(0, 50));
    }

    if(this.search){
      this.changeName()
    }

  }


  closeModal() {
    this.modalCtrl.dismiss({});
  }


  changeName() {

    if (!this.search) {
      this.start();
    } else {
      this.elementsShower = [];
      this.elementsFilter = this.elements.filter(element =>
          element?.[this.nameSearchElement]?.toString().toLowerCase().trim()
        .includes(this.search?.toLowerCase().trim()));
      if (this.elementsFilter.length < 50) {
        this.elementsShower.push(...this.elementsFilter.slice(0, this.elementsFilter.length));
      } else {
        this.elementsShower.push(...this.elementsFilter.slice(0, 50));
      }

      let elementFilter = this.elements.find(
        element => element?.[this.nameSearchElement]?.toString().toLowerCase().trim()
                      === this.search?.toLowerCase().trim());
      (elementFilter) ? this.elementSelected = elementFilter[this.nameID] : this.elementSelected = null;

    }

  }

  filterElement() {

    let elementFilter = this.elements.find(element => element?.[this.nameID] == this.elementSelected);
    if (elementFilter) {
      if (this.modalCtrl) {
        this.modalCtrl.dismiss({
          element: elementFilter
        });
      }
    } else {
      this.utils.presentToastTranslate('error', true, 0)
    }

  }


  deleteFilterElement() {

    if (this.modalCtrl) {
      this.modalCtrl.dismiss({
        delete: true
      });
    }
    if(this.successText){
      this.utils.presentToastTranslate(this.successText, false,0)
    }


  }

  loadData(){

    setTimeout(() => {

      if (this.elementsShower.length >= this.elementsFilter.length) {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
        return;
      }

      let newElements = [];
      if (this.elementsFilter.length < this.elementsShower.length + 50) {
        newElements = this.elementsFilter.slice(this.elementsShower.length, this.elementsFilter.length);
      } else {
        newElements = this.elementsFilter.slice(this.elementsShower.length, this.elementsShower.length + 50);
      }
      this.elementsShower.push(...newElements);

      this.inifiteScroll.complete();
    }, timeReload);

  }


}
