import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// models
import { ChangeShowReportModalSM } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ViewReportModalService {

  changeOpenViewReportModal$: Subject<ChangeShowReportModalSM> = new Subject<ChangeShowReportModalSM>();

  constructor() { }

  openModal(pos: number){
    this.changeOpenViewReportModal$.next(
      {
        openModal: true,
        pos
      }
    )
  }
}
