import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, throwError } from 'rxjs';

// models
import { RouteVisibility } from 'src/app/models';
import { ClientRoute } from 'src/app/pages/user/route-management/sales-module/models';
import { CreateRouteParams, GetRoutesParams, EditRouteParams } from './models';

// config
import { AppConfig } from 'src/app/config/config';
import { ROUTE_MANAGER_ROUTE } from './utils/constant';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient, protected router: Router) {}

  getClientsRoute(params: { routeID?: number } = {}) {
    let endpoint = `route/client`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<ClientRoute[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getRoutes(params: GetRoutesParams) {
    let endpoint = `getRoutes`;
    let routeUrl = this.router.url;

    const isEditRoute = routeUrl === ROUTE_MANAGER_ROUTE;

    return this.httpClient
      .post<RouteVisibility[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          if(!isEditRoute){
            res = res.filter(route => route.enable === 1)
          }
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  createRoute(params: CreateRouteParams) {
    let endpoint = `route/create`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<void>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  editRoute(params: EditRouteParams) {
    let endpoint = `route/edit`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<void>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  deleteRoute(params: { routeID: number }) {
    let endpoint = `route/delete`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<void>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}
