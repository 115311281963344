import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FilterDateServiceI } from 'src/app/models';
import { checkSegments } from 'src/app/pages/user/transactions/transactions-query/models';
import { FilterDateParams } from './filter-date.service';

@Injectable({
  providedIn: 'root'
})
export class FilterDateExtraHourService  implements FilterDateServiceI{

  hourEnd: string = null;
  hourStart: string = null;

  // emite los cambios del filtro
  filterChange$: Subject<Object[]> = new Subject<Object[]>();

  // clear
  reset$: Subject<Object[]> = new Subject<Object[]>();

  constructor() {}

  getCheckSegment() {
    return null;
  }

  setCheckSegment(newType: checkSegments) {}

  getDateStart() {}

  getDateEnd() {}

  getYear() {
    return null;
  }

  setDate(dateNew, datePos) {}

  getStartHour() {
    if (!this.hourStart) {
      this.hourStart = `00:00`;
    }
    return this.hourStart;
  }

  setHour(hour, posHour) {
    if (posHour == 0) {
      this.hourStart = hour;
      this.filterChange$.next([{ hourStart: this.hourStart }]);
    } else {
      this.hourEnd = hour;
      this.filterChange$.next([{ hourEnd: this.hourEnd }]);
    }
  }

  getEndHour() {
    if (!this.hourEnd) {
      this.hourEnd = '24:00';
    }
    return this.hourEnd;
  }

  resetDate() {

    this.hourStart = `00:00`;
    this.hourEnd = '24:00';

    this.reset$.next([true]);
  }

  getDate() {
    return null;
  }

  getDateWithoutHourParams(): FilterDateParams {
    return null;
  }

  getDateWithHour() {
    return null;
  }

  getInfoExtraHours(): ExtraInfoHours {
    return {
      hourStartExtra: this.hourStart || '00:00',
      hourEndExtra: this.hourEnd || '23:59',
    };
  }
}

interface ExtraInfoHours{
  hourEndExtra: string,
  hourStartExtra: string,
}
