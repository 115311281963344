import { Operator } from "src/app/models";


export const getOperatorDescription = (operatorSelectedId: number, operators: Operator[]): string => {

  if(!operatorSelectedId && operatorSelectedId !== 0){
    return '';
  }

  const operator = operators.find(operator => operator.id === operatorSelectedId)
  if(!operator){
    return '';
  }

  return operator?.description;
}
