// models
import { CardNames, Company } from 'src/app/models';

// services
import { FilterReportService } from '../../../../services';

// utils
import { UtilsTranslate } from 'src/app/utils';
import { haveSomeRepeatCard } from './have-some-repet-card';

interface IsValidCardGuidedReport {
  company: Company;
  _filterReportService: FilterReportService;
  utils: UtilsTranslate;
}

export const isValidCardGuidedReport = ({
  company,
  _filterReportService,
  utils,
}: IsValidCardGuidedReport): boolean => {

  const cardIds = _filterReportService.getCardsId();
  if ((cardIds || []).length === 0) {
    utils.presentToastTranslate(
      company.nameCard === CardNames.arbitradePay
        ? 'reports.create_edit.select_card2'
        : 'reports.create_edit.select_card'
    );
    return false;
  }

  if (
    haveSomeRepeatCard({
      company,
      _filterReportService,
      utils,
    })
  ) {
    return false;
  }

  return true;
};
