// import { Label, MultiDataSet } from 'ng2-charts';
// Charts
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import { ChartInputTillAlert, TillAlertsSpiderByEmail } from "../models";
import { NUM_CHARTS } from './constant';

export const getChartsByEmailTillAlert = (
  top10Alerts: TillAlertsSpiderByEmail[]
): ChartInputTillAlert => {

  let doughnutChartLabels: BaseChartDirective['labels'] = []
  let doughnutChartData: ChartConfiguration<'doughnut'>['data']['datasets'] = [
    {data: [], backgroundColor: [], borderColor: []}
  ];

  let i=0;
  let total = 0;
  let total10 = 0;
  for(let topAlert of top10Alerts){
    if(i< NUM_CHARTS){
      doughnutChartLabels.push(topAlert.Email)

      //  doughnutChartData.push(topAlert.c_alerts)
      doughnutChartData[0].data.push(topAlert.c_alerts)

      total10 += topAlert.c_alerts;
    }
    i++;
    total += topAlert.c_alerts;

  }

  return { doughnutChartLabels, doughnutChartData, total, total10 }

}
