// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { HeaderModalModule } from '../../header-modal/header-modal.modal';

// Component
import { LicensesVatgLegendComponent } from './licenses-vatg-legend.component';

// Service
import { LanguageService } from 'src/app/share/services';
import { SkinMangementService } from 'src/app/services';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,

    HeaderModalModule,
  ],
  declarations: [
    LicensesVatgLegendComponent
  ],
  providers: [LanguageService, SkinMangementService],
  exports: [
    LicensesVatgLegendComponent
  ],
})
export class LicenseVtagLegendModule {}
