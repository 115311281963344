import { Component, Input, OnInit } from '@angular/core';
import { SalesCancelationComponent } from '../../../pages/login/components/sales-cancelation/sales-cancelation.component';
import { ModalController, Platform } from '@ionic/angular';
import { Company } from '../../../models';
import { SIZE_FOOTER_V2V, pcWidth, pcWidthMix, type } from '../../../utils/constants';
import { SkinMangementService } from '../../../services/skin-mangement.service';
import { LegalNoticeV2vComponent } from '../../../pages/login/components/legal-notice-v2v/legal-notice-v2v.component';
import { getTargetByPlatform } from '../../../utils/useApkAndroid';
import { ColorModeService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { CookiesPolicyComponent, PrivacyPolicyComponent } from 'src/app/pages/login/components';

@Component({
  selector: 'app-footer-login',
  templateUrl: './footer-login.component.html',
  styleUrls: ['./footer-login.component.scss'],
})
export class FooterLoginComponent implements OnInit {
  @Input() colorFooter: string = null;

  currentDate = new Date();

  company: Company;
  type = type;
  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;

  target: '_self' | '_blank' = '_blank';

  sizeFooterV2V = SIZE_FOOTER_V2V;

  isLightV2VFooter = true;

  resize$: Subscription;

  isLoginElora: boolean = false;
  routerEvents$: Subscription;

  constructor(
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private skinService: SkinMangementService,
    private _colorModeService: ColorModeService,
    protected router: Router
  ) {}

  ngOnInit() {
    this.company = this.skinService.getCompany();

    this.target = getTargetByPlatform(this.platform);

    this.isLoginElora = this.router.url === '/login' && this.company?.companySkin === 'V2VFactory';

    this.getIsLightV2VFooter();

    if (this.company?.companySkin === 'V2VFactory') {
      this.routerEvents$ = this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationEnd) {
          this.isLoginElora =
            this.router.url === '/login' &&
            this.company?.companySkin === 'V2VFactory';
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.resize$) this.resize$.unsubscribe();
    if (this.routerEvents$) this.routerEvents$.unsubscribe();
  }

  async showSalesCancellation() {
    const isLightColor = this._colorModeService.getIsLightColor();

    const modal = await this.modalCtrl.create({
      component: SalesCancelationComponent,
      cssClass:
        this.platform.width() > this.pcWidth
          ? `modal-border-radius  ${!isLightColor ? 'modal-border-dark' : ''}`
          : '',
    });
    if (modal) await modal.present();
  }

  async showLegalNotice() {
    const isLightColor = this._colorModeService.getIsLightColor();

    const modal = await this.modalCtrl.create({
      component: LegalNoticeV2vComponent,
      cssClass:
        this.platform.width() > this.pcWidth
          ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
          : '',
    });
    if (modal) await modal.present();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  getIsLightV2VFooter() {
    if (this.company.companySkin === type.v2v) {
      this.isLightV2VFooter = true;
      return;
    }

    this.isLightV2VFooter = this.getIsLightByCompany()

    this.resize$ = this.platform.resize.subscribe(() => {
      this.isLightV2VFooter = this.getIsLightByCompany()
    });

  }

  getIsLightByCompany(){
    if ([type.rocca, type.triangle].includes(this.company.companySkin)){
      return this.isLightV2VFooter = this.platform.width() < 1200;
    }

    return this.isLightV2VFooter = this.platform.width() >= 1200;
  }

  async showPrivacyPolicy(){
    const isLightColor = this._colorModeService.getIsLightColor();

    const modal = await this.modalCtrl.create({
      component: PrivacyPolicyComponent,
      cssClass:
        this.platform.width() > this.pcWidth
          ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
          : '',
    });
    if (modal) await modal.present();
  }

  async showCookiesPolicy(){
    const isLightColor = this._colorModeService.getIsLightColor();

    const modal = await this.modalCtrl.create({
      component: CookiesPolicyComponent,
      cssClass:
        this.platform.width() > this.pcWidth
          ? `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
          : '',
    });
    if (modal) await modal.present();
  }
}
