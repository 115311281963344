import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastController } from '@ionic/angular';

// Service
import { LanguageRouteService } from '../../../../../services/languageRoute.ts/languagesRoute.services';
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from '../../../../../../services/skin-mangement.service';
import { FilterWebloadService } from '../../../../../../pages/user/cards/webload/share/services/filter-webload.service';
import { WebloadTransferService } from '../../../../../../pages/user/cards/webload/share/services/webload-transfer.service';

// Models
import { Company } from 'src/app/models';

// Utils
import { presentToast, successToast } from '../../../../../../utils';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-select-import-webload',
  templateUrl: './select-import-webload.component.html',
  styleUrls: ['./select-import-webload.component.scss'],
})
export class SelectImportWebloadComponent implements OnInit {

  company: Company;

  @Input() max = 2;
  @Input() min = 0.01;
  @Input() step = 0.01;
  @Input() route = 'cards-webload-unlimited';

  @Output() sendImport = new EventEmitter<boolean>();

  amount = null;

  loadingTransfer = false;

  errorEndpoint = '';
  errorImport1 = '';
  errorImport2 = '';
  errorCard = '';
  errorAlliance = '';
  successTranslate1 = '';
  successTranslate2 = '';
  errorNotAmount = '';
  errorAmountSubscriptions = '';
  errorDecimals = '';

  locale = 'es-ES'

  currency: string = '';

  changeLanguage$: Subscription;
  currencyChange$: Subscription;


  @Output() changeAmountLoadCredit = new EventEmitter<number>();

  constructor(
    private toastCtrl: ToastController,
    private _languageRouteService: LanguageRouteService,
    private translate: TranslateService,
    private _skinService: SkinMangementService,
    private _filterService: FilterWebloadService,
    private _amountTransferService: WebloadTransferService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });

    this.currencyChange$ = this._filterService.currencyChange$.subscribe(res => {
      this.currency = res
    })

  }

  ngOnDestroy(): void {
    if(this.changeLanguage$) this.changeLanguage$.unsubscribe()
    if(this.changeLanguage$) this.currencyChange$.unsubscribe()
  }


  changeAmount(){
    this._filterService.setAmount(this.amount);
  }

  sendFilter() {

    let params = this._filterService.getParams();


    if ((!params?.amount || params.amount < this.min || params.amount > this.max)) {
      presentToast(this.getReplaceError(), this.toastCtrl)
      return;
    }

    if(((this.amount * 100) % 1) != 0){
      presentToast(this.errorDecimals, this.toastCtrl);
      return;
    }

    if (!params.cardId) {
      (this.company.nameCard == 'AlliancePay')
        ? presentToast(this.errorAlliance, this.toastCtrl)
        : presentToast(this.errorCard, this.toastCtrl);

      return;
    }

    this.loadingTransfer = true;
    this._amountTransferService.sendTransfer(params.cardId, params.amount, this.route).subscribe({next: (res) => {
      if (res?.updated) {
        successToast(this.getSuccessTranslate(params.amount, params.cardNum), this.toastCtrl)
        this._filterService.resetParams();

        this.sendImport.emit(true);
      } else {
        presentToast(this.errorEndpoint, this.toastCtrl);
      }
      this.loadingTransfer = false;
      this.amount = 0;


    },error: () => {
      presentToast(this.errorEndpoint, this.toastCtrl);
      this.loadingTransfer = false;
    }})


  }

  clearFilter() {
    this._filterService.resetParams()
  }


  savePlaceholders() {
    this.translate.get([
    'amount_transfer.error_import_1', 'amount_transfer.error_import_2',
    'amount_transfer.error_card',
    'amount_transfer.error_cardA', 'error',
    'amount_transfer.add_amount_1', 'amount_transfer.add_amount_2',
    'load_credit.error_not_amount', 'locale', 'load_credit.error_import',
    'amount_transfer.error_decimals']).subscribe((translates) => {

      this.errorImport1 = translates['amount_transfer.error_import_1'];
      this.errorImport2 = translates['amount_transfer.error_import_2'];
      this.errorCard = translates['amount_transfer.error_card'];
      this.errorAlliance = translates['amount_transfer.error_cardA'];
      this.errorEndpoint = translates['error'];
      this.errorNotAmount = translates['load_credit.error_not_amount'];
      this.locale = translates['locale']
      this.errorAmountSubscriptions = translates['load_credit.error_import']
      this.errorDecimals = translates['amount_transfer.error_decimals']
      this.successTranslate1 = translates['amount_transfer.add_amount_1'];
      this.successTranslate2 = translates['amount_transfer.add_amount_2'];
    });
  }

  getSuccessTranslate(amount, cardNum){
    if(this.currency === '$'){
      return this.successTranslate1
              .replace('{amount}',amount)
              .replace('{cardnum}', cardNum)
              .replace('{currency}', this.currency)
    } else{
      return this.successTranslate2
              .replace('{amount}',amount)
              .replace('{cardnum}', cardNum)
              .replace('{currency}', this.currency)
    }
  }

  getReplaceError(){
    if(this.currency === '$'){
      return this.errorImport1
            .replace('{min}',this.min.toFixed())
            .replace('{max}', this.max.toFixed(2))
            .split('{currency}')
            .join(this.currency)
    }else {
      return this.errorImport2
      .replace('{min}',this.min.toFixed(2))
      .replace('{max}', this.max.toFixed(2))
      .split('{currency}')
      .join(this.currency)
    }
  }

  changeMax(){
    if(this.amount > this.max) {
      this.amount = this.max;
      this.changeAmount();
    }
  }



}
