// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { CarouselGuidedModule } from '../carousel-filter-guided/carousel-filter-guided.module';
import { HeaderModalModule } from '../header-modal/header-modal.modal';
import { FilterTemplateGuidedSelectModule } from './filter-template-guided-select/filter-template-guided-select.component.module';
import { InfoSelectedHeaderModule } from './info-selected-header';
import { FilterTemplateGuidedObjectModule } from './filter-template-guided-object';
import { FilterTemplateObjectTripleInputsModalModule } from './filter-template-object-triple-inputs-modal';
import {
  FilterInputSearchShareModule,
  FilterTemplateSelectElemByListShareModule,
} from './share';

// Component
import {
  FilterTemplateStringComponent,
  ModalSelectClientComponent,
  FilterTemplateObjectModalComponent,
  FilterTemplateObjectDoubleInputsModalComponent,
  FilterTemplateSelectedComponent,
  FilterTemplateGuidedStringDictionaryComponent,
  FilterTemplateGuidedStringComponent,
  FilterTemplateGuidedObjectDobleInputsComponent,
} from './';

// Service
import { LanguageService } from '../../services/languages.service';
import { SkinMangementService } from 'src/app/services';

// Pipe
import { TranslateByDictionaryPipe } from '../../pipes';
import { FilterTemplateOneElemPositionShareModule } from './share/filter-template-one-elem-position-share/filter-template-one-elem-position-share.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    HttpClientModule,

    CarouselGuidedModule,
    HeaderModalModule,
    FilterTemplateGuidedSelectModule,
    InfoSelectedHeaderModule,
    FilterTemplateGuidedObjectModule,
    FilterTemplateObjectTripleInputsModalModule,
    FilterInputSearchShareModule,
    FilterTemplateSelectElemByListShareModule,
    FilterTemplateOneElemPositionShareModule
  ],
  declarations: [
    FilterTemplateGuidedObjectDobleInputsComponent,
    FilterTemplateGuidedStringComponent,
    FilterTemplateObjectDoubleInputsModalComponent,
    FilterTemplateObjectModalComponent,
    FilterTemplateSelectedComponent,
    FilterTemplateStringComponent,
    ModalSelectClientComponent,
    FilterTemplateGuidedStringDictionaryComponent,
    ModalSelectClientComponent,
  ],
  providers: [LanguageService, SkinMangementService, TranslateByDictionaryPipe],
  exports: [
    HeaderModalModule,
    FilterTemplateGuidedSelectModule,
    InfoSelectedHeaderModule,
    FilterTemplateGuidedObjectModule,
    FilterTemplateObjectTripleInputsModalModule,

    FilterTemplateGuidedObjectDobleInputsComponent,
    FilterTemplateGuidedStringComponent,
    FilterTemplateObjectDoubleInputsModalComponent,
    FilterTemplateObjectModalComponent,
    FilterTemplateSelectedComponent,
    FilterTemplateStringComponent,
    ModalSelectClientComponent,
    FilterTemplateGuidedStringDictionaryComponent,
    ModalSelectClientComponent,
  ],
})
export class ShareModalsModule {}
