import { Platform } from '@ionic/angular';

// models
import { Company } from '../models';

export const clientesavDomain = () => {
  return document.location.hostname.includes('clientesav');
};

export const isMac = (): boolean => {
  return navigator
    ? (
        (navigator as any).userAgentData?.platform ||
        (navigator as any)?.platform ||
        'unknown'
      ).indexOf('Mac') > -1 || false
    : false;
};

export const isWindows = () => {
  return navigator
    ? (
        (navigator as any).userAgentData?.platform ||
        (navigator as any)?.platform ||
        'unknown'
      ).indexOf('Win') > -1 || false
    : false;
};

export const isApkAndroid = (platform: Platform) => {
  return (
    platform.platforms().includes('android') &&
    platform.platforms().includes('capacitor')
  );
};

export const isAllianceDomain = (company: Company) => {
  return company.tag === 'alliance';
};

export const isV2VDomain = (company: Company) => {
  return company.tag === 'v2vfactory';
};

export const isIos = (platform: Platform) => {
  return platform.is('ipad') || platform.is('ios') || platform.is('iphone');
};

export const haveDashboard = (
  company: Company,
  permissionLength: number
): boolean => {
  if (clientesavDomain()) {
    return false;
  }

  if (company.companySkin === 'Triangle') {
    return false;
  }

  if (company.companySkin === 'LaRocca' && permissionLength === 0) {
    return false;
  }

  return true;
};

export const isDesktopByNavigator = () => {
  if (!navigator?.userAgent) {
    return false;
  }

  if (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
    return false;
  }

  return true;
};
