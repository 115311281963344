// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-title {
  font-size: 1.3em;
}

.font-sm {
  font-size: 0.8em;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.select-max-width {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/carousel-filter-guided/carousel-filter-guided.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".text-title {\r\n  font-size: 1.3em;\r\n}\r\n\r\n.font-sm{\r\n  font-size: 0.8em;\r\n}\r\n\r\n.d-flex-center{\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.w-100{\r\n  width: 100%;\r\n}\r\n\r\n.select-max-width{\r\n  max-width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
