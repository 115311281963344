import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

// services
import { PermissionService } from 'src/app/services';

// utils
import { CLIENT_MANAGEMENT_ROUTE } from 'src/app/utils';

@Injectable({
  providedIn: 'root'
})
export class HaveClientManagementResolverService {

  constructor(
    private _permissionService: PermissionService
  ) { }

  resolve(): Observable<boolean> {
    return this._permissionService.getAllPermissions().pipe(
      map((res) => {
        let haveClientManagementRoute = res.some(
          (permission) =>
            permission.route === CLIENT_MANAGEMENT_ROUTE
        );
        return !!haveClientManagementRoute;
      })
    );
  }
}
