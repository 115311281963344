import { WritableSignal } from '@angular/core';

// models
import { Client } from 'src/app/models';
import {
  DataAssigmentRouteMangaterOptions,
  FilterClientOptionsRM,
  PositionVisibilityRouteManager,
  RouteEditable,
  TillSelectedRM,
} from '../../models';

// utils
import { clearTillsRM } from '../select-route';

interface ResetRouteEditableRM {
  routeEditable: WritableSignal<RouteEditable>;
  clientAssigmentSelected: WritableSignal<Client>;
  tills: WritableSignal<TillSelectedRM[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
  masterCheckTill: WritableSignal<boolean>;
  isIndeterminateTill: WritableSignal<boolean>;
  segmentDataAssigment: WritableSignal<DataAssigmentRouteMangaterOptions>;
  positionVisibility: WritableSignal<PositionVisibilityRouteManager>;
  filterClientOptions: WritableSignal<FilterClientOptionsRM>;
}

export const resetRouteEditableRM = ({
  routeEditable,
  clientAssigmentSelected,
  tills,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
  masterCheckTill,
  isIndeterminateTill,
  segmentDataAssigment,
  positionVisibility,
  filterClientOptions,
}: ResetRouteEditableRM) => {
  const operatorId = routeEditable().operatorId;

  routeEditable.set({
    operatorId,
    enabled: true,
    routeName: '',
    routeNumber: null,
    holderRouteDriver: { enable: true, name: '', routeDriverId: null },
    substituteRouteDriver: [{ enable: true, name: '', routeDriverId: null }],
    supervisor: { supervisor: '', supervisorId: null },
    tills: [],
  });

  clientAssigmentSelected.set(null);

  clearTillsRM(tills);
  clearTillsRM(tillsByOperator);

  tillsSelected.set([]);
  tillOneMachineSelected.set(null);

  masterCheckTill.set(true);
  isIndeterminateTill.set(false);

  segmentDataAssigment.set(DataAssigmentRouteMangaterOptions.data);
  filterClientOptions.set(FilterClientOptionsRM.filterClient);
  positionVisibility.set(PositionVisibilityRouteManager.client);
};
