// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-button {
  margin-top: 20px;
}

.icon-center {
  vertical-align: middle;
  padding-bottom: 6px;
}

.start-line {
  margin-top: 2%;
  text-align: left;
  font-size: 0.8em;
  color: var(--ion-color-danger);
}

.bg-red {
  background-color: red;
}

.font-sm {
  font-size: 0.8em;
}

.mt-interval {
  margin-top: -30px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/register-cards/components/subtypes/interval-card/interval-card-pc/interval-card-pc.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,8BAAA;AACF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,4BAAA;AAAF","sourcesContent":[".center-button {\r\n  margin-top: 20px;\r\n}\r\n\r\n.icon-center {\r\n  vertical-align: middle;\r\n  padding-bottom: 6px;\r\n}\r\n\r\n.start-line{\r\n  margin-top: 2%;\r\n  text-align: left;\r\n  font-size: 0.8em;\r\n  color: var(--ion-color-danger)\r\n}\r\n\r\n\r\n.bg-red{\r\n  background-color: red;\r\n}\r\n\r\n.font-sm{\r\n  font-size: 0.8em;\r\n}\r\n\r\n.mt-interval{\r\n  margin-top: -30px !important\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
