import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterTillMappingService {
  //paginator
  page = 1;
  skip = 0;
  changeFilter: Subject<boolean> = new Subject<boolean>();
  filterChange: Subject<FilterTillMapping> = new Subject<FilterTillMapping>();

  addedLines: string[] = [];

  isComplex = false;
  haveChangeCapacity: boolean = false;

  constructor() {}

  setPage(newPage: number) {
    this.skip = (newPage - 1) * 10;
    this.page = newPage;
    this.changeFilter.next(true);
  }

  reset(reset: boolean) {
    this.filterChange.next({ reset: reset });
  }

  getIsComplex() {
    return this.isComplex;
  }

  setIsComplex(isComplex: boolean) {
    this.isComplex = isComplex;
  }

  getHaveChangeCapacity() {
    return this.haveChangeCapacity;
  }

  setHaveChangeCapacity(haveChangeCapacity: boolean) {
    this.haveChangeCapacity = haveChangeCapacity;
  }
}

interface FilterTillMapping {
  reset: boolean;
}
