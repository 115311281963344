import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError} from 'rxjs';

// config
import { AppConfig } from 'src/app/config/config';

// services
import {
  ClientService,
  OperatorGroupService,
  OperatorService,
  TillService,
  CardService,
} from 'src/app/share/services';

// models
import { ParamsFiltersCards } from 'src/app/models';
import {
  CreateReportParams,
  EditReportParams,
  GetCardIdsByCardnumReportParams,
  GetReportParams,
  Report,
  TypeReport,
  ValidateExistCardsByReportParams,
  EmailReport,
} from '../models';

// utils
import { addUMTDate, removeUMTDate } from 'src/app/utils';



@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private _operatorGroupService: OperatorGroupService,
    private _operatorService: OperatorService,
    private _clientService: ClientService,
    private _tillService: TillService,
    private _cardService: CardService
  ) {}

  getReports(params: GetReportParams = {}) {
    let endpoint = `get-reports`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<Report[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          res.map((report) => {
            if (report.emails.length === 0) {
              let emptyEmailReport: EmailReport = {
                ID: null,
                ReportID: null,
                Email: '',
                Enable: 1,
                CreationDate: null,
                ModificationDate: null,
              };

              report.emails.push(emptyEmailReport);
            }
            if(report?.firtsExecutionDate){
              report.firtsExecutionDate = removeUMTDate(report.firtsExecutionDate)
            }
            if(report?.lastExecutionDate){
              report.lastExecutionDate = removeUMTDate(report.lastExecutionDate)
            }
            return report;
          });
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  editStatusReports(report: Report) {
    //get the day number of the month
    // if(report.firtsExecutionDate instanceof Date){
    //   // report.firtsExecutionDate = report.firtsExecutionDate.toISOString().split('T')[0]
    //   // report.firtsExecutionDate = report.firtsExecutionDate
    // }
    console.log('report', report)

    let sendFormat = ()=> {
      if(!report.SendingFormat || Number(report?.SendingFormat) == 0){return 1}
      return Number(report?.SendingFormat);
    }

    const paramsEdit: EditReportParams = {
      reportID: report?.reportId,
      typeID: report?.typeReport?.typeReportID,
      description: report?.reportName,
      companyID: report?.companyID,
      first_Date: (addUMTDate(report?.firtsExecutionDate)).toString(),
      enable: report?.enabled ? 0 : 1,
      emails: report?.emails,
      operatorGroupID: report?.operatorGroupID,
      operatorID: report?.operatorID,
      clientID: report?.clientID,
      machineID: report?.machineID,
      cards: (report?.cards || []).map((card) => card.id),
      sendingFormat: sendFormat(),
      // Periodicity: periodicidad(),
    };

    // console.log('params', paramsEdit)
    return this.editReport(paramsEdit);
  }

  getTypeReport() {
    let endpoint = `typeReports`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<TypeReport[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getOperatorsGroups() {
    return this._operatorGroupService.getOperatorGroup();
  }

  getOperators() {
    return this._operatorService.getOperators();
  }

  getClients() {
    return this._clientService.getClients();
  }

  getTills() {
    return this._tillService.getTills();
  }

  createReport(params: CreateReportParams) {
    let endpoint = `create-report`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<TypeReport[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );

  }

  editReport(params: EditReportParams) {
    let endpoint = `edit-report`;
    let routeUrl = this.router.url;

    return this.httpClient
      .post<null>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getCardById(cardId: number) {
    return this._cardService.getCardyById(cardId);
  }

  getCards(params: ParamsFiltersCards) {
    return this._cardService.getCards(params);
  }

  validateExistCardsByReport(
    params: ValidateExistCardsByReportParams
  ) {

    let endpoint = 'cardsVerification';
    let routeUrl = this.router.url;

    return this.httpClient
      .post<number[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getCardIdsByCardnum(params: GetCardIdsByCardnumReportParams){
    let endpoint = 'cardsNumtoID';
    let routeUrl = this.router.url;

    return this.httpClient
      .post<number[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}
