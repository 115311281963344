import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Config
import { AppConfig } from '../../../config/config';

// models
import { TillAena } from '../../../pages/user/aena/till-mapping-aena/models/till-aena.model';
import { FamilyAena } from '../../../pages/user/aena/share/models/family-aena.model';
import { GetSubgroupAena } from 'src/app/pages/user/aena/share/subgroup/models';

@Injectable({
  providedIn: 'root',
})
export class AenaService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(private httpClient: HttpClient, private router: Router) {}

  getTills(clientID: number) {
    const params: { clientID: number } = { clientID };

    const endpoint = `AENA/machines`;
    const routeUrl = this.router.url;

    return this.httpClient
      .post<TillAena[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getFamilyAena(isPublic: boolean) {
    let endpoint = isPublic ? `publicZone_families` : `restrictedZone_families`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<FamilyAena[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getSubgroupsAena(clientID: number = null) {
    let endpoint = `AENA/getSubgroups`;
    let routeUrl = this.router.url;

    const params: { clientID?: number } =
      !clientID && clientID !== 0 ? {} : { clientID };

    return this.httpClient
      .post<GetSubgroupAena>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }
}
