import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

// Environments
import { version } from '../../../../environments/version';

// Components
import { SalesCancelationComponent } from '../../../pages/login/components/sales-cancelation/sales-cancelation.component';

// Services
import { SkinMangementService } from '../../../services/skin-mangement.service';

// Models
import { Company } from '../../../models';

// Utils
import { pcWidth, pcWidthMix, type, ModalGenerator } from '../../../utils';

@Component({
  selector: 'app-footer-version',
  templateUrl: './footer-version.component.html',
  styleUrls: ['./footer-version.component.scss'],
})
export class FooterVersionComponent implements OnInit {
  version = version
  fecha = new Date();

  company: Company;
  type = type;

  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;


  constructor(
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private skinService: SkinMangementService,
    private modalGenerator: ModalGenerator
    ) { }

  ngOnInit() {
    this.company = this.skinService.getCompany();
  }

  async showSalesCancellation() {
    this.modalGenerator.show(SalesCancelationComponent)
  }


  closeModal() {
    this.modalCtrl.dismiss({
    });
  }






}
