// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-div {
  text-align: center;
  display: block;
  margin-right: 10%;
}

.center-left {
  text-align: left;
  display: block;
}

.custom-pagination ::ng-deep .ngx-pagination .current {
  background: var(--color-current-bg) !important;
  color: var(--color-light) !important;
}

.custom-pagination ::ng-deep .ngx-pagination a {
  color: var(--color-dark);
}

.dark-custom ::ng-deep .ngx-pagination :hover {
  color: var(--ion-color-hover-paginator);
}

.custom-pagination ::ng-deep .ngx-pagination .pagination-previous.disabled {
  color: var(--ion-color-medium);
}

.dark-custom ::ng-deep .ngx-pagination .pagination-previous.disabled :hover {
  color: var(--ion-color-medium);
  background-color: transparent;
}

.dark-custom ::ng-deep .ngx-pagination .pagination-next.disabled {
  color: var(--ion-color-medium);
}

.dark-custom ::ng-deep .ngx-pagination .pagination-next.disabled :hover {
  color: var(--ion-color-medium);
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/paginator/paginator.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,8CAAA;EACA,oCAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAGA;EACE,uCAAA;AAAF;;AAGA;EACE,8BAAA;AAAF;;AAGA;EACE,8BAAA;EACA,6BAAA;AAAF;;AAGA;EACE,8BAAA;AAAF;;AAIA;EACE,8BAAA;EACA,6BAAA;AADF","sourcesContent":[".center-div{\r\n  text-align:center;\r\n  display: block;\r\n  margin-right: 10%;\r\n}\r\n\r\n.center-left{\r\n  text-align:left;\r\n  display: block;\r\n}\r\n\r\n.custom-pagination ::ng-deep .ngx-pagination .current {\r\n  background: var(--color-current-bg) !important;\r\n  color: var(--color-light) !important;\r\n}\r\n\r\n.custom-pagination ::ng-deep .ngx-pagination a {\r\n  color: var(--color-dark);\r\n}\r\n\r\n\r\n.dark-custom ::ng-deep .ngx-pagination :hover{\r\n  color: var(--ion-color-hover-paginator);\r\n}\r\n\r\n.custom-pagination ::ng-deep .ngx-pagination .pagination-previous.disabled {\r\n  color: var(--ion-color-medium);\r\n}\r\n\r\n.dark-custom  ::ng-deep .ngx-pagination .pagination-previous.disabled :hover {\r\n  color: var(--ion-color-medium);\r\n  background-color: transparent;\r\n}\r\n\r\n.dark-custom  ::ng-deep .ngx-pagination .pagination-next.disabled{\r\n  color: var(--ion-color-medium);\r\n}\r\n\r\n\r\n.dark-custom  ::ng-deep .ngx-pagination .pagination-next.disabled :hover{\r\n  color: var(--ion-color-medium);\r\n  background-color: transparent;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
