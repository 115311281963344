import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { Platform } from '@ionic/angular';
import { type } from '../../../utils/constants';
import { Company } from 'src/app/models/';
import {
  ModalController,
  ToastController,
  IonInfiniteScroll,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from '../../../share/services/languageRoute.ts/languagesRoute.services';
import { SkinMangementService } from '../../../services/skin-mangement.service';
import { Card, ParamsGetCardByCardNum } from '../../../models/Card.model';
import { isApkAndroid, presentToast } from '../../../utils';
import { CardService } from 'src/app/share/services/card/card.service';
import { FilterCardNumService } from './service/filter-card-num.service';

import { UtilsTranslate } from '../../../utils/utils-translate';

@Component({
  selector: 'app-filter-card-num',
  templateUrl: './filter-card-num.component.html',
  styleUrls: ['./filter-card-num.component.scss'],
})
export class FilterCardNumComponent implements OnInit {
  @Input() skip: number = null;
  @Input() operatorGroupId: number = null;
  @Input() operatorId: number = null;
  @Input() cardNum: string = '';
  @Input() positionCardNum: number = 0;
  @Input() valid: number = 1;
  @Input() haveBankCardOption: boolean = false;
  @Input() clientId: number = null;

  // aspecto
  company: Company;
  type = type;

  cardSelected = null;
  errorEndPoint: string;

  cards: Card[] = [];

  typeFilter: TypeFilterCardFilter = 'cardNum';
  bankCardNum: string = '';
  bankCardNumAnterior: string = '';



  loading = true;
  loadingChange = false;

  // translates
  bankCardErrorTranslate = '';

  getCardNum: Subscription = null;
  getCardsNum: Subscription = null;

  changeLanguage$: Subscription;
  filterChange$: Subscription;


  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  cardNumPrev: string = '';

  constructor(
    public readonly platform: Platform,
    private toastCrl: ToastController,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _filterService: FilterCardNumService,

    // private _cardService: CardService,
    private utils: UtilsTranslate
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    if (this.haveBankCardOption) {
      this.typeFilter = this._filterService.getBankCardOption();
      //this.typeFilter = 'bankCard';
    }

    if (this.typeFilter == 'cardNum') {
      let { cardNum, cardId } = this._filterService.getCard(
        this.positionCardNum
      );
      this.cardNum = cardNum;
      this.cardSelected = cardId;
    } else {
      this.bankCardNum = this._filterService.getCard()?.cardNum?.toString();
    }

    let params = {
      operatorId: this.operatorId,
      operatorGroupId: this.operatorGroupId,
      cardNum: this.cardNum,
      clientId: this.clientId,
      skip: this.cards.length,
      valid: this.valid,
    };

    const paramsCardByCardNum: ParamsGetCardByCardNum = {
      cardNum: this.cardNum,
      clientId: this.clientId,
      operatorId: this.operatorId,
    };

    this.getCardNum = this._filterService
      .getCardByCardNum(paramsCardByCardNum)
      .subscribe(
        {next: (card) => {
          this.getCardsNum = this._filterService.getCards(params).subscribe(
            (res) => {
              this.cards = res;
              if (card && card?.cardNum !== '') {
                this.cards = this.cards.filter(
                  (cardAll) => cardAll.id !== card.id
                );
                this.cards.unshift(card);
              }
              this.loading = false;
            },
            (error) => {
              this.utils.presentToastTranslate('error', true, 0);
              this.loading = false;
            }
          );
        },
        error: () => {
          this.utils.presentToastTranslate('error', true, 0);
          this.loading = false;
        }}
      );

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe((valor) => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });

    this.filterChange$ = this._filterService.filterChange.subscribe((res) => {
      if (res.positionCardNum == this.positionCardNum) {
        this.cardNum = res.cardNum;
        this.cardSelected = res.cardId;
      }
    });
  }

  ngOnDestroy(): void {
    if(this.changeLanguage$) this.changeLanguage$.unsubscribe()
    if(this.filterChange$) this.filterChange$.unsubscribe()
  }

  changeCardNum() {
    this.cardSelected = null;
    this.inifiteScroll.disabled = false;

    let params = {
      operatorId: this.operatorId,
      operatorGroupId: this.operatorGroupId,
      clientId: this.clientId,
      cardNum: this.cardNum,
      skip: 0,
      valid: this.valid,
    };


    this.cards = [];

    this.loadingChange = true;


    const paramsCardByCardNum: ParamsGetCardByCardNum = {
      cardNum: this.cardNum,
      clientId: this.clientId,
      operatorId: this.operatorId,
    };

    if (this.getCardsNum) this.getCardsNum.unsubscribe();
    if (this.getCardNum) this.getCardNum.unsubscribe();

    this.getCardNum = this._filterService
      .getCardByCardNum(paramsCardByCardNum)
      .subscribe(
        {next: (card) => {
          this.getCardsNum = this._filterService.getCards(params).subscribe(
            {next: (res) => {
              this.cards = res;
              if (card && card?.cardNum !== '') {
                this.cards = this.cards.filter(
                  (cardAll) => cardAll.id !== card.id
                );
                this.cards.unshift(card);
              }
              this.loadingChange = false;
              this.loading = false;
            },
            error: () => {
              this.utils.presentToastTranslate('error', true, 0);
              this.loadingChange = false;
              this.loading = false;
            }}
          );
        },
        error: () => {
          this.utils.presentToastTranslate('error', true, 0);
          this.loadingChange = false;
          this.loading = false;
        }}
      );

  }


  closeModal() {
    if (this.modalCtrl) {
      this.modalCtrl.dismiss({});
    }
  }

  filterCardFilter() {


    if (this.typeFilter == 'cardNum') {
      let selectFilter: Card | null = this.cards.find(
        (card) => card.id == this.cardSelected
      );
      if (selectFilter) {

        this._filterService.setCardNum(
          selectFilter.cardNum,
          selectFilter.id,
          this.positionCardNum
        );
        this.modalCtrl.dismiss({
          id: selectFilter.id,
          cardNum: selectFilter.cardNum,
        });
      } else {
        presentToast(this.errorEndPoint, this.toastCrl);
      }
    } else {
      if (this.bankCardNum.length != 16 || !this.bankCardNum.includes('*')) {
        presentToast(this.bankCardErrorTranslate, this.toastCrl);
      } else {
        // 6N + 6* + 4N
        for (let i = 0; i < this.bankCardNum.length; i++) {
          let typePos = i > 5 && i < 12 ? '*' : 'number';
          if (typePos == '*' && this.bankCardNum[i] != '*') {

            presentToast(this.bankCardErrorTranslate, this.toastCrl);
            return;
          } else if (
            typePos == 'number' &&
            (this.bankCardNum[i] < '0' || this.bankCardNum[i] > '9')
          ) {
            presentToast(this.bankCardErrorTranslate, this.toastCrl);
            return;
          }
        }

        this._filterService.setBankCard(this.bankCardNum);
        this.modalCtrl.dismiss({});
      }
    }
  }

  deleteCardFilter() {
    this.cardNum = '';
    this.bankCardNum = '';
    this.typeFilter = 'cardNum';

    this._filterService.resetCard(this.positionCardNum);
    this.modalCtrl.dismiss({
      id: null,
      cardNum: '',
    });
  }

  loadData(event) {
    let params = {
      operatorId: this.operatorId,
      operatorGroupId: this.operatorGroupId,
      cardNum: this.cardNum,
      clientId: this.clientId,
      skip: this.cards.length,
      valid: this.valid,
    };
    this._filterService.getCards(params).subscribe((res) => {
      if (res.length > 0) {
        let cardsAux = [...this.cards, ...res];
        cardsAux = cardsAux.filter(
          (card, i, a) => a.findIndex((card2) => card2.id === card.id) === i
        );
        this.cards = cardsAux;

        this.inifiteScroll.complete();
      } else {
        this.inifiteScroll.complete();
        this.inifiteScroll.disabled = true;
      }
    });
  }

  //TARJETA BANCARIA
  bankCardChange(event) {

    if (this.bankCardNum.length == 0) {
      this.bankCardNumAnterior = this.bankCardNum;
    }

    if (
      this.bankCardNum.length > this.bankCardNumAnterior.length &&
      this.bankCardNum.length == 6 &&
      !this.bankCardNum.includes('*')
    ) {
      this.bankCardNum += '******';
    }
    this.bankCardNumAnterior = this.bankCardNum;
  }

  savePlaceholders() {
    this.translate
      .get(['transaction_query.error_4_digits', 'tag_status.errorEndPoint'])
      .subscribe((translates) => {
        this.bankCardErrorTranslate =
          translates['transaction_query.error_4_digits'];
        this.errorEndPoint = translates['tag_status.errorEndPoint'];
      });
  }

  changeCardNumDelay() {
    setTimeout(() => {
      this.changeCardNum();
    }, 400);
  }

  detectDeleteInAndroid() {
    if (
      this.cardNum.length < this.cardNumPrev.length &&
      isApkAndroid(this.platform)
    ) {
      this.changeCardNum()
    }

    this.cardNumPrev = this.cardNum;
  }
}

export type TypeFilterCardFilter = 'cardNum' | 'bankCard';
