
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from '../../../../../../../../services/skin-mangement.service';
import { LanguageRouteService } from '../../../../../../../../share/services/languageRoute.ts/languagesRoute.services';
import { VisibilitySelectService } from '../../../../../../../../share/services/visibility-select/visibility-select.service';

// Models
import { Company } from 'src/app/models/';

// Utils
import { type } from '../../../../../../../../utils/constants';


@Component({
  selector: 'app-legend-load-credit-unlimited',
  templateUrl: './legend-load-credit-unlimited.component.html',
  styleUrls: ['./legend-load-credit-unlimited.component.scss'],
})
export class LegendLoadCreditUnlimitedComponent implements OnInit {

  // aspecto
  company: Company;
  type = type;
  legendText = ''

  changeLanguage$: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _visibilityService: VisibilitySelectService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });

  }

  ngOnDestroy(): void {
    if(this.changeLanguage$) this.changeLanguage$.unsubscribe()
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  savePlaceholders(){
    this.translate.get(['load_credit_unlimited.legend_1', 'load_credit_unlimited.legend_2'])
        .subscribe((translates) => {
          let currency = this._visibilityService.getCurrency()
          if(currency === '$'){
            this.legendText = translates['load_credit_unlimited.legend_1']
                                    .split('{currency}')
                                    .join(currency)
          }else{
            this.legendText = translates['load_credit_unlimited.legend_2']
                                    .split('{currency}')
                                    .join(currency)
          }
    });
  }

}
