import { TemplateRef, WritableSignal } from '@angular/core';
import { ComponentProps } from '@ionic/core';

// components
import { CreateEditReportModalComponent } from '../../components/modals';

// models
import { Company } from 'src/app/models';
import { CreateEditDataReport, CreateEditReportModalProps, Report, TypeReport } from '../../models';
import { DatePickerFilterOptions } from 'src/app/share/components/filter-date-template/models';

// service
import { CardsByTextReportService, FilterReportService } from '../../services';

// utils
import { getStyleSizeBiggerModal, ModalGenerator } from 'src/app/utils';
import { setVisibilityByReportSelected } from './set-visibility-by-report-selected';

interface ShowModalEditReport {
  modalGenerator: ModalGenerator;
  isLightColor: boolean;
  width: number;
  report: Report;
  company: Company;
  _filterReportService: FilterReportService;
  _cardsByTextReportService: CardsByTextReportService;
  dateHourInModal: TemplateRef<string>;
  datePickerFilter: WritableSignal<DatePickerFilterOptions>;
  typeReports: TypeReport[];
}

export const showModalEditReport = async ({
  modalGenerator,
  isLightColor,
  width,
  report,
  company,
  _filterReportService,
  _cardsByTextReportService,
  dateHourInModal,
  datePickerFilter,
  typeReports,
}: ShowModalEditReport): Promise<CreateEditDataReport> => {

  _cardsByTextReportService.reset()

  setVisibilityByReportSelected(report, _filterReportService);

  const props: ComponentProps & CreateEditReportModalProps = {
    company: company,
    typeReports: typeReports,
    emails: report?.emails,
    reportName: report?.reportName,
    typeReportSelected: report?.typeReport?.typeReportID,
    modifiedDate: report?.firtsExecutionDate,
    isEdit: true,
    dateHourInModal: dateHourInModal,
    datePickerFilter: datePickerFilter,
    reportId: report?.reportId,
    reportEnable: report?.enabled,
    sendingFormatSelected: (report?.SendingFormat || '1').toString(),
  };

  console.log('props', props);

  const styleModal = getStyleSizeBiggerModal(isLightColor, width);

  return await modalGenerator.showWithCss(
    CreateEditReportModalComponent,
    props,
    styleModal
  );
};
