import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

// models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-filter-hour-pc',
  templateUrl: './filter-hour-pc.component.html',
  styleUrls: ['./filter-hour-pc.component.scss'],
})
export class FilterHourPcComponent implements OnInit {

  @Input() startHour;
  @Input() endHour;
  @Input() showTextHourComplete: boolean = false;
  @Input() showTextHour: boolean = true;


  @Output() hourSelected = new EventEmitter();

  constructor() { }

  ngOnInit() {}


  setHourStart(hour){
    this.hourSelected.emit({hour, pos: 0})
  }

  setHourEnd(hour){
    this.hourSelected.emit({hour, pos: 1})
  }
}
