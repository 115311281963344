import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSegment, Platform } from '@ionic/angular';

// swiper
import { SwiperComponent } from 'swiper/angular';
import Swiper from 'swiper';
import {  Pagination, SwiperOptions } from 'swiper';

// Service
import { VisibilityClientSelectService } from '../service/';

// Models
import { Company } from 'src/app/models';

// Utils
import { pcWidth } from 'src/app/utils';


@Component({
  selector: 'app-double-date-filter',
  templateUrl: './double-date-filter.component.html',
  styleUrls: ['./double-date-filter.component.scss'],
})
export class DoubleDateFilterComponent implements OnInit {

  @Input() company: Company;
  @Input() pcWidth = pcWidth;

  config: SwiperOptions = {
    pagination: { clickable: true },
    keyboard: true
  };


  segment = '0';

  @ViewChild(IonSegment) segment2: IonSegment;
  @ViewChild('swiperVisibilityClientSelect', { static: false }) swiper: SwiperComponent;

  constructor(
    public readonly platform: Platform,
    private _visibilityClientSelectService: VisibilityClientSelectService) { }

  ngOnInit() {
    this._visibilityClientSelectService.changeTypeDate(0)
    Swiper.use([Pagination])
  }

  segmentChanged() {
    this.swiper?.swiperRef?.slideTo(Number(this.segment));
    this._visibilityClientSelectService.changeTypeDate(Number(this.segment))
  }

  async slideChange() {
    this.segment2.value = (this.swiper?.swiperRef?.activeIndex).toString();
  }

  changeType(){
    this._visibilityClientSelectService.changeTypeDate(Number(this.segment))
  }


}
