import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Models
import { Company } from 'src/app/models';

// Service
import { SkinMangementService } from '../../../../../../../services/skin-mangement.service';

@Component({
  selector: 'app-legend-inventory',
  templateUrl: './legend-inventory.component.html',
  styleUrls: ['./legend-inventory.component.scss'],
})
export class LegendInventoryComponent implements OnInit {

  company: Company;

  constructor(
    private modalCtrl: ModalController,
    private skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this.skinService.getCompany()
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
