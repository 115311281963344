import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { PermissionService } from 'src/app/services';

// utils
import { NAME_PICKING_SALES_MODULE, ROUTE_SALES_MODULE } from '../utils';

@Injectable({ providedIn: 'root' })
export class MachinesModuleResolverService {
  constructor(private _permissionService: PermissionService) {}

  resolve(): Observable<boolean> {
    return this._permissionService.getAllPermissions().pipe(
      map((res) => {
        const isPicking = res.find(
          (permission) =>
            permission.route === ROUTE_SALES_MODULE &&
            permission.name === NAME_PICKING_SALES_MODULE
        );
        return !!isPicking;
      })
    );
  }
}
