import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeLanguage'
})
export class TypeLanguagePipe implements PipeTransform {

  transform(value: string, translates: {[name: string]: string}): string {
    if(translates[value]){
      return translates[value]
    }
    return value;
  }

}
