import { Company } from '../../app/models/';

export const Rocca: Company = {
  tag: 'rocca',
  companySkin: 'LaRocca',
  name: 'La Rocca',
  environment: 'baseURLRocca',
  appConfig: 'BaseURLRocca',

  nameCard: 'Tarjeta Cautiva',
  minCard: 6,
  maxCard: 9,
  url: 'larocca.v2vfactory.com',
  url_test: 'larocca.v2vfactory.com',
  url_local: 'localhost',

  companyId: 1,
  operatorGroupId: 51,

  color: 'rocca-primary',
  color2: 'rocca2',
  color3: 'rocca3',
  colorFilter: 'filter-rocca',
  colorText: 'rocca-primary-text',
  colorText2: 'rocca-text2',
  colorNavBar: 'rocca-light',
  colorMenuText: 'dark',

  bgColor1: 'rocca-color-toolbar',
  colorTextTitle: 'rocca-color-text-title',
  titlePage: 'rocca-color-text-title',

  // rgb
  colorRGB: '#0091b2',

  // sliders
  colorBullets: 'color-bullet-rocca',

  // Menu
  activeLink: 'active-link-rocca',
  groupItemMenu: 'group-item-menu-rocca',
  itemMenu: 'group-item-menu-rocca',

  // Profile
  ionCardHeader: 'ion-card-header-rocca',
  ionCardText1: 'ion-card-text1-rocca',
  ionCardText2: 'ion-card-text2-rocca',
  ionTextCardFooter: 'text-card-footer-rocca',

  // date-period
  ionSegmentButtonStyle: 'ion-segment-button-rocca-style',
  ionSegmentButtonStyle2: 'ion-segment-button-rocca-style2',
  tableFilter: 'filter-table-rocca',

  bgNavbar: 'bg-navbar-rocca',
  openMenuColor: 'rocca-primary',

  // table
  bgTable: 'bg-table-rocca',
  bgTable1: 'bg-table-switch-odd-rocca',
  bgTable2: 'bg-table-switch-pair-rocca',
  bgTable3: 'bg-filter-rocca',
  bgHoverTable: 'bg-hover-table-rocca',
  bgHoverTableWithoutFilter: 'bg-hover-table-sm-rocca',
  subHeaderTable: 'subheader-table-rocca',
  headerVerticalTable: 'header-vertical-table-rocca',
  // site
  blockTable: 'block-table-rocca',
  bgTableBlockHover: 'bg-table-block-hover-rocca',
  bgTableSelected: 'bg-table-selected-rocca',

  // spinner
  spinnerColor: 'spinner-color-rocca',

  //label not found
  labelDialog: 'label-dialog-rocca',

  // top 5, product sold
  labelDialogLoading: 'label-dialog-loading-rocca',

  // card (load-credit-card)
  cardTop: 'card-top-rocca',
  cardBottom: 'card-bottom-rocca',

  infoAlert: 'info-alert-rocca',

  segmentIos: 'segment-ios-rocca',

  // Chart
  colorRGBTitleDark: '#55B6CC',
  chartColors: [
    {
      backgroundColor: [
        '#0091B2',
        '#1299B8',
        '#24A1BD',
        '#37A9C3',
        '#49B0C8',
        '#5BB8CE',
        '#6DC0D3',
        '#80C8D9',
        '#92D0DE',
        '#A4D8E4',
      ],
      borderColor: [
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
      ],
    },
  ],
  chartColorsDark: [
    {
      backgroundColor: [
        '#A4D8E4',
        '#92D0DE',
        '#80C8D9',
        '#6DC0D3',
        '#5BB8CE',
        '#49B0C8',
        '#37A9C3',
        '#24A1BD',
        '#1299B8',
        '#0091B2',
      ],
      borderColor: [
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
      ],
    },
  ],

  ionColorToogle: 'ion-color-toogle-rocca',

  // online shop
  borderCard: 'border-card-rocca',
  borderCart: 'border-cart-rocca',
  border: 'border-rocca',

  // ecommerce-manager, visibility manager
  bgTable1Item: 'bg-table1-item-rocca',
  bgTable2Item: 'bg-table2-item-rocca',
  colorTextNoEditable: 'color-no-editable-text-rocca',
  colorTextNoEditableItem: 'var(--ion-color-rocca-not-editable)',

  // notification-manager
  buttonInfo: {
    backgroundColor: '#0091b2',
    borderColor: '#0091b2',
    textColor: '#999999',
    text: '',
    url: 'larocca.v2vfactory.com',
  },

  // access-web
  segmentVertical: 'segment-rocca-vertical',
  swiperBgAndBorder: 'swiper-rocca-bg',
  itemCheckboxAccessWeb: 'item-checkbox-rocca-access-web',
  checkboxAccessWeb: 'checkbox-rocca-access-web',
  bgColorContent: 'bg-color-content-rocca',
  borderHeadDark: 'border-head-dark-rocca',

  // range slider (sales-module)
  colorBallRange: 'var(--ion-color-rocca-primary-tint)',
  colorLineRange: 'var( --ion-color-rocca-primary)',
  colorInsideBallRange: 'var(--ion-color-rocca-contrast)',
  colorInsideBallRangeActive: 'var(--ion-color-rocca-primary-select-dark)',

  // pagination
  paginationCurrentBg: 'var(--ion-color-rocca-primary)',
  paginationColorDark: 'var(--ion-color-dark)',
  paginationColorLight: 'var(--ion-color-light)',

  // tag status
  colorIconWithHoverTable: 'color-icon-with-hover-table-rocca',

  // pre login
  colorCompanyText: 'var(--ion-color-rocca-primary-text)',
  colorBorderCheckBox: 'var(--ion-color-login-rocca-checkbox)',
  colorCompanyText2: 'var(--ion-color-rocca-primary-text)',

  // custom alert
  customAlertStyle: 'custom-alert-rocca',

  // ecommerce manager
  colorButtonTable: 'rocca-button-table',
  colorButtonTableHover: 'var(--ion-color-rocca-primary-button-table-hover)',

  // chart v4
  colorsCharts: [
    '#0091B2',
    '#1299B8',
    '#24A1BD',
    '#37A9C3',
    '#49B0C8',
    '#5BB8CE',
    '#6DC0D3',
    '#80C8D9',
    '#92D0DE',
    '#A4D8E4',
  ],

  backgroundChart: [
    '#0091B2',
    '#1299B8',
    '#24A1BD',
    '#37A9C3',
    '#49B0C8',
    '#5BB8CE',
    '#6DC0D3',
    '#80C8D9',
    '#92D0DE',
    '#A4D8E4',
  ],

  backgroundChartDark: [
    '#A4D8E4',
    '#92D0DE',
    '#80C8D9',
    '#6DC0D3',
    '#5BB8CE',
    '#49B0C8',
    '#37A9C3',
    '#24A1BD',
    '#1299B8',
    '#0091B2',
  ],

  borderChart: [
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
  ],

  borderChartDark: [
    '#1b1b1b',
    '#1b1b1b',
    '#1b1b1b',
    '#1b1b1b',
    '#1b1b1b',
    '#1b1b1b',
    '#1b1b1b',
    '#1b1b1b',
    '#1b1b1b',
    '#1b1b1b',
  ],
  donutBackground: ['rgba(0, 145, 178, 1)', 'rgba(217, 83, 79, 1)'],
  donutBackgroundDark: ['rgba(85, 182, 204, 1)', 'rgba(207, 102, 121, 1)'],

  colorTextVar: 'var(--ion-color-rocca-primary-text)',
  colorTextHover: 'var(--ion-color-rocca-text-hover)',

  // phone material
  colorTableVisibilityBg: 'var(--ion-color-rocca-tint-v2-switch)',
  colorTableVisibilityPlaceholder: '#bfe0e9',
  colorTablePhoneLightEg: 'rocca-table-phone-light-eg',

  // till-alert
  successTable: 'rocca-table-success',
  qrRequestColorTable: 'qr-request-rocca',

  // notification-panel
  colorAndroidIconTable: 'var(--ion-color-rocca-android-icon)',

  // dashboard welcome
  colorMonthBar: [
    '#F4F0ED',
    '#EBE4DF',
    '#E2D8D0',
    '#D9CCC2',
    '#D0C0B4',
    '#C7B4A5',
    '#BFA897',
    '#B69C88',
    '#AD907A',
    '#A4846C',
    '#9B785D',
    '#926C4F',
  ],

  colorMonthBarDark: [
    '#926C4F',
    '#9B785D',
    '#A4846C',
    '#AD907A',
    '#B69C88',
    '#BFA897',
    '#C7B4A5',
    '#D0C0B4',
    '#D9CCC2',
    '#E2D8D0',
    '#EBE4DF',
    '#F4F0ED',
  ],

  customPopoverDashboard: 'custom-popover-dashboard-rocca',

  homeBG: 'rocca-home',

  // User Documentation
  colorChipSuccess: 'var(--ion-color-rocca-primary-text)',

  colorCardBg: 'alliance-card-bg',
};
