// models
import { MachineAlertShowInfo } from '../../models';

// utils
import { ACTIVATE_DESACTIVATE_TEXT_TRANSLATE } from '../';

export const getActivateDesactivateTextTranslate = (
  alertsSelected: MachineAlertShowInfo[]
) => {

  if((alertsSelected || []).length === 0){
    return ACTIVATE_DESACTIVATE_TEXT_TRANSLATE.desactivate;
  }

  const someSelected = alertsSelected.some((alert) => alert.enable);
  const someNotSelected = alertsSelected.some((alert) => !alert.enable);

  if (someSelected && someNotSelected) {
    return ACTIVATE_DESACTIVATE_TEXT_TRANSLATE.activateDesactivate;
  }

  if (someSelected) {
    return ACTIVATE_DESACTIVATE_TEXT_TRANSLATE.desactivate;
  }

  return ACTIVATE_DESACTIVATE_TEXT_TRANSLATE.activate;
};
