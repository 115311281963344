import { WritableSignal } from '@angular/core';

// models
import { CardNames, Company } from 'src/app/models';
import {
  SelectCardsOptionsReport,
  StepCreateEditReport,
} from '../../../models';

// services
import {
  CardsByTextReportService,
  FilterReportService,
  ReportsService,
} from '../../../services';

// utils
import { UtilsTranslate } from 'src/app/utils';
import { isValidCardGuidedReport } from './guided';
import { isValidCheckCardsByTextReport } from './text';

interface IsValidCardReport {
  company: Company;
  showMultipleCards: boolean;
  selectCardSegment: WritableSignal<SelectCardsOptionsReport>;
  _reportService: ReportsService;
  _filterReportService: FilterReportService;
  _cardsByTextReportService: CardsByTextReportService;
  showerStep: Record<StepCreateEditReport, boolean>;
  utils: UtilsTranslate;
}

export const isValidCardReport = async ({
  company,
  _reportService,
  _filterReportService,
  _cardsByTextReportService,
  showMultipleCards,
  selectCardSegment,
  showerStep,
  utils,
}: IsValidCardReport) => {
  if (!showMultipleCards) {
    return true;
  }

  if (Number(selectCardSegment()) === SelectCardsOptionsReport.Guided) {
    return isValidCardGuidedReport({
      company,
      _filterReportService,
      utils,
    });
  }

  if (!_cardsByTextReportService.isValidCardText()) {
    utils.presentToastTranslate(
      company.nameCard === CardNames.arbitradePay
        ? 'reports.create_edit.error_invalid_card_alliancepay'
        : 'reports.create_edit.error_invalid_card'
    );
  }

  return _cardsByTextReportService.isValidCardText();

  // return await isValidCheckCardsByTextReport({
  //   company,
  //   _reportService,
  //   _filterReportService,
  //   _cardsByTextReportService,
  //   showerStep,
  //   utils,
  // });
};
