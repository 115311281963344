import { Component, ElementRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Platform } from '@ionic/angular';

// services
import { SkinMangementService } from '../../../../../../services/skin-mangement.service';

// models
import { Company } from 'src/app/models';

// utils
import { pcWidth } from '../../../../../../utils';
import { CheckSegment } from '../../../models';

@Component({
  selector: 'app-filter-date-input',
  templateUrl: './filter-date-input.component.html',
  styleUrls: ['./filter-date-input.component.scss'],
})
export class FilterDateInputComponent implements OnInit {

  @Input() dateText: string = '';
  @Input() checkSegment: CheckSegment;
  @Input() hideIconBySize: boolean = false;

  @Output() showDateInput = new EventEmitter()

  company: Company;
  pcWidth = pcWidth;

  constructor(
    public readonly platform: Platform,
    private _skinMangementService: SkinMangementService,
    private elem: ElementRef,
  ) { }

  ngOnInit() {

    this.company = this._skinMangementService.getCompany()

    this.elem.nativeElement.style.setProperty(
      '--icon-color',
      this.company.colorTextVar
    );

    this.elem.nativeElement.style.setProperty(
      '--icon-color-hover',
      this.company.colorTextHover
    );

  }

  showSelectedDate(){
    this.showDateInput.emit()
  }

}
