import { Component, OnInit, Input } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { MAX_INTERVAL_CARDS } from '../../../utils';
import { pcWidth } from '../../../../../../../utils';

@Component({
  selector: 'app-alert-max-cards-interval',
  templateUrl: './alert-max-cards-interval.component.html',
  styleUrls: ['./alert-max-cards-interval.component.scss'],
})
export class AlertMaxCardsIntervalComponent  implements OnInit {
  @Input() company: Company

  MAX_INTERVAL_CARDS = MAX_INTERVAL_CARDS;
  pcWidth = pcWidth;

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}

}
