// models
import { FilterDateServiceI } from 'src/app/models';

// utils
import { UtilsTranslate, getTimeByHourAndMinute } from 'src/app/utils';

interface IsRangeHoursIncludes {
  _filterDateService: FilterDateServiceI;
  _filterExtraHourDateService: FilterDateServiceI;
  utils: UtilsTranslate;
}

export const isRangeHoursIncludesMachineAlert = ({
  _filterDateService,
  _filterExtraHourDateService,
  utils,
}: IsRangeHoursIncludes) => {
  const timeStart = getTimeByHourAndMinute(_filterDateService.getStartHour());
  const timeEnd = getTimeByHourAndMinute(_filterDateService.getEndHour());

  const timeStart2 = getTimeByHourAndMinute(
    _filterExtraHourDateService.getStartHour()
  );
  const timeEnd2 = getTimeByHourAndMinute(
    _filterExtraHourDateService.getEndHour()
  );

  if (timeStart2 >= timeStart && timeStart2 <= timeEnd) {
    utils.presentToastTranslate(
      'machines_status.machine_status_alert.error.error_range_hour'
    );
    return true;
  }

  if (timeEnd2 >= timeStart && timeEnd2 <= timeEnd) {
    utils.presentToastTranslate(
      'machines_status.machine_status_alert.error.error_range_hour'
    );
    return true;
  }

  if (timeStart >= timeStart2 && timeStart <= timeEnd2) {
    utils.presentToastTranslate(
      'machines_status.machine_status_alert.error.error_range_hour2'
    );
    return true;
  }

  if (timeEnd >= timeStart2 && timeEnd <= timeEnd2) {
    utils.presentToastTranslate(
       'machines_status.machine_status_alert.error.error_range_hour2'
    );
    return true;
  }

  return false;
};
