import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Service
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../share/services/languages.service';
import { SkinMangementService } from '../../../../services/skin-mangement.service';

// Model
import { Company } from '../../../../models';

// Utils
import { type } from '../../../../utils/constants';

@Component({
  selector: 'app-send-email-force-password',
  templateUrl: './send-email-force-password.component.html',
  styleUrls: ['./send-email-force-password.component.scss'],
})
export class SendEmailForcePasswordComponent implements OnInit {

  company: Company;
  type = type;
  timeClose: number = 10000;


  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _languageService: LanguageService,
    private skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this.skinService.getCompany();
    this.closeInit();
  }

  closeInit(){
    setTimeout(()=> {
      this.closeModal();
    },this.timeClose)
  }

  closeModal(){
    this.modalCtrl.dismiss({

    });
  }

}
