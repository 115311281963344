import { WritableSignal } from '@angular/core';

// models
import { DatePickerFilterOptions } from 'src/app/share/components/filter-date-template/models';

// services
import { FilterDateServiceI } from 'src/app/models';
import { ONLY_DAYS_MONTH_REPORT } from '../constants';

// utils
import { addTimeDate, getTomorrowDay } from 'src/app/utils';

interface ApplyChangeTypeReportMonthly {
  datePickerFilter: WritableSignal<DatePickerFilterOptions>;
  _filterDateService: FilterDateServiceI;
}

interface SetFirstValidDateBySelectedDate {
  startDate: Date;
  _filterDateService: FilterDateServiceI;
  tomorrow: Date;
}

export const applyChangeTypeReportMonthly = ({
  datePickerFilter,
  _filterDateService,
}: ApplyChangeTypeReportMonthly) => {
  datePickerFilter.set(DatePickerFilterOptions.OnlyDaysMonthReport);

  changeDateToFirstDayValid(_filterDateService);
};

const changeDateToFirstDayValid = (_filterDateService: FilterDateServiceI) => {
  const startDate = new Date(_filterDateService.getDateStart()) ?? new Date();
  const dateSelected = startDate.getDate();

  if (!ONLY_DAYS_MONTH_REPORT.includes(dateSelected)) {
    const tomorrow = getTomorrowDay();
    if (startDate.getTime() > tomorrow.getTime()) {
      setFirstValidDateBySelectedDate({
        startDate,
        _filterDateService,
        tomorrow,
      });
    } else {
      setDateByFirstDateValid(tomorrow, _filterDateService);
    }
  }
};

// const getDaysValids = (weekDay: number): { prev: number; next: number } => {
//   if ((ONLY_DAYS_MONTH_REPORT || []).length !== 3) {
//     return;
//   }

//   const days: { prev: number; next: number }[] = [
//     { prev: weekDay[0], next: weekDay[1] },
//     { prev: weekDay[1], next: weekDay[2] },
//     { prev: weekDay[2], next: weekDay[0] },
//   ];

//   if (
//     weekDay >= ONLY_DAYS_MONTH_REPORT[0] &&
//     weekDay < ONLY_DAYS_MONTH_REPORT[1]
//   ) {
//     return days[0];
//   } else if (
//     weekDay >= ONLY_DAYS_MONTH_REPORT[1] &&
//     weekDay < ONLY_DAYS_MONTH_REPORT[2]
//   ) {
//     return days[1];
//   } else {
//     return days[2];
//   }
// };

const setFirstValidDateBySelectedDate = ({
  startDate,
  _filterDateService,
  tomorrow,
}: SetFirstValidDateBySelectedDate) => {
  // const daysValids = getDaysValids(weekDay);
  // if (daysValids) {
  //   const { prev, next } = daysValids;
  //   const prevDay = getDayByWeekDay(startDate, prev);
  //   if(prevDay.getTime() >= tomorrow.getTime()){
  //     _filterDateService.setDate(prevDay, 0)
  //   }else{
  //     const prevDay = getDayByWeekDay(startDate, next);
  //   }
  // }

  const ORDER_DATE_VALIDS = getOrderDateValids(startDate);

  for (const weekdayByOrder of ORDER_DATE_VALIDS) {
    const dateWeekDay = getDayByWeekDay(startDate, weekdayByOrder);
    if (dateWeekDay.getTime() >= tomorrow.getTime()) {
      _filterDateService.setDate(dateWeekDay, 0);
      return;
    }
  }

  for (const weekdayByOnlyDays of ONLY_DAYS_MONTH_REPORT) {
    let dateWeekDay = getDayByWeekDay(startDate, weekdayByOnlyDays);
    dateWeekDay = addTimeDate({
      startDate: dateWeekDay,
      increment: 1,
      typeIncrement: 'months',
    });
    // console.log('dateWeekDay', dateWeekDay);

    if (dateWeekDay.getTime() >= tomorrow.getTime()) {
      _filterDateService.setDate(dateWeekDay, 0);
      return;
    }
  }
};

const setDateByFirstDateValid = (
  tomorrow: Date,
  _filterDateService: FilterDateServiceI
) => {
  setFirstValidDateBySelectedDate({
    startDate: tomorrow,
    _filterDateService,
    tomorrow,
  });
};

const getDayByWeekDay = (date: Date, weekDay: number) => {
  return new Date(
    `${date.getFullYear().toString()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${weekDay.toString().padStart(2, '0')}`
  );
};

const getOrderDateValids = (startDate: Date) => {
  if ((ONLY_DAYS_MONTH_REPORT || []).length !== 3) {
    return ONLY_DAYS_MONTH_REPORT;
  }
  const weekDay = startDate.getDate();

  // if(weekDay > ONLY_DAYS_MONTH_REPORT[0] && weekDay <= ONLY_DAYS_MONTH_REPORT[1]){
  //   return [
  //     ONLY_DAYS_MONTH_REPORT[0],
  //     ONLY_DAYS_MONTH_REPORT[1],
  //     ONLY_DAYS_MONTH_REPORT[2]
  //   ]
  // }

  if (
    weekDay >= ONLY_DAYS_MONTH_REPORT[1] &&
    weekDay <= ONLY_DAYS_MONTH_REPORT[2]
  ) {
    return [
      ONLY_DAYS_MONTH_REPORT[1],
      ONLY_DAYS_MONTH_REPORT[2],
      ONLY_DAYS_MONTH_REPORT[0],
    ];
  }

  if (weekDay >= ONLY_DAYS_MONTH_REPORT[2]) {
    return [
      ONLY_DAYS_MONTH_REPORT[2],
      ONLY_DAYS_MONTH_REPORT[1],
      ONLY_DAYS_MONTH_REPORT[0],
    ];
  }

  return ONLY_DAYS_MONTH_REPORT;
};
