// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: center;
  align-items: center;
  height: 230px;
}

.transparent {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/modals/filter-template-guided-object/filter-template-guided-object.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAGA;EACE,6BAAA;AAAF","sourcesContent":[".spinner-center{\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  vertical-align: center;\r\n  align-items: center;\r\n  height: 230px;\r\n}\r\n\r\n\r\n.transparent{\r\n  background-color: transparent;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
