// models
import { WeekDayMachineAlert } from '../../models';

// utils
import { WEEK_MAX_DAYS } from '../constant';
import { getSelectAllWeekDayMachineAlertParams, weekDayToWeekDayDB } from '../get-params';

export const getWeekdayByAlertInfoMachineAlert = (
  weeks: WeekDayMachineAlert[]
): { WeekDayID: number }[] => {

  if((weeks || []).length === 0 || (weeks || []).length === WEEK_MAX_DAYS){
    return getSelectAllWeekDayMachineAlertParams()
  }

  let weekDaysIds: { WeekDayID: number} [] = []
  for(let week of weeks){
    weekDaysIds.push( {WeekDayID: weekDayToWeekDayDB(week.value)})
  }

  return weekDaysIds;
};

