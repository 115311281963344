export const MAX_LENGTH_FAMILIY_SCREEN_SM = 40

export const DAILY_TEMPLATE = 'Plantilla_Diaria';
export const TRANSACTIONS_TEMPLATE = 'Plantilla_Transacciones'
export const CODE_SAVIA_PATTERN = 'C/AAA/111/11-A-111-01'
export const DATE_SALE_PATTERN = "DD-MM-YYYY";

export const AENA_REGEN_FILE_ROUTE = '/user/regen-files-aena';
export const AENA_REGEN_FILES = 'AENA Regen Files';
export const AENA_REGEN_FILES2 = 'AENA Regen Files 2';
