

export const getClassNamesTitle = (width: number) => {

  if(width <= 1270 && width >= 1200){
    return 'font-md'
  }

  return '';
}
