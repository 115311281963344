import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Config
import { AppConfig } from '../../../config/config';

// service
import { CardService } from '../card/card.service';

// models
import { ParamsUnlinkClientCard, ParamsUnlinkClientCardByList } from '../../../pages/user/cards/register-cards/models';


@Injectable({
  providedIn: 'root'
})
export class RegisterCardsService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private _cardService: CardService
    ) { }

  registerCardList(params: RegisterCardParams){
    let endpoint = `register_cardsByList`;
    let routeUrl = this.router.url;
    return this.httpClient.post<RegisterCard>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  assignCardList(params: AssignCardParams){
    let endpoint = `AssignClientCardByList`;
    let routeUrl = this.router.url;
    return this.httpClient.post<AssignCard>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  checkCardList(params: AssignCardCheckParams ){
    let endpoint = `check_AssignClientCard`;
    let routeUrl = this.router.url;
    return this.httpClient.post<AssignCardCheck>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  registerCardInterval(params: RegisterCardInterval ){
    let endpoint = `register_cards`;
    let routeUrl = this.router.url;
    return this.httpClient.post<RegisterCard>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  assignCardInterval(params: AssignIntervalCardParams){
    let endpoint = `AssignClientCard`;
    let routeUrl = this.router.url;
    return this.httpClient.post<AssignCard>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(messageError);
      })
    );
  }

  unlinkCLientCard(params: ParamsUnlinkClientCard){
    let endpoint = `UnlinkClientCard`;
    let routeUrl = this.router.url;
    return this.httpClient.post<null>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(messageError);
      })
    );
  }

  unlinkClientCardByList(params: ParamsUnlinkClientCardByList){
    let endpoint = `UnlinkClientCardByList`;
    let routeUrl = this.router.url;
    return this.httpClient.post<null>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(messageError);
      })
    );
  }

  getCardsByClientId(clientId: number){
    return this._cardService.getCards({clientId});
  }

}


export interface RegisterCard{
  not_created?: string[]
}

export interface RegisterCardParams{
  Operator_id: number,
  VT: string[]
}

export interface RegisterCardInterval{
  VT_interval1: string,
	VT_interval2: string,
	Operator_id: number
}

export interface AssignCard{
  not_exist_cards: string[]
}

export interface AssignCardParams{
  Client_id: number
  VT: string[]
}

export interface AssignIntervalCardParams{
  VT_interval1: string,
	VT_interval2: string,
  Client_id: number
}


export interface AssignCardCheck{
  not_exist_cards: string [],
  cards_without_clients: number[],
  cards_with_clients: CardClient[]
}

export interface CardClient{
  card: string,
  client_id: number,
  client: string
}



export interface AssignCardCheckParams{
    VT_interval1?: string,
    VT_interval2?: string,
    VT?:string[],
    Client_id: number
}

