import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { Company } from 'src/app/models';
import { pcWidth } from 'src/app/utils';

@Component({
  selector: 'app-card-with-title-company-template',
  templateUrl: './card-with-title-company-template.component.html',
  styleUrls: ['./card-with-title-company-template.component.scss'],
})
export class CardWithTitleCompanyTemplateComponent implements OnInit {

  @Input() company: Company;
  @Input() isLightColor: boolean = true;

  @Input() title: string = '';
  @Input() titleHTML: string = '';

  pcWidth = pcWidth;

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}

}
