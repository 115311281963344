import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// services
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from 'src/app/services';
import { ColorModeService } from '../../../../services/color-mode.service';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.scss'],
})
export class CookiesPolicyComponent implements OnInit {
  company: Company;

  isLightColor: boolean = true;

  lang: string = 'es';

  changeColorLink: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private _traslateService: TranslateService,
    private skinService: SkinMangementService,
    private _colorModeService: ColorModeService
  ) {}

  ngOnInit() {
    this.company = this.skinService.getCompany();
    this.isLightColor = this._colorModeService.getIsLightColor();

    this.changeColorLink = !this.isLightColor;

    this.getTranslate();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async getTranslate() {
    this.lang = await this._traslateService.instant('locale_register');
  }
}
