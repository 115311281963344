export const MAX_NC_LENGTH = 99;

// Route
export const ROUTE_TILL_MAPPING: string = '/user/till-mapping';

// --------------------------------- Name Route ---------------------------------
export const NAME_BASIC_TILL_MAPPING: string = 'Till Scheme Basic';

// Capacity
export const NAME_BASIC_CAPACITY_TILL_MAPPING: string =
  'Till Scheme Basic Capacity';

// Assign standard products
export const NAME_COMPLEX_TILL_MAPPING: string = 'Till Scheme Complex';

// Assign standard products, capacity
export const NAME_COMPLEX_CAPACITY_TILL_MAPPING: string =
  'Till Scheme Complex Capacity';
