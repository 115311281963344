// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
  text-align: center;
  margin: 25px;
  display: block;
}

.space-filter-client {
  margin: 25px;
  width: 100%;
  height: 50%;
  margin-top: -1%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-100 {
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/visibility-client-till-select/visibility-client-till-select-sm/visibility-client-till-select-sm.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,cAAA;AACJ;;AAGA;EACI,YAAA;EACA,WAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;;AAGE;EACE,sBAAA;AAAJ","sourcesContent":[".center{\r\n    text-align: center;\r\n    margin: 25px;\r\n    display: block;\r\n\r\n}\r\n\r\n.space-filter-client{\r\n    margin: 25px;\r\n    width: 100%;\r\n    height: 50%;\r\n    margin-top: -1%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n\r\n  .w-100 {\r\n    width: 100% !important;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
