

// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { HeaderModalModule } from '../../header-modal/header-modal.modal';
import { FilterTemplateOneElemPositionShareModule } from '../share/filter-template-one-elem-position-share/filter-template-one-elem-position-share.component.module';


import { FilterInputSearchShareModule } from '../share/filter-input-search-share';
import { FilterTemplateSelectElemByListShareModule } from '../share';


// Component
import { FilterTemplateObjectTripleInputsModalComponent } from './filter-template-object-triple-inputs-modal.component';
// Service
import { LanguageService } from 'src/app/share/services';
import { SkinMangementService } from 'src/app/services';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,

    HeaderModalModule,
    FilterInputSearchShareModule,
    FilterTemplateSelectElemByListShareModule,
    FilterTemplateOneElemPositionShareModule
  ],
  declarations: [
    FilterTemplateObjectTripleInputsModalComponent
  ],
  providers: [LanguageService, SkinMangementService],
  exports: [
    FilterTemplateObjectTripleInputsModalComponent
  ],
})
export class FilterTemplateObjectTripleInputsModalModule {}
