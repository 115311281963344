import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ElementRef,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// service
import { TranslateService } from '@ngx-translate/core';
import {
  FilterDateFutureService,
  FilterDateService,
  LanguageRouteService,
} from 'src/app/share/services';
import { AuthService, SkinMangementService } from '../../../../../services';
import { FilterTransactionsQueryService } from 'src/app/pages/user/transactions/transactions-query/services';

// models
import { Company, FilterDateServiceI } from 'src/app/models';
import { CheckSegment, RangeDates } from '../../models';

// utils
import { pcWidth } from 'src/app/utils';
import { MIN_DATE_FILTER, getFilterDateService } from '../../helpers';


import { v4 as uuidv4 } from 'uuid';
import { MatLanguageService } from 'src/app/share/services/mat-language/mat-language.service';

@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss'],
})
export class FilterDateComponent implements OnInit {
  // properties
  @Input() maxDatePeriod: Date = null;
  @Input() minDate: Date = null;
  @Input() showHour: boolean = true;
  @Input() onlyPeriod: boolean = false;
  @Input() showTextHourComplete: boolean = false;

  startDate: string;
  endDate: string;
  startHour: string;
  endHour: string;

  url: string = '';
  filterDateId: string = null;

  company: Company;

  checkSegment: CheckSegment = 'date';
  _filterDateTemplateService: FilterDateServiceI;

  pcWidth = pcWidth;

  changeLanguage$: Subscription;

  datePipe = 'dd/MM/y';

  ranges: RangeDates = {
    oneDate: {
      minDate: MIN_DATE_FILTER,
      maxDate: new Date(),
    },
    period: {
      minDate: MIN_DATE_FILTER,
      maxDate: new Date(),
    },
  };

  applyValidator: Record<string, Function> = {};

  changeRoute$: Subscription;
  filterChange$: Subscription;
  clearFilter$: Subscription;
  platformResize$: Subscription;

  isActiveSubscriptions = false;

  // enableHour = true;

  versionDate;

  waitChangeInvalidDate: boolean = false;

  constructor(
    public readonly platform: Platform,
    private router: Router,
    // private elementRef: ElementRef,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinMangementService: SkinMangementService,
    private _authService: AuthService,
    private _filterTransactionQueryService: FilterTransactionsQueryService,
    private _filterDateService: FilterDateService,
    private _filterDateFutureService: FilterDateFutureService,
    private _matLanguageService: MatLanguageService
  ) {
    this.url = this.router.url;
    this.versionDate = uuidv4();

    this._filterDateTemplateService = getFilterDateService({
      routeUrl: this.router.url,
      _filterDateService: this._filterDateService,
      _filterTransactionQueryService: this._filterTransactionQueryService,
      _filterDateFutureService: this._filterDateFutureService,
    });

    this.getValidators();
  }

  ngOnInit() {
    this.company = this._skinMangementService.getCompany();

    if (
      this._filterDateTemplateService.getCheckSegment() === 'period' &&
      !this.onlyPeriod
    ) {
      this.checkSegment = this._filterDateTemplateService.getCheckSegment();
    }
    this.startRange();

    // this.startDate = this._filterDateTemplateService.getDateStart();
    // this.endDate = this._filterDateTemplateService.getDateEnd();
    // this.startHour = this._filterDateTemplateService.getStartHour();
    // this.endHour = this._filterDateTemplateService.getEndHour();

    // this.checkSegment = this._filterDateTemplateService.getCheckSegment()

    this.getCurrent();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    // no entra en pantalla pequeña
    // this.changeRoute$ = this.router.events.subscribe((ev: NavigationEnd) => {

    //   console.log('ev',  this.url, 'url', ev.url )

    //   // console.log('this.isActiveSubscriptions', this.isActiveSubscriptions)
    //   if (ev instanceof NavigationEnd) {

    //     if (this.url !== ev.url && this.isActiveSubscriptions) {
    //       console.log('destroy')
    //       // this.destroy();
    //       // && !this.isActiveSubscriptions
    //     }else if(this.url === ev.url ){
    //       console.log('entre por getDates')
    //       this.getDates();
    //     }
    //   }
    // });

    // this.getDates();

    this.changeRoute$ = this.router.events.subscribe((ev: NavigationEnd) => {
      if (ev instanceof NavigationEnd) {
        if (this.url !== ev.url) {
          this.destroy();
        }
        if (this.url === ev.url) {
          this.startSegment();
        }

        // this.checkSegment = 'date';
        // this._filterDateTemplateService.setCheckSegment('date');
      }
    });

    this.platformResize$ = this.platform.resize.subscribe(() => {
      if (
        this.checkSegment !== this._filterDateTemplateService.getCheckSegment()
      ) {
        this.checkSegment = 'other';
        setTimeout(() => {
          if (this.onlyPeriod) {
            this.checkSegment = 'period';
          } else {
            this.checkSegment = this._filterDateTemplateService.getCheckSegment();

            // TODO test
            if(this.checkSegment === 'other'){
              this.checkSegment = 'date';
            }
          }
        }, 50);
      }

      // console.log(
      //   'segment',
      //   this._filterDateTemplateService.getCheckSegment(),
      //   'select',
      //   this.checkSegment
      // );
      // this.checkSegment = this._filterDateTemplateService.getCheckSegment();

      // this.checkSegment = 'other';
      // setTimeout(()=> {
      //   this.checkSegment = this._filterDateTemplateService.getCheckSegment();
      //   console.log('segment',  this._filterDateTemplateService.getCheckSegment())
      // }, 200)
    });

    this.getDates();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(
    //   'changes is only period',
    //   this.onlyPeriod,
    //   'vesrion',
    //   this.versionDate
    // );
    if (this.maxDatePeriod) {
      this.ranges.period.maxDate = this.maxDatePeriod;
      this.ranges.oneDate.maxDate = this.maxDatePeriod;
    }

    if (this.onlyPeriod) {
      this.checkSegment = this._filterDateTemplateService.getCheckSegment();
      if (
        this.checkSegment &&
        (!this.checkSegment ||
          this.checkSegment === 'date' ||
          this.checkSegment === 'other')
      ) {
        // console.log('editar');
        this.checkSegment = 'period';
        this._filterDateTemplateService.setCheckSegment('period');
      }
    }
  }

  getCurrent() {
    this.startDate = this._filterDateTemplateService.getDateStart();
    this.endDate = this._filterDateTemplateService.getDateEnd();
    this.startHour = this._filterDateTemplateService.getStartHour();
    this.endHour = this._filterDateTemplateService.getEndHour();

    // this.checkSegment = this._filterDateTemplateService.getCheckSegment();
  }

  startSegment() {
    // console.log(
    //   'start segment only  period',
    //   this.onlyPeriod,
    //   'version',
    //   this.versionDate
    // );
    this.checkSegment = this._filterDateTemplateService.getCheckSegment();
    // console.log(
    //   'start segment',
    //   this.checkSegment,
    //   'version',
    //   this.versionDate
    // );
    if (
      (!this.checkSegment || this.checkSegment === 'other') &&
      !this.onlyPeriod
    ) {
      this.checkSegment = 'date';
      this._filterDateTemplateService.setCheckSegment('date');
    }
    // if (
    //   this.onlyPeriod &&
    //   (!this.checkSegment ||
    //     this.checkSegment === 'date' ||
    //     this.checkSegment === 'other')
    // ) {

    //   // setTimeout(()=> {
    //   //   console.log('change period timeout 600')
    //   //   this.checkSegment = 'period';
    //   //   this._filterDateTemplateService.setCheckSegment('period');
    //   // }, 200)

    // }
  }

  getDates() {
    if (!this.filterChange$) {
      this.filterChange$ = this._filterDateTemplateService.filterChange$.subscribe(
        () => {
          this.startDate = this._filterDateTemplateService.getDateStart();
          this.endDate = this._filterDateTemplateService.getDateEnd();
          this.startHour = this._filterDateTemplateService.getStartHour();
          this.endHour = this._filterDateTemplateService.getEndHour();

          this.validateDate();

          // console.log('change', this.versionDate);
          //console.log(this._filterDateTemplateService.getDateStart());
          // console.log('startDate', this.startDate)
        }
      );
    }

    if (!this.clearFilter$) {
      this.clearFilter$ = this._filterDateTemplateService.reset$.subscribe(
        () => {
          if (this.url === this.router.url) {
            this.checkSegment = this._filterDateTemplateService.getCheckSegment();

            if (
              this.onlyPeriod &&
              this.checkSegment &&
              (!this.checkSegment ||
                this.checkSegment === 'date' ||
                this.checkSegment === 'other')
            ) {
              this.checkSegment = 'period';
              this._filterDateTemplateService.setCheckSegment('period');
            }

            this.startDate = this._filterDateTemplateService.getDateStart();
            this.endDate = this._filterDateTemplateService.getDateEnd();
            this.startHour = this._filterDateTemplateService.getStartHour();
            this.endHour = this._filterDateTemplateService.getEndHour();

            this.validateDate();

            // console.log(
            //   'clear',
            //   this.versionDate,
            //   'route',
            //   this.router.url,
            //   'basic route',
            //   this.url
            // );
          }
        }
      );
    }

    this.isActiveSubscriptions = true;

    // this.validateDate();
  }

  destroy() {
    // console.log('destroy');
    //this.elementRef.nativeElement.remove();

    // console.log('enabled', this.enableHour)

    // if (this.filterChange$) this.filterChange$.unsubscribe();
    // if (this.clearFilter$) this.clearFilter$.unsubscribe();
    this.isActiveSubscriptions = false;
  }

  ngOnDestroy() {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if (this.changeRoute$) this.changeRoute$.unsubscribe();
    if (this.filterChange$) this.filterChange$.unsubscribe();
    if (this.clearFilter$) this.clearFilter$.unsubscribe();
    if (this.platformResize$) this.platformResize$.unsubscribe();
  }

  startRange() {
    if (this.minDate) {
      this.assignRange(new Date(this.minDate));
    } else {
      this._authService.getMinDate().then((minDate) => {
        this.assignRange(minDate as Date);
      });
    }
  }

  assignRange(minDate: Date) {
    this.ranges.oneDate.minDate = minDate || this.ranges.oneDate.minDate;
    this.ranges.period.minDate = minDate || this.ranges.oneDate.minDate;

    if (this.maxDatePeriod) {
      this.ranges.period.maxDate = this.maxDatePeriod;
    }
  }

  changePeriod() {
    this._filterDateTemplateService.setCheckSegment(this.checkSegment);
  }

  savePlaceholders() {
    this.translate
      .get(['locale', 'format_pipe_without_hour'])
      .subscribe((translates) => {
        this.datePipe = translates['format_pipe_without_hour'];
        // console.log('language', translates['locale']);
        this._matLanguageService.setLanguage(translates['locale']);
      });
  }

  validateDate() {
    for (const key in this.applyValidator) {
      this.applyValidator?.[key] && this.applyValidator[key]();
    }
  }

  getValidators() {
    this.applyValidator = {
      minDatePeriod: () => this.changeMinDatePeriod(),
      maxDatePeriod: () => this.changeMaxDatePeriod(),
    };
  }

  changeMinDatePeriod() {
    if (
      !this.ranges?.period?.minDate ||
      !this.startDate ||
      this.checkSegment !== 'period'
    ) {
      return;
    }
    const minDate = new Date(this.ranges.period.minDate);
    if (!minDate) {
      return;
    }

    // if (
    //   new Date(this.startDate.toString().replace(/-/g, '/')).getTime() -
    //     minDate.getTime() <
    //   0
    // ) {
    //   this._filterDateTemplateService.setDate(this.maxDatePeriod, 2);
    // }


    let userTimezoneOffset = Math.trunc(minDate.getTimezoneOffset() / 60);
    const minDateCurrent = new Date(minDate);
    const startDateCurrent = new Date(this.startDate);

    if (
      startDateCurrent.getTime() - minDateCurrent.getTime() < userTimezoneOffset * 60 * 60 * 1000  &&
      !this.waitChangeInvalidDate
    ) {
      this._filterDateTemplateService.setDate(minDate, 0);
      this.applyInvalidChangeDate();
    }
  }

  changeMaxDatePeriod() {
    if (
      !this.ranges?.period?.maxDate ||
      !this.endDate ||
      this.checkSegment !== 'period'
    ) {
      return;
    }

    const minDate = new Date(this.ranges.period.minDate);
    if (!minDate) {
      return;
    }

    let userTimezoneOffset = Math.trunc(minDate.getTimezoneOffset() / 60);
    const minDateCurrent = new Date(minDate);
    const startDateCurrent = new Date(this.endDate);

    if (
      startDateCurrent.getTime() - minDateCurrent.getTime() <
      userTimezoneOffset * 60 * 60 * 1000 &&
      !this.waitChangeInvalidDate
    ) {
      this._filterDateTemplateService.setDate(this.maxDatePeriod, 1);
      this.applyInvalidChangeDate();
    }
  }


  applyInvalidChangeDate() {
    this.waitChangeInvalidDate = true;
    setTimeout(() => {
      this.waitChangeInvalidDate = true;
    }, 100);
  }
}
