// models
import {
  TypesSelectedReport,
  ValidateExistCardsByReportParams,
} from '../../../../models';

// services
import { FilterReportService } from '../../../../services';

export const getValidateExistCardsByReportParams = (
  cards: string[],
  _filterReportService: FilterReportService
): ValidateExistCardsByReportParams => {
  let params: ValidateExistCardsByReportParams = {
    cards,
  };

  if (_filterReportService.getOperatorGroup(TypesSelectedReport.createEdit)) {
    params.reportOperatorGroupId = _filterReportService.getOperatorGroup(
      TypesSelectedReport.createEdit
    )?.ID;
  }

  if (_filterReportService.getOperator(TypesSelectedReport.createEdit)) {
    params.reportOperadorId = _filterReportService.getOperator(
      TypesSelectedReport.createEdit
    )?.id;
  }

  return params;
};
