export const getTypePaymentExcelTranslateTQ = (
  paymentType: string,
  translatesTypes: { [x: string]: string }
) => {
  if (translatesTypes?.[paymentType]) {
    return translatesTypes[paymentType];
  }

  return paymentType;
};
