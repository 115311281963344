import { AreaTotalArticleTranslate, TotalArticlesDashboardValids } from "../../models";

interface GetLabelTranslateDashboard{
  unitsOrBilling: TotalArticlesDashboardValids,
  areaTranslates: AreaTotalArticleTranslate,
  currencySymbol: string
}


export const getLabelTranslateDashboard = (
  {unitsOrBilling, areaTranslates,  currencySymbol}: GetLabelTranslateDashboard
) => {
  return unitsOrBilling === 'billing'
  ? `${areaTranslates.billing} (${currencySymbol})`
  : areaTranslates.units;
}
