// models
import { TypeReport } from '../../models';

// constants
import { TYPE_REPORT_MULTIPLE_CARD, TYPE_REPORT_SHOW_OPERATORGROUP_AND_OPERATOR } from '../constants';

export const haveTypeReportSelectedCard = (
  typeReports: TypeReport[],
  typeReportIdSelected: number
) => {

  if (!typeReportIdSelected && typeReportIdSelected !== 0) {
    return false;
  }

  const typeReportSelected = typeReports.find(
    (typeReport) => typeReport.ID === typeReportIdSelected
  );

  if (!typeReportSelected) {
    return false;
  }

  return TYPE_REPORT_MULTIPLE_CARD.includes(typeReportSelected?.Name);

};
