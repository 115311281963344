import { WritableSignal } from '@angular/core';

// models
import { Operator } from 'src/app/models';
import { TillSelectedRM } from '../../models';

interface SetTillsByOperatorSelectedRM {
  operatorSelected: WritableSignal<Operator>;
  tills: WritableSignal<TillSelectedRM[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
}

export const setTillsByOperatorSelectedRM = ({
  operatorSelected,
  tills,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
}: SetTillsByOperatorSelectedRM) => {
  if (!operatorSelected) {
    return;
  }

  const tillsByOperatorSelected = tills().filter(
    (till) => till?.operatorId === operatorSelected()?.id
  );

  tillsByOperator.set(tillsByOperatorSelected);
  tillsSelected.set([]);
  tillOneMachineSelected.set(null);
};
