export const INVENTORY_SUCCESS = 'Inventory ok';
export const INVENTORY_WARNING = 'Inventory with low quantity products';

// Permission
export const SUPERVISOR_INVENTORY_MANAGER_ROUTE = '/user/inventory-manager';
export const USE_SUPERVISOR_INVENTORY_MANAGER_NAME = ['Gestor de inventario (Supervisor)'];

// Translsate
export const FILTER_TRANSLATE_INVENTORY_MANAGER = 'inventory_manager.filter.filter_machine_client';
export const FILTER_TRANSLATE_INVENTORY_MANAGER_SUPERVISOR = 'inventory_manager.filter.filters_inventory';
