import { Component, OnInit, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs/internal/Subscription';

// Service
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from '../../../services/';
import {
  LanguageRouteService,
  OperatorGroupService,
  OperatorService,
  VisibilitySelectService,
} from '../../services/';

// Models
import { Company, Operator, OperatorGroup } from 'src/app/models';

// Utils
import { pcWidthMix, pcWidth, TIME_OUT_START } from '../../../utils/constants';

@Component({
  selector: 'app-visibility-select',
  templateUrl: './visibility-select.component.html',
  styleUrls: ['./visibility-select.component.scss'],
})
export class VisibilitySelectComponent implements OnInit {
  // @Input() company: Company;
  company: Company;
  @Input() operatorSegment: boolean = true;
  @Input() operatorGroupSegment: boolean = true;

  @Input() showSelect = true;

  @Input() newSize = null;
  //@Input() defaultOperator:string = ''

  @Input() pcWidth = pcWidth;
  @Input() allOptionDisabled: boolean = false;
  @Input() secondPosition: boolean = false;
  @Input() userLoading: boolean = false;

  pcWidthMix = pcWidthMix;

  operators: Operator[] = [];
  operatorsGroup: OperatorGroup[] = [];

  loadingOperators: boolean = true;
  loadingOperatorsGroup: boolean = true;

  operatorGroupSelected = null;
  operatorGroupSelected2 = null;
  operatorSelected = null;
  operatorSelected2 = null;

  firstTimeLoad = false;

  allTranslate = 'All';

  changeLanguage$: Subscription;
  resetVisibility$: Subscription;
  changeDestroy$: Subscription;

  constructor(
    private _languageRouteService: LanguageRouteService,
    private translate: TranslateService,
    private _skinService: SkinMangementService,
    private _operatorGroupService: OperatorGroupService,
    private _operatorService: OperatorService,
    private _visibilityService: VisibilitySelectService,
    public platform: Platform
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    setTimeout(() => {
      this.start();
    }, TIME_OUT_START);

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );
  }

  ngOnDestroy() {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    this.destroy();
  }

  destroy() {
    if (this.resetVisibility$) this.resetVisibility$.unsubscribe();
    if (this.changeDestroy$) this.changeDestroy$.unsubscribe();
  }

  start() {
    if (!this._visibilityService.getStarted())
      this._visibilityService.setStarted(true);

    if (this._visibilityService.getOperators()) {
      this.operators = this._visibilityService.getOperators();
      this.operatorsGroup = this._visibilityService.getOperatorsGroup();
      this.loadingOperators = this._visibilityService.getLoadingOperator();
      this.loadingOperatorsGroup = this._visibilityService.getloadingOperatorsGroup();
      this.operatorGroupSelected = this._visibilityService.getOperatorGroupSelected();
      this.operatorSelected = this._visibilityService.getOperatorSelected();
    } else {
      this._operatorGroupService.getOperatorGroup().subscribe((res) => {
        this.changeOperatorGroup(res);
        this.changeLoadingOperatorGroup(false);
        this._visibilityService.setloadingOperatorsGroup(
          this.loadingOperatorsGroup
        );
        const operatorGroupSelected = this._visibilityService.getOperatorGroupSelected();
        if (this.operatorsGroup.length >= 1 && !operatorGroupSelected) {
          this.changeOperatorGroupSel(this.operatorsGroup[0].ID);
          this.getOperators({ operatorGroupId: this.operatorsGroup[0].ID });
        } else {
          this.changeOperatorGroupSel(operatorGroupSelected);
          this.getOperators({ operatorGroupId: operatorGroupSelected });
        }
      });
    }

    this.resetVisibility$ = this._visibilityService.reset.subscribe((res) => {
      if (!this._visibilityService.getStarted()) {
        this.start();
        return;
      }

      if (this.operatorsGroup.length >= 1 && res?.reset) {
        this.changeOperatorGroupSel(this.operatorsGroup[0].ID);
        if (!res?.url) {
          this.getOperators({ operatorGroupId: this.operatorsGroup[0].ID });
        } else {
          this.getOperators(
            { operatorGroupId: this.operatorsGroup[0].ID },
            res?.url,
            res?.groupName
          );
        }
      }
    });

    this.changeDestroy$ = this._visibilityService.changeDestroy$.subscribe(
      () => {
        this.destroy();
      }
    );
  }

  getOperators(params: any = {}, url: string = '', groupName = '') {
    this.changeOperator([]);
    if (!params.operatorGroupId) {
      return;
    }
    this.changeLoadingOperator(true);
    this.changeOperatorSel(null);
    this._operatorService
      .getOperators(params, false, url, groupName)
      .subscribe((resOperator) => {
        this.changeOperator(resOperator);
        this.changeLoadingOperator(false);
        if (this.operators.length > 1) {
          let operatorAll: Operator = {
            id: -1,
            description: this.allTranslate,
          };
          if (this.operators.length >= 1) {
            operatorAll.Currency = [];
            operatorAll.Currency.push({
              Symbol: this.operators[0]?.Currency[0]?.Symbol || null,
              ID: this.operators[0]?.Currency[0]?.ID || null,
              Code: this.operators[0]?.Currency[0]?.Code || null,
              Number: this.operators[0]?.Currency[0]?.Number || null,
              Name: this.operators[0]?.Currency[0]?.Name || '',
            });
          }
          this.operators.unshift(operatorAll);
          this.changeOperator(resOperator);
        }
        if (this.operators.length >= 1) {
          this.changeOperatorSel(this.operators[0].id);
        }
        if (!this.firstTimeLoad) {
          this.firstTimeLoad = true;
          this.setOptionsValids();
        }
      });
  }

  // -------  Operator -------------
  changeOperator(newOperator: Operator[]) {
    this.operators = newOperator;
    this._visibilityService.setOperators(this.operators);
  }

  changeLoadingOperator(newLoadingOperator: boolean) {
    this.loadingOperators = newLoadingOperator;
    this._visibilityService.setloadingOperators(this.loadingOperators);
  }

  changeOperatorSel(newOperatorSelected: number) {
    if (!this.secondPosition) {
      this.operatorSelected = newOperatorSelected;
      this._visibilityService.setOperatorSelected(this.operatorSelected);
      this._visibilityService.changeFilter();
    } else {
      this.operatorSelected2 = newOperatorSelected;
      this._visibilityService.setOperatorSelectedSecondPosition(
        this.operatorSelected2
      );
      this._visibilityService.changeFilterSecondPosition();
    }
  }

  // -------  Grupo Operador -------------
  changeOperatorGroup(newOperatorGroup: OperatorGroup[]) {
    this.operatorsGroup = newOperatorGroup;
    this._visibilityService.setOperatorsGroup(this.operatorsGroup);
  }

  changeLoadingOperatorGroup(newLoadingOperatorGroup: boolean) {
    this.loadingOperatorsGroup = newLoadingOperatorGroup;
    this._visibilityService.setloadingOperatorsGroup(
      this.loadingOperatorsGroup
    );
  }

  changeOperatorGroupSel(newOperatorGroup: number) {
    if (!this.secondPosition) {
      this.operatorGroupSelected = newOperatorGroup;
      this._visibilityService.setOperatorGroupSelected(
        this.operatorGroupSelected
      );
      this._visibilityService.changeFilter();
    } else {
      this.operatorGroupSelected2 = newOperatorGroup;
      this._visibilityService.setOperatorGroupSelectedSecondPosition(
        this.operatorGroupSelected2
      );
      this._visibilityService.changeFilterSecondPosition();
    }
  }

  changeOperatorSelected(newOperator: number) {
    this.changeOperatorSel(newOperator);
    if (!this.secondPosition) {
      this._visibilityService.changeOperatorSelected();
      this._visibilityService.changeFilter();
    } else {
      this._visibilityService.changeFilterSecondPosition();
    }
  }

  changeOperatorGroupSelected(newOperatorGroup: number) {
    this.changeOperatorGroupSel(newOperatorGroup);
    this.changeOperatorSel(null);
    this.getOperators({ operatorGroupId: this.operatorGroupSelected });
    if (!this.secondPosition) {
      this._visibilityService.changeFilter();
    } else {
      this._visibilityService.changeFilterSecondPosition();
    }
  }

  // operatador o grupo operador son validos para pantalla sm
  setOptionsValids() {
    this._visibilityService.setOptionsValids();
  }

  savePlaceholders() {
    this.translate.get(['all']).subscribe((translates) => {
      this.allTranslate = translates['all'];
      this._visibilityService.changeAll(this.allTranslate);
    });
  }
}
