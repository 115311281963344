import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Config
import { AppConfig } from '../../../config/config';

// Models
import { Family, Article, ArticleExt, ImageArticle } from '../../../models';
import { TillMapping, ParamsArticlesByMachine } from '../../../pages/user/machines/till-mapping/models';


@Injectable({
  providedIn: 'root'
})
export class ArticleService {


  private serviceUrl = AppConfig.RTVnodeUrl;


  constructor(private httpClient: HttpClient, protected router: Router) {}

  getArticles(highLevel = false){

    let endpoint = `articles`;
    return this.httpClient.get<Article[]>(this.serviceUrl + endpoint).pipe(
      map(res => {
        if(!highLevel){
          res = res.filter(article => article.isValid1 === 1)
        }
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  getArticleById(articleId: number, operatorGroupID?: number, operatorID?: number){

    let endpoint = `articles/${articleId}`
    let routeUrl = this.router.url;

    let params: {  operatorGroupID?: number, operatorID?: number} = {}
    if(operatorGroupID){
      params.operatorGroupID = operatorGroupID
    }

    if(operatorID){
      params.operatorID = operatorID
    }

    return this.httpClient.post<ArticleExt>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );

  }

  getFamilies(){
    let endpoint = `families`;
    let routeUrl = this.router.url;
    return this.httpClient.get<Family[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  getFamilyById(id:number){
    let endpoint = `families/${id}`;
    let routeUrl = this.router.url;
    return this.httpClient.get<Family>(this.serviceUrl + endpoint + `?route=${routeUrl}`).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  getImageByArticleId(articleId: number){
    let endpoint = `/image/article/${articleId}`;
    let routeUrl = this.router.url;

    return this.httpClient
      .get<ImageArticle>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }


  getArticlesByMachine(params: ParamsArticlesByMachine){
    let endpoint = `machine/articles`;
    let routeUrl = this.router.url;
    return this.httpClient
      .post<TillMapping>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`,
        params
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

}
