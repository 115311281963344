import { WritableSignal } from '@angular/core';

// models
import {
  DayWeekMachineStatusAlert,
  TypeDateSelectedMachineAlert,
} from '../../models';

// service
import { FilterDateServiceI } from 'src/app/models';

// utils
import { getSelectAllWeekDayMachineAlertParams, weekDayToWeekDayDB } from './';

interface GetWeekDaysMachineAlert {
  daysWeek: WritableSignal<Record<DayWeekMachineStatusAlert, boolean>>;
  typeSelectDate: TypeDateSelectedMachineAlert;
  _filterDateService: FilterDateServiceI;
}

export const getWeekDaysMachineAlert = ({
  daysWeek,
  typeSelectDate,
  _filterDateService,
}: GetWeekDaysMachineAlert): { WeekDayID: number }[] => {
  if (haveDaysWeek(typeSelectDate, _filterDateService)) {
    return getWeekDayParams(daysWeek);
  }

  return getSelectAllWeekDayMachineAlertParams();
};

const haveDaysWeek = (
  typeSelectDate: TypeDateSelectedMachineAlert,
  _filterDateService: FilterDateServiceI
): boolean => {
  if (Number(typeSelectDate) === TypeDateSelectedMachineAlert.undefined) {
    return true;
  }

  if (_filterDateService.getCheckSegment() === 'period') {
    return true;
  }

  return false;
};

const getWeekDayParams = (
  daysWeek: WritableSignal<Record<DayWeekMachineStatusAlert, boolean>>
): { WeekDayID: number }[] => {
  let weekDays: { WeekDayID: number }[] = [];
  for (let [key, value] of Object.entries(daysWeek())) {
    if (value) {
      weekDays.push({ WeekDayID: weekDayToWeekDayDB(key) });
    }
  }

  return weekDays;
};
