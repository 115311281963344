import { pcWidth } from 'src/app/utils';
import { DIMENSION_SHOW_ONE_CHART, DIMENSION_SHOW_ONE_CHART_MENU } from '..';

const SIZE_CHANGE_UNITS_BILLING_MD = 950;
const SIZE_CHANGE_UNITS_BILLING_FIX_MENU_MD = 950;

const CLASS_NAMES = {
  pc: 'segment-area',
  md: 'margin-segment-md',
  sm: 'margin-segment',
};

const CLASS_NAMES_FIX_MENU = {
  pc: 'segment-area-fix-menu',
  md: 'margin-segment-fix-menu-md',
  sm: 'margin-segment',
};

export const getClassNamesUnitsBilling = (
  width: number,
  isOpenFixMenu: boolean
): string => {
  if (!isOpenFixMenu) {
    return getClassNamesUnitsBillingNotFixMenu(width);
  } else {
    return getClassNamesUnitsBillingFixMenu(width);
  }
};

const getClassNamesUnitsBillingNotFixMenu = (width: number) => {
  if (width > DIMENSION_SHOW_ONE_CHART) {
    return CLASS_NAMES.pc;
  }

  if (width <= pcWidth) {
    return CLASS_NAMES.sm;
  }

  if (width > SIZE_CHANGE_UNITS_BILLING_MD) {
    return CLASS_NAMES.md;
  }

  return '';
};

const getClassNamesUnitsBillingFixMenu = (width: number) => {
  if (width > DIMENSION_SHOW_ONE_CHART_MENU) {
    return CLASS_NAMES_FIX_MENU.pc;
  }

  if (width <= pcWidth) {
    return CLASS_NAMES_FIX_MENU.sm;
  }

  if (width > SIZE_CHANGE_UNITS_BILLING_FIX_MENU_MD) {
    return CLASS_NAMES_FIX_MENU.md;
  }

  return '';
};
