import { WritableSignal } from '@angular/core';

// models
import { CreateRouteParams } from 'src/app/share/services/routes/models';
import {
  FilterClientOptionsRM,
  RouteEditable,
  TillSelectedRM,
} from '../../../models';

interface GetCreateRouteParams {
  routeEditableCreate: WritableSignal<RouteEditable>;
  haveOperatorVisibility: boolean;
  filterClientOptions: WritableSignal<FilterClientOptionsRM>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
}

interface GetMachineIds {
  filterClientOptions: WritableSignal<FilterClientOptionsRM>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
}

export const getCreateRouteParams = ({
  routeEditableCreate,
  haveOperatorVisibility,
  filterClientOptions,
  tillsSelected,
  tillOneMachineSelected,
}: GetCreateRouteParams): CreateRouteParams => {
  let params: CreateRouteParams = {
    routeNumber: routeEditableCreate()?.routeNumber,
    routeName: (routeEditableCreate()?.routeNumber || '').toString(),
    SupervisorID: getSupervisor(routeEditableCreate),
    RouteDrivers: getRouteDrivers(routeEditableCreate),
    machinesID: getMachineIds({
      filterClientOptions,
      tillsSelected,
      tillOneMachineSelected,
    }),
  };
  if (!haveOperatorVisibility) {
    params.OperatorID = routeEditableCreate()?.operatorId;
  }

  return params;
};

const getRouteDrivers = (
  routeEditableCreate: WritableSignal<RouteEditable>
): { RouteDriverID: number; Titular: number }[] => {
  let routeDrivers: { RouteDriverID: number; Titular: number }[] = [];

  const holderRouteDriver: { RouteDriverID: number; Titular: number } = {
    RouteDriverID: routeEditableCreate()?.holderRouteDriver?.routeDriverId,
    Titular: 1,
  };

  routeDrivers.push(holderRouteDriver);

  for (let routeDriverPos of (routeEditableCreate()?.substituteRouteDriver || [])) {
    if (routeDriverPos?.routeDriverId || routeDriverPos?.routeDriverId === 0) {
      let substituteRouteDriver: { RouteDriverID: number; Titular: number } = {
        RouteDriverID: routeDriverPos?.routeDriverId,
        Titular: 0,
      };

      routeDrivers.push(substituteRouteDriver);
    }
  }

  return routeDrivers;
};

const getSupervisor = (routeEditableCreate: WritableSignal<RouteEditable>) => {
  const supervisorId = routeEditableCreate()?.supervisor?.supervisorId;

  if (supervisorId || supervisorId === 0) {
    return supervisorId;
  }
  return null;
};

const getMachineIds = ({
  filterClientOptions,
  tillsSelected,
  tillOneMachineSelected,
}: GetMachineIds): number[] => {
  if (filterClientOptions() === FilterClientOptionsRM.filterClient) {
    return tillsSelected().map((till) => till.id);
  }

  return tillOneMachineSelected()?.id || tillOneMachineSelected()?.id === 0
    ? [tillOneMachineSelected()?.id]
    : [];
};
