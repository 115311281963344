// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-lg {
  font-size: 3.5em;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt-30 {
  margin-top: 30px;
}

.ml-2 {
  margin-left: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.info {
  display: inline-flex;
  align-items: center;
}

.mt-5 {
  margin-top: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.font-size-dialog {
  font-size: 1.2em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/machines/inventory-manager/components/modals/legend-inventory-manager/legend-inventory-manager.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,oBAAA;EACA,mBAAA;AAAF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,gBAAA;AADF","sourcesContent":[".font-lg{\r\n  font-size: 3.5em;\r\n}\r\n\r\n.d-flex{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n\r\n.mt-30{\r\n  margin-top: 30px;\r\n}\r\n\r\n.ml-2{\r\n  margin-left: 10px;\r\n}\r\n\r\n.mt-20{\r\n  margin-top: 20px;\r\n}\r\n\r\n.info{\r\n  display: inline-flex;\r\n  align-items: center;\r\n}\r\n\r\n\r\n.mt-5{\r\n  margin-top: 5px;\r\n}\r\n\r\n.ml-5{\r\n  margin-left: 5px;\r\n}\r\n\r\n.font-size-dialog{\r\n  font-size: 1.2em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
