// models
import { Family } from 'src/app/models';
import { FamiliesDashboardBasic } from '../models';

// constant
import { FAMILIES_VALIDS_DW } from './constants';

export const getFamiliesValids = (families: Family[]) => {

  let familiesValids: Record<FamiliesDashboardBasic, Family[]> = {
    colddrink: [],
    hotdrink: [],
    snack: [],
  };

  for (let familyBasic of Object.keys(FAMILIES_VALIDS_DW)) {
    const familyFilter = families.filter((family) =>
      includeFamilies(FAMILIES_VALIDS_DW[familyBasic], family.description)
    );

    familiesValids[familyBasic] = familyFilter;
  }

  return familiesValids;

};

const includeFamilies = (families: string[], description: string) => {

  for(let family of families){
    if((description.trim().toLowerCase()).includes(family)){
      return true;
    }
  }

  return false;
}

