// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mt-5p {
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-date-template/components/filter-date/filter-date.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF","sourcesContent":[".mt-5p{\r\n  margin-top: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
