// External Module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Module
import { HeaderModalModule } from '../../header-modal/header-modal.modal';

// Components
import {
  DatePeriodNoCalendarComponent,
  DateNoCalendarModalComponent,
  PeriodNoCalendarModalComponent,
} from './';

// Services
import { LanguageService } from '../../../services/languages.service';

// Pipe
import { DateStringLanguagePipe } from '../../../pipes/date-string-language/date-string-language.pipe';

// Language
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeIt);

@NgModule({
  declarations: [
    DatePeriodNoCalendarComponent,
    DateNoCalendarModalComponent,
    PeriodNoCalendarModalComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    HeaderModalModule,
  ],
  providers: [LanguageService, DateStringLanguagePipe],
  exports: [
    DatePeriodNoCalendarComponent,
    DateNoCalendarModalComponent,
    PeriodNoCalendarModalComponent,
  ],
})
export class DatePeriodNoCalendarModule {}
