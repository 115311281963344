// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block {
  display: block;
}

.title-group {
  font-size: 1.2em;
  text-decoration: underline;
}

.mt-16 {
  margin-top: 16px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-6 {
  margin-top: 6px;
}

.mr-4 {
  margin-right: 4px;
}

.indent-3 {
  text-indent: 3%;
}

.line-height-sm {
  line-height: 1em;
}

.font-sm {
  font-size: 0.8em;
}

.font-xs {
  font-size: 0.6em;
}

.image_logo {
  height: 25px;
}

.image_logo_sm {
  height: 20px;
}

.d-center {
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login/components/privacy-policy/privacy-policy.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,gBAAA;EACA,0BAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,YAAA;AADF","sourcesContent":[".block{\r\n  display: block;\r\n}\r\n\r\n.title-group{\r\n  font-size: 1.2em;\r\n  text-decoration: underline;\r\n}\r\n\r\n.mt-16{\r\n  margin-top: 16px;\r\n}\r\n\r\n.mt-8{\r\n  margin-top: 8px;\r\n}\r\n\r\n.mt-6{\r\n  margin-top: 6px;\r\n}\r\n\r\n.mr-4{\r\n  margin-right: 4px;\r\n}\r\n\r\n\r\n.indent-3{\r\n  text-indent: 3%;\r\n}\r\n\r\n.line-height-sm{\r\n  line-height: 1em;\r\n}\r\n\r\n\r\n.font-sm{\r\n  font-size: 0.8em;\r\n}\r\n\r\n.font-xs{\r\n  font-size: 0.6em;\r\n}\r\n\r\n.image_logo{\r\n  height: 25px;\r\n}\r\n\r\n.image_logo_sm{\r\n  height: 20px;\r\n}\r\n\r\n.d-center{\r\n  margin: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
