// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-split-pane {
  --side-min-width: 100px;
  --side-max-width: 280px;
}

@media (min-width: 660px) and (max-width: 699px) {
  ion-split-pane {
    --side-min-width: 100px;
    --side-max-width: 240px;
  }
}
@media (min-width: 600px) and (max-width: 659px) {
  ion-split-pane {
    --side-min-width: 150px;
    --side-max-width: 200px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAGA;EACE,uBAAA;EACA,uBAAA;AAFF;;AAIA;EACE;IACA,uBAAA;IACA,uBAAA;EADA;AACF;AAGA;EACE;IACA,uBAAA;IACA,uBAAA;EADA;AACF","sourcesContent":["// ion-menu{\r\n//     --width: 234px;\r\n//   }\r\nion-split-pane{\r\n  --side-min-width: 100px;\r\n  --side-max-width: 280px;\r\n}\r\n@media (min-width: 660px) and (max-width: 699px) {\r\n  ion-split-pane{\r\n  --side-min-width: 100px;\r\n  --side-max-width: 240px;\r\n  }\r\n}\r\n@media (min-width: 600px) and (max-width: 659px) {\r\n  ion-split-pane{\r\n  --side-min-width: 150px;\r\n  --side-max-width: 200px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
