import { Component, OnInit, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Service
import { SkinMangementService } from '../../../../services/skin-mangement.service';

// Models
import { Company } from '../../../../models';

// Utils
import { type } from '../../../../utils/constants';

@Component({
  selector: 'app-confidentiality',
  templateUrl: './confidentiality.component.html',
  styleUrls: ['./confidentiality.component.scss'],
})
export class ConfidentialityComponent implements OnInit {

  public scrolling = false;

  scrollDepthTriggered: boolean = false;

  company: Company;
  type = type;

  constructor(
     private modalCtrl: ModalController,
     private skinService: SkinMangementService) { }

  ngOnInit() {
    this.company = this.skinService.getCompany();
  }

  closeModal(state: boolean = false) {
    if(this.modalCtrl){
      this.modalCtrl.dismiss({
        accept: state
      });
    }
  }


  async logScrolling($event) {


      // only send the event once
      if (this.scrollDepthTriggered) {
        return;
      }

      //console.log('event', $event);

      if ($event.target.localName != "ion-content") {
        // not sure if this is required, just playing it safe
        return;
      }

      const scrollElement = await $event.target.getScrollElement();
      //console.log({ scrollElement });

      // minus clientHeight because trigger is scrollTop
      // otherwise you hit the bottom of the page before
      // the top screen can get to 80% total document height
      const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
      // console.log('scrollHeight', {scrollHeight});

      const currentScrollDepth = $event.detail.scrollTop;
      // console.log('currentScrollDepth', {currentScrollDepth});

      const targetPercent = 80;

      let triggerDepth = ((scrollHeight / 100) * targetPercent);
      //console.log('triggerDepth', {triggerDepth});



      if (currentScrollDepth > triggerDepth) {
        //console.log(`Scrolled to ${targetPercent}%`);
        // this ensures that the event only triggers once
        //console.log('final');
        this.scrollDepthTriggered = true;
      }
  }

}
