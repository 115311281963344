import {
  Component,
  Input,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper';

// models
import { Client, Company, Operator } from 'src/app/models';
import { FilterClientByOperatorPositions } from './filter-client-by-operator-positions.enum';

@Component({
  selector: 'app-filter-client-by-operator',
  templateUrl: './filter-client-by-operator.component.html',
  styleUrls: ['./filter-client-by-operator.component.scss'],
})
export class FilterClientByOperatorComponent implements OnInit {
  @Input() company: Company;

  @Input() operators: Operator[] = [];
  @Input() clients: Client[] = [];

  client: WritableSignal<Client> = signal(null);
  position: WritableSignal<number> = signal(
    FilterClientByOperatorPositions.Operator
  );

  config: SwiperOptions = {
    pagination: { clickable: false },
    keyboard: false,
  };

  clientsByOperator: Client[] = [];
  operatorSelected: Operator = null;

  clientText: string = '';

  positionVisibility = FilterClientByOperatorPositions;

  titles = {
    [FilterClientByOperatorPositions.Operator]:
      'filter_client_by_operator.select_operator',
    [FilterClientByOperatorPositions.Client]:
      'filter_client_by_operator.select_client',
  };

  @ViewChild('swiperFilterClientDashboard', { static: false })
  swiper: SwiperComponent;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    if ((this.operators || []).length === 1) {
      this.changeOperator(this.operators[0]);
    }
  }

  changeClient(client) {
    if (!client) {
      return;
    }

    this.client.set(client);

    this.modalCtrl.dismiss({ element: this.client() });
  }

  changeOperator(operator: Operator) {
    this.operatorSelected = operator;
    this.clientsByOperator = this.clients.filter(
      (client) => client.OperatorId === this.operatorSelected.id
    );

    this.goClient();
  }

  goClient() {
    if ((this.operators || []).length > 1) {
      this.position.set(FilterClientByOperatorPositions.Client);
      this.swiper.swiperRef.slideNext(300);
    } else {
      setTimeout(() => {
        this.position.set(FilterClientByOperatorPositions.Client);
      }, 50);
    }
  }

  backOperator() {
    this.position.set(FilterClientByOperatorPositions.Operator);
    this.swiper.swiperRef.slidePrev(300);
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }
}
