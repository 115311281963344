import { LinkRouteGroup } from "src/app/models";

export const COLOR_STYLE_ARTICLE_SUBMENU = 'color-article-svg';
export const COLOR_STYLE_WHITE_SUBMENU = 'color-white-svg';
export const COLOR_STYLE_INVERT_SUBMENU = 'color-invert-svg';

const ARTICLE_ICON = '../../../assets/custom-icons/articles.svg'

export const isArticleGroupSubMenu = (routeGroup: LinkRouteGroup) => {
  return routeGroup.icon === ARTICLE_ICON;
}
