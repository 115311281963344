// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-lg {
  font-size: 2em;
  margin-right: 10px;
}

.icon-md {
  font-size: 1.5em;
  margin-right: 5px;
}

.display-inline {
  display: inline-block;
}

.text-justify {
  text-align: justify;
}

.d-flex-center2 {
  display: flex;
  align-items: center;
  justify-content: left;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/aena/share/components/ui/aena-alert/aena-alert.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;AAAF","sourcesContent":[".icon-lg{\r\n  font-size: 2em;\r\n  margin-right: 10px;\r\n}\r\n\r\n.icon-md{\r\n  font-size: 1.5em;\r\n  margin-right: 5px;\r\n}\r\n\r\n.display-inline{\r\n  display: inline-block;\r\n}\r\n\r\n.text-justify{\r\n  text-align: justify;\r\n}\r\n\r\n\r\n.d-flex-center2{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: left;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
