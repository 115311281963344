import { WritableSignal } from '@angular/core';

// models
import { Client, Operator, RouteDriver, Supervisor } from 'src/app/models';
import { MachineRM, RouteEditable, TillSelectedRM } from '../../models';

// services
import { RouteManagerService } from '../../services';

// utils
import { UtilsTranslate } from 'src/app/utils';
import {
  clearTillSelectedRM,
  removeInfoInvalidOperator,
  validateHolderRouteDriverRM,
  validateSubstituteRouteDriverRM,
} from './';

// ------------------------ Interface ------------------------
interface ValidateChangesRouteSelectedRM {
  routeParticalSelected: RouteEditable;

  haveOperatorVisibility: boolean;

  operators: Operator[];
  operatorSelected: WritableSignal<Operator>;

  clients: WritableSignal<Client[]>;
  clientsByOperator: WritableSignal<Client[]>;
  clientAssigmentSelected: WritableSignal<Client>;

  supervisors: WritableSignal<Supervisor[]>;
  supervisorsByOperator: WritableSignal<Supervisor[]>;

  routeDrivers: WritableSignal<RouteDriver[]>;
  loadingRouteDrivers: WritableSignal<boolean>;

  tills: WritableSignal<TillSelectedRM[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;

  _routeManagerService: RouteManagerService;

  utils: UtilsTranslate;
}

interface ValidateOperator {
  routeParticalSelected: RouteEditable;

  haveOperatorVisibility: boolean;

  operators: Operator[];
  operatorSelected: WritableSignal<Operator>;

  clients;
  clientsByOperator;
  clientAssigmentSelected;

  supervisors: WritableSignal<Supervisor[]>;
  supervisorsByOperator: WritableSignal<Supervisor[]>;

  routeDrivers: WritableSignal<RouteDriver[]>;
  loadingRouteDrivers: WritableSignal<boolean>;

  tills: WritableSignal<TillSelectedRM[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;

  _routeManagerService: RouteManagerService;

  utils: UtilsTranslate;
}

interface ApplyInfoExistOperator {
  routeParticalSelected: RouteEditable;

  haveOperatorVisibility: boolean;

  operatorSelected: WritableSignal<Operator>;

  clients: WritableSignal<Client[]>;
  clientsByOperator: WritableSignal<Client[]>;
  clientAssigmentSelected: WritableSignal<Client>;

  supervisors: WritableSignal<Supervisor[]>;
  supervisorsByOperator: WritableSignal<Supervisor[]>;

  routeDrivers: WritableSignal<RouteDriver[]>;
  loadingRouteDrivers: WritableSignal<boolean>;

  tills: WritableSignal<TillSelectedRM[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;

  _routeManagerService: RouteManagerService;

  utils: UtilsTranslate;
}

interface ValidateSupervisor {
  routeParticalSelected: RouteEditable;

  operatorSelected: WritableSignal<Operator>;

  supervisors: WritableSignal<Supervisor[]>;
  supervisorsByOperator: WritableSignal<Supervisor[]>;
}

interface ValidateRouteDriver {
  routeParticalSelected: RouteEditable;

  operatorSelected: WritableSignal<Operator>;

  routeDrivers: WritableSignal<RouteDriver[]>;
  loadingRouteDrivers: WritableSignal<boolean>;

  _routeManagerService: RouteManagerService;

  utils: UtilsTranslate;
}

interface ValidateTills {
  routeParticalSelected: RouteEditable;
  operatorSelected: WritableSignal<Operator>;
  tills: WritableSignal<TillSelectedRM[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
}

interface FilterClientByOperator {
  haveOperatorVisibility: boolean;
  operatorSelected: WritableSignal<Operator>;

  clients: WritableSignal<Client[]>;
  clientsByOperator: WritableSignal<Client[]>;
  clientAssigmentSelected: WritableSignal<Client>;
}

// ------------------------ Functions ------------------------
export const validateChangesRouteSelectedRM = ({
  routeParticalSelected,
  haveOperatorVisibility,
  operators,
  operatorSelected,
  clients,
  clientsByOperator,
  clientAssigmentSelected,
  supervisors,
  supervisorsByOperator,
  routeDrivers,
  loadingRouteDrivers,
  tills,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
  _routeManagerService,
  utils,
}: ValidateChangesRouteSelectedRM) => {
  validateOperator({
    routeParticalSelected,
    haveOperatorVisibility,
    operators,
    operatorSelected,
    clients,
    clientsByOperator,
    clientAssigmentSelected,
    supervisors,
    supervisorsByOperator,
    routeDrivers,
    loadingRouteDrivers,
    tills,
    tillsByOperator,
    tillsSelected,
    tillOneMachineSelected,
    _routeManagerService,
    utils,
  });
};

const validateOperator = ({
  routeParticalSelected,
  haveOperatorVisibility,
  operators,
  operatorSelected,
  clients,
  clientsByOperator,
  clientAssigmentSelected,
  supervisors,
  supervisorsByOperator,
  routeDrivers,
  loadingRouteDrivers,
  tills,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
  _routeManagerService,
  utils,
}: ValidateOperator) => {
  const operator = operators.find(
    (operator) => operator.id === routeParticalSelected?.operatorId
  );
  if (operator) {
    operatorSelected.set(operator);
    applyInfoExistOperator({
      routeParticalSelected,
      haveOperatorVisibility,
      operatorSelected,
      clients,
      clientsByOperator,
      clientAssigmentSelected,
      supervisors,
      supervisorsByOperator,
      routeDrivers,
      loadingRouteDrivers,
      tills,
      tillsByOperator,
      tillsSelected,
      tillOneMachineSelected,
      _routeManagerService,
      utils,
    });
  } else {
    console.error('Operator not exist');
    operatorSelected.set(null);
    routeParticalSelected = {
      ...routeParticalSelected,
      operatorId: null,
      supervisor: null,
      holderRouteDriver: { enable: true, routeDriverId: null, name: '' },
      substituteRouteDriver: [{ enable: true, routeDriverId: null, name: '' }],
      tills: [],
    };

    removeInfoInvalidOperator({
     clientAssigmentSelected,
     clientsByOperator,
     haveOperatorVisibility,
     supervisorsByOperator,
     tillOneMachineSelected,
     tillsByOperator,
     tillsSelected
    })
  }
};

const applyInfoExistOperator = ({
  routeParticalSelected,
  haveOperatorVisibility,
  operatorSelected,
  clients,
  clientsByOperator,
  clientAssigmentSelected,
  supervisors,
  supervisorsByOperator,
  routeDrivers,
  loadingRouteDrivers,
  tills,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
  _routeManagerService,
  utils,
}: ApplyInfoExistOperator) => {
  filterClientByOperator({
    haveOperatorVisibility,
    operatorSelected,
    clients,
    clientsByOperator,
    clientAssigmentSelected,
  });

  validateSupervisor({
    routeParticalSelected,
    operatorSelected,
    supervisors,
    supervisorsByOperator,
  });

  validateRouteDriver({
    routeParticalSelected,
    operatorSelected,
    routeDrivers,
    loadingRouteDrivers,
    _routeManagerService,
    utils,
  });

  validateTills({
    routeParticalSelected,
    operatorSelected,
    tills,
    tillsByOperator,
    tillsSelected,
    tillOneMachineSelected,
  });
};

const validateSupervisor = ({
  routeParticalSelected,
  operatorSelected,
  supervisors,
  supervisorsByOperator,
}: ValidateSupervisor) => {
  if (
    routeParticalSelected?.supervisor?.supervisorId === 0 ||
    routeParticalSelected?.supervisor?.supervisorId
  ) {
    const supervisorFilteredByOperator = supervisors().filter(
      (supervisor) => supervisor.OperatorID === operatorSelected()?.id
    );

    supervisorsByOperator.set(supervisorFilteredByOperator);

    const isValidSupervisorSelected = supervisorsByOperator().find(
      (supervisor) =>
        supervisor.ID === routeParticalSelected.supervisor.supervisorId
    );

    if (!isValidSupervisorSelected) {
      console.error('Supervisor invalid');
      routeParticalSelected.supervisor = { supervisor: '', supervisorId: null };
    }
  }
};

const validateRouteDriver = ({
  routeParticalSelected,
  operatorSelected,
  routeDrivers,
  loadingRouteDrivers,
  _routeManagerService,
  utils,
}: ValidateRouteDriver) => {
  routeDrivers.set([]);
  loadingRouteDrivers.set(true);
  _routeManagerService
    .getRouteDrivers({ OperatorID: operatorSelected()?.id })
    .subscribe({
      next: (res) => {
        routeDrivers.set(res);

        validateHolderRouteDriverRM(routeParticalSelected, routeDrivers);
        validateSubstituteRouteDriverRM(routeParticalSelected, routeDrivers);

        loadingRouteDrivers.set(false);
      },
      error: () => {
        utils.showError();
        routeDrivers.set([]);
        loadingRouteDrivers.set(false);
      },
    });
};

const validateTills = ({
  routeParticalSelected,
  operatorSelected,
  tills,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
}: ValidateTills) => {
  const tillsByRoutePartial = routeParticalSelected?.tills;

  const tillFilteredByOperator = tills().filter(
    (till) => till.operatorId === operatorSelected().id
  );
  tillsByOperator.set(tillFilteredByOperator);

  if ((tillsByRoutePartial || []).length === 0) {
    clearTillSelectedRM({
      tills,
      tillsByOperator,
      tillsSelected,
      tillOneMachineSelected,
    });
    return;
  }

  let tillsValidate: MachineRM[] = [];
  for (let till of tillsByRoutePartial) {
    const existTIill = tillsByOperator().find(
      (tillPos) => till.machineId === tillPos.id
    );
    if (existTIill) {
      tillsValidate.push({
        machineCode: existTIill.code,
        machineId: existTIill.id,
      });
    } else {
      console.error('Till Invalid');
    }
  }

  clearTillSelectedRM({
    tills,
    tillsByOperator,
    tillsSelected,
    tillOneMachineSelected,
  });

  if ((tillsValidate || []).length !== (tillsByRoutePartial || []).length) {
    routeParticalSelected.tills = tillsValidate;
  }

  if ((routeParticalSelected.tills || []).length === 0) {
    return;
  }

  const newTills = tills();
  const newTillsByOperator = tillsByOperator();
  const newTillSelected = [];
  for (let tillSelected of routeParticalSelected.tills) {
    const changeTill = newTills.find(
      (till) => till.id === tillSelected.machineId
    );
    if (changeTill) {
      changeTill.isSelected = true;
    }

    const changeTillOperator = newTillsByOperator.find(
      (till) => till.id === tillSelected.machineId
    );
    if (changeTillOperator) {
      changeTillOperator.isSelected = true;
      newTillSelected.push(changeTillOperator);
    }
  }

  tills.set(newTills);
  tillsByOperator.set(newTillsByOperator);
  tillsSelected.set(newTillSelected);
};

export const filterClientByOperator = ({
  haveOperatorVisibility,
  operatorSelected,
  clients,
  clientsByOperator,
  clientAssigmentSelected,
}: FilterClientByOperator) => {
  if(!haveOperatorVisibility){
    const newClientsByOperator = clients().filter(client => client.OperatorId === operatorSelected()?.id)
    clientsByOperator.set(newClientsByOperator);
    clientAssigmentSelected.set(null);
  }
};
