import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { Company } from 'src/app/models';
import { SkinMangementService } from 'src/app/services';

@Component({
  selector: 'app-dashboard-refilling-legend',
  templateUrl: './dashboard-refilling-legend.component.html',
  styleUrls: ['./dashboard-refilling-legend.component.scss'],
})
export class DashboardRefillingLegendComponent implements OnInit {

  company: Company;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
