import { Injectable } from '@angular/core';
import { Subject, lastValueFrom } from 'rxjs';

// service
import { TransactionsQueryService } from './transactions-query.service';
import { PermissionService } from '../../../../../services';

// models
import {Transaction,TransactionChange, CountTransaction, TransactionsParams } from '../models';

@Injectable({
  providedIn: 'root'
})
export class TransactionsContentService {

  transactions: Transaction[] = [];
  total: CountTransaction[] = null;
  counterTotal: number = 0;

  loadingTransactions = false;
  loadingTotal = false;

  page: number = null;

  // solo se puede descargar si tiene client, machine code, articles, card num o visibilidad cliente
  validDownload = false;

  transactionsPage: Object = {};

  transactionChange: Subject<TransactionChange> = new Subject<TransactionChange>();

  validChange: Subject<boolean> = new Subject<boolean>();

  lastParamsFilter: TransactionsParams;

  // descargar excel solo cuando tenga articles
  haveArticleFilter = false;

  firstTime = true;

  haveClientVisibility: boolean = false;

  constructor(
    private _transactionsQueryService: TransactionsQueryService,
    private _permissionService: PermissionService) {}

  getTransactions(): TransactionChange {

    return {
      transactions: this.transactions, total: this.total,
      loading: this.loadingTransactions, loadingTotal: this.loadingTotal, page: this.page,
      counter: this.counterTotal, firstTime: this.firstTime
    }
  }

  setTransactions(transactions: Transaction[], filter: Object, page: number = 0, changeFilter = true) {
    if(this.firstTime) this.firstTime = false;

    if (changeFilter) {
      this.transactionsPage = {};
    }

    this.transactionsPage[page.toString()] = transactions;


    this.transactions = transactions;
    this.loadingTransactions = false;
    this.page = page;
    this.transactionChange.next({
      transactions: this.transactions,
      loading: this.loadingTransactions, page: this.page,
      counter: this.counterTotal
    })
  }

  setTotal(total: CountTransaction[], page?: number) {

    this.total = total;

    this.loadingTotal = false;
    this.counterTotal = total.reduce( (acc,total) =>  acc + total.counter, 0)
    if(page){
      this.transactionChange.next({ total: this.total, loadingTotal: this.loadingTotal, counter: this.counterTotal,
      page
      });
    }else{
      this.transactionChange.next({ total: this.total, loadingTotal: this.loadingTotal, counter: this.counterTotal });
    }

  }

  setLoading() {
    if(this.firstTime) this.firstTime = false;
    this.transactions = [];
    this.loadingTransactions = true;
    this.loadingTotal = true;
    this.transactionChange.next({
      loading: this.loadingTransactions, loadingTotal: this.loadingTotal,
      transactions: this.transactions, firstTime: this.firstTime
    });
  }

  setLoadingPage() {
    this.transactions = [];
    this.loadingTransactions = true;
    this.transactionChange.next({ loading: this.loadingTransactions, transactions: this.transactions });
  }


  getTransactionsPage(paramsFilter: TransactionsParams) {

    let pageKey = paramsFilter.page?.toString() || (0).toString();

    // console.log('paramsFilter', paramsFilter)

    let isToday = false;

    let today = new Date();


    let dateSepartor = paramsFilter?.dateEnd.split('-')
    let dayParam = new Date(Number(dateSepartor[0]), Number(dateSepartor[1])-1, Number(dateSepartor[2]))

    if(paramsFilter?.hourEnd){
      let hourSeparator = paramsFilter?.hourEnd.split(':');
      dayParam = new Date(Number(dateSepartor[0]), Number(dateSepartor[1])-1, Number(dateSepartor[2]),
        Number(hourSeparator[0]), Number(hourSeparator[1]),
        Number(hourSeparator[2]));
      (today.getTime() - dayParam.getTime() >= 0) ? isToday = false : isToday = true;
    }else{
      today.setHours(0,0,0,0);
      dayParam.setHours(0,0,0,0);
      (today.setHours(0,0,0,0) === dayParam.setHours(0,0,0,0)) ? isToday= true : isToday = false;
    }

    // transaction repeteida con el mismo filtro
    if (pageKey in this.transactionsPage && !isToday) {
      this.transactions = this.transactionsPage[pageKey];
      this.transactionChange.next({ transactions: this.transactions });
    } else {
      this.setLoadingPage();

      this._transactionsQueryService.getTransactions(paramsFilter).subscribe({next: res => {
        this.setTransactions(res, paramsFilter, paramsFilter.page, false);

      }, error: () => {
        this.stopLoading();
      }});

      if(isToday){
        this.reloadCounter(paramsFilter)
      }
    }


  }


  stopLoading(){
    this.transactions = [];
    this.loadingTransactions = false;
    this.loadingTotal = false;
    this.transactionChange.next({
      loading: this.loadingTransactions, loadingTotal: this.loadingTotal,
      transactions: this.transactions
    });
  }

  reset(){
    this.transactions = [];
    this.total = null;
    this.loadingTransactions = false;
    this.loadingTotal = false;
    this.page = null;
    this.transactionsPage = {};
    this.lastParamsFilter = null;
    this.firstTime = true;
    this.counterTotal = 0;

    this.transactionChange.next({
      transactions: this.transactions,
      loading: this.loadingTransactions,
      page: this.page,
      total: [],
      firstTime: this.firstTime
    })
  }


  setValid(params: TransactionsParams){
    this.validDownload = false;
    if(
    ( this.haveClientVisibility
    || (params?.clientIds &&  params?.clientIds.length > 0) ||
    (params?.machine_codes  && params?.machine_codes.length >0) ||
    (params?.articleIds && params?.articleIds.length >0) ||
    (params?.cardNums && params?.cardNums.length > 0) ||
    params?.num_ticket_AENA > 0 ||
    (this._permissionService.getUserVisibilityByHierarchy("Client"))
    )
      && !this.loadingTransactions && this.transactions.length > 0 && this.haveArticleFilter
    ){
      this.validDownload = true;
    }

    //console.log('transactions', this.loadingTransactions, 'this.transactions.length > 0', this.transactions.length)

    this.validChange.next(this.validDownload);
  }

  setValidType(haveArticle){
    if(!haveArticle && this.validDownload){
      this.validDownload = false;
      this.validChange.next(this.validDownload);
    }
    this.haveArticleFilter = haveArticle
  }

  setValidFalse(){
    this.validDownload = false;
    this.validChange.next(this.validDownload);
  }

  getValid(){
    return this.validDownload;
  }

  setParamsFilter(paramsFilter){
    this.lastParamsFilter = {...paramsFilter};
  }

  getParamsFiltter(){
    return this.lastParamsFilter;
  }


  setHaveClientVisibility(haveClientVisibility: boolean){
    this.haveClientVisibility = haveClientVisibility;
  }

  async reloadCounter(paramsFilter){
    let resCount = await lastValueFrom(this._transactionsQueryService.getCountTransactions(paramsFilter))
    this.setTotal(resCount, paramsFilter?.page);
  }


}
