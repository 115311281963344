import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// services
import { StorageService } from '../share/services/storage/storage.service';

// models
import { ColorTheme } from '../models';

// utils
import { getColorButtonClear } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class ColorModeService {

  isLightColor: boolean = true;
  changeColor$ = new Subject<boolean>();

  constructor(
    private _storageService: StorageService
  ) { }

  start(){

    this._storageService.get('prefer-color').then((colorTheme: ColorTheme)=> {
      if(colorTheme === 'dark' || colorTheme === 'light'){
        this.getColorByStorage(colorTheme)
      }
      else{
        this.getColorByMatchMedia()
      }
    })

  }

  getColorByMatchMedia(){
    let systemDark = window.matchMedia("(prefers-color-scheme: dark)");

    if (systemDark.matches) {
      document.body.setAttribute('data-theme', 'dark');
      this.isLightColor = false;
    } else {
      document.body.setAttribute('data-theme', 'light');
      this.isLightColor = true;
    }
  }

  getColorByStorage(colorTheme: ColorTheme ){
    document.body.setAttribute('data-theme', colorTheme);
    this.isLightColor = colorTheme === 'light';
    this.changeColor$.next(this.isLightColor)
  }

  changeColor(){
    this.isLightColor = !this.isLightColor;
    if(!this.isLightColor){
      document.body.setAttribute('data-theme', 'dark');
    }
    else{
      document.body.setAttribute('data-theme', 'light');
    }
    this.changeColor$.next(this.isLightColor)
    this._storageService.set('prefer-color', this.isLightColor ? 'light' : 'dark')
  }

  getIsLightColor(){
    return this.isLightColor;
  }

  getColorTooltipTheme(): ColorTheme{
    return (this.isLightColor) ? 'dark' : 'light';
  }

  getColorTheme(): ColorTheme{
    return (this.isLightColor) ? 'light' : 'dark';
  }

  getColorButtonClear(){
    return getColorButtonClear(this.isLightColor);
  }

}


