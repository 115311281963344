import { Component, OnInit, Input } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { pcWidthMix } from 'src/app/utils';

@Component({
  selector: 'app-aena-alert',
  templateUrl: './aena-alert.component.html',
  styleUrls: ['./aena-alert.component.scss'],
})
export class AenaAlertComponent implements OnInit {

  @Input() company: Company;
  @Input() textInfo: string = 'assign_articles_families_aena.alert_info_aena';

  pcWidthMix = pcWidthMix;


  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}

}
