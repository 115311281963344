export const MAX_LENGTH_NAME_CLIENT: number = 100;
export const MAX_LENGTH_CODE_CLIENT: number = 30;
export const MAX_LENGTH_REFERENCE_CLIENT: number = 250;
export const MAX_LENGTH_CIF_CLIENT: number = 50;
export const MAX_LENGTH_ADDRESS_CLIENT: number = 255;
export const MAX_LENGTH_EMAIL_CLIENT: number = 255;
export const MAX_POSTAL_CODE_CLIENT: number = 8;


export const NAME_HIGH_LEVEL_CLIENT_ROUTE: string = 'High level: Create/Edit Client';
export const ROUTE_CLIENT_MANAGER: string = '/user/data-manager/client-manager'



