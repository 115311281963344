// chartjs
import { ChartOptions, ChartTypeRegistry, TooltipItem } from 'chart.js';

// utils
import { COLOR_AXIS_LIGHT, COLOR_AXIS_DARK, getColorTooptipColor, POSITIONS_FAMILIES_WITH_ALL } from '..';
import { FamiliesDashboardWithAll } from '../../models';

// --------- interfaces -----------------------

interface AverageInfo {
  showAverage: boolean,
  totals?: number[],
  translate?: { percentage: string; average: string },
  average?: Record<FamiliesDashboardWithAll, number[]>,
  symbolPriceAverage?: string,
}

interface GetAverageText {
  context: TooltipItem<keyof ChartTypeRegistry>;
  symbol: string;
  totals: number[];
  translate: { percentage: string; average: string };
  average: Record<FamiliesDashboardWithAll, number[]>,
  symbolPriceAverage: string,
}

interface GetAveragePrice{
  average: Record<FamiliesDashboardWithAll, number[]>,
  posType: number,
  position: number
}

export const getChartOptionsGridColor = (
  barChartOptions: ChartOptions,
  isLightColor: boolean,
  symbol: string = 'u',
  locale: string,
  average: AverageInfo,
  axesYmax: number = null
): ChartOptions => {
  const colorToolTip = getColorTooptipColor(isLightColor);

  let ticks: any = {
    callback: (label) => `${label}${symbol}`,
  };

  if (symbol === 'u') {
    ticks.precision = 0;
  }

  return {
    ...barChartOptions,
    scales: {
      x: {
        grid: {
          color: isLightColor ? COLOR_AXIS_LIGHT : COLOR_AXIS_DARK,
        },
      },
      y: {
        grid: {
          color: isLightColor ? COLOR_AXIS_LIGHT : COLOR_AXIS_DARK,
        },
        // ticks: {
        //   callback: (label) => (`${label}${symbol}`),
        //   precision: 0
        // },
        ticks,
        min: 0,
        max: axesYmax,
        beginAtZero: true,
      },
    },
    // layout: {
    //   padding: {
    //     top: 10
    //   },
    // },
    plugins: {
      ...barChartOptions.plugins,
      legend: { position: 'bottom' },
      tooltip: {
        backgroundColor: colorToolTip.backgroundColor,
        titleColor: colorToolTip.titleColor,
        callbacks: {
          labelTextColor: () => colorToolTip.labelColor,
          label: (context) => {
            if (average?.showAverage) {
              return getAverageText({
                context,
                symbol: symbol || '',
                totals: average?.totals || [],
                translate: average?.translate,
                average: average?.average,
                symbolPriceAverage: average?.symbolPriceAverage || ''
              });
            }

            if (symbol === 'u') {
              return context.label + ': ' + context.formattedValue + symbol;
            }

            if (locale === 'en-EN') {
              return (
                context.label + ': ' + Number(context.raw).toFixed(2) + symbol
              );
            }

            return (
              context.label +
              ': ' +
              Number(context.raw).toFixed(2).replace('.', ',') +
              symbol
            );
          },
        },
      },
    },
  };
};

export const getAverageText = ({
  context,
  symbol,
  totals,
  translate,
  average,
  symbolPriceAverage
}: GetAverageText) => {

  const averagePrice = getAveragePrice(
    {
      average,
      position: context?.dataIndex,
      posType: context?.datasetIndex
    }
  )

  if (context?.datasetIndex === 0) {
    if(average){
      return [
        context.label + ': ' + context.formattedValue + symbol,
        translate.average + averagePrice + symbolPriceAverage
      ]
    }
    return [context.label + ': ' + context.formattedValue + symbol];
  }

  const dataSetLength = (context?.dataset?.data || []).length;
  const totalsLength = (totals || []).length;
  if (totalsLength === 0 || totalsLength !== dataSetLength) {
    return [context.label + ': ' + context.formattedValue + symbol];
  }

  const position = context?.dataIndex;

  if (!totals?.[position]) {
    return [context.label + ': ' + context.formattedValue + symbol];
  }

  const averageText = (
    (Number(context.dataset.data[position]) / totals[position]) *
    100
  ).toFixed(2);

  if(average){
    return [
      context.label + ': ' + context.formattedValue + symbol,
      translate.percentage + averageText,
      translate.average + averagePrice + symbolPriceAverage,
    ]
  }

  return [
    context.label + ': ' + context.formattedValue + symbol,
    translate.percentage + averageText,
  ];
};

const getAveragePrice = ({
    average,
    posType,
    position
  }: GetAveragePrice
) => {
  if(!average){
    return 0;
  }

  const typeSelected: FamiliesDashboardWithAll = getTypeSelected(posType);
  return (average?.[typeSelected]?.[position] || 0).toFixed(2);

};

const getTypeSelected = (position: number) => {
  return POSITIONS_FAMILIES_WITH_ALL?.[position] || 'all';
}
