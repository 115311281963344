export const transformDateMachineAlert = (date: string) => {
  if (!date) {
    return null;
  }
  const dateTransformed = new Date(date);
  dateTransformed.setHours(0);
  dateTransformed.setMinutes(0);
  dateTransformed.setSeconds(0);
  dateTransformed.setMilliseconds(0);

  return dateTransformed;
};
