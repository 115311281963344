// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

import { FilterClientIndividualModule } from '../filter-client-individual/filter-client-individual.module';
import { FilterOperatorIndividualModule } from '../filter-operator-individual/filter-operator-individual.module';
import { LoadCreditCardModule } from '../../../pages/user/cards/webload/load-credit/component/load-credit-card/load-credit-card.module';
import { AlertMaxCardIntervalModule } from 'src/app/pages/user/cards/register-cards/components/share/alert-max-cards-interval/alert-max-cards-interval.module';
import { HeaderModalModule } from '../header-modal/header-modal.modal';
import { VisibilitySelectModule } from '../visibility-select/visibility-select.module';

// Component
import { CarouselFilterGuidedcomponent } from './carousel-filter-guided.component';
import {
  // ArrowPositionComponent,
  FilterCardIndividualComponent,
  AssignCardModalComponent,
  CreatedIntervalModalComponent,
  SelectIndividualCardComponent,
  SelectIntervalCardComponent,
  SelectImportWebloadComponent,
  SelectRegisterCardComponent,
} from './components';

// Service
import { LanguageService } from '../../services/languages.service';
import { SkinMangementService } from 'src/app/services';
import { ArrowPositionShareModule } from './components/arrow-position';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    HttpClientModule,
    SwiperModule,

    FilterClientIndividualModule,
    FilterOperatorIndividualModule,
    HeaderModalModule,
    VisibilitySelectModule,
    LoadCreditCardModule,
    AlertMaxCardIntervalModule,
    ArrowPositionShareModule
  ],
  declarations: [
    // ArrowPositionComponent,
    AssignCardModalComponent,
    CarouselFilterGuidedcomponent,
    CreatedIntervalModalComponent,
    FilterCardIndividualComponent,
    SelectImportWebloadComponent,
    SelectIndividualCardComponent,
    SelectIntervalCardComponent,
    SelectRegisterCardComponent,
  ],
  providers: [LanguageService, SkinMangementService],
  exports: [

    ArrowPositionShareModule,

    // ArrowPositionComponent,
    AssignCardModalComponent,
    CarouselFilterGuidedcomponent,
    CreatedIntervalModalComponent,
    FilterCardIndividualComponent,
    SelectImportWebloadComponent,
    SelectIntervalCardComponent,
    SelectRegisterCardComponent,
  ],
})
export class CarouselGuidedModule {}
