import { dateTypes } from '../../../../../utils/plugins/useDate.plugin';
export const MAX_LENGTH_NAME_REPORT: number = 100;

export const DIFF_DAYS_REPORT: number = 180;

export const PERIODICITIES:Record<string, dateTypes> = {
  'Daily': 'days',
  'Weekly': 'weeks',
  'Monthly': 'months',
  'Yearly': 'years'
}

