import { encryptValue } from './constants';
import * as CryptoJS from 'crypto-js';


// crypto 
// quitar el warning
// angular json allowedCommonJsDependencies
// brosser node: { crypto: true, stream: true }
// encriptar
export const setCrypto = (value) => {
    let keys = encryptValue;
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
  
    return encrypted.toString();
  }
  
  // desencriptar
  export const getCrypto = (value) => {
    let keys = encryptValue;
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
  
    return decrypted.toString(CryptoJS.enc.Utf8);
  }