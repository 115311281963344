import { WritableSignal } from '@angular/core';

// services
import { FilterDateServiceI } from 'src/app/models';

// utils
import { getTomorrowDay, sameDay } from 'src/app/utils';
import { PERIODICITIES_REPORT_MONTHLY_ID } from './constants';

interface GetFirstDateCreateEditReport {
  periodSelected: number;
  _filterDateService: FilterDateServiceI;
  dayReportMonthly: WritableSignal<number>;
  monthReportMonthly: WritableSignal<number>;
}

export const getFirstDateCreateEditReport = ({
  periodSelected,
  _filterDateService,
  dayReportMonthly,
  monthReportMonthly,
}: GetFirstDateCreateEditReport): Date => {
  const monthlyReportId: number = PERIODICITIES_REPORT_MONTHLY_ID;

  if (!periodSelected && periodSelected !== 0) {
    return null;
  }

  if (periodSelected !== monthlyReportId) {
    return getFirstDateWeeklyOrDaily(_filterDateService);
  }

  return getFirstDateMonthly(dayReportMonthly, monthReportMonthly);
};

export const getFirstDateWeeklyOrDaily = (
  _filterDateService: FilterDateServiceI
) => {
  if (!_filterDateService.getDateStart()) {
    return null;
  }
  return new Date(`${_filterDateService.getDateStart()} 00:00`);
};

export const getFirstDateMonthly = (
  dayReportMonthly: WritableSignal<number>,
  monthReportMonthly: WritableSignal<number>
) => {
  if (
    (!dayReportMonthly() && dayReportMonthly() !== 0) ||
    (!monthReportMonthly() && monthReportMonthly() !== 0)
  ) {
    return null;
  }

  const tomorrowDate = getTomorrowDay();
  let year = tomorrowDate.getFullYear();

  let dateText = `${monthReportMonthly()
    .toString()
    .padStart(2, '0')}-${dayReportMonthly()
    .toString()
    .padStart(2, '0')}-${year}`;

  let dateMonthly = new Date(dateText);

  const isSameDayMonthyAndTomrrow = sameDay(dateMonthly, tomorrowDate);

  if (
    dateMonthly.getTime() < tomorrowDate.getTime() &&
    !isSameDayMonthyAndTomrrow
  ) {
    dateText = `${monthReportMonthly()
      .toString()
      .padStart(2, '0')}-${dayReportMonthly()
      .toString()
      .padStart(2, '0')}-${Number(year + 1)}`;
    dateMonthly = new Date(dateText);
  }

  // console.log('date', dateMonthly);

  return dateMonthly;
};
