import { FilterDateServiceI } from 'src/app/models';
import { removeMaxHourMachineAlert } from './remove-max-hour-machine-alert';

export interface GetIntervalTimesMachineStatus {
  _filterDateService: FilterDateServiceI;
  haveExtraHour: boolean;
  _filterDateExtraHourService: FilterDateServiceI;
}

export const getIntervalTimesMachineStatus = ({
  _filterDateService,
  haveExtraHour,
  _filterDateExtraHourService,
}: GetIntervalTimesMachineStatus) => {
  let intervalTimes: { startTime: string; endTime: string }[] = [
    {
      startTime: removeMaxHourMachineAlert(_filterDateService.getStartHour()),
      endTime: removeMaxHourMachineAlert(_filterDateService.getEndHour()),
    },
  ];

  if (haveExtraHour) {
    intervalTimes.push({
      startTime: removeMaxHourMachineAlert(_filterDateExtraHourService.getStartHour()),
      endTime: removeMaxHourMachineAlert(_filterDateExtraHourService.getEndHour()),
    });
  }

  return intervalTimes;
};
