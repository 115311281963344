import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Service
import { SkinMangementService } from 'src/app/services';

// Models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-warning-not-email',
  templateUrl: './warning-not-email.component.html',
  styleUrls: ['./warning-not-email.component.scss'],
})
export class WarningNotEmailComponent implements OnInit {

  company: Company;

  constructor(
    private _skinService: SkinMangementService,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({

    });
  }

}
