// models
import {
  TypeAlertMachineStatus,
  WeekDaysMachineStatus,
  MakeTillAlertSpiderParams,
} from '../../../models';

// utils
import { getWeekDayIds } from '..';

export interface CreateMakeAlertSpiderParams {
  companyId: number;
  operatorGroupId: number;
  operatorId: number;
  clientId: number;
  tillId: number;
  hour: number;
  minute: number;
  startHour: string;
  endHour: string;
  userEmail: string;
  email: string;
  weekDays: WeekDaysMachineStatus[];
  typeAlertSelected: TypeAlertMachineStatus;
}

export const createMakeAlertParams = ({
  companyId,
  operatorGroupId,
  operatorId,
  clientId,
  tillId,
  hour,
  minute,
  startHour,
  endHour,
  userEmail,
  email,
  weekDays,
  typeAlertSelected,
}: CreateMakeAlertSpiderParams): MakeTillAlertSpiderParams => {
  return {
    companyID: companyId,
    operatorGroupID: operatorGroupId,
    operatorID: operatorId,
    clientID: clientId,
    machineIDs: [tillId],
    statusID: typeAlertSelected?.id,
    StartTime: startHour,
    EndTime: endHour,
    CronHourTime: hour,
    CronMinuteTime: minute,
    WeekDayIDs: getWeekDayIds(weekDays),
    email: [userEmail, email],
  };
};
