// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-100 {
  width: 100% !important;
}

.font-sm-icon {
  font-size: 1.2em;
  margin-right: 10px;
}

.min-height-250 {
  min-height: 250px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/cards/register-cards/components/share/modals/search-client-by-operator/search-client-by-operator.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".w-100{\r\n  width: 100% !important;\r\n}\r\n\r\n.font-sm-icon{\r\n  font-size: 1.2em;\r\n  margin-right: 10px;\r\n}\r\n\r\n.min-height-250{\r\n  min-height: 250px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
