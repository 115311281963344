import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';

// models
import { Company } from 'src/app/models';

// utils
import { isTypeExcelValid, UtilsTranslate } from 'src/app/utils';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnInit {
  @Input() company: Company;
  @Input() fileId: string = 'FileInput';
  @Input() file: WritableSignal<File> = signal(null);

  @Output() onFileSelected = new EventEmitter<any>()

  // @ViewChild('FileInput', {static: false}) inputFile: ElementRef;

  constructor(private utils: UtilsTranslate) {}

  ngOnInit() {}

  onFileSelectedHandle(event) {
    this.onFileSelected.emit(event)
  }

  openFileSelected() {
    const inputFile = document.getElementById(this.fileId);
    if (inputFile) {
      inputFile.click();
    } else {
      this.utils.showError();
    }
  }
}
