import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// models
import {
  CharacteristicsTillAlertSpider,
  CharacteristicsTillAlertSpiderWithExtra,
  EventFilterVisibility,
  FilterTableTillAlert,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class FilterTillAlertService {
  sendEventFilter$: Subject<EventFilterVisibility> = new Subject<EventFilterVisibility>();

  changeFilterTable$: Subject<{
    type: FilterTableTillAlert;
    reload: boolean;
  }> = new Subject<{ type: FilterTableTillAlert; reload: boolean }>();

  filterTable: FilterTableTillAlert = null;

  status: CharacteristicsTillAlertSpider[] = [];
  typesAlert: CharacteristicsTillAlertSpiderWithExtra[] = [];

  constructor() {}

  sendUpdateTillAlert() {
    this.sendEventFilter$.next('update');
  }

  sendClearTillAlert() {
    this.sendEventFilter$.next('clear');
  }

  getFilterTable() {
    return this.filterTable;
  }

  editFilterTable(filterTable: FilterTableTillAlert) {
    this.editFilters(filterTable);
    this.changeFilterTable$.next({ type: filterTable, reload: false });
  }

  editFilters(filterTable: FilterTableTillAlert) {
    this.filterTable = filterTable;
  }

  clearFilter(filterTable: FilterTableTillAlert, haveMoreOneOperator: boolean) {
    this.editFilters(filterTable);
    if (!haveMoreOneOperator) {
      this.reloadChart(filterTable);
    }
  }

  reloadChart(filterTable: FilterTableTillAlert) {
    this.changeFilterTable$.next({ type: filterTable, reload: true });
  }

  getStatus(){
    return this.status;
  }

  setStatus(status: CharacteristicsTillAlertSpider[]){
    this.status = status;
  }

  getTypeAlert(){
    return this.typesAlert;
  }

  setTypeAlert(typesAlert: CharacteristicsTillAlertSpiderWithExtra[]){
    this.typesAlert = typesAlert;
  }

  clear(){
    this.filterTable = {
      clientSelected: '',
      clientId: null,
      machineSelected: null,
      machineId: null,
      email: '',
      status: '',
      statusId: null,
      typeAlert: null,
      typeAlertId: null,
    };
  }

}
