
export const NAME_ARTICLE_MANAGER_ROUTES: {[x: string]: PermissionArticleManager } = {
  'Articles Basic': {plusInfo: false, productFrom0To99: false, downloadExcel: false},
  'Articles Medium': {plusInfo: false, productFrom0To99: true, downloadExcel: false},
  'Articles Complex': {plusInfo: false, productFrom0To99: false, downloadExcel: true},
  'Articles All': {plusInfo: false, productFrom0To99: true, downloadExcel: true},
  'Articles Basic Plus': {plusInfo: true, productFrom0To99: false, downloadExcel: false},
  'Articles Medium Plus': {plusInfo: true, productFrom0To99: true, downloadExcel: false},
  'Articles Complex Plus': {plusInfo: true, productFrom0To99: false, downloadExcel: true},
  'Articles All plus': {plusInfo: true, productFrom0To99: true, downloadExcel: true},
}

export interface PermissionArticleManager {
  plusInfo: boolean,
  productFrom0To99: boolean,
  downloadExcel: boolean
}

export const ARTICLE_MANAGER_ROUTE: string = '/user/data-manager/article-manager'
