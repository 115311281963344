export interface TranslateMachineRoutes {
  machineStatus: string;
  tillMapping: string;
  realtimesale: string;
  machineList: string;
  site: string;
  qrRequest: string;
  ticketCheck: string;
  inventory: string;
  inventoryManager: string;
  ocsAlerts: string;
  tillsAlerts: string;
  siteElora: string;
  iFreeVending: string
}

export const getRoutesMachines = (
  translates: TranslateMachineRoutes
): { route: string; name: string }[] => {
  return [
    { route: '/user/machines-status', name: translates.machineStatus },
    { route: '/user/till-mapping', name: translates.tillMapping },
    { route: '/user/real-time-sale', name: translates.realtimesale },
    { route: '/user/machines', name: translates.machineList },
    { route: '/user/site', name: translates.site },
    { route: '/user/qr-request', name: translates.qrRequest },
    { route: '/user/ticket-check', name: translates.ticketCheck },
    { route: '/user/inventory', name: translates.inventory },
    {
      route: '/user/inventory-manager',
      name: translates.inventoryManager,
    },
    { route: '/user/ocs-alerts', name: translates.ocsAlerts },
    { route: '/user/tills-alerts', name: translates.tillsAlerts },
    { route: '/user/tills-alerts-by-user', name: translates.tillsAlerts },
    { route: '/user/site-elora', name: translates.siteElora },
    { route: '/user/ifree-vend-manager', name: translates.iFreeVending}
  ];
};
