// services
import { FilterTransactionsQueryService } from '../../services';

// models
import { Client } from 'src/app/models';

// utils
import { isValidClientSelectedTransactionQuery } from './';

interface CheckClientFilteredChangeOperator {
  _filterService: FilterTransactionsQueryService;
  clients: Client[];
}

export const checkClientFilteredChangeOperator = ({
  _filterService,
  clients,
}: CheckClientFilteredChangeOperator) => {
  const isValidClient = isValidClientSelectedTransactionQuery({
    operatorIds: _filterService.getOperatorIds(),
    clientId: _filterService.getClientId(),
    clients,
  });

  if (!isValidClient) {
    _filterService.setClient('', '', null);
  }
};
