import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  WritableSignal,
} from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';

// services
import { FilterReportService } from '../../../services/filter-report.service';
import { ColorModeService } from '../../../../../../../services';
import { CardsByTextReportService } from '../../../services';

// models
import { CardNames, Company } from 'src/app/models';
import { DatePickerFilterOptions } from 'src/app/share/components/filter-date-template/models';
import {
  EditReportParams,
  Report,
  TypeReport,
} from '../../../models';

// utils
import {
  pcWidth,
  pcWidthMix,
  closeTooltip,
  TIME_WAIT_CLOSE_MODAL,
  ModalGenerator,
} from '../../../../../../../utils';
import { showModalEditReport, showReportCards, TYPE_REPORT_SHOW_OPERATORGROUP_AND_OPERATOR } from '../../../utils';

@Component({
  selector: 'app-view-report-modal',
  templateUrl: './view-report-modal.component.html',
  styleUrls: ['./view-report-modal.component.scss'],
})
export class ViewReportModalComponent implements OnInit {
  @Input() company: Company;
  @Input() report: Report;
  @Input() datePipeTranslate: string = 'dd/MM/yyyy HH:mm:ss';
  @Input() translatesPeriod: { [x: string]: string } = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly',
  };
  @Input() typeReports: TypeReport[] = [];

  @Input() dateHourInModal: TemplateRef<string>;
  @Input() datePickerFilter: WritableSignal<DatePickerFilterOptions>;

  @Input() haveInvalidCard: WritableSignal<boolean>;
  @Input() nextExecution: Date | string;

  @Output() editReportSuccessfully = new EventEmitter();

  id = 'closeViewReportModal';

  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;

  showReportCards = showReportCards;

  isModalClose = false;

  cardNames = CardNames;

  TYPE_REPORT_SHOW_OPERATORGROUP_AND_OPERATOR = TYPE_REPORT_SHOW_OPERATORGROUP_AND_OPERATOR;

  constructor(
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private _filterReportService: FilterReportService,
    private _cardsByTextReportService: CardsByTextReportService,
    private _colorModeService: ColorModeService,
    private modalGenerator: ModalGenerator
  ) {}

  ngOnInit() {
    closeTooltip();
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }

  changeStatus() {
    this.modalCtrl.dismiss({
      changeStatus: true,
    });
  }

  async showEditReport() {
    const data = await showModalEditReport({
      modalGenerator: this.modalGenerator,
      isLightColor: this._colorModeService.getIsLightColor(),
      width: this.platform.width(),
      report: this.report,
      company: this.company,
      _filterReportService: this._filterReportService,
      _cardsByTextReportService: this._cardsByTextReportService,
      dateHourInModal: this.dateHourInModal,
      datePickerFilter: this.datePickerFilter,
      typeReports: this.typeReports,
    });

    if (data?.success && data?.edit) {
      this._filterReportService.setCards([]);
      setTimeout(() => {
        this.modalCtrl.dismiss({ success: true, edit: true });
      }, TIME_WAIT_CLOSE_MODAL);
    }

  }

  editReport(params: EditReportParams) {
    this.isModalClose = true;
    this.modalCtrl.dismiss({ edit: params });
    setTimeout(() => {
      this.isModalClose = false;
    }, TIME_WAIT_CLOSE_MODAL);
  }
}
