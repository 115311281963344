import { RegisteredFooterService } from '../share/services';

export const editShowFooter = (
  _footerService: RegisteredFooterService,
  showFooter: boolean
) => {
  _footerService.setShowFooter(showFooter);
};

export const hideFooter = (_footerService: RegisteredFooterService) => {
  editShowFooter(_footerService, false);
};

export const showFooter = (_footerService: RegisteredFooterService) => {
  editShowFooter(_footerService, true);
};
