// External Module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

// Modules
import { FilterClientModule } from '../filter-client/filter-client.module';
// import { FilterDateModule } from '../filter-date/filter-date-module';
import { FilterDateTemplateModule } from '../filter-date-template/filter-date-template.module';
import { HeaderModalModule } from '../header-modal/header-modal.modal';
import { VisibilitySelectModule } from '../visibility-select/visibility-select.module';

// Components
import {
  DoubleDateFilterComponent,
  VisibilityClientSelectComponent,
  VisibilityClientSelectPcComponent,
  VisibilityClientSelectSmComponent,
} from './';

// Services
import { LanguageService } from '../../services/languages.service';

@NgModule({
  declarations: [
    DoubleDateFilterComponent,
    VisibilityClientSelectComponent,
    VisibilityClientSelectPcComponent,
    VisibilityClientSelectSmComponent,
  ],
  imports: [
    CommonModule,
    // FilterDateModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    SwiperModule,
    TranslateModule,

    FilterClientModule,
    FilterDateTemplateModule,
    HeaderModalModule,
    VisibilitySelectModule,
  ],
  providers: [LanguageService],
  exports: [VisibilityClientSelectComponent],
})
export class VisibilityClientSelectModule {}
