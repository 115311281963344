import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

// models
import { Company } from '../models';

// services
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from '../services';

// utils
import {
  presentToast,
  replaceNameCard,
  replaceNameCardPlural,
  successToast,
  warningToast,
} from './';
import { toastController } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsTranslate {
  company: Company;

  constructor(
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private skinServiceManager: SkinMangementService
  ) {
    this.company = this.skinServiceManager.getCompany();
  }

  presentToastTranslate = async (
    message: string,
    error = true,
    time: number = 50,
    duration?: number
  ) => {
    setTimeout(() => {
      if (error) {
        this.translate.get([message]).subscribe((translates) => {
          const text = replaceNameCard(translates[message]);
          presentToast({
            message: text,
            toastController: this.toastCtrl,
            company: this.company,
            duration,
          });
        });
      } else {
        this.translate.get([message]).subscribe((translates) => {
          const text = replaceNameCard(translates[message]);

          successToast(text, this.toastCtrl, duration);
        });
      }
    }, time);
  };

  presentToastWithVariable = async (
    message: string,
    nameVariable: string,
    valueVariable: string,
    error: boolean = true,
    duration?: number
  ) => {
    this.translate.get([message]).subscribe((translates) => {
      let messageChanged = translates[message].replace(
        '{{' + nameVariable + '}}',
        valueVariable
      );

      messageChanged = replaceNameCard(messageChanged);

      error
        ? presentToast({
            message: messageChanged,
            toastController: this.toastCtrl,
            duration,
            company: this.company,
          })
        : successToast(messageChanged, this.toastCtrl, duration);
    });
  };

  presentToastWithoutTranslate = async (message: string, error = true) => {
    const messageWithCardName = replaceNameCard(message);

    if (error)
      presentToast({
        message: messageWithCardName,
        toastController: this.toastCtrl,
        company: this.company,
      });
    else successToast(messageWithCardName, this.toastCtrl);
  };

  showError() {
    presentToast({
      message: this.translate.instant('error'),
      toastController: this.toastCtrl,
      company: this.company,
    });
  }

  async getTranslate(translateText: string) {
    return await this.translate.instant(translateText);
  }

  presentWarningTranslate = async (message: string, duration: number) => {
    this.translate.get([message]).subscribe((translates) => {
      warningToast(translates[message], this.toastCtrl, duration);
    });
  };

  removeWeigthTranslate(translate: string) {
    return translate.replace(/<b>/, '').replace(/<\/b>/g, '');
  }

  closeToast() {
    this.toastCtrl.getTop().then((toastActive) => {
      if (toastActive) {
        this.toastCtrl.dismiss();
      }
    });
  }

  presentToastCardNameTranslate = async (
    message: string,
    error = true,
    time: number = 50,
    duration?: number,
    isCardNamePlural?: boolean
  ) => {
    setTimeout(() => {
      if (error) {
        this.translate.get([message]).subscribe((translates) => {
          const text = isCardNamePlural
            ? replaceNameCardPlural(translates[message])
            : replaceNameCard(message);

          presentToast({
            message: text,
            toastController: this.toastCtrl,
            duration,
            company: this.company,
          });
        });
      } else {
        this.translate.get([message]).subscribe((translates) => {
          const text = replaceNameCard(translates[message]);

          successToast(text, this.toastCtrl, duration);
        });
      }
    }, time);
  };

  presentToastWithVariableAndCardName = async ({
    message,
    nameVariable,
    valueVariable,
    error,
    duration,
    isCardNamePlural,
  }: PresentToastWithVariableAndCardName) => {
    this.translate.get([message]).subscribe((translates) => {
      let messageChanged = translates[message].replace(
        '{{' + nameVariable + '}}',
        valueVariable
      );

      messageChanged = isCardNamePlural
        ? replaceNameCardPlural(messageChanged)
        : replaceNameCard(messageChanged);

      error
        ? presentToast({
            message: messageChanged,
            toastController: this.toastCtrl,
            duration,
            company: this.company,
          })
        : successToast(messageChanged, this.toastCtrl, duration);
    });
  };
}

interface PresentToastWithVariableAndCardName {
  message: string;
  nameVariable: string;
  valueVariable: string;
  error: boolean;
  duration?: number;
  isCardNamePlural?: boolean;
}
