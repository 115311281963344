import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
})
export class LegendComponent implements OnInit {

  @Input() company: Company;
  @Input() isLightColor: boolean = true;
  @Input() disabled: boolean = false;

  @Output() showLegend = new EventEmitter()

  constructor() { }

  ngOnInit() {}

  ngOnDestroy(){
  }

  showLegendModal(){
    this.showLegend.emit()
  }

}
