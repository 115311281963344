import { MAX_LENGTH_ROUTE_INPUTS } from "../../constant";

export const isValidRouteNumberRM = (routeNumber: number) => {
  if (typeof routeNumber !== 'number') {
    return false;
  }

  if((routeNumber || '').toString().length > MAX_LENGTH_ROUTE_INPUTS.routeNumber){
    return false;
  }

  return (
    routeNumber >= 0.0 &&
    Math.floor(routeNumber) === routeNumber &&
    routeNumber !== Infinity
  );
};
