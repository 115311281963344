import { WritableSignal } from '@angular/core';

// models
import { FilterDateServiceI } from 'src/app/models';

// number
import { getTimeByHour } from 'src/app/utils';

interface DiffHourMaxMachineAlert {
  _filterDateService: FilterDateServiceI;
  _filterDateExtraHourService: FilterDateServiceI;
  haveExtraHour: WritableSignal<boolean>;
}

export const diffHourMaxMachineAlert = ({
  _filterDateService,
  _filterDateExtraHourService,
  haveExtraHour,
}: DiffHourMaxMachineAlert): number => {
  const start = getTimeByHour(_filterDateService.getStartHour());
  const end = getTimeByHour(_filterDateService.getEndHour());

  const diffTime = end - start;

  if (!haveExtraHour()) {
    return diffTime;
  }

  const extraStart = getTimeByHour(_filterDateExtraHourService.getStartHour());
  const extraEnd = getTimeByHour(_filterDateExtraHourService.getEndHour());
  const diffExtraTime = extraEnd - extraStart;

  return Math.max(diffTime, diffExtraTime);
};
