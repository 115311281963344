import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

// Services
import { TranslateService } from '@ngx-translate/core';
import { ExcelService } from '../../../../../../share/services/excel/excel.service';
import { SendFilesAenaService, SendFilesFilterService } from '../../services';
import { LoadingService } from '../../../../../../share/services/loading/loading.service';

// Models
import { Company, ColorTheme } from '../../../../../../models';
import { CheckFileAena } from '../../models';

// Utils
import { errorsTypes, isTypeExcelValid, UtilsTranslate } from 'src/app/utils';
import {
  CODE_SAVIA_PATTERN,
  DAILY_TEMPLATE,
  DATE_SALE_PATTERN,
  TRANSACTIONS_TEMPLATE,
} from '../../../share/utils/constants';
import { TIME_ERROR_NOTE1_SEND_FILE } from '../../utils/constant';

@Component({
  selector: 'app-send-files-aena-card',
  templateUrl: './send-files-aena-card.component.html',
  styleUrls: ['./send-files-aena-card.component.scss'],
})
export class SendFilesAenaCardComponent implements OnInit {
  @Input() company: Company;
  @Input() title: string = 'Ficheros transaccionales';
  @Input() isTransactional: boolean = true;

  @Input() checkState: CheckFileAena;
  @Input() loadingState: boolean = true;
  @Input() isFile2: boolean = false;

  @Input() colorTheme: ColorTheme = 'light';

  @Output() reloadCheck = new EventEmitter();

  file: File = null;
  loadingSendFile: boolean = false;

  clearSubscription$: Subscription;

  @ViewChild('FileAena') inputFile: ElementRef;

  constructor(
    private utils: UtilsTranslate,
    private _excelService: ExcelService,
    private _translateService: TranslateService,
    private _sendFileAenaService: SendFilesAenaService,
    private _filterService: SendFilesFilterService,
    private _loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.file = this._filterService.getFile(this.isTransactional);

    this.clearSubscription$ = this._filterService.clearEvent$.subscribe(() => {
      this.file = this._filterService.getFile(this.isTransactional);
    });
  }

  ngOnDestroy(): void {
    if (this.clearSubscription$) this.clearSubscription$.unsubscribe();
  }

  openFileSelected() {
    this.inputFile.nativeElement.click();
  }

  async onFileSelected(event) {
    try {
      this.file = null;

      if (event?.target?.files.length === 0) {
        return;
      }

      const file: File = event?.target?.files[0];
      const mimeType = file.type;

      if (!isTypeExcelValid(mimeType)) {
        this.utils.presentToastTranslate('send_files_aena.card.invalid_format');
        return;
      }

      this.file = file;
      this._filterService.setFile(this.file, this.isTransactional);
    } catch (error) {
      this.utils.showError();
    }
  }

  sendFile() {
    if (
      this.loadingSendFile ||
      this.loadingState ||
      this.checkState?.state === 'processing'
    ) {
      return;
    }

    if (!this.file) {
      return;
    }

    this.loadingSendFile = true;
    this._loadingService.present();
    this._sendFileAenaService
      .sendFile(this.file, this.isTransactional)
      .subscribe({
        next: (res) => {
          // console.log('res', res);
          this.loadingSendFile = false;
          this.file = null;
          this._filterService.clearFile(this.isTransactional);
          this.utils.presentToastTranslate(
            this.isTransactional
              ? 'send_files_aena.card.success_transactional'
              : 'send_files_aena.card.success_daily_closing',
            false,
            0
          );
          this.reloadCheck.emit();
          this._loadingService.close();
        },
        error: (error) => {
          // console.log('error', error);

          if (
            error.includes(
              errorsTypes.not_exist_card_send_file_aena1 &&
                errorsTypes.not_exist_card_send_file_aena2
            )
          ) {
            let notes1 = error.replace(
              errorsTypes.not_exist_card_send_file_aena1,
              ''
            );
            notes1 = notes1.replace(
              errorsTypes.not_exist_card_send_file_aena2,
              ''
            );
            notes1 = notes1.trim();
            this.utils.presentToastWithVariable(
              'send_files_aena.card.error_not_exist_note',
              'notes1',
              notes1,
              true
            );
          } else if (
            error.includes(errorsTypes.never_generated_send_file_aena1) && error.includes(errorsTypes.never_generated_send_file_aena2)
          ) {

            const notes1 = error
              .replace(errorsTypes.never_generated_send_file_aena1, '')
              .replace(errorsTypes.never_generated_send_file_aena2, '');

            this.utils.presentToastWithVariable(
              'send_files_aena.card.error_note1',
              'note1',
              notes1,
              true,
              TIME_ERROR_NOTE1_SEND_FILE
            );
          } else if (errorsTypes.invalid_format_send_file_aena === error) {
            this.utils.presentToastTranslate(
              'send_files_aena.card.invalid_format_endpoint',
              true,
              0,
            );
          } else {
            this.utils.showError();
          }

          this.loadingSendFile = false;
          this._loadingService.close();
        }
    });
  }

  async downloadExcel() {
    if (this.isTransactional) {
      await this.downloadTransactionalsExcel();
    } else {
      await this.downloadDailyExcel();
    }
  }

  async downloadTransactionalsExcel() {
    let sendFileExcel = [];

    const translates = await {
      code: await this._translateService.instant(
        'send_files_aena.card.savia_code'
      ),
      ticket: await this._translateService.instant(
        'send_files_aena.card.ticket'
      ),
      ticketDate: await this._translateService.instant(
        'send_files_aena.card.ticket_date'
      ),
    };

    let sendFileExcelPos = {};
    sendFileExcelPos[translates.code] = CODE_SAVIA_PATTERN;
    if (!this.isFile2) {
      sendFileExcelPos[translates.ticket] = 0;
    } else {
      sendFileExcelPos[translates.ticketDate] = DATE_SALE_PATTERN;
    }
    // sendFileExcelPos[translates.ticket] = 0;
    sendFileExcel.push(sendFileExcelPos);

    this._excelService.saveAsExcelFileHeader(
      sendFileExcel,
      TRANSACTIONS_TEMPLATE,
      true,
      false
    );
  }

  async downloadDailyExcel() {
    let sendFileExcel = [];

    const translates = await {
      code: await this._translateService.instant(
        'send_files_aena.card.savia_code'
      ),
      saleDate: await this._translateService.instant(
        'send_files_aena.card.sale_date'
      ),
    };

    let sendFileExcelPos = {};
    sendFileExcelPos[translates.code] = CODE_SAVIA_PATTERN;
    sendFileExcelPos[translates.saleDate] = DATE_SALE_PATTERN;
    sendFileExcel.push(sendFileExcelPos);

    this._excelService.saveAsExcelFileHeader(
      sendFileExcel,
      DAILY_TEMPLATE,
      true,
      false
    );
  }
}
