import { BANK_CARD_CHARGES, CASH_LOADS } from '.';

export const isLoadFilterTransactionQuery = (
  typePayments: { id: number; name: string }[]
): boolean => {
  const isLoadFilter = typePayments.some(
    (typePayment) =>
      typePayment.name === CASH_LOADS || typePayment.name == BANK_CARD_CHARGES
  );
  return isLoadFilter;
};
