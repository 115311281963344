import { RoleMultiSelect } from '../models';

export const getIconRoutes = (roles: RoleMultiSelect[]) => {
  return roles.map((role) => ({
    ...role,
    iconImage: imageRoute?.[role.roleName] || imageRoute['not_found'],
  }));
};


const imageRoute: Record<string, string> = {
  client: '../../../../assets/images-routes/client.png',
  operations: '../../../../assets/images-routes/role.png',
  admin: '../../../../assets/images-routes/admin.png',
  reponedor: '../../../../assets/images-routes/reponedor.png',
  Supervisor: '../../../../assets/images-routes/supervisor2.png',
  not_found: '../../../../assets/images-routes/operator.png'
}
