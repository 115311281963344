import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

// utils
import { pcWidth, pcWidthMix } from '../../../../utils';

@Component({
  selector: 'app-info-selected-header',
  templateUrl: './info-selected-header.component.html',
  styleUrls: ['./info-selected-header.component.scss'],
})
export class InfoSelectedHeaderComponent implements OnInit {

  @Input() searchTextTranslate = '';
  @Input() searchValue = '';
  @Input() useMargin: boolean = true;

  pcWidthMix = pcWidthMix;
  pcWidth = pcWidth;

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}

}
