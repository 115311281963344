import { getUuid } from 'src/app/utils';
import {
  FamiliesDashboardBasic,
  PaymentDashboardType,
  FilterDashboards,
  ArticleFilterDashboard,
  FamiliesDashboardWithAll,
} from '../../models';

export const EXTRA_INFO_CLIENTS: string[] = ['AEROPORT EL PRAT'];
export const TILL_HAVE_ALLIANCE: number = 2060;
export const TILL_HAVE_ENRIQUE_TOMAS: number = 2061;
export const ALLIANCE_DASHBOARD_TEXT: string = 'Arbitrade';
export const ENRIQUE_TOMAS_TEXT: string = 'Enrique Tomás';

export const ARTICLE_DEFAULT_DASHBOARD: ArticleFilterDashboard = {
  favorites: [],
  areaA: {
    article: null,
    date: {
      dateSelected: null,
      typeDate: 'yearly',
    },
    selectDate: {
      start: null,
      id: getUuid(),
    },
    isBilling: false,
    family: null
  },
  areaB: {
    article: null,
    date: {
      dateSelected: null,
      typeDate: 'yearly',
    },
    selectDate: {
      start: null,
      id: getUuid(),
    },
    isBilling: false,
    family: null
  },
};

export const FILTER_DASHBOARDS_START: FilterDashboards = {
  totalGross: {
    filterPayment: null,
    showFamilies: false,
    date: {
      dateSelected: null,
      typeDate: 'yearly',
    },
    selectDate: {
      start: null,
      id: getUuid(),
    },
  },

  averageGross: {
    filterPayment: null,
    isBilling: false
  },

  articles: ARTICLE_DEFAULT_DASHBOARD,
  sameScale: true
};

// localstorage name
export const NAME_STORAGE_CLIENT_DASHBOARD: string = 'clientDashboard';
export const NAME_STORAGE_FILTER_DASHBOARDS: string = 'filtersDashboards';

// width
export const DIMENSION_SHOW_ONE_CHART: number = 1200;
export const DIMENSION_SHOW_ONE_CHART_MENU: number = 1560;
export const DIMENSION_PC_WIDTH_FIX_MENU = 850;
export const DIMENSION_SHOW_TITLE_CHART: number = 1500;
export const DIMENSION_TITLE_FIX_MENU_SIZE_DASHBOARD: number = 1800;
export const DIMENSION_TITLE_NOT_FIX_MENU_SIZE_DASHBOARD: number = 1550;

export const DIMENSION_LAST_DATE_DATA_COLLECTION_TEXT: number = 1300;
export const DIMENSION_LAST_DATE_DATA_COLLECTION_BOTTOM: number = 1050;

// type sales valids
export const TYPE_SALES_SHOWERS_DW: Record<PaymentDashboardType, string> = {
  cash: 'efectivo',
  creditCard: 'tarjeta bancaria',
  cardNum: 'tarjeta cautiva',
  subsidy: 'tarjeta cautiva bonificada',
  freeSale: 'venta gratuita',
  all: 'todos',
};

// families valids
export const FAMILIES_VALIDS_DW: Record<FamiliesDashboardBasic, string[]> = {
  hotdrink: ['bebidas calientes', 'b. calientes'],
  colddrink: ['bebidas frias', 'bebidas frías'],
  snack: ['snack'],
};

export const POSITIONS_FAMILIES_WITH_ALL: Record<number, FamiliesDashboardWithAll> = {
  0: 'all',
  1: 'colddrink',
  2: 'hotdrink',
  3: 'snack',
  4: 'other'
}


// ---------------------------- Dates ----------------------------
export const DIFF_LAST_DAY_WITH_INFO = -1;

export const SHOW_LAST_WEEK: number = 5;
export const DIFF_DAYS_FILTER_DATE: number = 30;

// Validates
// months
export const MAX_FILTER_MONTH_DASHBOARD = 12;
// days
export const MAX_FILTER_WEEK_DASHBOARD = 35;

export const MONTHS_TRANSLATES_DEFAULT: string[] = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
