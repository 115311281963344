import { getDaysInMonth } from '../date-helpers';


export const getLabelsDaysByMonth = (dateStart: Date) => {

  if(typeof dateStart === 'string'){
    dateStart = new Date(dateStart);
  }

  const daysInMonth = getDaysInMonth(
    dateStart.getMonth() + 1,
    dateStart.getFullYear()
  );

  return new Array(daysInMonth).fill(null).map((_, i) => (i + 1).toString());
};
