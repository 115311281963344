import { Directive, ElementRef, Input } from '@angular/core';

// services
import { IonIconsRemoveTitleService } from './ion-icons-remove-title.service';

@Directive({
  selector: '[ioniconRemoveTitle]',
})
export class IoniconsRemoveTitleDirective {
  @Input('iconTitle') iconTitle: string;
  // @Input() idIcon: string = '';

  constructor(
    private el: ElementRef,
    private _ionIconsRemoveTitleService: IonIconsRemoveTitleService
  ) {}

  ngOnInit(): void {


    // if (this._ionIconsRemoveTitleService.haveIdIcon(this.idIcon)) {
    //   return;
    // }

    // this._ionIconsRemoveTitleService.addIdIcon(this.idIcon);


    setTimeout(() => {
      this.removeTitle();
    }, 500);
  }

  removeTitle() {
    //  this.intervalSearchEelement = setInterval(() => {
    if (
      this.el.nativeElement &&
      this.el.nativeElement.shadowRoot &&
      this.el.nativeElement.shadowRoot.querySelector('.icon-inner svg title')
    ) {
      this.el.nativeElement.shadowRoot.querySelector(
        '.icon-inner svg title'
      ).innerHTML = this.iconTitle || '';
    }
    // }, 500)
  }

  ngOnDestroy(): void {
  }
}
