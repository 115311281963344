// params
import { lastValueFrom } from 'rxjs';

// models
import { GetCardIdsByCardnumReportParams } from '../../models';

// services
import { CardsByTextReportService, ReportsService } from '../../services';

// utils
import { UtilsTranslate } from 'src/app/utils';

interface GetCardsIdsByTextReport {
  _reportService: ReportsService;
  _cardsByTextReportService: CardsByTextReportService;
  utils: UtilsTranslate;
  isEdit: boolean;
}

export const getCardsIdsByTextReport = async ({
  _reportService,
  _cardsByTextReportService,
  utils,
  isEdit,
}: GetCardsIdsByTextReport): Promise<
  [cardIds: number[], haveError: boolean]
> => {
  const cards = _cardsByTextReportService.cardsByText();
  const reportVisibility = _cardsByTextReportService.visibilityValidateCardText();

  const params: GetCardIdsByCardnumReportParams = {
    cards,
    reportOperadorId: reportVisibility?.operatorId,
    reportOperatorGroupId: reportVisibility?.operatorGroupId,
  };

  try {
    const cardIds = await lastValueFrom(
      _reportService.getCardIdsByCardnum(params)
    );
    return [cardIds, false];
  } catch (error) {
    utils.presentToastTranslate(
      isEdit
        ? 'reports.create_edit.error_cards_create'
        : 'reports.create_edit.error_cards_edit'
    );
    return [[], true];
  }
};
