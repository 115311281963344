import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';

// services
import { TranslateService } from '@ngx-translate/core';
import { ColorModeService } from '../../../../../../../../services/color-mode.service';

// models
import { Card, CardNames, Client, Company } from 'src/app/models';

// utils
import { applyColorButtonAlerts, pcWidthMix, replaceNameCard } from 'src/app/utils';

@Component({
  selector: 'app-unlinked-card-by-client-selected',
  templateUrl: './unlinked-card-by-client-selected.component.html',
  styleUrls: ['./unlinked-card-by-client-selected.component.scss'],
})
export class UnlinkedCardByClientSelectedComponent implements OnInit {

  @Input() company: Company;
  @Input() cards: Card[] = []
  @Input() client: Client = null;
  @Input() loadingCardsByClient: boolean = false;
  @Input() total: number = 0;

  // paginator
  @Input() collection: { count: number; data: Card[] } = {
    count: 0,
    data: [],
  };
  @Input() config: {
    id: string;
    itemsPerPage: number;
    currentPage: number;
    totalItems: number;
  };
  @Input() page: number = 1;
  @Input() idPaginate: string = 'AssignArticlesFamilyPaginate';

  @Output() changePage = new EventEmitter<number>()
  @Output() removeCardPos = new EventEmitter<number>()

  pairElements: number[] = [];

  pcWidthMix = pcWidthMix;

  cardNames = CardNames;

  constructor(
    public readonly platform: Platform,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private _colorModeService: ColorModeService
  ) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {

    this.pairElements = [];

    for(let i=0; i < this.cards.length/2; i++){
      this.pairElements.push(1)
    }

  }

  ngOnDestroy(): void {
    this.pairElements = [];
  }

  async removeCard(pos: number){

    const textTranslate = this.company.nameCard === this.cardNames.arbitradePay
        ? 'register_cards.unlinked_card.alert_question2' : 'register_cards.unlinked_card.alert_question'

      const alertQuestion = this.translate.instant(textTranslate, {
        cardNum: this.cards?.[pos]?.cardNum, client: this.client?.description
      })

      const cssClass = this._colorModeService.getIsLightColor() ? '' : 'shadow-alert';

      const alert = await this.alertCtrl.create({
        header: replaceNameCard(alertQuestion),
        mode: 'ios',
        cssClass,
        buttons: [
          {
            text: this.translate.instant('no'),
            cssClass: 'alert-button-cancel',
            handler: () => {},
          },
          {
            text: this.translate.instant('yes'),
            cssClass: 'alert-button-confirm',
            handler: () => {
              this.removeCardPos.emit(pos)
            },
          },
        ],
      });

      applyColorButtonAlerts(this.company, '#f2f2f2');

      await alert.present();
  }

  pageChanged(page: number){
    this.changePage.emit(page)
  }

}
