import { Component, Input, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// services
import { SkinMangementService, ColorModeService } from '../../../services';

// models
import { Company } from 'src/app/models';

// Utils
import { pcWidth } from 'src/app/utils';


@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {

  @Input() id: string = 'basicPaginator'
  @Input() collection: { count: number, data: any[] } = { count: 0, data: [] };
  @Input() config: { id: string, itemsPerPage: number, currentPage: number, totalItems: number }


  @Output() pageChanged = new EventEmitter<number>();

  company: Company;
  pcWidth = pcWidth;

  isLightColor: boolean = true;

  changeColor$: Subscription;

  constructor(
    public readonly platform: Platform,
    private elem: ElementRef,
    private skinManagerService: SkinMangementService,
    private _colorModeService: ColorModeService
  ) { }

  ngOnInit() {
    this.company = this.skinManagerService.getCompany()

    this.setColorVariables()

    this.isLightColor = this._colorModeService.getIsLightColor()

    this.changeColor$ = this._colorModeService.changeColor$.subscribe(res =>
      this.isLightColor = this._colorModeService.getIsLightColor()
    )

  }

  ngOnDestroy(): void {
    if(this.changeColor$) this.changeColor$.unsubscribe()
  }

  changePage(page: number){
    this.pageChanged.emit(page)
  }

  setColorVariables() {

    this.elem.nativeElement.style.setProperty(
      '--color-current-bg',
      this.company.paginationCurrentBg
    );

    this.elem.nativeElement.style.setProperty(
      '--color-dark',
      this.company.paginationColorDark
    );

    this.elem.nativeElement.style.setProperty(
      '--color-light',
      this.company.paginationColorLight
    );
  }

}
