import { TypeMachineAlertOptions } from '../models';

// utils
import {
  ACTIVITY_STATUS_ALERT_NAME_DB,
  CONNECTIVITY_STATUS_ALERT_NAME_DB,
  PERFOMANCE_TYPE_ALERT,
} from './constant';

export const getTypeMachineAlert = (
  typeMachine: string
): TypeMachineAlertOptions => {
  if ((typeMachine || '').toLowerCase() === CONNECTIVITY_STATUS_ALERT_NAME_DB) {
    return TypeMachineAlertOptions.Connectivity;
  }

  if ((typeMachine || '').toLowerCase() === ACTIVITY_STATUS_ALERT_NAME_DB) {
    return TypeMachineAlertOptions.Activity;
  }

  if (
    (typeMachine || '').toLowerCase() ===
    PERFOMANCE_TYPE_ALERT.haveBankSales.toLowerCase()
  ) {
    return TypeMachineAlertOptions.Activity;
  }

  return null;
};
