import { CardNames, Company } from "src/app/models";

export const getCardRepeatByTextTranslateReport = (
  company: Company,
  cardLength: number
) => {

  if (company.nameCard === CardNames.arbitradePay) {
    return getTextRepeatAlliancePay(cardLength);
  } else {
    return getTextRepeatCloseLoopCard(cardLength);
  }
};

const getTextRepeatAlliancePay = (cardLength: number) => {
  return cardLength > 1
    ? 'reports.cards.repeat_cards_alliance'
    : 'reports.cards.repeat_card_alliance';
};

 const getTextRepeatCloseLoopCard = (cardLength: number) => {
  return cardLength > 1
    ? 'reports.cards.repeat_close_loop_cards'
    : 'reports.cards.repeat_close_loop_card';
};

