import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Service
import { RegisteredFooterService } from '../../services';
import { FabBrService } from '../fab-br/services/fab-br.service';

// utils
import { pcWidth } from '../../../utils/constants';

@Component({
  selector: 'app-registered',
  templateUrl: './registered.component.html',
  styleUrls: ['./registered.component.scss'],
})
export class RegisteredComponent implements OnInit {

  showFooter: boolean = true;

  filterChange$: Subscription;

  pcWidth = pcWidth;

  constructor(
    public readonly platform: Platform,
    private _fabBrService: FabBrService,
    private _registeredFooterService: RegisteredFooterService,
  ) {}

  ngOnInit() {

    this.filterChange$ = this._registeredFooterService.filterChange.subscribe((res) => {
      if (res?.reset == true) {
        this.showFooter = true;
      } else {
        this.showFooter = false;
      }
    });

  }

  ngOnDestroy(): void {
    if(this.filterChange$) this.filterChange$.unsubscribe()
  }

  clicked() {
    this._fabBrService.closeFabEmit();
  }

}
