import { ColorType } from 'src/app/models';

// Bar Color
export const LINE_DEFAULT_COLOR: string[] = [
  '#84c5f0',
  '#fd9fb2',
  '#fde097',
  '#4F9E4F',
  '#91d7d6',
  '#bfd4de',
  '#e8e8e7',
  '#f88e8f',
  '#8ed7d4',
  '#fcd09a',
  '#bdc3cd',
  '#92969d',
  '#64a5aa',
  '#64a5aa',
];

export const LINE_DEFAULT_COLOR_DARK: string[] = [
  '#84c5f0',
  '#fd9fb2',
  '#fde097',
  '#eff0f1',
  '#91d7d6',
  '#bfd4de',
  '#e8e8e7',
  '#f88e8f',
  '#8ed7d4',
  '#fcd09a',
  '#bdc3cd',
  '#92969d',
  '#64a5aa',
  '#64a5aa',
];

// Line Color
export const POINT_DEFAULT_COLOR: string[] = [
  '#56819D',
  '#B77381',
  '#A69363',
  '#3D7B3D',
  '#73ABAA',
  '#bfd4de',
  '#e8e8e7',
  '#f88e8f',
  '#8ed7d4',
  '#fcd09a',
  '#bdc3cd',
  '#92969d',
  '#4F8387',
  '#4F8387',
];

export const POINT_DEFAULT_COLOR_DARK: string[] = [
  '#56819D',
  '#B77381',
  '#A69363',
  '#848485',
  '#73ABAA',
  '#bfd4de',
  '#e8e8e7',
  '#f88e8f',
  '#8ed7d4',
  '#fcd09a',
  '#bdc3cd',
  '#92969d',
  '#4F8387',
  '#4F8387',
];

// Border Color
export const BORDER_DEFAULT_COLOR: string[] = [
  '#56819D',
  '#B77381',
  '#A69363',
  '#3D7B3D',
  '#73ABAA',
  '#707C82',
  '#C8C8C7',
  '#C57171',
  '#71ABA8',
  '#D1AC7F',
  '#969BA3',
  '#74777D',
  '#64a5aa',
  '#64a5aa',
];

export const BORDER_DEFAULT_COLOR_DARK: string[] = [
  '#56819D',
  '#B77381',
  '#A69363',
  '#848485',
  '#73ABAA',
  '#707C82',
  '#C8C8C7',
  '#C57171',
  '#71ABA8',
  '#D1AC7F',
  '#969BA3',
  '#74777D',
  '#64a5aa',
  '#64a5aa',
];

// Color Axis
export const COLOR_AXIS_LIGHT: ColorType = '#e5e5e5';
export const COLOR_AXIS_DARK = 'rgba(220,220,220,0.1)';

// Color tooltip
export const COLOR_TOOLTIP_LIGHT_DASHBOARD: ColorType = '#f4f5f8';
export const COLOR_TOOLTIP_DARK_DASHBOARD: ColorType = '#222428';
