import { WritableSignal } from '@angular/core';

//models
import {
  CreateRouteParams,
  EditRouteParams,
} from 'src/app/share/services/routes/models';
import {
  FilterClientOptionsRM,
  RouteEditable,
  RouteInfoRM,
  TillSelectedRM,
} from '../../../models';

// utils
import { getCreateRouteParams } from '../create';

interface GetEditRouteParams {
  routeSelected: WritableSignal<RouteInfoRM>;
  routeEditable: WritableSignal<RouteEditable>;
  haveOperatorVisibility: boolean;
  filterClientOptions: WritableSignal<FilterClientOptionsRM>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;
}

export const getEditRouteParams = ({
  routeSelected,
  routeEditable,
  haveOperatorVisibility,
  filterClientOptions,
  tillsSelected,
  tillOneMachineSelected,
}: GetEditRouteParams): EditRouteParams => {

  let paramsCreate: CreateRouteParams = getCreateRouteParams({
    routeEditableCreate: routeEditable,
    haveOperatorVisibility,
    filterClientOptions,
    tillsSelected,
    tillOneMachineSelected,
  });

  let params: EditRouteParams = {
    routeNumber: paramsCreate.routeNumber,
    routeName: paramsCreate.routeName,
    machinesID: paramsCreate.machinesID,
    RouteDrivers: paramsCreate.RouteDrivers,
    Enable: Number(routeEditable().enabled),
    routeID: routeSelected().routeId
  }

  if(paramsCreate?.OperatorID === 0 || paramsCreate?.OperatorID){
    params.OperatorID = paramsCreate.OperatorID;
  }

  if(paramsCreate?.SupervisorID === 0 || paramsCreate?.SupervisorID){
    params.SupervisorID = paramsCreate.SupervisorID;
  }

  return params;
};
