import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';

// Service
import { NotificationShareService } from "../../notification-share/services/notification-share.service";



@Injectable({providedIn: 'root'})
export class NotificationManagerAPIResolverService {

    constructor(private _notificationShareService: NotificationShareService,
    ) {}

    resolve(): Observable<any>{
        return this._notificationShareService
                .getAPI('/user/notification-manager', 'Gestión de aplicación')
    }

}
