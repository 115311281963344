import { MachineAlertShowInfo } from '../../models';

export const getEmailsNotRepeatMachineAlert = (
  alerts: MachineAlertShowInfo[]
): string[] => {
  let emails: string[] = [];

  for (let alert of alerts) {
    for (let email of alert.emails) {
      emails.push(email);
    }
  }

  return Array.from(new Set(emails)).sort((a, b) => a.localeCompare(b));
};
