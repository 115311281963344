import {
  Component,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { Company } from 'src/app/models';
import { TypeFilterTripleInput, FilterTemplateObjectTripleInputOneOption } from './models';

// utils
import { UtilsTranslate } from 'src/app/utils';
import { getElementFilterTemplateTripleInputs } from './utils';

@Component({
  selector: 'app-filter-template-object-triple-inputs-modal',
  templateUrl: './filter-template-object-triple-inputs-modal.component.html',
  styleUrls: ['./filter-template-object-triple-inputs-modal.component.scss'],
})
export class FilterTemplateObjectTripleInputsModalComponent implements OnInit {
  @Input() company: Company;

  @Input() title: string = 'filter';

  @Input() textInput1: string = 'name';
  @Input() textInput2: string = 'code';
  @Input() textInput3: string = 'other';

  @Input() textSearch1: string = 'name';
  @Input() textSearch2: string = 'code';
  @Input() textSearch3: string = 'other';

  @Input() search1: WritableSignal<string> = signal('');
  @Input() search2: WritableSignal<string> = signal('');
  @Input() search3: WritableSignal<string> = signal('');

  @Input() elemsInput1: any[] = [];
  @Input() elemsInput2: any[] = [];
  @Input() elemsInput3: any[] = [];

  @Input() elementsInput1: FilterTemplateObjectTripleInputOneOption;
  @Input() elementsInput2: FilterTemplateObjectTripleInputOneOption;
  @Input() elementsInput3: FilterTemplateObjectTripleInputOneOption;

  @Input() numElements = 50;

  @Input() haveClear: boolean = true;

  @Input() applyTranslate = 'apply';

  @Input() successText = 'successFilter';

  elementSelected1: WritableSignal<number | string> = signal(null);
  elementSelected2: WritableSignal<number | string> = signal(null);
  elementSelected3: WritableSignal<number | string> = signal(null);

  typeFilter: TypeFilterTripleInput = 'input1';

  constructor(
    private modalCtrl: ModalController,
    private utils: UtilsTranslate
  ) {}

  ngOnInit() {
    this.startElementsSelected();
  }

  startElementsSelected() {
    if (this.elementsInput1?.elementSelected) {
      const {elements, nameId, elementSelected} = this.elementsInput1;
      if(elements.some(elem => elem?.[nameId] === elementSelected?.[nameId])){
        this.elementSelected1.set(elementSelected?.[nameId] || null)
      }
    }

    if (this.elementsInput2?.elementSelected) {
      const {elements, nameId, elementSelected} = this.elementsInput2;
      if(elements.some(elem => elem?.[nameId] === elementSelected?.[nameId])){
        this.elementSelected2.set(elementSelected?.[nameId] || null)
      }
    }

    if (this.elementsInput3?.elementSelected) {
      const {elements, nameId, elementSelected} = this.elementsInput3;
      if(elements.some(elem => elem?.[nameId] === elementSelected?.[nameId])){
        this.elementSelected3.set(elementSelected?.[nameId] || null)
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  deleteFilterElement() {
    if (this.modalCtrl) {
      this.modalCtrl.dismiss({
        delete: true,
      });
    }
    this.utils.presentToastTranslate(this.successText, false, 0);
  }

  disabledFilter() {

    if (
      this.typeFilter === 'input1' &&
      this.elementSelected1() !== null &&
      this.elementSelected1() !== undefined
    ) {
      return false;
    }

    if (
      this.typeFilter === 'input2' &&
      this.elementSelected2() !== null &&
      this.elementSelected2() !== undefined
    ) {
      return false;
    }

    if (
      this.typeFilter === 'input3' &&
      this.elementSelected3() !== null &&
      this.elementSelected3() !== undefined
    ) {
      return false;
    }

    return true;
  }


   filterElement() {

    const {elementFilter, pos} = getElementFilterTemplateTripleInputs(
      {
        elementSelected1: this.elementSelected1,
        elementSelected2: this.elementSelected2,
        elementSelected3: this.elementSelected3,

        elementsInput1: this.elementsInput1,
        elementsInput2: this.elementsInput2,
        elementsInput3: this.elementsInput3,

        typeFilter: this.typeFilter
      }
    );


    if (elementFilter) {
      if (this.modalCtrl) {
        this.modalCtrl.dismiss({
          element: elementFilter,
          pos
        });
      }
      return;
    }


    this.utils.presentToastTranslate('error', true, 0)

  }

}


