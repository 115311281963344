// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark-color {
  color: var(--ion-color-dark) !important;
}

.placeholder-color {
  color: rgba(0, 0, 0, 0.6);
}

.placeholder-color-dark {
  color: rgba(255, 250, 250, 0.8);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/aena/share/components/ui/area-table-aena-check/area-table-aena-check.component.scss"],"names":[],"mappings":"AAAA;EAEE,uCAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,+BAAA;AAAF","sourcesContent":[".dark-color{\r\n  // color: #222428 !important;\r\n  color: var(--ion-color-dark) !important;\r\n}\r\n\r\n.placeholder-color{\r\n  color: rgba(black, 0.6);\r\n}\r\n\r\n.placeholder-color-dark{\r\n  color: rgba(#FFFAFA, 0.8)\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
