import { WritableSignal } from '@angular/core';

// models
import { Client, Operator, RouteDriver, Supervisor } from 'src/app/models';
import { RouteEditable, RouteInfoRM, TillSelectedRM } from '../../models';

// services
import { RouteManagerService } from '../../services';

// utils
import { UtilsTranslate } from 'src/app/utils';
import { getRoutePartialSelectedRM } from './get-route-partial-selected-rm';
import { validateChangesRouteSelectedRM } from './validate-changes-route-selected-rm';

interface ApplyInfoRouteSelectedRM {
  haveOperatorVisibility: boolean;

  operators: Operator[];
  operatorSelected: WritableSignal<Operator>;

  clients: WritableSignal<Client[]>;
  clientsByOperator: WritableSignal<Client[]>;
  clientAssigmentSelected:  WritableSignal<Client>;

  supervisors: WritableSignal<Supervisor[]>;
  supervisorsByOperator: WritableSignal<Supervisor[]>;

  routeDrivers: WritableSignal<RouteDriver[]>;
  loadingRouteDrivers: WritableSignal<boolean>;

  tills: WritableSignal<TillSelectedRM[]>;
  tillsByOperator: WritableSignal<TillSelectedRM[]>;
  tillsSelected: WritableSignal<TillSelectedRM[]>;
  tillOneMachineSelected: WritableSignal<TillSelectedRM>;

  routeSelected: WritableSignal<RouteInfoRM>;
  routeEditable: WritableSignal<RouteEditable>;

  _routeManagerService: RouteManagerService,

  utils: UtilsTranslate
}

export const applyInfoRouteSelectedRM = ({
  haveOperatorVisibility,
  operators,
  operatorSelected,
  clients,
  clientsByOperator,
  clientAssigmentSelected,
  supervisors,
  supervisorsByOperator,
  routeSelected,
  routeDrivers,
  loadingRouteDrivers,
  tills,
  tillsByOperator,
  tillsSelected,
  tillOneMachineSelected,
  routeEditable,
  _routeManagerService,
  utils
}: ApplyInfoRouteSelectedRM) => {
  let routeParticalSelected: RouteEditable = getRoutePartialSelectedRM(
    routeSelected
  );

  validateChangesRouteSelectedRM({
    haveOperatorVisibility,
    operators,
    operatorSelected,
    clients,
    clientsByOperator,
    clientAssigmentSelected,
    supervisors,
    supervisorsByOperator,
    routeDrivers,
    loadingRouteDrivers,
    tills,
    tillsByOperator,
    tillsSelected,
    tillOneMachineSelected,
    routeParticalSelected,
    _routeManagerService,
    utils
  });

  routeEditable.set(routeParticalSelected)
};
