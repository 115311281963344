// services
import { StorageService } from 'src/app/share/services';

// models
import { FilterDashboards } from '../../models';

// utils
import { copyNewObjet } from 'src/app/utils';

// constant
import {
  ARTICLE_DEFAULT_DASHBOARD,
  FILTER_DASHBOARDS_START,
  NAME_STORAGE_FILTER_DASHBOARDS,
} from '../constants';

export const getFilterDashboardStorage = async (
  _storageService: StorageService
): Promise<FilterDashboards> => {
  try {
    const filters = await _storageService.get(NAME_STORAGE_FILTER_DASHBOARDS);

    if (filters) {
      return adaptPreviousToCurrentVersion(filters);
    }

    return copyNewObjet<FilterDashboards>(FILTER_DASHBOARDS_START);
  } catch (error) {
    await _storageService.set(NAME_STORAGE_FILTER_DASHBOARDS, null);
    return copyNewObjet<FilterDashboards>(FILTER_DASHBOARDS_START);
  }
};

export const setFilterDashboardStorage = async (
  _storageService: StorageService,
  filters: FilterDashboards
): Promise<void> => {
  if (!filters) {
    await _storageService.set(NAME_STORAGE_FILTER_DASHBOARDS, null);
    return;
  }

  await _storageService.set(
    NAME_STORAGE_FILTER_DASHBOARDS,
    JSON.stringify(filters)
  );
};

export const clearFilterDashboardStorage = async (
  _storageService: StorageService
): Promise<void> => {
  setFilterDashboardStorage(_storageService, null);
};

/**
 * Changes storage (FilterDashboards)
 * OLD VERSION had totalArticles
 *
    totalArticles: {
      article: {id: number, description: string},
      favorites: number[],
      date: TypeDateDashboard,
      selectDate: SelectDate
    }

    averageGross: {
      filterPayment: number,
    }
  },
 *  CURRENT VERSION have articles
    articles: ArticleFilterDashboard

    averageGross: {
      filterPayment: number,
      isBilling: boolean,
    }
 */
const adaptPreviousToCurrentVersion = (filters: any) => {
  if (filters?.totalArticle) {
    delete filters.totalArticles;
  }

  const filtersPlus: FilterDashboards = JSON.parse(filters) as FilterDashboards;

  if (!filtersPlus?.articles) {
    filtersPlus.articles = ARTICLE_DEFAULT_DASHBOARD;
  }

  if (filtersPlus?.sameScale === null || filtersPlus?.sameScale === undefined) {
    filtersPlus.sameScale = true;
  }

  if (
    filtersPlus?.averageGross?.isBilling === undefined ||
    filtersPlus?.averageGross?.isBilling === undefined
  ) {
    filtersPlus.averageGross.isBilling = false;
  }

  return filtersPlus;
};
