import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ToastController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Service
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../../app/share/services';
import { AuthService, SkinMangementService } from '../../../../services/';

// Models
import { Credential, ForcePassword, Company } from '../../../../models';
import { UpdatedFP } from './models/updated-FP.model';

// Utils
import {
  errorsTypes,
  presentToast,
  setCrypto,
  successToast,
  MustMatch,
  type,
  PATTERN_PASSWORD_VALIDATE,
} from 'src/app/utils';

@Component({
  selector: 'app-force-password-renewal',
  templateUrl: './force-password-renewal.component.html',
  styleUrls: ['./force-password-renewal.component.scss'],
})
export class ForcePasswordRenewalComponent implements OnInit {
  @Input() username: string = '';

  loginUser: Credential = {
    user: '',
    password: '',
  };

  company: Company;
  type = type;

  // email: string = "";

  showPassword = false;
  showError = false;

  public forcePassword: FormGroup;

  //toastCtrl: ToastController;
  forceUserTranslate: string = '';
  emailNotExistTranslate: string = '';
  forceOldPasswordTranslate: string = '';
  forceNewPasswordTranslate: string = '';
  ForceDiferentPasswordTranslate = '';
  successPasswordTranslate: string = '';
  activedForcePasswordTranslate: string = '';
  IncorrectCredentialsTranslate: string = '';
  errorTranslate: string = '';

  changeLanguage$: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private _fb: FormBuilder,
    private skinService: SkinMangementService,
    private _authService: AuthService,
    private toastController: ToastController,
    private navCtrl: NavController,
    private translate: TranslateService,
    private _languageService: LanguageService
  ) {}

  ngOnInit() {
    this.company = this.skinService.getCompany();

    this.savePlaceholders();
    this.changeLanguage$ = this._languageService.languageChange.subscribe(
      (value) => {
        //console.log('es-ES', value);
        this.translate.setDefaultLang(value);
        this.savePlaceholders();
      }
    );

    this.company = this.skinService.getCompany();

    this.forcePassword = this._fb.group(
      {
        userEmail: [this.username, [Validators.required]],
        oldPassword: ['', [Validators.required]],
        contrasenya: [
          '',
          [Validators.required, Validators.pattern(PATTERN_PASSWORD_VALIDATE)],
        ],
        re_contrasenya: [
          '',
          [Validators.required, Validators.pattern(PATTERN_PASSWORD_VALIDATE)],
        ],
      },
      {
        validator: MustMatch('contrasenya', 're_contrasenya'),
      }
    );
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  changeShowPassword(event) {
    this.showPassword = event?.detail?.checked;
  }

  sendForcePassword() {
    if (!this.showError) this.showError = true;

    if (this.forcePassword.valid) {
      this.showError = false;
      let params: ForcePassword = {
        user: this.forcePassword.value.userEmail,
        old_password: setCrypto(this.forcePassword.value.oldPassword),
        new_password: setCrypto(this.forcePassword.value.contrasenya),
      };

      this._authService.forcePassword(params).subscribe({
        next: (res: UpdatedFP) => {
          if (res?.updated) {
            this._authService.guardarToken(res.token);
            this._authService.saveStorage(res);
            this.closeModal();
            successToast(this.successPasswordTranslate, this.toastController);
            this.navCtrl.navigateRoot('/user/home', { animated: true });
          } else {
            presentToast(this.errorTranslate, this.toastController);
          }
        },
        error: (error) => {
          if (error == errorsTypes.forcePassword_diferent_password) {
            presentToast(
              this.ForceDiferentPasswordTranslate,
              this.toastController
            );
          } else if (error == errorsTypes.force_password_actived) {
            presentToast(
              this.activedForcePasswordTranslate,
              this.toastController
            );
          } else if (
            error == errorsTypes.force_password_incorrect_crendentials
          ) {
            presentToast(
              this.IncorrectCredentialsTranslate,
              this.toastController
            );
          } else {
            presentToast(this.errorTranslate, this.toastController);
          }
        },
      });
    }
  }

  rememberPassword() {
    this.modalCtrl.dismiss({
      remember: true,
    });
  }

  savePlaceholders() {
    this.translate
      .get([
        'not_exist_email',
        'not_exist_user',
        'forcePassword_old_password',
        'forcePassword_new_password',
        'force-password-renewal.diferent_password',
        'force-password-renewal.success_password',
        'force-password-renewal.actived_force',
        'force-password-renewal.error_credentials',
        'force-password-renewal.error',
      ])
      .subscribe((translates) => {
        this.emailNotExistTranslate = translates['not_exist_email'];
        this.forceUserTranslate = translates['not_exist_user'];
        this.forceOldPasswordTranslate =
          translates['forcePassword-renewal.old_password'];
        this.forceNewPasswordTranslate =
          translates['forcePassword-renewal.new_password'];
        this.ForceDiferentPasswordTranslate =
          translates['force-password-renewal.diferent_password'];
        this.successPasswordTranslate =
          translates['force-password-renewal.success_password'];
        this.activedForcePasswordTranslate =
          translates['force-password-renewal.actived_force'];
        this.IncorrectCredentialsTranslate =
          translates['force-password-renewal.error_credentials'];
        this.errorTranslate = translates['force-password-renewal.error'];
      });
  }
}
