
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SkinMangementService } from '../../../../../../services/skin-mangement.service';
import { LanguageRouteService } from '../../../../../../share/services/languageRoute.ts/languagesRoute.services';
import { type } from '../../../../../../utils/constants';
import { Company } from 'src/app/models/';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'app-legend-tag-status',
  templateUrl: './legend-tag-status.component.html',
  styleUrls: ['./legend-tag-status.component.scss'],
})
export class LegendTagStatusComponent implements OnInit {

  // aspecto
  company: Company;
  type = type;



  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    public readonly platform: Platform
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
