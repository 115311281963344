import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ToastController, ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { lastValueFrom } from 'rxjs';

// Component
import { ModalSelectClientComponent } from '../../../../../../../share/components/modals/modal-select-client/modal-select-client.component';
import { AssignCardModalComponent } from 'src/app/share/components/carousel-filter-guided/components/select-register-card/assign-card-modal/assign-card-modal.component';

// Service
import { TranslateService } from '@ngx-translate/core';
import { ClientFilterService } from 'src/app/share/components/filter-client/service/client-filter.service';
import {
  RegisterCardParams,
  RegisterCardsService,
  AssignCardCheckParams,
  AssignCardCheck,
} from '../../../../../../../share/services/register-cards/register-cards.service';
import { LanguageRouteService } from '../../../../../../../share/services/languageRoute.ts/languagesRoute.services';
import { VisibilitySelectService } from '../../../../../../../share/services/visibility-select/visibility-select.service';
import { CardLengthRegisterService } from '../../../services/card-length-register.service';
import { ColorModeService } from 'src/app/services';

// Models
import { CardNames, Client, Company } from 'src/app/models';
import { ParamsUnlinkClientCardByList } from '../../../models';

// Utils
import {
  pcWidth,
  errorsTypes,
  successToast,
  UtilsTranslate,
  ModalGenerator,
  replaceNameCard,
} from '../../../../../../../utils';

@Component({
  selector: 'app-unitary-card-pc',
  templateUrl: './unitary-card-pc.component.html',
  styleUrls: ['./unitary-card-pc.component.scss'],
})
export class UnitaryCardPcComponent implements OnInit {
  @Input() company: Company = null;

  @Input() uniqueCard = null;

  @Input() segment = '0';
  @Input() segmentType: string = '0';

  showSelectOperator = false;

  errorText = '';

  // translates
  min6Translate = '';
  maxErrorTranslate = '';
  requiredTranslate = '';
  createTranslate = '';
  assignSuccessTranslate = '';

  showError = false;

  operatorId = null;
  errorColor = false;

  client: Client = null;

  loadingIndividual = false;

  pcWidth = pcWidth;

  minCard: number = 6;
  maxCard: number = 8;

  cardNames = CardNames;

  changeLanguage$: Subscription;
  filterChange$: Subscription;

  constructor(
    private translate: TranslateService,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    private _languageRouteService: LanguageRouteService,
    private _registerCardsService: RegisterCardsService,
    private _visibilityService: VisibilitySelectService,
    private _clientFilter: ClientFilterService,
    private utils: UtilsTranslate,
    private platform: Platform,
    private _cardLengthService: CardLengthRegisterService,
    private _colorModeService: ColorModeService,
    private modalGenerator: ModalGenerator
  ) {}

  ngOnInit() {
    this.minCard = this._cardLengthService.getMinLengthCard();
    this.maxCard = this._cardLengthService.getMaxLengthCard();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    this.filterChange$ = this._visibilityService.filterChange.subscribe(
      (res) => {
        if (res && res?.operatorSelected && res?.operatorSelected != -1) {
          this.showSelectOperator = false;
          this.operatorId = res.operatorSelected;
          if (this.errorColor) {
            this.errorColor = false;
          }
        }
      }
    );
    this.validate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.segment || changes?.segmentType) {
      this.uniqueCard = '';
      this.showError = false;
    }
  }

  ngOnDestroy(): void {
    if (this.filterChange$) this.filterChange$.unsubscribe();
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
  }

  uniqueCardChange() {
    this.validate();
  }

  inputFocus() {
    let operatorSelected = this._visibilityService.getOperatorSelected();
    if (!operatorSelected || operatorSelected == -1) {
      this.showSelectOperator = true;
    } else {
      this.showSelectOperator = false;
    }
  }

  validate() {
    // 6 caracteres
    if (this.uniqueCard && this.uniqueCard.length < this.minCard) {
      this.errorText = this.min6Translate;
    }

    // 8 o 9 caracteres segun company
    else if (this.uniqueCard && this.uniqueCard.length > this.maxCard) {
      this.errorText = this.maxErrorTranslate;
    }
    // no card
    else if (!this.uniqueCard) {
      this.errorText = this.requiredTranslate;
    } else {
      this.errorText = '';
    }
  }

  savePlaceholders() {
    this.translate
      .get([
        'register_cards.select_pc.min_card_length',
        'register_cards.select_pc.max_card_length',
        'register_cards.interval_sm.error_required_individual2',
        'register_cards.interval_sm.error_required_individual',
        'register_cards.select_individual.created_alliancepay',
        'register_cards.select_individual.assign_alliancepay',
        'register_cards.select_individual.created_v2v',
        'register_cards.select_individual.assign_v2v',
      ])
      .subscribe((translates) => {
        this.min6Translate = this.getMinTranslate(
          translates['register_cards.select_pc.min_card_length'],
          this.minCard
        );
        this.maxErrorTranslate = this.getMaxTranslate(
          translates['register_cards.select_pc.max_card_length'],
          this.maxCard
        );

        this.company.nameCard == this.cardNames.arbitradePay
          ? (this.requiredTranslate = replaceNameCard(
              translates[
                'register_cards.interval_sm.error_required_individual2'
              ]
            ))
          : (this.requiredTranslate =
              translates[
                'register_cards.interval_sm.error_required_individual'
              ]);

        if (this.company.nameCard == this.cardNames.arbitradePay) {
          this.createTranslate = replaceNameCard(
            translates['register_cards.select_individual.created_alliancepay']
          );
          this.assignSuccessTranslate = replaceNameCard(
            translates['register_cards.select_individual.assign_alliancepay']
          );
        } else {
          this.createTranslate =
            translates['register_cards.select_individual.created_v2v'];
          this.assignSuccessTranslate =
            translates['register_cards.select_individual.assign_v2v'];
        }
      });
  }

  getMinTranslate(minTranslate, min) {
    return minTranslate.replace('{{min}}', min);
  }

  getMaxTranslate(maxTranslate, max) {
    return maxTranslate.replace('{{max}}', max);
  }

  async showSelectClient() {
    const data = await this.modalGenerator.show(ModalSelectClientComponent);

    if (data?.client) {
      this.client = data.client;
    }
  }

  async sendFilter() {
    if (this.errorText) {
      this.showError = true;
      return;
    }

    if (!this.operatorId && this._visibilityService.getOperatorSelected) {
      this.operatorId = this._visibilityService.getOperatorSelected();
    }

    // registrar card aun operador
    if (this.segment == '0') {
      await this.registerCard();
    }

    // asignar una card a un cliente
    else if (this.segment === '1') {
      await this.linkSave();
    } else {
      await this.unlinkCard();
    }
  }

  async registerCard() {
    if (this.showSelectOperator || !this.operatorId || this.operatorId == -1) {
      this.errorColor = true;
      return;
    }

    this.showError = false;
    this.errorColor = false;
    this.errorText = '';

    this.loadingIndividual = true;

    let params: RegisterCardParams = {
      Operator_id: this._visibilityService.getOperatorSelected(),
      VT: [this.uniqueCard.trim()],
    };

    this._registerCardsService.registerCardList(params).subscribe({
      next: (res) => {
        // console.log('res', res);
        this.loadingIndividual = false;
        if (res?.not_created.length == 0) {
          successToast(this.getSuccessText(), this.toastCtrl);
          this.uniqueCard = '';
          if (!this.showSelectOperator) {
            this._visibilityService.resetParams();
          }
        } else {
          this.utils.presentToastTranslate('error', true, 0);
        }
      },
      error: (error) => {
        this.loadingIndividual = false;
        if (error == errorsTypes.exist_card) {
          this.utils.presentToastTranslate(
            'register_cards.select_individual.exist_card',
            true,
            0
          );
        } else {
          this.utils.presentToastTranslate('error', true, 0);
        }
      },
    });
  }

  async linkSave() {
    if (!this.client || !this.client?.ID) {
      this.showError = true;
      return;
    }

    this.showError = false;
    this.errorText = '';

    let params: AssignCardCheckParams = {
      Client_id: this.client.ID,
      VT: [this.uniqueCard],
    };

    this.loadingIndividual = true;

    try {
      let resCheck = await lastValueFrom(
        this._registerCardsService.checkCardList(params)
      );

      if (
        resCheck.cards_with_clients.length > 0 ||
        resCheck.not_exist_cards.length > 0
      ) {
        await this.showAssignModal(resCheck, params);
        this.loadingIndividual = false;
      } else {
        this.loadingIndividual = false;
        this.changeClient(params);
      }
    } catch (error) {
      // console.log('error')
      this.utils.presentToastTranslate('error', true, 0);
      this.loadingIndividual = false;
    }
  }

  async unlinkCard() {
    this.showError = false;
    this.errorText = '';

    this.loadingIndividual = true;

    const params: ParamsUnlinkClientCardByList = {
      VT: [this.uniqueCard],
    };

    try {
      await lastValueFrom(
        this._registerCardsService.unlinkClientCardByList(params)
      );

      this.loadingIndividual = false;
      this.utils.presentToastWithVariable(
        this.company.nameCard === this.cardNames.arbitradePay
          ? 'register_cards.select_pc.unlinked_unitary1'
          : 'register_cards.select_pc.unlinked_unitary2',
        'cardnum',
        this.uniqueCard,
        false
      );
      this.uniqueCard = '';
    } catch (error) {
      if (error === errorsTypes.incorrect_card_register_card) {
        this.utils.presentToastWithVariable(
          this.company.nameCard === this.cardNames.arbitradePay
            ? 'register_cards.select_pc.unlinked_not_exist1'
            : 'register_cards.select_pc.unlinked_not_exist2',
          'cardNum',
          this.uniqueCard,
          true
        );
      } else {
        this.utils.showError();
      }
      this.loadingIndividual = false;
    }
  }

  clearFilter() {
    this.loadingIndividual = false;
    this.uniqueCard = '';
    this._visibilityService.resetParams();
    this._clientFilter.clearFilter();
    this.client = null;
    this.operatorId = null;
    this.errorText = '';
    this.showError = false;
  }

  getSuccessText() {
    return this.createTranslate.replace(
      '{{cardNum}}',
      this.uniqueCard.toString()
    );
  }

  getSuccessAssignText() {
    return this.assignSuccessTranslate
      .replace('{{cardNum}}', this.uniqueCard.toString())
      .replace('{{client}}', this.client.description);
  }

  async showAssignModal(res: AssignCardCheck, params) {
    const isLightColor = this._colorModeService.getIsLightColor();

    const modal = await this.modalCtrl.create({
      component: AssignCardModalComponent,
      componentProps: {
        cardsWithClients: res?.cards_with_clients || [],
        notExistCards: res?.not_exist_cards || [],
      },
      cssClass:
        this.platform.width() > pcWidth &&
        `modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`,
    });

    if (modal) {
      await modal.present();
      const { data } = await modal.onWillDismiss();

      if (data?.changeClient) {
        this.changeClient(params);
      }
    }
  }

  changeClient(params) {
    this.loadingIndividual = true;
    this._registerCardsService.assignCardList(params).subscribe({
      next: (res) => {
        this.loadingIndividual = false;
        if (res?.not_exist_cards && res?.not_exist_cards.length == 0) {
          successToast(this.getSuccessAssignText(), this.toastCtrl);
          // this.created.emit(true);
          this.client = null;
          this._clientFilter.clearFilter();
          this.uniqueCard = '';
        } else {
          this.utils.presentToastTranslate('error', true, 0);
        }
      },
      error: () => {
        this.loadingIndividual = false;
        this.utils.presentToastTranslate('error', true, 0);
      },
    });
  }
}
