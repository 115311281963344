// External modules
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';


// Component
import { IconSystemOperativeComponent } from './icon-system-operative.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,

        HttpClientModule,
    ],
    declarations: [
      IconSystemOperativeComponent
    ],
    providers: [

    ],
    exports: [
      IconSystemOperativeComponent
    ]
})

export class IconSystemOperativeModule { }
