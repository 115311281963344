

// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Component
import { FilterInputSearchShareComponent } from './filter-input-search-share.component';

// Service
import { LanguageService } from 'src/app/share/services';
import { SkinMangementService } from 'src/app/services';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,

  ],
  declarations: [
    FilterInputSearchShareComponent
  ],
  providers: [LanguageService, SkinMangementService],
  exports: [
    FilterInputSearchShareComponent
  ],
})
export class FilterInputSearchShareModule {}
