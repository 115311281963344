// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

// Modules
import { HeaderModalModule } from '../header-modal/header-modal.modal';
import { ArrowPositionShareModule } from '../carousel-filter-guided/components/arrow-position';
import { FilterTemplateGuidedSelectModule } from '../modals/filter-template-guided-select';
import { InfoSelectedHeaderModule } from '../modals/info-selected-header';
import { FilterTemplateGuidedObjectModule } from '../modals/filter-template-guided-object';

// Component
import { FilterClientByOperatorComponent } from './filter-client-by-operator.component';

// Service
import { LanguageService } from '../../services';
import { SkinMangementService } from 'src/app/services';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SwiperModule,

    HeaderModalModule,
    ArrowPositionShareModule,
    FilterTemplateGuidedSelectModule,
    InfoSelectedHeaderModule,
    FilterTemplateGuidedObjectModule
  ],
  declarations: [FilterClientByOperatorComponent],
  providers: [LanguageService, SkinMangementService],
  exports: [
    FilterClientByOperatorComponent
  ],
})
export class FilterClientByOperatorModule {}
