
export const PREFERRED_COUNTRIES_DEFAULT = ['es', 'ad']

export const PREFIX_PHONE_DEFAULT = '34';

export const PLACEHOLDER_PHONE_TEMPLATE = 'phone_template.enter_number'

export const PHONE_DARK_MODE_INPUT_STYLE = 'dark-transparent-input';


// table-visibility-dark-placeholder
export const EXTRA_CLASS_PHONE_DARK_TABLE_GREY_PLACEHOLDER = 'table-visibility table-visibility-dark table-visibility-grey-placeholder'
