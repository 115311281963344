import {
  Component,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

// services
import { TranslateService } from '@ngx-translate/core';
import { CardsByTextReportService } from '../../../services';

// models
import { Company } from 'src/app/models';
import { InvalidCardReport } from '../../../models';

// utils
import {
  joinArrWithStrongTag,
  replaceNameCard,
  replaceNameCardPlural,
} from 'src/app/utils';
import {
  getCardRepeatByTextTranslateReport,
  getCheckInvalidCardsTextTranslateReport,
  getTitleInvalidCardByTextReport,
} from '../../../utils';

@Component({
  selector: 'app-invalid-cards-by-text-report-modal',
  templateUrl: './invalid-cards-by-text-report-modal.component.html',
  styleUrls: ['./invalid-cards-by-text-report-modal.component.scss'],
})
export class InvalidCardsByTextReportModalComponent implements OnInit {
  @Input() company: Company;

  invalidCardsByText: WritableSignal<InvalidCardReport>;
  translateTitle: string = '';
  translateDuplicate: string = '';
  translateInvalidCards: string = '';

  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private _cardsByTextReportService: CardsByTextReportService
  ) {}

  ngOnInit() {
    this.invalidCardsByText = this._cardsByTextReportService.invalidCardsByText;
    this.getTitle();
    this.getTranslateDuplicate();
    this.getTranslateInvalidCards();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

  async getTitle() {
    this.translateTitle = getTitleInvalidCardByTextReport(
      this.company,
      this.invalidCardsByText()
    );
  }

  async getTranslateDuplicate() {
    const duplicates = this.invalidCardsByText().dupplicateCards || [];

    if (duplicates.length > 0) {
      const translateDuplicateCard = getCardRepeatByTextTranslateReport(
        this.company,
        duplicates.length
      );

      const cards = joinArrWithStrongTag(duplicates);

      const duplicatesTranslate = await this.translate.instant(
        translateDuplicateCard,
        { cards }
      );

      if ((duplicates || []).length > 1) {
        this.translateDuplicate = replaceNameCardPlural(duplicatesTranslate);
      } else {
        this.translateDuplicate = replaceNameCard(duplicatesTranslate);
      }
    }
  }

  async getTranslateInvalidCards() {
    const notExistCards = this.invalidCardsByText().notExistCards || [];

    if (notExistCards.length > 0) {
      const translateNotExistCard = getCheckInvalidCardsTextTranslateReport(
        this.company,
        notExistCards.length
      );

      const cards = joinArrWithStrongTag(notExistCards);

      const invalidCardsTranslate = await this.translate.instant(
        translateNotExistCard,
        { cards }
      );

      if ((notExistCards || []).length > 1) {
        this.translateInvalidCards = replaceNameCardPlural(
          invalidCardsTranslate
        );
      } else {
        this.translateInvalidCards = replaceNameCard(invalidCardsTranslate);
      }
    }
  }
}
