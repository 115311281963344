// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-icon {
  border: 1px solid black;
  background-color: var(--ion-color-light);
}

.icon-size {
  font-size: 2em;
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.h-60 {
  height: 60px;
}

table {
  text-align: center;
  align-items: center;
  width: 100%;
  border-collapse: collapse;
}

th {
  color: var(--ion-color-light);
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  padding: 2px;
}

td {
  height: 60px;
}

tr {
  font-size: 1em;
}

td,
th {
  padding: 2px;
  border: 1px solid var(--ion-color-light-table-th);
  text-align: left;
  text-align: center;
}

.font-sm {
  font-size: 0.9em;
}

ion-icon:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.w-40 {
  width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/listings/reports/components/modals/view-report-modal/view-report-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,wCAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,yBAAA;AACF;;AAEA;EACE,6BAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;AACF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAIA;;EAEE,YAAA;EACA,iDAAA;EACA,gBAAA;EACA,kBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAKA;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AAFF;;AAMA;EACE,UAAA;AAHF","sourcesContent":[".border-icon{\r\n  border: 1px solid black;\r\n  background-color: var(--ion-color-light);\r\n}\r\n\r\n.icon-size{\r\n  font-size: 2em;\r\n}\r\n\r\n.d-flex{\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.h-60{\r\n  height: 60px;\r\n}\r\n\r\ntable {\r\n  text-align: center;\r\n  align-items: center;\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n}\r\n\r\nth {\r\n  color: var(--ion-color-light);\r\n  font-weight: bold;\r\n  text-align: center;\r\n  font-size: 20px;\r\n  padding: 2px;\r\n\r\n}\r\n\r\ntd{\r\n  height: 60px;\r\n}\r\n\r\ntr{\r\n  font-size: 1em;\r\n\r\n}\r\n\r\ntd,\r\nth {\r\n  padding: 2px;\r\n  border: 1px solid  var(--ion-color-light-table-th);\r\n  text-align: left;\r\n  text-align: center;\r\n}\r\n\r\n.font-sm{\r\n  font-size: 0.9em;\r\n}\r\n\r\n\r\nion-icon:before {\r\n  content: '';\r\n  position: absolute;\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n\r\n.w-40{\r\n  width: 40%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
