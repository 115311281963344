// models
import { twoDecimalsTransform } from 'src/app/utils';
import { FilterDashboards, MaxAxisYDashboard } from '../models';

export const getMaxAxisYByFilterDashboard = (
  filterDashboard: FilterDashboards,
  maxAxisY: MaxAxisYDashboard
): number => {
  if (!filterDashboard.sameScale) {
    return null;
  }

  if (
    filterDashboard.articles?.areaA?.isBilling !==
    filterDashboard.articles?.areaB?.isBilling
  ) {
    return null;
  }

  return filterDashboard.articles?.areaA?.isBilling
    ? twoDecimalsTransform(maxAxisY.max.billing)
    : twoDecimalsTransform(maxAxisY.max.units);
};
