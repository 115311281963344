import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


// Config
import { AppConfig } from '../../../config/config';

// Models
import { Operator, Currency, TypeVat } from '../../../models/Operator.model';
import { CreateOperator, EditOperator } from '../../../pages/user/data-manager/operator-manager/models';
import { MACHINE_STATUS_ROUTE, MACHINE_STATUS_SUBROUTES } from 'src/app/utils';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router) { }

  getOperators(params: ParamsGetOperators = {}, highLevel = false, prevRoute: string = '', groupName: string = ''){
    let endpoint = `operators/get-operators`;
    let routeUrl = prevRoute || this.router.url;

    if(MACHINE_STATUS_SUBROUTES.includes(routeUrl)){
      routeUrl = MACHINE_STATUS_ROUTE;
    }

    let urlComplete = this.serviceUrl + endpoint + `?route=${routeUrl}`;
    if(groupName){
      urlComplete += `&group=${groupName}`
    }

    return this.httpClient.post<Operator[]>(urlComplete, params).pipe(
      map(res => {
        if(!highLevel){
          res = res.filter(operator => operator.isValido === 1)
        }
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }


  getOperatorById(operatorId: number){
    const params: ParamsGetOperators = {
      operatorId
    }

    return this.getOperators(params)
  }

  createOperator(params: CreateOperator){
    let endpoint = `operator`;
    let routeUrl = this.router.url;

    return this.httpClient.post<Operator[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  updateOperator(params: EditOperator){
    let endpoint = `editOperator`;
    let routeUrl = this.router.url;

    return this.httpClient.post<Operator>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  getCurrencies() {
    let endpoint = `currency`;
    let routeUrl = this.router.url;

    return this.httpClient.get<Currency[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`).pipe(
      map(res => {
        res = res.filter(resPos => resPos.ID > 0)
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  getVats() {
    let endpoint = `vat`;
    let routeUrl = this.router.url;

    return this.httpClient.get<TypeVat[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`).pipe(
      map(res => {
        res = res.filter(resPos => resPos.ID > 0)
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }




}

  export interface ParamsGetOperators {
  operatorGroupId?: number,
  operatorId?: number
}
