import { Injectable } from '@angular/core';

// service
import { StorageService } from '../share/services/storage/storage.service';

// environment
import { version } from 'src/environments/version';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(
    private _storageService: StorageService,
  ) {}

  async versionActions() {
    // console.log('cache', caches)

    // console.log('navigator', navigator)
    //   if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker.getRegistrations()
    //     .then(function(registrations) {
    //       console.log('registrations', registrations)
    //        for(let registration of registrations) {
    //           // registration.unregister();
    //        }
    //     });
    //  }

    // await this.clearVersionOld()
    await this.clearVersionNew();
  }

  // async clearVersionOld(){
  //   let currentVersion = await this.storage.get('version');
  //   if (!currentVersion) {
  //     await this.storage.set('version', version);
  //   } else if (currentVersion != version) {

  //     await this.storage.clear();
  //     await sessionStorage.clear();
  //     if (caches) {
  //       caches.keys().then((cacheNames) => {
  //         return Promise.all(
  //           cacheNames.filter((cacheName) => {
  //           }).map((cacheName) => caches.delete(cacheName)))
  //       })
  //     }
  //     await this.storage.set('version', version);
  //     this.navCtrl.navigateRoot('/login', { animated: false });
  //     window.location.reload();
  //   }
  // }

  async clearVersionNew() {
    let currentVersion = await this._storageService.get('version');
    if (currentVersion != version || !currentVersion) {
      await this._storageService.clear();
      await sessionStorage.clear();
      if (caches) {
        caches.keys().then((cacheNames) => {
          return Promise.all(
            cacheNames
              .filter((cacheName) => {})
              .map((cacheName) => caches.delete(cacheName))
          );
        });
      }
      await this._storageService.set('version', version);
      handleHardReload(window.location.href);
    }
  }
}


const handleHardReload = async(url) => {
  await fetch(url, {
      headers: {
          Pragma: 'no-cache',
          Expires: '-1',
          'Cache-Control': 'no-cache',
      },
  });
  window.location.href = url;
  window.location.reload();
}

