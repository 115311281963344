import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastController, ModalController } from '@ionic/angular';
import { Subscription, lastValueFrom } from 'rxjs';

// Component
import { AssignCardModalComponent } from '../assign-card-modal/assign-card-modal.component';

// Service
import { TranslateService } from '@ngx-translate/core';
import {
  VisibilitySelectService,
  LanguageRouteService,
} from '../../../../../services';
import {
  RegisterCardsService,
  RegisterCardParams,
  AssignCardCheckParams,
  AssignCardCheck,
} from '../../../../../services/register-cards/register-cards.service';
import { FilterSelectRegisterCardService } from '../service/filter-select-register-card.service';
import { CardLengthRegisterService } from '../../../../../../pages/user/cards/register-cards/services';

// Models
import { Company } from 'src/app/models/';
import { CarouselType } from '../../../models/carousel-type.type';

// Utils
import {
  successToast,
  UtilsTranslate,
  errorsTypes,
} from '../../../../../../utils';
import { ParamsUnlinkClientCardByList } from '../../../../../../pages/user/cards/register-cards/models/params-unlinked-client-card-list.model';

@Component({
  selector: 'app-select-individual-card',
  templateUrl: './select-individual-card.component.html',
  styleUrls: ['./select-individual-card.component.scss'],
})
export class SelectIndividualCardComponent implements OnInit {
  @Input() company: Company;
  @Input() cardInvidual: string = '';
  @Input() showError: boolean = false;

  @Input() isRegister: boolean = false;
  @Input() clientId: number = null;
  @Input() clientName: string = '';

  @Input() haveBack: boolean = true;
  @Input() isUnLinked: boolean = false;

  @Output() handleBack = new EventEmitter<CarouselType>();
  @Output() created = new EventEmitter<boolean>();

  messageError = '';

  requiredErrorTranslate = '';
  min6Translate = '';
  maxErrorTranslate = '';
  createTranslate = '';
  assignSuccessTranslate = '';

  loadingIndividual = false;

  minCard: number = 6;
  maxCard: number = 8;

  changeLanguage$: Subscription;
  resetCard$: Subscription;

  constructor(
    private _languageRouteService: LanguageRouteService,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private _filterSelectRegisterCardService: FilterSelectRegisterCardService,
    private _registerCardsService: RegisterCardsService,
    private _visibilityService: VisibilitySelectService,
    private utils: UtilsTranslate,
    private _cardLengthService: CardLengthRegisterService
  ) {}

  ngOnInit() {
    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    this.minCard = this._cardLengthService.getMinLengthCard();
    this.maxCard = this._cardLengthService.getMaxLengthCard();

    this.resetCard$ = this._filterSelectRegisterCardService.resetCards.subscribe(
      (res) => {
        if (res) {
          this.cardInvidual = '';
          this.showError = false;
        }
      }
    );

    this.validateInput();
  }

  ngOnDestroy(): void {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if (this.resetCard$) this.resetCard$.unsubscribe();
  }

  validateInput() {
    // if(!this.showError){
    //   return;
    // }

    if (!this.cardInvidual) {
      this.messageError = this.requiredErrorTranslate;
    } else if (this.cardInvidual && this.cardInvidual.length < this.minCard) {
      this.messageError = this.min6Translate;
    } else if (this.cardInvidual && this.cardInvidual.length > this.maxCard) {
      this.messageError = this.maxErrorTranslate;
    } else {
      this.messageError = '';
    }
  }

  savePlaceholders() {
    this.translate
      .get([
        'register_cards.select_pc.min_card_length',
        'register_cards.select_pc.max_card_length',
        'register_cards.interval_sm.error_required_individual2',
        'register_cards.interval_sm.error_required_individual',
        'register_cards.select_individual.created_alliancepay',
        'register_cards.select_individual.created_v2v',
        'register_cards.select_individual.assign_alliancepay',
        'register_cards.select_individual.assign_v2v',
      ])
      .subscribe((translates) => {
        this.min6Translate = this.getMinTranslate(
          translates['register_cards.select_pc.min_card_length'],
          this.minCard
        );
        this.maxErrorTranslate = this.getMaxTranslate(
          translates['register_cards.select_pc.max_card_length'],
          this.maxCard
        );
        if (this.company.nameCard == 'AlliancePay') {
          this.requiredErrorTranslate =
            translates['register_cards.interval_sm.error_required_individual2'];
          this.createTranslate =
            translates['register_cards.select_individual.created_alliancepay'];
          this.assignSuccessTranslate =
            translates['register_cards.select_individual.assign_alliancepay'];
        } else {
          this.requiredErrorTranslate =
            translates['register_cards.interval_sm.error_required_individual'];
          this.createTranslate =
            translates['register_cards.select_individual.created_v2v'];
          this.assignSuccessTranslate =
            translates['register_cards.select_individual.assign_v2v'];
        }
      });
  }

  getMinTranslate(minTranslate, min) {
    return minTranslate.replace('{{min}}', min);
  }

  getMaxTranslate(maxTranslate, max) {
    return maxTranslate.replace('{{max}}', max);
  }

  async sendCard() {
    if (this.messageError) {
      this.showError = true;
      return;
    }

    if (!this.cardInvidual) {
      this.validateInput();
      this.showError = true;
      this.loadingIndividual = false;
      return;
    }

    //console.log('client', this.clientId, this.isRegister, this.cardInvidual)

    this.loadingIndividual = true;
    if (this.isUnLinked) {
      await this.unlinked();
    } else if (this.isRegister) {
      // dar de alta
      let params: RegisterCardParams = {
        Operator_id: this._visibilityService.getOperatorSelected(),
        VT: [this.cardInvidual],
      };

      this._registerCardsService.registerCardList(params).subscribe({
        next: (res) => {
          // console.log('res', res);
          this.loadingIndividual = false;
          if (res?.not_created.length == 0) {
            successToast(this.getSuccessText(), this.toastCtrl);
            this.created.emit(true);
          } else {
            this.utils.presentToastTranslate('error', true, 0);
          }
        },
        error: (error) => {
          this.loadingIndividual = false;
          if (error == errorsTypes.exist_card) {
            this.utils.presentToastTranslate(
              'register_cards.select_individual.exist_card',
              true,
              0
            );
          } else {
            //console.log('error', error);
            this.utils.presentToastTranslate('error', true, 0);
          }
        },
      });
    } else {
      // asignar cliente
      let params: AssignCardCheckParams = {
        Client_id: this.clientId,
        VT: [this.cardInvidual],
      };

      try {
        let resCheck = await lastValueFrom(
          this._registerCardsService.checkCardList(params)
        );

        if (
          resCheck.cards_with_clients.length > 0 ||
          resCheck.not_exist_cards.length > 0
        ) {
          await this.showModal(resCheck, params);
          this.loadingIndividual = false;
        } else {
          this.loadingIndividual = false;
          this.changeClient(params);
        }
      } catch (error) {
        //console.log('error')
        this.loadingIndividual = false;
        this.utils.presentToastTranslate('error', true, 0);
      }
    }
  }

  async unlinked() {
    this.showError = false;
    this.loadingIndividual = true;

    const params: ParamsUnlinkClientCardByList = {
      VT: [this.cardInvidual],
    };

    try {
      await lastValueFrom(
        this._registerCardsService.unlinkClientCardByList(params)
      );

      this.utils.presentToastWithVariable(
        this.company.nameCard === 'AlliancePay'
          ? 'register_cards.select_pc.unlinked_unitary1'
          : 'register_cards.select_pc.unlinked_unitary2',
        'cardnum',
        this.cardInvidual,
        false
      );
      this.cardInvidual = '';
      this.created.emit();
      this.loadingIndividual = false;
    } catch (error) {
      if (error === errorsTypes.incorrect_card_register_card) {
        this.utils.presentToastWithVariable(
          this.company.nameCard === 'AlliancePay'
            ? 'register_cards.select_pc.unlinked_not_exist1'
            : 'register_cards.select_pc.unlinked_not_exist2',
          'cardNum',
          this.cardInvidual,
          true
        );
      } else {
        this.utils.showError();
      }
      this.loadingIndividual = false;
    }
  }

  back() {
    this.handleBack.emit('IntervalCard');
  }

  getSuccessText() {
    return this.createTranslate.replace(
      '{{cardNum}}',
      this.cardInvidual.toString()
    );
  }

  getSuccessAssignText() {
    return this.assignSuccessTranslate
      .replace('{{cardNum}}', this.cardInvidual.toString())
      .replace('{{client}}', this.clientName);
  }

  async showModal(res: AssignCardCheck, params) {
    const modal = await this.modalCtrl.create({
      component: AssignCardModalComponent,
      componentProps: {
        cardsWithClients: res?.cards_with_clients || [],
        notExistCards: res?.not_exist_cards || [],
      },
    });

    if (modal) {
      await modal.present();
      const { data } = await modal.onWillDismiss();

      if (data?.changeClient) {
        //console.log('change client');
        this.changeClient(params);
      }
    }
  }

  changeClient(params) {
    this.loadingIndividual = true;
    this._registerCardsService.assignCardList(params).subscribe(
      (res) => {
        this.loadingIndividual = false;
        if (res?.not_exist_cards && res?.not_exist_cards.length == 0) {
          successToast(this.getSuccessAssignText(), this.toastCtrl);
          this.created.emit(true);
        } else {
          this.utils.presentToastTranslate('error', true, 0);
        }
      },
      (error) => {
        this.loadingIndividual = false;
        this.utils.presentToastTranslate('error', true, 0);
      }
    );
  }
}
