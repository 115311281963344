import { DimensionMaxIM } from '../models/dimension-max-IM.model';

export const MAX_SIZE_IM: number = 25000000;

export const DIMENSIONS_MAX_ANDROID: DimensionMaxIM = {
  Splash: [1920, 2880],
  Login: [1080, 720],
  Banner: [1920, 1360],
}

export const DIMENSIONS_MAX_IOS: DimensionMaxIM = {
  Splash: [1729, 2436],
  Login: [1080, 720],
  Banner: [480, 340],
};

