import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { IonSegment, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// swiper
import { SwiperComponent } from 'swiper/angular';
import { Pagination, SwiperOptions, Swiper } from 'swiper';

// Service
import { FilterSelectRegisterCardService } from './service/filter-select-register-card.service';

// Models
import { Company, Client } from 'src/app/models';
import { CarouselType } from '../../models';
import { IntervalCard } from '../../../../../pages/user/cards/register-cards/models/';

// Utils
import { pcWidth, pcWidthMix } from '../../../../../utils';

@Component({
  selector: 'app-select-register-card',
  templateUrl: './select-register-card.component.html',
  styleUrls: ['./select-register-card.component.scss'],
})
export class SelectRegisterCardComponent implements OnInit {

  // swipper
  config: SwiperOptions = {
    pagination: { clickable: true },
    keyboard: true,
  };

  @Input() company: Company;
  @Input() client: Client= null;
  @Input() haveBack: boolean = true;
  @Input() isUnLinked: boolean = false;
  @Input() cardInvidual: string = '';
  @Input() intervalCard: IntervalCard = {posStart: '', posEnd: '' }
   // true dar de alta - false asignar cliente
   @Input() isRegister: boolean = false;

  @Output() handleBack = new EventEmitter<CarouselType>()
  @Output() created = new EventEmitter<boolean>()

  segmentCard = '0';

  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;

  @ViewChild('segmentSelectRegister') segment2: IonSegment;
  @ViewChild('slideSelectRegister', { static: false }) swiper: SwiperComponent;

  resetCards$: Subscription;

  constructor(
    private _filterSelectRegisterCardService: FilterSelectRegisterCardService,
    public readonly platform:Platform
  ) { }

  ngOnInit() {

    Swiper.use([Pagination]);

    this.intervalCard =  {posStart: '', posEnd: '' }

    this.resetCards$ = this._filterSelectRegisterCardService.resetCards.subscribe(res => {
      if(res){
        this.segmentCard = '0'
      }
    })
  }

  ngOnDestroy(){
    if(this.resetCards$) this.resetCards$.unsubscribe()
  }

  segmentChanged() {
    this.swiper?.swiperRef?.slideTo(Number(this.segmentCard));
  }

  async slideChange() {
    this.segment2.value = (this.swiper?.swiperRef?.activeIndex).toString();
  }

  back(event){
    this.handleBack.emit(event);
  }

  sendCreated(event){
    this.created.emit(event)
  }

}
