// constant
import { WEEK_MAX_DAYS } from '../constant';

// utils
import { weekDayToWeekDayDB } from './weekday-to-weeday-db';

export const getSelectAllWeekDayMachineAlertParams = (): {
  WeekDayID: number;
}[] => {
  let weekDays: { WeekDayID: number }[] = new Array(WEEK_MAX_DAYS)
    .fill(0)
    .map((_, i) => ({ WeekDayID: weekDayToWeekDayDB(i) }));


  return weekDays;
};
