

// Modules
export { CanvasNg2ChartModule } from '../components/charts/canvas-ng2-chart/canvas-ng2-chart.module';
export { CarouselGuidedModule } from '../components/carousel-filter-guided/carousel-filter-guided.module';
export { ColorChangeButtonModule } from '../components/color-change-button/color-change-button.module';
export { DatePeriodNoCalendarModule } from '../components/filter-date/date-period-no-calendar/date-period-no-calendar.module';
export { FilterCardInputModule } from '../components/filter-card-input/filter-card-input.module';
export { FilterClientIndividualModule } from '../components/filter-client-individual/filter-client-individual.module';
export { FilterClientModule } from '../components/filter-client/filter-client.module';
export { FilterClientTillModule } from '../components/filter-client-till/filter-client-till.module';
export { FilterDateTemplateModule } from '../components/filter-date-template/filter-date-template.module';
export { FilterOperatorIndividualModule } from '../components/filter-operator-individual/filter-operator-individual.module';
export { HeaderModalModule } from '../components/header-modal/header-modal.modal';
export { IconSystemOperativeModule } from '../components/icon-system-operative/icon-system-operative.module';
export { IdleModule } from '../components/idle/idle.module';
export { LanguageSelectorModule } from '../components/language-selector/language-selector.module';
export { LoadCreditCardModule } from '../../pages/user/cards/webload/load-credit/component/load-credit-card/load-credit-card.module';
export { MainMenuModule } from '../components/main-menu/main-menu.module';
export { ShareModalsModule } from '../components/modals/share-modals.module';
export { UICustomModule } from '../components/ui/ui-custom.module';
export { VisibilityClientSelectModule } from '../components/visibility-client-select/visibility-client-select.module';
export { VisibilityClientTillSelectModule } from '../components/visibility-client-till-select/visibility-client-till-select.module';
export { VisibilitySelectModule } from '../components/visibility-select/visibility-select.module';
export { PhoneInputTemplateModule } from '../components/phone-input-template/phone-input-template.module';
