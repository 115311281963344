import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

// services
import { SkinMangementService } from 'src/app/services';

// models
import { Company } from 'src/app/models';

// utils
import { pcWidth } from '../../../../../../../utils';


@Component({
  selector: 'app-till-manager-legend',
  templateUrl: './till-manager-legend.component.html',
  styleUrls: ['./till-manager-legend.component.scss'],
})
export class TillManagerLegendComponent implements OnInit {

  company: Company;

  pcWidth = pcWidth;

  constructor(
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
