// models
import { UpdateTillAlertSpiderParams } from '../../../models';

// utils
import { CreateMakeAlertSpiderParams, createMakeAlertParams } from './';

interface GetUpdateTillAlertSpiderParams extends CreateMakeAlertSpiderParams {
  tillAlertEnabled: boolean;
  tillAlertId: number;
}

export const getUpdateTillAlertSpiderParams = ({
  companyId,
  operatorGroupId,
  operatorId,
  clientId,
  tillId,
  hour,
  minute,
  startHour,
  endHour,
  userEmail,
  email,
  weekDays,
  typeAlertSelected,
  tillAlertEnabled,
  tillAlertId,
}: GetUpdateTillAlertSpiderParams): UpdateTillAlertSpiderParams => {
  const createParams = createMakeAlertParams({
    companyId,
    operatorGroupId,
    operatorId,
    clientId,
    tillId,
    hour,
    minute,
    startHour,
    endHour,
    userEmail,
    email,
    weekDays,
    typeAlertSelected,
  });

  const enable = tillAlertEnabled ? 1 : 0;

  return { ...createParams, enable, machineAlertID: tillAlertId };
};
