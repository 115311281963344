import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { pcWidthMix } from 'src/app/utils';


@Component({
  selector: 'app-filter-template-guided-select',
  templateUrl: './filter-template-guided-select.component.html',
  styleUrls: ['./filter-template-guided-select.component.scss'],
})
export class FilterTemplateGuidedSelectComponent implements OnInit {

  @Input() company: Company;
  @Input() titleText: string = '';
  @Input() labelText: string = '';
  @Input() elements: any[] = [];
  @Input() idName: string = '';
  @Input() descriptionName: string = '';

  // @Input() operatorGroupText: string = '';

  @Output() changeElementSelected= new EventEmitter<any>()

  elemSelected: Number = null;

  pcWidthMix = pcWidthMix;

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}


  changeElement(){
    const element = this.elements.find((elemPos)=> elemPos?.[this.idName] === this.elemSelected )
    this.changeElementSelected.emit(element)
  }

}
