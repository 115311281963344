import { Company } from '../../app/models/';
export const V2vFactory: Company = {
  tag: 'v2vfactory',
  companySkin: 'V2VFactory',
  name: 'V2V Factory',
  environment: 'baseUrlElora',
  appConfig: 'BaseURLElora',

  nameCard: 'Tarjeta Cautiva',
  minCard: 6,
  maxCard: 9,
  url: 'elora.v2vfactory.com',
  url_test: 'testing.v2vfactory.com',
  url_local: 'localhost',

  companyId: null,
  operatorGroupId: null,

  color: 'primary',
  color2: 'primary2',
  color3: 'primary3',
  colorFilter: 'filter-v2v',
  colorText: 'primary-text',
  colorText2: 'primary-text2',
  colorNavBar: 'primary',
  colorMenuText: 'dark',

  bgColor1: 'v2v-color-toolbar',
  colorTextTitle: 'v2v-color-text-title',
  titlePage: 'v2v-color-text-title',

  // rgb
  colorRGB: '#3880ff',

  // sliders
  colorBullets: 'color-bullet-v2v',

  // Menu
  activeLink: 'active-link-v2v',
  groupItemMenu: 'group-item-menu-v2v',
  itemMenu: 'item-menu-v2v',

  // Profile
  ionCardHeader: 'ion-card-header-v2v',
  ionCardText1: 'ion-card-text1-v2v',
  ionCardText2: 'ion-card-text2-v2v',
  ionTextCardFooter: 'text-card-footer-v2v',

  // date-period
  ionSegmentButtonStyle: 'ion-segment-button-v2v-style',
  ionSegmentButtonStyle2: 'ion-segment-button-v2v-style2',
  tableFilter: 'filter-table-v2v',

  bgNavbar: 'bg-table-v2v',
  openMenuColor: 'primary',

  // table
  bgTable: 'bg-table-v2v',
  bgTable1: 'bg-table-switch-pair-v2v',
  bgTable2: 'bg-table-switch-odd-v2v',
  bgTable3: 'bg-filter-v2v',
  bgHoverTable: 'bg-hover-table-v2v',
  bgHoverTableWithoutFilter: 'bg-hover-table-sm-v2v',
  subHeaderTable: 'subheader-table-v2v',
  headerVerticalTable: 'header-vertical-table-v2v',
  // site
  blockTable: 'block-table-v2v',
  bgTableBlockHover: 'bg-table-block-hover-v2v',
  bgTableSelected: 'bg-table-selected-v2v',

  // spinner
  spinnerColor: 'spinner-color-v2v',

  //label not found
  labelDialog: 'label-dialog-v2v',

  // top 5, product sold
  labelDialogLoading: 'label-dialog-loading-v2v',

  // card (load-credit-card)
  cardTop: 'card-top-v2v',
  cardBottom: 'card-bottom-v2v',

  infoAlert: 'info-alert-v2v',

  segmentIos: 'segment-ios-v2v',

  // Chart
  colorRGBTitleDark: '#b3d1ff',
  chartColors: [
    {
      backgroundColor: [
        '#428cff',
        '#5497ff',
        '#67a3ff',
        '#7aaeff',
        '#8dbaff',
        '#a0c5ff',
        '#b3d1ff',
        '#c6dcff',
        '#d9e8ff',
        '#ecf3ff',
        '#ebf3ff',
      ],
      borderColor: [
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
        'white',
      ],
    },
  ],
  chartColorsDark: [
    {
      backgroundColor: [
        '#d9e8ff',
        '#c6dcff',
        '#b3d1ff',
        '#a0c5ff',
        '#8dbaff',
        '#7aaeff',
        '#67a3ff',
        '#5597fe',
        '#428cff',
        '#2f80ff',
      ],
      borderColor: [
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
        '#00091a',
      ],
    },
  ],

  ionColorToogle: 'ion-color-toogle-v2v',

  // online shop
  borderCard: 'border-card-v2v',
  borderCart: 'border-cart-v2v',
  border: 'border-v2v',

  // ecommerce-manager
  bgTable1Item: 'bg-table1-item-v2v',
  bgTable2Item: 'bg-table2-item-v2v',
  colorTextNoEditable: 'color-no-editable-text-v2v',
  colorTextNoEditableItem: 'var(--ion-color-primary-not-editable)',

  // notification-manager
  buttonInfo: {
    backgroundColor: '#3880ff',
    borderColor: '#3880ff',
    textColor: '#999999',
    text: '',
    url: 'https://www.v2vfactory.com/',
  },

  // access-web
  segmentVertical: 'segment-v2v-vertical',
  swiperBgAndBorder: 'swiper-v2v-bg',
  itemCheckboxAccessWeb: 'item-checkbox-v2v-access-web',
  checkboxAccessWeb: 'checkbox-v2v-access-web',
  bgColorContent: 'bg-color-content-v2v',
  borderHeadDark: 'border-head-dark-v2v',

  // range slider (sales-module)
  colorBallRange: 'var(--ion-color-primary)',
  colorLineRange: 'var(--ion-color-primary)',
  colorInsideBallRange: 'var(--ion-color-primary-contrast)',
  colorInsideBallRangeActive: 'var(--ion-color-primary-select-dark)',

  // pagination
  paginationCurrentBg: 'var(--ion-color-primary)',
  paginationColorDark: 'var(--ion-color-dark)',
  paginationColorLight: 'var(--ion-color-light)',

  // tag status
  colorIconWithHoverTable: 'color-iconm-with-hover-table-v2v',

  // pre login
  colorCompanyText: 'var(--ion-color-primary-text)',
  colorCompanyText2: 'var(--ion-color-primary-text)',

  // custom alert
  customAlertStyle: 'custom-alert-v2v',

  // ecommerce manager
  colorButtonTable: 'primary-button-table',
  colorButtonTableHover: 'var(--ion-color-primary-button-table-hover)',

  // chart v4
  colorsCharts: [
    '#428cff',
    '#5497ff',
    '#67a3ff',
    '#7aaeff',
    '#8dbaff',
    '#a0c5ff',
    '#b3d1ff',
    '#c6dcff',
    '#d9e8ff',
    '#ecf3ff',
  ],

  backgroundChart: [
    '#428cff',
    '#5497ff',
    '#67a3ff',
    '#7aaeff',
    '#8dbaff',
    '#a0c5ff',
    '#b3d1ff',
    '#c6dcff',
    '#d9e8ff',
    '#ecf3ff',
  ],

  backgroundChartDark: [
    '#d9e8ff',
    '#c6dcff',
    '#b3d1ff',
    '#a0c5ff',
    '#8dbaff',
    '#7aaeff',
    '#67a3ff',
    '#5597fe',
    '#428cff',
    '#2f80ff',
  ],

  borderChart: [
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
    'white',
  ],

  borderChartDark: [
    '#d9e8ff',
    '#c6dcff',
    '#b3d1ff',
    '#a0c5ff',
    '#8dbaff',
    '#7aaeff',
    '#67a3ff',
    '#5597fe',
    '#428cff',
    '#2f80ff',
  ],
  donutBackground: ['rgba(84, 151, 255, 1)', 'rgba(217, 83, 79, 1)'],
  donutBackgroundDark: ['rgba(103, 163, 255, 1)', 'rgba(207, 102, 121, 1)'],

  colorTextVar: 'var(--ion-color-primary-text)',
  colorTextHover: 'var(--ion-color-primary-text-hover)',

  // phone material
  colorTableVisibilityBg: 'var(--ion-color-primary-tint-v2)',
  colorTableVisibilityPlaceholder: '#b8d2fb',
  colorTablePhoneLightEg: 'medium',

  // till-alert
  successTable: 'success',
  qrRequestColorTable: 'qr-request',

  // notification-panel
  colorAndroidIconTable: 'var(--ion-color-android-icon)',

  // dashboard welcome
  colorMonthBar: [
    '#F4F0ED',
    '#EBE4DF',
    '#E2D8D0',
    '#D9CCC2',
    '#D0C0B4',
    '#C7B4A5',
    '#BFA897',
    '#B69C88',
    '#AD907A',
    '#A4846C',
    '#9B785D',
    '#926C4F',
  ],

  colorMonthBarDark: [
    '#926C4F',
    '#9B785D',
    '#A4846C',
    '#AD907A',
    '#B69C88',
    '#BFA897',
    '#C7B4A5',
    '#D0C0B4',
    '#D9CCC2',
    '#E2D8D0',
    '#EBE4DF',
    '#F4F0ED',
  ],

  customPopoverDashboard: 'custom-popover-dashboard-v2v',

  homeBG: 'v2v-home',

  // User Documentation
  colorChipSuccess: 'var(--ion-color-primary-chip-success)',

  colorCardBg: 'primary-bg-card',
};
