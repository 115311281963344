import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FilterClientTillService {

  resetFilter: Subject<boolean> = new Subject<boolean>();
  changeFilter$: Subject<ClientTillFilterChange> = new Subject<ClientTillFilterChange>()
  changeRoute$: Subject<boolean> = new Subject<boolean>()

  name: string = '';
  code: number = null;

  clientId: number = null;
  tillId: number = null;

  constructor() { }

  setTillFilter(tillId, code, clientId){
    this.tillId = tillId;
    this.code = code;
    this.clientId = clientId;
    this.name = '';
    this.changeFilter$.next({type: 'till', id: this.tillId})
  }

  setClientFilter(clientId, name){
    this.tillId = null;
    this.code = null;
    this.clientId = clientId;
    this.name = name;
    this.changeFilter$.next({type: 'client', id: this.clientId})
  }

  getNameClient(){
    return this.name;
  }

  getTillCode(){
    return this.code;
  }

  getTillId(){
    return this.tillId;
  }

  getFilter(): {clientId?: number, tillId?: number}{
    let params = {};
    if(this.clientId){
      params = {clientId: this.clientId}
    }
    if(this.tillId){
      params = {tillId : this.tillId}
    }
    return params;
  }

  getClientId(){
    // let params:number = null
    // if(this.clientId){
    //   params = this.clientId
    // }
    // if(this.tillId){
    //  params = this.getIdTillFilter()
    // }
    // return params;
    return this.clientId;
  }


  getFilterSubsidies(){
    let params;
    if(this.clientId){
     params = {clientID: this.clientId}
    }
    if(this.tillId){
        params = {machineID : this.tillId}
    }
    return params;
  }


  getFilterSubsidies2(){
    let params;
    if(this.clientId){
     params =  this.clientId
    }
    if(this.tillId){
        params = this.tillId
    }
    return params;
  }

  reset(){
    this.name = '';
    this.code = null;
    this.clientId = null;
    this.tillId = null;
    this.resetFilter.next(true);
  }

  changeRoute(){
    this.changeRoute$.next(null)
  }
}


interface ClientTillFilterChange {
  type: ClientOrTill,
  id: number
}

type ClientOrTill = 'client' | 'till'
