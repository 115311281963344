// models
import { RouterDriverByRoute, RouteVisibility } from 'src/app/models';
import {
  MachineRM,
  OperatorRM,
  RouteDriverByTypeRM,
  RouteDriverRM,
  RouteInfoRM,
  SupervisorRM,
} from '../models';

export const getRouteInfoRM = (routes: RouteVisibility[]): RouteInfoRM[] => {
  let routesInfo: RouteInfoRM[] = [];

  for (let route of routes) {
    const routeInfo: RouteInfoRM = {
      routeId: route?.route_id,
      routeName: route?.route_name || '',
      routeNumber: route?.route_number || '',

      supervisor: getSupervisor(route),
      routeDrivers: getRouteDriversByType(route),
      machines: getMachines(route),
      operator: getOperator(route),
      enabled: route?.enable > 0
    };

    routesInfo.push(routeInfo);
  }

  routesInfo = routesInfo.sort((a, b) =>
    a.routeName.localeCompare(b.routeName)
  );

  return routesInfo;
};

const getSupervisor = (route: RouteVisibility): SupervisorRM => {
  if (!route?.supervisor_id && route?.supervisor_id !== 0) {
    return null;
  }

  return {
    supervisor: route?.supervisor || '',
    supervisorId: route?.supervisor_id,
  };
};

const getRouteDriversByType = (route: RouteVisibility): RouteDriverByTypeRM => {
  let routeDriverByType: RouteDriverByTypeRM = {
    holder: null,
    substitutes: [],
  };

  for (let routeDriver of route?.routeDrivers || []) {
    if (routeDriver.Titular === '0') {
      const routeDriverSubstitute: RouteDriverRM = getRouteDriver(routeDriver);
      routeDriverByType.substitutes.push(routeDriverSubstitute);
    } else {
      routeDriverByType.holder = getRouteDriver(routeDriver);
    }
  }

  return routeDriverByType;
};

const getRouteDriver = (routeDriver: RouterDriverByRoute): RouteDriverRM => {
  return {
    enable: routeDriver?.Enable > 0,
    name: routeDriver?.Name || '',
    routeDriverId: routeDriver?.RouteDriverID,
  };
};

const getMachines = (route: RouteVisibility): MachineRM[] => {
  let machines: MachineRM[] = [];

  for (let routeMachine of route?.machines || []) {
    const machine: MachineRM = {
      machineCode: routeMachine?.machine_code,
      machineId: routeMachine?.machine_id,
    };

    machines.push(machine);
  }

  return machines;
};


const getOperator = (route: RouteVisibility): OperatorRM  => {
  return {
    operator: route?.operator,
    operatorId: route?.operator_id
  }
}
