import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { ColorChangeButtonComponent } from './color-change-button.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
  ],
  declarations: [
    ColorChangeButtonComponent
  ],
  exports: [
    ColorChangeButtonComponent
  ],
})
export class ColorChangeButtonModule {}

