import { WritableSignal } from '@angular/core';

// models
import { Client } from 'src/app/models';

interface GetClientsByOperatorRM {
  clients: WritableSignal<Client[]>;
  loadingClients: WritableSignal<boolean>;
  clientsByOperator: WritableSignal<Client[]>;
  operatorId: number;
}

export const getClientsByOperatorRM = ({
  clients,
  loadingClients,
  clientsByOperator,
  operatorId,
}: GetClientsByOperatorRM) => {
  if ((clients() || []).length === 0 || loadingClients()) {
    clientsByOperator.set([]);
    return;
  }

  const clientFiltered = clients().filter(
    (client) => client.OperatorId === operatorId
  );


  clientsByOperator.set(clientFiltered);
};
