// models
import {
  Report,
  TypesSelectedReport,
  VisibilitySelectedReport,
} from '../../models';

// services
import { FilterReportService } from '../../services';

// utils
import { showReportCards } from '../show-report-cards';

export const setVisibilityByReportSelected = (
  report: Report,
  _filterReportService: FilterReportService
) => {

  const visibility: VisibilitySelectedReport = {
    operatorGroupId: report?.OperatorGroup?.id,
    operatorId: report?.Operator?.id,
    clientId: report?.Client?.id || null,
    tillId: report?.Till?.id || null,
    cards: showReportCards(report?.typeReport?.typeReport || '')
      ? report?.cards || []
      : [],
  };

  _filterReportService.setVisibility(
    visibility,
    TypesSelectedReport.createEdit
  );

};
