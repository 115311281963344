import { Component, OnInit, Input } from '@angular/core';
import { IntervalCard } from '../../../../../models';
import { Company } from 'src/app/models';
import { SkinMangementService } from 'src/app/services';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-created-intervals-card-pc',
  templateUrl: './created-intervals-card-pc.component.html',
  styleUrls: ['./created-intervals-card-pc.component.scss'],
})
export class CreatedIntervalsCardPcComponent implements OnInit {

  @Input() intervals: IntervalCard[] = []

  company: Company;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.intervals[0].posStart
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
