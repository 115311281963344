import { WritableSignal } from '@angular/core';

// models
import {
  Report,
  FilterStatusOptionsReport,
  VisibilityReport,
} from '../../models';

// utils
import { setTotalsActiveReports } from '../chart';
import { getReportsByVisibility } from './get-reports-by-visibility';
import { getReportsByPeriodicityFilter } from './get-reports-by-periodicity-filter';
import { getReportsByStatusFilter } from './get-reports-by-status-filter';
import { getReportsByTypeReportFilter } from './get-reports-by-type-report-filter';

interface UseFilterReport {
  reports: WritableSignal<Report[]>;
  statusSelected: WritableSignal<FilterStatusOptionsReport>;
  filterVisibility: VisibilityReport;
  useFiterVisibility: WritableSignal<boolean>;
  periodSelected: WritableSignal<number>;
  totalActive: WritableSignal<number>;
  totalInactive: WritableSignal<number>;
  useFilterTypeReport: WritableSignal<boolean>;
  typeReportFilteredSelected: WritableSignal<number>;
}

export const useFilterReport = ({
  reports,
  statusSelected,
  filterVisibility,
  useFiterVisibility,
  periodSelected,
  useFilterTypeReport,
  typeReportFilteredSelected,
  totalActive,
  totalInactive,
}: UseFilterReport) => {

    getReportsByVisibility({ reports, filterVisibility, useFiterVisibility });

    getReportsByPeriodicityFilter({reports, periodSelected})

    getReportsByTypeReportFilter({reports, useFilterTypeReport, typeReportFilteredSelected})

    setTotalsActiveReports({reports: reports(), totalActive, totalInactive})

    getReportsByStatusFilter({reports, statusSelected})

};
