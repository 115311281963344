import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';

// components
import { HoursPickerModalComponent } from '../hours-picker-modal/hours-picker-modal.component';

// models
import { Company } from 'src/app/models';

@Component({
  selector: 'app-filter-hour-sm',
  templateUrl: './filter-hour-sm.component.html',
  styleUrls: ['./filter-hour-sm.component.scss'],
})
export class FilterHourSmComponent implements OnInit {

  @Input() hourPick1;
  @Input() hourPick2;
  @Input() company: Company;
  @Input() onlyOneHour: boolean = false;
  @Input() showTextHourSm: boolean = true;

  @Output() hourSelected = new EventEmitter<{
    hourStart: string;
    hourEnd: string;
  }>();

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  async showSelectHour() {

    let modal = await this.modalCtrl.create({
      component: HoursPickerModalComponent,
      componentProps: {
        hourPick1: this.hourPick1,
        hourPick2: this.hourPick2,
        company: this.company,
        onlyOneHour: this.onlyOneHour,
      },
    });
    if (modal) {
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data?.updated) {
        this.hourPick1 = data?.hourStart;
        this.hourPick2 = data?.hourEnd;
        this.hourSelected.emit({
          hourStart: data?.hourStart,
          hourEnd: data?.hourEnd,
        });
      }
    }

  }
}
