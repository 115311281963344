import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// Config
import { AppConfig } from '../../config/config';

// Service
import { PermissionService } from '../../services/permission.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {

  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor( private _permissionService: PermissionService,
    private httpClient: HttpClient,
    public _router: Router ) {}

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean  {
    return this._permissionService.permissionValidate(state.url);
  }

}
