import { WritableSignal } from '@angular/core';

// models
import { DayWeekMachineStatusAlert } from '../../models';

// services
import { TranslateService } from '@ngx-translate/core';
import { MachineAlertInfoStartedService } from '../../services';

// utils
import { WEEK_MAX_DAYS } from '../constant';
import { getWekDaysTextMachineAlerts } from '../date';
import { checkEventWeekDayMachineAlert } from './check-event-weekday-machine-alert';

interface StartWeekDaysMachineAlert {
  translateService: TranslateService;
  _machineAlertInfoService: MachineAlertInfoStartedService;
  daysWeek: WritableSignal<Record<DayWeekMachineStatusAlert, boolean>>;
  isIndeterminateDaysWeek: WritableSignal<boolean>;
  masterCheckDaysWeek: WritableSignal<boolean>;
  weekDaysText: WritableSignal<string>;
  daysWeekOrder: DayWeekMachineStatusAlert[];
}

export const startWeekDaysMachineAlert = async ({
  translateService,
  _machineAlertInfoService,
  daysWeek,
  isIndeterminateDaysWeek,
  masterCheckDaysWeek,
  weekDaysText,
  daysWeekOrder,
}: StartWeekDaysMachineAlert) => {
  const weekDaysEdit: number[] = _machineAlertInfoService.getWeekDays();

  let dayWeekSelected: Record<
    DayWeekMachineStatusAlert,
    boolean
  > = setDayWeekSelected(weekDaysEdit);
  daysWeek.set(dayWeekSelected);

  await setWeekDaysTranslate({
    translateService,
    daysWeek,
    daysWeekOrder,
    weekDaysText,
  });

  checkEventWeekDayMachineAlert({
    daysWeek,
    daysWeekOrder,
    isIndeterminate: isIndeterminateDaysWeek,
    masterCheck: masterCheckDaysWeek
  })
};

const setDayWeekSelected = (
  weekDaysEdit: number[]
): Record<DayWeekMachineStatusAlert, boolean> => {
  if (weekDaysEdit.length === 0 || weekDaysEdit.length === WEEK_MAX_DAYS) {
    return {
      [DayWeekMachineStatusAlert.sunday]: true,
      [DayWeekMachineStatusAlert.monday]: true,
      [DayWeekMachineStatusAlert.tuesday]: true,
      [DayWeekMachineStatusAlert.wednesday]: true,
      [DayWeekMachineStatusAlert.thursday]: true,
      [DayWeekMachineStatusAlert.friday]: true,
      [DayWeekMachineStatusAlert.saturday]: true,
    };
  }

  const DAY_WEEK_POS: Record<number, DayWeekMachineStatusAlert> = {
    0: DayWeekMachineStatusAlert.sunday,
    1: DayWeekMachineStatusAlert.monday,
    2: DayWeekMachineStatusAlert.tuesday,
    3: DayWeekMachineStatusAlert.wednesday,
    4: DayWeekMachineStatusAlert.thursday,
    5: DayWeekMachineStatusAlert.friday,
    6: DayWeekMachineStatusAlert.saturday,
  };

  let daysSelected: Record<DayWeekMachineStatusAlert, boolean> = {
    [DayWeekMachineStatusAlert.sunday]: false,
    [DayWeekMachineStatusAlert.monday]: false,
    [DayWeekMachineStatusAlert.tuesday]: false,
    [DayWeekMachineStatusAlert.wednesday]: false,
    [DayWeekMachineStatusAlert.thursday]: false,
    [DayWeekMachineStatusAlert.friday]: false,
    [DayWeekMachineStatusAlert.saturday]: false,
  };

  for (let i of weekDaysEdit) {
    if (DAY_WEEK_POS?.[i] || DAY_WEEK_POS?.[i] === 0) {
      const dayWeekEdit: DayWeekMachineStatusAlert = DAY_WEEK_POS?.[i];
      daysSelected[dayWeekEdit] = true;
    }
  }

  return daysSelected;
};

interface SetWeekDaysTranslate {
  translateService: TranslateService;
  daysWeek: WritableSignal<Record<DayWeekMachineStatusAlert, boolean>>;
  daysWeekOrder: DayWeekMachineStatusAlert[];
  weekDaysText: WritableSignal<string>;
}

const setWeekDaysTranslate = async ({
  translateService,
  daysWeek,
  daysWeekOrder,
  weekDaysText,
}: SetWeekDaysTranslate) => {
  await getWekDaysTextMachineAlerts({
    _translateService: translateService,
    daysWeek: daysWeek,
    daysWeekOrder: daysWeekOrder,
    weekDaysText: weekDaysText,
  });
};
