import { TranslateService } from '@ngx-translate/core';

// models
import { PeriodicityInfo } from '../';
import { PeriocityOptionReport } from '../enums';

// utils
import { PERIODICITY_REPORT_TRANSLATES } from '../../utils';

export class Periodicity {

  periocities: PeriodicityInfo[] = [
    { Periodicity: 'Daily', Name: 'Daily', ID: 1 },
    { Periodicity: 'Weekly', Name: 'Weekly', ID: 2 },
    { Periodicity: 'Monthly', Name: 'Monthly', ID: 3 },
    { Periodicity: 'Yearly', Name: 'Yearly', ID: 4 },
  ];

  // translatesNames = ['reports.daily', 'reports.weekly','reports.monthly', 'reports.yearly']

  translatesNames = PERIODICITY_REPORT_TRANSLATES;

  constructor(private translate: TranslateService) {}

  getPeriocities(): PeriodicityInfo[]{
    return [
      {
        Periodicity: 'Daily',
        Name: this.translate.instant(this.translatesNames[0]),
        ID: PeriocityOptionReport.Daily,
      },
      {
        Periodicity: 'Weekly',
        Name: this.translate.instant(this.translatesNames[1]),
        ID: PeriocityOptionReport.Weekly,
      },
      {
        Periodicity: 'Monthly',
        Name: this.translate.instant(this.translatesNames[2]),
        ID: PeriocityOptionReport.Monthly,
      }
    ];
  }
}
