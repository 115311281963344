// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  width: 100%;
  align-items: center;
}
:host .type-toggle {
  padding-inline-start: 0.5rem;
}
:host .type-toggle .show-option {
  color: gray;
}
:host .type-toggle .hide-option {
  font-size: 1.2rem;
  display: block;
  color: gray;
}
:host .type-toggle .hide-option:not(ion-icon) {
  text-transform: uppercase;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/show-hide-password/show-hide-password.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,mBAAA;AACJ;AAAE;EACI,4BAAA;AAEN;AADE;EACE,WAAA;AAGJ;AADM;EACE,iBAAA;EACA,cAAA;EACA,WAAA;AAGR;AADQ;EACE,yBAAA;EACA,eAAA;AAGV","sourcesContent":[":host {\r\n    display: flex;\r\n    width: 100%;\r\n    align-items: center;\r\n  .type-toggle {\r\n      padding-inline-start: 0.5rem;\r\n  .show-option{\r\n    color: gray\r\n  }\r\n      .hide-option {\r\n        font-size: 1.2rem;\r\n        display: block;\r\n        color: gray;\r\n  // In case you want to use text instead of icons\r\n        &:not(ion-icon) {\r\n          text-transform: uppercase;\r\n          font-size: 1rem;\r\n        }\r\n      }\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
