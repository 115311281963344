import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import write_blob from "capacitor-blob-writer";
import { isApkAndroid } from './useDomain';

// utils
import { UtilsTranslate } from './utils-translate';

export const writeFileApk = async (path: string, data: string) => {
  await Filesystem.writeFile({
    path,
    data,
    directory: Directory.Documents,
    encoding: Encoding.UTF8,
  });
};

export const writeFileApkbyBlob = (path: string, blob: Blob, utils: UtilsTranslate)=> {
  write_blob({
    blob,
    path,
    directory: Directory.Documents,
  }).then( () => {
      utils.presentToastWithVariable('save_file.save_successfully', 'fileName', path, false)
    }).catch((error) => {
      utils.presentToastTranslate('save_file.save_error', true, 0)
      // Mostrar en test
      //window.alert(error)
      //console.error(error)
  });
}

export const getTargetByPlatform = (platform: Platform): '_self' | '_blank' => {
  return isApkAndroid(platform) ? '_self' : '_blank';
}
