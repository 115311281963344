import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// models
import { FilterDateServiceI } from 'src/app/models';
import { checkSegments } from 'src/app/pages/user/transactions/transactions-query/models';

@Injectable({
  providedIn: 'root'
})
export class FilterDateInModalService implements FilterDateServiceI {

  checkSegment: checkSegments = 'date';
  dateStart: string = null;
  dateEnd: string = null;
  hourEnd: string = null;
  hourStart: string = null;

  // emite los cambios del filtro
  filterChange$: Subject<Object[]> = new Subject<Object[]>();

  // clear
  reset$: Subject<Object[]> = new Subject<Object[]>();

  constructor() { }

  getDateStart(){
    if (!this.dateStart) {
      let dateFull = new Date();
      this.dateStart = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;
    }
    return this.dateStart;
  }

  getDateEnd() {
    if (!this.dateEnd) {
      let dateFull = new Date();
      this.dateEnd = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;
    }
    return this.dateEnd;
  }


  getCheckSegment(){
    return this.checkSegment;
  }

  setCheckSegment(newType: checkSegments) {
    if (newType != this.checkSegment) {
      this.checkSegment = newType;
      if (this.checkSegment == 'date') {
        this.filterChange$.next([
          { checkSegment: this.checkSegment },
          { dateStart: this.dateStart },
          { hourStart: this.hourStart },
          { hourEnd: this.hourEnd },
        ]);
      } else {
        this.filterChange$.next([
          { checkSegment: this.checkSegment },
          { dateStart: this.dateStart },
          { dateEnd: this.dateEnd },
        ]);
      }
    }
  }

  setDate(dateNew, datePos) {
    let dateFull = new Date(dateNew);
    dateFull = new Date(dateFull.setHours(dateFull.getHours() + 2));
    if (datePos == 0) {
      this.dateStart = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;

      this.filterChange$.next([{ dateStart: this.dateStart }]);
    } else {
      this.dateEnd = `${dateFull.getFullYear()}-${
        dateFull.getMonth() + 1
      }-${dateFull.getDate()}`;
      this.filterChange$.next([{ dateEnd: this.dateEnd }]);
    }
  }

  getStartHour() {
    if (!this.hourStart) {
      this.hourStart = `00:00`;
    }
    return this.hourStart;
  }

  getEndHour() {
    if (!this.hourEnd) {
      this.hourEnd = '24:00';
    }
    return this.hourEnd;
  }

  setHour(hour, posHour) {
    if (posHour == 0) {
      this.hourStart = hour;
      this.filterChange$.next([{ hourStart: this.hourStart }]);
    } else {
      this.hourEnd = hour;
      this.filterChange$.next([{ hourEnd: this.hourEnd }]);
    }
  }

  getDate() {
    if (this.checkSegment == 'period') {
      return {
        datainici: this.dateStart,
        datafinal: this.dateEnd,
      };
    } else {
      return {
        datainici: this.dateStart,
      };
    }
  }

  clear(){
    this.dateStart = null;
    this.dateEnd = null;
    this.hourEnd = null;
    this.hourStart = null;
  }

}
