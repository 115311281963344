import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';


// Service
import { SkinMangementService } from '../../../../../../../../services/skin-mangement.service';

// Models
import { Company } from 'src/app/models/';
import { ImageSite, IconSite } from '../../../../../site/models';

// Utils
import {
  iconsSite,
  imagesSite,
  imagesSiteElora,
  type,
} from '../../../../../../../../utils/constants';

@Component({
  selector: 'app-legend-site',
  templateUrl: './legend-site.component.html',
  styleUrls: ['./legend-site.component.scss'],
})
export class LegendSiteComponent implements OnInit {
  images: { [x: string]: ImageSite } = imagesSite;
  icons: { [x: string]: IconSite } = iconsSite;

  // aspecto
  company: Company;
  type = type;

  isSiteElora = false;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
    private router: Router
  ) {}

  ngOnInit() {
    this.company = this._skinService.getCompany();

    this.router.url === '/user/site-elora'
      ? (this.images = imagesSiteElora)
      : (this.images = imagesSite);

    this.isSiteElora = this.router.url === '/user/site-elora';
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }
}
