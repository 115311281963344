import { ForceInventoryStockParams } from "../models";
import { InfoInventoryManagerService } from "../services";

export const getForceParamsInvManager = (
  _infoService: InfoInventoryManagerService
): ForceInventoryStockParams  => {

  if(!_infoService.visibilitySelected()){
    return null;
  }

  if(_infoService.isSupervisorFilter()){
    return {
      SupervisorID: _infoService.visibilitySelected().supervisor?.id
    }
  }

  if(_infoService.isClientFilter()){
    return {
      ClientID: _infoService.visibilitySelected().client?.id
    }
  }

  if(_infoService.isMachineFilter()){
    return {
      MachineID: _infoService.visibilitySelected().machine?.id
    }
  }

  return null;

}
