import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

// Config
import { AppConfig } from 'src/app/config/config';

// Services
import { PermissionService } from 'src/app/services';


@Injectable({
  providedIn: 'root'
})
export class NotCompanyVisibilityGuard  {

  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private _permissionService: PermissionService,
    public _router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this._permissionService.notCompanyVisibility();
  }
  
}
