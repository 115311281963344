// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-align-info-success {
  display: flex;
}

.mt-2 {
  margin-top: 2px;
}

.color-success {
  background-color: var(--ion-color-success);
}

.mr-5 {
  margin-right: 5px;
}

.rounded {
  border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/ui/info-success-alert/info-success-alert.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,0CAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".d-align-info-success{\r\n  display: flex;\r\n}\r\n\r\n.mt-2{\r\n  margin-top: 2px;\r\n}\r\n\r\n.color-success {\r\n  background-color: var(--ion-color-success);\r\n}\r\n\r\n.mr-5{\r\n  margin-right: 5px;\r\n}\r\n\r\n.rounded{\r\n  border-radius: 20px\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
