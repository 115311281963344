import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ElementRef,
  WritableSignal,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// service
import { TranslateService } from '@ngx-translate/core';
import {
  FilterDateExtraHourService,
  FilterDateFutureService,
  FilterDateService,
  LanguageRouteService,
} from 'src/app/share/services';
import { SkinMangementService } from '../../../../../services';
import { FilterTransactionsQueryService } from 'src/app/pages/user/transactions/transactions-query/services';
import { MatLanguageService } from 'src/app/share/services/mat-language/mat-language.service';

// models
import { Company, FilterDateServiceI } from 'src/app/models';

// utils
import { pcWidth } from 'src/app/utils';
import { getFilterDateService } from '../../helpers';

import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-filter-double-hour-selected',
  templateUrl: './filter-double-hour-selected.component.html',
  styleUrls: ['./filter-double-hour-selected.component.scss'],
})
export class FilterDoubleHourSelectedComponent implements OnInit {
  @Input() showHour: boolean = true;

  @Input() showTextHourComplete: boolean = true;
  @Input() showHourPeriod: boolean = true;
  @Input() showOptionExtraHour: boolean = true;
  @Input() haveExtraHour: WritableSignal<boolean>;

  startHour: string;
  endHour: string;

  startHourExtra: string;
  endHourExtra: string;

  url: string = '';
  filterDateId: string = null;

  company: Company;

  _filterDateTemplateService: FilterDateServiceI;

  pcWidth = pcWidth;

  changeLanguage$: Subscription;
  filterChange$: Subscription;
  clearFilter$: Subscription;
  filterChangeExtraHour$: Subscription;

  versionDate;

  waitChangeInvalidDate: boolean = false;

  constructor(
    public readonly platform: Platform,
    private router: Router,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinMangementService: SkinMangementService,
    private _filterTransactionQueryService: FilterTransactionsQueryService,
    private _filterDateService: FilterDateService,
    private _filterDateFutureService: FilterDateFutureService,
    public readonly _filterDateExtraHourService: FilterDateExtraHourService,
    private _matLanguageService: MatLanguageService
  ) {
    this.url = this.router.url;
    this.versionDate = uuidv4();

    this._filterDateTemplateService = getFilterDateService({
      routeUrl: this.router.url,
      _filterDateService: this._filterDateService,
      _filterTransactionQueryService: this._filterTransactionQueryService,
      _filterDateFutureService: this._filterDateFutureService,
    });
  }

  ngOnInit() {
    this.company = this._skinMangementService.getCompany();

    this.getCurrent();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(
      (valor) => {
        this.translate.setDefaultLang(valor);
        this.savePlaceholders();
      }
    );

    this.getDates();
    this.getExtraHour();
  }

  getCurrent() {
    this.startHour = this._filterDateTemplateService.getStartHour();
    this.endHour = this._filterDateTemplateService.getEndHour();
    this.startHourExtra = this._filterDateExtraHourService.getStartHour();
    this.endHourExtra = this._filterDateExtraHourService.getEndHour();
  }

  getDates() {
    if (!this.filterChange$) {
      this.filterChange$ = this._filterDateTemplateService.filterChange$.subscribe(
        () => {
          this.startHour = this._filterDateTemplateService.getStartHour();
          this.endHour = this._filterDateTemplateService.getEndHour();
        }
      );
    }

    if (!this.clearFilter$) {
      this.clearFilter$ = this._filterDateTemplateService.reset$.subscribe(
        () => {
          if (this.url === this.router.url) {
            this.startHour = this._filterDateTemplateService.getStartHour();
            this.endHour = this._filterDateTemplateService.getEndHour();
          }
        }
      );
    }
  }

  ngOnDestroy() {
    if (this.changeLanguage$) this.changeLanguage$.unsubscribe();
    if (this.filterChange$) this.filterChange$.unsubscribe();
    if (this.clearFilter$) this.clearFilter$.unsubscribe();
    if (this.filterChangeExtraHour$) this.filterChangeExtraHour$.unsubscribe();
  }

  savePlaceholders() {
    this.translate.get(['locale']).subscribe((translates) => {
      this._matLanguageService.setLanguage(translates['locale']);
    });
  }

  addTimeSlot() {
    this.haveExtraHour.set(!this.haveExtraHour());
  }

  getExtraHour() {
    if (!this.filterChangeExtraHour$) {
      this.filterChangeExtraHour$ = this._filterDateExtraHourService.filterChange$.subscribe(
        () => {
          this.startHourExtra = this._filterDateExtraHourService.getStartHour();
          this.endHourExtra = this._filterDateExtraHourService.getEndHour();
        }
      );
    }
  }
}
