// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-text {
  font-size: 1.3em;
}

.text-indent-10 {
  text-indent: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/legends/licenses-vatg-legend/licenses-vatg-legend.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".font-text{\r\n  font-size: 1.3em;\r\n}\r\n\r\n.text-indent-10{\r\n  text-indent: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
