// models
import { CardNames, Company } from 'src/app/models';
import { StepCreateEditReport } from '../../../../models';

// services
import {
  CardsByTextReportService,
  FilterReportService,
  ReportsService,
} from '../../../../services';

// utils
import { UtilsTranslate } from 'src/app/utils';
import { usePreviouslyValidatedCardTextReport } from './use-previously-validated-card-text-report';
import { validateCardsTextReport } from './validate-cards-text-report';

interface IsValidCheckCardsByTextReport {
  company: Company;
  _reportService: ReportsService;
  _filterReportService: FilterReportService;
  _cardsByTextReportService: CardsByTextReportService;
  showerStep: Record<StepCreateEditReport, boolean>;
  utils: UtilsTranslate;
}

export const isValidCheckCardsByTextReport = async ({
  company,
  _reportService,
  _filterReportService,
  _cardsByTextReportService,
  showerStep,
  utils,
}: IsValidCheckCardsByTextReport): Promise<boolean> => {
  if (
    usePreviouslyValidatedCardTextReport({
      _filterReportService,
      _cardsByTextReportService,
    })
  ) {
    if (!_cardsByTextReportService.isValidCardText()) {
      utils.presentToastTranslate(
        company.nameCard === CardNames.arbitradePay
          ? 'reports.create_edit.error_invalid_card_alliancepay'
          : 'reports.create_edit.error_invalid_card'
      );
    }
    return _cardsByTextReportService.isValidCardText();
  }

  await validateCardsTextReport({
    company,
    _reportService,
    _filterReportService,
    _cardsByTextReportService,
    showerStep,
    utils,
  });

  return _cardsByTextReportService.isValidCardText();
};
