import { Pipe, PipeTransform } from '@angular/core';

// utils
import { replaceNameCard, replaceNameCardPlural } from 'src/app/utils';

@Pipe({
  name: 'cardName',
})
export class CardNamePipe implements PipeTransform {
  transform(value: string, isPlural: boolean = false): string {
    if (!value) {
      return value;
    }

    return isPlural ? replaceNameCardPlural(value) : replaceNameCard(value);
  }
}
