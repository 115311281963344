import { IonicSafeString, ToastController } from '@ionic/angular';

// models
import { Company } from '../models';

// utils
import {
  durationErrorToast,
  durationSuccessToast,
  durationWarningToast,
} from './constants';

interface PresentToast {
  message: string;
  toastController: ToastController;
  company: Company;
  duration?: number;
}

// message, toastController: ToastController, duration: number = durationErrorToast

export const presentToast = async ({
  message,
  toastController,
  duration = durationErrorToast,
  company,
}: PresentToast) => {
  // company only color, islightColor
  const toast = await toastController.create({
    message: new IonicSafeString(message),
    duration,
    position: 'top',
    color: company.colorErrorToast,
    cssClass: 'present-toast',
    icon: 'alert-circle-outline',
  });

  if (toast) {
    toast.present();
  }
};

export const successToast = async (
  message,
  toastController: ToastController,
  duration: number = durationSuccessToast
) => {
  if (!duration) {
    duration = durationSuccessToast;
  }
  const toast = await toastController.create({
    message: new IonicSafeString(`${message}`),
    duration,
    position: 'top',
    icon: 'checkmark-circle-outline',
    color: 'success',
  });

  if (toast) {
    toast.present();
  }
};

export const warningToast = async (
  message,
  toastController: ToastController,
  duration: number = durationWarningToast
) => {
  const toast = await toastController.create({
    message: new IonicSafeString(
      `<ion-icon name="warning-outline"></ion-icon>  ${message}`
    ),
    duration,
    position: 'top',
    color: 'warning',
    icon: 'warning-outline',
  });

  if (toast) {
    toast.present();
  }
};
