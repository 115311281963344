// models
import { FilterTableTillAlert, TillAlertSpiderTable } from '../../models';

// utils
import { removeDuplicateObject } from 'src/app/utils';
import { getTillAlertsByFilter } from './get-till-alerts-by-filter';

export const getTillAlertFilterMachine = (
  tillsAlerts: TillAlertSpiderTable[],
  filtersTable: FilterTableTillAlert
) => {
  let filterTableWithTypeFilter = getTillAlertsByFilter(
    tillsAlerts,
    filtersTable,
    'machine'
  );

  let tills = filterTableWithTypeFilter.map((tillAlert) => ({
    machine: tillAlert?.machine.machineCode,
    machineId: tillAlert?.machine.machineId,
  }));

  return removeDuplicateObject(tills, 'machineId');
};
