import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Service
import { SkinMangementService } from '../../../../../../services';

// Models
import { Company } from '../../../../../../models/';

// Utils
import { type } from 'src/app/utils/constants';


@Component({
  selector: 'app-legend-qr-request',
  templateUrl: './legend-qr-request.component.html',
  styleUrls: ['./legend-qr-request.component.scss'],
})
export class LegendQrRequestComponent implements OnInit {

  // aspecto
  company: Company;
  type = type;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService
  ) { }

  ngOnInit() {
    this.company = this._skinService.getCompany();
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }

}
