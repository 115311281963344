import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ParamsFiltersCards } from 'src/app/models';
import { ParamsGetCardByCardNum } from 'src/app/models/Card.model';
import { CardService } from '../../../services/card/card.service';
import { UtilsTranslate } from '../../../../utils/utils-translate';

@Injectable({
  providedIn: 'root'
})
export class FilterCardNumService {


  cardNum: string = '';
  cardId = null;

  cardNum2: string = '';
  cardId2: number = null;

  haveBankCardOption: boolean = false;

  filterChange: Subject<CardFilter> = new Subject<CardFilter>();

  constructor(
    private _cardService: CardService,
    private utils: UtilsTranslate
    ) { }

  getCard(positionCardNum: number = 0): CardFilter {
    if (positionCardNum == 0) {
      return { cardNum: this.cardNum, cardId: this.cardId, haveBankCardOption: this.haveBankCardOption }
    } else {
      return { cardNum: this.cardNum2, cardId: this.cardId2, haveBankCardOption: this.haveBankCardOption }
    }

  }

  getCardNum(positionCardNum: number = 0): string {
    return (positionCardNum == 0) ? this.cardNum : this.cardNum2
  }

  getCardId(positionCardNum: number = 0) {
    return (positionCardNum == 0) ? this.cardId : this.cardId2
  }

  setCard(cardNum: string, cardId: number, positionCardNum: number = 0) {

    if (positionCardNum == 0) {
      this.cardNum = cardNum;
      this.cardId = cardId;
      this.haveBankCardOption = false;

      this.filterChange.next({
        cardNum: this.cardNum, cardId: this.cardId, haveBankCardOption: this.haveBankCardOption,
        positionCardNum
      })
    } else {
      this.cardNum2 = cardNum;
      this.cardId2 = cardId;
      this.haveBankCardOption = false;

      this.filterChange.next({
        cardNum: this.cardNum2, cardId: this.cardId2,
        haveBankCardOption: this.haveBankCardOption, positionCardNum
      })
    }

  }


  resetCard(positionCardNum: number = 0) {
    if (positionCardNum == 0) {
      this.cardNum = '';
      this.cardId = null;
      //console.log('reset card');
      this.filterChange.next({ cardNum: this.cardNum, cardId: this.cardId, haveBankCardOption: this.haveBankCardOption, positionCardNum })

    } else {
      this.cardNum2 = '';
      this.cardId2 = null;
      this.filterChange.next({
        cardNum: this.cardNum2, cardId: this.cardId2, haveBankCardOption: this.haveBankCardOption,
        positionCardNum
      })

    }

  }

  resetAllCards(){
    this.resetCard();
    this.resetCard(1);
  }


  getCards(params) {
    if (params?.operatorId == -1) {
      delete params.operatorId
    }
    //console.log('params', params)
    return this._cardService.getCards(params);
  }


  getCardByCardNum(params: ParamsGetCardByCardNum){
    if(params?.operatorId === -1){
      delete params.operatorId
    }

    if(!params?.clientId){
      delete params?.clientId
    }

    return this._cardService.getCardByCardnum(params);
  }

  getBankCardOption() {
    if (!this.haveBankCardOption) {
      return 'cardNum';
    }
    else {
      return 'bankCard';
    }

  }

  setCardNum(cardNum: string, id: number, positionCardNum: number = 0) {
    if (positionCardNum == 0) {
      this.cardNum = cardNum;
      this.cardId = id;
      this.haveBankCardOption = false;
      this.filterChange.next({ cardNum: this.cardNum, cardId: this.cardId, haveBankCardOption: this.haveBankCardOption, positionCardNum });
    } else {
      this.cardNum2 = cardNum;
      this.cardId2 = id;
      this.haveBankCardOption = false;
      this.filterChange.next({ cardNum: this.cardNum2, cardId: this.cardId2, haveBankCardOption: this.haveBankCardOption, positionCardNum });
    }
  }

  getBankCard() {
    if (this.haveBankCardOption) {
      return this.cardNum;
    } else {
      return null;
    }
  }


  setBankCard(cardNum) {
    this.cardNum = cardNum;
    this.cardId = null;
    this.haveBankCardOption = true;
    this.filterChange.next({ cardNum: this.cardNum, cardId: this.cardId, haveBankCardOption: this.haveBankCardOption });
  }


  setCardByCardNum(cardNum: string,pos: number, operatorGroupId?: number, operatorId?: number){
    const params: ParamsFiltersCards = {
      cardNum,
      skip: 0
    }
    if(operatorGroupId){
      params.operatorGroupId = operatorGroupId;
    }
    if(operatorId){
      params.operatorId = operatorId;
    }
    this._cardService.getCards(params).subscribe({next: res => {
      if(res && res.length > 0){
        this.setCard(cardNum, res[0].id, pos)
      }else{
        this.setCard('', null, pos)
      }
    }, error: ()=> {
      this.utils.showError()
      this.setCard('', null, pos)
    }})
  }



}

interface CardFilter {
  cardNum: string,
  cardId: number,
  haveBankCardOption?: boolean,
  positionCardNum?: number
}


