// models
import { DeleteMachineAlertParams } from "../../models";

// service
import { PermissionService } from '../../../../../../../services/permission.service';


export const getDeleteMachineAlertsParams = (
  alertId: number,
  _permissionService: PermissionService
): DeleteMachineAlertParams => {

  return {
    alertID: alertId,
    companyID: _permissionService.getUserVisibilityByHierarchy(
      'Company'
    ),
    operatorGroupID: _permissionService.getUserVisibilityByHierarchy(
      'OperatorGroup'
    ),
  };
}
