export interface DashboardTranslates {
  welcome: string;
  refilling: string,
  statusNet: string
}

export const getDashboardRoutes = (
  translates: DashboardTranslates
): { route: string; name: string }[] => {
  return [
    {
      route: '/user/dashboard/welcome',
      name: translates.welcome,
    },
    {
        route: '/user/dashboard/refilling-dashboard',
        name: translates.refilling,
    },
    {
      route: '/user/dashboard/statusnet',
      name: translates.statusNet,
  },
  ];
};
