// models
import { PeriodicityInfo } from '../models';

// utils
import { dateTypes } from '../../../../../utils/plugins/useDate.plugin';

export const MAX_LENGTH_NAME_REPORT: number = 100;

export const DIFF_DAYS_REPORT: number = 365 * 100;

export const MAX_GUIDED_CARD_REPORT: number = 10;

export const TYPE_REPORT_MULTIPLE_CARD: string[] = [
  'Listado de Ventas con tarjeta Cautiva',
];

export const TYPE_REPORT_SHOW_OPERATORGROUP_AND_OPERATOR =  [
  'Listado FreeVend'
]

// export const ONLY_DAYS_MONTH_REPORT: number[] = [1, 20, 26];
export const ONLY_DAYS_MONTH_REPORT: number[] = [1, 26];


export const TIME_DURATION_ERROR_REPORT: number = 8000;
export const TIME_DURATION_MIN_CLOSE_LOADING_BANNER_REPORT: number = 150;

export const PERIODICITY_REPORT_TRANSLATES: string[] = [
  'reports.daily',
  'reports.weekly',
  'reports.monthly',
  'reports.yearly',
];

// ----------- Periodicities -----------

export type PERIODICITIES_REPORT_OPTIONS = 'Daily' | 'Weekly' | 'Monthly';

export const DAILY_PERIODICITY_REPORT: PERIODICITIES_REPORT_OPTIONS = 'Daily';
export const WEEKLY_PERIODICITY_REPORT: PERIODICITIES_REPORT_OPTIONS = 'Weekly';
export const MONTHLY_PERIODICITY_REPORT: PERIODICITIES_REPORT_OPTIONS =
  'Monthly';

export const PERIODICITIES_REPORTS_BY_OPTIONS: Record<
  PERIODICITIES_REPORT_OPTIONS,
  dateTypes
> = {
  Daily: 'days',
  Weekly: 'weeks',
  Monthly: 'months',
};

export const PERIODICITIES_REPORTS: PeriodicityInfo[] = [
  { Periodicity: 'Daily', Name: 'Daily', ID: 1 },
  { Periodicity: 'Weekly', Name: 'Weekly', ID: 2 },
  { Periodicity: 'Monthly', Name: 'Monthly', ID: 3 },
];

export const PERIODICITIES_REPORT_MONTHLY_ID = 3;

export const DURATION_TOAST_ONLY_MONDAY_REPORT = 6000;
