// Elora
export const IMAGE_LOGIN_ELORA_FRONT =
  '../../../assets/images/elora/login_elora_front.jpg';

export const LOGO_ELORA_HORIZONTAL_DARK =
  '../../../assets/images/elora/logo_elora_horizontal_dark.png';
export const LOGO_ELORA_HORIZONTAL_LIGHT =
  '../../../assets/images/elora/logo_elora_horizontal_light.png';

export const LOGO_ELORA_CENTRAL_DARK =
  '../../../assets/images/elora/logo_elora_dark.png';
export const LOGO_ELORA_CENTRAL_LIGHT =
  '../../../assets/images/elora/logo_elora_light.png';
