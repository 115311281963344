// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// modules
import { VisibilitySelectModule } from '../visibility-select/visibility-select.module';

// Component
import { FilterOperatorIndividualComponent } from './filter-operator-individual.component';

// Service
import { LanguageService } from '../../services/languages.service';
import { SkinMangementService } from 'src/app/services';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule,
        HttpClientModule,
        VisibilitySelectModule
    ],
    declarations: [
      FilterOperatorIndividualComponent
    ],
    providers: [
        LanguageService,
        SkinMangementService,
    ],
    exports: [
      FilterOperatorIndividualComponent
    ]
})

export class FilterOperatorIndividualModule { }
