import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Components
import { PeriodNoCalendarModalComponent } from './components/period-no-calendar-modal/period-no-calendar-modal.component';
import { DateNoCalendarModalComponent } from './components/date-no-calendar-modal/date-no-calendar-modal.component';

// Service
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from '../../../services/languageRoute.ts/languagesRoute.services';
import { SkinMangementService } from '../../../../services/skin-mangement.service';
import { FilterDatePeriodNoCalendarService } from './service/filter-date-period-no-calendar.service';
import { ColorModeService } from '../../../../services/color-mode.service';

// Models
import { Company } from '../../../../models/';


// Utils
import { type, pcWidth } from '../../../../utils/constants';



@Component({
  selector: 'app-date-period-no-calendar',
  templateUrl: './date-period-no-calendar.component.html',
  styleUrls: ['./date-period-no-calendar.component.scss'],
})
export class DatePeriodNoCalendarComponent implements OnInit {


  @Input() onlyPeriod = false;
  @Input() onlyDate: boolean = false
  @Input() maxDatePeriod: Date = null
  @Input() minDate:string = ''

  // aspecto
  company: Company;
  type = type;

  defaultDate;
  startDate;
  endDate;


  startDateB;
  endDateB;

  pcWidth = pcWidth;

  checkSegment: CheckSegment = 'date';
  locale:string = ''

  datePipeMonthYear = 'M-y';
  localeRegister = 'es';

  filterChangeDateNoCalendar$: Subscription;
  changeLanguage$: Subscription;

  constructor(
    private _languageRouteService: LanguageRouteService,
    private translate: TranslateService,
    private _skinService: SkinMangementService,
    public readonly platform: Platform,
    private modalCtrl: ModalController,
    private _filterDateNoCalendarService: FilterDatePeriodNoCalendarService,
    private _colorModeService: ColorModeService
  ) { }

  ngOnInit() {

    // this.minDate= '2021-01-21';
    this.company = this._skinService.getCompany();

    this.getInfoOnChangeResolution()

    this.defaultDate = this._filterDateNoCalendarService.getDefaultDate()
    this._filterDateNoCalendarService.setDateParams(this.defaultDate)

    this.filterChangeDateNoCalendar$ = this._filterDateNoCalendarService.filterChange.subscribe(res => {
      if (res.reset) {
        this.startDate = this._filterDateNoCalendarService.getDateStart();
        this.endDate = this._filterDateNoCalendarService.getDateEnd();
        this.startDateB = this.startDate;
        this.endDateB = this.endDate;
        this.checkSegment = 'date';
      }
    })

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.locale = valor
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });

  }

  ngOnDestroy() {
    if(this.filterChangeDateNoCalendar$) this.filterChangeDateNoCalendar$.unsubscribe()
    if(this.changeLanguage$) this.changeLanguage$.unsubscribe()
  }

  changePeriod() {

    if(this.checkSegment === 'period' && this.maxDatePeriod){

      if(new Date(this.startDateB.toString().replace(/-/g, "/")).getTime() - this.maxDatePeriod.getTime() > 0){
        this.startDateB = this.maxDatePeriod;
      }
      if(new Date(this.endDateB.toString().replace(/-/g, "/")).getTime() - this.maxDatePeriod.getTime() > 0){
        this.endDateB = this.maxDatePeriod;
      }
    }

    this._filterDateNoCalendarService.setCheckSegment((this.checkSegment))

  }



  getInfoOnChangeResolution() {

    let segment = this._filterDateNoCalendarService.getSegment()

    if (segment) {
      this.checkSegment = 'period'
    }
    else{
      this.checkSegment = 'date'
    }

    //date
    if (!this._filterDateNoCalendarService.getDate()) {
      this.startDate = this._filterDateNoCalendarService.getDateStart();
      this.endDate = this._filterDateNoCalendarService.getDateEnd();
    }
    else {
      this.startDate = this._filterDateNoCalendarService.getStartDateFormated()
      this.endDate = this._filterDateNoCalendarService.getDateParamsEnd();
    }
    //period
    if (!this._filterDateNoCalendarService.getStartPeriod()) {
      this.startDateB = this._filterDateNoCalendarService.getDateStart()
    }
    else{
      this.startDateB = this._filterDateNoCalendarService.getPeriodStartFormated()
    }
    if (!this._filterDateNoCalendarService.getStartPeriod()) {
      this.endDateB = this._filterDateNoCalendarService.getCurrentDayWithDay()
    }
    else {
      this.endDateB = this._filterDateNoCalendarService.getPeriodEndFormated()
    }

  }

  async openDate() {
    const isLightColor = this._colorModeService.getIsLightColor();
    let modal = await this.modalCtrl.create({
      component: DateNoCalendarModalComponent,
      componentProps: {
        minDate: this.minDate,
        locale: this.locale
       },
      cssClass: (this.platform.width() > pcWidth ) ?
          `modal-height-450 modal-border-radius  ${!isLightColor ? 'modal-border-dark' : ''}`
           : ''
    });
    if (modal) {
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data?.date){
        this.startDate = data.date
      }
    }
  }

  async openPeriod() {
    const isLightColor = this._colorModeService.getIsLightColor();
    let modal = await this.modalCtrl.create({
      component: PeriodNoCalendarModalComponent,
      componentProps: {
        minDate: this.minDate,
        locale: this.locale
      },
      cssClass: (this.platform.width() > pcWidth )
          ? `modal-height-500 modal-border-radius ${!isLightColor ? 'modal-border-dark' : ''}`
          : ''
    });
    if (modal) {
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data?.startPeriod && data?.endPeriod){
        this.startDateB = data.startPeriod
        this.endDateB = data.endPeriod
      }
    }
  }

  savePlaceholders() {
    //this.changeLanguage()
    this.translate.get(['format_pipe_month_year', 'locale_register']).subscribe(translates =>{
      this.datePipeMonthYear = translates['format_pipe_month_year']
      this.localeRegister = translates['locale_register']
    });
  }

}


type CheckSegment = 'date' | 'period' | 'other' | null
