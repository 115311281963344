import { WritableSignal } from '@angular/core';

// models
import { Report } from '../../models';
import { PeriodicityInfo } from '../../models/periocity-info.model';

// constant
import { PERIODICITIES_REPORTS } from '../constants';

interface GetReportsByPeriodicityFilter {
  reports: WritableSignal<Report[]>;
  periodSelected: WritableSignal<number>;
}
export const getReportsByPeriodicityFilter = ({
  reports,
  periodSelected,
}: GetReportsByPeriodicityFilter) => {
  if (periodSelected() !== null && periodSelected() !== undefined) {
    const periodReportSelected: PeriodicityInfo = PERIODICITIES_REPORTS.find(
      (periodicity) => periodicity.ID === periodSelected()
    );

    if (periodReportSelected) {
      getReportByPeriodSelected(reports, periodReportSelected.Periodicity);
    }
  }
};

const getReportByPeriodSelected = (
  reports: WritableSignal<Report[]>,
  periodName: string
) => {
  reports.set(reports().filter(
    (report) =>
      (report.typeReport.periodicity || '').toLowerCase() ===
      (periodName || '').toLowerCase()
  ));
};
