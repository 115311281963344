import {Injectable} from '@angular/core';
import { DateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class MatLanguageService {

  adapters: Record<string, AdapterCodeAndDay> = {
    'es-ES': {code: 'es-ES', firstDay: 1},
    'en-EN': {code: 'en-EN', firstDay: 0},
    'fr-FR': {code: 'fr-FR', firstDay: 1},
    'it-IT': {code: 'it-IT', firstDay: 1},
  }

  constructor(
    private dateAdapter: DateAdapter<any>
  ) { }

  setLanguage(language: string){
    const newLocale = this.adapters?.[language];

    this.dateAdapter.setLocale(newLocale?.code || 'es-ES')

    this.dateAdapter.getFirstDayOfWeek = () => newLocale?.firstDay || 0
  }
}


interface AdapterCodeAndDay{
  code: string,
  firstDay: number
}
