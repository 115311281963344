import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

// Service
import { FixMenuService } from '../fix-menu/fix-menu.service';

// Utils
import { pcWidth, pcWidthMix } from '../../../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class WidthChangeService {

  changePCWidth$: Subject<sizeWidth> = new Subject<sizeWidth>();
  pcWidth = pcWidth;
  pcWidthMix = pcWidthMix;
  sizeMenu = 280;

  widthChange$: Subscription;

  constructor(private _fixMenu: FixMenuService) {
    this.widthChange$ = this._fixMenu.fixMenuChange.subscribe(res => {
      if(res){
        this.changePCWidth$.next({pcWidth: this.pcWidth + this.sizeMenu, pcWidthMix: this.pcWidthMix + this.sizeMenu})
      }else{
        this.changePCWidth$.next({pcWidth: this.pcWidth, pcWidthMix: this.pcWidthMix})
      }
    })
  }

  ngOnDestroy(): void {
    if(this.widthChange$) this.widthChange$.unsubscribe()
  }

  getPCWidth(){
    return (this._fixMenu.getMenuFix()) ? this.pcWidth + this.sizeMenu : this.pcWidth;
  }

  getPcWidthMix(){
    return (this._fixMenu.getMenuFix()) ? this.pcWidthMix + this.sizeMenu : this.pcWidthMix;
  }
}

interface sizeWidth{
  pcWidth: number,
  pcWidthMix: number
}
