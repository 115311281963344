// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-100 {
  width: 100% !important;
}

.max-width100 {
  max-width: 100% !important;
}

.select-width {
  max-width: 100% !important;
  width: 100% !important;
}

::ng-deep .size-pc {
  --width: 95%;
  --max-width: 400px;
}

::ng-deep .size-sm {
  --width: 100%;
  --max-width: 500px;
}

.not_available {
  font-size: small;
  color: var(--ion-color-danger);
}

.d-align {
  display: flex;
  align-items: center;
}

.mt-button-icon {
  margin-top: 10px;
}

.margin-type-report-info {
  margin-left: 10px;
  margin-right: 15px;
}

.h-200 {
  height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/listings/reports/components/modals/create-edit-report-modal/create-edit-report-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,0BAAA;EACA,sBAAA;AACF;;AAEA;EAEE,YAAA;EACA,kBAAA;AAAF;;AAGA;EAEE,aAAA;EACA,kBAAA;AADF;;AAKA;EACE,gBAAA;EACA,8BAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAMA;EACE,iBAAA;EACA,kBAAA;AAHF;;AAOA;EACE,aAAA;AAJF","sourcesContent":[".w-100{\r\n  width: 100% !important;\r\n}\r\n\r\n.max-width100{\r\n  max-width: 100% !important;\r\n}\r\n\r\n.select-width{\r\n  max-width: 100% !important;\r\n  width: 100% !important;\r\n}\r\n\r\n::ng-deep .size-pc\r\n{\r\n  --width: 95%;\r\n  --max-width: 400px;\r\n}\r\n\r\n::ng-deep .size-sm\r\n{\r\n  --width: 100%;\r\n  --max-width: 500px;\r\n}\r\n\r\n\r\n.not_available{\r\n  font-size: small;\r\n  color: var(--ion-color-danger);\r\n}\r\n\r\n.d-align{\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.mt-button-icon{\r\n  margin-top: 10px;\r\n}\r\n\r\n\r\n.margin-type-report-info{\r\n  margin-left: 10px;\r\n  margin-right: 15px;\r\n}\r\n\r\n\r\n.h-200{\r\n  height: 200px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
