import { Component, OnInit, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

// Service
import { SkinMangementService } from '../../../../../../../../../services/skin-mangement.service';

// Models
import { Company } from 'src/app/models/';
import { IntervalCard } from '../../../../../models/interval-cards.model';


// modal pc con las tarjetas repetidas
// TODO borrar
@Component({
  selector: 'app-created-interval-card-pc',
  templateUrl: './created-interval-card-pc.component.html',
  styleUrls: ['./created-interval-card-pc.component.scss'],
})
export class CreatedIntervalCardPcComponent implements OnInit {

  @Input() intervals: IntervalCard[] = []
  intervalCreated: IntervalCard[] = [];

  company: Company;

  constructor(
    private modalCtrl: ModalController,
    private _skinService: SkinMangementService,
    public readonly platform: Platform
  ) { }

  ngOnInit() {

    this.company = this._skinService.getCompany();

    for(let interval of this.intervals){
      if(interval?.notCreateds?.length > 0 && !interval?.notCreateds?.includes('all') ){
        interval.subIntervals = this.getInterval(interval.notCreateds)
      }
    }

    this.intervalCreated = this.intervals.filter(interval => !interval.notCreateds)

  }


  closeModal() {
    this.modalCtrl.dismiss({});
  }

  getInterval(notCreateds: string[]){

    let notCreated: number[] = notCreateds.map(Number);

    let intervals: {posStart:number, posEnd: number}[] = [];
    let intervalPos: {posStart:number, posEnd: number} = {posStart: null, posEnd: null}


    for(let posNotCreated of notCreated){
      if(!intervalPos.posStart){
        intervalPos.posStart = posNotCreated
      }else if(
          posNotCreated == intervalPos.posStart +1
         || (intervalPos.posEnd && intervalPos?.posEnd +1 == posNotCreated) ){
          if(posNotCreated >= 12 && posNotCreated <=17)
          //  console.log(!intervalPos.posEnd, posNotCreated == intervalPos.posStart +1
            // ,intervalPos?.posEnd +1 == posNotCreated, intervalPos?.posEnd +1, posNotCreated)
        intervalPos.posEnd = posNotCreated
      }else{
        intervals.push({...intervalPos})
        intervalPos.posStart = posNotCreated;
        intervalPos.posEnd = null
      }
    }

    if(intervalPos.posStart){
      intervals.push(intervalPos);
    }

    return intervals;

  }

}
