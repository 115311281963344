import { WritableSignal } from "@angular/core";

// models
import { RouteInfoRM } from "../models";

// services
import { RouteManagerService } from "../services";

// utils
import { UtilsTranslate } from "src/app/utils";
import { getRouteInfoRM } from "./get-route-info-rm";

interface GetRoutesRM{
  routes: WritableSignal<RouteInfoRM[]>,
  loadingRoutes: WritableSignal<boolean>,
  routeSelected: WritableSignal<RouteInfoRM>,
  _routeManagerService: RouteManagerService,
  utils: UtilsTranslate
}

export const getRoutesRM = ({
  _routeManagerService,
  routes,
  loadingRoutes,
  routeSelected,
  utils
}: GetRoutesRM) => {
  routes.set([]);
  loadingRoutes.set(true);
  routeSelected.set(null);
  _routeManagerService.getRoutes({}).subscribe({
    next: (res) => {
      console.log('res', res);
      routes.set(getRouteInfoRM(res));
      loadingRoutes.set(false);
    },
    error: () => {
      utils.showError();
      routes.set([]);
      loadingRoutes.set(false);
    },
  });
}
