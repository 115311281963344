import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// services
import { SkinMangementService } from 'src/app/services';
import { ColorModeService } from '../../../../services/color-mode.service';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {

  company: Company;

  isLightColor: boolean = true;

  currentYear = new Date().getFullYear()

  constructor(
    private skinService: SkinMangementService,
    private modalCtrl: ModalController,
    private _colorModeService: ColorModeService
  ) { }

  ngOnInit() {
    this.company = this.skinService.getCompany();
    this.isLightColor = this._colorModeService.getIsLightColor()
  }

  closeModal(){
    this.modalCtrl.dismiss()
  }

}
