import { GroupsNameRouteValids } from "../models/groups-name-route-valid.type";

export const GROUP_NAME_ROUTES_DB: Record<GroupsNameRouteValids, string> = {
  query: 'Transacciones',
  cards: 'Tarjetas',
  machines: 'Máquinas',
  articles: 'Artículos',
  dataManager: 'Gestor de datos',
  qr: 'QR',
  onlineShop: 'Tienda',
  userManagement: 'GestiónDeUsuarios',
  clickCollect: 'Click Collect',
  applicationManagement: 'Gestión de aplicación',
  aena: 'Aena',
  listing: 'Listados',
  routesManagement: 'Gestión de rutas',
  horeca: 'Horeca',
  billing: 'Facturación',
  dashboards: 'Dashboard',
  documentation: 'Documentación',
  qPay: 'Wipay'
}


export type GroupNameRoutesDB = keyof typeof GROUP_NAME_ROUTES_DB;
