import { WritableSignal } from '@angular/core';

// models
import { Report } from '../../models';

// utils
import { TYPE_REPORT_MULTIPLE_CARD } from '../constants';

export const setShowInvalidCard = (
  haveInvalidCard: WritableSignal<boolean>,
  reportSelected: WritableSignal<Report>
) => {
  haveInvalidCard.set(false);
  if (
    !TYPE_REPORT_MULTIPLE_CARD.includes(
      reportSelected()?.typeReport?.typeReport || ''
    ) &&
    (reportSelected()?.cards || []).length > 0
  ) {
    haveInvalidCard.set(true);
  }
};
