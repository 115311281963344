// login
export * from './login-alliance/login-alliance.component';
export * from './login-elora/login-elora.component';
export * from './login-v2v/login-v2v.component';

// extra
export * from './confidentiality-v2v/confidentiality-v2v.component';
export * from './confidentiality/confidentiality.component';
export * from './cookies-policy/cookies-policy.component';
export * from './force-password-renewal/force-password-renewal.component';
export * from './legal-notice-v2v/legal-notice-v2v.component';
export * from './privacy-policy/privacy-policy.component'
export * from './sales-cancelation/sales-cancelation.component';
export * from './send-email-force-password/send-email-force-password.component';
export * from './warning-not-email/warning-not-email.component';
