// External modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Component
import { FooterUpdateDeleteModalComponent } from './footer-update-delete-modal.component';

// Service
import { LanguageService } from 'src/app/share/services';
import { SkinMangementService } from 'src/app/services';

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule],
  declarations: [FooterUpdateDeleteModalComponent],
  providers: [LanguageService, SkinMangementService],
  exports: [FooterUpdateDeleteModalComponent],
})
export class FooterUpdateDeleteModalModule {}
