// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.start-line {
  margin-top: 2%;
  text-align: left;
  font-size: 0.8em;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/carousel-filter-guided/components/select-register-card/select-individual-card/select-individual-card.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;AACJ","sourcesContent":[".start-line{\r\n    margin-top: 2%;\r\n    text-align: left;\r\n    font-size: 0.8em;\r\n    color: red;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
