import { Injectable } from '@angular/core';

// services
import { StorageService } from '../share/services';

@Injectable({
  providedIn: 'root'
})
export class RemoveStorageService {

  constructor(
    private _storageService: StorageService,
  ) { }

  async removeStorage(){
    await this._storageService.remove('token');
    await this._storageService.remove('user');
    await this._storageService.remove('email');
    await this._storageService.remove('phone');
    await this._storageService.remove('name');
    await this._storageService.remove('surname');
    await this._storageService.remove('minDate');
    await this._storageService.remove('phonePrefix');

    this._storageService.remove('direction_shop');
    this._storageService.remove('contact_shop');
    this._storageService.remove('selectedShop');
    this._storageService.remove('shopArticles');
    this._storageService.remove('notVatProducts');
    this._storageService.remove('observation_shop');
    this._storageService.remove('multiLogin')
  }
}
