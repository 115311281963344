// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-md {
  font-size: 1.2em;
}

.input-style-alliance {
  width: 95%;
}

input[type=text] {
  border: 1px solid var(--ion-color-medium);
  border-radius: 4px;
  box-sizing: border-box;
  transition: 0.5s;
  outline: none;
  color: #585858;
}

input[type=text]:focus {
  border: 1px solid #585858;
}

.error-input-alliance {
  border: 1px solid red !important;
}

.text-md-card {
  font-size: 1.3em;
  color: var(--ion-color-shop-online-text);
}

.margin-end {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/ui/input-company/input-company.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAGA;EACE,UAAA;AAAF;;AAGA;EACE,yCAAA;EACA,kBAAA;EACA,sBAAA;EAEA,gBAAA;EACA,aAAA;EACA,cAAA;AAAF;;AAIA;EACE,yBAAA;AADF;;AAKA;EACE,gCAAA;AAFF;;AAKA;EACE,gBAAA;EACA,wCAAA;AAFF;;AAKA;EACE,iBAAA;AAFF","sourcesContent":[".text-md{\r\n  font-size: 1.2em;\r\n}\r\n\r\n\r\n.input-style-alliance{\r\n  width: 95%;\r\n}\r\n\r\ninput[type=text] {\r\n  border: 1px solid var(--ion-color-medium);\r\n  border-radius: 4px;\r\n  box-sizing: border-box;\r\n  -webkit-transition: 0.5s;\r\n  transition: 0.5s;\r\n  outline: none;\r\n  color: #585858\r\n}\r\n\r\n\r\ninput[type=text]:focus {\r\n  border: 1px solid #585858;\r\n}\r\n\r\n\r\n.error-input-alliance{\r\n  border: 1px solid red !important;\r\n}\r\n\r\n.text-md-card{\r\n  font-size: 1.3em;\r\n  color: var(--ion-color-shop-online-text);\r\n}\r\n\r\n.margin-end{\r\n  margin-left: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
