// models
import { ArticleClientDashboard, FamilyDW } from '../../models';

// utils
import { removeDuplicateObject } from 'src/app/utils';

export const getFamiliesDashboardWelcome = (
  articles: ArticleClientDashboard[]
): FamilyDW[] => {
  const families: FamilyDW[] = articles.map((article) => ({
    family: article.Family,
    familyId: article.FamilyID,
  }));

  let familyNotDuplicate = removeDuplicateObject(families, 'familyId');

  familyNotDuplicate = removeInvalidFamilies(familyNotDuplicate || []);

  return familyNotDuplicate;
};

const removeInvalidFamilies = (families: FamilyDW[]) => {
  return families.filter(
    (family) => family?.familyId || family?.familyId === 0
  );
};
