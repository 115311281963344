// External Module
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Components
import { FilterDateInputComponent } from './filter-date-input.component';

// Services
import { LanguageService } from '../../../../../services/languages.service';

@NgModule({
  declarations: [
    FilterDateInputComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
  ],
  providers: [
    LanguageService,
    DatePipe,
  ],
  exports: [
    FilterDateInputComponent
  ],
})
export class FilterDateInputModule {}
