import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';

// services
import { ImageManagerService } from '../services';

// utils
import { ROUTEHOME, UtilsTranslate } from 'src/app/utils';


@Injectable({
  providedIn: 'root',
})
export class HaveImageAPIGuard {
  constructor(
    private navCtrl: NavController,
    private _imageManagerService: ImageManagerService,
    private utils: UtilsTranslate,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // console.log('route', route.url[0].path,'complete',`/user/${route.url[0].path}`)

    const url = `/user/${route.url[0].path}` || '';
    let groupName = 'Gestión de aplicación';

    if (url === '/user/refunds') {
      groupName = 'Tarjetas';
    }

    return new Promise<boolean>((resolve) => {
      this._imageManagerService.getAPI(url, groupName).subscribe({
        next: () => {
          resolve(true);
        },
        error: () => {
          this.utils.presentToastTranslate(
            'image_manager.error_guard',
            true,
            0
          );
          this.navCtrl.navigateRoot(ROUTEHOME, { animated: false });
          resolve(false);
        },
      });
    });
  }
}
