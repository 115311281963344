// models
import { TypeSale } from '../../../transactions/transactions-query/models';
import { PaymentDashboardType, TypeSaleDW } from '../models';

// constant
import { TYPE_SALES_SHOWERS_DW } from './constants';

export const getPaymentsEanbled = (
  typeSales: TypeSale[]
): Partial<Record<PaymentDashboardType, TypeSaleDW>> => {
  let paymentsEnabled: Partial<Record<PaymentDashboardType, TypeSaleDW>> = {};

  for (let typeSale of typeSales) {
    if (
      typeSale.Enable &&
      Object.values(TYPE_SALES_SHOWERS_DW).includes(typeSale.name.trim().toLowerCase())
    ) {
      const nameTypeSale = getKeyByValue(TYPE_SALES_SHOWERS_DW, typeSale.name.trim().toLowerCase());

      paymentsEnabled[nameTypeSale] = {
        id: typeSale.id,
        description: nameTypeSale,
      };
    }
  }

  paymentsEnabled.all = {id: null, description: 'all'}

  return paymentsEnabled;
};

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key]?.trim()?.toLowerCase() === value);
};
