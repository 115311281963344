import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CardService } from '../../../services/card/card.service';

@Injectable({
  providedIn: 'root'
})
export class FilterCardInputService {

  cardNum: string = '';
  cardId = null;

  cardNum2: string = '';
  cardId2: number = null;

  deleteClick: boolean = false
  haveBankCardOption: boolean = false;

  filterChange: Subject<CardFilter> = new Subject<CardFilter>();

  constructor(private _cardService: CardService) { }

  getCard(positionCardNum: number = 0): CardFilter {
    if (positionCardNum == 0) {
      return { cardNum: this.cardNum, cardId: this.cardId, haveBankCardOption: this.haveBankCardOption }
    } else {
      return { cardNum: this.cardNum2, cardId: this.cardId2, haveBankCardOption: this.haveBankCardOption }
    }

  }

  getCardNum() {
      return this.cardNum
  }

  getDeleteClick() {
    return this.deleteClick
  }

  getCardId(positionCardNum: number = 0) {
    return (positionCardNum == 0) ? this.cardId : this.cardId2

  }


  setCard(cardNum: string, cardId: number, positionCardNum: number = 0) {

    if (positionCardNum) {
      this.cardNum = cardNum;
      this.cardId = cardId;
      this.haveBankCardOption = false;

      this.filterChange.next({
        cardNum: this.cardNum, cardId: this.cardId, haveBankCardOption: this.haveBankCardOption,
        positionCardNum
      })
    } else {
      this.cardNum2 = cardNum;
      this.cardId2 = cardId;
      this.haveBankCardOption = false;

      this.filterChange.next({
        cardNum: this.cardNum2, cardId: this.cardId2,
        haveBankCardOption: this.haveBankCardOption, positionCardNum
      })
    }

  }

  deleteCard(){
    this.cardNum = '';
    this.deleteClick = true
    this.filterChange.next({cardNum: this.cardNum, deleteClick: this.deleteClick })
    setTimeout(() => {

      this.deleteClick = false

    },100)


  }

  resetCard(positionCardNum: number = 0) {
    if (positionCardNum == 0) {
      this.cardNum = '';
      this.cardId = null;

      this.filterChange.next({ cardNum: this.cardNum, cardId: this.cardId, haveBankCardOption: this.haveBankCardOption, positionCardNum })

    } else {
      this.cardNum2 = '';
      this.cardId2 = null;
      this.filterChange.next({
        cardNum: this.cardNum2, cardId: this.cardId2, haveBankCardOption: this.haveBankCardOption,
        positionCardNum
      })

    }

  }

  resetAllCards(){
    this.resetCard();
    this.resetCard(1);
  }


  getCards(params) {
    if (params?.operatorId == -1) {
      delete params.operatorId
    }

    return this._cardService.getCards(params);
  }

  setCardNum(cardNum: string) {

      this.cardNum = cardNum;
      this.filterChange.next({ cardNum: this.cardNum });
      //this.filterChange.next({ cardNum: this.cardNum, cardId: this.cardId, haveBankCardOption: this.haveBankCardOption, positionCardNum });

  }


}

interface CardFilter {
  cardNum: string,
  cardId?: number,
  haveBankCardOption?: boolean,
  positionCardNum?: number,
  deleteClick?: boolean
}


