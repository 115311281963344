import { Company } from 'src/app/models';

// services
import { FilterReportService } from '../../../../services';

// utils
import { searchDuplicateCompleteObj, UtilsTranslate } from 'src/app/utils';
import { getTextRepeatCardTranslateReport } from '../../../translates';
import { TIME_DURATION_ERROR_REPORT } from '../../../constants';

interface HaveSomeRepeatCard {
  company: Company;
  _filterReportService: FilterReportService;
  utils: UtilsTranslate;
}

export const haveSomeRepeatCard = ({
  company,
  _filterReportService,
  utils,
}: HaveSomeRepeatCard) => {
  const cardsSelected = _filterReportService.getValidCards();

  const duplicateCards = searchDuplicateCompleteObj<{
    id: number;
    cardNum: string;
  }>(cardsSelected, 'id');

  if ((duplicateCards || []).length > 0) {
    const cardNumbers = duplicateCards.map((card) => card.cardNum);

    let textDuplicate = getTextRepeatCardTranslateReport(
      company,
      cardNumbers.length
    );

    utils.presentToastWithVariable(
      textDuplicate,
      'cards',
      cardNumbers.join(', '),
      true,
      TIME_DURATION_ERROR_REPORT
    );
    return true;
  }

  return false;
};
