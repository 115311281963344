import { Component, Input, OnInit, Output, EventEmitter, ElementRef, TemplateRef } from '@angular/core';
import { alliance, V2vFactory } from 'src/environments/companySetting';


@Component({
  selector: 'app-input-company',
  templateUrl: './input-company.component.html',
  styleUrls: ['./input-company.component.scss'],
})
export class InputCompanyComponent implements OnInit {
  @Input() company = V2vFactory;

  @Input() inputCompany: string = "";
  @Input() type: InputsValids = 'text';
  @Input() required: boolean = true;
  @Input() placeholder: string = "";

  @Input() labelText: string = "";

  @Input() haveError: boolean = false;
  @Input() errorTemplate: TemplateRef<string>;


  @Output() inputCompanyChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() isCard: boolean = false;

  @Input() info: TemplateRef<string>;

  @Input() maxLength: number = 200;


  constructor() {}

  ngOnInit() {}

}


type InputsValids = 'text' | 'button' | 'checkbox' | 'color' | 'date' | '"datetime-local'
    | 'email' | 'file' | 'hidden' | 'image'| 'month' | 'number' |  'password'
    | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'time'
    | 'url' | 'week' | null

