import { FilterDateDashboard, SelectDateValids } from '../../models';

export const isValidFilterDateByTypeSelected = (
  typeSelected: SelectDateValids,
  fiterDateDashboard: FilterDateDashboard
): boolean => {
  if (typeSelected === 'yearly') {
    return true;
  }

  if (fiterDateDashboard[typeSelected].dateSelected) {
    return true;
  }

  return false;
};
