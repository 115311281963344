// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-dark {
  border: 1px solid var(--ion-color-medium);
  box-shadow: 0px 0px 17px 2px rgba(128, 128, 128, 0.8);
}

.d-block {
  display: block;
}

.border2-dark {
  border-top: 1px solid var(--ion-color-medium);
}

.font-text-card {
  font-size: 1.2em;
  color: var(--ion-color-dark);
}

.d-inline-flex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
}

.mt-3 {
  margin-top: 3px;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

.mt-2 {
  margin-top: 2px;
  margin-right: 3px;
}

.d-flex {
  display: flex;
}

.d-flex-space {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/cards-template/card-with-title-dropdown-template/card-with-title-dropdown-template.component.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,qDAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,6CAAA;AACF;;AAEA;EACE,gBAAA;EACA,4BAAA;AACF;;AAEA;EACE,oBAAA;EACA,2BAAA;EACA,mBAAA;EACA,QAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF","sourcesContent":[".border-dark {\r\n  border: 1px solid var(--ion-color-medium);\r\n  box-shadow: 0px 0px 17px 2px rgb(128, 128, 128, 0.8);\r\n}\r\n\r\n.d-block{\r\n  display: block;\r\n}\r\n\r\n.border2-dark {\r\n  border-top: 1px solid var(--ion-color-medium);\r\n}\r\n\r\n.font-text-card{\r\n  font-size: 1.2em;\r\n  color: var(--ion-color-dark);\r\n}\r\n\r\n.d-inline-flex{\r\n  display: inline-flex;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n  gap: 2px;\r\n}\r\n\r\n.mt-3{\r\n  margin-top: 3px;\r\n}\r\n\r\n.d-flex-center{\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\n.mt-2{\r\n  margin-top: 2px;\r\n  margin-right: 3px;\r\n}\r\n\r\n.d-flex{\r\n  display: flex;\r\n}\r\n\r\n.d-flex-space{\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
