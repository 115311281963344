import { WritableSignal } from '@angular/core';

// models
import { Report } from '../../models';

// utils
import { setShowInvalidCard } from './set-show-invalid-card-report';
import { nextExecutionReport } from './set-next-execution-report';

interface SetReportSelectedByPos {
  pos: number;
  posSelected: WritableSignal<number>;
  reportSelected: WritableSignal<Report>;
  haveInvalidCard: WritableSignal<boolean>;
  nextExecution: WritableSignal<Date | string>;
  reports: Report[];
  page: number;
  itemPerPage: number;
  isWidhBiggerPcWidthMix: boolean;
}

interface UpdatePosSelected {
  pos: number;
  page: number;
  itemPerPage: number;
  posSelected: WritableSignal<number>;
  isWidhBiggerPcWidthMix: boolean;
}

export const setReportSelectedByPos = ({
  pos,
  posSelected,
  reportSelected,
  haveInvalidCard,
  nextExecution,
  reports,
  page,
  itemPerPage,
  isWidhBiggerPcWidthMix,
}: SetReportSelectedByPos) => {
  updatePosSelected({
    pos,
    itemPerPage,
    page,
    posSelected,
    isWidhBiggerPcWidthMix,
  });
  reportSelected.set(reports[pos]);
  setShowInvalidCard(haveInvalidCard, reportSelected);
  nextExecutionReport(nextExecution, reportSelected);
};



const updatePosSelected = ({
  pos,
  itemPerPage,
  page,
  posSelected,
  isWidhBiggerPcWidthMix,
}: UpdatePosSelected) => {
  const newPos = pos + (page - 1) * itemPerPage;
  if (newPos !== posSelected()) {
    posSelected.set(newPos);
  } else if (isWidhBiggerPcWidthMix) {
    posSelected.set(null);
  }
};
