import { FilterTableTillAlert, TillAlertSpiderTable, type ListFilterTypeTillAlert } from '../../models';

export const getTillAlertsByFilter = (
  tillsAlerts: TillAlertSpiderTable[],
  filtersTable: FilterTableTillAlert,
  typeFilter: ListFilterTypeTillAlert ) => {

  let filterTableWithTypeFilter: FilterTableTillAlert = getfilterTableWithTypeFilter(filtersTable, typeFilter)

  return getTillAlertsFilter(tillsAlerts, filterTableWithTypeFilter)

};

const getfilterTableWithTypeFilter = (
  filtersTable: FilterTableTillAlert,
  typeFilter: ListFilterTypeTillAlert) => {

  let filterTableWithTypeFilter: FilterTableTillAlert = {...filtersTable}

  if(typeFilter === 'client' && (filterTableWithTypeFilter.clientId ||filterTableWithTypeFilter.clientId === 0)){
    filterTableWithTypeFilter.clientId = null;
  }

  if(typeFilter === 'machine' && (filterTableWithTypeFilter.clientId ||filterTableWithTypeFilter.clientId === 0)){
    filterTableWithTypeFilter.machineId = null;
  }

  if(typeFilter === 'email' && filterTableWithTypeFilter.email){
    filterTableWithTypeFilter.email = '';
  }


  return filterTableWithTypeFilter;
}


const getTillAlertsFilter = (
  tillsAlerts: TillAlertSpiderTable[],
  filtersTable: FilterTableTillAlert,
) => {
  let tillAlertFilter  = tillsAlerts.map(tillALert => ({...tillALert}))

  if(filtersTable.clientId || filtersTable.clientId === 0){
    tillAlertFilter = tillAlertFilter.filter(tillAlert  => tillAlert.client.clientId === filtersTable.clientId)
  }

  if(filtersTable.machineId || filtersTable.machineId === 0){
    tillAlertFilter = tillAlertFilter.filter(tillAlert  => tillAlert.machine.machineId === filtersTable.machineId)
  }

  if(filtersTable.email){
    tillAlertFilter = tillAlertFilter.filter(tillAlert  => tillAlert.email === filtersTable.email)
  }

  if(filtersTable.statusId || filtersTable.statusId === 0){
    tillAlertFilter = tillAlertFilter.filter(tillAlert  => tillAlert.status.id === filtersTable.statusId)
  }

  if(filtersTable.typeAlertId || filtersTable.typeAlertId === 0){
    tillAlertFilter = tillAlertFilter.filter(tillAlert  => tillAlert.typeAlert.id === filtersTable.typeAlertId)
  }

  return tillAlertFilter;

}

