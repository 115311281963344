

export enum StepCreateEditReport {
  "Periodicity" = 1,
  "ReportType" = 2,
  "Visibility" = 3,
  "Date" = 4,
  "Emails" = 5,
  "Name" = 6
}





