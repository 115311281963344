// option tooltip
import { TooltipOptions } from 'ng2-tooltip-directive';
import { ImageSite, IconSite } from '../pages/user/machines/site/models';

export const PORT_IONIC = '8100';

export const itemPerPage = 10;

export const MINDATE = '2016-01-01';
export const THREE_MONTHS: number = 90;

export const ITEMSPERPAGE: number = 10;
export const ITEM_SHOWER_MODAL: number = 50;

export const type = {
  alliance: 'AllianceVending',
  v2v: 'V2VFactory',
  rocca: 'LaRocca',
  triangle: 'Triangle',
};

// Roles
export const REPLENISHER_ROLE = 'reponedor';
export const MULTI_LOGIN_ROLE = 'MultiLogin';

// interteceptor errors token
export const errorToken = 'Token es incorrecto';
export const errorToken2 = 'Se necesita el token';

export const encryptValue = 'Ar38p6$#@$^@0ehJ';

export const paymentDB = [
  'Cash',
  'Credit',
  'Mifare',
  'Online',
  'Free',
  'Webload',
];

// -------------------------------- Size --------------------------------
export const pcWidth = 602;
export const pcWidthMix = 1280;
export const GRIDSIZE = {
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1200,
  xxl: 1400,
};
export const SIZE_FOOTER_V2V = 1200;
export const ION_GRIDSIZE = {
  xs: 0,
  sm: 575,
  md: 768,
  lg: 992,
  xl: 1200
}

// -------------------------------- Flags --------------------------------
export const imageFlags: { image: string; locale: string }[] = [
  { image: '../../../../assets/img/flags/es.svg', locale: 'es-ES' },
  { image: '../../../../assets/img/flags/gb.svg', locale: 'en-EN' },
  { image: '../../../../assets/img/flags/fr.svg', locale: 'fr-FR' },
  { image: '../../../../assets/img/flags/it.svg', locale: 'it-IT' },
];

// -------------------------------- Routes --------------------------------
// Main Menu
export const MULTI_LOGIN_ROUTE = '/user/select-role';

// Routes
export const ROUTEHOME = '/user/home';
export const NAMEMACHINESTATUS = 'Estado actual máquinas';
export const NAME2MACHINESTATUS = 'Machine Status';
export const ROUTETILLSTATE = '/user/machines-status/till-state';
export const ROUTECURRENTSTATE = '/user/machines-status/current-state';
export const ROUTEMACHINESTATE = '/user/machines-status';
export const SITE_ELORA_ROUTE = '/user/site-elora';

// register card
export const ROUTEREGISTERCARD = '/user/register-cards';
export const NAMEREGISTERCARDANDASSIGN = 'High Level:Alta Alliance';
export const NAMEREGISTERCARD = 'Mid Level: Alta Alliance';
export const NAMEASSIGNCARD = 'Low Level:Alta Alliance';
export const LOW_LEVEL_REGISTER_CARD = [
  'Low Level:Alta Alliance (6-8 dígitos)',
  'Low Level:Alta Alliance (6-9 dígitos)',
];
export const MID_LEVEL_REGISTER_CARD = [
  'Mid Level: Alta Alliance (6-8 dígitos)',
  'Mid Level: Alta Alliance (6-9 dígitos)',
];
export const HIGH_LEVEL_REGISTER_CARD = [
  'High Level:Alta Alliance (6-8 dígitos)',
  'High Level:Alta Alliance (6-9 dígitos)',
];

// home
export const SELECT_ROLE_ROUTE = '/user/select-role';
export const ONLINE_SHOP_ROUTE = '/user/online-shop';

// Dashboards
export const DASHBOARD_WELCOME_ROUTE = '/user/dashboard/welcome';
export const DASHBOARD_REFILLING_ROUTE = '/user/dashboard/refilling-dashboard';
export const DASHBOARD_SELECTOR_ROUTE = '/user/dashboard/dashboard-selector';
export const DASHBOARD_TECHNICAL_ROUTE ='/user/dashboard/statusnet'
// TODO borrar
export const DASHBOARD_HORECA_ROUTE_TEST = '/user/horeca/dashboard'

// horeca
export const HORECA_QUERY_ROUTE = '/user/horeca/horeca-query';
export const HORECA_DASHBOARD_ROUTE = '/user/horeca/horeca-dashboard';

// Route Management
export const CLIENT_MANAGEMENT_ROUTE = '/user/machine-module';

export const PROFILE_ROUTE = '/user/profile';

// support routes
export const CREATE_TICKET_URL =
  'https://v2vfactory.com/intranet/public/ticket/create_ticket.php';
export const CHECK_TICKET_URL =
  'https://www.v2vfactory.com/intranet/public/ticket/list.php';

// ecommerce
export const ECOMMERCE_ROUTE = '/user/online-shop';
export const ARBITRADE_ECOMMERCE_LINK =
  'https://arbitrade.tienda/iniciar-sesion';
export const OPERATOR_GROUP_LINK_ARBITRADE = 2;

// idle
export const ROUTE_NOT_SHOW_IDLE: string[] = [
  '/user/home',
  '/user/real-time-sale',
  '/user/site',
  '/user/access-web',
  '/user/access-web-client',
  '/user/dashboard/welcome',
  '/user/site-elora',
  '/user/dashboard/refilling-dashboard',
  '/user/generate-qr'
];

// Group Route Name
export const DASHBOARD_GROUP_NAME_ROUTE = 'Dashboard';
export const ROUTE_MANAGEMENT_GROUP_NAME_ROUTE = 'Gestión de rutas';

// Machine status
export const MACHINE_STATUS_SUBROUTES = ['/user/machines-status/till-state', '/user/machines-status/current-state'];
export const MACHINE_STATUS_ROUTE = '/user/machines-status';


export type ColorModeValids = 'light' | 'dark';

// -------------------------------- Site --------------------------------
export const imagesSite: { [x: string]: ImageSite } = {
  '0': {
    image: '../../../../../../assets/images/bottle.svg',
    alt: 'bebida fria',
    text: 'loading_proposal.legend_site.colddrink_machine',
  },
  '1': {
    image: '../../../../../../assets/images/latte.svg',
    alt: 'bebida caliente',
    text: 'loading_proposal.legend_site.hotdrink_machine',
  },
  '2': {
    image: '../../../../../../assets/images/snack.svg',
    alt: 'snack',
    text: 'loading_proposal.legend_site.snacks_machine',
  },
  '3': {
    image: '../../../../../../assets/images/vending-machine.svg',
    alt: 'mixed',
    text: 'loading_proposal.legend_site.mixed_machine',
  },
  '4': {
    icon: 'help',
    color: 'dark',
    text: 'loading_proposal.legend_site.undefined_sell',
  },
};

export const imagesSiteElora: { [x: string]: ImageSite } = {
  '0': imagesSite['0'],
  '1': imagesSite['1'],
  '2': imagesSite['2'],
  '3': {
    image: imagesSite['3'].image,
    alt: imagesSite['3'].alt,
    text: 'site_elora.mixed_machine',
  },
  '4': imagesSite['4'],
};

export const iconsSite: { [x: string]: IconSite } = {
  '0': {
    name: 'wifi-outline',
    color: 'dark',
    text: 'loading_proposal.legend_site.unregistred_machines',
  },
  '1': {
    name: 'wifi-outline',
    color: 'success',
    text: 'loading_proposal.legend_site.connected_machines',
  },
  '2': {
    name: 'wifi-outline',
    color: 'danger',
    text: 'loading_proposal.legend_site.disconnected_machine',
  },
  '3': {
    name: 'checkmark-circle',
    color: 'success',
    text: 'loading_proposal.legend_site.operative_machine',
  },
  '4': {
    name: 'warning',
    color: 'warning',
    text: 'loading_proposal.legend_site.fail_machine',
  },
  '5': {
    name: 'lock-closed',
    color: 'medium',
    text: 'loading_proposal.legend_site.block_machine',
  },
};

// ----------------------------- Till state, Machine status ----------------------------
export const iconState = {
  '1': {
    name: 'radio-button-on-outline',
    color: 'icon-success-table',
    text: 'loading_proposal.legend_site.connected_machines',
  },
  '0': {
    name: 'radio-button-on-outline',
    color: 'danger-always',
    text: 'loading_proposal.legend_site.disconnected_machine',
  },
  '2': {
    name: 'wifi-outline',
    color: 'dark',
    text: 'loading_proposal.legend_site.unregistred_machines',
  },
};

export const modesTill = [
  'operating',
  'failure',
  'Failure',
  'locked',
  'Regular',
  'Blocked',
];

export const iconMode = {
  operating: {
    name: 'checkmark-circle',
    color: 'icon-success-table',
    text: 'loading_proposal.legend_site.operative_machine',
  },
  Regular: {
    name: 'checkmark-circle',
    color: 'icon-success-table',
    text: 'loading_proposal.legend_site.operative_machine',
  },
  failure: {
    name: 'warning',
    color: 'warning',
    text: 'loading_proposal.legend_site.fail_machine',
  },
  Failure: {
    name: 'warning',
    color: 'warning',
    text: 'loading_proposal.legend_site.fail_machine',
  },
  locked: {
    name: 'lock-closed',
    color: 'medium-block',
    text: 'loading_proposal.legend_site.block_machine',
  },
  Blocked: {
    name: 'lock-closed',
    color: 'medium-block',
    text: 'loading_proposal.legend_site.block_machine',
  },
};

// -------------------------------- Length Card --------------------------------
export const MINLENGTHCARD: number = 6;
export const MAXCARDLENGTH1: number = 8;
export const MAXCARDLENGTH2: number = 9;

// -------------------------------- Pattern --------------------------------
export const PATTERN_PASSWORD_VALIDATE =
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&+-])[A-Za-zd$@$!%*?&+-].{8,}';
export const PATTERN_EMAIL_VALIDATE = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

// -------------------------------- Time --------------------------------
export const durationErrorToast = 2000;
export const durationSuccessToast = 2000;
export const durationWarningToast = 8000;

// auth service
export const TIME_OUT_ERROR = 10000;

// download ticket transaction-query
export const timeOutDownload = 15000;

// endpoint pdf
export const TIME_OUT_DOWNLOAD = 15000;

// wait start
export const TIME_OUT_GROUP: number = 200;
export const TIME_OUT_START: number = 250;
export const TIME_OUT_EXTRA: number = 400;
export const TIME_OUT_EXTRA_APK: number = 600;

// close second modal
export const TIME_WAIT_CLOSE_MODAL = 250;
export const TIME_WAIT_CLOSE_MODAL_APK = 500;

// time swiper change pos
export const TIME_SWIPER_CHANGE_SLIDE = 300;

// phone
export const TIME_WAIT_PHONE_INPUT = 50;

// Date translate
export const SUNDAY_FIRST_DAY_WEEK = '0';

// wait select all
export const MASTER_CHECK_TIME_OUT = 20;

export const timeReload = 200;
export const TIME_LOADING_DEFAULT = 100;

export const MyDefaultTooltipOptions: TooltipOptions = {
  'show-delay': 500,
  //   'hideDelayAfterClick': 10,
  //   'hideDelayTouchscreen': 20
};
