import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Model
import { typeDeleteMenu } from '../model';


@Injectable({
  providedIn: 'root'
})
export class SendFilter {


    public filterValues: Subject<Object[]> = new Subject<Object[]>();
    public typeDelete: Subject<typeDeleteMenu> = new Subject<typeDeleteMenu>();
    public resetFilter: Subject<boolean> = new Subject<boolean>();

    constructor(){

    }

    changeFilter(newFilter){
      this.filterValues.next(newFilter);
    }

    delete(typeDelete: typeDeleteMenu){
      this.typeDelete.next(typeDelete);
    }

    clearFilter(){
      this.resetFilter.next(true);
    }
}
