import { Phone } from '../models';
import { phone, PhoneResult } from 'phone';
import {
  GetPhone,
  PhoneInputAllEntries,
  PhonePrefixValid,
} from '../share/components/phone-input-template/models';
import { StartPhone } from '../share/components/phone-input-template/models/start-phone.model';
import { PREFERRED_COUNTRIES_DEFAULT } from '../share/components/phone-input-template/utils';

export const getPhone = (phoneAndPrefix: string): GetPhone => {
  return phone(phoneAndPrefix);
};

export const getPhoneAndPrefix = (
  phoneInput: PhoneInputAllEntries
): PhonePrefixValid => {

  return {
    phone: phoneInput.phoneParcial,
    prefix: phoneInput.phoneInputCountry.dialCode,
    isValid: phoneInput.isValid,
  };
};

export const getPhoneStart = (
  phoneNumber: string,
  prefix?: string
): StartPhone => {

  if (phoneNumber?.[0] === '+' && !prefix) {
    return phoneWidthPrefix(phoneNumber);
  }

  if (!prefix && phoneNumber.length === 6) {
    prefix = '376';
  }

  if (!prefix && phoneNumber.length === 9) {
    prefix = '34';
  }

  // console.log('valores', prefix, phoneNumber)

  const phoneAndPrefix = prefix ? `+${prefix}${phoneNumber}` : phoneNumber;

  const valid: PhoneResult = phone(phoneAndPrefix, { country: null });

  const isoCode: string = (valid?.countryIso2 || 'es').toLowerCase();

  return {
    preferedCountries: getPreferedCountries(isoCode),
    valid: !!valid?.isValid,
    phoneNumber: phoneNumber,
    prefix: prefix,
  };
};

const getPreferedCountries = (isoCode: string): string[] => {
  if (!isoCode) {
    return PREFERRED_COUNTRIES_DEFAULT;
  }

  if (!PREFERRED_COUNTRIES_DEFAULT.includes(isoCode)) {
    return [isoCode, ...PREFERRED_COUNTRIES_DEFAULT];
  }

  return Array.from(new Set([isoCode, ...PREFERRED_COUNTRIES_DEFAULT]));
};

const phoneWidthPrefix = (phoneAndPrefix: string) => {

  const valid: PhoneResult = phone(phoneAndPrefix, { country: null });
  const isoCode: string = (valid?.countryIso2 || 'es').toLowerCase();

  let phoneNumber = '';
  let prefix = '';
  if(!valid || !valid?.isValid){
    phoneNumber = phoneAndPrefix.replace('+34', '')
    prefix = '34'
  }
  else{
    phoneNumber = (valid?.phoneNumber || '').replace(valid?.countryCode || '', '');
    prefix = (valid?.countryCode || '').replace('+', '');
  }

  return {
    preferedCountries: getPreferedCountries(isoCode),
    valid: !!valid?.isValid,
    phoneNumber,
    prefix,
  };

};

/**
 * TODO
 * change phone input
 * remove styles
 */
// IonIntlTelInputModel
export const convertPhoneToTelInputModel = (phoneInput: Phone): any => {
  if (!phoneInput?.prefix && phoneInput?.number?.length === 6) {
    phoneInput.prefix = '376';
  }

  const phoneAndPrefix = phoneInput.prefix
    ? `+${phoneInput.prefix} ${phoneInput.number}`
    : phoneInput.number;
  const valid: PhoneResult = phone(phoneAndPrefix, { country: null });
  const isoCode: string = (valid?.countryIso2 || 'es').toLowerCase();
  const dialCode: string = phoneInput.prefix
    ? `+${phoneInput.prefix}`
    : valid?.countryCode || '+34';

  return {
    dialCode,
    internationalNumber: '',
    isoCode,
    nationalNumber: phoneInput.number,
  };
};

export const removeAllCaret = (
  idPhone: string,
  useMargin: boolean = false,
  firstTime = true,
  timeWait = 200
) => {
  const selectPhone = document.querySelectorAll(`#${idPhone}`);
  if (selectPhone?.length > 0) {
    for (let i = 0; i < selectPhone.length; i++) {
      const iconDiv = selectPhone[i].querySelector('.ionic-selectable-icon');
      if (iconDiv) {
        iconDiv?.classList.forEach((el) => iconDiv.classList.remove(el));
      }

      if (useMargin) {
        const code = selectPhone[i].querySelector('.ion-intl-tel-input-number');
        if (code) {
          code.classList.add('ion-margin-start');
        }
      }
    }
  } else if (firstTime) {
    setTimeout(() => removeAllCaret(idPhone, useMargin, false), timeWait);
  }
};

export const removeCaretAndSeparator = (
  idPhone: string,
  useMargin: boolean = false,
  firstTime = true,
  timeWait = 200
) => {
  const selectPhone = document.querySelector(`#${idPhone}`);
  if (selectPhone) {
    const iconDiv = selectPhone.querySelector('.ionic-selectable-icon');
    if (iconDiv) {
      iconDiv?.classList.forEach((el) => iconDiv.classList.remove(el));
    }
    if (useMargin) {
      const code = selectPhone.querySelector('.ion-intl-tel-input-number');
      if (code) {
        code.classList.add('ion-margin-start');
      }
    }
  } else if (firstTime) {
    setTimeout(
      () => removeCaretAndSeparator(idPhone, useMargin, false),
      timeWait
    );
  }
};

export const removeSelectCaret = (id, firstTime = true) => {
  const selectPhone = document.querySelector(`#${id}`);
  if (selectPhone) {
    const iconDiv = selectPhone.querySelector('.ionic-selectable-icon');
    if (iconDiv) {
      iconDiv?.classList.forEach((el) => iconDiv.classList.remove(el));
    }
  } else if (firstTime) {
    setTimeout(() => removeSelectCaret(id, false), 200);
  }
};
