import { WritableSignal } from '@angular/core';

// models
import {
  ArticleClientDashboard,
  ArticlesStorageDashboard,
  FilterDashboards,
  TotalArticlesAreasValids,
} from '../../models';

interface ValidateArticleId {
  articles: ArticleClientDashboard[];
  articlesStorage: ArticlesStorageDashboard;
  filtersDashboard: WritableSignal<FilterDashboards>;
}

interface ValidArticleSelected {
  articles: ArticleClientDashboard[];
  filtersDashboard: WritableSignal<FilterDashboards>;
  option: TotalArticlesAreasValids;
}

interface ValidateArticleStorage extends ValidArticleSelected {
  articleStorage: { id: number; description: string };
}

export const validateArticleId = ({
  articles,
  filtersDashboard,
  articlesStorage,
}: ValidateArticleId): boolean => {
  if (!articlesStorage.isFirtTime) {
    return validArticlesSelected(articles, filtersDashboard);
  }

  articlesStorage.isFirtTime = false;
  return validateFirstTime({ articles, filtersDashboard, articlesStorage });
};

const validateFirstTime = ({
  articles,
  filtersDashboard,
  articlesStorage,
}: ValidateArticleId): boolean => {
  let isArticleAValid = true;
  let isArticleBValid = true;

  if (articlesStorage?.articleA) {
    isArticleAValid = validateArticleStorage({
      articles: articles,
      articleStorage: articlesStorage.articleA,
      filtersDashboard: filtersDashboard,
      option: 'areaA',
    });
  }

  if (articlesStorage?.articleB) {
    isArticleBValid = validateArticleStorage({
      articles: articles,
      articleStorage: articlesStorage.articleB,
      filtersDashboard: filtersDashboard,
      option: 'areaB',
    });
  }

  return isArticleAValid && isArticleBValid;
};

const validArticlesSelected = (
  articles: ArticleClientDashboard[],
  filtersDashboard: WritableSignal<FilterDashboards>
): boolean => {
  let isArticleSelectedAValid = true;
  let isArticleSelectedBValid = true;

  if (filtersDashboard().articles?.areaA) {
    isArticleSelectedAValid = validArticleSelected({
      articles,
      filtersDashboard,
      option: 'areaA',
    });
  }

  if (filtersDashboard().articles?.areaB) {
    isArticleSelectedBValid = validArticleSelected({
      articles,
      filtersDashboard,
      option: 'areaB',
    });
  }

  return isArticleSelectedAValid || isArticleSelectedBValid;
};

const validateArticleStorage = ({
  articles,
  articleStorage,
  filtersDashboard,
  option,
}: ValidateArticleStorage): boolean => {
  if (
    articleStorage &&
    articles.some(
      (article) =>
        article.ArticleID === articleStorage.id &&
        article.Article === articleStorage.description
    )
  ) {
    filtersDashboard.mutate(
      (filterDashboard) =>
        (filterDashboard.articles[option].article = {
          id: articleStorage.id,
          description: articleStorage.description,
        })
    );

    return true;
  }

  if (articleStorage) {
    return false;
  }

  return true;
};

const validArticleSelected = ({
  articles,
  filtersDashboard,
  option,
}: ValidArticleSelected): boolean => {
  const articleSelected = filtersDashboard().articles[option].article;

  if(!articleSelected){
    return true;
  }

  if (
    !articles.some(
      (article) =>
        article.ArticleID === articleSelected.id &&
        article.Article === articleSelected.description
    )
  ) {
    filtersDashboard.mutate(
      (filterDashboard) => (filterDashboard.articles[option].article = null)
    );

    return false;
  }

  return true;
};
