import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, throwError } from 'rxjs';
import { delay, map, catchError } from 'rxjs/operators';

// config
import { AppConfig } from 'src/app/config/config';

// Service
import { ArticleService } from '../../../../../share/services/article/article.service';

// models
import { TypeDevice, API } from '../models';
import {
  ArticleNotification,
  NotificationTypes,
  ParamsCreateNotification,
} from '../../notification-panel/models';
import { ImageManagerSO, ImageType, ParamsCheckImage, ParamsCheckImageIM, ParamsUploadImage, UpdateImageIM } from '../../image-manager/models';

// constants
import { ImageTypes } from '../utils/notification-share-constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationShareService {
  private serviceUrl = AppConfig.RTVnodeUrl;

  constructor(
    private httpClient: HttpClient,
    protected router: Router,
    private _articleService: ArticleService
  ) {}

  getNotificationsTypes() {
    let endpoint = 'getNotificationsTypes';
    let routeUrl = this.router.url;

    return this.httpClient
      .get<NotificationTypes[]>(
        this.serviceUrl + endpoint + `?route=${routeUrl}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getArticles() {
    return this._articleService.getArticles();
  }

  getTypeDevices() {
    let endpoint = 'api/getTypeDevices';
    let routeUrl = this.router.url;

    return this.httpClient
      .get<TypeDevice[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`)
      .pipe(
        map((res) => {
          res = res.filter(
            (device) =>
              device.Descripcion === 'Android' || device.Descripcion === 'IOS'
          );
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getAPI(routeURL = null, group = null) {
    let endpoint = `getApp`;
    let routeUrl = routeURL || this.router.url;

    return this.httpClient
      .get<API>(
        this.serviceUrl +
          endpoint +
          `?route=${routeUrl}` +
          (group ? `&group=${group}` : '')
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  getImageTypes(){
    let endpoint = `getImageTypes`;
    let routeUrl = this.router.url;

    return this.httpClient.get<ImageType[]>(this.serviceUrl + endpoint + `?route=${routeUrl}`).pipe(
        map(res => {

          if(routeUrl === '/user/notification-manager'){
            res = res.filter(
              (r) => r.Descripcion === ImageTypes.Header || r.Descripcion === ImageTypes.Body
            );
          }

          if(routeUrl === '/user/image-manager'){
            res = res.filter(
              (r) => r.Descripcion === ImageTypes.Banner || r.Descripcion === ImageTypes.Splash
            );
          }

          return res;
        }), catchError(error => {
          let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
          return throwError(() => messageError);
        })
    );

  }

  checkImage(params: ParamsCheckImageIM){
    let endpoint = `checkImage`;
    let routeUrl = this.router.url;

    return this.httpClient
    .post<{response: boolean}>(this.serviceUrl + endpoint + `?route=${routeUrl}`, params)
    .pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        let messageError = !error?.error?.error?.message
          ? '504 Gateway timeout'
          : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  uploadImage(params: ParamsUploadImage){
    let endpoint = `uploadImage/${params?.imageTypeID}/${params?.deviceTypeID}/${params?.file?.name}`;
    let routeUrl = this.router.url;

    const formData: FormData = new FormData();
    formData.append('fileKey', params?.file, params?.file?.name);


    return this.httpClient
      .post<UpdateImageIM>(this.serviceUrl + endpoint + `?route=${routeUrl}`, formData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          let messageError = !error?.error?.error?.message
            ? '504 Gateway timeout'
            : error?.error?.error?.message;
          return throwError(() => messageError);
        })
      );
  }

  createNotificationApp(params: ParamsCreateNotification){
    let endpoint = 'createNotificationApp';
    let routeUrl = this.router.url;

    return this.httpClient.post<null>
        (this.serviceUrl + endpoint + `?route=${routeUrl}`, params).pipe(
      map(res => {
        return res;
      }), catchError(error => {
        let messageError = (!error?.error?.error?.message) ? '504 Gateway timeout' : error?.error?.error?.message;
        return throwError(() => messageError);
      })
    );
  }

  getURLImage(){
    return this.serviceUrl + 'api/GetImage/';
  }
}
