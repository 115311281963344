import { Injectable } from "@angular/core";

import { Observable, of } from 'rxjs';
import { filter, find, map, first, take } from 'rxjs/operators';
import { PermissionService } from '../../../../../services/permission.service';
import { Client } from '../../../../../models/';


@Injectable({providedIn: 'root'})
export class ClientResolverService {

    constructor(private _permissionService: PermissionService) {}

    resolve(): Observable<any>{
        return this._permissionService
                .getVisibility()
                    .pipe(
                        take(1),
                        map((res) => res[0].clientId)
                    );
    }

}
