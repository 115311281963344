import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { HeaderModalComponent } from './header-modal.component';

// Services
import { LanguageService } from '../../services/languages.service';
import { CardNamePipeModule } from '../../pipes';


@NgModule({
    declarations: [
        HeaderModalComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
      ReactiveFormsModule,
      TranslateModule,

      CardNamePipeModule
    ],
    providers: [
      LanguageService,
    ],
    exports: [
        HeaderModalComponent
    ]
  })
  export class HeaderModalModule { }
