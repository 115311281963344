// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sidenav ion-item-group[submenu] {
  overflow: hidden;
  display: block;
  height: 0;
  transition: height 0.5s linear;
  padding-left: 5%;
}
#sidenav ion-item-group[submenu].visible {
  height: inherit;
}

.boton {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  color: blue;
  margin-top: 2%;
  margin-left: 30%;
  width: 150px;
  height: 40px;
}

.link-not-decoration {
  color: inherit;
  text-decoration: inherit;
}

.color-invert-svg {
  filter: brightness(0) invert(1) contrast(100%);
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/main-menu/sub-menu-support/sub-menu-support.component.scss"],"names":[],"mappings":"AACI;EACE,gBAAA;EACA,cAAA;EACA,SAAA;EACA,8BAAA;EACA,gBAAA;AAAN;AACM;EACE,eAAA;AACR;;AAKE;EACE,6BAAA;EACE,4BAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;AAFN;;AAME;EACE,cAAA;EACA,wBAAA;AAHJ;;AAME;EAEE,8CAAA;AAHJ","sourcesContent":["#sidenav {\r\n    ion-item-group[submenu] {\r\n      overflow: hidden;\r\n      display: block;\r\n      height: 0;\r\n      transition: height 0.5s linear;\r\n      padding-left: 5%;\r\n      &.visible {\r\n        height: inherit;\r\n      }\r\n    }\r\n  }\r\n\r\n\r\n  .boton{\r\n    background-color: Transparent;\r\n      background-repeat:no-repeat;\r\n      border: none;\r\n      cursor:pointer;\r\n      overflow: hidden;\r\n      color: blue;\r\n      margin-top: 2%;\r\n      margin-left:30%;\r\n      width: 150px;\r\n      height: 40px;\r\n\r\n  }\r\n\r\n  .link-not-decoration{\r\n    color: inherit;\r\n    text-decoration: inherit;\r\n  }\r\n\r\n  .color-invert-svg{\r\n    -webkit-filter: brightness(0) invert(1);\r\n    filter: brightness(0) invert(1) contrast(100%);\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
