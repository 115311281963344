import { WritableSignal } from "@angular/core";

// models
import { TillSelectedRM } from "../../models";

export const clearTillsRM = ( tills: WritableSignal<TillSelectedRM[]>) => {
  const newTills = tills().map((till) => {
    if (till.isSelected) {
      till.isSelected = false;
    }
    return till;
  });

  tills.set(newTills)
}
