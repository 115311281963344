import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

// Models
import { LinkRoute, Company } from 'src/app/models';

// Utils
import { CHECK_TICKET_URL, CREATE_TICKET_URL, getTargetByPlatform } from 'src/app/utils';

@Component({
  selector: 'app-sub-menu-search',
  templateUrl: './sub-menu-search.component.html',
  styleUrls: ['./sub-menu-search.component.scss'],
})
export class SubMenuSearchComponent implements OnInit {

  @Input() routeMenu: LinkRoute = null;
  @Input() company: Company = null;

  @Input() createTicket = CREATE_TICKET_URL;
  @Input() checkTicket = CHECK_TICKET_URL;

  target: '_self' | '_blank' = '_blank';

  constructor(
    private platform: Platform
  ) { }

  ngOnInit() {
    this.target = getTargetByPlatform(this.platform)
  }

  openLink(link: string): void {
    if(link) window.open(link , this.target)
  }


}
