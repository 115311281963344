import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { ION_GRIDSIZE, pcWidthMix } from 'src/app/utils';

@Component({
  selector: 'app-arrow-back-select-all-position',
  templateUrl: './arrow-back-select-all-position.component.html',
  styleUrls: ['./arrow-back-select-all-position.component.scss'],
})
export class ArrowBackSelectAllPositionComponent implements OnInit {

  @Input() company: Company;

  @Input() haveBack: boolean = true;
  @Input() backTranslate: string = 'back';

  @Input() haveSelectAll: boolean = true;
  @Input() selectAllTranslate: string = '';

  @Input() masterCheck: boolean = true;
  @Input() isIndeterminate: boolean = true;

  @Output() sendBack = new EventEmitter()
  @Output() sendSelectAll = new EventEmitter()

  pcWidthMix = pcWidthMix;
  sizeMd = ION_GRIDSIZE.md;

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}

  selectAll(){
    this.sendSelectAll.emit()
  }

  back(){
    this.sendBack.emit()
  }

}
