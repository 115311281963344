import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

// Service
import { SkinMangementService } from '../../../../services/skin-mangement.service';

// Model
import { CardNames, Company } from '../../../../models';

// Utils
import { type, getTargetByPlatform } from '../../../../utils';

@Component({
  selector: 'app-sales-cancelation',
  templateUrl: './sales-cancelation.component.html',
  styleUrls: ['./sales-cancelation.component.scss'],
})
export class SalesCancelationComponent implements OnInit {

  // carga online
  @Input() haveFooter = false;

  scrollDepthTriggered: boolean = false;

  company: Company;
  type = type;

  target: '_self' | '_blank' = '_blank';

  cardNames = CardNames;

  constructor(
    private platform: Platform,
    private modalCtrl: ModalController,
    private skinService: SkinMangementService) { }

  ngOnInit() {
    this.company = this.skinService.getCompany();
    this.target = getTargetByPlatform(this.platform)
  }


  closeModal(state: boolean = false) {
    this.modalCtrl.dismiss({
      accept: state
    });
  }

  async logScrolling($event) {

    if (this.scrollDepthTriggered) {
      return;
    }

    if ($event.target.localName != "ion-content") {
      return;
    }

    const scrollElement = await $event.target.getScrollElement();

    const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;

    const currentScrollDepth = $event.detail.scrollTop;

    const targetPercent = 80;

    let triggerDepth = ((scrollHeight / 100) * targetPercent);

    if (currentScrollDepth > triggerDepth) {
      this.scrollDepthTriggered = true;
    }
}

}
