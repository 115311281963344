// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-weight-bold {
  text-indent: 1.5em;
  font-weight: bold;
}

.ml-1 {
  margin-left: 10px;
}

.font-style2 {
  font-size: 1.5em;
}

.d-flex-center {
  display: flex;
  justify-content: left;
  align-items: center;
}

.d-flex-center2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon-lg {
  font-size: 2.2em;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/transactions/transactions-query/components/modals/legend-transaction-query/legend-transaction-query.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACE,gBAAA;AACF;;AAGA;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;AAAF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;AADF;;AAIA;EACE,gBAAA;EACA,kBAAA;AADF","sourcesContent":[".font-weight-bold{\r\n    text-indent: 1.5em;\r\n    font-weight: bold;\r\n}\r\n\r\n.ml-1{\r\n    margin-left: 10px;\r\n}\r\n\r\n.font-style2{\r\n  font-size: 1.5em;\r\n}\r\n\r\n\r\n.d-flex-center{\r\n  display: flex;\r\n  justify-content: left;\r\n  align-items: center;\r\n}\r\n\r\n\r\n.d-flex-center2{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: flex-start;\r\n}\r\n\r\n.icon-lg{\r\n  font-size: 2.2em;\r\n  margin-right: 10px;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
