import { Injectable } from '@angular/core';

// services
import { OperatorGroupService,OperatorService, ClientService  } from '../../../../../share/services';

@Injectable({
  providedIn: 'root'
})
export class RegisterCardsVisibilitiesService {

  constructor(
    private _operatorGroupService: OperatorGroupService,
    private _operatorService: OperatorService,
    private _clientService: ClientService
  ) { }

  getOperatorGroups(){
    return this._operatorGroupService.getOperatorGroup()
  }

  getOperators(){
    return this._operatorService.getOperators()
  }

  getClients(){
    return this._clientService.getClients()
  }

}
