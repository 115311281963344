// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-datetime {
  --background: #ffffff;
  --background-rgb: 255, 241, 242;
  border-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/filter-date/date-period-no-calendar/date-period-no-calendar.component.scss"],"names":[],"mappings":"AACA;EACI,qBAAA;EACA,+BAAA;EACA,mBAAA;AAAJ","sourcesContent":["\r\nion-datetime {\r\n    --background: #ffffff;\r\n    --background-rgb: 255, 241, 242;\r\n    border-radius: 16px;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
