import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Operator } from 'src/app/models';
import { Company } from 'src/app/models/';
import { SkinMangementService } from 'src/app/services/skin-mangement.service';
import { successToast, type } from 'src/app/utils';
import { LanguageRouteService } from '../../services/languageRoute.ts/languagesRoute.services';
import { FilterOperatorModalService } from './services/filter-operator-modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-operator-modal',
  templateUrl: './filter-operator-modal.component.html',
  styleUrls: ['./filter-operator-modal.component.scss'],
})
export class FilterOperatorModalComponent implements OnInit {

  @Input() operatorsFullInput: Operator[] = [];

  company: Company;
  type = type;
  errorTranslate = '';
  loadingOperator:boolean = false;
  deleteOperatorFilterTranslate = '';
  operatorSelected:number = null

  operator: string = '';
  operators: Operator[] = [];
  operatorFilter: Operator[] = [];

  changeLanguage$: Subscription;

  @ViewChild(IonInfiniteScroll) inifiteScroll: IonInfiniteScroll;

  constructor(
    private modalCtrl: ModalController,
    private toastCrl: ToastController,
    private translate: TranslateService,
    private _languageRouteService: LanguageRouteService,
    private _skinService: SkinMangementService,
    private _filterService: FilterOperatorModalService,
  ) { }

  async ngOnInit() {
    this.company = this._skinService.getCompany();

    this.changeLanguage$ = this._languageRouteService.changeLanguage$.subscribe(valor => {
      this.translate.setDefaultLang(valor);
      this.savePlaceholders();
    });


    if (this._filterService.getOperator() ){
      this.operator = this._filterService.getOperatorName()
    }

    if (!this.operatorsFullInput){
      this.loadingOperator = true
    }

    if (this.operatorsFullInput){
      this.start()
    }

  }

  ngOnDestroy(): void {
    if(this.changeLanguage$) this.changeLanguage$.unsubscribe()
  }


  start(){

    if(this.operatorsFullInput.length > 50 ){
      this.operators = this.operatorsFullInput.slice(0,50)
      }
    else{
        this.operators = this.operatorsFullInput.slice(0, this.operatorsFullInput.length)
      }

  }

  changeOperator() {
    if (!this.operator){
      this.start()
    }
    else{

      this.operators = [ ]
      this.operatorFilter = this.operatorsFullInput.filter(operator =>
        (operator?.description || '').toLowerCase().trim()
      .includes((this.operator || '').toLowerCase().trim()));

      if (this.operatorFilter.length < 50) {
        this.operators.push(...this.operatorFilter.slice(0, this.operatorFilter.length));
      }
      else {
        this.operators.push(...this.operatorFilter.slice(0, 50));
      }

   }

  }

 loadData(event) {

  setTimeout(() => {
    this.operatorFilter = this.operatorsFullInput.filter(operator => this.operator.trim()
    .includes(operator.name.trim()));

    if (this.operators.length >= this.operatorFilter.length) {
      this.inifiteScroll.complete();
      this.inifiteScroll.disabled = true;
      return;
    }
    let nuevoArr2 = [];

    if (this.operatorFilter.length < this.operators.length + 50) {
      nuevoArr2 = this.operatorFilter.slice(this.operators.length, this.operatorFilter.length);
    } else {
      nuevoArr2 = this.operatorFilter.slice(this.operators.length, this.operators.length + 50);
    }
    this.operators.push(...nuevoArr2);
    this.inifiteScroll.complete();
  }, 100)

}


deleteOperatorFilter() {
  this.operator = '';
  this._filterService.deleteOperator()
  this.operators = [ ]
  this.operatorsFullInput = [ ]
  successToast(this.deleteOperatorFilterTranslate, this.toastCrl);

  this.modalCtrl.dismiss({
    delete: true
  });
}

  filterOperator() {
        this.modalCtrl.dismiss({
          operator: this.operatorSelected,
        });
      this._filterService.setOperator(this.operatorSelected)
  }

  closeModal() {
    this.modalCtrl.dismiss({});
  }


  savePlaceholders() {
    this.translate.get(['transaction_query.carousel-filter.operator_deleted']).subscribe((translates) => {
      this.deleteOperatorFilterTranslate = translates['transaction_query.carousel-filter.operator_deleted'];
    })
  }


}
