import { InsertButtonInfoNM } from '../pages/user/application-management/notification-manager/models';
import { ColorType } from './color-type.type';

// utils
import { ALLIANCEPAY_CARD_NAME } from '../utils/constants';

export interface Company {
  tag: string;
  companySkin: string;
  name: string;
  environment: string;
  appConfig: string;

  nameCard: CardNames;
  minCard: number;
  maxCard: number;
  url: string;
  url_test: string;
  url_local: string;
  companyId: number;
  operatorGroupId: number;

  activeLink: string;
  groupItemMenu: string;
  itemMenu: string;

  colorRGB: ColorType;

  color: string;
  color2: string;
  color3: string;
  colorCancel: string;
  colorFilter: string;
  colorText: string;
  colorText2: string;

  colorNavBar: string,
  colorMenuText: string,
  colorIconMenuNavbar: string,
  colorErrorToast: string,

  colorBullets: string;

  bgColor1: string;
  colorTextTitle: string; // TODO remove in register-user
  titlePage: string;

  ionCardHeader: string;
  ionCardText1: string;
  ionCardText2: string;
  ionTextCardFooter: string;

  ionSegmentButtonStyle: string;
  ionSegmentButtonStyle2: string;
  tableFilter: string;

  spinnerColor: string;

  bgNavbar: string;
  openMenuColor: string;

  bgTable: string;
  bgTable1: string;
  bgTable2: string;
  bgTable3: string;
  bgHoverTable: string;
  bgHoverTableWithoutFilter: string;
  subHeaderTable: string;
  headerVerticalTable: string;
  blockTable: string;
  bgTableBlockHover: string;
  bgTableSelected: string;

  labelDialog: string;

  // top 5, product sold
  labelDialogLoading: string;
  textDialogLoadingColor: string;

  cardTop: string;
  cardBottom: string;

  infoAlert: string;

  // segment ios
  segmentIos: string;

  // ion-toogle
  ionColorToogle: string;

  // Chart
  colorRGBTitleDark: ColorType;
  chartColors: { backgroundColor: string[]; borderColor: string[] }[];
  chartColorsDark: { backgroundColor: string[]; borderColor: string[] }[];

  // online shop
  borderCard: string;
  borderCart: string;
  border: string;

  // ecommerce-manager, visibility manager
  bgTable1Item: string;
  bgTable2Item: string;
  colorTextNoEditable: string;
  colorTextNoEditableItem: string;

  // notification-manager
  buttonInfo: InsertButtonInfoNM;

  // access-web
  segmentVertical: string;
  swiperBgAndBorder: string;
  itemCheckboxAccessWeb: string;
  checkboxAccessWeb: string;
  bgColorContent: string;
  borderHeadDark: string;

  // range slider (sales-module)
  colorBallRange: string;
  colorLineRange: string;
  colorInsideBallRange: string;
  colorInsideBallRangeActive: string;

  // pagination
  paginationCurrentBg: string;
  paginationColorDark: string;
  paginationColorLight: string;

  // tag status
  colorIconWithHoverTable: string;

  // pre login
  colorCompanyText: string;
  colorCompanyText2: string; // site-elora

  // Login alliance
  colorBorderCheckBox?: string;

  // custom alert
  customAlertStyle: string;

  // ecommerce manager, Aena
  colorButtonTable: string;
  colorButtonTableHover: string;

  // chart v4
  colorsCharts: string[];
  backgroundChart: ColorType[];
  backgroundChartDark: ColorType[];
  borderChart: ColorType[];
  borderChartDark: ColorType[];
  donutBackground: ColorType[];
  donutBackgroundDark: ColorType[];
  donutBackgroundBorder: ColorType[];
  donutBackgroundDarkBorder: ColorType[];

  // date
  colorTextVar: string;
  colorTextHover: string;

  // phone material
  colorTableVisibilityBg: string;
  colorTableVisibilityPlaceholder: string;
  colorTablePhoneLightEg: string;
  useDifferentColorPlaceholderTable: boolean,

  // till-alert,  qr-request
  successTable: string;
  qrRequestColorTable: string;

  // notification-panel
  colorAndroidIconTable: string;

  // dashboard-welcome
  colorMonthBar: ColorType[];
  colorMonthBarDark: ColorType[];
  customPopoverDashboard: string;

  // Home
  homeBG: string;

  // User Documentation
  colorChipSuccess: string;
  colorIconChipSuccess: string;

  // License Vtag
  colorCardBg: string;

  // online load
  textLink: string;

  familiesFont: {
    h1: string
    other: string
  };

  // subsidy
  colorInvalidIconButton: string;


  // documentation
  documentation: {
    title: {
      fontFamily: string;
      color: string;
    };
    card: {
      fontFamilyTitle: string;
      fontFamilySubtitle: string;
      path: DocumentationPathOptions;
    };
  };

  borderDarkCard: string;
}

type DocumentationPathOptions = 'elora' | 'arbitrade';

export enum CardNames {
  arbitradePay = ALLIANCEPAY_CARD_NAME,
  closeLoopCard = 'Tarjeta Cautiva',
  other = null,
}
