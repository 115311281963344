import { Injectable } from '@angular/core';

import { StorageCapacitor } from './storage-capacitor/storage-capacitor.class';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private _storage: StorageCapacitor) {}

  async set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  async get(key: string): Promise<any> {
    const value = await this._storage.get(key)
    return (value !== 'null' && value !== 'undefined') ? value : '';
  }

  async remove(key: string) {
    return await this._storage.remove(key);
  }

  async clear() {
    return await this._storage.clear();
  }
}
