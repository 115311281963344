import { SalesWithoutCapacity } from '../models';
import { PRODUCT_PREPARED } from './constant';

export const getSalesWithoutCapacityByArticle = (
  sales: SalesWithoutCapacity[],
  percentageIncr: number
): SalesWithoutCapacity[] => {
  if ((sales || []).length === 0) {
    return [];
  }

  const salesByArticles: Record<number, SalesWithoutCapacity> = {};
  for (let sale of sales) {
    if (salesByArticles?.[sale.articleID]) {
      salesByArticles[sale.articleID].sales += sale.sales;
    } else {
      salesByArticles[sale.articleID] = sale;
    }
  }

  let salesWithoutCapacityByArticle: SalesWithoutCapacity[] = [];
  for (let key of Object.keys(salesByArticles)) {
    salesWithoutCapacityByArticle.push(salesByArticles[key]);
  }

  salesWithoutCapacityByArticle = salesWithoutCapacityByArticle.map((sale) => ({
    ...sale,
    productPrepare: Math.ceil((sale.sales * PRODUCT_PREPARED) / sale.unitsBox),
    productPrepareWithInc: Math.ceil((sale.sales * percentageIncr) / sale.unitsBox),
    replacementUnits: Math.ceil(sale.sales * percentageIncr),
    isSelected: false
  }));



  return salesWithoutCapacityByArticle;
};
