
import { IonicSafeString, ToastController } from '@ionic/angular';

import { durationErrorToast, durationSuccessToast, durationWarningToast } from './constants';

export const presentToast = async (message, toastController: ToastController, duration: number = durationErrorToast) => {

  const toast = await toastController.create({
        message: new IonicSafeString(message),
        duration,
        position: 'top',
        color: 'danger'
    });

    if (toast) {
        toast.present();
    }
}

export const successToast = async (message, toastController: ToastController, duration: number = durationSuccessToast) => {

  const toast = await toastController.create({
        message: new IonicSafeString(`${message}`),
        duration,
        position: 'top',
        icon: 'checkmark-circle-outline',
        color: 'success'
    });

    if (toast) {
        toast.present();
    }
}

export const warningToast = async (message, toastController: ToastController, duration: number = durationWarningToast) => {
  const toast = await toastController.create({
      message:  new IonicSafeString(`<ion-icon name="warning-outline"></ion-icon>  ${message}`),
      duration,
      position: 'top',
      color: 'warning',
      icon: 'warning-outline'
  });

  if (toast) {
      toast.present();
  }
}


