import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Platform } from '@ionic/angular';
// models
import { Company } from 'src/app/models';

// utils
import { pcWidth, pcWidthMix } from '../../../../../../../utils';

@Component({
  selector: 'app-filter-till-aena',
  templateUrl: './filter-till-aena.component.html',
  styleUrls: ['./filter-till-aena.component.scss'],
})
export class FilterTillAenaComponent implements OnInit {

  @Input() company: Company;
  @Input() machineNumber:number = null;

  @Input() colTill: number = 3;
  @Input() colCodeNumber: number = 4.5;
  @Input() pcWidth = pcWidth;
  @Input() pcWidthMix = pcWidthMix;

  @Output() showFilterModal = new EventEmitter()


  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}

  showFilterTill(){
    this.showFilterModal.emit()
  }

}
