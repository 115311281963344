import { Injectable } from '@angular/core';

// models
import { Permission } from '../models';

// constant
import { NEWS_ROUTES, SHOW_NEWS_REDIRECT } from '../config';

@Injectable({
  providedIn: 'root',
})
export class ShowNewsRedirectService {
  constructor() {}

  getShowNewPermission(
    permissions: Permission[]
  ): { haveNewsRedirect: boolean; permission: string | null } {
    if (!this.getShowNewsRedirect()) {
      return { permission: null, haveNewsRedirect: false };
    }

    return {
      haveNewsRedirect: true,
      permission: this.getHavePermission(permissions),
    };
  }

  getShowNewsRedirect() {
    return SHOW_NEWS_REDIRECT;
  }

  private getHavePermission(permissions: Permission[]) {
    for (let { route } of permissions) {
      if (NEWS_ROUTES.includes(route)) {
        return route;
      }
    }
    return null;
  }
}
