import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Platform } from '@ionic/angular';

// models
import { Company } from 'src/app/models';

// utils
import { pcWidth } from 'src/app/utils';

@Component({
  selector: 'app-filter-table-button-aena',
  templateUrl: './filter-table-button-aena.component.html',
  styleUrls: ['./filter-table-button-aena.component.scss'],
})
export class FilterTableButtonAenaComponent implements OnInit {

  @Input() company: Company;
  @Input() loading: boolean = true;
  @Input() disabledButton: boolean = false;
  @Input() textButton: string = '';
  @Input() filterText: string = '';

  @Output() showFilter = new EventEmitter()

  pcWidth = pcWidth

  constructor(
    public readonly platform: Platform
  ) { }

  ngOnInit() {}


  showFilterModal(){
    this.showFilter.emit()
  }

}
