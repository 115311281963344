// constant
import {
  ALLIANCEPAY_CARD_NAME,
  NAMES_CARDS_PREV,
  NAMES_CARDS_PREV_PLURAL,
} from './constants';

// utils
import { replaceAll } from './utils';

export const replaceNameCard = (value: string): string => {
  const nameCard = ALLIANCEPAY_CARD_NAME;
  const namesCardPrev = NAMES_CARDS_PREV;

  return replaceAllNameCard({ value, nameCard, namesCardPrev });
};

export const replaceNameCardPlural = (value: string): string => {
  const nameCard = ALLIANCEPAY_CARD_NAME;
  const namesCardPrev = NAMES_CARDS_PREV_PLURAL;

  return replaceAllNameCard({ value, nameCard, namesCardPrev });
};

interface ReplaceAllNameCard {
  value: string;
  nameCard: string;
  namesCardPrev: string[];
}

const replaceAllNameCard = ({
  value,
  nameCard,
  namesCardPrev,
}: ReplaceAllNameCard): string => {
  if (!value) {
    return '';
  }

  for (let nameCardPrev of namesCardPrev) {
    value = replaceAll(value, nameCardPrev, nameCard);
  }

  return value;
};
