import { CardNames, Company } from 'src/app/models';

const TRANSLATE_PATH = 'reports.cards.';
const CHECK_INVALID_CARD_TRANSLATES = {
  alliance: {
    plural: `${TRANSLATE_PATH}invalid_cards_alliance`,
    singular: `${TRANSLATE_PATH}invalid_card_alliance`,
  },
  closeLoopCards: {
    plural: `${TRANSLATE_PATH}invalid_close_loop_cards`,
    singular: `${TRANSLATE_PATH}invalid_close_loop_card`,
  },
};

export const getCheckInvalidCardsTextTranslateReport = (
  company: Company,
  cardLength: number
) => {
  if (company.nameCard === CardNames.arbitradePay) {
    return getInvalidCheckCardsAlliance(cardLength);
  } else {
    return getInvalidCheckCloseLoopCard(cardLength);
  }
};

const getInvalidCheckCardsAlliance = (cardLength: number) => {
  return cardLength === 1
    ? CHECK_INVALID_CARD_TRANSLATES.alliance.singular
    : CHECK_INVALID_CARD_TRANSLATES.alliance.plural;
};

const getInvalidCheckCloseLoopCard = (cardLength: number) => {
  return cardLength === 1
    ? CHECK_INVALID_CARD_TRANSLATES.closeLoopCards.singular
    : CHECK_INVALID_CARD_TRANSLATES.closeLoopCards.plural;
};
