// models
import { HaveToGoDashboardStatusNet } from 'src/app/home/models';
import { Permission } from 'src/app/models';

// utils
import { DASHBOARD_TECHNICAL_ROUTE } from '../constants';

interface IsDashboardStatusNetValid{
  operatorGroupId: number,
  operatorId: number,
  clientGroupId: number,
  clientId: number,
  supervisorId: number,
  routeDriveId: number,
  routeId: number,
}

export const haveToGoDashboardStatusNet = ({
  permissions,
  operatorGroupId,
  operatorId,
  clientGroupId,
  clientId,
  supervisorId,
  routeDriveId,
  routeId,
}: HaveToGoDashboardStatusNet): boolean => {
  return (
    haveDashboardStatusNet(permissions) &&
    isDashboardStatusNetValid({
      operatorGroupId,
      operatorId,
      clientGroupId,
      clientId,
      supervisorId,
      routeDriveId,
      routeId,
    })
  );
};

const haveDashboardStatusNet = (permissions: Permission[]) => {
  if (
    permissions.some(
      (permission) => permission.route === DASHBOARD_TECHNICAL_ROUTE
    )
  ) {
    return true;
  }

  return false;
};

const isDashboardStatusNetValid = ({
  operatorGroupId,
  operatorId,
  clientGroupId,
  clientId,
  supervisorId,
  routeDriveId,
  routeId,
}: IsDashboardStatusNetValid) => {

  if(!operatorGroupId){
    return false;
  }

  if(clientId || clientGroupId){
    return false;
  }

  if(operatorGroupId || operatorId){
    return true;
  }

  return false;

};
