// external modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {NgxMatIntlTelInputComponent} from 'ngx-mat-intl-tel-input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

// component
import { PhoneInputTemplateComponent } from './phone-input-template.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    NgxMatIntlTelInputComponent
  ],
  declarations: [
    PhoneInputTemplateComponent
  ],
  providers: [

  ],
  exports: [
    PhoneInputTemplateComponent
  ]
})
export class PhoneInputTemplateModule {}
