// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-operator {
  max-width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/share/components/visibility-select/filter-operator/filter-operator.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF","sourcesContent":[".select-operator{\r\n  max-width: 100% !important\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
