import { GroupsNameRouteValids } from '../models/groups-name-route-valid.type';

export const ICONS_GROUP_ROUTE: Record<
  GroupsNameRouteValids,
  IconByImage | IconByIonic
> = {
  query: { icon: '../../../assets/custom-icons/transaction.svg' },
  cards: { icon: '../../../assets/custom-icons/icons/cards.svg' },
  machines: {
    icon: '../../../assets/custom-icons/vending.svg',
    icon2: '../../../assets/custom-icons/machine-svg.svg',
  },
  articles: { icon: '../../../assets/custom-icons/articles.svg' },
  dataManager: { nameIcon: 'create-outline' },
  qr: { nameIcon: 'qr-code-outline' },
  onlineShop: { icon: '../../../assets/custom-icons/icons/online-shop.svg' },
  userManagement: { nameIcon: 'person-add-outline' },
  clickCollect: {
    icon: '../../../assets/custom-icons/icons/click-collect.svg',
  },
  applicationManagement: {
    icon: '../../../assets/custom-icons/icons/application-management.svg',
  },
  aena: { nameIcon: 'airplane-outline' },
  listing: { nameIcon: 'list-outline' },
  routesManagement: {
    icon: '../../../assets/custom-icons/icons/routes-management.svg',
  },
  horeca: { nameIcon: 'cafe' },
  billing: { nameIcon: 'calculator' },
  dashboards: { nameIcon: 'bar-chart-outline' },
  documentation: { nameIcon: 'document-text-outline' },
  qPay: {icon: '../../../assets/custom-icons/icons/master-card-icon.svg' }
};

interface IconByImage {
  icon: string;
  icon2?: string;
}

interface IconByIonic {
  nameIcon: string;
}
